// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatsection {
  margin: 20px 0px 0px;
  /* border-radius: 8px 0px 0px 0px; */
  /* border: 1px solid; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: 100%;
}

.main-chat {
  display: flex;
}
.blocked{
  margin-bottom: 15px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  padding: 6px 15px;
  background: var(--color);
  border-radius: 8px;
}


@media screen and (max-width: 768px) {
  .main-chat {
    position: relative;
    /* width: 18.2rem; */
    background: white;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/Chat/ChatSection/ChatSection.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oCAAoC;EACpC,uBAAuB;EACvB,+CAA+C;EAC/C,WAAW;AACb;;AAEA;EACE,aAAa;AACf;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,kBAAkB;AACpB;;;AAGA;EACE;IACE,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;EACnB;AACF","sourcesContent":[".chatsection {\n  margin: 20px 0px 0px;\n  /* border-radius: 8px 0px 0px 0px; */\n  /* border: 1px solid; */\n  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;\n  width: 100%;\n}\n\n.main-chat {\n  display: flex;\n}\n.blocked{\n  margin-bottom: 15px;\n  text-align: center;\n  font-size: 20px;\n  font-weight: 400;\n  padding: 6px 15px;\n  background: var(--color);\n  border-radius: 8px;\n}\n\n\n@media screen and (max-width: 768px) {\n  .main-chat {\n    position: relative;\n    /* width: 18.2rem; */\n    background: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
