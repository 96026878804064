import React, { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import JobSeeking from './JobSeeking/JobSeeking';
import JobPosting from './JobPosting/JobPosting';


const CineJob = () => {
  const [alignment, setAlignment] = useState('jobSeeking');

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <div>
      <div className="flex align-items-center justify-content-between mt-4 pt-4 lg:pt-0">
        <h3 style={{ color: '#4b4b4b' }}>Cine Jobs</h3>
        <div>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Job Type"
          >
            <ToggleButton
              value="jobSeeking"
              sx={{
                '&.Mui-selected': {
                  color: '#fff',
                  bgcolor: '#2196f3'
                },
                color: '#2196F3',
                bgcolor: '#fff',
                '&:hover': {
                  bgcolor: 'transparent',
                },
                '&.Mui-selected:hover': {
                  bgcolor: '#2196f3'
                }
              }}
            >
              Job Seeking
            </ToggleButton>
            <ToggleButton
              value="jobPosting"
              sx={{
                '&.Mui-selected': {
                  color: '#fff',
                  bgcolor: '#2196f3'
                },
                color: '#2196F3',
                bgcolor: '#fff',
                '&:hover': {
                  bgcolor: 'transparent',
                },
                '&.Mui-selected:hover': {
                  bgcolor: '#2196f3'
                }
              }}
            >
              Job Posting
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="mt-4">
        {alignment === 'jobSeeking' && <JobSeeking alignment={alignment} setAlignment={setAlignment} />}
        {alignment === 'jobPosting' && <JobPosting alignment={alignment} setAlignment={setAlignment} />}
      </div>
    </div>
  );
}

export default CineJob;
