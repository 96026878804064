// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-input .p-inputtext {
  width: 100%;
  height: 35px;
}

/* .schoolform .p-datatable .p-datatable-tbody > tr > th {
  padding: 12px 0px 0px;
} */
.schoolform .p-datatable .p-datatable-tbody > tr > td {
  padding: 0px 15px;
}
.personal-modal {
  /* width: 40% !important; */
}

  .MuiAccordion-gutters.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation.MuiPaper-elevation1.MuiPaper-root.MuiPaper-rounded.css-1086bdv-MuiPaper-root-MuiAccordion-root.profile-card-accord {
    box-shadow: none;
  }

  .css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
    background-color: white !important;
  }
@media only screen and (max-width: 768px) {
  .personal-modal {
    width: 93% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/AddEducationDetails/School.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;;GAEG;AACH;EACE,iBAAiB;AACnB;AACA;EACE,2BAA2B;AAC7B;;EAEE;IACE,gBAAgB;EAClB;;EAEA;IACE,kCAAkC;EACpC;AACF;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".table-input .p-inputtext {\n  width: 100%;\n  height: 35px;\n}\n\n/* .schoolform .p-datatable .p-datatable-tbody > tr > th {\n  padding: 12px 0px 0px;\n} */\n.schoolform .p-datatable .p-datatable-tbody > tr > td {\n  padding: 0px 15px;\n}\n.personal-modal {\n  /* width: 40% !important; */\n}\n\n  .MuiAccordion-gutters.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation.MuiPaper-elevation1.MuiPaper-root.MuiPaper-rounded.css-1086bdv-MuiPaper-root-MuiAccordion-root.profile-card-accord {\n    box-shadow: none;\n  }\n\n  .css-1086bdv-MuiPaper-root-MuiAccordion-root::before {\n    background-color: white !important;\n  }\n@media only screen and (max-width: 768px) {\n  .personal-modal {\n    width: 93% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
