import React, { createContext, useState, useRef } from 'react';
import axios from 'axios';
import { Nodeapi } from '../config/serverUrl';
import moment from "moment-timezone";


export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [senddata, setsenddata] = useState([])
  const [display, setdisplay] = useState(false)
  const [selectedData, setSelectedData] = useState([]);
  const [post_details, setpost_details] = useState({
    post_title: ""
  })
  const [Ad_Details, setAd_Details] = useState(true)
  const [AD_rejected, setAD_rejected] = useState(false)
  const [AD_Posted, setAD_Posted] = useState(false)
  const [AD_Total, setAD_Total] = useState(false)
  const [uploadedImages, setUploadedImages] = useState([]);

  const [UploadFiles, setUploadFiles] = useState([]);
  const [view_adpost, setview_adpost] = useState([])
  const [displaypost, setdisplaypost] = useState([]);
  const [favorites, setfavorites] = ([])
  const [edit_account, setedit_account] = useState([])
  const [visible, setvisible] = useState(false)

  const [listcompanies, setlistcompanies] = useState([])
  const [VideoPage, setVideoPage] = useState(false);
  const [Joinchatmaster, setJoinchatmaster] = useState(null);
  const [AudioCall, setAudioCall] = useState(false);
  const [CallfromUser, setCallfromUser] = useState(false);
  const [CallRoomid, setCallRoomid] = useState(null);
  const [Callaccept, setCallaccept] = useState(false);
  const [listimages, setlistimages] = useState([])
  const [home_listimages, sethome_listimages] = useState([]);
  const [calorname, setCallerName] = useState(null);
  const [activeVideo, setActiveVideo] = useState('');
  const [MainScrool, setMainScrool] = useState(true);
  const [CallOnLine, setCallOnLine] = useState(false);
  const [callerprofile, setCallerprofile] = useState('');

  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isTypingid, setIsTypingId] = useState(false);
  const [fwmessage, setFwmessage] = useState([]);
  const [forward, setForward] = useState(false);
  const [SelectedContact, setSelectedContact] = useState([]);
  const [Messagestatus, setMessagestatus] = useState([]);
  const [MessageDeliver, setMessageDeliver] = useState(false);
  const [userTmZone, setUserTimeZone] = useState({});

  const [microphone, setClickmicrophone] = useState(false); //closeChange
  const [cate, setCate] = useState(false); //closeChange
  const [emoji, setEmoji] = useState(false); //closeChange
  const [consearch, setConSearch] = useState(false); //closeChange
  const [gifShow, setGifShow] = useState(false);
  const [selectedGif, setSelectedGif] = useState(null);
  const [Gifsubtype, setGifsubtype] = useState('');
  const [TextReply, setTextReply] = useState('text');
  const [UserStatus, setUserStatus] = useState('');
  const [openGroup, setopenGroup] = useState(false);
  const [openGroupStage2, setopenGroupStage2] = useState(false);
  const [Chatlist, setChatlist] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedIdswithdata, setSelectedIdswithdata] = useState([]);
  const [imagecrop, setimagecrop] = useState(false);
  const [CallStatus, setCallStatus] = useState('');
  const [Profileimg, setProfileimg] = useState('null');
  const [collapse, setCollapse] = useState(false);
  const [addfavorites, setaddfavorites] = useState(Array(displaypost.length).fill(false))
  const [category, setcategory] = useState("")
  const [postiddata, setpostiddata] = useState("")
  const [vehicleOption, setVehicleOption] = useState([]);
  const [userName, setUserName] = useState("");
  const [ShowCHat, setShowChat] = useState(false);
  const [show, setShow] = useState(true);
  const [clientdetails,setclientdetails] = useState([])
  const[invoicedetails,setinvoicedetails] = useState('')
  const[userdetails,setuserdetails] = useState('')
  const divRefs = useRef({});
  const [inspectionMode, setInspectionMode] = useState(false);
  const [muteContact, setMuteContact] = useState({});

  const[unionterritory,setunionterritory] = useState(false)
  const [usercountry,setusercountry] = useState("")
  const [Insidestate,setInsidestate] = useState(false)
  const [outerstate,setouterstate] = useState(false)
  const[country_check,setcountry_check] = useState(false)
  const[searchTerm, setSearchTerm] = useState('')
  const [sendMultiImg,setSendMultiImg]= useState(false);
  const [SelectFile, setSelectFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [ChatClickedid, setChatClickedid] = useState('');
  const [datavalue,setdatavalue] = useState([])

  const [title,settitle] = useState('')
  const [description,setdescription] = useState('')
  const [place,setplace] = useState('')
  const [Price,setPrice] = useState('')
  const [loading, setLoading] = useState(false);
  const [back_btn,setback_btn] = useState(false)
  const [showTooltip,setShowTooltip] = useState(false);
  const scrollViewRef = useRef(null);
  const [time, setTime] = useState(0);
  const [recordedBlob, setRecordedBlob] = useState(null);

  const fetchdata = async()=>{
    const postId = localStorage.getItem('PostID')
   
    setdatavalue(null);
    settitle('');
    setdescription('');
    setplace('');
    setPrice('');
    setLoading(true);
    
    try {
       const res = await axios.get(`${Nodeapi}/getselected_posts?postid=${postId}`)
       const data = res.data.data.result
       console.log('data:',data);
       setdatavalue(data)
       settitle(data[0].post_title)
       setdescription(data[0].post_description)
       setplace(data[0].post_location)
       setPrice(data[0].post_price)
       
       setLoading(false);
    } catch (error) {
      setLoading(false);
    }
}
  
  const [chathistory, setChathistory] = useState([
    {
      id: 1,
      sender_id: 123456,
      receiver_id: 789012,
      pdf: "PDF-1t82t567-ehfhegh-pdf",
      video: "",
      message: "",
      img: "https://car-images.bauersecure.com/wp-images/4816/bmw_g70_design_debate5.jpg",
      type: "msg",
      subtype: "img",
      date_added: "2024-04-18 11:20:28.000",
      chatmaster_id: "CHAT000",
      audio:
        "https://onlinetestcase.com/wp-content/uploads/2023/06/100-KB-MP3.mp3",
    },
  ]);

  const ClickParticipantList = (id) =>{
    
  }


  const handelModelClose = () => {
    setSelectedContact([]);
  };


  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const handlecloseEmoji = () => {
    setCate(false);
    setopenGroup(false);
    setEmoji(false);
    setConSearch(false);
    setGifShow(false);

  };
  

  const handleUpdatemessage = async (messageid, status) => {
    const res = await axios.patch(`${Nodeapi}/updateMessageStatus`, {
      messageid: messageid,
      status: status
    });
  }


  const handleName = (name) => {
    setUserName(name);
    setShow(false);
    handleShowArea();
  };

  const handleShowArea = () => {
    setShowChat(true);
  };



  const handleSendCallmessage = async (senderid, reciverid, calltime, staticRoomId, subtype) => {

    try {
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      const formattedHours = (hours % 12) || 12;
      const formattedMinutes = String(minutes).padStart(2, '0'); // Pad minutes with leading zero if needed

      const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
      const formData = new FormData();
      formData.append("senderid", senderid);
      formData.append("receiverid", reciverid);
      formData.append("type", "msg");
      formData.append("message", calltime);
      formData.append("time", formattedTime);
      formData.append("subtype", subtype);
      formData.append("chatmaster_id", staticRoomId);
      const res = await axios.post(`${Nodeapi}/msgconversation`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data) {
        if (res.data.code == 200) {
          console.log(res);
        } else {
          console.log(res);
        }
      }
    } catch (err) {

      console.log("err of insert data", err);
    }
  };

  const handleFWCheckboxChange = (fwData, checked) => {
    setSelectedContact((prevIds) =>
      checked
        ? [...prevIds, fwData]
        : prevIds.filter((fw_id) => fw_id.id !== fwData.id)
    );
  };


  const [edit, setEdit] = useState(false);
  const [editMsg, setEditMsg] = useState();
  const [inputfield, setInputfield] = useState("");


  const handleEditSendMessage = async ({ id, date_added }) => {
    try {
      const res = await axios.put(`${Nodeapi}/editConversation`, {
        id: id,
        updatedvalue: inputfield,
        msgdate: date_added,
      });
      console.log('mesage', res.data.data)
      setEdit(false);
    } catch (error) {
      console.log(error);
    }

    setInputfield("");
  };

  const convertToIST = (time, timeZone) => {
    const istTimeZone = "Asia/Kolkata";

    // Check if the given timezone is already IST
    if (timeZone === istTimeZone) {
      return moment.tz(time, istTimeZone).format();
    }

    // Convert the given time to IST
    const convertedTime = moment.tz(time, timeZone).tz(istTimeZone).format();
    return convertedTime;
  };


  const convertTimezone = (time, userTimezone) => {
    // Regular expression to check if time is in ISO 8601 format
    const iso8601Regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[\+\-]\d{2}:\d{2})/;

    if (iso8601Regex.test(time)) {
      // Time is in ISO 8601 format, convert it
      const chatTime = convertToIST(time, userTimezone);

      const hours = moment(chatTime).format("HH");
      const minutes = moment(chatTime).format("mm");
      const ampm = hours >= 12 ? "pm" : "am";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = String(minutes).padStart(2, "0"); // Pad minutes with leading zero if needed

      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    } else {
      // Time is in the old format, return it as is
      return time;
    }
  };


  const handleCheckboxChangeUncheck = (id, name, image) => {
    const currentIndex = selectedIds.findIndex(item => item.id === id);
    const newSelectedIds = [...selectedIds];

    if (currentIndex === -1) {
      newSelectedIds.push({ id: id, name: name, img: image });
      setSelectedIdswithdata(newSelectedIds);
    } else {
      newSelectedIds.splice(currentIndex, 1);
      selectedIdswithdata.splice(currentIndex, 1);

    }

    setSelectedIds(newSelectedIds);
  };


  const [unreadChats, setUnreadChats] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [textRows, setTextRows] = useState(1);


  
//file Download function
const downloadFileAtURL = (url) => {
  const fileName = url.split("/").pop();

  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const blobURL = window.URL.createObjectURL(blob);
      const aTag = document.createElement("a");
      aTag.href = blobURL;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();

      // Revoke the object URL after the download
      window.URL.revokeObjectURL(blobURL);
    })
    .catch(error => {
      console.error('There was an error with the download:', error);
    });
};



  const fetchunreadmsg = async (authdata) => {
    try {
      if (authdata?.id) {
        const res = await axios.get(`${Nodeapi}/fetchTotalUnreadMsg?userId=${authdata.id}`);
        const uniqueChatmasters = res.data.data.uniqueChatmasters;
        setUnreadChats(uniqueChatmasters);
        const count = uniqueChatmasters.length;
        setUnreadCount(count < 100 ? count : "99+");
      }
    } catch (error) {
      console.log("Error in fetching unread msg count", error);
    }
  };

  const [activeTab, setActiveTab] = useState("media");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const allowedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.jfif'];

  function isValidImageFile(filename) {
      const fileExtension = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
      return allowedImageExtensions.includes(`.${fileExtension}`);
  }

  const allowedVideoExtensions = ['.mp4', '.avi', '.mkv', '.mov'];

  function isValidVideoFile(filename) {
      const fileExtension = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
      return allowedVideoExtensions.includes(`.${fileExtension}`);
  }
  const [forwardList, setForwardList] = useState([]);

  const forwardListData = async (id) => {
    const listdetails = {
      id: id,
      receiver_id: "",
    };
    const response = await axios.post(`${Nodeapi}/chatlist`, listdetails);
    setForwardList(response.data.data.chatlist);
  };

  return (
    <DataContext.Provider value={{
      forwardListData,
      forwardList,
      setForwardList,
      senddata,
      setsenddata,
      setdisplay,
      display,
      setSelectedData,
      selectedData,
      setpost_details,
      post_details,
      setAd_Details,
      Ad_Details,
      setAD_rejected,
      AD_rejected,
      setAD_Posted,
      AD_Posted,
      setUploadedImages,
      uploadedImages,
      setUploadFiles,
      UploadFiles,
      setAD_Total,
      AD_Total,
      setview_adpost,
      view_adpost,
      setdisplaypost,
      displaypost,
      setfavorites,
      favorites,
      setedit_account,
      edit_account,
      setvisible,
      visible,
      setlistcompanies,
      listcompanies,
      VideoPage,
      setVideoPage,
      setJoinchatmaster,
      Joinchatmaster,
      AudioCall, setAudioCall,
      CallfromUser, setCallfromUser,
      CallRoomid, setCallRoomid,
      Callaccept, setCallaccept,
      sethome_listimages,
      home_listimages,
      listimages,
      setlistimages,
      calorname, setCallerName,
      activeVideo, setActiveVideo,
      MainScrool, setMainScrool,
      handleSendCallmessage,
      CallOnLine, setCallOnLine,
      typing, setTyping,
      isTyping, setIsTyping,
      isTypingid, setIsTypingId,
      callerprofile, setCallerprofile,
      fwmessage, setFwmessage,
      SelectedContact, setSelectedContact,
      handelModelClose,
      handleFWCheckboxChange,
      forward, setForward,
      Messagestatus, setMessagestatus,
      handleUpdatemessage,
      edit,
      setEdit,
      editMsg,
      setEditMsg,
      inputfield, setInputfield,
      handleEditSendMessage,
      MessageDeliver, setMessageDeliver,
      convertToIST,
      userTmZone, setUserTimeZone,
      convertTimezone,
      microphone, setClickmicrophone,
      cate, setCate,
      emoji, setEmoji,
      consearch, setConSearch,
      handlecloseEmoji,
      gifShow, setGifShow,
      selectedGif, setSelectedGif,
      Gifsubtype, setGifsubtype,
      TextReply, setTextReply,
      UserStatus, setUserStatus,
      openGroup, setopenGroup,
      openGroupStage2, setopenGroupStage2,
      Chatlist, setChatlist,
      selectedIds, setSelectedIds,
      selectedIdswithdata, setSelectedIdswithdata,
      handleCheckboxChangeUncheck,
      imagecrop, setimagecrop,
      data, setData,
      unreadCount, setUnreadCount,
      fetchunreadmsg,
      activeTab, setActiveTab,
      handleTabClick,
      textRows, setTextRows,
      CallStatus, setCallStatus,
      addfavorites, setaddfavorites,
      category, setcategory,
      postiddata, setpostiddata,
      vehicleOption, setVehicleOption,
      Profileimg, setProfileimg,
      collapse, setCollapse,
      handleCollapse,
      userName, setUserName,
      handleShowArea,
      handleName,
      show, setShow,
      ShowCHat, setShowChat,
     ClickParticipantList,
     clientdetails,setclientdetails,
      invoicedetails,setinvoicedetails,
      userdetails,setuserdetails,
      divRefs,
      Insidestate,setInsidestate,
      outerstate,setouterstate,
      country_check,setcountry_check,
      unionterritory,setunionterritory,
      usercountry,setusercountry,
      inspectionMode, setInspectionMode,
      muteContact, setMuteContact,
      isValidImageFile,
      selectedImage, setSelectedImage,
      isValidVideoFile,
      searchTerm, setSearchTerm,
      sendMultiImg,setSendMultiImg,
      SelectFile, setSelectFile,
      ChatClickedid, setChatClickedid,
      chathistory, setChathistory,
      datavalue,setdatavalue,
      title,settitle,
      description,setdescription,
      place,setplace,
      Price,setPrice,
      fetchdata,
      loading, setLoading,
      back_btn,setback_btn,
      showTooltip,setShowTooltip,
      scrollViewRef,
      time, setTime,
  recordedBlob, setRecordedBlob,
  downloadFileAtURL
    }}>

      {children}
    </DataContext.Provider>
  );
};
