import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./Admin.css";
import { IoArrowForwardOutline} from "react-icons/io5";
import { useSelector } from "react-redux";
import axios from "axios";
import { Nodeapi, Assetsapi } from "../config/serverUrl";
import { DataContext } from "./DataContext";
import { Dialog } from "primereact/dialog";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { IoCalendarOutline, IoCloseSharp } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import "./View_Adpost.css";
import { IoSearchOutline } from "react-icons/io5";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";

import Swal from "sweetalert2";
import { ConfirmDialog } from "primereact/confirmdialog";
import { IoMdArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "#fff",
  border: "0px",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
  //   height: "483px",
  //   overflowY: "scroll",
};

function Advertisment_Panel() {
  const authdata = useSelector((state) => state.auth.user);
  const {
    setsenddata,
    setdisplay,
    display,
    senddata,
    setSelectedData,
    selectedData,
    Ad_Details,
    setAd_Details,
    setpost_details,
    setAD_rejected,
    AD_rejected,
    setAD_Posted,
    AD_Posted,
    setAD_Total,
    AD_Total,
    setlistimages,
  } = useContext(DataContext);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState("advertisement");
  const [postdetails, setpostdetails] = useState([]);
  const [filtervalue, setfiltervalue] = useState([]);
  const [heart, setHeart] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [Update, setUpdate] = useState(false);
  const [bookmarkStates, setBookmarkStates] = useState(
    Array(senddata.length).fill(false)
  );
  const [confrim, setconfrim] = useState(false);
  const [adreject, setadreject] = useState(false);
  const [totalads, settotalads] = useState("");
  const [posted, setposted] = useState("");
  const [rejectedpost, setrejectedpost] = useState("");
  const [pending, setpending] = useState("");
  const [display1, setdisplay1] = useState(true);
  const [display2, setdisplay2] = useState(false);
  const [display3, setdisplay3] = useState(false);
  const [display4, setdisplay4] = useState(false);
  const [display5, setdisplay5] = useState(false);
  const [data1, setdata1] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);

  const [approvedAds, setApprovedAds] = useState([]);
  const [rejecteddAds, setRejectedAds] = useState([]);
  const [pendingAds, setPendingAds] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [LostAndFoundData, setLostAndFoundData] = useState([]);
  useEffect(() => {
    if (selectedTab === 'lost-and-found') {
      fetchLostAndFoundData();
    }
  }, [selectedTab]);

  const fetchLostAndFoundData = async () => {
    try {
      const { data } = await axios.get(`${Nodeapi}/lostfound/count`);
      if (Array.isArray(data.pendingPostsByUser)) {
        setLostAndFoundData(data.pendingPostsByUser);
      } else {
        console.error("API response does not contain an array:", data);
      }
    } catch (error) {
      console.error("Error fetching lost and found items:", error);
    }
  };
  const navigate = useNavigate();

  const handlreject = () => {
    setadreject(true);
    setconfrim(false);
  };

  const handlecancel = () => {
    setadreject(false);
  };

  const handleBookmarkClick = (index) => {
    const newBookmarkStates = [...bookmarkStates];
    newBookmarkStates[index] = !newBookmarkStates[index];
    setBookmarkStates(newBookmarkStates);
    navigate("/main/View_CardDetails");
    if (newBookmarkStates[index]) {
      setSelectedIndices([...selectedIndices, index]);
      setSelectedData([...selectedData, senddata[index]]);
    } else {
      const updatedIndices = selectedIndices.filter((i) => i !== index);
      const updatedData = selectedData.filter((data, i) => i !== index);
      setSelectedIndices(updatedIndices);
      setSelectedData(updatedData);
      setpost_details(updatedData);
    }
  };

  const handleclick = () => {
    setHeart(!heart);
  };

  useEffect(() => {
    fetchdata();
    fetchpostedcount();
    fetchrejectedcount();
    fetchpendingcount();
  }, []);

  const fetchdata = async () => {
    try {
      let isActive = 1;
      const response = await axios.get(`${Nodeapi}/getpostdata1`);
      setpostdetails(response.data.fetchpostdata);
      setfiltervalue(response.data.alldata);

      const datacount = response.data.alldata;

      const totalcount = datacount.length;

      settotalads(totalcount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchpostedcount = async () => {
    try {
      let approved = 1;
      const res = await axios.get(
        `${Nodeapi}/fetchpostedcount?approved=${approved}`
      );
      const postedcount = res.data.postedcount;

      const datacount = postedcount.length;
      if (datacount) {
        setposted(datacount);
      } else {
        let val = 0;
        setposted(val);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchrejectedcount = async () => {
    try {
      let rejected = 1;
      const res = await axios.get(
        `${Nodeapi}/fetchrejectedcount?rejected=${rejected}`
      );
      const rejectedcount = res.data.rejectedcount;
      const datacount = rejectedcount.length;
      if (datacount) {
        setrejectedpost(datacount);
      } else {
        let val = 0;
        setrejectedpost(val);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchpendingcount = async () => {
    try {
      let isActive = 1;
      const response = await axios.get(
        `${Nodeapi}/getpostdata?isactive=${isActive}`
      );
      const dataValue = response.data.fetchpostdata;

      const filterdata = dataValue.filter(
        (item) => item.approved !== "1" && item.rejected !== "1"
      );

      setdata1(filterdata);

      const datacount = filterdata.length;
      if (datacount) {
        setpending(datacount);
      } else {
        let val = 0;
        setpending(val);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleViewAdvertisement = (rowData) => {
    const newBookmarkStates = Array(bookmarkStates.length).fill(false);
    setBookmarkStates(newBookmarkStates);
    setSelectedData("");
    const id_value = rowData.userid;
    const filterdata = filtervalue.filter((item) => item.userid === id_value);
    const dataval = filterdata.filter(
      (item) => item.approved !== "1" && item.rejected !== "1"
    );

    if (dataval.length > 0) {
      setdisplay(true);
      setsenddata(dataval);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "No further ads from this user.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleViewPostedAds = (rowData) => {
    const newBookmarkStates = Array(bookmarkStates.length).fill(false);
    setBookmarkStates(newBookmarkStates);
    setSelectedData("");
    setdisplay(true);

    const id_value = rowData.userid;
    const filterdata = filtervalue.filter((item) => item.userid === id_value);
    const posteddata = filterdata.filter((item) => item.approved === "1");

    setsenddata(posteddata);
  };

  const handleViewRejectedAds = (rowData) => {
    const newBookmarkStates = Array(bookmarkStates.length).fill(false);
    setBookmarkStates(newBookmarkStates);
    setdisplay(true);
    setSelectedData("");
    const id_value = rowData.userid;
    const filterdata = filtervalue.filter((item) => item.userid === id_value);
    const rejecteddata = filterdata.filter((item) => item.rejected === "1");
    setsenddata(rejecteddata);
  };

  const handleViewTotalAds = (rowData) => {
    setdisplay(true);
    setSelectedData("");
    setAD_Total(true);
    const id_value = rowData.userid;
    const filterdata = filtervalue.filter((item) => item.userid === id_value);
    const dataval = filterdata.filter((data) => data.approved === "1");
    const rejecteddata = filterdata.filter((item) => item.rejected === "1");
    const alldata = filterdata.filter(
      (item) => item.approved !== "1" && item.rejected !== "1"
    );
    setsenddata(filterdata);
    setApprovedAds(dataval);
    setRejectedAds(rejecteddata);
    setPendingAds(alldata);
  };

  const handleViewPendingAds = (rowData) => {
    const newBookmarkStates = Array(bookmarkStates.length).fill(false);
    setBookmarkStates(newBookmarkStates);
    setdisplay(true);
    setSelectedData("");
    const id_value = rowData.userid;

    // const filterdata = data1.filter((item) => item.userid === id_value);
    const filterdata = filtervalue.filter(
      (item) =>
        item.userid === id_value &&
        item.approved !== "1" &&
        item.rejected !== "1"
    );
    // const rejecteddata = filterdata.filter((item)=> item.rejected === "1")

    setsenddata(filterdata);
  };

  const handleSubmit = async () => {
    if (selectedData.length > 0) {
      setconfrim(true);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Select the Posts",
        showConfirmButton: false,
        timer: 2000,
      });
      setdisplay(false);
    }
  };

  const handleCancel = () => {
    setdisplay(false);
  };

  const updateBookmarkStatesToFalse = () => {
    const newBookmarkStates = Array(senddata.length).fill(false);
    setBookmarkStates(newBookmarkStates);
  };

  const handlepost = async () => {
    try {
      if (selectedData.length > 0) {
        const selectedIds = selectedData.map((card) => card.id);
        const countSelectedIds = selectedIds.length;

        const response = await axios.post(`${Nodeapi}/updateDisplay`, {
          selectedIds: selectedIds,
          countSelectedIds: countSelectedIds,
        });

        if (response.status === 200) {
          if (Update) {
            setdisplay(false);
            setconfrim(false);
            handlepending();
            fetchpostedcount();

            fetchrejectedcount();
            fetchpendingcount();
            updateBookmarkStatesToFalse();
          } else {
            fetchdata();
            setdisplay(false);
            setconfrim(false);
            fetchpostedcount();
            fetchrejectedcount();
            fetchpendingcount();
            updateBookmarkStatesToFalse();
          }

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Successfully Posted",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          console.error("Failed to update display for selected cards");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handletotalads = async () => {
    try {
      setdisplay(false);
      setdisplay1(false);
      setdisplay2(false);
      setdisplay3(false);
      setdisplay4(false);
      setdisplay5(true);
      setUpdate(false);
      setAD_rejected(false);
      setAd_Details(false);
      setAD_Posted(false);

      const response = await axios.get(`${Nodeapi}/getpostdata1`);

      setpostdetails(response.data.fetchpostdata);

      setfiltervalue(response.data.alldata);
    } catch (error) {}
  };

  const handleposted = async () => {
    try {
      setdisplay1(false);
      setdisplay2(true);
      setdisplay3(false);
      setdisplay4(false);
      setdisplay5(false);
      setUpdate(false);
      setAd_Details(false);
      setAD_rejected(false);
      setAD_Posted(true);
      const response = await axios.get(`${Nodeapi}/getpostdata1`);

      const val = response.data.fetchpostdata;

      const posted = val.filter((data) => data.Approved > 0);
      setpostdetails(posted);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlerejected = async () => {
    try {
      setdisplay1(false);
      setdisplay2(false);
      setdisplay3(true);
      setdisplay4(false);
      setdisplay5(false);
      setUpdate(false);
      setAd_Details(false);
      setAD_rejected(true);
      setAD_Posted(false);
      const response = await axios.get(`${Nodeapi}/getpostdata1`);

      const val = response.data.fetchpostdata;

      const posted = val.filter((data) => data.Rejected > 0);
      setpostdetails(posted);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlepending = async () => {
    setdisplay1(false);
    setdisplay2(false);
    setdisplay3(false);
    setdisplay4(true);
    setdisplay5(false);
    setUpdate(true);
    setAd_Details(true);
    setAD_rejected(false);
    setAD_Posted(false);
    setAD_Total(false);
    const response = await axios.get(`${Nodeapi}/getpostdata1`);

    const val = response.data.fetchpostdata;
    const posted = val.filter((data) => data.pending > 0);
    setpostdetails(posted);
  };

  const handleUnpost = async () => {
    if (selectedData.length > 0) {
      setVisible(true);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Select the Posts",
        showConfirmButton: false,
        timer: 2000,
      });
      setdisplay(false);
    }
  };

  const accept = async () => {
    try {
      if (selectedData.length > 0) {
        const selectedIds = selectedData.map((card) => card.id);

        const response = await axios.post(`${Nodeapi}/unpostdisplay`, {
          selectedIds: selectedIds,
        });

        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Successfully UnPosted",
            showConfirmButton: false,
            timer: 2000,
          });
          setdisplay(true);
          setconfrim(false);
          fetchpostedcount();
          handleposted();
          fetchpendingcount();
        } else {
          console.error("Failed to update display for selected cards");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handlepostreject = async () => {
    try {
      if (selectedData.length > 0) {
        const selectedIds = selectedData.map((card) => card.id);

        const response = await axios.post(`${Nodeapi}/rejectedpost`, {
          selectedIds: selectedIds,
        });

        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Successfully Rejected",
            showConfirmButton: false,
            timer: 2000,
          });
          setdisplay(true);
          setadreject(false);
          setconfrim(false);
          fetchdata();
          fetchpostedcount();
          fetchrejectedcount();
          fetchpendingcount();
        } else {
          console.error("Failed to update display for selected cards");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUnreject = () => {
    if (selectedData.length > 0) {
      setVisible1(true);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Select the Posts",
        showConfirmButton: false,
        timer: 2000,
      });

      setdisplay(false);
    }
  };

  const accept1 = async () => {
    try {
      if (selectedData.length > 0) {
        const selectedIds = selectedData.map((card) => card.id);

        const response = await axios.post(`${Nodeapi}/Unrejectedpost`, {
          selectedIds: selectedIds,
        });

        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Successfully UnRejected",
            showConfirmButton: false,
            timer: 2000,
          });
          setdisplay(false);
          setconfrim(false);
          fetchrejectedcount();
          handlerejected();
          fetchpendingcount();
        } else {
          console.error("Failed to update display for selected cards");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const reject1 = () => {
    setVisible1(false);
  };

  const handlebackarrow = () => {
    fetchdata();
    setdisplay1(true);
    setdisplay2(false);
    setdisplay3(false);
    setdisplay4(false);
    setdisplay5(false);
    setAD_rejected(false);
    setAD_Posted(false);
    setAd_Details(true);
    setAD_Total(false);
  };

  const handleimage = async (data) => {
    const val = senddata[data];

    try {
      const res = await axios.get(
        `${Nodeapi}/getimagename?userid=${val.post_id}`
      );
      const data = res.data.result;
      const imageFileNames = data.map((image) => image.file_name);

      setlistimages(imageFileNames);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue); 
  };

  const formatPrice = (price) => {
    const number = Number(price);
    const priceStr = number.toString();

    if (priceStr.length > 7) {
      return priceStr.slice(0, 7) + "...";
    }
    return priceStr;
  };

  const truncateTitle = (title, maxLength = 20) => {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + "..."; // Truncate and append ellipsis
    }
    return title; // Return as is if within the limit
  };

  const truncateText = (text, maxLength = 10) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <>
      <div style={{ padding: "16px" }}>
        <h4 style={{ color: "#2196f3" }}>Advertisement Dashboard</h4>

        <div className="ad-Panel">
          <div className="row">
            <div className="col-3">
              <div className="ad-panel-card">
                <div className="top">
                  <h3>{totalads}</h3>
                  <p style={{ color: "#B5B5B5" }}>Total Ads</p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handletotalads}
                  className="bottom d-flex align-items-center justify-content-between"
                >
                  <p>View More</p>
                  <IoArrowForwardOutline />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="ad-panel-card">
                <div className="top">
                  <h3>{posted}</h3>
                  <p style={{ color: "#B5B5B5" }}>Posted</p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleposted}
                  className="bottom d-flex align-items-center justify-content-between"
                >
                  <p>View More</p>
                  <IoArrowForwardOutline />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="ad-panel-card">
                <div className="top">
                  <h3>{rejectedpost}</h3>
                  <p style={{ color: "#B5B5B5" }}>Rejected</p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handlerejected}
                  className="bottom d-flex align-items-center justify-content-between"
                >
                  <p>View More</p>
                  <IoArrowForwardOutline />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="ad-panel-card">
                <div className="top">
                  <h3>{pending}</h3>
                  <p style={{ color: "#B5B5B5" }}>Pending</p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handlepending}
                  className="bottom d-flex align-items-center justify-content-between"
                >
                  <p>View More</p>
                  <IoArrowForwardOutline />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card_ad4" style={{ marginTop: "37px" }}>
          <div className="adv-search-panel justify-content-between">
            {display1 && (
              <>
                <h4
                  style={{
                    marginLeft: "20px",
                    color: "#2196f3",
                    marginTop: "2px",
                  }}
                  className="mobileview"
                >
                  Users Ads
                </h4>
              </>
            )}
            {display2 && (
              <div style={{ display: "flex" }}>
                <IoMdArrowBack
                  className="back_arrow"
                  onClick={handlebackarrow}
                />
                <h4 style={{ color: "#2196f3", marginTop: "2px" }}>
                  Approved Ads
                </h4>
              </div>
            )}
            {display3 && (
              <div style={{ display: "flex" }}>
                <IoMdArrowBack
                  className="back_arrow"
                  onClick={handlebackarrow}
                />
                <h4 style={{ color: "#2196f3", marginTop: "2px" }}>
                  Rejected Ads
                </h4>
              </div>
            )}
            {display4 && (
              <div style={{ display: "flex" }}>
                <IoMdArrowBack
                  className="back_arrow"
                  onClick={handlebackarrow}
                />
                <h4 style={{ color: "#2196f3", marginTop: "2px" }}>
                  Pending Ads
                </h4>
              </div>
            )}
            {display5 && (
              <div style={{ display: "flex" }}>
                <IoMdArrowBack
                  className="back_arrow"
                  onClick={handlebackarrow}
                />
                <h4 style={{ color: "#2196f3", marginTop: "2px" }}>
                  Total Ads
                </h4>
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab value="blood-donation" label="Blood Donation" />
                  <Tab value="advertisement" label="Advertisement" />
                  <Tab value="lost-and-found" label="Lost and Found" />
                  <Tab value="cine-jobs" label="Cine Jobs" />
                </Tabs>
              </Box>

              {/* <input className="adv-search" placeholder="Search here" />
              <button className="adv-search-btn">
                <IoSearchOutline className="search-icon-adv" />
              </button> */}
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            {selectedTab === "advertisement" && (
              <DataTable
                value={postdetails}
                paginator
                rows={10}
                tableStyle={{ minWidth: "50rem" }}
                className="data"
              >
                <Column field="s_no" header="S.No" className="font-bold" />
                <Column
                  field="username"
                  header="User Name"
                  className="font-bold"
                  style={{ width: "200px" }}
                />
                <Column
                  field="kid_id"
                  header="KID ID"
                  style={{ width: "150px" }}
                />
                <Column field="total" header="Total Post" />
                <Column field="Approved" header="Approved" />
                <Column field="Rejected" header="Rejected" />
                <Column field="pending" header="Pending" />
                <Column
                  header="Advertisement"
                  body={(rowData) => (
                    <div>
                      {display1 && (
                        <div>
                          <button
                            className="view_btn"
                            onClick={() => handleViewAdvertisement(rowData)}
                          >
                            View
                          </button>
                        </div>
                      )}
                      {display2 && (
                        <div>
                          <button
                            className="view_btn"
                            onClick={() => handleViewPostedAds(rowData)}
                          >
                            View
                          </button>
                        </div>
                      )}
                      {display3 && (
                        <div>
                          <button
                            className="view_btn"
                            onClick={() => handleViewRejectedAds(rowData)}
                          >
                            View
                          </button>
                        </div>
                      )}
                      {display4 && (
                        <div>
                          <button
                            className="view_btn"
                            onClick={() => handleViewPendingAds(rowData)}
                          >
                            View
                          </button>
                        </div>
                      )}
                      {display5 && (
                        <div>
                          <button
                            className="view_btn"
                            onClick={() => handleViewTotalAds(rowData)}
                          >
                            View
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                />
              </DataTable>
            )}
            {selectedTab === "lost-and-found" && (
              <DataTable
                value={LostAndFoundData}
                paginator
                rows={10}
                tableStyle={{ minWidth: "50rem" }}
                className="data"
              >
               <Column field="s_no" header="S.No" className="font-bold" />
                <Column
                  field="username"
                  header="User Name"
                  className="font-bold"
                  style={{ width: "200px" }}
                />
                <Column
                  field="kid_id"
                  header="KID ID"
                  style={{ width: "150px" }}
                />
                <Column field="total" header="Total Post" />
                <Column field="approved" header="Approved" />
                <Column field="rejected" header="Rejected" />
                <Column field="pending" header="Pending" />
                <Column
                  header="Advertisement"
                  // body={(rowData) => (
                  //   <div>
                  //     {display1 && (
                  //       <div>
                  //         <button
                  //           className="view_btn"
                  //           onClick={() => handleViewAdvertisement(rowData)}
                  //         >
                  //           View
                  //         </button>
                  //       </div>
                  //     )}
                  //     {display2 && (
                  //       <div>
                  //         <button
                  //           className="view_btn"
                  //           onClick={() => handleViewPostedAds(rowData)}
                  //         >
                  //           View
                  //         </button>
                  //       </div>
                  //     )}
                  //     {display3 && (
                  //       <div>
                  //         <button
                  //           className="view_btn"
                  //           onClick={() => handleViewRejectedAds(rowData)}
                  //         >
                  //           View
                  //         </button>
                  //       </div>
                  //     )}
                  //     {display4 && (
                  //       <div>
                  //         <button
                  //           className="view_btn"
                  //           onClick={() => handleViewPendingAds(rowData)}
                  //         >
                  //           View
                  //         </button>
                  //       </div>
                  //     )}
                  //     {display5 && (
                  //       <div>
                  //         <button
                  //           className="view_btn"
                  //           onClick={() => handleViewTotalAds(rowData)}
                  //         >
                  //           View
                  //         </button>
                  //       </div>
                  //     )}
                  //   </div>
                  // )}
                />
              </DataTable>
            )}
          </div>
        </div>

        {senddata.length === 1 ? (
          <Dialog
            header={senddata[0]?.username || "User"}
            visible={display}
            style={{ width: "22vw" }}
            breakpoints={{ "1280px": "40vw", "641px": "100vw" }}
            onHide={() => setdisplay(false)}
          >
            <Grid container spacing={2}>
              {senddata?.map((listing, index) => (
                <Grid key={index} onClick={()=>setdisplay(false)}>
                  <div
                    className="adpost_card"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleBookmarkClick(index);
                      handleimage(index);
                    }}
                  >
                    <div className="ad-card-heart">
                      <div
                        style={{
                          backgroundColor: "#E6F2FC",
                          padding: "15px",
                          borderRadius: "10px 10px 0 0",
                        }}
                      >
                        <AutoPlaySwipeableViews
                          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                          enableMouseEvents
                        >
                          {listing?.images.map((step, index) => (
                            <div key={step.label}>
                              {Math.abs(activeStep - index) <= 10 ? (
                                <Box
                                  component="img"
                                  sx={{
                                    height: 180,
                                    display: "block",
                                    maxWidth: 400,
                                    overflow: "hidden",
                                    width: "100%",
                                    borderRadius: 2,
                                  }}
                                  src={`${Assetsapi}/adimages/${step}`}
                                  alt={`House ${index + 1}`}
                                />
                              ) : null}
                            </div>
                          ))}
                        </AutoPlaySwipeableViews>
                      </div>
                    </div>
                    <div style={{ padding: "15px" }}>
                      <p
                        className="title"
                        style={{
                          marginTop: "8px",
                          textTransform: "capitalize",
                        }}
                      >
                        {truncateTitle(listing.post_title)}
                      </p>

                      <p
                        className="card-desp"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          textTransform: "capitalize",
                        }}
                      >
                        {listing.post_description}
                      </p>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{
                          borderBottom: "1px solid #E6E6E6",
                          paddingBottom: "12px",
                          marginTop: "16px",
                        }}
                      >
                        <p className="card-price">
                          {listing.salaryCurrency}
                          {formatPrice(listing.post_price)}
                        </p>
                        {/* {Ad_Details && (
                            <Link to={'/main/View_CardDetails'}>
                              <button onClick={() => { handleBookmarkClick(index); handleimage(index); }} className="btn-view">View Details</button>
                            </Link>
                          )}  */}

                        {AD_rejected && (
                          <Link to={"/main/View_CardDetails"}>
                            <button
                              onClick={() => {
                                handleBookmarkClick(index);
                                handleimage(index);
                              }}
                              className="btn-view-reject"
                            >
                              Rejected
                            </button>
                          </Link>
                        )}

                        {AD_Posted && (
                          <Link to={"/main/View_CardDetails"}>
                            <button
                              onClick={() => {
                                handleBookmarkClick(index);
                                handleimage(index);
                              }}
                              className="btn-view-post"
                            >
                              Approved
                            </button>
                          </Link>
                        )}

                        {AD_Total &&
                          approvedAds.length > 0 &&
                          approvedAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-post"
                                  >
                                    Approved Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}

                        {AD_Total &&
                          rejecteddAds.length > 0 &&
                          rejecteddAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-reject"
                                  >
                                    Rejected Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}

                        {AD_Total &&
                          pendingAds.length > 0 &&
                          pendingAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-pending"
                                  >
                                    Pending Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-1">
                        <p className="botm-text">
                          <CiLocationOn
                            style={{
                              color: "#8f8f8f",
                              textTransform: "capitalize",
                            }}
                          />
                          <span className="icons ms-1">
                            {truncateText(listing.post_location)}
                          </span>
                        </p>
                        {listing.created_at && (
                          <p className="botm-text">
                            <IoCalendarOutline style={{ color: "#8f8f8f" }} />
                            <span className="icons ms-1">
                              {new Date(listing.created_at).toLocaleDateString(
                                "en-CA"
                              )}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Dialog>
        ) : senddata.length === 2 ? (
          <Dialog
            header={senddata[0]?.username || "User"}
            visible={display}
            style={{ width: "41vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            onHide={() => setdisplay(false)}
          >
            <Grid container spacing={2}>
              {senddata?.map((listing, index) => (
                <Grid key={index} xs={12} sm={6} onClick={()=>setdisplay(false)}>
                  <div
                    className="adpost_card"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleBookmarkClick(index);
                      handleimage(index);
                    }}
                  >
                    <div className="ad-card-heart">
                      <div
                        style={{
                          backgroundColor: "#E6F2FC",
                          padding: "15px",
                          borderRadius: "10px 10px 0 0",
                        }}
                      >
                        <AutoPlaySwipeableViews
                          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                          enableMouseEvents
                        >
                          {listing.images.map((step, index) => (
                            <div key={step.label}>
                              {Math.abs(activeStep - index) <= 10 ? (
                                <Box
                                  component="img"
                                  sx={{
                                    height: 180,
                                    display: "block",
                                    maxWidth: 400,
                                    overflow: "hidden",
                                    width: "100%",
                                    borderRadius: 2,
                                  }}
                                  src={`${Assetsapi}/adimages/${step}`}
                                  alt={`House ${index + 1}`}
                                />
                              ) : null}
                            </div>
                          ))}
                        </AutoPlaySwipeableViews>
                      </div>
                    </div>
                    <div style={{ padding: "15px" }}>
                      <p
                        className="title"
                        style={{
                          marginTop: "8px",
                          textTransform: "capitalize",
                        }}
                      >
                        {truncateTitle(listing.post_title)}
                      </p>

                      <p
                        className="card-desp"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          textTransform: "capitalize",
                        }}
                      >
                        {listing.post_description}
                      </p>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{
                          borderBottom: "1px solid #E6E6E6",
                          paddingBottom: "12px",
                          marginTop: "16px",
                        }}
                      >
                        <p className="card-price">
                          {" "}
                          {listing.salaryCurrency}
                          {formatPrice(listing.post_price)}
                        </p>
                        {/* {Ad_Details && (
                            <Link to={'/main/View_CardDetails'}>
                              <button onClick={() => { handleBookmarkClick(index); handleimage(index); }} className="btn-view">View Details</button>
                            </Link>
                          )} */}

                        {AD_rejected && (
                          <Link to={"/main/View_CardDetails"}>
                            <button
                              onClick={() => {
                                handleBookmarkClick(index);
                                handleimage(index);
                              }}
                              className="btn-view-reject"
                            >
                              Rejected
                            </button>
                          </Link>
                        )}

                        {AD_Posted && (
                          <Link to={"/main/View_CardDetails"}>
                            <button
                              onClick={() => {
                                handleBookmarkClick(index);
                                handleimage(index);
                              }}
                              className="btn-view-post"
                            >
                              Approved
                            </button>
                          </Link>
                        )}

                        {AD_Total &&
                          approvedAds.length > 0 &&
                          approvedAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-post"
                                  >
                                    Approved Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}

                        {AD_Total &&
                          rejecteddAds.length > 0 &&
                          rejecteddAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-reject"
                                  >
                                    Rejected Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}

                        {AD_Total &&
                          pendingAds.length > 0 &&
                          pendingAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-pending"
                                  >
                                    Pending Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-1">
                        <p className="botm-text">
                          <CiLocationOn
                            style={{
                              color: "#8f8f8f",
                              textTransform: "capitalize",
                            }}
                          />
                          <span className="icons ms-1">
                            {truncateText(listing.post_location)}
                          </span>
                        </p>
                        {listing.created_at && (
                          <p className="botm-text">
                            <IoCalendarOutline style={{ color: "#8f8f8f" }} />
                            <span className="icons ms-1">
                              {new Date(listing.created_at).toLocaleDateString(
                                "en-CA"
                              )}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Dialog>
        ) : senddata.length > 2 ? (
          <Dialog
            header={senddata[0]?.username || "User"}
            visible={display}
            style={{ width: "60vw " }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            onHide={() => setdisplay(false)}
          >
            <Grid container spacing={2}>
              {senddata?.map((listing, index) => (
                <Grid key={index} xs={12} sm={6} md={4} onClick={()=>setdisplay(false)}>
                  <div
                    className="adpost_card"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleBookmarkClick(index);
                      handleimage(index);
                    }}
                  >
                    <div className="ad-card-heart">
                      <div
                        style={{
                          backgroundColor: "#E6F2FC",
                          padding: "15px",
                          borderRadius: "10px 10px 0 0",
                        }}
                      >
                        {/* <img
                        src={Images[index % Images.length]}
                        alt={`Image ${index + 1}`}
                        className="card-image img-fluid"
                        style={{
                          // width: "17.6rem",
                          borderRadius: "8px",
                          // marginBottom: "12px",
                          width: "100%",
                          height: "10rem",
                        }}
                      /> */}
                        <AutoPlaySwipeableViews
                          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                          // index={activeStep}
                          // onChangeIndex={handleStepChange}
                          enableMouseEvents
                        >
                          {listing.images.map((step, index) => (
                            <div key={step.label}>
                              {Math.abs(activeStep - index) <= 10 ? (
                                <Box
                                  component="img"
                                  sx={{
                                    height: 180,
                                    display: "block",
                                    maxWidth: 400,
                                    overflow: "hidden",
                                    width: "100%",
                                    borderRadius: 2,
                                  }}
                                  src={`${Assetsapi}/adimages/${step}`}
                                  alt={`House ${index + 1}`}
                                />
                              ) : null}
                            </div>
                          ))}
                        </AutoPlaySwipeableViews>
                      </div>
                    </div>
                    <div style={{ padding: "15px" }}>
                      <p
                        className="title"
                        style={{
                          marginTop: "8px",
                          textTransform: "capitalize",
                        }}
                      >
                        {truncateTitle(listing.post_title)}
                      </p>

                      <p
                        className="card-desp"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          textTransform: "capitalize",
                        }}
                      >
                        {listing.post_description}
                      </p>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{
                          borderBottom: "1px solid #E6E6E6",
                          paddingBottom: "12px",
                          marginTop: "16px",
                        }}
                      >
                        <p className="card-price">
                          {" "}
                          {listing.salaryCurrency}
                          {formatPrice(listing.post_price)}
                        </p>
                        {/* {Ad_Details && (
                            <Link to={'/main/View_CardDetails'}>
                              <button onClick={() => { handleBookmarkClick(index); handleimage(index); }} className="btn-view">View Details</button>
                            </Link>
                          )} */}

                        {AD_rejected && (
                          <Link to={"/main/View_CardDetails"}>
                            <button
                              onClick={() => {
                                handleBookmarkClick(index);
                                handleimage(index);
                              }}
                              className="btn-view-reject"
                            >
                              Rejected
                            </button>
                          </Link>
                        )}

                        {AD_Posted && (
                          <Link to={"/main/View_CardDetails"}>
                            <button
                              onClick={() => {
                                handleBookmarkClick(index);
                                handleimage(index);
                              }}
                              className="btn-view-post"
                            >
                              Approved
                            </button>
                          </Link>
                        )}

                        {AD_Total &&
                          approvedAds.length > 0 &&
                          approvedAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-post"
                                  >
                                    Approved Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}
                        {AD_Total &&
                          rejecteddAds.length > 0 &&
                          rejecteddAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-reject"
                                  >
                                    Rejected Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}

                        {AD_Total &&
                          pendingAds.length > 0 &&
                          pendingAds.map((ad, index) => (
                            <div key={index}>
                              {ad.id === listing.id && (
                                <Link to={"/main/View_CardDetails"}>
                                  <button
                                    onClick={() => {
                                      handleBookmarkClick(index);
                                      handleimage(index);
                                    }}
                                    className="btn-view-pending"
                                  >
                                    Pending Ad
                                  </button>
                                </Link>
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-1">
                        <p className="botm-text">
                          <CiLocationOn
                            style={{
                              color: "#8f8f8f",
                              textTransform: "capitalize",
                            }}
                          />
                          <span className="icons ms-1">
                            {truncateText(listing.post_location)}
                          </span>
                        </p>
                        {listing.created_at && (
                          <p className="botm-text">
                            <IoCalendarOutline style={{ color: "#8f8f8f" }} />
                            <span className="icons ms-1">
                              {new Date(listing.created_at).toLocaleDateString(
                                "en-CA"
                              )}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Dialog>
        ) : null}

        <Modal open={confrim} onClose={() => setconfrim(false)}>
          <Box sx={style} style={{ width: "303px" }}>
            <div
              className="justify-content-between"
              style={{ display: "flex" }}
            >
              <h6 className="mb-2">Confirmation:</h6>
              <IoCloseSharp
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={() => setconfrim(false)}
              />
            </div>

            <p>Are you sure you want to post?</p>
            <button className="reject_btn" onClick={handlreject}>
              Reject
            </button>
            <button className="post_btn" onClick={handlepost}>
              Post
            </button>
          </Box>
        </Modal>

        <Modal open={adreject} onClose={() => setadreject(false)}>
          <Box sx={style} style={{ width: "370px" }}>
            <p style={{ marginTop: "9px" }}>Reason ?</p>

            <textarea className="text_area"></textarea>

            <button onClick={handlepostreject} className="rej_sub_bt">
              Submit
            </button>

            <button onClick={handlecancel} className="rej_cancel_btn">
              Cancel
            </button>
          </Box>
        </Modal>

        <ConfirmDialog
          group="declarative"
          visible={visible}
          onHide={() => setVisible(false)}
          message="Are you sure you want to UnPost?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          accept={accept}
          reject={reject}
        />

        <ConfirmDialog
          group="declarative"
          visible={visible1}
          onHide={() => setVisible1(false)}
          message="Are you sure you want to UnReject?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          accept={accept1}
          reject={reject1}
        />
      </div>
    </>
  );
}

export default Advertisment_Panel;
