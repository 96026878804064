import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { IoShareSocialOutline } from "react-icons/io5";
import VehicleGallery from "./VehicleGallery";
import { TiArrowForwardOutline } from "react-icons/ti";
import { IoMdHeartEmpty, IoMdPin } from "react-icons/io";
import "./VehicleOverview.scss";
import { GoDotFill } from "react-icons/go";
import { TbFileImport } from "react-icons/tb";
import { BiPhoneCall, BiSolidFile } from "react-icons/bi";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { PiChatCircleTextDuotone } from "react-icons/pi";
import VehicleOverviewCard from "./VehicleOverviewCard";
import VehicleOverviewReport from "./VehicleOverviewReport";
import VehicleSimilarCard from "./VehicleSimilarCard";
import { DataContext } from "../../../Admin/DataContext";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Nodeapi } from "../../../config/serverUrl";
import { useParams } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import "../../Subcription/Billing.css";

const VehicleOverview = () => {
  const [posts, setPosts] = useState([]);
  const authdata = useSelector((state) => state.auth.user);
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    view_adpost,
    sethome_listimages,
    setview_adpost,
    setdatavalue,
    datavalue,
    settitle,
    title,
    description,
    setdescription,
    place,
    setplace,
    setPrice,
    Price,
    fetchdata,
    loading,
    setData,
    divRefs,
    setShow,
    setJoinchatmaster,
    back_btn,
    setback_btn,
  } = useContext(DataContext);

  const formattedPrice = (price) => {
    return new Intl.NumberFormat("en-IN").format(price);
  };

  const handleMessage = async () => {
    const res = await axios.post(`${Nodeapi}/joinchatmaster`, {
      user_id: authdata?.id,
      receiver_id: id,
      type: "product_cart",
    });

    if (res.data) {
      if (res.data.code == 200) {
        if (divRefs.current[id]) {
          divRefs.current[id].click();
        }
      } else {
        setData((prevList) => {
          return [...res.data.data.data, ...prevList];
        });
      }
    }
    navigate("/main/chat");

    setTimeout(() => {
      if (divRefs.current[id]) {
        divRefs.current[id].click();
      }
    }, 1000);
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const getpostdetails = async () => {
    try {
      const res = await axios.get(`${Nodeapi}/getdisplaypost`);
      const valuedata = res.data.getdata;
      setPosts(valuedata);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpostdetails();
  }, []);

  const filterPost = posts.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const postId = parseInt(id);
  const post = filterPost.find((post) => post.id === postId);

  const currentIndex = posts.findIndex((post) => post.id === postId);
  const previousIndex = currentIndex > 0 ? currentIndex - 1 : null;
  const nextIndex = currentIndex < posts.length - 1 ? currentIndex + 1 : null;

  const handleBack = () => {
    if (previousIndex !== null) {
      navigate(`/main/VehicleOverview/${posts[previousIndex].id}`);
    }
  };

  const handleNext = () => {
    if (nextIndex !== null) {
      navigate(`/main/VehicleOverview/${posts[nextIndex].id}`);
    }
  };

  const handleback = () => {
    navigate("/");
    setview_adpost([]);
  };
  const handlebacktoacitivity = () => {
    navigate("/main/Activity");
    setview_adpost([]);
    setback_btn(false);
  };

  const handleContact = async () => {
    const kidid = datavalue[0]?.kid_id;
    const image_id = datavalue[0].post_id;
    const postid = datavalue[0].id;
    const today = new Date().toISOString().split("T")[0];
    console.log(kidid, image_id, postid);

    const res = await axios.post(`${Nodeapi}/Contact_view`, {
      kidid: kidid,
      image_id: image_id,
      postid: postid,
      contactdate: today,
    });

    const data = res.data;
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Check this out!',
          text: 'Have a look at this amazing content!',
          url: window.location.href,
        });
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <div className="VehicleOverview">
      {back_btn ? (
        <div className="mt-2" style={{ marginLeft: "2%" }}>
          <button
            style={{ border: "none", background: "none" }}
            onClick={handlebacktoacitivity}
          >
            <MdOutlineKeyboardArrowLeft
              style={{ fontSize: "28px", marginTop: "-8%" }}
            />
            <span style={{ fontSize: "20px", marginLeft: "-5px" }}>Back</span>
          </button>
        </div>
      ) : (
        <div
          className="mt-2"
          style={{
            marginRight: "2rem",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            style={{ border: "none", background: "none" }}
            onClick={handleBack}
            disabled={previousIndex === null}
          >
            <MdKeyboardDoubleArrowLeft
              style={{ fontSize: "22px", marginTop: "-8%" }}
            />
          </button>
          <button
            style={{ border: "none", background: "none" }}
            onClick={handleback}
          >
            <IoHomeOutline style={{ fontSize: "22px", marginTop: "-8%" }} />
          </button>
          <button
            style={{ border: "none", background: "none" }}
            onClick={handleNext}
            disabled={nextIndex === null}
          >
            <MdKeyboardDoubleArrowRight
              style={{ fontSize: "22px", marginTop: "-8%" }}
            />
          </button>
        </div>
      )}

      {loading ? (
        <div className="spinner-container">
          <div className="spin"></div>
        </div>
      ) : (
        <Grid className="mt-0" container spacing={2}>
          <Grid item xs={12} sm={8}>
            <VehicleGallery datavalue={[post]} />
            <div
              className="VehiDesciption mt-4"
              style={{ padding: "0px 20px" }}
            >
              <div className="card">
                <h4>Description</h4>
                <p>{post?.post_description}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="vehiGal_right">
              <div className="card">
                <div className="flex align-items-center justify-content-between mb-2">
                  <h5>{post?.post_title}</h5>
                  <div className="vehigal_buttons flex align-items-center justify-content-between">
                    <p onClick={handleShare}>
                      <IoShareSocialOutline className="vehibut-icon" />
                    </p>
                    <p>
                      <TiArrowForwardOutline className="vehibut-icon" />
                    </p>
                    <p>
                      <IoMdHeartEmpty className="vehibut-icon" />
                    </p>
                  </div>
                </div>
                <div className="vehimodal"></div>
                <div className="vehikms"></div>
                <div className="vehiloco">
                  <p>
                    <IoMdPin />
                    <span>{post?.post_location}</span>
                  </p>
                </div>
                <div className="vehiprice">
                  <p>
                    <MdOutlineCurrencyRupee />
                    <span>{formattedPrice(post?.post_price)}</span>
                  </p>
                </div>
                <div className="vehibutons2">
                  <button
                    className="vheibtn1"
                    onClick={() => {
                      handleMessage();
                      handleContact();
                    }}
                  >
                    <BiPhoneCall />
                    <span>Contact</span>
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <div className="VehiclesimilarCard">
        <VehicleSimilarCard />
      </div>
    </div>
  );
};

export default VehicleOverview;
