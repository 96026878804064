import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  movieGenres,
  streamPlatform,
  indianLanguages,
  typeofCertificate,
} from "./option.js";
import { Box, Button, Modal, IconButton } from "@mui/material";
import { Calendar } from "primereact/calendar";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import {
  MdDriveFolderUpload,
  MdRemoveCircleOutline,
  CiCircleRemove,
} from "react-icons/md";
import { Nodeapi, Assetsapi } from "../../config/serverUrl";

function CineModal(prop) {
  const {
    open,
    handleClose,
    documentation,
    isEdit,
    handleChange,
    releaseDate,
    handleDateChange,
    genre,
    handleChangeGenre,
    MenuProps,
    getStyles,
    theme,
    stream,
    handleChangeStream,
    time,
    handleTimeChange,
    language,
    handleChangeLanguage,
    imageUploadSection,
    addmorimage,
    listimages,
    additionalImages,
    handleuploadRemove,
    handleSubmit,
    buttonvalue,
    isSubmitting,
  } = prop;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          border: "0px",
          boxShadow: 24,
          borderRadius: "12px",
          p: 4,
          maxHeight: "90vh",
          margin: "8px",
          overflowY: "auto",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", top: 8, right: 12, padding: "25px" }}
        >
          <CloseIcon />
        </IconButton>
        <h6 className="mb-2">{isEdit ? "Edit" : "Add"} Movie</h6>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%", marginRight: "10px" }}>
            <label className="form-label labels mt-3">Movie Name</label>
            <input
              placeholder="Enter Movie Name"
              type="text"
              id="price"
              className="form-control"
              name="title"
              value={documentation.title}
              onChange={handleChange}
              required
            />
          </div>

          <div style={{ width: "50%" }}>
            <label className="form-label labels mt-3">Release Date</label>
            <input
              id="monthdate"
              type="date"
              className="w-full input2"
              name="releaseDate"
              value={releaseDate}
              style={{
                width: "100%",
                height: "37px",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
                border: "none",
                outline: "2px solid #4a90e2",
                outlineOffset: "0px",
              }}
              onChange={handleDateChange}
              required
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "60%", marginRight: "10px" }}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <label
                style={{ width: "10px" }}
                className="form-label labels mt-3"
              >
                Genre
              </label>
            </div>

            <div style={{ marginTop: "-7px" }}>
              <FormControl sx={{ m: 1, width: 250 }}>
                <InputLabel
                  id="demo-multiple-chip-label"
                  style={{ marginTop: "-5px" }}
                ></InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={genre}
                  onChange={handleChangeGenre}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label=""
                      sx={{ height: 38, overflow: "hidden" }}
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.length > 2 ? (
                        <>
                          {selected.slice(0, 2).map((value, idx) => (
                            <Chip key={idx} label={value} />
                          ))}
                          <Chip label={"..."} />
                        </>
                      ) : (
                        selected.map((value, idx) => (
                          <Chip key={idx} label={value} />
                        ))
                      )}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {movieGenres.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, genre, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ width: "80%" }}>
            <label className="form-label labels mt-3">Trailer URL</label>
            <input
              placeholder="Enter Trailer URL"
              type="text"
              id="whom"
              className="form-control"
              name="trailerUrl"
              value={documentation.trailerUrl}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%", marginRight: "10px" }}>
            <label className="form-label labels mt-3">Director</label>
            <input
              placeholder="Enter Director name"
              type="text"
              id="whom"
              className="form-control"
              name="director"
              value={documentation.director}
              onChange={handleChange}
              required
            />
          </div>
          <div style={{ width: "50%" }}>
            <label className="form-label labels mt-3">Music Director</label>
            <input
              placeholder="Enter Music Director name"
              type="text"
              id="whom"
              className="form-control"
              name="musicDirector"
              value={documentation.musicDirector}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%", marginRight: "10px" }}>
            <label className="form-label labels mt-3">Writer</label>
            <input
              placeholder="Enter Writer name"
              type="text"
              id="whom"
              className="form-control"
              name="writer"
              value={documentation.writer}
              onChange={handleChange}
              required
            />
          </div>
          <div style={{ width: "50%" }}>
            <label className="form-label labels mt-3">Certified For</label>
            <select
              id="expenseName"
              className="form-select col-md-4 form-control"
              name="certificate"
              value={documentation.certificate}
              onChange={handleChange}
            >
              <option value="">Enter Certification type</option>
              {typeofCertificate.map((expense, index) => (
                <option key={index} value={expense}>
                  {expense}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%", marginRight: "10px" }}>
            <label className="form-label labels mt-3">Producer</label>
            <input
              placeholder="Enter Producer name"
              type="text"
              id="whom"
              className="form-control"
              name="producer"
              value={documentation.producer}
              onChange={handleChange}
              required
            />
          </div>
          <div style={{ width: "50%" }}>
            <label className="form-label labels mt-3">Production Company</label>
            <input
              placeholder="Enter Production Company"
              type="text"
              id="whom"
              className="form-control"
              name="productionCompany"
              value={documentation.productionCompany}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%", marginRight: "10px" }}>
            <label className="form-label labels mt-3">Place</label>
            <input
              placeholder="Enter Area name"
              type="text"
              id="whom"
              className="form-control"
              name="place"
              value={documentation.place}
              onChange={handleChange}
              required
            />
          </div>
          <div style={{ width: "50%" }}>
            <label className="form-label labels mt-3">Theatre Name</label>
            <input
              placeholder="Enter Production Company"
              type="text"
              id="whom"
              className="form-control"
              name="theatre"
              value={documentation.theatre}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div style={{ width: "49%", marginTop: "10px" }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "80%", marginRight: "10px" }}>
            <label className="form-label labels mt-3">Streaming Platform</label>
            <div style={{ marginTop: "-7px", marginLeft: "-5px" }}>
              <FormControl sx={{ m: 1, width: 250 }}>
                <InputLabel
                  id="demo-multiple-chip-label"
                  style={{ marginTop: "-5px" }}
                ></InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={stream}
                  onChange={handleChangeStream}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label=""
                      sx={{ height: 40, overflow: "hidden" }}
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.length > 1 ? (
                        <>
                          {selected.slice(0, 1).map((value, idx) => (
                            <Chip key={idx} label={value} />
                          ))}
                          <Chip label={"hai how"} />
                        </>
                      ) : (
                        selected.map((value, idx) => (
                          <Chip key={idx} label={value} />
                        ))
                      )}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {streamPlatform
                    .sort((a, b) => a.localeCompare(b))
                    .map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, stream, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="form-label labels mt-3">Run Time</label>
            <Calendar
              value={time}
              onChange={handleTimeChange}
              timeOnly
              placeholder="Enter Run Time"
              style={{ marginLeft: "-9px" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "80%", marginRight: "10px" }}>
            <label className="form-label labels mt-3">Language</label>
            <div style={{ marginTop: "-7px", marginLeft: "-5px" }}>
              <FormControl sx={{ m: 1, width: 250 }}>
                <InputLabel
                  id="demo-multiple-chip-label"
                  style={{ marginTop: "-5px" }}
                ></InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={language}
                  onChange={handleChangeLanguage}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label=""
                      sx={{ height: 40, overflow: "hidden" }}
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.length > 2 ? (
                        <>
                          {selected.slice(0, 2).map((value, idx) => (
                            <Chip key={idx} label={value} />
                          ))}
                          <Chip label={"..."} />
                        </>
                      ) : (
                        selected.map((value, idx) => (
                          <Chip key={idx} label={value} />
                        ))
                      )}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {indianLanguages
                    .sort((a, b) => a.localeCompare(b))
                    .map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, stream, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ width: "80%" }}>
            <label className="form-label labels mt-3">Performing Artists</label>
            <input
              placeholder="Enter Performing Artists"
              type="text"
              id="whom"
              className="form-control"
              name="performingArtist"
              value={documentation.performingArtist}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <label className="form-label labels mt-3">Synopsis</label>
          <input
            placeholder="Enter Synopsis"
            type="text"
            id="whom"
            className="form-control"
            name="synopsis"
            value={documentation.synopsis}
            onChange={handleChange}
            required
          />
        </div>
        <div className="">
          <label className="form-label labels mt-2" htmlFor="issueDate">
            Upload Images
          </label>
        </div>
        {!isEdit && imageUploadSection}
        {isEdit && addmorimage}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {[...listimages, ...additionalImages].map((imageObj, index) => {
            if (imageObj.image_url == undefined) return;
            return (
              <div
                key={index}
                className="upload-card m-2 px-2"
                style={{ position: "relative" }}
              >
                {console.log(imageObj.image_url)}
                <img
                  src={`${Assetsapi}/adimages/${imageObj.image_url}`}
                  alt={`Uploaded ${index + 1}`}
                  style={{
                    width: "7rem",
                    height: "7rem",
                    borderRadius: "10px",
                  }}
                />
                <MdRemoveCircleOutline
                  onClick={() => handleuploadRemove(index, listimages[index])}
                  style={{
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "14px",
                    cursor: "pointer",
                    borderRadius: "50%",
                    fontSize: "1.3rem",
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="text-center mt-4">
          <button className="btn btn-danger me-2" onClick={handleClose}>
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            value={buttonvalue}
            disabled={isSubmitting}
          >
            {isSubmitting ? "submitting" : buttonvalue}
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default CineModal;
