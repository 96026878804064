import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { IoCalendarOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import "../AdDashboard.css";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import { Nodeapi, Assetsapi } from "../../../config/serverUrl";
import data from "../../home.json";
import { DataContext } from "../../../Admin/DataContext";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const listings = [
  {
    category: "Property",
    title: "Office Space",
    action: "Buy",
    description:
      "Commercial office space with fully furnished plug and play, conference hall, reception, meeting .....",
    price: "10 Lakhs",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "Rectangle 85.png",
  },
  {
    category: "Property",
    title: "Luxury beach house - 6BHK",
    action: "Rent",
    description:
      "Nestled along the sun-kissed shores, our luxury beach house embodies elegance and its finest .....",
    price: "4 Crore",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "image 1.png",
  },
  {
    category: "Vehicle",
    title: "Jeep Wrangler Rubicon ",
    action: "Buy",
    description:
      "Wrangler Rubicon with Automatic 8 gear, manual override, sport mode 4 valves /cylinder DOHC .....",
    price: "50 Lakhs",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "Rectangle 100.png",
  },
  {
    category: "Vehicle",
    title: "Audi Q3 30TDI Premium plus",
    action: "Buy",
    description:
      "Audi Q3 specifications with V6 diesel engine common rail injection, exhaust gas turbocharge....",
    price: "23 Lakhs",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "Rectangle 96.png",
  },
  {
    category: "Job",
    title: "ASV - Physics Educational Teacher",
    action: "Seeking",
    description:
      "Teaching Physic education  subjects to senior classes, and guide students to laboratory work ....",
    price: "10 Lakhs",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "Rectangle 98.png",
  },
  {
    category: "Property",
    title: "Agricultural Land - 2 Acres",
    action: "Lease",
    description:
      "Automatic Climate Control, 2 Airbags, Alloy Wheel Security system, Good Battery Condition",
    price: "4 Crore",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "image_Agri.png",
  },
  {
    category: "Property",
    title: "A1 apartments residential flat - 2 BHK  ",
    action: "Lease",
    description:
      "2BHK, 1800 sq ft spacious ground floor raised well above ground house with metro water supply.....",
    price: "4 Crore",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "image_home.png",
  },
  {
    category: "Vechile",
    title: "JCB 3DX",
    action: "Buy",
    description:
      "Loaded with state of art technology inherited from the Expert range, is built to take in on multiple.....",
    price: "4 Crore",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "Rectangle 93.png",
  },
  {
    category: "Property",
    title: "Global Office Space",
    action: "Lease",
    description:
      "Commercial office space with fully furnished plug and play, conference hall, reception, meeting .....",
    price: "4 Crore",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "Rectangle 86.png",
  },

  {
    category: "Property",
    title: "Luxury beach house - 6BHK",
    action: "Lease",
    description:
      "Nestled along the sun-kissed shores, our luxury beach house embodies elegance and its finest .....",
    price: "4 Crore",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "Residential2.png",
  },
  {
    category: "Property",
    title: "A1 apartments residential flat -2 BHK ",
    action: "Rent",
    description:
      "Automatic Climate Control, 2 Airbags, Alloy Wheel Security system, Good Battery Condition",
    price: "28,000/M",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "Rectangle 86.png",
  },
  {
    category: "Vechile",
    title: "Ather 450 Apex",
    action: "Buy",
    description:
      "Ather 450X electric scooter is a machine that personifies pure performance, futuristic range....",
    price: "80,000",
    date: "18th March 2024",
    location: "Madipakkam",
    image: "image 5.png",
  },
];

const LivePost = ({
  listvechicles,
  display,
  properties,
  typevalue,
  display1,
}) => {
  const authdata = useSelector((state) => state.auth.user);
  const Navigate = useNavigate();
  const userId = authdata?.id;
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const theme = useTheme();
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectedIndices1, setSelectedIndices1] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const {
    // setdisplaypost,
    // displaypost,
    setview_adpost,
    view_adpost,
    logoutdata,
    sethome_listimages,
    setaddfavorites,
    addfavorites,
    setcategory,
    setpostiddata,
  } = useContext(DataContext);
  const [displaypost, setdisplaypost] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bookmarkStates, setBookmarkStates] = useState(
    Array(displaypost.length).fill(false)
  );
  const [selectfavorite, setselectfavorite] = useState([]);
  const admin_value = authdata?.isAdmin;
  const [value, setvalue] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (display) {
      setvalue(listvechicles);
    } else {
      {
        admin_value ? setvalue(displaypost) : setvalue(listings);
      }
    }
  }, [listvechicles, display]);

  useEffect(() => {
    if (authdata) {
      const fetchdata = async () => {
        const res = await axios.get(
          `${Nodeapi}/getLikedAds?id=${authdata.userid}`
        );
        const likedAds = res.data.getdata;
        const newAddfavorites = [...addfavorites];
        const newSelectedIndices1 = [];
        const newSelectfavorite = [];

        likedAds.forEach((post, index) => {
          if (post.Save_data == 1) {
            newAddfavorites[index] = true;
            newSelectedIndices1.push(index);
            newSelectfavorite.push(post);
          }
        });
        setaddfavorites(newAddfavorites);
        setSelectedIndices1(newSelectedIndices1);
        setselectfavorite(newSelectfavorite);
      };
      fetchdata();
    }
  }, [displaypost, Nodeapi]);

  useEffect(() => {
    const loadImages = async () => {
      const loadedImages = await Promise.all(
        listings.map(async (listing) => {
          try {
            const image = await import(`../assets/Image/${listing.image}`);
            return image.default;
          } catch (error) {
            console.error("Error loading image:", error);
            return null;
          }
        })
      );
      setImages(loadedImages);
    };

    loadImages();
  }, [listings]);

  useEffect(() => {
    if (display1) {
      if (typevalue === "Buy") {
        const filterdata = data.filter((item) => item.action === "Buy");
        const finaldata = filterdata.filter(
          (val) => val.type === properties[0]
        );
        setvalue(finaldata);
      } else if (typevalue === "Rent/Lease") {
        const filterdata = data.filter(
          (item) => item.action === "Rent" || item.action === "Lease"
        );
        const finaldata = filterdata.filter(
          (val) => val.type === properties[0]
        );
        setvalue(finaldata);
      }
    }
    fetchData();
  }, [properties, data]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const postDetailsResponse = await axios.get(
        `${Nodeapi}/adpost/adDashboard?id=${userId}`
      );
      setdisplaypost(postDetailsResponse.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch job details:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (logoutdata) {
    fetchData();
  }
  useEffect(() => {
    fetchData();
  }, []);

  const viewdetail = (index, userid) => {
    const newBookmarkStates = [...bookmarkStates];
    newBookmarkStates[index] = !newBookmarkStates[index];
    setBookmarkStates(newBookmarkStates);

    if (newBookmarkStates[index]) {
      setSelectedIndices([...selectedIndices, index]);
      setview_adpost([...view_adpost, displaypost[index]]);
    } else {
      const updatedIndices = selectedIndices.filter((i) => i !== index);
      const updatedData = view_adpost.filter((data, i) => i !== index);

      setSelectedIndices(updatedIndices);
      setview_adpost(updatedData);
    }

    if (authdata) {
      Navigate(`/main/PostOverview/${userid}`);
    } else {
      Navigate("/login");
    }
  };

  const handleimage = async (data) => {
    const val = displaypost[data];
    const category = val.post_category;
    const postid = val.post_id;
    setcategory(category);
    localStorage.setItem("Category", category);
    localStorage.setItem("Postiddata", postid);
    setpostiddata(postid);

    try {
      const res = await axios.get(
        `${Nodeapi}/getimagename?userid=${val.post_id}`
      );
      const data = res.data.result;
      const imageFileNames = data.map((image) => image.file_name);
      sethome_listimages(imageFileNames);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleview = async (data) => {
    const val = displaypost[data];
    const today = new Date().toISOString().split("T")[0];
    localStorage.setItem("PostID", val.id);
    try {
      const res = await axios.post(`${Nodeapi}/viewhistory`, {
        id: val.id,
        date: today,
        kid_id: authdata.userid,
        post_id: val.post_id,
      });
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <>
      <div>
        <h6 className="my-3 recentpost"></h6>
        <div className="ad-content">
        {loading ? (
            <span>Loading...</span>
          ) : displaypost.length > 0 ? (
          <Grid container spacing={2}>
            {displaypost.map((listing, index) => (
              <Grid xs={12} sm={6} md={3} key={index}>
                <div
                  className="my-ad-card-home"
                  style={{ padding: "0px", cursor: "pointer" }}
                >
                  <div className="ad-card-heart">
                    <div
                      style={{
                        backgroundColor: "#E6F2FC",
                        padding: "15px",
                        borderRadius: "10px 10px 0 0",
                      }}
                    >
                      <AutoPlaySwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        enableMouseEvents
                        onClick={() => {
                          viewdetail(index, listing.userid);
                          handleimage(index);
                          handleview(index);
                        }}
                      >
                        {listing.images.map((step, index) => (
                          <div key={step.label}>
                            {Math.abs(activeStep - index) <= 10 ? (
                              <Box
                                component="img"
                                sx={{
                                  height: 180,
                                  display: "block",
                                  maxWidth: 400,
                                  overflow: "hidden",
                                  width: "100%",
                                  borderRadius: 2,
                                }}
                                src={`${Assetsapi}/adimages/${step}`}
                                alt={`House ${index + 1}`}
                              />
                            ) : null}
                          </div>
                        ))}
                      </AutoPlaySwipeableViews>
                    </div>
                  </div>
                  <div
                    style={{ padding: "12px" }}
                    onClick={() => {
                      viewdetail(index, listing.userid);
                      handleimage(index);
                      handleview(index);
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="title" style={{ marginTop: "8px" }}>
                        {listing.post_title}
                      </p>
                    </div>
                    <p
                      className="card-desp"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {listing.post_description}
                    </p>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        borderBottom: "1px solid #E6E6E6",
                        paddingBottom: "12px",
                        marginTop: "16px",
                      }}
                    >
                      <p className="card-price">
                        ₹
                        {listing.post_price.length > 7
                          ? `${listing.post_price.substring(0, 7)}..`
                          : listing.post_price}
                      </p>

                      <button
                        onClick={() => {
                          viewdetail(index, listing.userid);
                          handleimage(index);
                          handleview(index);
                        }}
                        className="btn-view"
                      >
                        {authdata ? "View Details" : "View Details"}
                      </button>
                    </div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ marginTop: "0.70rem" }}
                    >
                      <p className="botm-text">
                        <CiLocationOn
                          style={{
                            color: "#8f8f8f",
                            marginBottom: "3px",
                            fontSize: "19px",
                          }}
                        />
                        <span className="icons ms-1">
                          {listing.post_location}
                        </span>
                      </p>
                      {listing.created_at && (
                        <p className="botm-text">
                          <IoCalendarOutline
                            style={{
                              color: "#8f8f8f",
                              marginBottom: "2px",
                              fontSize: "16px",
                            }}
                          />
                          <span className="icons ms-1">
                            {(() => {
                              const date = new Date(listing.created_at);
                              const formattedDate = `${date
                                .getDate()
                                .toString()
                                .padStart(2, "0")}-${(date.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}-${date.getFullYear()}`;
                              return formattedDate;
                            })()}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        ) : (
            <span>No data available</span>
          )}
        </div>
      </div>
    </>
  );
};
export default LivePost;