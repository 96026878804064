// data.js

export const movieGenres = [
  "Action",
  "Comedy",
  "Drama",
  "Fantasy",
  "Horror",
  "Science Fiction",
  "Romance",
  "Thriller",
  "Mystery",
  "Musical",
  "Documentary",
  "Animation",
  "Western",
  "War",
  "Crime",
];

export const streamPlatform = [
  "Disney+ Hotstar",
  "Netflix India",
  "Amazon Prime Video India",
  "ZEE5",
  "Sony LIV",
  "MX Player",
  "Voot",
  "JioCinema",
  "Eros Now",
  "Apple TV+",
  "Discovery+",
  "Aha",
  "MX Player",
  "Sun NXT",
  "Airtel Xstream",
];

export const indianLanguages = [
  "Hindi",
  "Bengali",
  "Telugu",
  "Marathi",
  "Tamil",
  "Urdu",
  "Gujarati",
  "Malayalam",
  "Kannada",
  "Oriya",
  "Punjabi",
  "Assamese",
  "Maithili",
  "Sanskrit",
  "Konkani",
  "Nepali",
  "Sindhi",
  "Dogri",
  "Manipuri",
  "Bodo",
  "Santhali",
  "Kashmiri",
  "Rajasthani",
  "Sikkimese",
  "Mizo",
  "Meitei",
  "Khandesi",
  "Tulu",
  "Angika",
  "Chhattisgarhi",
  "Lepcha",
  "Garo",
  "Khasi",
  "Hmar",
  "Tibetan",
];

export const typeofCertificate = [
  "U",
  "U/A",
  "A",
  "S",
  "U/A 7+",
  "U/A 13+",
  "U/A 15+",
];
