import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CineModal from "./CineModal.js";
import { MdDriveFolderUpload, MdRemoveCircleOutline } from "react-icons/md";

import {
  IoChevronBackCircleOutline,
  IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { Box, Button, Modal, IconButton } from "@mui/material";
import { RiMenuFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import CinePostGallery from "./CinePostGallery";
import { FaRegEdit } from "react-icons/fa";
import axios from "axios";
import "./overview.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import moment from "moment/moment";
import { Nodeapi } from "../../config/serverUrl";
import Swal from "sweetalert2";
import MainCard from "./MainCard.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function CinePostOverview() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [genre, setGenre] = useState([]);
  const [stream, setStream] = useState([]);
  const [language, setLanguage] = useState([]);
  const [time, setTime] = useState(new Date());

  const param = useParams();
  const [documentation, setDocumentation] = useState({
    title: "",
    trailerUrl: "",
    director: "",
    musicDirector: "",
    writer: "",
    producer: "",
    productionCompany: "",
    performingArtist: "",
    synopsis: "",
    place: "",
    theatre: "",
  });
  const theme = useTheme();

  const [releaseDate, setReleaseDate] = useState(null);
  const authData = useSelector((state) => state.auth.user);
  const userId = authData?.id;
  const [buttonvalue, setButtonValu] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [listimages, setlistimages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [pageView, setPageView] = useState([]);
  useEffect(() => {
    fetchData();
    fetchAllData();
  }, [param.id]);

  const fetchData = async () => {
    const res = await axios.get(`${Nodeapi}/cinePost/getOneData/${param.id}`);
    const allData = res.data.data.data;
    const allImageData = res.data.data.allImages;
    setProducts(allData);
    setItemData(allImageData);
  };

  const fetchAllData = async () => {
    const res = await axios.get(`${Nodeapi}/cinePost/getAllData/${userId}`);
    setPageView(res.data.data.data);
  };

  const handleChangeGenre = (event) => {
    const {
      target: { value },
    } = event;
    setGenre(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeStream = (event) => {
    const {
      target: { value },
    } = event;
    setStream(typeof value === "string" ? value.split(",") : value);
  };

  const handleTimeChange = (e) => {
    setTime(e.value);
  };

  const handleChangeLanguage = (event) => {
    const {
      target: { value },
    } = event;
    setLanguage(typeof value === "string" ? value.split(",") : value);
  };

  const handleClose = () => {
    setOpen(false);
    setDocumentation("");
    setAdditionalImages([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocumentation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    setReleaseDate(e.target.value);
  };

  const handleRemove = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleAddMoreImage = (event) => {
    const files = Array.from(event.target.files);
    const newupdateImages = files.map((file) => ({
      filePath: file,
      documentName: file.name,
    }));
    setAdditionalImages([...additionalImages, ...newupdateImages]);
  };

  const handleuploadRemove = async (index, fileName) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        setlistimages((prevImages) => prevImages.filter((_, i) => i !== index));

        await axios.delete(`${Nodeapi}/cinePost/deleteImage/${fileName.id}`);
      }
    } catch (error) {
      console.error("Error deleting image from backend:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const action = event.target.value;
    if (isSubmitting) return; //Prevent multiple submission
    setIsSubmitting(true);

    if (action === "Update") {
      const isEmpty = Object.values(documentation).reduce((acc, cur) => {
        if (cur == "") {
          ++acc;
        }
        return acc;
      }, 0);

      if (isEmpty > 5) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Fill Out All Required Fields",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsSubmitting(false);
        return setOpen(true);
      } else {
        const formattedDate = moment(releaseDate).format("DD-MM-YYYY");
        const date = new Date(time);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        const genreArray = genre.join(",");
        const streamArray = stream.join(",");
        const languageArray = language.join(",");
        const formData = new FormData();

        // Append images
        [...uploadedImages, ...additionalImages].forEach((image) => {
          formData.append("images", image.filePath); // Adjust if `filePath` needs to be an actual file object
        });

        formData.append("title", documentation.title);
        formData.append("release_date", formattedDate);
        formData.append("genre", JSON.stringify(genreArray)); // Convert arrays to JSON string if required by backend
        formData.append("trailer_url", documentation.trailerUrl);
        formData.append("director", documentation.director);
        formData.append("music_director", documentation.musicDirector);
        formData.append("writer", documentation.writer);
        formData.append("movie_certificate", documentation.certificate);
        formData.append("producer", documentation.producer);
        formData.append("production_company", documentation.productionCompany);
        formData.append("streaming_platform", JSON.stringify(streamArray));
        formData.append("run_time", formattedTime);
        formData.append("language", JSON.stringify(languageArray));
        formData.append("performing_artist", documentation.performingArtist);
        formData.append("movie_description", documentation.synopsis);
        formData.append("place", documentation.place);
        formData.append("theatre", documentation.theatre);

        const res = await axios.put(
          `${Nodeapi}/cinePost/updateData/${param.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Important for file uploads
            },
          }
        );

        if (res.data) {
          if (res.data.code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Movie edited Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            handleClose();
            setDocumentation("");
            setIsSubmitting(false);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Error 404",
              showConfirmButton: false,
              timer: 2000,
            });
            handleClose();
          }
        }
        // setIsSubmitting(false);
      }
    }
    fetchData();
    handleClose();
    setIsSubmitting(false);
  };

  const handleEdit = async (id) => {
    setOpen(true);
    setIsEdit(true);
    setButtonValu("Update");
    const res = await axios.get(`${Nodeapi}/cinePost/getOneData/${id}`);
    const allData = res.data.data.data;
    const allImageData = res.data.data.allImages;
    setDocumentation({
      title: allData[0].title,
      trailerUrl: allData[0].trailer_url,
      director: allData[0].director,
      musicDirector: allData[0].music_director,
      writer: allData[0].writer,
      certificate: allData[0].movie_certificate,
      producer: allData[0].producer,
      productionCompany: allData[0].production_company,
      performingArtist: allData[0].performing_artist,
      synopsis: allData[0].movie_description,
      theatre: allData[0].theatre,
      place: allData[0].place,
    });

    // const monthDate = moment(allData[0].release_date, "DD-MM-YYYY").toDate();
    const monthDate = allData[0].release_date.split("-").reverse().join("-");
    setReleaseDate(monthDate);

    var genArr = allData[0].genre;
    var arr2 = genArr.replace(/^"|"$/g, "").split(","); // Removes leading and trailing quotes

    const stream = allData[0].streaming_platform;
    const arr3 = stream.replace(/^"|"$/g, "").split(",");

    setStream(arr3);
    const language = allData[0].language;
    const arr4 = language.replace(/^"|"$/g, "").split(",");
    setLanguage(arr4);
    const time = allData[0].run_time;
    const [h, m] = time.split(":");
    const date = new Date();
    date.setHours(h);
    date.setMinutes(m);
    date.setMilliseconds(0);
    setTime(date);
    setGenre(arr2);
    setlistimages(allImageData);
  };

  const [sharing, setSharing] = useState(false);
  const handleShare = async (url) => {
    if (navigator.share && !sharing) {
      setSharing(true);
      try {
        await navigator.share({
          title: "Check out this Cinema post!",
          url: url,
        });
      } catch (error) {
        console.error("Error sharing this Cinema post:", error);
      } finally {
        setSharing(false);
      }
    }
  };
  const cineUrl = `${window.location.origin}/main/CinePost/${param.id}`;

  const addmorimage = (
    <>
      <Button component="label" sx={{ height: 60 }}>
        <MdDriveFolderUpload
          style={{
            fontSize: "10px",
            marginRight: "0.5rem",
            border: "1px dashed #68bcff",
            borderRadius: "10px",
            background: "#eff5fc",
            width: "4rem",
            height: "3rem",
            // marginLeft: "-425px",
            cursor: "pointer",
          }}
        />
        <input
          hidden
          multiple
          accept="image/*"
          type="file"
          onChange={handleAddMoreImage}
        />
      </Button>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        {additionalImages.map((image, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            <img
              src={URL.createObjectURL(image.filePath)}
              alt={image.documentName}
              style={{
                width: "126px",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemove(index)}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                minWidth: 0,
                padding: "5px",
              }}
            >
              <MdRemoveCircleOutline />
            </Button>
          </div>
        ))}
      </div>
    </>
  );

  const handlePreviousJob = () => {
    const currentIndex = pageView.findIndex(
      (job) => String(job.id) === String(param.id)
    );

    if (currentIndex > 0) {
      const prevJobId = pageView[currentIndex - 1].id;
      navigate(`/main/cinePost/view/${prevJobId}`);
    }
  };

  const currentIndex = pageView.findIndex(
    (job) => String(job.id) === String(param.id)
  );

  const hasPreviousJob = currentIndex > 0;
  const hasNextJob = currentIndex < pageView.length - 1;

  const handleNextJob = () => {
    const currentIndex = pageView.findIndex(
      (job) => String(job.id) === String(param.id)
    );

    if (currentIndex < pageView.length - 1) {
      const nextJobId = pageView[currentIndex + 1].id;
      navigate(`/main/cinePost/view/${nextJobId}`);
    }
  };
  const handleUpload = (event) => {
    const files = Array.from(event.target.files);

    const newImages = files.map((file) => ({
      filePath: file,
      documentName: file.name,
    }));
    setUploadedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const imageUploadSection = (
    <>
      <div className="col-12 mt-md-0">
        <div className="d-flex flex-wrap">
          {uploadedImages.map((image, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <img
                src={URL.createObjectURL(image.filePath)}
                alt={image.documentName}
                style={{
                  width: "126px",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemove(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  minWidth: 0,
                  padding: "5px",
                }}
              >
                <MdRemoveCircleOutline />
              </Button>
            </div>
          ))}
          <label
            htmlFor="upload-photo"
            className="upload-card p-0"
            style={{
              border: "1px dashed #68bcff",
              borderRadius: "10px",
              background: "#eff5fc",
              width: "7rem",
              height: "6rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "-7px",
              cursor: "pointer",
            }}
          >
            <input
              hidden
              multiple
              accept="image/*"
              type="file"
              id="upload-photo"
              onChange={handleUpload}
            />
            <div
              className="icon"
              style={{
                fontSize: "1.5rem",
                marginRight: "0.5rem",
                color: "#2196f3",
              }}
            >
              <MdDriveFolderUpload />
            </div>
            <div className="overlay">
              <p style={{ color: "#2196f3" }}>Add</p>
            </div>
          </label>
        </div>
      </div>
    </>
  );

  return (
    <div className="CinePostOverview">
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pt={{ xs: 5, md: 3 }}
        mb={2}
      >
        <Box></Box>
        <Box>
          <IconButton onClick={handlePreviousJob} disabled={!hasPreviousJob}>
            <IoChevronBackCircleOutline />
          </IconButton>
          <IconButton onClick={() => navigate("/main/cinePost")}>
            <RiMenuFill />
          </IconButton>
          <IconButton onClick={handleNextJob} disabled={!hasNextJob}>
            <IoChevronForwardCircleOutline />
          </IconButton>
        </Box>
      </Box>
      <Grid className="mt-0" container spacing={2}>
        <Grid item xs={12} sm={8}>
          <CinePostGallery imgVal={itemData} />
          <div
            className="Description mt-4"
            style={{ padding: "0px 20px", marginTop: "35px" }}
          >
            <div className="card">
              <h4>Description</h4>

              {products.map((cur) => (
                <p key={cur.id}>{cur.movie_description}</p>
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="gallery_right" style={{ width: "350px" }}>
            <div className="card">
              {products.map((cur) => (
                <MainCard
                  cur={cur}
                  handleShare={handleShare}
                  cineUrl={cineUrl}
                />
              ))}
              <div className="buttons3">
                <button className="btn1" onClick={() => handleEdit(param.id)}>
                  <FaRegEdit />
                  <span>Edit Post</span>
                </button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <CineModal
        open={open}
        handleClose={handleClose}
        documentation={documentation}
        isEdit={isEdit}
        handleChange={handleChange}
        releaseDate={releaseDate}
        handleDateChange={handleDateChange}
        genre={genre}
        handleChangeGenre={handleChangeGenre}
        MenuProps={MenuProps}
        getStyles={getStyles}
        theme={theme}
        stream={stream}
        handleChangeStream={handleChangeStream}
        time={time}
        handleTimeChange={handleTimeChange}
        language={language}
        handleChangeLanguage={handleChangeLanguage}
        imageUploadSection={imageUploadSection}
        addmorimage={addmorimage}
        listimages={listimages}
        additionalImages={additionalImages}
        handleuploadRemove={handleuploadRemove}
        handleSubmit={handleSubmit}
        buttonvalue={buttonvalue}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}

export default CinePostOverview;
