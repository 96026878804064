import React, { useState, useContext, useEffect } from "react";
import "./AccountSharing.css";
import { MdArrowBack } from "react-icons/md";
import {
  FormControlLabel,
  Radio,
  TextField,
  Tab,
  Tabs,
  Box,
  Button,
  Checkbox,
} from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import Swal from "sweetalert2";
import { DataContext } from "../../Admin/DataContext";
import { useNavigate } from "react-router-dom";

const AccountSharing = () => {
  const values = [
    { name: "Grant user to manage all services" },
    { name: "HRMS" },
    { name: "Payroll" },
    { name: "Projects" },
    { name: "Accounts" },
    { name: "Finance" },
    { name: "Administrator" },
  ];

  const { edit_account, visible, setvisible } = useContext(DataContext);

  const [selectedValues, setSelectedValues] = useState([]);
  const [originalValues, setOriginalValues] = useState([]);
  const [email, setEmail] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [kidIds, setKidIds] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (visible) {
      const val = edit_account?.Kid_id;
      setEmail(val);

      const selected = [];
      if (edit_account?.HRMS === "1") selected.push("HRMS");
      if (edit_account?.Payroll === "1") selected.push("Payroll");
      // Add other checks for different account types as needed
      setSelectedValues(selected);
      setOriginalValues(selected);
    }
  }, [visible, edit_account]);

  const handleRadioChange = (event) => {
    setSelectedValues([event.target.value]);
  };

  const handleEmailChange = (event) => {
    const userInput = event.target.value.toUpperCase();
    setEmail(userInput);
  };

  const handleAddUser = () => {
    if (email.trim() !== "") {
      setKidIds([...kidIds, email]);
      setEmail(""); // Clear the input field after adding
    }
  };

  const handleRemoveKidId = (index) => {
    const newKidIds = kidIds.filter((_, i) => i !== index);
    setKidIds(newKidIds);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSubmit1 = async (event) => {
    event.preventDefault();

    if (visible) {
      try {
        const accounts = {
          selectedValues,
          originalValues,
          kidId: Array.isArray(kidIds) && kidIds.length > 0 ? kidIds : [email],
        };
        const response = await axios.post(`${Nodeapi}/UpdateAccount`, accounts);
        const data = response.data;

        if (data.success === true) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Account Updated Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          setvisible(false);
          setKidIds([]); // Clear the list after successful update
          setTimeout(() => {
            navigate("/main/Accountsharingactivity");
          }, 2000);
        }
      } catch (error) {
        console.log("Update Error:", error);
      }
    } else {
      try {
        const accounts = {
          selectedValues,
          kidId: Array.isArray(kidIds) && kidIds.length > 0 ? kidIds : [email],
        };

        const response = await axios.post(
          `${Nodeapi}/account_access`,
          accounts
        );
        const data = response.data;

        if (data.success === true) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Account Shared Successfully ",
            showConfirmButton: false,
            timer: 2000,
          });

          setKidIds([]); // Clear the list after successful submission
          setTimeout(() => {
            navigate("/main/Accountsharingactivity");
          }, 2000);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const handleSubmit = () => {
    // Handle the submit logic for the "Request Access" tab if needed
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedValues((prevSelectedValues) =>
      event.target.checked
        ? [...prevSelectedValues, value]
        : prevSelectedValues.filter((selectedValue) => selectedValue !== value)
    );
  };

  const handleback = () => {
    setvisible(false);
    navigate("/main/Accountsharingactivity");
  };

  return (
    <div className="accountsharing">
      <div className="card">
        <div className="header d-flex">
          {/* <Link to="/main">
            <IoIosArrowBack className="icon" />
          </Link> */}
          <h3 className="h-title">Account Sharing</h3>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-10 col-12">
            <div className="content">
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Grant Access" />
                <Tab label="Request Access" />
              </Tabs>

              <TabPanel value={selectedTab} index={0}>
                <form onSubmit={handleSubmit1}>
                  <p
                    className="text-h1"
                    style={{ color: "#8B8B8B", fontWeight: "600" }}
                  >
                    You can assign someone to access and administrate your
                    company account.
                  </p>
                  <p
                    className="text-h1"
                    style={{ color: "#4A4A4A", fontWeight: "600" }}
                  >
                    Please enter the recipient's email address to whom you'd
                    like to assign
                  </p>
                  <div className="row">
                    <div className="col-6">
                      <TextField
                        className="w-100 general-input text-h2"
                        placeholder="Enter Kid id"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div className="col-2">
                      <div className="flex align-items-center h-full">
                        {!visible && (
                          <Button
                            style={{
                              padding: "8px 20px",
                              marginTop: "11px",
                              border: "none",
                              background: "#2196f3",
                              color: "white",
                              borderRadius: "8px",
                            }}
                            onClick={handleAddUser}
                          >
                            Add User
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-1">
                    <div>
                      {kidIds.map((kidId, index) => (
                        <div
                          key={index}
                          className="user-list"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            background: "#f0f0f0",
                            borderRadius: "8px",
                            padding: "4px 12px",
                            marginTop: "8px",
                          }}
                        >
                          <p>{kidId}</p>
                          <IoCloseSharp
                            onClick={() => handleRemoveKidId(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <p
                    className="text-h1"
                    style={{ color: "#4A4A4A", fontWeight: "600" }}
                  >
                    Select the limits of authorization level
                  </p>
                  {values.map((value, index) => (
                    <div key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedValues.includes(value.name)}
                            onChange={handleCheckboxChange}
                            value={value.name}
                          />
                        }
                        label={value.name}
                      />
                    </div>
                  ))}
                  <div className="grant-access-btn mt-5">
                    {visible && (
                      <Button
                        className=""
                        style={{
                          textAlign: "center",
                          marginRight: "10px",
                          marginTop: "10px",
                        }}
                        onClick={handleback}
                      >
                        <MdArrowBack /> Back
                      </Button>
                    )}
                    <Button
                      type="submit"
                      style={{
                        padding: "8px 20px",
                        marginTop: "11px",
                        border: "none",
                        background: "#2196f3",
                        color: "white",
                        borderRadius: "8px",
                      }}
                      className="grant-access-btn"
                    >
                      Grant Access
                    </Button>
                  </div>
                </form>
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <form onSubmit={handleSubmit}>
                  <p
                    className="text-h1"
                    style={{ color: "#8B8B8B", fontWeight: "600" }}
                  >
                    You can request someone to access and administrate their
                    company account.
                  </p>
                  <p
                    className="text-h1"
                    style={{ color: "#4A4A4A", fontWeight: "600" }}
                  >
                    Please enter the email address of the provider to whom you'd
                    like to request access
                  </p>
                  <TextField
                    className="w-100 general-input text-h2"
                    placeholder="Recipient Email address"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <p
                    className="text-h1"
                    style={{ color: "#4A4A4A", fontWeight: "600" }}
                  >
                    Select the limits of authorization level
                  </p>
                  {values.map((value, index) => (
                    <div key={index}>
                      <FormControlLabel
                        value={value.name}
                        control={
                          <Radio
                            checked={selectedValues === value.name}
                            onChange={handleRadioChange}
                          />
                        }
                        label={value.name}
                      />
                    </div>
                  ))}
                  <div className="request-access-btn mt-5">
                    {visible && (
                      <Button className=""
                      style={{
                        textAlign: "center",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}>
                           <MdArrowBack /> Back
                      </Button>
                    )}

                    <Button
                        type="submit"
                        style={{
                          padding: "8px 20px",
                          marginTop: "11px",
                          border: "none",
                          background: "#2196f3",
                          color: "white",
                          borderRadius: "8px",
                        }}
                        className="request-access-btn"
                    >
                      Request Access
                    </Button>
                  </div>
                </form>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

export default AccountSharing;
