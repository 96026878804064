import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Billing.css'
import { DataContext } from "../../Admin/DataContext";
import html2pdf from 'html2pdf.js';
import complogo from "../../assets/images/compLogo.svg";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Swal from 'sweetalert2';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "#fff",
  border: "0px",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
  //   height: "483px",
  //   overflowY: "scroll",
};

const Subscription = () => {
  const [quantity, setQuantity] = useState("");
  const [totalads, settotalads] = useState("")
  const [totalAdsPurchased, setTotalAdsPurchased] = useState(0);
  const [lastPurchaseDate, setLastPurchaseDate] = useState('');
  const [lastPurchaseAmount, setLastPurchaseAmount] = useState('');
  const [visible, setVisible] = useState(false);
  const [SGST, setSGST] = useState('')
  const [CGST, setCGST] = useState('')
  const [totalprice1, settotalprice1] = useState("")
  const [IGST, setIGST] = useState('')
  const [UTGST, setUTGST] = useState('')
  const [totalprice2, settotalprice2] = useState("")
  const [Finalprice, setFinalprice] = useState('')
  const [totalprice3, settotalprice3] = useState("")
  const [data, setdata] = useState(false)


  const {
    clientdetails,
    setclientdetails,
    setinvoicedetails,
    setuserdetails,
    invoicedetails,
    Insidestate,
    outerstate,
    unionterritory,
    setInsidestate,
    setouterstate,
    setunionterritory,
    usercountry
  } = useContext(DataContext);

  const navigate = useNavigate()

  const authdata = useSelector((state) => state.auth.user);
  const id_value = authdata.id
  const userid = authdata.userid
  

  const price = parseInt(quantity) * 100;
  const handleQuantityChange = (e) => {
    const value = e.target.value.trim();
    if (/^\d*$/.test(value)) {
      setQuantity(value);
    }
  };



  useEffect(() => {
    Fetchdata()

  }, [])





  const Fetchdata = async () => {
    const Response = await axios.get(`${Nodeapi}/getclientpay_details?userid=${userid}`)
    const data = Response.data
    const finaldata = Response.data.data.result
    let filterdata = finaldata.filter((item) => item.payment === "Success")
    const total = filterdata.reduce((acc, purchase) => acc + parseInt(purchase.quantity, 10), 0);
    setTotalAdsPurchased(total);
    if (filterdata.length > 0) {
      const latestPurchase = filterdata.reduce((latest, current) =>
        new Date(latest.purchase_date) > new Date(current.purchase_date) ? latest : current
      );
      const formattedDate = new Date(latestPurchase.purchase_date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      setLastPurchaseDate(` ${formattedDate}`);
      setLastPurchaseAmount(`${latestPurchase.amount}`);
    }
    if (data.status == "success") {
      setclientdetails(filterdata)
    }

  }





  const handlesubmit = async () => {

    if (quantity == '') {
      Swal.fire({
        position: "center",
        icon: "error",
        title: 'Enter The Credentials',
        showConfirmButton: false,
        timer: 2000
      });
      return
    }

    if (Insidestate) {
      const amount = price;
      const sgst = (amount * 9) / 100;
      const cgst = (amount * 9) / 100;
      const gst = sgst + cgst
      const final_price = amount + gst;
      setSGST(sgst)
      setCGST(cgst)
      settotalprice1(final_price)
      setFinalprice(final_price)
    } else if (outerstate) {
      const amount = price;
      const gst = (amount * 18) / 100;
      const final_price = amount + gst;
      setIGST(gst)
      settotalprice2(final_price)
      setFinalprice(final_price)
    } else if (unionterritory) {
      const amount = price;
      const utgst = (amount * 9) / 100;
      const cgst = (amount * 9) / 100;
      const gst = utgst + cgst
      const final_price = amount + gst;
      setUTGST(utgst)
      setCGST(cgst)
      settotalprice3(final_price)
      setFinalprice(final_price)
    } else {
      setFinalprice(price)
    }

    setVisible(true)
  }


  const handlepayamount = async () => {

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    try {
      const res = await axios.post(`${Nodeapi}/pay`, {
        price: Finalprice,
        unitprice: price,
        customerid: authdata.userid,
        purchasedate: formattedDate,
        customername: authdata.name,
        quantity: quantity,
        sgst: SGST,
        cgst: CGST,
        igst: IGST,
        utgst: UTGST,
        country:usercountry,
        userid:id_value
      })
      const val = res.data

      const data = res.data.result
      if (val.success === true) {
        window.location.href = data
      }

    } catch (error) {
      console.log('Payment_Error:', error);
    }
  }

  const generatePDF = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.id = 'side-bar-main-menu';
    tempDiv.innerHTML = htmlContent;
    document.body.appendChild(tempDiv);

    html2pdf().from(tempDiv).save('Invoice.pdf').then(() => {
      document.body.removeChild(tempDiv);
    });
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleinvoice = (rowdata) => {
    setinvoicedetails(rowdata);
    console.log(rowdata);

    let sgst_value = rowdata.SGST;
    let cgst_value = rowdata.CGST;
    let igst_value = rowdata.IGST;
    let utgst_value = rowdata.UTGST;
   let UnitPrice = rowdata.Unit_price
   let totalprice = rowdata.amount

    const isSGSTCGST = sgst_value && cgst_value;
    const isUTGST = utgst_value && cgst_value;
    const isIGST = igst_value;


    const prefix = 'KCPL-';
    const date = new Date().getFullYear();
    const randomNum = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    const userdetails = {
      username: authdata.name,
      email: authdata.email,
      mobileno: authdata.mobile_no,
    };
    setuserdetails(userdetails);

    const htmlContent = `
    <div id="invoice" class="invoice">
        <div class="invoice-in">
            <div class="invoice-top">
                <img src="${complogo}" class="invoice-logo" alt="Company Logo" />
                <h3>KODUKKU <br /> CLASSIFIEDS <br /> PRIVATE LIMITED</h3>
            </div>
            <div class="invoice-middle">
                <div class="flex justify-content-between" style="margin: 3rem 0">
                    <div class="invoice-to">
                        <h5>BILL TO</h5>
                        <p>${userdetails.username}</p>
                        <p>${userdetails.email}</p>
                        <p>${userdetails.mobileno}</p>
                    </div>
                    <div class="invoice-from">
                        <h5>INVOICE</h5>
                        <p>Invoice Number: <span>${prefix}${date}-${randomNum}</span></p>
                        <p>Invoice Date: <span>${new Date().toLocaleDateString()}</span></p>
                        <p>Payment Mode: <span>${rowdata.payment_type}</span></p>
                        <p>Paid Date: <span>${formatDate(rowdata.purchase_date)}</span></p>
                    </div>
                </div>
                <div class="invoice-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Sl. No</th>
                                <th>Description</th>
                                <th>Unit</th>
                                <th>Unit Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Subscription</td>
                                <td>${rowdata.quantity}</td>
                                <td>${rowdata.Unit_price}</td>
                                <td>${rowdata.Unit_price}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="invoice-total" style="display: flex; justify-content: end">
                    <div class="invoice-card" style="border: none; padding: 20px 15px; border-radius: 18px; width: 14rem; background: #EFF5FC; color: black;">
                        <div class="grid">
                            <div class="col-6">
                                <h6>Sub total</h6>
                            </div>
                            <div class="col-6">
                                <p style="text-align: end">₹${rowdata.Unit_price}</p>
                            </div>
                        </div>
                        ${isSGSTCGST || isUTGST || isIGST ? `
                        <div class="grid">
                            <div class="col-6">
                                ${isSGSTCGST ? `
                                <h6>SGST (9%)</h6>
                                <h6>CGST (9%)</h6>` : ''}
                                ${isUTGST ? `
                                <h6>UTGST (9%)</h6>
                                <h6>CGST (9%)</h6>` : ''}
                                ${isIGST ? `
                                <h6>IGST (18%)</h6>` : ''}
                            </div>
                            <div class="col-6">
                                ${isSGSTCGST ? `
                                <p style="text-align: end">₹${rowdata.SGST}</p>
                                <p style="text-align: end">₹${rowdata.CGST}</p>` : ''}
                                ${isUTGST ? `
                                <p style="text-align: end">₹${rowdata.UTGST}</p>
                                <p style="text-align: end">₹${rowdata.CGST}</p>` : ''}
                                ${isIGST ? `
                                <p style="text-align: end">₹${rowdata.IGST}</p>` : ''}
                            </div>
                        </div>` : ''}
                        <div class="grid">
                            <div class="col-6">
                                <h6>Total</h6>
                            </div>
                            <div class="col-6">
                                 <p style="text-align: end">₹${rowdata.amount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="invoice-footer">
            <p class="invoice-address">
                253 Thanthai Periyar Nagar, <br />
                Velachery –Tambaram Main Road, Pallikaranai, <br />
                Chennai 600100. <br />
                CIN: U74999TN2022PTC156514 | GSTIN: 33AAJCK8851D1ZH
            </p>
            <div class="">
                <p class="invoice-link">
                    <TfiWorld style="color: #fff; margin-right: 4px" />
                    www.kodukku.com
                </p>
                <p>Contact Us: customercare@kodukku.com</p>
            </div>
        </div>
    </div>`;
    generatePDF(htmlContent);
  };

  const handleback = () => {
    navigate('/main/Location')
    setInsidestate(false)
    setouterstate(false)
    setunionterritory(false)
  }


  return (
    <>


      <div className="subscription">
        <div className="card">
          <div style={{ marginLeft: "-15px" }}>
            <MdOutlineKeyboardArrowLeft style={{ fontSize: "31px", marginTop: "-6px", color: "#8f8f8f" }} />
            <button onClick={handleback} className="backbutton">Back</button>
          </div>

          <h3 className="mt-1" style={{ color: "#2196f3" }}>Subscription</h3>
          {/* <Grid container spacing={2}>
          <Grid item sm={2}>
            <img
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
              src="https://a.storyblok.com/f/47007/2400x628/b1148e90a9/subscriptionbasedapps_blog_teaser_v01.png/m/1120x292"
              alt=""
            />
          </Grid> */}
          <div style={{ display: "flex" }}>
            <div>
              <h5 className="mt-2" >Buy credentials to post Advertisements</h5>
              <p style={{ color: "#8f8f8f" }}>1 Credential = ₹100 💰</p>
              <div
                className="cred-price"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  style={{
                    border: "1px solid #e1e1e1",
                    borderRadius: "10px",
                    padding: "10px",
                    outline: "none",
                  }}
                  type="text"
                  className="cred-quantity"
                  placeholder="Enter the credential"
                  value={quantity}
                  onChange={handleQuantityChange}
                />

                <p
                  className="cred-value"
                  style={{ fontSize: "1rem", margin: "0 50px" }}
                >
                  ₹<span style={{ fontSize: "3rem" }}>{price}</span>
                </p>
              </div>
              <div>
                <button
                  style={{
                    marginTop: "10px",
                    background: "#37c137",
                    color: "white",
                    border: "none",
                    padding: "5px 26px",
                    borderRadius: "10px",
                  }}
                  onClick={handlesubmit}
                >
                  Buy
                </button>
              </div>
            </div>

            <div>

            </div>
            <div className="col-3" style={{ marginLeft: "1%", marginTop: "-1%" }}>
              <div className="ad-panel-card">
                <div className="top" style={{ padding: "20px" }}>

                  <p style={{ color: "#8f8f8f", fontSize: "20px" }}>Total Ads Purchased</p>

                  <p className='mt-2' style={{ color: "#2196f3", marginLeft: "12%", fontSize: "24px" }}>{totalAdsPurchased}</p>

                  <p className='mt-2' style={{ color: "#8f8f8f" }}>Remaining Ads: {totalAdsPurchased}</p>

                </div>

              </div>
            </div>


            <div className="col-3" style={{ marginTop: "-1%" }}>
              <div className="ad-panel-card">
                <div className="top" style={{ padding: "14px" }}>

                  <p style={{ color: "#8f8f8f", fontSize: "20px" }}>Amount of Purchase</p>
                  <p className='mt-2' style={{ color: "#2196f3", marginLeft: "12%", fontSize: "24px" }}>₹{lastPurchaseAmount}.00</p>
                  <p className='mt-1' style={{ fontSize: "12px", color: "#B5B5B5" }} >You paid</p>

                  <p className='mt-0' style={{ color: "#8f8f8f" }}>  on {lastPurchaseDate}</p>
                </div>

              </div>
            </div>


          </div>




          {/* </Grid> */}
        </div>
      </div>
      <div className='card mt-5'>
        <h5 style={{ color: "#2196f3" }}>Payment history and Invoicing</h5>
        <div className='mt'>
          <DataTable value={clientdetails} tableStyle={{ minWidth: '50rem' }}>
            <Column
              header="S.No"
              body={(rowData) => <span>{clientdetails.indexOf(rowData) + 1}</span>}
            ></Column>
            <Column field="transactionId" header="Transaction ID" />
            <Column field="purchase_date" header="Date" />
            <Column field="amount" header="Amount" />
            <Column field="quantity" header="Number of Ads" />
            <Column field="payment_type" header="Payment Method" />
            <Column field="payment" header="Transaction Status" />
            <Column
              header="Invoice"
              body={(rowData) => {
                return (
                  <div>
                    <button onClick={() => handleinvoice(rowData)} className="in_btn">Download</button>
                  </div>
                );
              }}
            />
          </DataTable>

        </div>

      </div>

      {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
      <Modal open={visible} onClose={() => setVisible(false)}>
        <Box sx={style} style={{ width: "373px", borderRadius: "0px" }}>
          <h5>Payment Summary</h5>

          <div
            style={{ marginTop: "10%" }}
          >
            <div className="grid">
              <div className="col-6">
                <h6>Total Units</h6>
              </div>
              <div className="col-6">
                <p style={{ textAlign: "end" }}>{quantity}</p>
              </div>
            </div>
            <div className="grid">
              <div className="col-6">
                <h6>Sub total</h6>
              </div>
              <div className="col-6">
                <p style={{ textAlign: "end" }}>₹{price}</p>
              </div>
            </div>
            {Insidestate && (
              <>
                <div className="grid">
                  <div className="col-6">
                    <h6>SGST(9%)</h6>
                  </div>
                  <div className="col-6">
                    <p style={{ textAlign: "end" }}>₹{SGST}</p>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-6">
                    <h6>CGST(9%)</h6>
                  </div>
                  <div className="col-6">
                    <p style={{ textAlign: "end" }}>₹{CGST}</p>
                  </div>
                </div>
              </>


            )}

            {outerstate && (
              <div className="grid">
                <div className="col-6">
                  <h6>IGST(18%)</h6>
                </div>
                <div className="col-6">
                  <p style={{ textAlign: "end" }}>₹{IGST}</p>
                </div>
              </div>
            )}

            {unionterritory && (
              <>
                <div className="grid">
                  <div className="col-6">
                    <h6>UTGST(9%)</h6>
                  </div>
                  <div className="col-6">
                    <p style={{ textAlign: "end" }}>₹{UTGST}</p>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-6">
                    <h6>CGST(9%)</h6>
                  </div>
                  <div className="col-6">
                    <p style={{ textAlign: "end" }}>₹{CGST}</p>
                  </div>
                </div>
              </>

            )}

            <div style={{ borderBottom: "1px dotted #ccc", paddingBottom: "10px", marginBottom: "10px" }}></div>
            {Insidestate && (
              <div className="grid">
                <div className="col-6">
                  <h6>Net Payable Amount</h6>
                </div>
                <div className="col-6">
                  <p style={{ textAlign: "end" }}>₹{totalprice1}</p>
                </div>
              </div>
            )}
            {outerstate && (
              <div className="grid">
                <div className="col-6">
                  <h6>Net Payable Amount</h6>
                </div>
                <div className="col-6">
                  <p style={{ textAlign: "end" }}>₹{totalprice2}</p>
                </div>
              </div>
            )}
            {unionterritory && (
              <div className="grid">
                <div className="col-6">
                  <h6>Net Payable Amount</h6>
                </div>
                <div className="col-6">
                  <p style={{ textAlign: "end" }}>₹{totalprice3}</p>
                </div>
              </div>
            )}
            {!Insidestate && !outerstate && !unionterritory && (
              <div className="grid">
                <div className="col-6">
                  <h6>Net Payable Amount</h6>
                </div>
                <div className="col-6">
                  <p style={{ textAlign: "end" }}>₹{price}</p>
                </div>
              </div>
            )}

          </div>
          <div className="mt-3">
            <button className="pay_cancle_btn mt-1" onClick={() => setVisible(false)}>Cancel</button>
            <button className="pay-button mt-1" onClick={handlepayamount}>Continue</button>
          </div>



        </Box>
      </Modal>
    </>

  );
};

export default Subscription;





