import React, { useEffect, useState } from "react";
import "./Sales_details.css";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Nodeapi } from "../config/serverUrl";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { IoSearchOutline } from "react-icons/io5";
import { FaArrowUpLong } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { Calendar } from "primereact/calendar";
import { GoArrowDown } from "react-icons/go";
import { GoArrowUp } from "react-icons/go";
function Sales_details() {
  const [products, setProducts] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [maindetails, setmaindetails] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [subscription, setsubscription] = useState("");
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [weeklyRevenue, setWeeklyRevenue] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [weeklySales, setWeeklySales] = useState(0);
  const [currentMonthRevenue, setCurrentMonthRevenue] = useState(0);
  const [previousMonthRevenue, setPreviousMonthRevenue] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [currentWeekRevenue, setCurrentWeekRevenue] = useState(0);
  const [previousWeekRevenue, setPreviousWeekRevenue] = useState(0);
  const [weeklypercentageChange, setWeeklyPercentageChange] = useState(0);
  const [currentMonthAds, setCurrentMonthAds] = useState(0);
  const [previousMonthAds, setPreviousMonthAds] = useState(0);
  const [totaladspercentageChange, setTotalAdsPercentageChange] = useState(0);
  const [currentWeekAds, setCurrentWeekAds] = useState(0);
  const [previousWeekAds, setPreviousWeekAds] = useState(0);
  const [weeklysales_percentage, setweeklysales_percentage] = useState(0);
  const [showSGST, setShowSGST] = useState(false);
  const [showCGST, setShowCGST] = useState(false);
  const [showUTGST, setShowUTGST] = useState(false);
  const [showIGST, setShowIGST] = useState(false);
  const removeDuplicates = (products) => {
    const uniqueProducts = [];
    const uniqueIds = new Set();

    for (const product of products) {
      if (!uniqueIds.has(product.customer_ID)) {
        uniqueIds.add(product.customer_ID);
        uniqueProducts.push(product);
      }
    }

    return uniqueProducts;
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const getTotalRevenueByMonth = (data, month, year) => {
    return data
      .filter((item) => {
        const date = new Date(item.purchase_date);
        return date.getMonth() === month && date.getFullYear() === year;
      })
      .reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const getCurrentMonthAndYear = () => {
    const today = new Date();
    return { month: today.getMonth(), year: today.getFullYear() };
  };

  const getPreviousMonthAndYear = (month, year) => {
    const date = new Date(year, month - 1);
    return { month: date.getMonth(), year: date.getFullYear() };
  };

  const getTotalRevenueByWeek = (data, week, year) => {
    return data
      .filter((item) => {
        const date = new Date(item.purchase_date);
        const itemYear = date.getFullYear();
        const itemWeek = getWeekNumber(date);
        return itemYear === year && itemWeek === week;
      })
      .reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const getWeekNumber = (date) => {
    const janFirst = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date - janFirst) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + 1) / 7);
  };

  const getCurrentWeekAndYear = () => {
    const today = new Date();
    return { week: getWeekNumber(today), year: today.getFullYear() };
  };

  const getPreviousWeekAndYear = (week, year) => {
    const date = new Date(year, 0, (week - 1) * 7);
    const previousWeek = week === 1 ? 52 : week - 1; // Handle week 1 transition to week 52
    const previousYear = week === 1 ? year - 1 : year;
    return { week: previousWeek, year: previousYear };
  };

  const getTotalAdsByMonth = (data, month, year) => {
    return data
      .filter((item) => {
        const date = new Date(item.purchase_date);
        return date.getMonth() === month && date.getFullYear() === year;
      })
      .reduce((total, item) => total + parseInt(item.quantity, 10), 0);
  };

  const getTotalAdsByWeek = (data, week, year) => {
    return data
      .filter((item) => {
        const date = new Date(item.purchase_date);
        const itemYear = date.getFullYear();
        const itemWeek = getWeekNumber(date);
        return itemYear === year && itemWeek === week;
      })
      .reduce((total, item) => total + parseInt(item.quantity, 10), 0);
  };

  const fetchdata = async () => {
    const res = await axios.get(`${Nodeapi}/getpaymentdetails`);
    const data = res.data.data.result;
    const filterdata = data.filter((item) => item.payment === "Success");
    console.log("filterdata:", filterdata);
    setProducts(filterdata);
    setFilteredProducts(removeDuplicates(filterdata));
    Monthlydata(filterdata);
    weeklyrevenuedatas(filterdata);
    totaladscount(filterdata);
    weeklysalescount(filterdata);
  };

  const Monthlydata = (filterdata) => {
    const { month: currentMonth, year: monthcurrentYear } =
      getCurrentMonthAndYear();
    const { month: previousMonth, year: monthpreviousYear } =
      getPreviousMonthAndYear(currentMonth, monthcurrentYear);
    const currentMonthRevenue = getTotalRevenueByMonth(
      filterdata,
      currentMonth,
      monthcurrentYear
    );
    const totalRevenue = filterdata.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    const previousMonthRevenue = getTotalRevenueByMonth(
      filterdata,
      previousMonth,
      monthpreviousYear
    );
    const percentageChange = previousMonthRevenue
      ? ((currentMonthRevenue - previousMonthRevenue) / previousMonthRevenue) *
        100
      : 100;
    // Set revenue and percentage change state
    setCurrentMonthRevenue(totalRevenue);
    setPreviousMonthRevenue(previousMonthRevenue);
    setPercentageChange(percentageChange);
  };

  const weeklyrevenuedatas = (filterdata) => {
    const { week: currentWeek, year: weekcurrentYear } =
      getCurrentWeekAndYear();
    const { week: previousWeek, year: weekpreviousYear } =
      getPreviousWeekAndYear(currentWeek, weekcurrentYear);

    // Calculate total revenue for the current and previous week
    const currentWeekRevenue = getTotalRevenueByWeek(
      filterdata,
      currentWeek,
      weekcurrentYear
    );
    const previousWeekRevenue = getTotalRevenueByWeek(
      filterdata,
      previousWeek,
      weekpreviousYear
    );

    // Calculate percentage change
    const weekpercentageChange = previousWeekRevenue
      ? ((currentWeekRevenue - previousWeekRevenue) / previousWeekRevenue) * 100
      : 100;

    // Set weekly revenue and percentage change state
    setCurrentWeekRevenue(currentWeekRevenue);
    setPreviousWeekRevenue(previousWeekRevenue);
    setWeeklyPercentageChange(weekpercentageChange);
  };

  const totaladscount = (filterdata) => {
    const { month: current, year: currentYear } = getCurrentMonthAndYear();
    const { month: previous, year: previousYear } = getPreviousMonthAndYear(
      current,
      currentYear
    );

    // Calculate total ads sold for the current and previous month
    const currentMonthAds = getTotalAdsByMonth(
      filterdata,
      current,
      currentYear
    );
    const previousMonthAds = getTotalAdsByMonth(
      filterdata,
      previous,
      previousYear
    );

    const totalAds = filterdata.reduce(
      (total, item) => total + parseFloat(item.quantity),
      0
    );

    // Calculate percentage change
    const AdspercentageChange = previousMonthAds
      ? ((currentMonthAds - previousMonthAds) / previousMonthAds) * 100
      : 100;

    // Set ads sales and percentage change state
    setCurrentMonthAds(totalAds);
    setPreviousMonthAds(previousMonthAds);
    setTotalAdsPercentageChange(AdspercentageChange);
  };

  const weeklysalescount = (filterdata) => {
    const { week: currentWeeksales, year: currentYearsales } =
      getCurrentWeekAndYear();
    const { week: previousWeeksales, year: previousYearsales } =
      getPreviousWeekAndYear(currentWeeksales, currentYearsales);

    // Calculate total ads sold for the current and previous week
    const currentWeekAds = getTotalAdsByWeek(
      filterdata,
      currentWeeksales,
      currentYearsales
    );
    const previousWeekAds = getTotalAdsByWeek(
      filterdata,
      previousWeeksales,
      previousYearsales
    );

    // Calculate percentage change
    const weeklysalespercentageChange = previousWeekAds
      ? ((currentWeekAds - previousWeekAds) / previousWeekAds) * 100
      : 100;

    // Set weekly ads sales and percentage change state
    setCurrentWeekAds(currentWeekAds);
    setPreviousWeekAds(previousWeekAds);
    setweeklysales_percentage(weeklysalespercentageChange);
  };

  const handleselecteduser = (rowdata) => {
    resetGSTStates();
    const customerid = rowdata.customer_ID;
    const filterdata = products.filter(
      (item) => item.customer_ID === customerid
    );

    const showSGST = filterdata.some((item) => item.SGST);
    const showCGST = filterdata.some((item) => item.CGST);
    const showUTGST = filterdata.some((item) => item.UTGST);
    const showIGST = filterdata.some((item) => item.IGST);

    setmaindetails(filterdata);
    setDialogVisible(true);
    setShowSGST(showSGST);
    setShowCGST(showCGST);
    setShowUTGST(showUTGST);
    setShowIGST(showIGST);
  };

  const resetGSTStates = () => {
    setShowSGST(false);
    setShowCGST(false);
    setShowUTGST(false);
    setShowIGST(false);
  };

  const onRowClick = (event) => {
    handleselecteduser(event.data);
  };

  const dialogFooterTemplate = () => {
    return (
      <Button
        label="Ok"
        icon="pi pi-check"
        className="ok_btn"
        onClick={() => setDialogVisible(false)}
      />
    );
  };

  const datavalue = [
    { name: "Current Month", value: "currentMonth" },
    { name: "Current Subscriptions", value: "currentSubscriptions" },
  ];

  const handlechanges = (e) => {
    const val = e.target.value;
    if (val === "") {
      fetchdata();
      setSearchInput("");
    } else {
      setSearchInput(val);
    }
  };

  const handleSearch = () => {
    if (searchInput.trim() === "") {
      // Reset to original data if search input is empty
      setFilteredProducts(products);
    } else {
      const lowercasedFilter = searchInput.toLowerCase();
      const filteredData = products.filter(
        (item) =>
          item.customer_ID.toLowerCase().includes(lowercasedFilter) ||
          item.customer_name.toLowerCase().includes(lowercasedFilter)
      );
      const data = removeDuplicates(filteredData);
      setFilteredProducts(data);
    }
  };

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === "Enter") {
        handleSearch();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [searchInput]);

  console.log("filteredProducts", filteredProducts);

  const getCurrentMonth = () => {
    const date = new Date();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const year = date.getFullYear();
    return { month, year };
  };

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const startOfWeek = currentDate.getDate() - currentDate.getDay() + 1;
    const startOfWeekDate = new Date(currentDate.setDate(startOfWeek));
    return startOfWeekDate;
  };

  const calculateMetrics = (data) => {
    const total = data.reduce((sum, item) => sum + parseFloat(item.amount), 0);
    setTotalRevenue(total);

    const startOfWeekDate = getCurrentWeek();
    const weeklyData = data.filter(
      (item) => new Date(item.purchase_date) >= startOfWeekDate
    );
    const weeklyTotal = weeklyData.reduce(
      (sum, item) => sum + parseFloat(item.amount),
      0
    );
    setWeeklyRevenue(weeklyTotal);

    setTotalSales(data.length);
    setWeeklySales(weeklyData.length);
  };

  const filterCurrentMonthData = () => {
    const { month, year } = getCurrentMonth();
    const filteredData = products.filter((item) => {
      const purchaseDate = new Date(item.purchase_date);
      return (
        purchaseDate.getMonth() + 1 === month &&
        purchaseDate.getFullYear() === year
      );
    });
    const uniqueData = removeDuplicates(filteredData);
    setFilteredProducts(uniqueData);
  };

  const handledropdown = (e) => {
    const val = e.value;
    setsubscription(val);

    if (val === "currentMonth") {
      filterCurrentMonthData();
    } else if (val === "currentSubscriptions") {
      setFilteredProducts(removeDuplicates(products));
    }
  };

  const calculateTotalRevenue = (data) => {
    const total = data.reduce((sum, item) => sum + parseFloat(item.amount), 0);
    setTotalRevenue(total);
  };

  const percentageColor = percentageChange >= 0 ? "green" : "red";
  const arrow = percentageChange >= 0 ? <GoArrowUp /> : <GoArrowDown />;
  const weekpercentageColor = weeklypercentageChange >= 0 ? "green" : "red";
  const AdspercentageColor = totaladspercentageChange >= 0 ? "green" : "red";
  const WeeklyAdspercentageColor =
    weeklysales_percentage >= 0 ? "green" : "red";

  return (
    <>
      <div className="Sales_card">
        <h4 style={{ color: "#2196f3" }}>Sales Dashboard</h4>
        <div>
          <div className="ad-Panel mt-3">
            <div className="row">
              <div className="col-3">
                <div className="minicards">
                  <div className="top" style={{ padding: "20px" }}>
                    <p style={{ color: "#B5B5B5", fontSize: "20px" }}>
                      Total Revenue{" "}
                    </p>

                    <p
                      className="mt-2"
                      style={{
                        color: "#2196f3",
                        marginLeft: "12%",
                        fontSize: "24px",
                      }}
                    >
                      {" "}
                      ₹{currentMonthRevenue.toLocaleString()}
                    </p>

                    <p className="mt-2" style={{ color: "#B5B5B5" }}>
                      <span style={{ color: percentageColor }}>
                        {percentageChange.toFixed(2)}%
                      </span>
                      <span style={{ marginLeft: "3px" }}> Last Month</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="minicards">
                  <div className="top" style={{ padding: "20px" }}>
                    <p style={{ color: "#B5B5B5", fontSize: "20px" }}>
                      Weekly Revenue{" "}
                    </p>
                    <p
                      className="mt-2"
                      style={{
                        color: "#2196f3",
                        marginLeft: "12%",
                        fontSize: "24px",
                      }}
                    >
                      ₹{currentWeekRevenue.toLocaleString()}
                    </p>

                    <p className="mt-2" style={{ color: "#B5B5B5" }}>
                      <span style={{ color: weekpercentageColor }}>
                        {weeklypercentageChange.toFixed(2)}%
                      </span>
                      <span style={{ marginLeft: "3px" }}> Last Week</span>
                    </p>

                    {/* <p className='mt-0' style={{ color: "#242426" }}>  on March 2,2022</p> */}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="minicards">
                  <div className="top" style={{ padding: "20px" }}>
                    <p style={{ color: "#B5B5B5", fontSize: "20px" }}>
                      Total Sales{" "}
                    </p>
                    <p
                      className="mt-2"
                      style={{
                        color: "#2196f3",
                        marginLeft: "8%",
                        fontSize: "24px",
                      }}
                    >
                      {" "}
                      {currentMonthAds.toLocaleString()} Ads
                    </p>
                    <p className="mt-2" style={{ color: "#B5B5B5" }}>
                      <span style={{ color: AdspercentageColor }}>
                        {totaladspercentageChange.toFixed(2)}%
                      </span>
                      <span style={{ marginLeft: "3px" }}> Last Month</span>
                    </p>

                    {/* <p className='mt-0' style={{ color: "#242426" }}>  on March 2,2022</p> */}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="minicards">
                  <div className="top" style={{ padding: "20px" }}>
                    <p style={{ color: "#B5B5B5", fontSize: "20px" }}>
                      Weekly Sales{" "}
                    </p>
                    <p
                      className="mt-2"
                      style={{
                        color: "#2196f3",
                        marginLeft: "12%",
                        fontSize: "24px",
                      }}
                    >
                      {currentWeekAds.toLocaleString()} Ads
                    </p>
                    <p className="mt-2" style={{ color: "#B5B5B5" }}>
                      <span style={{ color: WeeklyAdspercentageColor }}>
                        {weeklysales_percentage.toFixed(2)}%
                      </span>
                      <span style={{ marginLeft: "3px" }}> Last Week</span>
                    </p>

                    {/* <p className='mt-0' style={{ color: "#242426" }}>  on March 2,2022</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="userdetails_cards mt-5">
        <div className="client_search-panel  justify-content-between">
          <div className="md:col-3 col-12">
            <div className="p-inputgroup">
              <div className="input-wrapper">
                <Dropdown
                  onChange={handledropdown}
                  options={datavalue}
                  value={subscription}
                  optionLabel="name"
                  placeholder="Select a Subcription"
                  className="w-full mb-4"
                />
              </div>
            </div>
          </div>
          <div className="md:col-3 col-12">
            <div className="p-inputgroup">
              <div className="input-wrapper">
                <InputText
                  type="text"
                  className="search-input p-inputtext p-component w-full"
                  placeholder="Search"
                  value={searchInput}
                  onChange={handlechanges}
                  style={{ height: "43px" }}
                />
                <IoSearchOutline
                  className="search-icon"
                  onClick={handleSearch}
                />
              </div>
            </div>
          </div>

          {/* <button onClick={handleSearch} className="client_search-btn">
            <IoSearchOutline className="search-icon-adv" />
          </button> */}
        </div>

        <div className="mt-3">
          <DataTable
            value={filteredProducts}
            paginator
            rows={10}
            tableStyle={{ minWidth: "50rem" }}
            onRowClick={onRowClick}
            selectionMode="single"
            dataKey="id"
            stateStorage="session"
            stateKey="dt-state-demo-local"
            emptyMessage="No customers found."
          >
            <Column
              header="S.No"
              style={{ width: "8%" }}
              body={(rowData) => (
                <span>{filteredProducts.indexOf(rowData) + 1}</span>
              )}
            ></Column>
            <Column
              field="customer_ID"
              header="Customer ID"
              style={{ width: "18%" }}
            />
            <Column
              field="customer_name"
              header="Customer Name"
              style={{ width: "18%" }}
            />
            <Column field="Country" header="Country" style={{ width: "13%" }} />
            <Column field="purchase_date" header="Date" />
          </DataTable>

          <Dialog
            header="Payment Details"
            visible={dialogVisible}
            style={{ width: "75vw" }}
            maximizable
            modal
            contentStyle={{ height: "300px" }}
            onHide={() => {
              resetGSTStates();
              setDialogVisible(false);
            }}
            footer={dialogFooterTemplate}
          >
            <DataTable
              value={maindetails}
              scrollable
              scrollHeight="flex"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column field="purchase_date" header="Date"></Column>
              <Column field="quantity" header="Unit"></Column>
              <Column field="Unit_price" header="Unit Price"></Column>
              <Column field="payment_type" header="Payment Type"></Column>
              <Column
                field="payment"
                header="Status"
                body={(rowData) => (
                  <span style={{ color: "green" }}>{rowData.payment}</span>
                )}
              ></Column>
              {showSGST && <Column field="SGST" header="SGST"></Column>}
              {showCGST && <Column field="CGST" header="CGST"></Column>}
              {showUTGST && <Column field="UTGST" header="UTGST"></Column>}
              {showIGST && <Column field="IGST" header="IGST"></Column>}
              <Column field="amount" header="Total Price"></Column>
            </DataTable>
          </Dialog>
        </div>
      </div>
    </>
  );
}

export default Sales_details;
