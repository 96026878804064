import React, { useState } from 'react'
import {
  Avatar, Box, Button, Card, Chip, Divider, styled, Tab, Tabs, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LocationIcon from '../../../../assets/Icons/CineJobLocation.png';
import RupeeIcon from '../../../../assets/Icons/CineJobRupee.png';
import ExperienceIcon from '../../../../assets/Icons/CineJobSuitcase.png';
import PhoneIcon from '../../../../assets/Icons/CineJobPhone.png';
import EmailIcon from '../../../../assets/Icons/CineJobEmail.png';
import LinkIcon from '../../../../assets/Icons/CineJobLink.png';
import OfficeIcon from '../../../../assets/Icons/CineJobOffice.png';
import { MdDownloading, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { ASSETS_API } from '../../../../config/api';
import { LuFileText } from "react-icons/lu";
import { TbClockCheck } from "react-icons/tb";
import moment from 'moment';

const JobContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginBottom: '0px'
}));

const JobIcon = styled(Avatar)(({ theme }) => ({
  width: '25px',
  height: '25px',
  marginRight: '4px'
}));

const SkillChip = styled(Chip)(({ theme }) => ({
  border: '2px solid #E6E6E6',
  color: '#4a4a4a',
  fontSize: '14px',
  backgroundColor: '#fff',
  borderRadius: '8px'
}));

const CineJobProfile = () => {
  const [tabValue, setTabValue] = useState(0)
  const location = useLocation();
  const navigate = useNavigate()
  const { userData, createdAt } = location.state || {};
  const {
    profileCount,
    user: {
      id,
      name,
      email,
      mobile_no,
      profile_image,
    }, educationInfo, jobInfo,
    userStatus
  } = userData;

  const getColor = (count) => {
    if (count === 0) return { color: '#FF763C', backgroundColor: '#FFEFE8' };
    if (count <= 40) return { color: '#EBAB05', backgroundColor: '#FAF6CD' };
    if (count <= 75) return { color: '#EBAB05', backgroundColor: '#FAF6CD' };
    return { color: '#0BAB66', backgroundColor: '#C8F4E1' };
  };

  const profileImageUrl = profile_image ? ASSETS_API.ASSETS_PROFILE_IMAGES(profile_image) : 'default-profile-image.jpg';
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const staticStatuses = [
    { status: 'applied', color: '#B5B5B5' },
    { status: 'shortlisted', color: '#B5B5B5' },
    { status: 'onHold', color: '#B5B5B5' },
    { status: 'interviewed', color: '#B5B5B5' },
    { status: 'hire', color: '#B5B5B5' }
  ];

  const getHighestStatusReached = (statusArray) => {
    const statusOrder = ['applied', 'shortlisted', 'onHold', 'interviewed', 'hire'];
    for (let i = statusOrder.length - 1; i >= 0; i--) {
      if (statusArray.some(item => item.status === statusOrder[i])) {
        return statusOrder[i];
      }
    }
    return null;
  };

  const highestStatusReached = getHighestStatusReached(userStatus);

  return (
    <Box>
      <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"} pt={{ xs: 5, md: 3 }} mb={2}>
        <Box>
          <span onClick={() => navigate('/main/advDasboared')} style={{ cursor: 'pointer' }}>
            <MdOutlineKeyboardArrowLeft style={{ width: '40px', height: '40px' }} />
            <span style={{ fontSize: '20px' }}>Back</span>
          </span>
        </Box>
      </Box>
      <Card sx={{ p: 3 }}>
        <Card >
          <Box display={"flex"} justifyContent={"space-evenly"} flexWrap={"wrap"} gap={3} p={3}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{
                height: 125,
                width: 130,
                borderRadius: '8px',
                backgroundColor: '#fff',
                padding: 8,
                textAlign: 'center',
                position: 'relative'
              }}
            >
              <Avatar
                src={profileImageUrl}
                alt=""
                style={{
                  objectFit: 'contain',
                  width: 100,
                  height: 100,
                }}
              />
              <Typography
                style={{
                  textAlign: "center",
                  position: 'absolute',
                  top: 100,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  ...getColor(parseInt(profileCount, 10)),
                  padding: '4px 7px 3px 7px',
                  borderRadius: 2,
                  fontSize: '16px',
                  lineHeight: '10px'
                }}
              >
                {profileCount}%
              </Typography>
              <Typography variant="body1" style={{ marginTop: 8 }}>
                {name}
              </Typography>
            </Box>
            {/* <Divider orientation="vertical" variant="middle" flexItem sx={{
       borderWidth: 1,
      }} /> */}
            <Box>
              <Typography variant="h6" mb={2} color={"#2196f3"}>Work Info</Typography>
              <Box display="flex" flexDirection={"column"} mb={1}>
                <Box display="flex" alignItems="center" mb={1}>
                  <JobIcon src={OfficeIcon} alt="" variant="square" />
                  <Typography variant="body2" color="#4a4a4a" ml={1}>
                    {jobInfo[jobInfo?.length - 1] ? jobInfo[jobInfo.length - 1]?.current_company : 'Not specified'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <JobIcon src={LocationIcon} alt="" variant="square" />
                  <Typography variant="body2" color="#4a4a4a" ml={1}>
                    {jobInfo[jobInfo?.length - 1] ? jobInfo[jobInfo.length - 1]?.currently_located : 'Not specified'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <JobIcon src={ExperienceIcon} alt="" variant="square" />
                  <Typography variant="body2" color="#4a4a4a" ml={1}>
                    {jobInfo[jobInfo?.length - 1] ? jobInfo[jobInfo.length - 1]?.work_experiance : 'Not specified'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <JobIcon src={RupeeIcon} alt="" variant="square" />
                  <Typography variant="body2" color="#2196f3" ml={1}>
                    {jobInfo[jobInfo?.length - 1] ? jobInfo[jobInfo.length - 1]?.salary_expectation : 'Not specified'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem sx={{
              borderWidth: 1,
            }} />
            <Box>
              <Typography variant="h6" mb={2} color={"#2196f3"}>Contact Info</Typography >
              <Box display="flex" flexDirection={"column"} mb={1}>
                <Box display="flex" alignItems="center" mb={1}>
                  <JobIcon src={EmailIcon} alt="" variant="square" />
                  <Typography variant="body2" color="#4a4a4a" ml={1}>
                    {email ? email : 'Not specified'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <JobIcon src={PhoneIcon} alt="" variant="square" />
                  <Typography variant="body2" color="#4a4a4a" ml={1}>
                    {mobile_no ? mobile_no : 'Not specified'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <JobIcon src={LocationIcon} alt="" variant="square" />
                  <Typography variant="body2" color="#4a4a4a" ml={1}>
                    {mobile_no ? mobile_no : 'Not specified'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <JobIcon src={LinkIcon} alt="" variant="square" />
                  {mobile_no ?
                    <Typography variant="body2" color="#2196f3" ml={1}>
                      {mobile_no}
                    </Typography> :
                    <Typography variant="body2" color="#4a4a4a" ml={1}>
                      Not specified
                    </Typography>}
                </Box>
              </Box>
            </Box>
            <Box alignSelf={"self-end"}>
              <Button variant='contained' sx={{
                textTransform: 'capitalize',
                backgroundColor: '#2196F3',
                color: '#FFF',
                '&:hover': {
                  backgroundColor: '#1976D2',
                }
              }}>
                Download Resume<MdDownloading style={{ width: '24px', height: '24px', marginLeft: '2px' }} />
              </Button>
            </Box>
          </Box>
        </Card>
        <Box sx={{ my: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Profile Details"
            sx={{
              '& .MuiTab-root': {
                color: '#8b8b8b',
                textTransform: 'capitalize',
                height: '55px',
                minHeight: '55px',
                display: 'flex',
                alignItems: 'center',
              },
              '& .Mui-selected': {
                color: '#2196F3',
                borderBottomColor: '#2196F3',
                background: 'rgba(33, 150, 243, 0.1)'
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#2196F3',
              },
            }}
          >
            <Tab
              icon={<LuFileText />}
              iconPosition="start"
              label={`Resume Details`}
              sx={{
                color: tabValue === 0 ? '#2196f3' : '#8b8b8b',
              }}
            />

            <Tab
              icon={<TbClockCheck />}
              iconPosition="start"
              label={`Hiring Pipeline`}
              sx={{
                color: tabValue === 1 ? '#2196f3' : '#8b8b8b',
              }}
            />
          </Tabs>
        </Box>
        <Box>
          {tabValue === 0 && (
            <Box>
              <Box mb={3}>
                <Typography variant="h6" color={"#9554FF"} sx={{ mb: 1 }}>Profile Summary</Typography>
                <Typography variant="body1" color={"#4b4b4b"}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic, et? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Culpa doloribus aspernatur minus laudantium neque minima asperiores et maiores dolorum! Nostrum?
                </Typography>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" color={"#9554FF"} mb={1}>
                  Key Skill
                </Typography>
                <JobContainer>
                  {(
                    <Typography variant="body2" color="#4A4A4A" >
                      Not specified
                    </Typography>
                  )}
                </JobContainer>
              </Box>

              <Box sx={{ position: 'relative' }}>
                <Typography variant="h6" sx={{ color: '#9554FF', mb: 1 }}>
                  Work Experience
                </Typography>
                <Box>
                  {jobInfo.map((item, index) => (
                    <Box key={index} sx={{ position: 'relative' }}>
                      {index < jobInfo.length - 1 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            left: 6,
                            top: 10,
                            height: '100%',
                            width: 2,
                            bgcolor: '#E6E6E6',
                            zIndex: 0,
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          bgcolor: '#B5B5B5',
                          zIndex: 1,
                          position: 'absolute',
                          left: 0,
                          top: 0,
                        }}
                      />
                      <Box sx={{ ml: 3, position: 'relative', pb: 3 }}>
                        <Typography variant="body1" fontWeight={500} textTransform="capitalize">
                          {item?.current_company}
                        </Typography>
                        <Typography display={"inline-block"} py={.4} px={.6} borderRadius={1} variant="caption" mb={1} textTransform="capitalize"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: {
                              xs: 'calc(100% - 50px)',
                              sm: 'calc(100% - 100px)',
                              md: '100%'
                            }
                          }} color="#2196f3" bgcolor={"#CDE9FF"}>
                          {item?.current_role}
                        </Typography>
                        {item?.start_career_year && item?.start_career_month &&
                          <Typography variant="body2" color="#8B8B8B" textTransform="capitalize" mb={1}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              maxWidth: {
                                xs: 'calc(100% - 50px)',
                                sm: 'calc(100% - 100px)',
                                md: '100%'
                              }
                            }}>
                            {moment(item?.start_career_month).format("YYYY")} - {moment(item?.start_career_year).format("YYYY")}
                          </Typography>
                        }
                        <Typography variant="body2" color="#8B8B8B" textTransform="capitalize" mb={1}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: {
                              xs: 'calc(100% - 50px)',
                              sm: 'calc(100% - 100px)',
                              md: '100%'
                            }
                          }}>
                          {item?.user_role}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box sx={{ position: 'relative' }}>
                <Typography variant="h6" sx={{ color: '#9554FF', mb: 1 }}>
                  Education
                </Typography>
                <Box>
                  {educationInfo.map((item, index) => (
                    <Box key={index} sx={{ position: 'relative' }}>
                      {/* Connector Line */}
                      {index < educationInfo.length - 1 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            left: 6,
                            top: 10,
                            height: '100%',
                            width: 2,
                            bgcolor: '#E6E6E6',
                            zIndex: 0,
                          }}
                        />
                      )}
                      {/* Timeline Dot */}
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          bgcolor: '#B5B5B5',
                          zIndex: 1,
                          position: 'absolute',
                          left: 0,
                          top: 0,
                        }}
                      />
                      {/* Content */}
                      <Box sx={{ ml: 3, position: 'relative', pb: 3 }}>
                        <Typography variant="body1" fontWeight={500} textTransform="capitalize" mb={1}>
                          {item?.collegename}
                        </Typography>
                        <Typography display={"inline-block"} py={.4} px={.6} borderRadius={1} variant="caption" mb={1} textTransform="capitalize"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: {
                              xs: 'calc(100% - 50px)',
                              sm: 'calc(100% - 100px)',
                              md: '100%'
                            }
                          }} color="#2196f3" bgcolor={"#CDE9FF"}>
                          {item?.collegedegree}
                        </Typography>
                        <Typography variant="body2" mb={1} color="#8B8B8B" textTransform="capitalize"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: {
                              xs: 'calc(100% - 50px)',
                              sm: 'calc(100% - 100px)',
                              md: '100%'
                            }
                          }}>
                          {moment(item?.collegestartYear).format("YYYY")} - {moment(item?.collegeendYear).format("YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box>
                <Typography variant="h6" color={"#9554FF"}>Download Resume
                </Typography>
                <Box alignSelf={"self-end"}>
                  <Button variant='outlined' sx={{
                    mb: 2, borderColor: '#2196f3',
                    color: '#2196f3',
                    '&:hover': {
                      backgroundColor: 'rgba(33, 150, 243, 0.1)',
                      borderColor: '#2196f3',
                    }, textTransform: 'capitalize'
                  }}>
                    Download Resume<MdDownloading style={{ width: '24px', height: '24px', marginLeft: '2px' }} />
                  </Button>
                </Box>
              </Box>

            </Box>
          )}
          {tabValue === 1 && (
            <Box sx={{ position: 'relative' }}>
              <Typography variant="h6" sx={{ color: '#9554FF', mb: 1 }}>
                Status
              </Typography>
              <Typography variant="body2" sx={{ color: '#4b4b4b', mb: 1 }}>
                Publish on {moment(createdAt.split("Z")[0]).format('MMMM DD, YYYY [at] h:mm A')}
              </Typography>
              <Box>
                {staticStatuses.map((staticItem, index) => {
                  const matchingItem = userStatus.find(item => item.status === staticItem.status);
                  return (
                    <Box key={staticItem.status} sx={{ position: 'relative' }}>
                      {/* Connector Line */}
                      {index < staticStatuses.length - 1 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            left: 6,
                            top: 10,
                            height: '100%',
                            width: 2,
                            bgcolor: matchingItem ? "#E9F5FF" : '#E6E6E6',
                            zIndex: 0,
                          }}
                        />
                      )}
                      {/* Timeline Dot */}
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          bgcolor: matchingItem ? "#2196F3" : '#B5B5B5',
                          zIndex: 1,
                          position: 'absolute',
                          left: 0,
                          top: 0,
                        }}
                      />
                      {/* Content */}
                      <Box sx={{ ml: 3, position: 'relative', pb: 3 }}>
                        <Typography variant="body1" fontWeight={500} textTransform="capitalize">
                          {staticItem.status}
                        </Typography>
                        <Typography variant="body2" color="#8B8B8B" textTransform="capitalize" mb={1}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: {
                              xs: 'calc(100% - 50px)',
                              sm: 'calc(100% - 100px)',
                              md: '100%'
                            }
                          }}>
                          {matchingItem ? moment(matchingItem.statusDate.split("Z")[0]).format('MMM DD YYYY hh:mmA') : 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  )
}

export default CineJobProfile
