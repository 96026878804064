import { useState } from 'react';

export const useFormData = () => {
  const [formData, setFormData] = useState({
    itemName: "",
    category: "",
    lostAt: "",
    foundAt: "",
    street: "",
    landmark: "",
    area: "",
    city: "",
    district: "",
    pincode: "",
    state: "",
    country: "",
    description: "",
    dateTime: "",
    name: "",
    email: "",
    contactNo: "",
    images: [],
  });

  return [formData, setFormData];
};
