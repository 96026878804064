import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdOutlineDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Dialog } from "primereact/dialog";
import { IoMdAdd } from "react-icons/io";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import "./companyprofile.css";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Nodeapi } from "../config/serverUrl";
import axios from "axios";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";

import { countries, indianStates } from "../Component/Profile/Dropdown_list";

const AssetGroup = () => {
  const [visible, setVisible] = useState(false);
  const [Update, setUpdate] = useState(false);
  const authdata = useSelector((state) => state.auth.user);
  const data_initialvalues = {
    nameofcompany: "",
    companywebsite: "",
    project_name: "",
    phonenumber: "",
    emailaddress: "",
    division: "",
    otherdivision: "",
    buildingname: "",
    doornumber: "",
    street: "",
    city: "",
    state: "",
    country: "",
  };

  const [data, setdata] = useState(data_initialvalues);
  const [displaycontact, setdisplaycontact] = useState([]);
  const [selected, setSelected] = React.useState(null);
  const [datevalue, setdatevalue] = useState("");
  const [phone, setphone] = useState("");
  const [emailError, setEmailError] = useState("");
  const userid = authdata.id;

  useEffect(() => {
    fetchcontactdata(userid);
  }, [userid]);

  const fetchcontactdata = async () => {
    try {
      const res = await axios.get(
        `${Nodeapi}/fetchcompanycontactdata?id=${userid}`
      );

      const data = res.data.data.fetchdata;
      setdisplaycontact(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
    }
  };

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelected(rowData);
      menuLeft.current.toggle(event);
    };

    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  const menuLeft = useRef(null);

  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selected),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selected),
    },
  ];

  const handleEdit = (rowData) => {
    setVisible(true);
    setUpdate(true);
    setdata(rowData);
    setphone(rowData.phonenumber);
  };

  const deleteContactData = async (id) => {
    try {
      await axios.delete(`${Nodeapi}//deletecompanycontactData/${id}`);
      fetchcontactdata(userid);
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
  };

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData.id);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        fetchcontactdata(userid);
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Invalid Email Address",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    try {
      if (Update) {
        const response = await axios.post(`${Nodeapi}/Updatecompanycontact`, {
          ...data,
          id: data.id,
          userid: data.userid,
        });
        const newdata = response.data.data.response;
        if (response) {
          setVisible(false);
          setdisplaycontact(newdata);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          fetchcontactdata(userid);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
          setVisible(false);
        }
      } else {
        const isEmpty = Object.values(data).every((value) => value === "");

        if (isEmpty) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please Fill Out All Required Fields",
            showConfirmButton: false,
            timer: 2000,
          });
          return setVisible(false);
        }

        const json_data = {
          insertdata: data,
        };

        const response = await axios.post(
          `${Nodeapi}/companycontact`,
          json_data
        );
        const newData = response.data.data.aboutdata;
        if (response) {
          setVisible(false);
          setdisplaycontact([...displaycontact, newData[0]]);

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Inserted Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          fetchcontactdata(userid);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
          setVisible(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setdata("");
    setVisible(false);
    setEmailError("");
  };

  const handleopen = () => {
    setVisible(true);
    setdata(data_initialvalues);
    setUpdate(false);
    setdatevalue("");
    setphone("");
    setEmailError("");
  };

  const handelvalues = (e) => {
    const { id, value } = e.target;
    setdata({ ...data, [id]: value, userid: authdata?.id });

    if (id === "emailaddress") {
      validateEmail(value);
    }
  };

  const handlemobileno = (value) => {
    const newDob = value;

    setphone(newDob);
    setdata({
      ...data,
      phonenumber: newDob,
    });
  };

  const division = [
    "Branch",
    "Construction Site",
    "Franchise",
    "Go Down",
    "Head Quarter",
    "Off Premises",
    "Off Shore",
    "Off Site",
    "On Premises",
    "On Shore",
    "On Site",
    "Others",
    "Outsource",
    "Virtual",
    "Warehouse",
  ];

  return (
    <div>
      <div className="asset-table align-items-center mb-3 flex justify-content-end">
        <button
          className="btn btn-add"
          style={{ marginRight: "2px" }}
          onClick={handleopen}
        >
          <IoMdAdd className="mb-1" />
        </button>
      </div>

      <Dialog
        header="Contact Us"
        visible={visible}
        style={{ width: "42vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setVisible(false)}
      >
        <div>
          <div className="row">
            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Name of Comapny
              </label>
              <input
                className="form-control"
                type="text"
                id="nameofcompany"
                onChange={handelvalues}
                value={data?.nameofcompany}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Company Website
              </label>
              <input
                className="form-control"
                type="text"
                id="companywebsite"
                onChange={handelvalues}
                value={data?.companywebsite}
              />
            </div>
            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="project_name">
                Project Name
              </label>
              <input
                className="form-control"
                type="text"
                id="project_name"
                onChange={handelvalues}
                value={data?.project_name}
              />
            </div>
            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Division
              </label>
              <select
                className="form-select"
                id="division"
                value={data.division}
                onChange={handelvalues}
              >
                <option value="">Select Division</option>
                {division.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>

              {data.division === "Others" && (
                <input
                  type="text"
                  id="otherdivision"
                  className="form-control mt-3"
                  name="otherdivision"
                  value={data.otherdivision}
                  onChange={handelvalues}
                />
              )}
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Building Name
              </label>
              <input
                className="form-control"
                type="text"
                id="buildingname"
                onChange={handelvalues}
                value={data?.buildingname}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Door Number
              </label>
              <input
                className="form-control"
                type="text"
                id="doornumber"
                onChange={handelvalues}
                value={data?.doornumber}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Street
              </label>
              <input
                className="form-control"
                type="text"
                id="street"
                onChange={handelvalues}
                value={data?.street}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                City
              </label>
              <input
                className="form-control"
                type="text"
                id="city"
                onChange={handelvalues}
                value={data?.city}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                State
              </label>
              <select
                className="form-select"
                id="state"
                value={data.state}
                onChange={handelvalues}
              >
                <option value="">Select State</option>
                {indianStates.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Country
              </label>
              <select
                className="form-select"
                id="country"
                value={data.country}
                onChange={handelvalues}
              >
                <option value="">Select Country</option>
                {countries.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Email Address
              </label>
              <input
                className="form-control"
                type="text"
                id="emailaddress"
                onChange={handelvalues}
                value={data?.emailaddress}
                required
              />
              {emailError && <small className="p-error">{emailError}</small>}
            </div>
            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="vehicleNumber">
                Mobile No
              </label>
              <PhoneInput
                country={"in"}
                value={phone}
                onChange={handlemobileno}
                className="border-example "
              />
            </div>
          </div>

          <div className="text-center mt-2">
            <button
              className="btn btn-danger me-2"
              style={{ marginLeft: "21px" }}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSubmit}>
              {Update ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </Dialog>

      <DataTable value={displaycontact} tableStyle={{ minWidth: "50rem" }}>
        <Column
          header="S.No"
          body={(rowData) => <span>{displaycontact.indexOf(rowData) + 1}</span>}
        ></Column>
        <Column
          field="nameofcompany"
          header="Organization name"
          style={{ minWidth: "160px" }}
        />
        <Column
          field={(rowData) =>
            rowData.division === "Others"
              ? rowData.otherdivision
              : rowData.division
          }
          header="Division"
        />
        <Column field="emailaddress" header="Email Address" />
        <Column
          field="phonenumber"
          header="Mobile Number"
          style={{ minWidth: "160px" }}
        />
        <Column field="companywebsite" header="Websites" />
        <Column
          field="Address"
          header="Address"
          body={(rowData) =>
            rowData.doornumber &&
            rowData.buildingname &&
            rowData.street &&
            rowData.city &&
            rowData.state &&
            rowData.country
              ? `${rowData.doornumber} .${rowData.buildingname},${rowData.street},${rowData.city},${rowData.state},${rowData.country}`
              : ""
          }
        />
        <Column header="Actions" body={actions}></Column>
      </DataTable>
    </div>
  );
};

export default AssetGroup;