import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Nodeapi } from "../../../config/serverUrl";
import { districtsInIndia } from "../../Profile/Dropdown_list";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Modal, styled } from "@mui/material";
import { AiFillCheckSquare, AiFillCloseSquare } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import "../blooddonation.css";
import { formatDate } from "../BloodCommon";

const bloodgroup = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const DonorManagementList = () => {
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;
  const [donorManagement, setDonorManagement] = useState([]);
  const [requests, setRequests] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [formData, setFormData] = useState({
    available_date: "",
    available_time: "",
    blood_units: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDonorManagement = async () => {
      try {
        const response = await axios.get(
          `${Nodeapi}/blood-donation/donorManagement`
        );
        setDonorManagement(response.data);
      } catch (error) {
        console.error("Error fetching donor management data:", error);
      }
    };

    const fetchRequests = async () => {
      try {
        const response = await axios.get(`${Nodeapi}/blood-donation/requests`);
        setRequests(response.data);
      } catch (error) {
        console.error("Error fetching requests:", error);
      }
    };

    fetchDonorManagement();
    fetchRequests();
  }, [userId]);

  const combinedData = useMemo(() => {
    const pendingDonors = donorManagement.filter(
      (donor) => donor.donor_id == userId
    );
    const requestIds = pendingDonors.flatMap((donor) => donor.request_id);

    return requests
      .filter((request) => requestIds.includes(request.id))
      .map((request, index) => {
        const donor = donorManagement.find(
          (d) => d.request_id === request.id && d.donor_id == userId
        );
        return {
          id: request.id,
          display_id: index + 1,
          patient_id: request.patient_id,
          blood_group: request.blood_group,
          district: request.district,
          from_date_required: formatDate(request.from_date_required),
          to_date_required: formatDate(request.to_date_required),
          urgency_level: request.urgency_level,
          blood_component_type: request.blood_component_type,
          units_required: request.units_required,
          status: donor ? donor.donor_status : "Pending",
        };
      });
  }, [donorManagement, requests, userId]);

  const filtered = useMemo(() => {
    return combinedData.filter((item) => {
      return (
        (selectedDistrict ? item.district === selectedDistrict : true) &&
        (selectedBloodGroup ? item.blood_group === selectedBloodGroup : true) &&
        (selectedPatientId ? item.patient_id.includes(selectedPatientId) : true)
      );
    });
  }, [combinedData, selectedDistrict, selectedBloodGroup, selectedPatientId]);

  const updateDonorStatus = async (requestId, status, available_date, available_time, blood_units) => {
    try {
      await axios.patch(
        `${Nodeapi}/blood-donation/donorManagement/${userId}/${requestId}`,
        {
          donor_status: status,
          available_date,
          available_time,
          blood_units,
        }
      );
    } catch (error) {
      console.error(`Error updating donor status to ${status}:`, error);
    }
  };
  const handleAccept = (requestId) => {
    setCurrentRequestId(requestId);
    setModalOpen(true);
  };
  const handleReject = async (requestId) => {
    await updateDonorStatus(requestId, "Rejected");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { available_date, available_time, blood_units } = formData;
    if (available_date && available_time && blood_units) {
      await updateDonorStatus(
        currentRequestId,
        "Accepted",
        available_date,
        available_time,
        blood_units
      );
      setModalOpen(false);
      navigate(`/main/DonorManagementView/${currentRequestId}`);
    } else {
      alert("Please fill out all fields.");
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleRowClick = (params) => {
    if (params.row.status === "Accepted") {
      navigate(`/main/DonorManagementView/${params.id}`);
    }
  };

  const statusStyles = {
    Accepted: {
      backgroundColor: "#EDFFF3",
      borderColor: "#17A536",
      color: "#17A536",
    },
    Pending: {
      backgroundColor: "#fff3cd",
      borderColor: "#FFA502",
      color: "#FFA502",
    },
    Rejected: {
      backgroundColor: "#FFEDED",
      borderColor: "#E72121",
      color: "#E72121",
    },
    Default: {
      backgroundColor: "#ffffff",
      borderColor: "#e0e0e0",
      color: "#000000",
    },
  };

  const columns = [
    { field: "display_id", headerName: "ID", width: 50 },
    { field: "patient_id", headerName: "Patient ID", width: 120 },
    { field: "blood_group", headerName: "Blood Group", width: 120 },
    { field: "district", headerName: "District", width: 120 },
    { field: "blood_component_type", headerName: "Component", width: 130 },
    { field: "units_required", headerName: "Units", width: 90 },
    { field: "from_date_required", headerName: "From Date", width: 120 },
    { field: "to_date_required", headerName: "To Date", width: 120 },
    { field: "urgency_level", headerName: "Urgency Level", width: 130 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const status = params.value;
        const { backgroundColor, borderColor, color } =
          statusStyles[status] || statusStyles.Default;
        return (
          <div
            style={{
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              color: color,
              width: "80px",
              padding: "4px",
              borderRadius: "4px",
              border: "1px solid",
              textAlign: "center",
            }}
          >
            {status}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        const isAcceptedOrRejected =
          params.row.status === "Accepted" || params.row.status === "Rejected";
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "15px",
            }}
          >
            {!isAcceptedOrRejected && (
              <>
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "25px",
                    color: "#2196F3",
                  }}
                  onClick={() => {
                    handleAccept(params.id);
                  }}
                >
                  <AiFillCheckSquare />
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "25px",
                    color: "#FF384A",
                  }}
                  onClick={() => {
                    handleReject(params.id);
                  }} >
                  <AiFillCloseSquare />
                </span>
              </>
            )}
          </div>
        );},},];

  const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    borderRadius: "12px",
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#2196F3",
      color: "#FFF",
      fontWeight: "500",
      fontSize: "16px",
      padding: "5px",
    },
    "& .MuiDataGrid-cell": {
      padding: "20px",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: theme.palette.grey[200],
    },
    "& .MuiDataGrid-toolbarContainer": {
      marginBottom: "16px",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  }));
  return (
    <div>
      <div className="search-filter">
        <div style={{ position: "relative", flex: "1 1 100%", maxWidth: "350px" }}>
          <select
            className="form-select"
            name="district"
            id="district"
            value={selectedDistrict}
            onChange={(e) => setSelectedDistrict(e.target.value)}>
            <option value="">Select District</option>
            {districtsInIndia.map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
          </select>
          {selectedDistrict && (
            <IoIosClose onClick={() => setSelectedDistrict("")}
              style={{
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "30px",
              }}/>)}
        </div>
        <div style={{ position: "relative", flex: "1 1 100%", maxWidth: "150px" }}>
          <select
            className="form-select"
            id="bloodGroup"
            name="bloodGroup"
            value={selectedBloodGroup}
            onChange={(e) => setSelectedBloodGroup(e.target.value)}>
            <option value="">Blood Group</option>
            {bloodgroup
              .sort((a, b) => a.localeCompare(b))
              .map((bg, index) => (
                <option key={index} value={bg}>
                  {bg}
                </option>
              ))}
          </select>
          {selectedBloodGroup && (
            <IoIosClose onClick={() => setSelectedBloodGroup("")}
              style={{
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "30px",
              }}/>)}
        </div>
        <div style={{ position: "relative", flex: "1 1 100%", maxWidth: "150px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Patient ID"
            value={selectedPatientId}
            onChange={(e) => setSelectedPatientId(e.target.value)}
          />
          {selectedPatientId && (
            <IoIosClose onClick={() => setSelectedPatientId("")}
              style={{
                position: "absolute",
                right: "8px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "30px",
              }}/>)}
        </div>
      </div>

      <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
        {filtered.length > 0 ? (
          <CustomDataGrid
            rows={filtered}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            onRowClick={handleRowClick}
            style={{ flex: 1 }}
          />
        ) : (
          <div className="no-request">No blood requests found</div>
        )}
      </div>
      {modalOpen && (
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box  className="scrollBarHidden"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "#FFF",
              border: "none",
              borderRadius: "12px",
              boxShadow: 24,
              p: 4,
            }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5 className="mb-2">Donor Availability</h5>
              <IoIosClose onClick={handleCloseModal}
                style={{
                  cursor: "pointer",
                  fontSize: "30px",
                  color: "#8B8B8B",
                }}/>
            </div>
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
              <label className="mb-1" htmlFor="available_date"> Available Date</label>
              <input
                type="date"
                name="available_date"
                className="form-control"
                value={formData.available_date}
                onChange={handleInputChange}/>
              <label className="mb-1" htmlFor="available_time">Available Time</label>
              <input
                type="time"
                name="available_time"
                className="form-control"
                value={formData.available_time}
                onChange={handleInputChange} />
              <label className="mb-1" htmlFor="units"> Units of Blood</label>
              <input
                type="number"
                name="blood_units"
                className="form-control"
                value={formData.blood_units}
                onChange={handleInputChange}/>
              <div className="text-center mt-3">
                <button className="btn btn-danger me-4" onClick={handleCloseModal}> Cancel</button>
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}> Submit </button>
              </div>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );};
export default DonorManagementList;
