import React, { useState,useEffect,useContext } from "react";
import "./Accountsharingactivity.css";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaPause } from "react-icons/fa";
import { PiDotsThreeOutlineVertical } from "react-icons/pi";
import { Nodeapi } from "../../config/serverUrl";
import axios from "axios";
import { DataContext } from "../../Admin/DataContext";
import { useNavigate } from "react-router-dom";
import { Try } from "@mui/icons-material";
import Swal from 'sweetalert2';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function createData(email, accessCategory, accessState) {
  return { email, accessCategory, accessState };
}


const rows = [
  createData("user1@example.com", "HRMS ; payroll", "Active"),
  createData("user2@example.com", "Payroll ; Accounts", "Currently inactive"),
  createData("user3@example.com", "Projects ; Finance", "Active"),
  createData("user4@example.com", "Accounts", "Currently inactive"),
  createData("user5@example.com", "Finance ; HRMS", "Active"),
];

const Accountsharingactivity = () => {


  const {
    setedit_account,
    setvisible
  } = useContext(DataContext);

  const navigate = useNavigate()
 
  const [totaldata,settotaldata] = useState([])
  const[acitive_btn,setacitive_btn]= useState(false)
  const [anchorEls, setAnchorEls] = useState(Array(totaldata.length).fill(null));
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
useEffect(()=>{

getdata()
},[])

const getdata = async ()=>{
  const response = await axios.get(`${Nodeapi}/GetAccountCategory`)
  const data = response.data.result

  settotaldata(data)
}
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClick = (event, index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const handleMenuItemClick = async (index) => {
    try {
      const filterdata = totaldata[index];

      let val = filterdata.id;
      let newState = filterdata.Access_State === 1 ? 0 : 1;
   
      const res = await axios.post(`${Nodeapi}/UpdateAccountAccess`, {
        id: val,
        newState: newState
      });

      const data = res.data;
      if (data.success === true) {
        getdata();
      }
    } catch (error) {
      console.error(error);
    }

    handleClose(index);
  };

  const handleedit = (index)=>{
    const filterdata = totaldata[index];
    setedit_account(filterdata)
    setvisible(true)
    navigate('/main/AccountSharing')

  }

  const handleremove = async (val)=>{
    
    try {
      const res = await axios.delete(`${Nodeapi}/deleteaccout?id=${val}`)
      const data = res.data
      getdata()
    } catch (error) {
      console.log('error:',error);
    }
  }

  const handleDelete = async (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
   
    if (result.isConfirmed) {
      const filterdata = totaldata[index];
    let val = filterdata.id;
      try {
        await handleremove(val);
        Swal.fire({
          title: 'Deleted!',
          text: 'User has been Deleted.',
          icon: 'success'
        });
      
       

      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  }
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const rowsToDisplay = totaldata.slice(startIndex, endIndex);

  return (
    <div className="Accountsharingactivity">
      <div className="card">
        <div className="header d-flex">
          {/* <Link to="/main">
            <IoIosArrowBack className="icon" />
          </Link> */}
          <h3 className="h-title">Activity</h3>
        </div>
        <div className="content">
          {/* <p
            style={{
              background: "#DD6945",
              display: "inline",
              padding: "6px 15px",
              borderRadius: "6px",
              color: "white",
              marginBottom: "20px",
            }}
          >
            Currently your account is managed by recipient1@kodukku.com
          </p> */}
          <div className="content-table">
          <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: '600', width: '5%' }}>S.No</TableCell>
              <TableCell style={{ fontWeight: '600', width: '10%' }}>UserName</TableCell>
              <TableCell style={{ fontWeight: '600', width: '0%' }}>KID ID</TableCell>
              <TableCell style={{ fontWeight: '600', width: '10%' }} align="right">
                Access Category
              </TableCell>
              <TableCell style={{ fontWeight: '600', width: '10%' }} align="right">
                Access State
              </TableCell>
              <TableCell style={{ fontWeight: '600', width: '17%' }} align="right">
                Manage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay.map((row, index) => (
              <TableRow
                key={row.Kid_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" >
                  {startIndex + index + 1}
                </TableCell>
                <TableCell component="th" scope="row" style={{ color: '#2196F3' }}>
                  {row.username}
                </TableCell>
                <TableCell component="th" scope="row" style={{ color: '#2196F3' }}>
                  {row.Kid_id}
                </TableCell>
                <TableCell align="right" style={{ color: '#8B8B8B' }}>
                  {[
                    row.HRMS === '1' ? 'HRMS' : null,
                    row.Payroll === '1' ? 'Payroll' : null,
                  ]
                    .filter(Boolean)
                    .join(', ') || 'No Account Access'}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ color: row.Access_State === 1 ? '#08BB50' : '#DBAB00' }}
                >
                  {row.Access_State === 1 ? 'Active' : 'InActive'}
                </TableCell>
                <TableCell align="right" style={{ color: '#2196F3' }}>
                  <Button
                    id={`basic-button-${startIndex + index}`}
                    aria-controls={anchorEls[startIndex + index] ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEls[startIndex + index] ? 'true' : undefined}
                    onClick={(event) => handleClick(event, startIndex + index)}
                  >
                    <PiDotsThreeOutlineVertical />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEls[startIndex + index]}
                    open={Boolean(anchorEls[startIndex + index])}
                    onClose={() => handleClose(startIndex + index)}
                    MenuListProps={{ 'aria-labelledby': `basic-button-${startIndex + index}` }}
                  >
                    <MenuItem
                      style={{ color: '#8B8B8B' }}
                      onClick={() => handleMenuItemClick(startIndex + index)}
                    >
                      {row.Access_State === 1 ? 'InActive' : 'Active'}
                    </MenuItem>
                    <MenuItem style={{ color: '#8B8B8B' }} onClick={() => handleedit(startIndex + index)}>
                      Edit
                    </MenuItem>
                    <MenuItem style={{ color: '#8B8B8B' }} onClick={() => handleDelete(startIndex + index)}>
                      Remove
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>   
     
      </TableContainer>
    <Stack spacing={2} style={{marginLeft:"470px",marginTop:"23px"}}>
        <Pagination
          count={Math.ceil(totaldata.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accountsharingactivity;
