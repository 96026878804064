// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import persistedReducer from './slice'; // Assuming the slice is in slice.js
import chatSlice from './slices/chatSlice';
import socketSlice from './slices/socketSlice';
import tabSlice from './slices/postAddSlice';
import filterSlice from './slices/filterSlice';

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    chat:chatSlice,
    socket:socketSlice,
    tab:tabSlice,
    filter: filterSlice,
  },
});

export const persistor = persistStore(store);
export default store;
