// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Accountsharingactivity {
}
.Accountsharingactivity .card {
  padding: 35px 20px;
}
.Accountsharingactivity .card a {
  text-decoration: none;
}
.Accountsharingactivity .card a .icon {
  font-size: 1.7rem;
}

.Accountsharingactivity .card .header {
  color: #2196f3;
  /* align-items: center; */
  text-decoration: none;
}

.Accountsharingactivity .card .header i {
  font-size: 25px;
  text-decoration: none;
  color: #2196f3;
}

.Accountsharingactivity .card .header h3 {
  font-size: 24px;
  margin-left: 16px;
  font-weight: 600;
}

.Accountsharingactivity .card .content {
  margin: 10px 40px;
}

@media screen and (max-width: 768px) {
  .Accountsharingactivity .card {
    padding: 24px;
  }
  .Accountsharingactivity .card .content {
    margin: 10px 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/AccountSharing/Accountsharingactivity.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".Accountsharingactivity {\n}\n.Accountsharingactivity .card {\n  padding: 35px 20px;\n}\n.Accountsharingactivity .card a {\n  text-decoration: none;\n}\n.Accountsharingactivity .card a .icon {\n  font-size: 1.7rem;\n}\n\n.Accountsharingactivity .card .header {\n  color: #2196f3;\n  /* align-items: center; */\n  text-decoration: none;\n}\n\n.Accountsharingactivity .card .header i {\n  font-size: 25px;\n  text-decoration: none;\n  color: #2196f3;\n}\n\n.Accountsharingactivity .card .header h3 {\n  font-size: 24px;\n  margin-left: 16px;\n  font-weight: 600;\n}\n\n.Accountsharingactivity .card .content {\n  margin: 10px 40px;\n}\n\n@media screen and (max-width: 768px) {\n  .Accountsharingactivity .card {\n    padding: 24px;\n  }\n  .Accountsharingactivity .card .content {\n    margin: 10px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
