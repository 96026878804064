import React from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import axios from "axios";
import { authapi } from "../../config/serverUrl";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./loader.css";
import { useNavigate } from "react-router";
import Calendars from "../../utils/Calender";

const ChangePassword = ({ mobile, email }) => {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const toast = useRef(null);
  const [hasLoader, setHasLoader] = useState(false);

  const [date, setDate] = useState(null);
  const [DateError, setDateError] = useState("");
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible(!confirmPasswordVisible);

  // const formattedDob = date ? date.toISOString().split('T')[0] : '';

  const onchageDate = async (e) => {
    setDateError("");
    setDate(e.value);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // Handle form submission here
    if (date == null) {
      setDateError("Date of Birth is required");
    } else {
      setHasLoader(true);

      const res = await axios.post(`${authapi}/auth/reset_password`, {
        email: email,
        mobile: mobile,
        password: values.password,
        dob: date,
      });

      if (res.data) {
        if (res.data.code == 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.data.message,
            life: 3000,
          });
          setHasLoader(false);

          navigate("/login", { state: { reset: "success" } });
        } else {
          setHasLoader(false);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.data.data.message,
            life: 3000,
          });
        }
      }
    }
  };
  const handledate = (e) => {
    const selectedDate = new Date(e.target.value);
    const utcDate = new Date(
      Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      )
    );
    setDate(utcDate);
  };

  console.log("date", date);

  return (
    <>
      <Toast ref={toast} />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors }) => (
            <Form>
             <div className="calendar-container">
             <p className="mt-3" style={{ color: "#8b8b8b" }}>
                Date Of Birth
              </p>
              <div className="row">
                <div className="col-md-6 col-12">
                  {/* <Calendar
                id="buttondisplay"
                value={date}
                onChange={(e)=>setDate(e.value)}
                showIcon
                name="dateOfBirth"
                // Adjust the month index by adding 1
                monthNavigator
                yearNavigator
                yearRange="1500:2024"
                dateFormat="dd/mm/yy"
                inputId="in"
              /> */}

                  <Calendar
                    inputId="birth_date"
                    dateFormat="dd/mm/yy"
                    id="buttondisplay"
                    name="dateOfBirth"
                    value={date}
                    onChange={handledate}
                    placeholder="Date of Birth"
                    style={{
                      height: "43px",
                      borderTopLeftRadius: "6px",
                      borderBottomLeftRadius: "6px",
                    }} // Set the width to 300px
                  />

                  {/* Display error message for date of birth */}
                  {DateError ? (
                    <div style={{ color: "red" }}>{DateError}</div>
                  ) : null}
                </div>
                <div className="col-6"></div>
              </div>
             </div>

             <div className="row">
      <div className="col-md-6 col-12">
        <div className="password-field-container">
          <Field
            className={`form-control${errors.password ? ' is-invalid' : ''}`}
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            placeholder="Create Password"
            style={{
              borderLeft: "2px solid #e6e6e6",
              padding: "8px",
              borderLeft: errors.password ? '2px solid red' : '2px solid #d1d5db'
            }}
          />
          <span className="toggle-password" onClick={togglePasswordVisibility}>
            {passwordVisible ? <FaEyeSlash style={{color:"#2196F3"}}/> : <FaEye style={{color:"#2196F3"}}/>}
          </span>
        </div>
        <ErrorMessage style={{ color: 'red' }} name="password" component="div" />
      </div>
      <div className="col-md-6 col-12">
        <div className="password-field-container">
          <Field
            className={`form-control${errors.confirmPassword ? ' is-invalid' : ''}`}
            type={confirmPasswordVisible ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Confirm Password"
            style={{
              borderLeft: "2px solid #e6e6e6",
              padding: "9px",
              borderLeft: errors.confirmPassword ? '2px solid red' : '2px solid #d1d5db'
            }}
          />
          <span className="toggle-password" onClick={toggleConfirmPasswordVisibility}>
            {confirmPasswordVisible ? <FaEyeSlash style={{color:"#2196F3"}}/> : <FaEye style={{color:"#2196F3"}}/>}
          </span>
        </div>
        <ErrorMessage style={{ color: 'red' }} name="confirmPassword" component="div" />
      </div>
    </div>

              <div className="d-flex justify-content-between">
                <p></p>

                {!hasLoader ? (
                  <button
                    style={{
                      backgroundColor: "#2196f3",
                      color: "white",
                    }}
                    disabled={!mobile}
                    type="submit"
                    className="btn btn-verify mt-3"
                  >
                    {" "}
                    Continue
                  </button>
                ) : (
                  <button
                    className="btn btn-verify btn-verify"
                    value="sendmailotp"
                    type="submit"
                    style={{
                      backgroundColor: "#2196f3",
                      color: "white",
                      width: "113px",
                      height: "43px",
                    }}
                  >
                    <span class="loader"></span>
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ChangePassword;
