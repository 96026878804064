import React, { useEffect, useState } from 'react';
import { TextField, FormControl, Grid, Typography, FormLabel, Box, Autocomplete, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import Category from '../data/Category';

// Styled TextField for Autocomplete
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    borderColor: '#2196f3',
    '&:hover fieldset': {
      borderColor: '#2196f3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#2196f3',
  },
}));

// Styled Popper for Autocomplete dropdown
const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    borderRadius: '10px',
  },
  '& .MuiAutocomplete-option': {
    '&[data-focus="true"]': {
      backgroundColor: '#e3f2fd',
      color: '#2196f3',
    },
  },
}));

const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    borderColor: '#2196f3',
    '&:hover fieldset': {
      borderColor: '#2196f3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#2196f3',
  },
};

const chipStyles = {
  margin: 5,
};

const chipSquareStyles = {
  margin: 5,
  borderRadius: 10, // Make chips square
  width: 40, // Set a fixed width
  height: 40, // Set a fixed height
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const StyledChip = styled(Chip)(({ theme, selected }) => ({
  margin: theme.spacing(2),
  borderRadius: '15px',
  backgroundColor: selected ? '#2196f3' : '#fff',
  border: `1px solid ${selected ? '#2196f3' : '#BBB9B9'}`,
  color: selected ? '#fff' : '#BBB9B9',
  '&:hover': {
    backgroundColor: selected ? '#1976d2' : 'rgba(33, 150, 243, 0.1)',
    border: '1px solid #2196F3',
  },
  '& .MuiChip-deleteIcon': {
    color: selected ? '#fff' : '#2196F3',
  },
}));

const JobDetailsForm = ({ jobDetails, setJobDetails, errorsJobDetails, setErrorsJobDetails }) => {
  const [isMaxSalaryDisabled, setIsMaxSalaryDisabled] = useState(!jobDetails.minSalary);

  useEffect(() => {
    setIsMaxSalaryDisabled(!jobDetails.minSalary);
  }, [jobDetails.minSalary]);

  useEffect(() => {
    if (!jobDetails.industry) {
      setJobDetails(prevState => ({ ...prevState, jobTitle: '' }));
    }
  }, [jobDetails.industry, setJobDetails]);

  const handleToggle = (type, value) => {
    setJobDetails((prevState) => ({
      ...prevState,
      [type]: prevState[type].includes(value)
        ? prevState[type].filter((item) => item !== value)
        : [...prevState[type], value],
    }));
  };

  const handleMinSalaryChange = (event) => {
    handleInputChange(event);
    if (!event.target.value) {
      setJobDetails(prevState => ({ ...prevState, maxSalary: '' }));
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setJobDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorsJobDetails((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

  };

  const chipValue = {
    workShifts: ['Day Shift', 'Night Shift', 'Rotational Shift'],
    workModes: ["In Office", "Virtual", "Hybrid", "Onsite"],
    workingDays: ['Sun', 'Mon', 'Tue', 'Web', 'Thu', 'Fri', 'Sat'],
    workType: ['Permanent', 'Temporary', 'Full-time', 'Part-time', 'Internship']
  }

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h6" marginBottom={"10px"} sx={{ color: '#2196f3' }} gutterBottom>About the job</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel component={"legend"}>Cine Industry</FormLabel>
            <Autocomplete
              value={jobDetails.industry}
              onChange={(event, newValue) => setJobDetails(prevState => ({ ...prevState, industry: newValue }))}
              options={Object.keys(Category)}
              renderInput={(params) => <StyledTextField {...params} placeholder="Select Cine Industry" />}
              size='small'
              PopperComponent={(props) => <StyledPopper {...props} />} error={!!errorsJobDetails.industry}
            />
            {errorsJobDetails.industry && <Typography color="error">{errorsJobDetails.industry}</Typography>}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel component={"legend"}>Job Title</FormLabel>
            {jobDetails.industry === 'Others' ? (
              <TextField
                fullWidth
                placeholder="Enter Job Title"
                name="jobTitle"
                value={jobDetails.jobTitle}
                onChange={handleInputChange}
                sx={textFieldStyles}
                size='small'
                error={!!errorsJobDetails.jobTitle}
              />

            ) : (
              <Autocomplete
                value={jobDetails.jobTitle}
                onChange={(event, newValue) => setJobDetails(prevState => ({ ...prevState, jobTitle: newValue }))}
                options={jobDetails.industry ? Category[jobDetails.industry] : []}
                renderInput={(params) => <StyledTextField {...params} placeholder="Select Job Title" />}
                size='small'
                PopperComponent={(props) => <StyledPopper {...props} />}
                disabled={!jobDetails.industry}
              />
            )}
            {errorsJobDetails.jobTitle && <Typography color="error">{errorsJobDetails.jobTitle}</Typography>}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Job Opening</FormLabel>
          <TextField
            fullWidth
            placeholder="Enter job opening"
            name="jobOpening"
            value={jobDetails.jobOpening}
            onChange={handleInputChange}
            sx={textFieldStyles}
            size='small'
            error={!!errorsJobDetails.jobOpening}
            type='number'
          />
          {errorsJobDetails.jobOpening && <Typography color="error">{errorsJobDetails.jobOpening}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Work Shifts</FormLabel>
          <Box display="flex" flexWrap="wrap">
            {chipValue.workShifts.map((shift) => (
              <StyledChip
                key={shift}
                label={shift}
                icon={jobDetails.workShifts.includes(shift) ? <CheckIcon style={{ color: "#fff" }} /> : <AddIcon style={{ color: '#BBB9B9' }} />}
                selected={jobDetails.workShifts.includes(shift)}
                onClick={() => handleToggle('workShifts', shift)}
                style={chipStyles}
              />
            ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Work Mode</FormLabel>
          <Box display="flex" flexWrap="wrap">
            {chipValue.workModes.map((mode) => (
              <StyledChip
                key={mode}
                label={mode}
                icon={jobDetails.workModes.includes(mode) ? <CheckIcon style={{ color: "#fff" }} /> : <AddIcon style={{ color: '#BBB9B9' }} />}
                selected={jobDetails.workModes.includes(mode)}
                onClick={() => handleToggle('workModes', mode)}
                style={chipStyles}
              />
            ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Work Type</FormLabel>
          <Box display="flex" flexWrap="wrap">
            {chipValue.workType.map((type) => (
              <StyledChip
                key={type}
                label={type}
                icon={jobDetails.workType.includes(type) ? <CheckIcon style={{ color: "#fff" }} /> : <AddIcon style={{ color: '#BBB9B9' }} />}
                selected={jobDetails.workType.includes(type)}
                onClick={() => handleToggle('workType', type)}
                style={chipStyles}
              />
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormLabel component={"legend"}>Salary Scale</FormLabel>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3} md={3}>
              <TextField
                fullWidth
                placeholder="₹Min Salary - Eg. 1 LPA"
                name="minSalary"
                value={jobDetails.minSalary}
                onChange={handleMinSalaryChange}
                sx={textFieldStyles}
                type="number"
                size='small'
                InputProps={{
                  inputProps: { min: 1 }
                }}
              />
            </Grid>
            <Grid item xs={1} md={1} style={{ textAlign: 'center' }}>
              <Typography>to</Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <TextField
                fullWidth
                placeholder="₹Max Salary - Eg. 10 LPA"
                name="maxSalary"
                value={jobDetails.maxSalary}
                onChange={handleInputChange}
                sx={textFieldStyles}
                type="number"
                size='small'
                InputProps={{
                  inputProps: { min: 1 }
                }}
                disabled={isMaxSalaryDisabled}
              />
            </Grid>
            <Grid item xs={1} md={1} style={{ textAlign: 'center' }}>
              <Typography>Per Annum</Typography>
            </Grid>
          </Grid>
          {errorsJobDetails.salary && <Typography color="error">{errorsJobDetails.salary}</Typography>}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormLabel component={"legend"}>Working Days</FormLabel>
          <Box display="flex" flexWrap="wrap">
            {chipValue.workingDays.map((day, index) => (
              <StyledChip
                key={index}
                label={day}
                selected={jobDetails.workingDays.includes(day)}
                onClick={() => handleToggle('workingDays', day)}
                style={chipSquareStyles}
              />
            ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Job Description</FormLabel>
          <TextField
            fullWidth
            placeholder="Enter job description"
            name="jobDescription"
            value={jobDetails.jobDescription}
            onChange={handleInputChange}
            sx={textFieldStyles}
            multiline
            rows={4}
            error={!!errorsJobDetails.jobDescription}
          />
          {errorsJobDetails.jobDescription && <Typography color="error">{errorsJobDetails.jobDescription}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Job Location</FormLabel>
          <TextField
            fullWidth
            placeholder="Enter job location"
            name="jobAddress"
            value={jobDetails.jobAddress}
            onChange={handleInputChange}
            sx={textFieldStyles}
            size='small'
            error={!!errorsJobDetails.jobAddress}
          />
          {errorsJobDetails.jobAddress && <Typography color="error">{errorsJobDetails.jobAddress}</Typography>}
        </Grid>

        {/* <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <iframe
              style={{ width: '100%' }}
              className="post_iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.135099205469!2d80.21299197507632!3d12.963205787351377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525db9195fde7b%3A0x87a8ca7d197ca826!2sKodukku%20Classifieds%20Private%20Limited!5e0!3m2!1sen!2sin!4v1711107736387!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </Box>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default JobDetailsForm;
