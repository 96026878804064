import React, { useState, useEffect } from "react";
import Jobusestates from "../useStates/JobUsestate";
import axios from "axios";
import { Nodeapi } from "../config/serverUrl";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
// import { VscSearch } from "react-icons/vsc";
import { LiaSearchSolid } from "react-icons/lia";
function UserCanditate() {
  const [personaldetails, setpersonaldetails] = useState(null);
  const [eductionaldetails, seteductionaldetails] = useState([]);
  const [joddetails, setjoddetails] = useState([]);
  const [jobRoleDetails, setJobRoleDetails] = useState([]);
  const [userId, setUserId] = useState("");
  const [jobSkillDetails, setJobSkillDetails] = useState([]);
  const [relationshipdetails, setrelationshipdetails] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [age, setAge] = useState("");
  const [username, setusername] = useState("");
  const [fathername, setfathername] = useState("");
  const [grandfathername, setgrandfathername] = useState("");

  const authdata = useSelector((state) => state.auth.user);

  const fetchDobDetails = async (kidId) => {
    const id_value = userId;
    try {
      const response = await axios.get(
        `${Nodeapi}/fetchdobcandidates?kidId=${kidId}`
      );

      const data = response.data.data.fetchDob;
      const namevale = response.data.data.alldata.map((item) => item.name);
      setusername(namevale);
      const fathername = response.data.data.alldata.map(
        (item) => item.fathername
      );
      setfathername(fathername);
      const grandfathername = response.data.data.alldata.map(
        (item) => item.familyname
      );
      setgrandfathername(grandfathername);
      const dob = new Date(data[0].dob);
      setDateOfBirth(dob);

      // Calculate age
      const today = new Date();
      const diffTime = today - dob;
      const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));

      // Calculate remaining time until next birthday
      let nextBirthday = new Date(
        today.getFullYear(),
        dob.getMonth(),
        dob.getDate()
      );

      if (today > nextBirthday) {
        nextBirthday.setFullYear(today.getFullYear() + 1);
      }

      const diffTimeToNextBirthday = nextBirthday - today;
      const diffDays = Math.ceil(
        diffTimeToNextBirthday / (1000 * 60 * 60 * 24)
      );

      let diffMonths;
      if (
        today.getMonth() === dob.getMonth() &&
        today.getDate() <= dob.getDate()
      ) {
        diffMonths = 0;
      } else if (
        today.getMonth() > dob.getMonth() ||
        (today.getMonth() === dob.getMonth() && today.getDate() > dob.getDate())
      ) {
        diffMonths = 12 - (today.getMonth() - dob.getMonth()) - 1;
      } else {
        diffMonths = dob.getMonth() - today.getMonth() - 1;
      }

      setAge(`${diffYears} years, ${diffMonths} months, ${diffDays} days`);
    } catch (error) {
      console.error("Error fetching date of birth:", error);
    }
  };



  const fetchPersonalDetails = async (kidId) => {
    try {
      const response = await axios.get(
        `${Nodeapi}/Getpersonaldetails?kidId=${kidId}`
      );

      const data = response.data.fetchpersonal[0];
      if (data) {
        setpersonaldetails(data);
      } else {
        alert("No personal details found for the given KID number.");
      }
    } catch (error) {
      console.error("Error fetching personal details:", error);
    }
  };

  const fetchEductionalDetails = async (kidId) => {
    try {
      const response = await axios.get(
        `${Nodeapi}/Geteductionaldetails?kidId=${kidId}`
      );

      const data = response.data.fetcheduction;
      if (data) {
        seteductionaldetails(data);
      } else {
        alert("No eductional details found for the given KID number.");
      }
    } catch (error) {
      console.error("Error fetching personal details:", error);
    }
  };
  const fetchJobDetails = async (kidId) => {
    try {
      const response = await axios.get(
        `${Nodeapi}/Getjobdetails?kidId=${kidId}`
      );

      const data = response.data.fetchjob;
      setjoddetails(data);
    } catch (error) {
      console.error("Error fetching personal details:", error);
    }
  };
  const fetchRealtionshipDetails = async (kidId) => {
    try {
      const response = await axios.get(
        `${Nodeapi}/Getrelationdetails?kidId=${kidId}`
      );

      const data = response.data.fetchrealtion;
      if (data) {
        setrelationshipdetails(data);
      } else {
        alert("No relationship details found for the given KID number.");
      }
    } catch (error) {
      console.error("Error fetching personal details:", error);
    }
  };

  const FetchJobRoleDetails = async () => {
    try {
      const res = await axios.get(`${Nodeapi}/jobDetails/roles`);
      const data = res.data;
      if (res.data) {
        setJobRoleDetails(data);
      } else {
        alert("No Job Role details found for the given KID number.");
      }
    } catch (error) {
      console.error("Failed to fetch job details", error);
      return null;
    }
  };

  const FetchJobSkillDetails = async () => {
    try {
      const res = await axios.get(`${Nodeapi}/jobDetails/skills`);
      const data = res.data;
      if (res.data) {
        setJobSkillDetails(data);
      } else {
        alert("No job skill details found for the given KID number.");
      }
    } catch (error) {
      console.error("Failed to fetch job details", error);
      return null;
    }
  };

  const handleChange = (event) => {
    const userInput = event.target.value.toUpperCase(); // Convert input to uppercase
    setUserId(userInput);
  };

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === "Enter") {
        handleClick();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [userId]);

  const handleClick = () => {
    fetchPersonalDetails(userId);
    fetchEductionalDetails(userId);
    fetchJobDetails(userId);
    fetchRealtionshipDetails(userId);
    FetchJobRoleDetails();
    FetchJobSkillDetails();
    fetchDobDetails(userId);
  };

  return (
    <>
      <div style={{ marginTop: "25px", display: "flex", marginLeft: "8px" }}>
        <button className="user_btn" onClick={handleClick}>
          <LiaSearchSolid style={{ fontSize: "28px" }} />
        </button>
        <input
          className="text_box"
          placeholder="KID Number"
          value={userId}
          onChange={handleChange}
        />
      </div>

      <div className="usercanditate_card">
        <h5
          style={{ color: "#2196F3", fontWeight: "bold", marginTop: "-10px" }}
        >
          Personal Details
        </h5>
        {personaldetails && Object.keys(personaldetails).length > 0 && (
          <>
            <div className="expand">
              <div className="">
                <table
                  style={{  borderCollapse: "collapse" }}
                  className="details-table"
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Name
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          marginRight: "25px",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${personaldetails ? username : ""}`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Father Name
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          marginRight: "25px",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${personaldetails ? fathername : ""}`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        GrandFather Name
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          marginRight: "25px",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${personaldetails ? grandfathername : ""}`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Birth Time
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          marginRight: "25px",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.time : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Age{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${personaldetails ? age : ""}`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Gender
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.gender : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Marital Status{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.material_status : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Blood Group{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.bloodgroup : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Nationality{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.nationality : ""
                      }`}</td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Disability{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails
                          ? personaldetails.disability
                            ? "Yes"
                            : "No"
                          : ""
                      }`}</td>
                    </tr>
                    {personaldetails && personaldetails.disability === true && (
                      <tr>
                        <td
                          style={{
                            border: "1px ",
                            textAlign: "left",
                            fontWeight: "bold",
                            paddingRight: "10px",
                            color: "#2f1c6a",
                          }}
                        >
                          Disability Reason{" "}
                        </td>
                        <td
                          style={{
                            border: "1px ",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >{`:  ${personaldetails.disability_reason}`}</td>
                      </tr>
                    )}
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Religion{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.religion : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Caste{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.caste : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        InterCaste{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.Intercaste : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Community{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.community : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Party Name{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.partyname : ""
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          fontWeight: "bold",
                          paddingRight: "10px",
                          color: "#2f1c6a",
                        }}
                      >
                        Membership Status{" "}
                      </td>
                      <td
                        style={{
                          border: "1px ",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >{`:  ${
                        personaldetails ? personaldetails.membershipstatus : ""
                      }`}</td>
                    </tr>

                    {personaldetails &&
                      (personaldetails.membershipstatus === "Leader" ||
                        personaldetails.membershipstatus === "Member") && (
                        <>
                          <tr>
                            <td
                              style={{
                                border: "1px ",
                                textAlign: "left",
                                fontWeight: "bold",
                                paddingRight: "10px",
                                color: "#2f1c6a",
                              }}
                            >
                              Membership ID{" "}
                            </td>
                            <td
                              style={{
                                border: "1px ",
                                textAlign: "left",
                                paddingLeft: "10px",
                              }}
                            >{`:  ${
                              personaldetails
                                ? personaldetails.membershipid
                                : ""
                            }`}</td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "1px ",
                                textAlign: "left",
                                fontWeight: "bold",
                                paddingRight: "10px",
                                color: "#2f1c6a",
                              }}
                            >
                              Designation{" "}
                            </td>
                            <td
                              style={{
                                border: "1px ",
                                textAlign: "left",
                                paddingLeft: "10px",
                              }}
                            >{`:  ${
                              personaldetails ? personaldetails.designation : ""
                            }`}</td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                border: "1px ",
                                textAlign: "left",
                                fontWeight: "bold",
                                paddingRight: "10px",
                                color: "#2f1c6a",
                              }}
                            >
                              Geographic{" "}
                            </td>
                            <td
                              style={{
                                border: "1px ",
                                textAlign: "left",
                                paddingLeft: "10px",
                              }}
                            >{`: ${
                              personaldetails ? personaldetails.geographic : ""
                            }`}</td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="usercanditate_card">
        <h5
          style={{ color: "#2196F3", fontWeight: "bold", marginTop: "-10px" }}
        >
          Educational Details
        </h5>
        {eductionaldetails && Object.keys(eductionaldetails).length > 0 && (
          <>
            <div className="expand mt-2">
              <DataTable
                value={eductionaldetails}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  header="S.No"
                  body={(rowData) => (
                    <span>{eductionaldetails.indexOf(rowData) + 1}</span>
                  )}
                ></Column>
                <Column
                  field="rollno"
                  header="Roll Number"
                  style={{ minWidth: "150px " }}
                ></Column>
                <Column
                  // field="collegename"
                  field={(rowData) =>
                    rowData.collegename === "otherCollegeName"
                      ? rowData.otherCollegeName
                      : rowData.collegename
                  }
                  header="Institution Name"
                  style={{ minWidth: "200px " }}
                ></Column>
                <Column
                  field={(rowData) =>
                    rowData.collegedegree === "otherDegree"
                      ? rowData.otherDegree
                      : rowData.collegedegree
                  }
                  header=" Course"
                  style={{ minWidth: "190px " }}
                ></Column>
                <Column
                  field={(rowData) =>
                    rowData.collegespecialization === "otherSpecialization"
                      ? rowData.otherSpecialization
                      : rowData.collegespecialization
                  }
                  header=" Specialization"
                  style={{ minWidth: "220px " }}
                ></Column>
                <Column
                  field="collegestartYear"
                  header="Start Year"
                  style={{ minWidth: "180px " }}
                ></Column>
                <Column
                  field="collegeendYear"
                  header="End Year"
                  style={{ minWidth: "160px " }}
                ></Column>
                <Column
                  field="collegepercentage"
                  header="Percentage"
                  style={{ minWidth: "100px " }}
                ></Column>
                <Column
                  field="collegesection"
                  header=" Section"
                  style={{ minWidth: "190px " }}
                ></Column>
                <Column
                  field={(rowData) =>
                    rowData.university === "otherUniversity"
                      ? rowData.otherUniversity
                      : rowData.university
                  }
                  header="University"
                  style={{ minWidth: "190px " }}
                ></Column>
                {/* <Column body={(rowData) => actions(rowData)} header="Actions"></Column> */}
              </DataTable>
            </div>
          </>
        )}
      </div>

      <div className="usercanditate_card">
        <h5
          style={{ color: "#2196F3", fontWeight: "bold", marginTop: "-10px" }}
        >
          Job Details
        </h5>
        {joddetails && Object.keys(joddetails).length > 0 && (
          <>
            <div className="expand mt-2">
              <DataTable value={joddetails} tableStyle={{ minWidth: "50rem" }}>
                <Column
                  header="S.No"
                  body={(rowData) => (
                    <span>{joddetails.indexOf(rowData) + 1}</span>
                  )}
                ></Column>

                <Column
                  field="jobDetails_company_name"
                  style={{ width: "15%", minWidth: "141px" }}
                  header="Company Name"
                ></Column>
                <Column
                  field="job_role_id"
                  style={{ width: "15%", minWidth: "141px" }}
                  header="Designation"
                  body={(rowData) => {
                    const jobRoleId = Number(rowData.job_role_id);
                    const jobRole = jobRoleDetails.find(
                      (role) => role.job_role_id === jobRoleId
                    );
                    const prefix = rowData.prefixDesignation
                      ? `${rowData.prefixDesignation} `
                      : "";
                    const postfix = rowData.postfixDesignation
                      ? ` ${rowData.postfixDesignation}`
                      : "";
                    return jobRole
                      ? `${prefix}${jobRole.job_role_name}${postfix}`
                      : rowData.otherRole;
                  }}
                ></Column>
                <Column
                  field="industry"
                  style={{ width: "15%", minWidth: "141px" }}
                  header="Industry"
                  body={(rowData) => {
                    if (rowData.industry === "Other") {
                      return rowData.otherIndustry;
                    } else {
                      return rowData.industry;
                    }
                  }}
                ></Column>
                <Column
                  field="jobDetails_skills_id"
                  style={{ width: "15%", minWidth: "141px" }}
                  header="Skills"
                  body={(rowData) => {
                    const jobSkillIds = rowData.jobDetails_skills_id
                      .split(",")
                      .map(Number);
                    const jobSkills = jobSkillDetails
                      .filter((skill) =>
                        jobSkillIds.includes(skill.jobDetails_skills_id)
                      )
                      .map((skill) => skill.jobDetails_skills_name);

                    return jobSkills.length > 0
                      ? jobSkills.join(", ")
                      : "Unknown Skills";
                  }}
                ></Column>
                <Column
                  field="JobResponsibilities"
                  header="Job Responsibilities"
                  style={{ width: "18%", minWidth: "161px" }}
                ></Column>
                <Column
                  field="jobDetails_issue_date"
                  style={{ width: "15%", minWidth: "141px" }}
                  header="Date Of Joining"
                ></Column>
                <Column
                  field="jobDetails_validity_date"
                  style={{ width: "15%", minWidth: "141px" }}
                  header="Date Of Relieving"
                ></Column>
                <Column
                  field="workExperience"
                  header="Work Experience"
                  style={{ width: "15%", minWidth: "141px" }}
                ></Column>
                {/* <Column
                body={(rowData) => actions(rowData)}
                header="Actions"
                ></Column> */}
              </DataTable>
            </div>
          </>
        )}
      </div>

      <div className="usercanditate_card">
        <h5
          style={{ color: "#2196F3", fontWeight: "bold", marginTop: "-10px" }}
        >
          Relationship Details
        </h5>
        {relationshipdetails && Object.keys(relationshipdetails).length > 0 && (
          <>
            <div className="expand mt-2">
              <DataTable
                value={relationshipdetails}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  header="S.No"
                  body={(rowData) => (
                    <span>{relationshipdetails.indexOf(rowData) + 1}</span>
                  )}
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="firstname"
                  header="First Name"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="fathername"
                  header="Father Name"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="familyname"
                  header="Family Name"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="relation"
                  header="Relation"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="DOB"
                  header="DOB"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="age"
                  header="Age"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="time"
                  header="Birth of Time"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="bloodGroup"
                  header="Blood Group"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="importedNote"
                  header="Important Note"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="phonenumber"
                  header="Phone Number"
                ></Column>
                <Column
                  style={{ minWidth: "141px" }}
                  field="occupation"
                  header="Occupation"
                ></Column>

                {/* <Column
                body={(rowData) => actions(rowData)}
                header="Actions"
                ></Column> */}
              </DataTable>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default UserCanditate;
