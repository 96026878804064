import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import './ChatReport.css';
import { IoSearch } from "react-icons/io5";

import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { Nodeapi } from "../config/serverUrl";
import { MdOutlineCancel } from "react-icons/md";
import { FaArrowCircleRight } from "react-icons/fa";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { format } from 'date-fns';
import { IoMdAdd } from "react-icons/io";
import ChatReportTeamM from "./ChatReportsTeamM.jsx";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import ReportChatArea from "./ReportChatArea";

const ChatReports = () => {
    const toast = useRef(null);
    const authdata = useSelector((state) => state.auth.user);
    const [reports, setReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [searchText, setSearchText] = useState('');
    const [actionVisible, setActionVisible] = useState(false);
    const [selectedReportId, setSelectedReportId] = useState(null);
    const [selectedChatMasterId, setSelectedChatMasterId] = useState(null);
    const [searchMode, setSearchMode] = useState(false);
    const [activeTab, setActiveTab] = useState('home');
    const [members, setMembers] = useState([]);
    const [reportMenu, setReportMenu] = useState(null);
    const [memMenu, setMemMenu] = useState(null);
    const [selectedKid, setSelectedKid] = useState(null);
    const [assignVisible, setAssignVisible] = useState(false);
    const [sortField, setSortField] = useState('Report_ID');
    const [sortOrder, setSortOrder] = useState(1);
    const [infoVisible, setInfoVisible] = useState(false);
    const [selectedReportDetails, setSelectedReportDetails] = useState(null);
    const [rep_total, setRep_total] = useState(0);
    const [rep_resolved, setRep_resolved] = useState(0);
    const [rep_pending, setRep_pending] = useState(0);
    const [rep_progress, setRep_progress] = useState(0);
    const [memTotal, setMemTotal] = useState(0);
    const [assignedTotal, setAssignedTotal] = useState(0);
    const [completedTotal, setCompletedTotal] = useState(0);
    const [addMemVisible, setAddMemVisible] = useState(false);
    const [users, setUsers] = useState([]);
    const [addUser, setAddUser] = useState('');
    const [assignUser, setAssignUser] = useState('');
    const [isDashBoard, setIsDashBoard] = useState(true);
    const [repUserName, setRepUserName] = useState('');
    const [repReportedName, setRepReportedName] = useState('');
    const [repUserPrimaryId, setRepUserPrimaryId] = useState('');
    const [repReportedPrimaryId, setReportedPrimaryId] = useState('');
    const [repActionVisible, setRepActionVisible] = useState(false);
    const [rowStatus, setRowStatus] = useState("");
    const [inspectionMode ,setInspectionMode] = useState("");
    const [memSearchText, setMemSearchText] = useState('');
    const [memSearchMode, setMemSearchMode] = useState(false);
    const [memSearchFilter, setMemSearchFilter] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [notifyMail, setNotifyMail] = useState(false);

    const formatTimeOfReport = (timeString) => {
        if (!timeString) return '';
        const date = new Date(timeString);
        return format(date, 'dd-MM-yyyy HH:mm');
    };

    const onSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    const getSortIcon = (field) => {
        if (sortField !== field) {
            return null;
        }
        if (sortOrder === 1) {
            return <span className="custom-sort-icon asc"></span>;
        } else if (sortOrder === -1) {
            return <span className="custom-sort-icon desc"></span>;
        }
        return null;
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleStatusChange = useCallback(async (newStatus, selectedReportId) => {
        setActionVisible(false);
        const data = {
            reportId: selectedReportId,
            status: newStatus
        };
        try {
            const res = await axios.post(`${Nodeapi}/editReport`, data);
            setReports(prevReports =>
                prevReports.map(report =>
                    report.Report_ID === selectedReportId ? { ...report, Status: newStatus } : report
                )
            );
            setRep_pending(res.data.data.newRepLength);
            setRep_progress(res.data.data.inprogressLength);
            setRep_resolved(res.data.data.resolvedLength);

            if(newStatus === "Resolved"){
                setCompletedTotal(completedTotal+1);
            }else{
                setCompletedTotal(completedTotal-1);
            }

            const len_newrep1 = res.data.data ? res.data.data.len_newrep : 0;
            const len_inprog1 = res.data.data ? res.data.data.len_inprog : 0;
            const len_res1 = res.data.data ? res.data.data.len_res : 0;

            setMembers(prevMembers =>
                prevMembers.map(member =>
                    member.kid === selectedReportId
                        ? { ...member, len_newrep: len_newrep1, len_inprog: len_inprog1, len_res: len_res1 }
                        : member
                )
            );

            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Report Status updated successfully",
                life: 2000,
            });
        } catch (error) {
            console.error("Error updating report status:", error);
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "An error occurred while updating the report status.",
                life: 2000,
            });
        }
    }, []);

    const showActionDialog = useCallback((reportId) => {
        setReportMenu(null);
        setSelectedReportId(reportId);
        setActionVisible(true);
    }, []);

    const showInfoDialog = useCallback(async (reportId, chatmasterId) => {
        setReportMenu(null);
        setSelectedReportId(reportId);
        setSelectedChatMasterId(chatmasterId);
        setInfoVisible(true);


        try {
            const response = await axios.get(`${Nodeapi}/fetchOneChatReport/${reportId}`);
            // Assuming the response data structure contains an array of reports
            const reportDetails = response.data.data.report && response.data.data.report[0];
            setRepUserName(response.data.data.userName);
            setRepReportedName(response.data.data.reportedName);
            setRepUserPrimaryId(response.data.data.userPrimaryId);
            setReportedPrimaryId(response.data.data.reportedPrimaryId);

            if (reportDetails) {
                setSelectedReportDetails(reportDetails);

            } else {
                console.error("No report details found");
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "No report details found.",
                    life: 2000,
                });
            }
        } catch (error) {
            console.error("Error fetching report details:", error);
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "An error occurred while fetching the report details.",
                life: 2000,
            });
        }
    }, []);

    const showAssignDialog = useCallback((reportId, status) => {
        setReportMenu(null);
        setSelectedReportId(reportId);
        setRowStatus(status);
        setAssignVisible(true);
    }, []);

    const showMemInfoDialog = useCallback((kid) => {
        setMemMenu(null);
        setIsDashBoard(false);
    }, []);

    const showInsception = async () => {
        setInfoVisible(false);
        setIsDashBoard(false);
        setInspectionMode("report");

    }

    const handleSearchText = (event) => {
        setSearchText(event.target.value);
    };

    const handleMemSearchText = (event) => {
        setMemSearchText(event.target.value);
    };

    const handleSearch = () => {
        setSearchMode(true);
        const filtered = reports.filter(report =>
            report.Report_ID.toLowerCase().includes(searchText.toLowerCase())
        );
        setSearchFilter(filtered);
        setFilteredReports(filtered);
    };

    const handleMemSearch = () => {
        setMemSearchMode(true);
        const filtered = members.filter(member =>
            member.kid.toLowerCase().includes(memSearchText.toLowerCase())
        );
        setMemSearchFilter(filtered);
        setFilteredMembers(filtered);
    }

    const handleSearchMode = () => {
        setSearchMode(false);
        setFilteredReports(reports);
        setSearchFilter([]);
        setSearchText('');
    };

    const handleMemSearchMode = () => {
        setMemSearchMode(false);
        setFilteredMembers(members);
        setMemSearchFilter([]);
        setMemSearchText('');
    };

    const handleClick = (event, reportId, chatmasterId, status) => {
        setReportMenu(event.currentTarget);
        setSelectedReportId(reportId);
        setSelectedChatMasterId(chatmasterId);
        setRowStatus(status);
    };

    const handleMemClick = (event, kid) => {
        setMemMenu(event.currentTarget);
        setSelectedKid(kid);
    };

    const handleClose = () => {
        setReportMenu(null);
    };

    const handleMemClose = () => {
        setMemMenu(null);
    }

    useEffect(() => {

        fetchReports();
        fetchMembers();
        // setReports(demoReports);
        //setMembers(demoMembers);
    }, []);


    const fetchReports = async () => {
        try {
            const response = await axios.get(`${Nodeapi}/fetchChatReports`);
            setReports(response.data.data.reports);
            setRep_total(response.data.data.totalReports);
            setRep_resolved(response.data.data.resolvedLength);
            setRep_pending(response.data.data.newRepLength);
            setRep_progress(response.data.data.inprogressLength);
        } catch (error) {
            console.log("Error fetching Reports =>", error);
        }
    };

    const fetchMembers = async () => {
        try {
            const response = await axios.get(`${Nodeapi}/fetchReportHandlers`);
            setMembers(response.data.data.reportHandlers);
            setMemTotal(response.data.data.handlersLength);
            setAssignedTotal(response.data.data.assignedLength);
            setCompletedTotal(response.data.data.resolvedLength);
        } catch (error) {
            console.log("Error fetching Reports =>", error);
        }
    };

    useEffect(() => {
        const filterReports = () => {
            let filtered = searchMode ? searchFilter : reports;
            if (selectedStatus !== 'All') {
                filtered = filtered.filter(report => report.Status === selectedStatus);

            }
            setFilteredReports(filtered);
        };
        filterReports();
    }, [selectedStatus, searchText, reports, searchMode, searchFilter]);

    useEffect(()=> {
        const filterMembers = () => {
            let filtered = memSearchMode ? memSearchFilter : members;
            setFilteredMembers(filtered);
        };
        filterMembers();
    },[memSearchText, members, memSearchMode, memSearchFilter]);

    const serialNumberTemplate = (rowData, { rowIndex }) => {
        return rowIndex + 1;
    };

    const customBodyTemplate = (rowData, field) => {
        if (field === 'Time_of_Report') {
            return formatTimeOfReport(rowData[field]);
        }
        // return rowData[field];
        return rowData[field] === null || rowData[field] === undefined ? '-' : rowData[field];
    };

    const customReportID = (reportId, chatmasterId) => {
        showInfoDialog(reportId, chatmasterId);
        console.log("report details=> ", reportId, chatmasterId);
    };

    const customKidID = (event,kid) => {
        showMemInfoDialog();
        console.log("member details=>", kid);
    };
    const showAddMemDialog = async () => {
        setAddMemVisible(true);

        const res = await axios.get(`${Nodeapi}/fetchUsers`);
        setUsers(res.data.data.uniq_users);
    };

    const handleAddMember = useCallback(async () => {
        try {
            const newUser = { kidId: addUser }; // Assuming addUser is structured with kidId
            console.log("Adding new member with kidId:", newUser.kidId);

            const response = await axios.post(`${Nodeapi}/addMemHandler`, newUser);
            console.log("Response from backend:", response.data);

            // setMembers(response.data.data.reportHandlers);
            const { message, addMem } = response.data.data;

            console.log('addMem:',addMem);
 
            setMembers([...members,addMem]);
            
            fetchMembers()
            // Assuming you have a state variable named members and a setter function setMembers
            // setMembers(prevMembers => {
            //     console.log("Previous members:", prevMembers);
            //     console.log("New members to add:", addMem);
            //     if (!Array.isArray(prevMembers)) {
            //         return addMem; // If prevMembers is not an array, return addMem directly
            //     }
            //     return [...prevMembers, ...addMem]; // Spread prevMembers and addMem
            // });
            
            
            setMemTotal(memTotal+1);

            console.log("New member added successfully:", addMem);

            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: message,
                life: 2000,
            });

        } catch (error) {
            console.error("Error Adding Member:", error);

            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "An error occurred while adding member.",
                life: 2000,
            });
        }
    }, [addUser]); 


    const handleAssignMember = useCallback(async () => {
        try {
            const data = {
                reportId: selectedReportId,
                userId: assignUser
            }
            const response = await axios.post(`${Nodeapi}/assignReport`, data);
            setReports(prevReports =>
                prevReports.map(report =>
                    report.Report_ID === selectedReportId
                        ? { ...report, Assigned_to: assignUser, Assign_time: new Date().toISOString(), Status: "Pending" }
                        : report
                )
            );
            if (rowStatus === "In-Progress"){
                setRep_pending(rep_pending+1);
                setRep_progress(rep_progress-1);
            }
            if (rowStatus === "Resolved"){
                setRep_pending(rep_pending+1);
                setRep_resolved(rep_resolved+1);
            }

            setMembers(prevMembers =>
                prevMembers.map(member =>
                    member.kid === assignUser
                        ? {
                            ...member,
                            len_rep: (member.len_rep || 0) + 1,
                            len_newrep: (member.len_newrep || 0) + 1
                        }
                        : member
                )
            );

            setAssignedTotal(response.data.data.assignedLength);
            setCompletedTotal(response.data.data.resolvedLength);

            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Report Assigned successfully",
                life: 2000,
            });

        } catch (error) {
            console.error("Error Assigning to Member:", error);

            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "An error occurred while assigning member.",
                life: 2000,
            });
        }
    }, [assignUser]);


    useEffect(() => {
        fetchMembers();
        fetchReports();
    }, [handleAddMember, handleAssignMember, handleStatusChange, activeTab]);

    const MAX_CHAR_LIMIT = 2000;
    const [desText, setDesText] = useState('');
    const [remainingChars, setRemainingChars] = useState(MAX_CHAR_LIMIT);
    const [action, setAction] = useState('');
    const handleActionChange = (event) => {
        setAction(event.target.value);
    };

    const handleActionClose = () => {
        setRepActionVisible(false);
        setDesText("");
        setRemainingChars(0);
        setAction('');
    }

    useEffect(() => {
        setRemainingChars(0);
    }, [])

    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        if (value.length <= MAX_CHAR_LIMIT) {
            setDesText(value);
            setRemainingChars(value.length);
        }
    };

    const HandleAction = () => {
        setRepActionVisible(true);
        handleClose();
    };

    const handleActionSubmit = async () => {

        try {
            console.log(authdata);

            
            const data = {
                reportId: selectedReportId,
                action : action,
                actionReason: desText
            };
            console.log("action Data=> ", data);
            const res = await axios.post(`${Nodeapi}/addCRAction`,data);

            setReports(prevReports =>
                prevReports.map(report =>
                    report.Report_ID === selectedReportId
                        ? { ...report, Action: action, Action_des: desText, Completed_time :new Date().toISOString() }
                        : report
                )
            );
            handleStatusChange("Resolved",selectedReportId);
            if (notifyMail === true){
                const data = {
                    reportId: selectedReportId
                }
                
                const mailres = await axios.post(`${Nodeapi}/notifyReporter`,data);
                
                    toast.current.show({
                        severity: "success",
                        summary: "Success",
                        detail: mailres.data.data.message,
                        life: 2000,
                    });
                
            }
            if(res.data){

                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: res.data.data.message,
                    life: 2000,
                });
            }
            setIsDashBoard(true);
        } catch (err) {

            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: err.data.data.message,
                life: 2000,
            });
        }
    };

    const handleMailCheckbox =(e) => {
        if(e.target.checked){
            setNotifyMail(true);
        }
    };

    return (
        <div className="ChatReport">
            <Toast ref={toast} />
            <div className="reports-header" >
                <h2 style={{ color: "#2196F3" }}>Chat Reports</h2>

            </div>
            {isDashBoard ? <>
                <div className="card-view">
                    <div className={`cards ${activeTab === 'home' ? 'active' : ''}`}
                    >
                        <p className="cards-title">Reports</p>
                        <div className="reports-details">
                            <div className="count">
                                <p className="count-num">{rep_total}</p>
                                <p className="count-text">Total</p>
                            </div>
                            <div className="count">
                                <p className="count-num">{rep_resolved}</p>
                                <p className="count-text">Resolved</p>
                            </div>
                            <div className="count">
                                <p className="count-num">{rep_pending}</p>
                                <p className="count-text">Pending</p>
                            </div>
                            <div className="count">
                                <p className="count-num">{rep_progress}</p>
                                <p className="count-text">In-Progress</p>
                            </div>
                        </div>
                        <div className={`cards-details ${activeTab === 'home' ? 'active' : ''}`}
                            onClick={() => handleTabClick('home')}>
                            <span>See More</span>
                            <FaArrowCircleRight />
                        </div>
                    </div>
                    <div className={`cards ${activeTab === 'about' ? 'active' : ''}`}
                    >
                        <p className="cards-title">Team - Members</p>
                        <div className="reports-details">
                            <div className="count">
                                <p className="count-num">{memTotal}</p>
                                <p className="count-text">Members</p>
                            </div>
                            <div className="count">
                                <p className="count-num">{assignedTotal}</p>
                                <p className="count-text">Assigned</p>
                            </div>
                            {/* <div className="count">
                            <p className="count-num">8</p>
                            <p className="count-text">Pending</p>
                        </div> */}
                            <div className="count">
                                <p className="count-num">{completedTotal}</p>
                                <p className="count-text">Completed</p>
                            </div>
                        </div>
                        <div className={`cards-details ${activeTab === 'about' ? 'active' : ''}`}
                            onClick={() => handleTabClick('about')}>
                            <span>See More</span>
                            <FaArrowCircleRight />
                        </div>
                    </div>
                    {/* <div></div>
                <div></div> */}
                </div>
                <div className="card">
                    {activeTab === 'home' && <div>

                        <div className="card-panel1">
                            <div className="card-top">
                                <div className="reports-filter-btns">
                                    <Button
                                        className={`filter-button2 ${selectedStatus === 'All' ? 'active' : ''}`}
                                        onClick={() => setSelectedStatus('All')}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        className={`filter-button2 ${selectedStatus === 'Pending' ? 'active' : ''}`}
                                        onClick={() => setSelectedStatus('Pending')}
                                    >
                                        Pending
                                    </Button>
                                    <Button
                                        className={`filter-button2 ${selectedStatus === 'In-Progress' ? 'active' : ''}`}
                                        onClick={() => setSelectedStatus('In-Progress')}
                                    >
                                        In-Progress
                                    </Button>
                                    <Button
                                        className={`filter-button2 ${selectedStatus === 'Resolved' ? 'active' : ''}`}
                                        onClick={() => setSelectedStatus('Resolved')}
                                    >
                                        Resolved
                                    </Button>
                                </div>
                                <div className="entry-div">
                                    <p>Total: {filteredReports.length} entries</p>
                                </div>
                                <div className="search-div">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Report ID"
                                        value={searchText}
                                        onChange={handleSearchText}
                                    />
                                    {searchMode ?
                                        <button type="button" className="search-btn" onClick={handleSearchMode}>
                                            <MdOutlineCancel />
                                        </button> :
                                        <button className="search-btn" type="button" onClick={handleSearch}>
                                            <IoSearch className="search-icon" />
                                        </button>
                                    }
                                </div>
                            </div>

                            <div className="reports-table">
                                <DataTable
                                    value={filteredReports}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    tableStyle={{ minWidth: '50rem', fontSize: '15px' }}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    onSort={onSort}
                                >
                                    <Column
                                        header="S.No"
                                        body={serialNumberTemplate}
                                        style={{ width: '50px', textAlign: 'center' }}
                                    />
                                    <Column
                                        field="Report_ID"
                                        header={<div>Report ID {getSortIcon('Report_ID')}</div>}
                                        style={{ wordBreak: 'break-word', width:'150px' }}
                                        body={(rowData) => (
                                            <div className="report-id" onClick={() => { customReportID(rowData.Report_ID, rowData.ChatmasterID) }} style={{ cursor: 'pointer' }}>
                                                {rowData.Report_ID}
                                            </div>
                                        )}
                                        bodyStyle={{ width: '150px' }}
                                        sortable
                                        frozen
                                    ></Column>

                                    <Column
                                        field="Time_of_Report"
                                        header="Time of Report"
                                        //body={(rowData) => customBodyTemplate(rowData, 'Time_of_Report')}
                                        style={{ wordBreak: 'break-word', width: '150px' }}
                                    ></Column>
                                    <Column
                                        field="ChatmasterID"
                                        header="Chatmaster ID"
                                        style={{ wordBreak: 'break-word', width: '120px', fontSize: '14px' }}
                                    ></Column>
                                    <Column
                                        field="Reported_Content"
                                        header="Content"
                                        body={(rowData) => customBodyTemplate(rowData, 'Reported_Content')}
                                        style={{ wordBreak: 'break-word', width: '120px' }}
                                    ></Column>
                                    <Column
                                        field="Assigned_to"
                                        header="Assigned_to"
                                        body={(rowData) => customBodyTemplate(rowData, 'Assigned_to')}
                                        style={{ wordBreak: 'break-word', width:'150px' }}
                                        bodyStyle={{ width: '160px' }}
                                    ></Column>
                                    <Column
                                        field="Action"
                                        header="Action_Taken"
                                        body={(rowData) => customBodyTemplate(rowData, 'Action')}
                                        style={{ wordBreak: 'break-word', width: '150px' }}
                                    ></Column>

                                    <Column
                                        field="Status"
                                        header="Status"
                                        style={{ wordBreak: 'break-word', width: '110px' }}
                                        body={(rowData) => (
                                            <span className={rowData.Status === 'Pending' ? 'pending-text' : 'completed-text'}>
                                                {rowData.Status}
                                            </span>
                                        )}
                                    ></Column>
                                    <Column
                                        header="Action"
                                        body={(rowData) => (
                                            <PiDotsThreeOutlineVerticalFill
                                                reportId={rowData.Report_ID}

                                                onClick={(event) => handleClick(event, rowData.Report_ID, rowData.ChatmasterID, rowData.Status)}
                                            />
                                        )}
                                        style={{ textAlign: 'center', width: '50px' }}
                                    ></Column>

                                </DataTable>
                            </div>

                            <Menu
                                id="basic-menu"
                                anchorEl={reportMenu}
                                open={Boolean(reportMenu)}
                                onClose={handleClose}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                            >
                                <MenuItem onClick={() => showInfoDialog(selectedReportId, selectedChatMasterId)}>View</MenuItem>
                                <MenuItem onClick={() => showAssignDialog(selectedReportId, rowStatus)}>Assign</MenuItem>
                                <MenuItem onClick={() => showActionDialog(selectedReportId)}>Status</MenuItem>
                            </Menu>

                            <Dialog
                                visible={assignVisible}
                                onHide={() => setAssignVisible(false)}
                                header="Assign Status"
                                style={{ width: '35vw' }}
                                breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                            >
                                <p style={{ fontSize: "16px", fontWeight: 600, margin: "10px 0", textAlign: "center" }}>
                                    Assign the Report ({selectedReportId}) to:-
                                </p>
                                <div style={{ margin: "10px 0", fontSize: "20px", lineHeight: 1.5, display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
                                    <span>Member:</span>
                                    <select
                                        className="form-select"
                                        style={{ width: "80%", padding: "8px", margin: "10px 0", fontSize: "14px", marginLeft: "8px" }}
                                        onChange={(e) => setAssignUser(e.target.value)}
                                    >
                                        <option value="">--Please Select a Member--</option>
                                        {members.map(member => (
                                            <option value={member.kid}>
                                                {member.kid}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                    <Button
                                        style={{
                                            border: "1px solid #1877f2",
                                            padding: "11px 17px",
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            color: "#1877f2",
                                            margin: "0px 10px",
                                        }}
                                        className="p-button-text"
                                        onClick={() => setAssignVisible(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        style={{
                                            border: "1px solid #1877f2",
                                            padding: "11px 17px",
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            color: "#1877f2",
                                            margin: "0px 10px",
                                        }}
                                        className="p-button-danger"
                                        onClick={() => { handleAssignMember(); setAssignVisible(false) }}
                                    >
                                        Assign
                                    </Button>
                                </div>
                            </Dialog>

                            <Dialog
                                visible={infoVisible}
                                onHide={() => setInfoVisible(false)}
                                header={`Report Info : ${selectedReportId}`}
                                style={{ width: '35vw' }}
                                breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                            >
                                {selectedReportDetails ? (
                                    <div className="reportInfo">
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Report ID  :</h6> <p>{selectedReportDetails.Report_ID}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">User ID  :</h6> <p>{selectedReportDetails.UserID}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">ReportedID  :</h6><p> {selectedReportDetails.ReportedID === null ? '-' : selectedReportDetails.ReportedID}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Time of Report  :</h6><p> {formatTimeOfReport(selectedReportDetails.Time_of_Report)}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Chatmaster ID  :</h6> <p>{selectedReportDetails.ChatmasterID}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Reported Content  :</h6> <p>{selectedReportDetails.Reported_Content === null ? '-' : selectedReportDetails.Reported_Content}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Reported Messgage  :</h6><p> {selectedReportDetails.Messages === null ? '-' : selectedReportDetails.Messages}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Reason  :</h6><p> {selectedReportDetails.Reason === null ? '-' : selectedReportDetails.Reason}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Assigned to  :</h6><p> {(selectedReportDetails.Assigned_to === null) ? '-' : selectedReportDetails.Assigned_to}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Assign Time  :</h6><p> {selectedReportDetails.Assign_time === null ? '-' : formatTimeOfReport(selectedReportDetails.Assign_time)}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Action Taken  :</h6><p> {selectedReportDetails.Action === null ? '-' : selectedReportDetails.Action}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Action Description  :</h6><p> {selectedReportDetails.Action_des === null ? '-' : selectedReportDetails.Action_des}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Completed Time  :</h6> <p>{selectedReportDetails.Completed_time === null ? '-' : formatTimeOfReport(selectedReportDetails.Completed_time)}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Email sent  :</h6><p> {selectedReportDetails.Email_sent === null ? '-' : selectedReportDetails.Email_sent}</p></div>
                                        <div className="flex"><h6 className="w-6 md:w-4 ">Status  :</h6> <p>{selectedReportDetails.Status}</p></div>
                                        <div className="text-end" >
                                            <button className="btn btn-primary" variants="blue" onClick={() => { showInsception();  }}>Investicate</button>
                                            <button className="btn btn-primary" style={{ marginLeft: '15px' }} variants="blue" onClick={() => setInfoVisible(false)}>OK</button>
                                        </div>
                                    </div>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </Dialog>

                            <ConfirmDialog
                                visible={actionVisible}
                                onHide={() => setActionVisible(false)}
                                message={`Change status of Report ID: ${selectedReportId}?`}
                                header="Action Status"
                                icon="pi pi-exclamation-triangle"
                                breakpoints={{ '1100px': '75vw', '960px': '100vw' }}
                                footer={
                                    <div>
                                        <Button label="Pending" icon="pi pi-refresh" onClick={() => handleStatusChange('Pending', selectedReportId)} />
                                        <Button label="In-Progress" icon="pi pi-spin" onClick={() => handleStatusChange('In-Progress', selectedReportId)} />
                                        <Button label="Resolved" icon="pi pi-check" onClick={() => handleStatusChange('Resolved', selectedReportId)} />
                                        <Button label="Cancel" icon="pi pi-times" onClick={() => setActionVisible(false)} className="p-button-secondary" />
                                    </div>
                                }
                            />
                        </div>
                    </div>}
                    {activeTab === 'about' && <div>
                        <div className="card-panel2">
                            <div className="memberTable" style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                            <div className="search-div2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by KID"
                                        value={memSearchText}
                                        onChange={handleMemSearchText}
                                    />
                                    {memSearchMode ?
                                        <button type="button" className="search-btn" onClick={handleMemSearchMode}>
                                            <MdOutlineCancel />
                                        </button> :
                                        <button className="search-btn" type="button" onClick={handleMemSearch}>
                                            <IoSearch className="search-icon" />
                                        </button>
                                    }
                                </div>
                            <div className="add-mem" >
                                <button className="btn btn-primary" onClick={showAddMemDialog}><IoMdAdd />Add</button>
                            </div>
                            
                            </div>

                            <div className="reports-table">
                                <DataTable
                                    value={filteredMembers}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    tableStyle={{ minWidth: '50rem', fontSize: '15px' }}
                                >
                                    <Column
                                        header="S.No"
                                        body={(rowData, { rowIndex }) => rowIndex + 1}
                                        style={{ width: '50px', textAlign: 'center' }}
                                    />
                                    <Column
                                        field="kid"
                                        header="Kid"
                                        style={{ wordBreak: 'break-word' }}
                                        body={(rowData) => (
                                            <div className="report-id" onClick={(event) => { customKidID(event, rowData.kid); setSelectedKid(rowData.kid); setInspectionMode("member") }} style={{ cursor: 'pointer' }}>
                                                {rowData.kid}
                                            </div>
                                        )}
                                        bodyStyle={{ width: '150px' }}
                                    />
                                    <Column
                                        field="username"
                                        header="Name"
                                        headerStyle={{textAlign: 'center'}}
                                        style={{ wordBreak: 'break-word' }}
                                        bodyStyle={{ width: '150px' }}
                                    />
                                    <Column
                                        field="len_rep"
                                        header="Total Reports"
                                        headerStyle={{textAlign: 'center'}}
                                        body={(rowData) => customBodyTemplate(rowData, 'len_rep')}
                                        style={{ wordBreak: 'break-word' }}
                                        bodyStyle={{ width: '110px', textAlign: 'center' }}
                                    />
                                    <Column
                                        field="len_res"
                                        header="Resolved"
                                        headerStyle={{textAlign: 'center'}}
                                        body={(rowData) => customBodyTemplate(rowData, 'len_res')}
                                        style={{ wordBreak: 'break-word' }}
                                        bodyStyle={{ width: '110px', textAlign: 'center' }}
                                    />
                                    <Column
                                        field="len_newrep"
                                        header="Pending"
                                        headerStyle={{textAlign: 'center'}}
                                        body={(rowData) => customBodyTemplate(rowData, 'len_newrep')}
                                        style={{ wordBreak: 'break-word' }}
                                        bodyStyle={{ width: '110px', textAlign: 'center' }}
                                    />
                                    <Column
                                        field="len_inprog"
                                        header="In-Progress"
                                        headerStyle={{textAlign: 'center'}}
                                        body={(rowData) => customBodyTemplate(rowData, 'len_inprog')}
                                        style={{ wordBreak: 'break-word' }}
                                        bodyStyle={{ width: '110px', textAlign: 'center' }}
                                    />
                                    <Column
                                        header="Action"
                                        body={(rowData) => (
                                            <PiDotsThreeOutlineVerticalFill
                                                kidId={rowData.kid}
                                                onClick={(event) => handleMemClick(event, rowData.kid)}
                                            />
                                        )}
                                        style={{ textAlign: 'center', width: '50px' }}
                                    ></Column>
                                </DataTable>
                            </div>

                            <Menu
                                id="member-menu"
                                anchorEl={memMenu}
                                open={Boolean(memMenu)}
                                onClose={handleMemClose}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                            >
                                <MenuItem onClick={() => showMemInfoDialog(selectedKid)}>View</MenuItem>
                                {/* <MenuItem onClick={() => showAssignDialog(selectedReportId)}>Assign</MenuItem>
                            <MenuItem onClick={() => showActionDialog(selectedReportId)}>Status</MenuItem>
                         */}

                            </Menu>

                            <Dialog
                                visible={addMemVisible}
                                onHide={() => setAddMemVisible(false)}
                                header="Add Member"
                                style={{ width: '35vw' }}
                            >
                                <div className="flex" style={{ alignItems: "center" }} >

                                    <p>Member : </p>
                                    <select className="form-select" style={{ width: "70%", marginLeft: "4px" }} onChange={(e) => setAddUser(e.target.value)}>
                                        <option value="">Select a member</option>
                                        {users.map(user => (
                                            <option value={user.user_id}>
                                                {user.user_id} - {user.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ textAlign: "end" }}>
                                    <Button onClick={() => { handleAddMember(); setAddMemVisible(false); }}>Add</Button>
                                    <Button onClick={() => setAddMemVisible(false)}>Cancel</Button>
                                </div>
                            </Dialog>
                        </div>
                    </div>}
                </div>
            </>
                : <>

                    {inspectionMode === "member" && (<>
                        <div>
                            <div onClick={() => setIsDashBoard(true)} style={{ cursor: 'pointer', color: '#2196F3', display: 'flex', fontSize: 'x-large', alignItems: 'center', marginLeft: '2%' }}>
                                <FaArrowLeft />
                                <p style={{ marginLeft: '5%' }}>Back</p>
                            </div>
                            <div style={{ display: 'flex', margin: '11px 26px', fontSize: 'x-large' }}>
                                <p className="p-text">Member:</p>
                                <p className="p-text" style={{ marginLeft: '5px' }}>{selectedKid}</p>

                            </div>
                        </div>

                        <ChatReportTeamM
                            inspectionMode={inspectionMode}
                            selectedKid={selectedKid}
                        />
                        </>
                    )}

                        {inspectionMode === "report" && ( 
                            <>
                            <div>

                                <div onClick={() => setIsDashBoard(true)} style={{ cursor: 'pointer', color: '#2196F3', display: 'flex',  fontSize: 'x-large', alignItems: 'center', marginLeft: '2%' }}>
                                    <FaArrowLeft />
                                    <p >Back</p>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginRight:'8%'}}>
                                <div style={{ display: 'flex', margin: '11px 11px', fontSize: 'x-large' }}>
                                    <p className="p-text">Inspect:</p>
                                    <p className="p-text" style={{ marginLeft: '5px' }}>{selectedReportId}</p>
                
                                </div>
                                
                                <button className="btn btn-primary" style={{height:'max-content', margin:'11px 12px'}} onClick={() => setRepActionVisible(true)}>Action</button>
                            </div>
                            </div>
                            <div className="chat" style={{ padding: "1.5%", width: '75%', margin: '0 auto' }}>
                                <div className="inspect-header" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '15px', color: 'white', background: '#1877f2', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                                    <p style={{fontSize: '14px'}}>{repUserName} (Reporter)</p>
                                    <p style={{textAlign: 'end',fontSize: '14px'}}>{repReportedName} (Reported)</p>
                                </div>
                                <div className="inspect-chat" style={{ height: '60vh', border: '1px solid' }} >
                                    {/* <p>chat Area</p> */}
                                    <ReportChatArea
                                        selectedReportId={selectedReportId}
                                        selectedChatMasterId={selectedChatMasterId}
                                        repUserPrimaryId={repUserPrimaryId}
                                        repReportedPrimaryId={repReportedPrimaryId}
                                    />
                                </div>
                            </div>
                            

                            <Dialog
                                header="Action Confirmation"
                                visible={repActionVisible}
                                style={{ width: "35vw" }}
                                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                                onHide={() => handleActionClose()}
                            >
                                <div style={{ padding: "10px" }}>
                                    <p style={{ fontSize: "16px", fontWeight: 600, margin: "10px 0", textAlign: "center" }}>
                                        Action for Report ID: {selectedReportId}
                                    </p>
                                    <div style={{ margin: "10px 0", fontSize: "20px", lineHeight: 1.5, display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
                                        <span>Action:</span>
                                        <select
                                            id="report-actions"
                                            value={action}
                                            onChange={handleActionChange}
                                            className="form-select"
                                            style={{ width: "80%", padding: "8px", margin: "10px 0", fontSize: "14px" }}
                                        >
                                            <option value="">--Please choose an action--</option>
                                            <option value="warning">Issue a Warning</option>
                                            <option value="suspend">Temporary Suspension</option>
                                            <option value="removeContent">Remove Content</option>
                                            <option value="educateUser">Educate User</option>
                                            <option value="moderate">Moderate Chat</option>
                                            <option value="block">Block User</option>
                                            <option value="investigate">Initiate Investigation</option>
                                            <option value="escalate">Escalate to Support</option>
                                            <option value="communityReport">Enable Community Reporting</option>
                                            <option value="feedback">Collect Feedback</option>
                                        </select>

                                    </div>
                                    <div style={{ margin: "10px 0", fontSize: "16px", lineHeight: 1.5, display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
                                        <span>Description:</span>
                                        <textarea value={desText} className="form-control" onChange={handleDescriptionChange} style={{ width: '500px' }}></textarea>
                                        <span>{remainingChars}/{MAX_CHAR_LIMIT}</span>
                                    </div>
                                    {/* <div style={{margin: "10px 0", fontSize: "16px", lineHeight: 1.5, display: "flex", flexDirection: "row", gap: "3px", alignItems: "center"}}>
        <input type="checkbox" onChange={handleReportCheckbox} style={{ width: "20px", height: "15px", padding: "5px", margin: "10px"}}/>
        <p style={{ margin: "10px 0", fontSize: "17px", lineHeight: 0.5 }}>Also Block this contact</p>
    </div> */}
                                    <div style={{margin: "10px 0", fontSize: "16px", lineHeight: 1.5, display: "flex", flexDirection: "row", gap: "3px", alignItems: "center"}}>
                                        <input type="checkbox" onChange={handleMailCheckbox} style={{ width: "20px", height: "15px", padding: "5px", margin: "10px"}}/>
                                        <p style={{ margin: "10px 0", fontSize: "17px", lineHeight: 0.5 }}>Notify the Reporter</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                                        <Button
                                            style={{
                                                border: "1px solid #1877f2",
                                                padding: "5px 15px",
                                                fontSize: "12px",
                                                fontWeight: 600,
                                                color: "#1877f2",
                                                margin: "0px 10px",
                                            }}
                                            className="p-button-text"
                                            onClick={() => {
                                                handleActionClose();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            style={{
                                                border: "1px solid #1877f2",
                                                padding: "5px 15px",
                                                fontSize: "12px",
                                                fontWeight: 600,
                                                color: "#1877f2",
                                                margin: "0px 10px",
                                            }}
                                            className="p-button-danger"
                                            onClick={() => {
                                                handleActionSubmit();
                                                handleActionClose();
                                            }}
                                        >
                                            Confirm Action
                                        </Button>
                                    </div>
                                </div>
                            </Dialog>

                        </>
                    )}
                </>
            }
        </div>
    );
}

export default ChatReports;
