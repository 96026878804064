import { useState } from "react";

const BloodUsestates = () =>{
    const [requestFormData, setRequestFormData] = useState({
        kidId: "",
        patient_id: "",
        firstname: "",
        lastname: "",
        dob: "",
        gender: "",
        blood_group: "",
        mobile_no: "",
        email: "",
        place: "",
        district: "",
        state: "",
        country: "",
        pincode: "",
        id_proof: null,
        hospital_name: "",
        doctor_name: "",
        ward_room_number: "",
        units_required: "",
        blood_component_type: "",
        from_date_required: "",
        to_date_required: "",
        to_time_required: "",
        urgency_level: "",
        reason_for_requirement: "",
        patient_underwent_surgery: "",
        surgery_type: "",
        approval_status: "Created",
        donor_criteria: null,
      });
      const [requestErrors, setRequestErrors] = useState({
        patient_id: "",
        kidId: "",
        dob: "",
        gender: "",
        mobile_no: "",
        email: "",
        place: "",
        district: "",
        state: "",
        country: "",
        pincode: "",
        blood_group: "",
        units_required: "",
        blood_component_type: "",
        from_date_required: "",
        to_date_required: "",
        to_time_required: "",
        urgency_level: "",
        hospital_name: "",
      });  
  const [errors, setErrors] = useState({
    email: "",
    mobile_no: "",
  });

  const validateRequestForm = () => {
    const newErrors = {};
    if (!requestFormData.patient_id)
      newErrors.patient_id = "*Patient Id required";
    if (!requestFormData.kidId) newErrors.kidId = "*KID Id required";
    if (!requestFormData.dob) newErrors.dob = "*Date of Birth is required";
    if (!requestFormData.gender) newErrors.gender = "*Gender is required";
    if (!requestFormData.blood_group)
      newErrors.blood_group = "*Blood Group is required";
    if (!requestFormData.mobile_no)
      newErrors.mobile_no = "*Mobile Number is required";
    if (!requestFormData.email) newErrors.email = "*Email is required";
    if (!requestFormData.place) newErrors.place = "*Place is required";
    if (!requestFormData.district) newErrors.district = "*District is required";
    if (!requestFormData.state) newErrors.state = "*State is required";
    if (!requestFormData.country) newErrors.country = "*Country is required";
    if (!requestFormData.pincode) newErrors.pincode = "*Pincode is required";
    if (!requestFormData.units_required)
      newErrors.units_required = "Blood units required";
    if (!requestFormData.blood_component_type)
      newErrors.blood_component_type = "*Blood Component type is required";
    if (!requestFormData.from_date_required)
      newErrors.from_date_required = "*From Date is required";
    if (!requestFormData.to_date_required)
      newErrors.to_date_required = "*To Date is required";
    if (!requestFormData.to_time_required)
      newErrors.to_time_required = "*To Time is required";
    if (!requestFormData.urgency_level)
      newErrors.urgency_level = "*Urgency Level is required";
    if (!requestFormData.hospital_name)
      newErrors.hospital_name = "*Hospital Name is required";
    setRequestErrors(newErrors);
    return Object.values(newErrors).every((value) => !value);
  };
      return{
        requestFormData, setRequestFormData,
        requestErrors, setRequestErrors,
        errors, setErrors,
        validateRequestForm,
      }
}

export default BloodUsestates