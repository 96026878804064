import React, { useEffect, useRef, useState,useContext } from 'react';
import io from 'socket.io-client';
import { v4 as uuidV4 } from 'uuid';
import { useSelector } from 'react-redux';
import '../src/Component/Chat/ChatSection/ChatHeader/ChatHeader.css'
import axios from 'axios';
import { Nodeapi } from './config/serverUrl';
import { DataContext } from './Admin/DataContext';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { IoVideocamOffOutline } from "react-icons/io5";
import { IoVideocamOutline } from "react-icons/io5";
import { CiMicrophoneOff } from "react-icons/ci";
import { CiMicrophoneOn } from "react-icons/ci";
import { MdOutlineCallEnd } from "react-icons/md";

const Video2 = ({Receiverid}) => {

  const { id, chatmasterid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location; 
  console.log('location state',state);
  const {  setVideoPage,Callaccept, setCallaccept,calorname,handleSendCallmessage } = useContext(DataContext);
  const authdata = useSelector((state) => state.auth.user);
  const staticRoomId = chatmasterid ? chatmasterid : state?.chatdata; // Static room ID
  const [inCall, setInCall] = useState(false);
  const socketRef = useRef();
  const peerRef = useRef();
  const localStreamRef = useRef();
  const remoteStreamRef = useRef();
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [userId, setUserId] = useState(null);
  const [otherUserId, setOtherUserId] = useState(''); // State to store the other user's ID
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);

  

  useEffect(() => {
    
    socketRef.current = io(process.env.REACT_APP_VIDEOAPI); // Adjust the server URL

    socketRef.current.on('receive_message', (data)=>{
      console.log('receive_message',data);
    });
    socketRef.current.on('user-connected', handleUserConnected);
    socketRef.current.on('offer', handleReceiveOffer);
    socketRef.current.on('answer', handleAnswer);
    socketRef.current.on('ice-candidate', handleNewICECandidateMsg);
    socketRef.current.on('hang-up', handleRemoteHangUp);


    // Automatically join the static room on load
    joinRoom();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);
  const [visible, setVisible] = useState(false);

  const [mic, setMic] = useState(false);
  const handleMic = () => {
    setMic(!mic);
  };

  const [video, setVideo] = useState(false);
  const handleVideo = () => {
    setVideo(!video);
  };

  const joinRoom = async () => {
    const newUserId = uuidV4(); // Generate a new unique user ID
    setUserId(newUserId); // Store the new user ID in state
   const res = await axios.get(`${Nodeapi}/getuser?id=${id ? id : state?.receivercallid}`);
   
    await startLocalStream();
    socketRef.current.emit('join-room', staticRoomId, authdata?.id, state.call_status,id ? id : state?.receivercallid, authdata?.name,"video","","");
    setInCall(true);
  };

  const startLocalStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setLocalStream(stream);
      localStreamRef.current = stream;
    } catch (error) {
      console.error('Error accessing media devices.', error);
      alert('Could not start video source. Please check your camera and microphone permissions.');
    }
  };

  const createPeerConnection = () => {
    const configuration = {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302'
        }
      ]
    };

    const peerConnection = new RTCPeerConnection(configuration);

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketRef.current.emit('ice-candidate', { roomId: staticRoomId, candidate: event.candidate });
      }
    };

    peerConnection.ontrack = (event) => {
      if (event.streams && event.streams[0]) {
        setRemoteStream(event.streams[0]);
        remoteStreamRef.current = event.streams[0];
      }
    };

    localStreamRef.current.getTracks().forEach(track => {
      peerConnection.addTrack(track, localStreamRef.current);
    });

    return peerConnection;
  };

  const callUser = async (userId) => {
    peerRef.current = createPeerConnection();

    const offer = await peerRef.current.createOffer();
    await peerRef.current.setLocalDescription(new RTCSessionDescription(offer));

    socketRef.current.emit('offer', { roomId: staticRoomId, sdp: offer, userId });
  };

  const handleReceiveOffer = async (data) => {
    // Show a confirmation dialog when receiving a call offer
    peerRef.current = createPeerConnection();
      await peerRef.current.setRemoteDescription(new RTCSessionDescription(data.sdp));
  
      const answer = await peerRef.current.createAnswer();
      await peerRef.current.setLocalDescription(new RTCSessionDescription(answer));
  
      socketRef.current.emit('answer', { roomId: staticRoomId, sdp: answer, userId: data.userId });
  };
  



  const handleAnswer = async (data) => {
    await peerRef.current.setRemoteDescription(new RTCSessionDescription(data.sdp));
  };

  

  const handleNewICECandidateMsg = async (data) => {
    try {
      setCallaccept(true);
      console.log('ice-candidate', data);

      if (!peerRef.current) {
        throw new Error('RTCPeerConnection is not initialized.');
      }

      const candidate = new RTCIceCandidate(data.candidate);
    await peerRef.current.addIceCandidate(candidate);
      console.log('Added ICE candidate:', candidate);
    } catch (error) {
      console.log('Error handling new ICE candidate:', error);
    }
  };

  const handleUserConnected = (userId) => {
    console.log(`User connected: ${userId}`);
    // Automatically call the new user
    callUser(userId);
  };

  const handleToggleAudio = () => {
    const audioTracks = localStreamRef.current.getAudioTracks();
    audioTracks.forEach(track => {
      track.enabled = !track.enabled;
    });
    setAudioMuted(!audioMuted);
  };

  const handleToggleVideo = () => {
    const videoTracks = localStreamRef.current.getVideoTracks();
    videoTracks.forEach(track => {
      track.enabled = !track.enabled;
    });
    setVideoMuted(!videoMuted);
  };

  const handleHangUp = () => {
    if (peerRef.current) {
      peerRef.current.close();
      peerRef.current = null;
    }
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => track.stop());
      localStreamRef.current = null;
    }
    if (remoteStreamRef.current) {
      remoteStreamRef.current.getTracks().forEach(track => track.stop());
      remoteStreamRef.current = null;
    }
    setVideoPage(false);
    setLocalStream(null);
    setRemoteStream(null);
    setInCall(false);
    setCallaccept(false);
    handleSendmessage();
    socketRef.current.emit('hang-up', { roomId: staticRoomId });
    socketRef.current.emit('hang-up',  staticRoomId,"","","","","","decline");
    navigate('/main/Chat');
  };

  const handleRemoteHangUp = (data) => {
    console.log(data);
    if (peerRef.current) {
      peerRef.current.close();
      peerRef.current = null;
    }
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => track.stop());
      localStreamRef.current = null;
    }
    if (remoteStreamRef.current) {
      remoteStreamRef.current.getTracks().forEach(track => track.stop());
      remoteStreamRef.current = null;
    }
    setLocalStream(null);
    setRemoteStream(null);
    setInCall(false);
    setCallaccept(false);

    navigate('/main/Chat');
  
    if(data.userId == authdata?.id || data?.outsidecalldecline=="1"){
      handleSendCallmessage(authdata?.id,data.userId,"00:00",staticRoomId,"callRequest")
    }
  };


  const [time, setTime] = useState(0);
  useEffect(() => {
      let timer;
      if(Callaccept){
        timer = setInterval(() => {
          setTime(prevTime => prevTime + 1);
        }, 1000);
      }
     
      
      return () => clearInterval(timer);
    }, [Callaccept]);

    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };



    const handleStop = () => {
      console.log(time);
    };

    const handleSendmessage = async () => {
  
      try {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        const formattedHours = (hours % 12) || 12;
        const formattedMinutes = String(minutes).padStart(2, '0'); // Pad minutes with leading zero if needed
        
        const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
        const formData = new FormData();
        formData.append("senderid", authdata?.id);
        formData.append("receiverid", id ? id : state?.receivercallid);
        formData.append("type", "msg");
        formData.append("message", formatTime(time));
        formData.append("time", formattedTime);
        formData.append("incoming", ''); // Use boolean value instead of string
        formData.append("outgoing", ''); // Use boolean value instead of string
        formData.append("subtype", 'callRequest');
        formData.append("chatmaster_id", staticRoomId);
        formData.append("image", "");
        formData.append("audio", "");
        formData.append("reply", '');
        formData.append("Msgtype", "User");
        formData.append("replyimg", '');  
        formData.append("delivert_status", "");
        
        const res = await axios.post(`${Nodeapi}/msgconversation`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        
        if (res.data) {
          if (res.data.code == 200) {
            console.log(res);
          } else {
            console.log(res);
          }
        }
      } catch (err) {
      
        console.log("err of insert data", err);
      }
    };




  return (
    <div style={styles.container} className='videocallcontainer'>
      {inCall && (
        <div className="ch-video">
             <div className="ch-videocall">
             
             <video
  className="ch-mecall"
  ref={(video) => {
    if (video && localStream && !video.srcObject) {
      video.srcObject = localStream;
      video.play().catch(error => {
        console.error('Error playing local video:', error);
      });
    }
  }}
  muted
></video>

             <div className="ch-ourcall">
              
             <video
  className="ch-ourvideo"
  ref={(video) => {
    if (video && remoteStream && !video.srcObject) {
      video.srcObject = remoteStream;
      video.play().catch(error => {
        console.error('Error playing remote video:', error);
      });
    }
  }}
></video>

             </div>
             <div className="ch-video-controls">
             
             <button className="ch-video-cont" onClick={handleToggleAudio}>
                  {!audioMuted ? <CiMicrophoneOn /> : <CiMicrophoneOff />}
                </button>
                <button className="ch-video-cont" onClick={handleToggleVideo}>
                  {videoMuted ? <IoVideocamOffOutline /> : <IoVideocamOutline />}
                </button>
                <button onClick={handleHangUp} className="ch-video-cont ch-video-red">
                  <MdOutlineCallEnd />
                </button>



            {/* <button onClick={handleToggleAudio} style={audioMuted ? styles.mutedButton : styles.unmutedButton}>
              {audioMuted ? 'Unmute Audio' : 'Mute Audio'}
            </button>
            <button onClick={handleToggleVideo} style={videoMuted ? styles.mutedButton : styles.unmutedButton}>
              {videoMuted ? 'Unmute Video' : 'Mute Video'}
            </button>
            <button onClick={handleHangUp} style={styles.hangUpButton}>Hang Up</button> */}
         
             </div>
             <div className="ch-video-name">
            
               <h3 className="ch-video-text">{state?.callerfrom == 0 ? state?.callername : calorname}</h3>
               <p className="ch-video-time">{Callaccept ? formatTime(time) : 'calling...'}</p>
             </div>
           </div>
             </div>

      ) }
    </div>
  );
};

const styles = {
 container: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
   // Optional: Adjust to your needs

},

  videoContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'space-around',
  },
  video: {
    width: '45%',
    height: 'auto',
    backgroundColor: 'black',
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mutedButton: {
    backgroundColor: 'red',
    color: 'white',
    marginBottom: '10px',
  },
  unmutedButton: {
    backgroundColor: 'green',
    color: 'white',
    marginBottom: '10px',
  },
  hangUpButton: {
    backgroundColor: 'orange',
    color: 'white',
    marginBottom: '10px',
  },
};

export default Video2;
