import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "./DataContext";
import axios from "axios";
import { Nodeapi, Assetsapi } from "../config/serverUrl";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import { Getimagenames } from "../routes/profileRoutes";
import "../Component/VehicleBoard/VehicleOverview/VehicleOverview.scss";
import { MdOutlineScreenShare } from "react-icons/md";
import { MdOutlineStopScreenShare } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { MdCancelPresentation } from "react-icons/md";
import { MdCommentsDisabled } from "react-icons/md";
import { Grid } from "@mui/material";
import {IoMdPin } from "react-icons/io";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import View_CardDetails_Gallery from "../Component/view_Card_Gallery/View_CardDetails_Gallery";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "#fff",
  border: "0px",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
  //   height: "483px",
  //   overflowY: "scroll",
};

function View_CardDetails() {
  const [confrim, setconfrim] = useState(false);
  const [adreject, setadreject] = useState(false);
  const [reason_text, setreason_text] = useState({
    rejected_reason: "",
  });
  const [visible1, setVisible1] = useState(false);
  const [visible, setVisible] = useState(false);
  const [next_btn, setnext_btn] = useState(false);
  const [selectednext, setselectednext] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showIcon, setShowIcon] = useState(false);
  const navigate = useNavigate();
  const {
    setsenddata,
    senddata,
    setdisplay,
    Ad_Details,
    setAd_Details,
    setAD_rejected,
    AD_rejected,
    setSelectedData,
    selectedData,
    setAD_Posted,
    AD_Posted,
    AD_Total,
    view_adpost,
    setlistimages,
    listimages,
  } = useContext(DataContext);

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [images, setImages] = useState(null);
  const galleria = useRef(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const itemTemplate = (item) => {
    return (
      <img
        src={`${Assetsapi}/adimages/${item}`}
        alt=""
        style={{ width: "100%" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={`${Assetsapi}/adimages/${item}`}
        alt=""
        style={{ display: "block", width: "80px", height: "80px" }}
      />
    );
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    if (galleria.current) {
      galleria.current.show();
    }
  };

  const icon = <i className="pi pi-search"></i>;
  const handlreject = () => {
    // setadreject(true)
    setconfrim(false);
  };

  const handlepost = async () => {
    try {
      if (selectedData.length > 0) {
        const selectedIds = selectedData.map((card) => card.id);
        const countSelectedIds = selectedIds.length;

        const response = await axios.post(`${Nodeapi}/updateDisplay`, {
          selectedIds: selectedIds,
          countSelectedIds: countSelectedIds,
        });

        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Successfully Posted",
            showConfirmButton: false,
            timer: 2000,
          });

          const NextDatas = senddata.filter(
            (item) => item.id !== selectedIds[0]
          );

          setconfrim(false);
          setdisplay(true);
          // fetchdata()
          // setSelectedData(NextDatas)
          setSelectedData([""]);
          setTimeout(() => {
            navigate("/main/Advertisment_Panel");
          }, 2000);
        } else {
          console.error("Failed to update display for selected cards");
        }
      } 
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlepostreject = async () => {
    try {
      if (reason_text.rejected_reason == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Enter the Rejection Reason",
          showConfirmButton: false,
          timer: 2000,
        });
        setadreject(false);
      } else {
        if (selectedData.length > 0) {
          const selectedIds = selectedData.map((card) => card.id);

          const response = await axios.post(`${Nodeapi}/rejectedpost`, {
            selectedIds: selectedIds,
            rejected_reason: reason_text,
          });

          if (response.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Successfully Rejected",
              showConfirmButton: false,
              timer: 2000,
            });
            setdisplay(false);
            setadreject(false);
            setconfrim(false);
            setdisplay(true);
            setTimeout(() => {
              navigate("/main/Advertisment_Panel");
            }, 2000);
          } else {
            console.error("Failed to update display for selected cards");
          }
        } 
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlesubmit = () => {
    setconfrim(true);
  };
  const handlerehect = () => {
    setadreject(true);
  };

  const handlecancel = () => {
    setadreject(false);
  };

  const handleback = async () => {
    setdisplay(true);
    setSelectedData("");
    setAD_rejected(false);
    setAd_Details(true);
    setAD_Posted(false);
    const currentIndex = senddata.indexOf(selectedData[0]);
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      // setCurrentIndex(newIndex);
      let val = [senddata[newIndex]];
      setSelectedData(val);
      const value = senddata[newIndex];
      try {
        const data = await Getimagenames(value.post_id);
        const imageFileNames = data.map((image) => image.file_name);
        setlistimages(imageFileNames);
      } catch (error) {
        console.error("error:", error);
      }
    } else {
      navigate("/main/Advertisment_Panel");
    }
  };

  const handleback1 = async () => {
    setdisplay(true);
    setSelectedData("");
    setAD_rejected(false);
    setAd_Details(false);
    setAD_Posted(true);
    const currentIndex = senddata.indexOf(selectedData[0]);
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      // setCurrentIndex(newIndex);
      let val = [senddata[newIndex]];
      setSelectedData(val);
      const value = senddata[newIndex];
      try {
        const data = await Getimagenames(value.post_id);
        const imageFileNames = data.map((image) => image.file_name);
        setlistimages(imageFileNames);
      } catch (error) {
        console.error("error:", error);
      }
    } else {
      navigate("/main/Advertisment_Panel");
    }
  };

  const handleback2 = async () => {
    setdisplay(true);
    setSelectedData("");
    setAD_rejected(true);
    setAd_Details(false);
    setAD_Posted(false);
    const currentIndex = senddata.indexOf(selectedData[0]);
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      // setCurrentIndex(newIndex);
      let val = [senddata[newIndex]];
      setSelectedData(val);
      const value = senddata[newIndex];
      try {
        const data = await Getimagenames(value.post_id);
        const imageFileNames = data.map((image) => image.file_name);
        setlistimages(imageFileNames);
      } catch (error) {
        console.error("error:", error);
      }
    } else {
      navigate("/main/Advertisment_Panel");
    }
  };

  const handleBackMain = () => {
    setShowIcon(true)
    setdisplay(true)
    setSelectedData([])
    navigate("/main/Advertisment_Panel");
  };

  const handlechange = (e) => {
    let val = e.target.value;
    setreason_text(val);
  };

  const reject1 = () => {
    setVisible1(false);
  };

  const reject = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible1(false);
    navigate("/main/Advertisment_Panel");
    setAD_rejected(false);
    setdisplay(false);
    setAd_Details(true);
  };

  const handleunpost_cancel = () => {
    navigate("/main/Advertisment_Panel");
    setAD_Posted(false);
    setdisplay(false);
    setAd_Details(true);
  };

  const handleUnreject = () => {
    if (selectedData.length > 0) {
      setVisible1(true);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Select the Posts",
        showConfirmButton: false,
        timer: 2000,
      });

      setdisplay(false);
    }
  };

  const accept1 = async () => {
    try {
      if (selectedData.length > 0) {
        const selectedIds = selectedData.map((card) => card.id);

        const response = await axios.post(`${Nodeapi}/Unrejectedpost`, {
          selectedIds: selectedIds,
        });

        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Successfully UnRejected",
            showConfirmButton: false,
            timer: 2000,
          });

          setVisible1(false);
          setdisplay(true);
          setAD_rejected(false);
          setAd_Details(true);
          setTimeout(() => {
            navigate("/main/Advertisment_Panel");
          }, 2000);
        } else {
          console.error("Failed to update display for selected cards");
        }
      } 
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUnpost = async () => {
    if (selectedData.length > 0) {
      setVisible(true);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Select the Posts",
        showConfirmButton: false,
        timer: 2000,
      });
      setdisplay(false);
    }
  };

  const accept = async () => {
    try {
      if (selectedData.length > 0) {
        const selectedIds = selectedData.map((card) => card.id);

        const response = await axios.post(`${Nodeapi}/unpostdisplay`, {
          selectedIds: selectedIds,
        });

        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Successfully UnPosted",
            showConfirmButton: false,
            timer: 2000,
          });
          setdisplay(false);
          setconfrim(false);
          setAD_Posted(false);
          setAd_Details(true);
          setVisible(false);
          setTimeout(() => {
            navigate("/main/Advertisment_Panel");
            setdisplay(true);
          }, 2000);
        } else {
          console.error("Failed to update display for selected cards");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlenext = async () => {
    const currentIndex = senddata.indexOf(selectedData[0]);

    if (currentIndex < senddata.length - 1) {
      const newIndex = currentIndex + 1;
      const val = [senddata[newIndex]];
      setSelectedData(val);
      const value = senddata[newIndex];
      try {
        const data = await Getimagenames(value.post_id);
        const imageFileNames = data.map((image) => image.file_name);
        setlistimages(imageFileNames);
      } catch (error) {
        console.error("error:", error);
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "No further ads from this user.",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };


  return (
    <>
      <div className="VehicleOverview">
        <div
          className="mt-2"
          style={{
            marginRight: "2rem",
            display: "flex",
            justifyContent: 'space-between',
            alignItems: "center",
          }}
        >
          {selectedData.map((data, index) => (

            <h5 key={index} style={{ marginLeft: '2rem' }}>{data.username}</h5>
          ))}

          {AD_Total && (
            <div style={{ display: "flex ", justifyContent: 'flex-end', alignItems: 'center', gap: '0.5rem', marginTop: "2px" }}>
              <MdKeyboardDoubleArrowLeft style={{ fontSize: "22px", cursor: 'pointer' }} onClick={handleback} />
              <IoHomeOutline style={{ fontSize: "22px", cursor: 'pointer' }} onClick={handleBackMain} />
              <MdKeyboardDoubleArrowRight
                style={{ fontSize: "22px", cursor: 'pointer' }}
                onClick={handlenext} />

            </div>
          )}

          {!AD_Total && Ad_Details && (
            <div
              style={{ display: "flex ", justifyContent: 'flex-end', alignItems: 'center', gap: '0.5rem', marginTop: "2px" }}
            >
              <MdKeyboardDoubleArrowLeft style={{ fontSize: "22px", cursor: 'pointer' }} onClick={handleback} />
              <IoHomeOutline style={{ fontSize: "22px", cursor: 'pointer' }} onClick={handleBackMain} />

              <MdKeyboardDoubleArrowRight
                style={{ fontSize: "22px", cursor: 'pointer' }}
                onClick={handlenext} />

            </div>
          )}

          {!AD_Total && AD_Posted && (
            <div
              style={{ display: "flex ", justifyContent: 'flex-end', alignItems: 'center', gap: '0.5rem', marginTop: "2px" }}
            >

              <MdKeyboardDoubleArrowLeft style={{ fontSize: "22px", cursor: 'pointer' }} onClick={handleback1} />

              <IoHomeOutline style={{ fontSize: "22px", cursor: 'pointer' }} onClick={handleBackMain} />
              <MdKeyboardDoubleArrowRight
                style={{ fontSize: "22px", cursor: 'pointer' }}
                onClick={handlenext} />
            </div>
          )}

          {!AD_Total && AD_rejected && (
            <div
              style={{ display: "flex ", justifyContent: 'flex-end', alignItems: 'center', gap: '0.5rem', marginTop: "2px" }}
            >

              <MdKeyboardDoubleArrowLeft style={{ fontSize: "22px", cursor: 'pointer' }} onClick={handleback2} />

              <IoHomeOutline style={{ fontSize: "22px", cursor: 'pointer' }} onClick={handleBackMain} />
              <MdKeyboardDoubleArrowRight
                style={{ fontSize: "22px", cursor: 'pointer' }}
                onClick={handlenext}

              />
            </div>
          )}

        </div>

        <Grid className="mt-0" container spacing={2}>
          <Grid item xs={12} sm={8}>
            <View_CardDetails_Gallery imgValue={listimages} />
            <div className="VehiDesciption mt-4" style={{ padding: "0px 20px" }}>
              <div className="card">
                <h4>Description</h4>
                {selectedData.map((data, index) => (
                  <p key={index}>{data.post_description}</p>
                ))}
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="vehiGal_right">
              <div className="card">
                {selectedData.map((data, index) => (
                  <React.Fragment key={index}>
                    <h5 >{data.post_title}</h5>
                    <div style={{ paddingTop: '1rem' }} >
                      <div className="vehiloco">
                        <p>
                          <IoMdPin />
                          <span>{data.post_location
                      ? data.post_location.toUpperCase()
                      : ""}</span>
                        </p>
                      </div>
                      <div className="vehikms"></div>
                      <div className="vehiprice">
                        <p>
                          <MdOutlineCurrencyRupee />
                          <span>{data.post_price}</span>
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))}



                <div className="vehibutons2">
                  {Ad_Details && (
                    <div className="text-center">
                      <button className="post_btn" onClick={handlesubmit}>
                      <MdOutlineScreenShare style={{paddingBottom:'2px',marginRight:'2px'}}/>
                        Post
                      </button>
                      <button className="reject_btn" onClick={handlerehect}>
                      <MdCancelPresentation style={{paddingBottom:'2px',marginRight:'2px'}}/>
                        Reject
                      </button>
                    </div>
                  )}
                  {AD_rejected && (
                    <div className="text-center" style={{ marginTop: "22px" }}>
                      <button className="btn btn-primary" onClick={handleUnreject}>
                      <MdCommentsDisabled style={{paddingBottom:'2px',marginRight:'2px'}}/>
                        UnReject
                      </button>
                      <button
                        className="btn btn-danger me-2"
                        style={{ marginLeft: "21px" }}
                        onClick={handleCancel}
                      >
                      <MdCancel style={{paddingBottom:'2px',marginRight:'2px'}}/>
                        Cancel
                      </button>
                    </div>
                  )}

                  {AD_Posted && (
                    <div className="text-center" style={{ marginTop: "22px" }}>
                      <button className="btn btn-primary" onClick={handleUnpost}>
                      <MdOutlineStopScreenShare style={{paddingBottom:'2px',marginRight:'2px'}}/>
                        UnPost
                      </button>
                      <button
                        className="btn btn-danger me-2"
                        style={{ marginLeft: "21px" }}
                        onClick={handleunpost_cancel}
                      >
                       <MdCancel style={{paddingBottom:'2px',marginRight:'2px'}}/>
                        Cancel
                      </button>
                    </div>
                  )}
                </div>

                <Modal open={confrim} onClose={() => setconfrim(false)}>
                  <Box sx={style} style={{ width: "303px" }}>
                    <div className="justify-content-between" style={{ display: "flex" }}>
                      <h6 className="mb-2">Confirmation:</h6>
                      <IoCloseSharp
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={() => setconfrim(false)}
                      />
                    </div>

                    <p>Are you sure you want to post?</p>
                    <button className="reject_btn" onClick={handlreject}>
                      No
                    </button>
                    <button className="post_btn" onClick={handlepost}>
                      Yes
                    </button>
                  </Box>
                </Modal>

                <Modal open={adreject} onClose={() => setadreject(false)}>
                  <Box sx={style} style={{ width: "370px" }}>
                    <p style={{ marginTop: "9px" }}>Reason ?</p>

                    <textarea
                      value={reason_text.rejected_reason}
                      className="text_area"
                      onChange={handlechange}
                    ></textarea>

                    <button onClick={handlepostreject} className="rej_sub_bt">
                      Submit
                    </button>

                    <button onClick={handlecancel} className="rej_cancel_btn">
                      Cancel
                    </button>
                  </Box>
                </Modal>

                <Modal open={visible1} onClose={() => setVisible1(false)}>
                  <Box sx={style} style={{ width: "303px" }}>
                    <div className="justify-content-between" style={{ display: "flex" }}>
                      <h6 className="mb-2">Confirmation:</h6>
                      <IoCloseSharp
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={() => setVisible1(false)}
                      />
                    </div>

                    <p>Are you sure you want to UnReject the Ad?</p>
                    <button className="reject_btn" onClick={reject1}>
                      No
                    </button>
                    <button className="post_btn" onClick={accept1}>
                      Yes
                    </button>
                  </Box>
                </Modal>

                <Modal open={visible} onClose={() => setVisible(false)}>
                  <Box sx={style} style={{ width: "303px" }}>
                    <div className="justify-content-between" style={{ display: "flex" }}>
                      <h6 className="mb-2">Confirmation:</h6>
                      <IoCloseSharp
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={() => setVisible(false)}
                      />
                    </div>

                    <p>Are you sure you want to UnPost the Ad?</p>
                    <button className="reject_btn" onClick={reject}>
                      No
                    </button>
                    <button className="post_btn" onClick={accept}>
                      Yes
                    </button>
                  </Box>
                </Modal>

              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default View_CardDetails;
