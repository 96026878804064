// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.chatloader {
    width: 60px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side,#ddd5d5 90%,#f7f6f600) 0/calc(100%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1s steps(4) infinite;
  }
  @keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}`, "",{"version":3,"sources":["webpack://./src/utils/threeDot.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,WAAW;IACX,eAAe;IACf,gGAAgG;IAChG,4BAA4B;IAC5B,kCAAkC;EACpC;EACA,eAAe,GAAG,4BAA4B,CAAC","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.chatloader {\n    width: 60px;\n    aspect-ratio: 4;\n    background: radial-gradient(circle closest-side,#ddd5d5 90%,#f7f6f600) 0/calc(100%/3) 100% space;\n    clip-path: inset(0 100% 0 0);\n    animation: l1 1s steps(4) infinite;\n  }\n  @keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
