import axios from "axios";
import { Nodeapi } from "../config/serverUrl";

export const FetchDetails = async (id, table) => {
  try {
    const res = await axios.post(`${Nodeapi}/fetchdetails`, {
      id: id,
      table: table,
    });

    if (res.data) {
      if (res.data.code == 200) {
        return table == "JobDetails"
          ? [res.data.data.response, res.data.data.jobSkills]
          : res.data.data.response;
      } else {
        return null;
      }
    }
  } catch (error) {
    return null;
  }
};

export const ContactInformation = async (id) => {
  try {
    const res = await axios.post(`${Nodeapi}/fetchContactInformation`, {
      id: id,
    });

    if (res.data) {
      if (res.data.code == 200) {
        return res.data.data.response;
      } else {
        return res;
      }
    }
  } catch (error) {
    return error;
  }
};

export const AddContactForm = async (ContactForm, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust the content type based on your API requirements
    };

    const res = await axios.post(`${Nodeapi}/contactinformation`, ContactForm, {
      headers,
    });
    if (res.data) {
      if (res.data.code == 200) {
        return res;
      } else {
        return res;
      }
    }
  } catch (error) {
    return null;
  }
};

export const UpdateContactForm = async (ContactForm, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Adjust the content type based on your API requirements
  };
  try {
    const res = await axios.post(`${Nodeapi}/updateContact`, ContactForm, {
      headers,
    });
    if (res.data) {
      if (res.data.code == 200) {
        return res;
      } else {
        return res;
      }
    }
  } catch (error) {
    return error;
  }
};

export const AddDetails = async (ContactForm) => {
  try {
    const res = await axios.post(`${Nodeapi}/addDetails`, ContactForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.data) {
      if (res.data.code == 200) {
        return res;
      } else {
        return res;
      }
    }
  } catch (error) {
    return "Error Inserting Data";
  }
};

export const UpdateDetails = async (ContactForm) => {
  try {
    const res = await axios.post(`${Nodeapi}/updateDetails`, ContactForm);
    if (res.data) {
      if (res.data.code == 200) {
        return res;
      } else {
        return res;
      }
    }
  } catch (error) {
    return "Error Updateing Data";
  }
};

export const Edit_Home_Data = async (ContactForm) => {
  try {
    const res = await axios.post(`${Nodeapi}/HmApplianceData`, ContactForm);
    if (res.data) {
      if (res.data.code == 200) {
        return res;
      } else {
        return null;
      }
    }
  } catch (error) {
    return null;
  }
};

export const Update_Home_Data = async (ContactForm) => {
  try {
    const res = await axios.post(`${Nodeapi}/HmApplianceData`, ContactForm);
    if (res.data) {
      if (res.data.code == 200) {
        return res;
      } else {
        return null;
      }
    }
  } catch (error) {
    return null;
  }
};


//AdvertisingDashboard
export const FetchPostDetails = async (userId) => {
  try {
    const res = await axios.get(`${Nodeapi}/adpost?id=${userId}`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch post details", error);
    return null;
  }
};


export const addPostDetails = async (postDetails) => {
  try {
    const res = await axios.post(`${Nodeapi}/adpost/adHome`, postDetails, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res.data && res.data.success) {
      return res.data;
    } else {
      throw new Error(res.data.message || "Failed to add post details");
    }
  } catch (error) {
    console.error("Failed to add job details", error);
    throw error;
  }
};

export const updatePostDetails = async (id, formData) => {
  try {
    const res = await axios.put(`${Nodeapi}/adpost/update/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to update post details", error);
    return null;
  }
};

export const deleteDraft = async (id) => {
  try {
    const response = await axios.put(`${Nodeapi}/adpost/adHome/draftDelete/${id}`);
    console.log(response.data.message);
  } catch (error) {
    console.error("Error marking post as deleted:", error.response.data.message);
  }
};

export const reActivateDelete = async (postId) => {
  try {
    const res = await axios.put(`${Nodeapi}/adpost/${postId}/reactivate`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to activate post", error);
    return null;
  }
};
export const activatePost = async (postId) => {
  try {
    const res = await axios.put(`${Nodeapi}/adpost/${postId}/activate`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to activate post", error);
    return null;
  }
};

export const deactivatePost = async (postId) => {
  try {
    const res = await axios.put(`${Nodeapi}/adpost/${postId}/deactivate`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to activate post", error);
    return null;
  }
};

export const FetchUserPostDetails = async (userId) => {
  try {
    const res = await axios.get(`${Nodeapi}/adpost/adDashboard?id=${userId}`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch post details", error);
    return null;
  }
};


export const FetchJobDetails = async (userId) => {
  try {
    const res = await axios.get(`${Nodeapi}/jobDetails?id=${userId}`);

      console.log('response:',res);

    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch job details", error);
    return null;
  }
};

export const FetchJobRoleDetails = async () => {
  try {
    const res = await axios.get(`${Nodeapi}/jobDetails/roles`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch job details", error);
    return null;
  }
};

export const FetchJobSkillDetails = async () => {
  try {
    const res = await axios.get(`${Nodeapi}/jobDetails/skills`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch job details", error);
    return null;
  }
};

export const FetchJobDesignationDetails = async () => {
  try {
    const res = await axios.get(`${Nodeapi}/jobDetails/designations`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch job details", error);
    return null;
  }
};


export const addJobDetails = async (jobDetails) => {
  try {
    const res = await axios.post(`${Nodeapi}/jobDetails`, jobDetails);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to add job details", error);
    return null;
  }
};

export const updateJobDetails = async (id, jobDetails) => {
  try {
    const res = await axios.put(`${Nodeapi}/jobDetails/${id}`, jobDetails);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to update job details", error);
    return null;
  }
};

export const deleteJobDetails = async (id) => {
  try {
    const res = await axios.delete(`${Nodeapi}/jobDetails/${id}`);
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to delete job details", error);
    return null;
  }
};

export const Getimagenames = async(userid)=>{
  try {
    const res = await axios.get(`${Nodeapi}/getimagename?userid=${userid}`)
    const data = res.data.result
    return data
  } catch (error) {
    console.log('Error In Listing Images:',error);
  }
}
