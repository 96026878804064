import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { IoHomeOutline, IoShareSocialOutline } from "react-icons/io5";
import { TiArrowForwardOutline } from "react-icons/ti";
import { IoMdHeartEmpty, IoMdPin } from "react-icons/io";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdOutlineScreenShare,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import LostAndFoundGallery from "./LostAndFoundGallery";
// import LostAndFoundSimilarCard from "./LostAndFoundSimilarCard";
import { FaRegEdit } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import LostAndFoundModal from "./LostAndFoundModal";
import axios from "axios";
import Swal from "sweetalert2";
import { Nodeapi } from "../../config/serverUrl";
import { useSelector } from "react-redux";
import { useFormData } from "./UseState/LostAndFoundUseState";

const LostAndFoundOverview = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [formData, setFormData] = useFormData();
  const [errors, setErrors] = useState({});
  const [itemData, setItemData] = useState([]);
  const [reportType, setReportType] = useState("");
  const [loading, setLoading] = useState(true);
  const [editId, setEditId] = useState("");
  const [removedImages, setRemovedImages] = useState([]);
  const authData = useSelector((state) => state.auth.user);
  const userId = authData?.id;
  const { id } = useParams();
  
  useEffect(() => {
    fetchData();
  }, [userId]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${Nodeapi}/lostfound/${userId}`);
      if (Array.isArray(data.data)) {
        setItemData(data.data);
        setLoading(false)
      } else {
        console.error("API response is not an array:", data);
      }
    } catch (error) {
      console.error("Error fetching lost and found items:", error);
    }
  };

  const sortedItem = itemData.sort(
    (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
  );
  const itemId = parseInt(id);
  const item = sortedItem.find((post) => post.id === itemId);

  useEffect(() => {
    if (item) {
      if (!itemData.some((row) => row.id === item.id)) {
        setItemData((prev) => [...prev, item]);
      }
      setFormData(item);  
      setReportType(item.reportType);
      setEditId(item.id);
      setMode("edit");
    }
    setLoading(false);
  }, [item]);  
  
  useEffect(() => {
  }, [formData]);
  
  const handleOpen = (type) => {
    setOpen(true);
    setFormData(type);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      itemName: "",
      category: "",
      description: "",
      lostAt: "",
      street: "",
      landmark: "",
      area: "",
      city: "",
      district: "",
      state: "",
      pincode: "",
      dateTime: "",
      name: "",
      email: "",
      contactNo: "",
      images: [],
    });
    setErrors({});
  };
  if (loading) {
    return (
      <div className="loading-spinner-container d-flex justify-content-center align-items-center">
        <ClipLoader color="#3498db" loading={loading} size={50} />
      </div>
    );
  }

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const newFiles = Array.from(files);
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [
          ...(Array.isArray(prevFormData.images) ? prevFormData.images : []),
          ...newFiles,
        ],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleRemoveImage = (indexToRemove) => {
    setFormData((prevFormData) => {
      const removedImage = prevFormData.images[indexToRemove];
      setRemovedImages((prevRemovedImages) => [
        ...prevRemovedImages,
        removedImage,
      ]);
      return {
        ...prevFormData,
        images: prevFormData.images.filter(
          (_, index) => index !== indexToRemove
        ),
      };
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const requiredFields = [
      "itemName",
      "category",
      "description",
      // "street",
      // "landmark",
      // "area",
      // "city",
      "district",
      "state",
      // "pincode",
      "dateTime",
      "name",
      // "email",
      "contactNo",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field
          .replace(/([A-Z])/g, " $1")
          .replace(/\b\w/g, (str) => str.toUpperCase())
          .toLowerCase()
          .replace(/^\w/, (str) => str.toUpperCase())} is required`;
        isValid = false;
      }
    });
    // if (!/^\d{6}$/.test(formData.pincode)) {
    //   newErrors.pincode = "Pincode must be 6 digits";
    //   isValid = false;
    // }
    // if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //   newErrors.email = "Email is not valid";
    //   isValid = false;
    // }
    if (!/^\d{12}$/.test(formData.contactNo)) {
      newErrors.contactNo = "Contact No must be 10 digits";
      isValid = false;
    }
    if (reportType === "lost" && !formData.lostAt) {
      newErrors.lostAt = "Lost At is required";
      isValid = false;
    }
    if (reportType === "found" && !formData.foundAt) {
      newErrors.foundAt = "Found At is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const prepareFormData = (data) => {
    const cleanedData = { ...data };
    if (reportType === "lost") {
      delete cleanedData.foundAt;
    } else if (reportType === "found") {
      delete cleanedData.lostAt;
    }
    return cleanedData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const cleanedData = prepareFormData(formData);
      cleanedData.removedImages = removedImages;
      try {
        const formDataToSend = new FormData();
        Object.keys(cleanedData).forEach((key) => {
          if (key === "images") {
            cleanedData[key].forEach((file) => {
              formDataToSend.append("images", file);
            });
          } else {
            formDataToSend.append(key, cleanedData[key]);
          }
        });
        removedImages.forEach((image) => {
          formDataToSend.append("removedImages", image);
        });
        const response = await axios.put(
          `${Nodeapi}/lostfound/${editId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Post updated Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        handleClose();
        handleHome();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const currentIndex = itemData.findIndex((post) => post.id === itemId);
  const previousIndex = currentIndex > 0 ? currentIndex - 1 : null;
  const nextIndex = currentIndex < itemData.length - 1 ? currentIndex + 1 : null;
  const handleBack = () => {
    if (previousIndex !== null) {
      navigate(`/main/LostAndFoundOverview/${itemData[previousIndex].id}`);
    }
  };

  const handleNext = () => {
    if (nextIndex !== null) {
      navigate(`/main/LostAndFoundOverview/${itemData[nextIndex].id}`);
    }
  };

  const handleHome = () => {
    navigate("/main/LostAndFound");
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Check this out!',
          text: 'Have a look at this amazing content!',
          url: window.location.href,
        });
      } else {
        alert('Sharing is not supported in this browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <div className="LostAndFoundOverview">
      <div
          className="mt-2"
          style={{
            marginRight: "2rem",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            style={{ border: "none", background: "none" }}
            onClick={handleBack}
            disabled={previousIndex === null}
          >
            <MdKeyboardDoubleArrowLeft
              style={{ fontSize: "22px", marginTop: "-8%" }}
            />
          </button>
          <button
            style={{ border: "none", background: "none" }}
            onClick={handleHome}
          >
            <IoHomeOutline style={{ fontSize: "22px", marginTop: "-8%" }} />
          </button>
          <button
            style={{ border: "none", background: "none" }}
            onClick={handleNext}
            disabled={nextIndex === null}
          >
            <MdKeyboardDoubleArrowRight
              style={{ fontSize: "22px", marginTop: "-8%" }}
            />
          </button>
        </div>
      {loading ? (
        <div className="spinner-container">
          <div className="spin"></div>
        </div>
      ) : (
        <Grid className="mt-0" container spacing={2}>
          <Grid item xs={12} sm={8}>
            <LostAndFoundGallery
              imgValue={item?.images || []}
            />
            <div className="Description mt-4" style={{ padding: "0px 20px" }}>
              <div className="card">
                <h4>Description</h4>
                <p>{item?.description}</p>
            </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="gallery_right">
              <div className="card">
                <div className="flex align-items-center justify-content-between mb-2">
                  <h5>{item?.itemName}</h5>
                  <div className="buttons_lnf flex align-items-center justify-content-between">
                    <p onClick={handleShare}>
                      <IoShareSocialOutline className="but-icon" />
                    </p>
                    <p>
                      <TiArrowForwardOutline className="but-icon" />
                    </p>
                    <p>
                      <IoMdHeartEmpty className="but-icon" />
                    </p>
                  </div>
                </div>
                <div className="location_lnf">
                  <p>
                    <IoMdPin />
                    <span>{item?.district}</span>
                  </p>
                </div>
                <div className="buttons2_lnf mt-2">
                  <button className="btn1_lnf" onClick={()=>handleOpen(item)}>
                    <FaRegEdit />
                    <span>Edit Post</span>
                  </button>
                  <button className="btn1_lnf" onClick={() => {}}>
                    <MdOutlineScreenShare />
                    <span>Post Now</span>
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
       )} 
      {/* <div className="SimilarCard">
        <LostAndFoundSimilarCard currentItem={item} />
      </div> */}
      <LostAndFoundModal
        open={open}
        handleClose={handleClose}
        reportType={reportType}
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        handleChange={handleChange}
        handleRemoveImage={handleRemoveImage}
        validateForm={validateForm}
        prepareFormData={prepareFormData}
        handleSubmit={handleSubmit}
        mode={mode}
      />
    </div>
  );
};

export default LostAndFoundOverview;
