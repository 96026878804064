import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Nodeapi } from "../config/serverUrl";
import Message from "../Component/Chat/ChatSection/ChatArea/ChatBox/Message";
import ChatSection from "../Component/Chat/ChatSection/ChatSection";
import { DataContext } from "./DataContext";
import "../Component/Chat/ChatSection/ChatArea/ChatBox/Message.css";
import { Image } from "primereact/image";
import { FileText } from "@phosphor-icons/react";
import { BackendApi, BackendVidoeApi, BackendAudioApi, DocumentApi } from "../config/serverUrl";
import { FaImage } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import docimg from '../assets/Icons/pdf-icon.png';
import audioreply from '../assets/Icons/audio-headset.png'
import videoreply from '../assets/Icons/play.png'
import { AiFillAudio } from "react-icons/ai";
import { FaVideo } from "react-icons/fa";
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

const ReportChatArea = ({
  selectedReportId,
  selectedChatMasterId,
  repUserPrimaryId,
  repReportedPrimaryId
}) => {

  const [chathistory, setChathistory] = useState([]);
  const scrollViewRef = useRef(null);

  const fetchMessages = async () => {
    try {
      const data = {
        reportId:selectedReportId};
      const res = await axios.post(`${Nodeapi}/fetchChatMessages`,data);
      console.log(res);
      console.log("Message fetched=>", res.data.data.allMsgs);
      setChathistory(res.data.data.allMsgs);
    } catch (error) {
      console.log("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    console.log("ReportChatArea mounted successfully");
    fetchMessages();
  }, []);

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    
  }, [chathistory]);

  const groupMessagesByDate = (messages) => {
    if (!messages || !Array.isArray(messages)) {
      // Handle case where messages is undefined or not an array
      return [];
    }

    const groupedMessages = [];
    let currentDate = null;

    messages.forEach((el) => {
      if (!el || typeof el.date_added !== "string") {
        // Skip invalid message objects
        return;
      }

      const messageDate = new Date(el.date_added).toDateString();

      if (messageDate !== currentDate) {
        groupedMessages.push({ type: "divider", date: messageDate });
        currentDate = messageDate;
      }

      groupedMessages.push(el);
    });

    return groupedMessages;
  };

  useEffect(() => {
    groupMessagesByDate(chathistory);
  }, [chathistory]);

  const groupedChathistory = groupMessagesByDate(chathistory);

  return (
    <div className="report-chat-area" ref={scrollViewRef} style={{ overflowY: "auto", height: "59vh" }}>

      <div className="message" >
        {chathistory !==null ? (<>
        
        {groupedChathistory.map((el) => { 
          if (el.type === "divider") {
            return (
              <Timeline
                el={el}
                repReportedPrimaryId={repReportedPrimaryId}

              />
            );
          }

          switch (el.type) {
            case "divider":
              return (
                <Timeline
                  el={el}
                  repReportedPrimaryId={repReportedPrimaryId}

                />
              );

            case "msg":
              switch (el.subtype) {
                case "img":
                  return (
                    <MediaMsg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}

                    />
                  );

                case "video":
                  return (
                    <VideoMsg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );

                case "audio":
                  return (
                    <AudioMsg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );

                case "doc":
                  return (
                    <PdfMsg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );
                case "reply":
                  return (
                    <ReplyImg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );
                case "img_reply":
                  return (
                    <ReplyImg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );
                case "doc_reply":
                  return (
                    <ReplyImg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );
                case "audio_reply":
                  return (
                    <ReplyImg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );
                case "video_reply":
                  return (
                    <ReplyImg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );
                case "link":
                  return (
                    <LinkMsg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    />
                  );
                default:
                  return (
                    <TextMsg
                      el={el}
                      repReportedPrimaryId={repReportedPrimaryId}
                    // data={data}
                    />
                  );
              }

            default:
              return <></>;
          }
        })}
        </>) :(<><p>No Chats Found</p></>) }
      </div>
      
    </div>
  );
}

const TextMsg = ({ el, repReportedPrimaryId }) => {
  return (
    <div className="msg-text" style={{ justifyContent: el.sender_id == repReportedPrimaryId ? "flex-end" : "flex-start" }}>
      <div className="right" style={{ background: '#eff5fc' }}>
        <div>
          <p className="text">{el.message}</p>
          <p className="chat-time">{el.time}</p>
        </div>
      </div>
    </div>
  );
};

const LinkMsg = ({ el, repReportedPrimaryId }) => {
  return (
    <div className="msg-text" style={{ justifyContent: el.sender_id == repReportedPrimaryId ? "flex-end" : "flex-start" }}>
      <div className="right" style={{ background: '#eff5fc' }}>
        <div>
          <p className="text"><a style={{ fontWeight: "300", wordBreak: "break-word" }} href={el.message}>{el.message}</a></p>
          <p className="chat-time">{el.time}</p>
        </div>
      </div>
    </div>
  );
};

const Timeline = ({ el, repReportedPrimaryId }) => {
  return (
    <div className="text-center" >
      <p className="timeline" >
        <span style={{ background: '#eff5fc' }}>{el.date}</span>
      </p>
    </div>
  );
};
//fd
const MediaMsg = ({ el, repReportedPrimaryId }) => {
  return (
    <div className="msg-img" style={{ justifyContent: el.sender_id == repReportedPrimaryId ? "flex-end" : "flex-start" }}>
      <div className="right" style={{ background: '#eff5fc' }}>
        <div>
          {el.img && (
            <Image
              src={`${BackendApi}/${el.img}`}
              alt=""
              className="img-chat"
              width="100%"
              preview
            />
          )}
          {el.message && <p className="chat-text-img">{el.message}</p>}
          <p className="chat-time">{el.time}</p>
        </div>
      </div>
    </div>
  );
};

const VideoMsg = ({ el, repReportedPrimaryId }) => {
  return (
    <div className="msg-vdo" style={{ justifyContent: el.sender_id == repReportedPrimaryId ? "flex-end" : "flex-start" }}>
      <div className="right" style={{ background: '#eff5fc' }}>
        <div>
          {el.img && <video src={`${BackendVidoeApi}/${el.img}`} controls className="chat-vdo" />}
          {el.message && <p className="chat-text-vdo">{el.message}</p>}
          <p className="chat-time">{el.time}</p>
        </div>
      </div>
    </div>
  );
};

const AudioMsg = ({ el, repReportedPrimaryId }) => {
  return (
    <div className="msg-ado" style={{ justifyContent: el.sender_id == repReportedPrimaryId ? "flex-end" : "flex-start" }}>
      <div className="right" style={{ background: '#eff5fc' }}>
        <div>
          {el.img && (
            <audio controls loop>
              <source src={`${BackendAudioApi}/${el.img}`} type="audio/mp3" className="chat-ado" />
            </audio>
          )}
          {el.message && <p className="chat-text-ado">{el.message}</p>}
          <p className="chat-time">{el.time}</p>
        </div>
      </div>
    </div>
  );
};

const PdfMsg = ({ el, repReportedPrimaryId }) => {
  return (
    <div className="msg-pdf" style={{ justifyContent: el.sender_id == repReportedPrimaryId ? "flex-end" : "flex-start" }}>
      <div className="right" style={{ background: '#eff5fc' }}>
        <div className=" ">
          {el.img && (
            <div className="pdf-chat-side">
              <FileText size={40} className="pdf-chat-img" />
              <div className="text">
                <p className="pdf-name">{el.img}</p>
                <p className="pdf-size">1mb</p>
              </div>
            </div>
          )}
          {el.message && <p className="chat-text-pdf">{el.message}</p>}
          <p className="chat-time">{el.time}</p>
        </div>
      </div>
    </div>
  );
};


const ReplyImg = ({ el, repReportedPrimaryId }) => {

  let icon;
  let text;
  let source;
  if (el.subtype == "img_reply") {
    icon = <FaImage />;
    text = 'image';
    source = <img src={`${BackendApi}/${el.img}`} alt="" className="img-reply-img" />;
  } else if (el.subtype == "doc_reply") {
    icon = <IoDocumentText />;
    text = 'Document';
    source = <img src={docimg} alt="" className="img-reply-img" />;
  }
  else if (el.subtype == "audio_reply") {
    icon = <AiFillAudio />;
    text = 'Audio';
    source = <img src={audioreply} alt="" className="img-reply-img" />;
  }
  else if (el.subtype == "video_reply") {
    icon = <FaVideo />;
    text = 'Video';
    source = <img src={videoreply} alt="" className="img-reply-img" />;
  }
  else if (el.subtype == "reply") {
    text = el.message;
  }

  return (
    <div className="reply-img" style={{ justifyContent: el.sender_id == repReportedPrimaryId ? "flex-end" : "flex-start" }}>
      <div className="right" style={{ background: '#eff5fc' }}>
        <div
          className="d-flex justify-content-between"
          style={{ background: "#fff", padding: "8px", borderRadius: "4px" }}
        >
          <div className="right-head">
            <h6>@mukesh</h6>
            <div className="d-flex ">
              <div className="reply-icon">
                {icon}
              </div>
              <p>{text} </p>
            </div>
          </div>
          {
            el.subtype != 'reply' && (
              <div className="img-reply">
                <div className="img-reply-img">
                  {source}
                </div>
              </div>
            )
          }
        </div>
        <div className="">
          <p style={{ fontsize: "0.8rem" }}>
            {el.reply}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReportChatArea;




