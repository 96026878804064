import React, { useState } from "react";
import { useEffect } from "react";
import { FetchDetails } from "../../routes/profileRoutes";
import Jobusestates from "../../useStates/JobUsestate";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { style } from "../../Styles/Jobformstyle";
import "../../Component/AddEducationDetails/School.css";
import { MdModeEdit } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import {
  religions,
  nationalities,
  communities,
  nationalPartyNames,
  maritalStatus,
  bloodgroup,
} from "./Dropdown_list";
import "./Relationship.css";
import Swal from "sweetalert2";
import Select from "react-select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { Calendar } from "primereact/calendar";

const PersonalDetailsForm1 = () => {
  const authdata = useSelector((state) => state.auth.user);

  const toast = useRef(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [age, setAge] = useState("");
  const [finalval, setfinalval] = useState([]);

  const [selectedDisabilityReasons, setSelectedDisabilityReasons] = useState(
    []
  );
  const [displayedDisabilityInfo, setDisplayedDisabilityInfo] = useState(null);

  const allDisabilityReasons = [
    // Physical Disabilities
    "Paralysis",
    "Limb loss or limb difference",
    "Cerebral palsy",
    "Muscular dystrophy",
    "Spinal cord injury",
    "Multiple sclerosis",
    "Parkinson's disease",

    // Sensory Disabilities
    "Blindness",
    "Low vision",
    "Deafness",
    "Hearing impairment",
    "Deafblindness",

    // Cognitive Disabilities
    "Intellectual disabilities",
    "Down syndrome",
    "Autism spectrum disorders",
    "Dyslexia",
    "Attention deficit hyperactivity disorder (ADHD)",
    "Developmental delays",

    // Mental Health Disabilities
    "Depression",
    "Anxiety disorders",
    "Bipolar disorder",
    "Schizophrenia",
    "Post-traumatic stress disorder (PTSD)",
    "Obsessive-compulsive disorder (OCD)",

    // Chronic Health Conditions
    "Chronic pain conditions (e.g., fibromyalgia)",
    "Diabetes",
    "Asthma",
    "Epilepsy",
    "Chronic fatigue syndrome",
    "HIV/AIDS",

    // Neurological Disabilities
    "Epilepsy",
    "Alzheimer's disease",
    "Huntington's disease",
    "Tourette syndrome",
    "Traumatic brain injury (TBI)",
    "Cerebral vascular accident (stroke)",

    // Communication Disabilities
    "Speech disorders",
    "Aphasia",
    "Voice disorders",

    // Learning Disabilities
    "Dyslexia",
    "Dyscalculia",
    "Dysgraphia",
    "Auditory processing disorder",
    "Visual processing disorder",
  ];

  const handleAddReasonClick = () => {
    const selectedReason = document.getElementById("disabilityReason").value;

    if (selectedReason && !selectedDisabilityReasons.includes(selectedReason)) {
      setSelectedDisabilityReasons((prevReasons) => [
        ...prevReasons,
        selectedReason,
      ]);
      document.getElementById("disabilityReason").value = "";
      setDisplayedDisabilityInfo(null);
    }
  };

  const [personal, setpersonal] = useState(false);
  const handlePersonalOpen = () => setpersonal(true);
  const handlePersonalClose = () => setpersonal(false);

  const {
    personaldetails,
    setPersonaldetails,
    PersonalDetailsForm,
    setPersonalDetailsForm,
  } = Jobusestates();

  const fetchPersonalInformation = async () => {
    const fetchPersonalDetails = await FetchDetails(
      authdata?.id,
      "PersonalDetails"
    );

    if (fetchPersonalDetails) {
      setPersonaldetails(fetchPersonalDetails);
    }
  };

  useEffect(() => {
    fetchPersonalInformation();
  }, [authdata?.id]);

  const fetchDobDetails = async () => {
    try {
      const response = await axios.post(`${Nodeapi}/fetchdob`, {
        id: authdata?.id,
      });

      const data = response.data.data.fetchDob;
      const dob = new Date(data[0].dateofbirth);
      setDateOfBirth(dob);
    } catch (error) {
      console.error("Error fetching date of birth:", error);
    }
  };
  useEffect(() => {
    fetchDobDetails();
  }, [authdata?.id]);

  useEffect(() => {
    if (dateOfBirth) {
      const today = moment();
      const dobMoment = moment(dateOfBirth);

      const years = today.diff(dobMoment, "years");
      dobMoment.add(years, "years");

      const months = today.diff(dobMoment, "months");
      dobMoment.add(months, "months");

      const days = today.diff(dobMoment, "days");

      setAge(`${years} years, ${months} months, ${days} days`);
    }
  }, [dateOfBirth]);

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.value);
    const utcDate = new Date(
      Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      )
    );
    setDateOfBirth(utcDate);
  };

  const formatDateForDisplay = (date) => {
    if (!date) return "";
    const localDate = new Date(date.toLocaleString());
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const addEditValues = async (e, table) => {
    e.preventDefault();
    const fetchPersonalDetails = await FetchDetails(authdata?.id, table);
    if (fetchPersonalDetails) {
      setPersonalDetailsForm(fetchPersonalDetails);
      const selected = fetchPersonalDetails?.nationality?.split(",");
      setfinalval(selected);
    } else {
      setPersonalDetailsForm(null);
    }
  };

  const handlenationality = (value) => {
    const selectedSkills = value.map((option) => option.value);
    setfinalval(selectedSkills);
    const filterdata = value.map((option) => option.value).join(",");
    const updatedForm = {
      ...PersonalDetailsForm,
      nationality: filterdata,
    };
    setPersonalDetailsForm(updatedForm);
  };

  const removeEmptyStrings = (obj) => {
    const cleanedObject = {};
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (value !== "" && value !== null && value !== undefined) {
        cleanedObject[key] = value;
      }
    });
    return cleanedObject;
  };

  const handlePersonalDetails = (e, gender) => {
    const { name, value } = e.target;
    let updatedGender =
      gender || (PersonalDetailsForm ? PersonalDetailsForm.gender : "");

    const updatedForm = {
      ...PersonalDetailsForm,
      [name]: value === "" ? null : value,
      userid: authdata?.id,
      canditate_id: authdata?.userid,
      gender: updatedGender,
      age: age,
    };

    const cleanedForm = removeEmptyStrings(updatedForm);
    setPersonalDetailsForm(cleanedForm);
  };

  const HandleAddPersonalDetails = async (e) => {
    e.preventDefault();
    const action = e.nativeEvent.submitter.value;

    const personalDetailsData = {
      personalData: removeEmptyStrings(PersonalDetailsForm),
      userData: {
        id: PersonalDetailsForm.userid,
        dateofbirth: dateOfBirth
          ? dateOfBirth.toISOString().split("T")[0]
          : null,
      },
    };

    try {
      let response;
      if (action.trim() === "update") {
        response = await axios.post(
          `${Nodeapi}/updateDetails`,
          personalDetailsData
        );
      } else {
        if (!PersonalDetailsForm) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please Fill Out All Required Fields",
            showConfirmButton: false,
            timer: 2000,
          });
          return setpersonal(false);
        }
        response = await axios.post(
          `${Nodeapi}/addpersonaldetails`,
          personalDetailsData
        );
      }
      if (response.data.status === "success") {
        setpersonal(false);
        if (
          Array.isArray(response.data.data.personalResponse) &&
          response.data.data.personalResponse.length > 0
        ) {
          setPersonaldetails(response.data.data.personalResponse[0]);
        } else {
          console.warn("personalResponse is not an array or is empty");
          setPersonaldetails(null);
        }

        Swal.fire({
          position: "center",
          icon: "success",
          title:
            action.trim() === "update"
              ? "Updated Successfully"
              : "Personal Details Inserted Successfully",
          showConfirmButton: false,
          timer: 2000,
        });

        await fetchPersonalInformation();
        await fetchDobDetails();
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: response.data.message || "Error 404",
          showConfirmButton: false,
          timer: 2000,
        });
        setpersonal(false);
      }
    } catch (error) {
      console.error("Error updating details:", error);
      Swal.fire({
        position: "center",
        icon: "error",
        title: error.response?.data?.message || "An unexpected error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
      setpersonal(false);
    }
  };

  const sortedCommunities = communities.sort((a, b) => a.localeCompare(b));

  return (
    <div>
      <Toast ref={toast} />
      <div className="d-flex justify-content-between align-items-center ">
        {!personaldetails && (
          <h5
            style={{ color: "#2196F3", fontWeight: "bold", fontSize: "21px" }}
          >
            Personal Details
          </h5>
        )}

        <p>
          {!personaldetails && (
            <Button
              onClick={(e) => {
                handlePersonalOpen();
                addEditValues(e, "PersonalDetails");
              }}
            >
              <MdAdd size={22} />
            </Button>
          )}

          <Modal
            open={personal}
            onClose={handlePersonalClose} 
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              style={{
                overflowY: "scroll",
                height: "90vh",
              }}
              className="personal-modal scrollBarHidden"
            >
              <h6>Personal Details</h6>

{/* .... */}
              <form onSubmit={HandleAddPersonalDetails}>
                <div className="grid mt-2">
                  <div className="col-6 ">
                    <label className="labels mb-1">Date Of Birth</label>
                    <Calendar
                      value={dateOfBirth}
                      onChange={handleDateChange}
                      id="insurancevaliditydate"
                      className="input1"
                      showIcon={false}
                      yearNavigator
                      yearRange="1500:2100"
                      inputValue={formatDateForDisplay(dateOfBirth)}
                      dateFormat="dd-MM-yy"
                      style={{
                        width: "88%",
                        height: "38px",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        border: "none",
                      }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="labels mb-1">Birth Time</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Time"
                      name="time"
                      onChange={handlePersonalDetails}
                      value={
                        PersonalDetailsForm ? PersonalDetailsForm.time : ""
                      }
                    />
                  </div>
                  <div className="col-12 md:col-6">
                    <label className="labels mb-1">Age</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Age"
                      name="age"
                      onChange={handlePersonalDetails}
                      value={age}
                    />
                  </div>
                  <div className="col-12 md:col-6">
                    <label className="labels mb-1">Gender:</label>
                    <div className="flex">
                      <button
                        type="button"
                        style={{
                          padding: "8px 12px",
                          fontSize: "13px",
                          fontWeight: 600,
                        }}
                        className={`btn btn-outline-primary  ${PersonalDetailsForm?.gender === "Male" && "active"
                          }`}
                        onClick={(e) => handlePersonalDetails(e, "Male")}
                      >
                        Male
                      </button>
                      <button
                        type="button"
                        style={{
                          padding: "8px 12px",
                          fontSize: "13px",
                          fontWeight: 600,
                        }}
                        className={`btn btn-outline-primary  mx-2
                             ${PersonalDetailsForm?.gender === "Female" &&
                          "active"
                          }`}
                        onClick={(e) => handlePersonalDetails(e, "Female")}
                      >
                        Female
                      </button>
                      <button
                        type="button"
                        style={{
                          padding: "8px 12px",
                          fontSize: "13px",
                          fontWeight: 600,
                        }}
                        className={`btn btn-outline-primary  ${PersonalDetailsForm?.gender === "Transgender" &&
                          "active"
                          }`}
                        onClick={(e) =>
                          handlePersonalDetails(e, "Transgender")
                        }
                      >
                        Transgender
                      </button>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <label className="labels mb-1">Marital Status</label>
                    <select
                      className="form-select"
                      id=""
                      name="material_status"
                      onChange={handlePersonalDetails}
                      value={
                        PersonalDetailsForm
                          ? PersonalDetailsForm.material_status
                          : ""
                      }
                    >
                      <option value="">Select Marital status</option>
                      {maritalStatus
                        .sort((a, b) => a.localeCompare(b))
                        .map((status, index) => (
                          <option key={index} value={status}>
                            {status}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="md:col-6 col-12 ">
                    <label className="mb-1 labels" htmlFor="">
                      Blood Group
                    </label>
                    <select
                      className="form-select"
                      id="nationality"
                      name="bloodgroup"
                      value={
                        PersonalDetailsForm
                          ? PersonalDetailsForm.bloodgroup
                          : ""
                      }
                      onChange={handlePersonalDetails}
                    >
                      <option value="">Select a BloodGroup</option>
                      {bloodgroup
                        .sort((a, b) => a.localeCompare(b))
                        .map((bloodgroup, index) => (
                          <option key={index} value={bloodgroup}>
                            {bloodgroup}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-md-6 col-12">
                    <label className="mb-1 labels" htmlFor="nationalitycheck">
                      Dual Nationality
                    </label>
                    <select
                      className="form-select"
                      id="nationalitycheck"
                      name="nationalitycheck"
                      value={
                        PersonalDetailsForm
                          ? PersonalDetailsForm?.nationalitycheck
                          : ""
                      }
                      onChange={handlePersonalDetails}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  {PersonalDetailsForm &&
                    PersonalDetailsForm?.nationalitycheck === "Yes" && (
                      <div className="col-md-6 col-12  ">
                        <label className="mb-1 labels" htmlFor="">
                          Nationality
                        </label>

                        <Select
                          //  value={PersonalDetailsForm ? PersonalDetailsForm.nationality : ""}
                          value={finalval.map((item) => ({
                            value: item,
                            label: item,
                          }))}
                          isMulti
                          options={nationalities.map(
                            (nationality, index) => ({
                              value: nationality,
                              label: nationality,
                            })
                          )}
                          onChange={(value) =>
                            handlenationality(value, "nationality")
                          }
                        />
                      </div>
                    )}

                  {PersonalDetailsForm &&
                    PersonalDetailsForm?.nationalitycheck === "No" && (
                      <div className="col-md-6 col-12  ">
                        <label className="mb-1 labels" htmlFor="">
                          Nationality
                        </label>
                        <select
                          className="form-select"
                          id="nationality"
                          name="nationality"
                          value={
                            PersonalDetailsForm
                              ? PersonalDetailsForm.nationality
                              : ""
                          }
                          onChange={handlePersonalDetails}
                        >
                          <option value="">Select a Nationality</option>

                          {nationalities
                            .sort((a, b) => a.localeCompare(b))
                            .map((nationality, index) => (
                              <option key={index} value={nationality}>
                                {nationality}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}

                  <div className="col-4">
                    <label className="labels mb-1">Disability</label>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          name="disability"
                          id="disabilityYes"
                          value="true"
                          onChange={handlePersonalDetails}
                          checked={
                            PersonalDetailsForm?.disability === "true" ||
                            PersonalDetailsForm?.disability === true
                          }
                        />
                        <label htmlFor="disabilityYes" className="ms-1">
                          Yes
                        </label>
                      </div>
                      <div className="d-flex align-items-center ms-3">
                        <input
                          type="radio"
                          name="disability"
                          id="disabilityNo"
                          value="false"
                          onChange={handlePersonalDetails}
                          checked={
                            PersonalDetailsForm?.disability === "false" ||
                            PersonalDetailsForm?.disability === false
                          }
                        />
                        <label htmlFor="disabilityNo" className="ms-1">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {PersonalDetailsForm?.disability === "true" ||
                    PersonalDetailsForm?.disability === true ? (
                    <div className="col-8">
                      <label className="labels mb-1">Disability Reason</label>
                      <select
                        id="disabilityReason"
                        name="disability_reason"
                        className="form-select mr-2"
                        value={
                          PersonalDetailsForm
                            ? PersonalDetailsForm.disability_reason
                            : ""
                        }
                        onChange={handlePersonalDetails}
                      >
                        <option value="">Select Disability Reason</option>
                        {allDisabilityReasons.map((reason, index) => (
                          <option key={index} value={reason}>
                            {reason}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}

                  <div className="col-12">
                    <label className="labels mb-1">Religion </label>
                    <select
                      id="religion"
                      name="religion"
                      className="form-select"
                      value={
                        PersonalDetailsForm
                          ? PersonalDetailsForm.religion
                          : ""
                      }
                      onChange={handlePersonalDetails}
                    >
                      <option value="">Select a religion</option>
                      {religions
                        .sort((a, b) => a.localeCompare(b))
                        .map((religion, index) => (
                          <option key={index} value={religion}>
                            {religion}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="labels mb-1">Community </label>
                    <select
                      id="community"
                      name="community"
                      className="form-select"
                      value={
                        PersonalDetailsForm
                          ? PersonalDetailsForm.community
                          : ""
                      }
                      onChange={handlePersonalDetails}
                    >
                      <option value="">Select a community</option>
                      {sortedCommunities.map((community, index) => (
                        <option key={index} value={community}>
                          {community}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="labels mb-1">Caste</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Caste"
                      name="caste"
                      onChange={handlePersonalDetails}
                      value={
                        PersonalDetailsForm ? PersonalDetailsForm.caste : ""
                      }
                    />
                  </div>
                  <div className="col-12">
                    <label className="labels mb-1">Inter-caste </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Inter-Caste"
                      name="Intercaste"
                      onChange={handlePersonalDetails}
                      value={
                        PersonalDetailsForm
                          ? PersonalDetailsForm.Intercaste
                          : ""
                      }
                    />
                  </div>
                </div>

                <h6 className="mt-2">Politicals</h6>
                <label htmlFor="partyName" className="labels mt-2">
                  Party Name
                </label>
                <select
                  id="partyname"
                  className="form-select mt-1"
                  onChange={handlePersonalDetails}
                  name="partyname"
                  value={
                    PersonalDetailsForm ? PersonalDetailsForm.partyname : ""
                  }
                >
                  <option value="">Select a party</option>
                  {nationalPartyNames
                    .sort((a, b) => a.localeCompare(b))
                    .map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                </select>

                <label htmlFor="membershipStatus" className="labels mt-2">
                  Membership Status
                </label>
                <select
                  id="membershipstatus"
                  className="form-select mt-1"
                  onChange={handlePersonalDetails}
                  name="membershipstatus"
                  value={
                    PersonalDetailsForm
                      ? PersonalDetailsForm.membershipstatus
                      : ""
                  }
                >
                  <option value="">Select a status</option>
                  <option value="Leader">Leader</option>
                  <option value="Follower">Follower</option>
                  <option value="Supporter">Supporter</option>
                  <option value="Member">Member</option>
                </select>

                {PersonalDetailsForm &&
                  (PersonalDetailsForm.membershipstatus === "Leader" ||
                    PersonalDetailsForm.membershipstatus === "Member") && (
                    <>
                      <label className="labels mb-1 mt-2">
                        Membership Id
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Membership ID"
                        name="membershipid"
                        onChange={handlePersonalDetails}
                        value={
                          PersonalDetailsForm
                            ? PersonalDetailsForm.membershipid
                            : ""
                        }
                      />

                      <label htmlFor="designation" className="labels mt-2">
                        Designation
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Designation"
                        name="designation"
                        onChange={handlePersonalDetails}
                        value={PersonalDetailsForm.designation || ""}
                      />
                      <label htmlFor="geographic" className="labels mt-2">
                        Geographic
                      </label>
                      <select
                        name="geographic"
                        className="form-select mt-1"
                        onChange={handlePersonalDetails}
                        value={PersonalDetailsForm.geographic || ""}
                      >
                        <option value="">Select a Geographic</option>
                        <option value="National">National</option>
                        <option value="State">State</option>
                        <option value="District">District</option>
                        <option value="Taluka">Taluka</option>
                        <option value="Village">Village</option>
                        <option value="Taluka">Street</option>
                      </select>
                    </>
                  )}

                <div className="text-center mt-2">
                  <button
                    className="btn btn-danger me-2"
                    onClick={handlePersonalClose}
                  >
                    Cancel
                  </button>
                  {personaldetails ? (
                    <button
                      type="submit"
                      value="update"
                      className="btn btn-primary"
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      value="add"
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </Box>
          </Modal>
        </p>
      </div>
      {personaldetails && authdata && (
        <div className="profile-card_pd">
          <div className="header_pd">
            <h5
              style={{
                color: "#2196F3",
                fontWeight: "bold",
                fontSize: "21px",
              }}
            >
              Personal Details
            </h5>
            <div className="button-container">
              <Button
                onClick={(e) => {
                  handlePersonalOpen();
                  addEditValues(e, "PersonalDetails");
                }}
              >
                {personaldetails ? (
                  <MdModeEdit size={22} />
                ) : (
                  <MdAdd size={22} />
                )}
              </Button>
            </div>
          </div>
          <div className="details_pd">
            <div className="row">
              <div className="pd_label">Name</div>
              <div className="pd_content">{authdata?.name?.charAt(0).toUpperCase() + authdata?.name?.slice(1)}</div>
            </div>
            <div className="row">
              <div className="pd_label">Date of Birth</div>
              <div className="pd_content">{personaldetails ? personaldetails.dateofbirth : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Birth Time</div>
              <div className="pd_content">{personaldetails ? personaldetails.time : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Age</div>
              <div className="pd_content">{personaldetails ? age : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Gender</div>
              <div className="pd_content">{personaldetails ? personaldetails.gender : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Marital Status</div>
              <div className="pd_content">{personaldetails ? personaldetails.material_status : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Blood Group</div>
              <div className="pd_content">{personaldetails ? personaldetails.bloodgroup : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Nationality</div>
              <div className="pd_content">{personaldetails ? personaldetails.nationality : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Disability</div>
              <div className="pd_content">{personaldetails ? (personaldetails.disability ? "Yes" : "No") : ""}</div>
            </div>
            {personaldetails && personaldetails.disability === true && (
              <div className="row">
                <div className="pd_label">Disability Reason</div>
                <div className="pd_content">{personaldetails.disability_reason}</div>
              </div>
            )}
            <div className="row">
              <div className="pd_label">Religion</div>
              <div className="pd_content">{personaldetails ? personaldetails.religion : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Caste</div>
              <div className="pd_content">{personaldetails ? personaldetails.caste : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">InterCaste</div>
              <div className="pd_content">{personaldetails ? personaldetails.Intercaste : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Community</div>
              <div className="pd_content">{personaldetails ? personaldetails.community : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Party Name</div>
              <div className="pd_content">{personaldetails ? personaldetails.partyname : ""}</div>
            </div>
            <div className="row">
              <div className="pd_label">Membership Status</div>
              <div className="pd_content">{personaldetails ? personaldetails.membershipstatus : ""}</div>
            </div>
            {personaldetails && personaldetails.membershipstatus === "Yes" && (
              <div className="row">
                <div className="pd_label">Party Code</div>
                <div className="pd_content">{personaldetails.partycode}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default PersonalDetailsForm1;