import React from "react";
import "./Message.css";
import { useContext,useState } from "react";
import { BackendApi } from "../../../../../config/serverUrl";
import { DataContext } from "../../../../../Admin/DataContext";
import { useSelector } from "react-redux";
import {
  TextMsg,
  Timeline,
  MediaMsg,
  VideoMsg,
  AudioMsg,
  PdfMsg,
  ReplyImg,
  LinkMsg,
  VideoCall,
  AudioCall,
  GifMsg,
  CreateGroupTimeline,
  MultipleMediaMsg
} from "./MessageType";
import ImageGrid from "./ImageGrid";


const Message = ({
  handleReply,
  setReplyBtn,
  ReplyData,
  setReplyData,
  chathistory,
  userName,
  setReplyNames,
  handleDelete,
  selectionMode,
  handleCheckboxChange
}) => {
 

 
  const groupMessagesByDate = (messages) => {
    if (!messages || !Array.isArray(messages)) {
      // Handle case where messages is undefined or not an array
      return [];
    }

    const groupedMessages = [];
    let currentDate = null;

    messages.forEach((el) => {
      if (!el || typeof el.date_added !== "string") {
        // Skip invalid message objects
        return;
      }

      const messageDate = new Date(el.date_added).toDateString();

      if (messageDate !== currentDate) {
        groupedMessages.push({ type: "divider", date: messageDate });
        currentDate = messageDate;
      }

      groupedMessages.push(el);
    });

    return groupedMessages;
  };

 

  const chatdata = useSelector((state) => state.chat.chathistory);
  const groupedChathistory = groupMessagesByDate(chatdata);
  
  return (
    <div className="message">
      {groupedChathistory.map((el,index) => {
        
        if (el.type === "noMessagesFound") {
          return (
            <div key={index} className="no-messages-found">
              No messages found
            </div>
          );
        }
        

        if (el.type === "divider") {
          return (
            <Timeline
              el={el}
              handleReply={handleReply}
              setReplyData={setReplyData}
              ReplyData={ReplyData}
              userName={userName}
              setReplyNames={setReplyNames}
              
            />
          );
        }

        switch (el.type) {
          case "divider":
            return (
              <Timeline
                el={el}
                handleReply={handleReply}
                setReplyData={setReplyData}
                ReplyData={ReplyData}
                userName={userName}
                setReplyNames={setReplyNames}
                
              />
            );

            case "msg":
              case "forward":
            switch (el.subtype) {
              case "creategroup":
                return (
                  <CreateGroupTimeline
                    el={el}
                  />
                );
              case "img":
                return (
                  <MediaMsg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    ReplyData={ReplyData}
                    userName={userName}
                    setReplyNames={setReplyNames}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                    
                  />
                );
                case "multiple_img":
                return (
                  <MultipleMediaMsg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    ReplyData={ReplyData}
                    userName={userName}
                    setReplyNames={setReplyNames}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                    
                  />
                );
              case "video":
                return (
                  <VideoMsg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );

              case "audio":
                return (
                  <AudioMsg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );

              case "doc":
                return (
                  <PdfMsg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
                case "reply":
                return (
                  <ReplyImg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
              case "img_reply":
                return (
                  <ReplyImg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
              case "doc_reply":
                return (
                  <ReplyImg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
                case "gif_reply":
                return (
                  <ReplyImg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
              case "audio_reply":
                return (
                  <ReplyImg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
              case "video_reply":
                return (
                  <ReplyImg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
                case "link":
                return (
                  <LinkMsg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
                case "callRequest":
                return (
                  <VideoCall
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
                case "AudiocallRequest":
                return (
                  <AudioCall
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
                case "gif":
                return (
                  <GifMsg
                    el={el}
                    handleReply={handleReply}
                    setReplyData={setReplyData}
                    setReplyNames={setReplyNames}
                    ReplyData={ReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
              default:
                return (
                  <TextMsg
                    el={el}
                    handleReply={handleReply}
                    setReplyBtn={setReplyBtn}
                    setReplyNames={setReplyNames}
                    setReplyData={setReplyData}
                    userName={userName}
                    handleDelete={handleDelete}
                    selectionMode={selectionMode}
                    handleCheckboxChange={handleCheckboxChange}
                    // data={data}
                  />
                );
            }

          default:
            return <></>;
        }
      })}

    
    </div>
  );
};

export default Message;
