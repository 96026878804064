import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdOutlineDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Dialog } from "primereact/dialog";
import { IoMdAdd } from "react-icons/io";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import "./companyprofile.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useRef } from "react";
import { Nodeapi } from "../config/serverUrl";
import axios from "axios";
import moment from "moment/moment";
import { Menu } from "primereact/menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import { department, designation } from "./Data/employeeAssetData";

const EmployeeAsset = () => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = React.useState(null);
  const [team_data, setteam_data] = useState({
    KidId: "",
    Name: "",
    Lastname: "",
    Designation: "",
    Department: "",
    YearofJoining: "",
    userid: "",
    email: "",
    mobile_no: "",
    work_email: "",
    work_mobile_no: "",
    other_designation: "",
    other_department: "",
  });
  const [displaydata, setdisplaydata] = useState([]);
  const [Update, setUpdate] = useState(false);
  const [datevalue, setdatevalue] = useState("");
  const [kidId, setKidId] = useState("");
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");

  const authdata = useSelector((state) => state.auth.user);

  const val = authdata.id;

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [errors, setErrors] = useState({
    email: "",
    work_email: "",
    mobile_no: "",
    work_mobile_no: "",
  });

  const validatePhoneNumber = (value) => {
    const cleanValue = value
      .replace(/^\+91/, "")
      .replace(/^91/, "")
      .replace(/\D/g, "");

    return cleanValue.length === 10;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email" || name === "work_email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid email",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    setteam_data((prevData) => ({
      ...prevData,
      [name]: value,
      userid: authdata?.id,
    }));
  };

  const handleDepartmentDropdownChange = (e) => {
    const value = e.target.value;
    setteam_data((prevData) => ({
      ...prevData,
      Department: value,
      other_department: value === "Others" ? prevData.other_department : "",
    }));
  };
  const handleOtherDepartmentChange = (e) => {
    const value = e.target.value;
    setteam_data((prevData) => ({
      ...prevData,
      other_department: value,
    }));
  };

  const handleDesignationDropdownChange = (e) => {
    const value = e.target.value;
    setteam_data((prevData) => ({
      ...prevData,
      Designation: value,
      other_designation: value === "Others" ? prevData.other_designation : "",
    }));
  };
  const handleOtherDesignationChange = (e) => {
    const value = e.target.value;
    setteam_data((prevData) => ({
      ...prevData,
      other_designation: value,
    }));
  };

  useEffect(() => {
    fetchteamdata();
  }, []);

  const fetchteamdata = async () => {
    try {
      const res = await axios.get(`${Nodeapi}/fetchteamdata?id=${val}`);

      const data = res.data.data.fetchdata;

      setdisplaydata(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKidChange = async (event) => {
    const { value } = event.target;
    setKidId(value);

    try {
      const res = await axios.get(
        `${Nodeapi}/Getfirstandlastname?kidId=${value}`
      );

      const { name, fathername } = res.data.fetchpersonal[0];
      setName(name);
      setFatherName(fathername);

      setteam_data((prevData) => ({
        ...prevData,
        Name: name,
        Lastname: fathername,
        kidId: value,
      }));
    } catch (error) {
      console.error("Error fetching name and father name:", error);
    }
  };

  const handleopen = () => {
    setVisible(true);
    setteam_data({
      Name: "",
      Lastname: "",
      Designation: "",
      Department: "",
      YearofJoining: "",
      userid: "",
      other_designation: "",
      other_department: "",
    });
    setKidId("");
    setName("");
    setFatherName("");
    setUpdate(false);
    setdatevalue("");
  };

  const handleMobileNoChange = (value, field) => {
    if (field === "mobile_no" || field === "work_mobile_no") {
      if (!validatePhoneNumber(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "Invalid mobile number",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    }

    setteam_data((prevData) => ({
      ...prevData,
      [field]: value,
      userid: authdata?.id,
    }));
  };
  const handleDateChange = (event) => {
    const newDob = event.value;
    const formattedDate = moment(newDob).format("DD-MM-YYYY");

    setteam_data({
      ...team_data,
      YearofJoining: formattedDate,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...team_data,
      id: team_data.id,
      userid: team_data.userid,
    };

    try {
      let response;
      if (Update) {
        response = await axios.post(`${Nodeapi}/Updateteamdata`, payload);
      } else {
        response = await axios.post(`${Nodeapi}/Management`, {
          insertdata: payload,
        });
      }

      if (response) {
        setVisible(false);
        const newData = Update
          ? response.data.data.response
          : [...displaydata, payload];

        setdisplaydata(newData);

        Swal.fire({
          position: "center",
          icon: "success",
          title: Update ? "Updated Successfully" : "Inserted Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Submission Error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelected(rowData);
      menuLeft.current.toggle(event);
    };

    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  const menuLeft = useRef(null);

  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selected),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selected),
    },
  ];

  const handleEdit = (rowData) => {
    setVisible(true);
    setUpdate(true);
    const value1 = rowData.YearofJoining;
    let startYear = moment(value1, "DD-MM-YYYY").toDate();
    setdatevalue(startYear);

    setteam_data({
      ...rowData,
      Name: rowData.Name,
      Lastname: rowData.Lastname,
      Designation: rowData.Designation,
      Department: rowData.Department,
      YearofJoining: rowData.YearofJoining,
      other_designation: rowData.other_designation || "",
      other_department: rowData.other_department || "",
    });

    setKidId(rowData.KidId);
  };

  const deleteContactData = async (id) => {
    try {
      await axios.delete(`${Nodeapi}/deleteteamData/${id}`);
      fetchteamdata();
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
  };

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData.id);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  const getname = (rowData) => {
    const { Name, Lastname } = rowData;
    return `${Name} ${Lastname}`;
  };

  const getDesignation = (rowData) => {
    const designation =
      rowData.Designation === "Others"
        ? rowData.other_designation
        : rowData.Designation;

    return designation;
  };

  const getDepartment = (rowData) => {
    const department =
      rowData.Department === "Others"
        ? rowData.other_department
        : rowData.Department;

    return department;
  };

  return (
    <div>
      <div className="asset-table align-items-center mb-3 flex justify-content-end">
        <button
          className="btn btn-add"
          style={{ marginRight: "2px" }}
          onClick={handleopen}
        >
          <IoMdAdd className="mb-1" />
        </button>
      </div>

      <Dialog
        header="Management"
        visible={visible}
        style={{ width: "42vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setVisible(false)}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="vehicleNumber">
              KID Number
            </label>
            <input
              className="form-control"
              type="text"
              name="Name"
              value={kidId}
              onChange={handleKidChange}
              readOnly={Update}
              disabled={Update} 
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="vehicleNumber">
              First Name
            </label>
            <input
              className="form-control"
              type="text"
              name="Name"
              value={team_data?.Name}
              onChange={(e) => setName(e.target.value)}
              readOnly
              disabled
            />
          </div>

          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="vehicleNumber">
              Last Name
            </label>
            <input
              className="form-control"
              type="text"
              name="Lastname"
              value={team_data?.Lastname}
              onChange={(e) => setFatherName(e.target.value)}
              readOnly
              disabled
            />
          </div>

          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="vehicleNumber">
              Department
            </label>
            <Dropdown
              className="w-full"
              id="Department"
              name="Department"
              value={team_data.Department}
              options={[...department, { label: "Others", value: "Others" }]}
              onChange={(e) => handleDepartmentDropdownChange(e)}
              placeholder="Select Department"
              optionLabel="label"
              optionValue="value"
              filter
              showClear
            />

            {team_data.Department === "Others" && (
              <div className="mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter new department"
                  value={team_data.other_department}
                  onChange={(e) => handleOtherDepartmentChange(e)}
                />
              </div>
            )}
          </div>

          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="vehicleNumber">
              Designation
            </label>
            <Dropdown
              className="w-full"
              id="Designation"
              name="Designation"
              value={team_data.Designation}
              options={[...designation, { label: "Others", value: "Others" }]}
              onChange={(e) => handleDesignationDropdownChange(e)}
              placeholder="Select Designation"
              optionLabel="label"
              optionValue="value"
              filter
              showClear
            />

            {team_data.Designation === "Others" && (
              <div className="mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter new designation"
                  value={team_data.other_designation}
                  onChange={(e) => handleOtherDesignationChange(e)}
                />
              </div>
            )}
          </div>

          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="email">
              Email Address
            </label>
            <input
              className="form-control"
              type="email"
              name="email"
              value={team_data.email}
              onChange={handleChange}
            />
            {errors.email && <small className="p-error">{errors.email}</small>}
          </div>

          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="mobileNumber">
              Mobile Number
            </label>
            <PhoneInput
              country={"in"}
              value={team_data.mobile_no}
              onChange={(value) => handleMobileNoChange(value, "mobile_no")}
              className="border-example "
            />
            {errors.mobile_no && (
              <small className="p-error">{errors.mobile_no}</small>
            )}
          </div>
          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="work_email">
              Work Email Address
            </label>
            <input
              className="form-control"
              type="email"
              name="work_email"
              value={team_data.work_email}
              onChange={handleChange}
            />
            {errors.work_email && (
              <small className="p-error">{errors.work_email}</small>
            )}
          </div>

          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="workMobileNumber">
              Work Mobile Number
            </label>
            <PhoneInput
              country={"in"}
              value={team_data.work_mobile_no}
              onChange={(value) =>
                handleMobileNoChange(value, "work_mobile_no")
              }
              className="border-example "
            />
            {errors.work_mobile_no && (
              <small className="p-error">{errors.work_mobile_no}</small>
            )}
          </div>
          <div className="col-md-6 col-sm-12 my-2">
            <label className="mb-1" htmlFor="vehicleNumber">
              Date of Joining
            </label>
            <Calendar
              id="insurancevaliditydate"
              showIcon={false}
              name="YearofJoining"
              monthNavigator
              yearNavigator
              yearRange="1500:2100"
              dateFormat="dd-mm-yy"
              inputId="in"
              value={datevalue}
              onChange={handleDateChange}
              className="cal-input w-full"
              style={{ width: "100%", height: "37px" }}
            />
          </div>
        </div>
        <div className="text-center mt-4">
          <button
            className="btn btn-danger me-2"
            style={{ marginLeft: "21px" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleSubmit}>
            {Update ? "Update" : "Submit"}
          </button>
        </div>
      </Dialog>

      <DataTable value={displaydata} tableStyle={{ minWidth: "50rem" }}>
        <Column
          header="S.No"
          body={(rowData) => <span>{displaydata.indexOf(rowData) + 1}</span>}
        ></Column>
        <Column field="Name" header="Name" body={getname}></Column>

        <Column
          field="Designation"
          header="Designation"
          body={getDesignation}
          style={{ width: "200px" }}
        />
        <Column
          field="Department"
          header=" Department"
          body={getDepartment}
        ></Column>
        <Column field="YearofJoining" header=" Date of Joining"></Column>
        <Column body={actions} header="Actions"></Column>
      </DataTable>
    </div>
  );
};

export default EmployeeAsset;
