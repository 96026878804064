import { MdAdd } from "react-icons/md";
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "@mui/material";
import { Menu } from "primereact/menu";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Nodeapi, Assetsapi } from "../config/serverUrl";
import { MdDriveFolderUpload, MdRemoveCircleOutline } from "react-icons/md";
import axios from "axios";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { IoMdAdd } from "react-icons/io";
import { Calendar } from "primereact/calendar";
import moment from "moment/moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "120px",
  backgroundColor: "#fff",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
};

const AdvertisingDashboard = () => {
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;
  const [postDetails, setPostDetails] = useState([]);
  const [data, setData] = useState({
    productname: "",
    category: "",
    product_duration: "",
    description: "",
    warrantyperiod: "",
  });
  const [selectedPostDetail, setSelectedPostDetail] = useState({});
  const [additionalImages, setAdditionalImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [cost, setCost] = useState("");
  const [listimages, setlistimages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [errors, setErrors] = useState({
    productname: "",
    product_duration: "",
    description: "",
    cost: "",
    warrantyperiod: "",
  });
  const handleDateChange = (event) => {
    const newDob = event.value;
    const formattedDate = moment(newDob).format("DD-MM-YYYY");

    setData({
      ...data,
      warrantyperiod: formattedDate,
    });
  };
  const [isEditMode, setIsEditMode] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  console.log(uploadedImages, "uploadedImages");
  const menuLeft = useRef(null);
  const handleOpen = () => setOpen(true);

  const clearForm = () => {
    setData({
      productname: "",
      product_duration: "",
      description: "",
      warrantyperiod: "",
    });
    setCost("");
    setUploadedImages([]);
    setlistimages([]);
    setErrors({
      productname: "",
      product_duration: "",
      description: "",
      cost: "",
      warrantyperiod: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditMode(false);
    setSelectedPostDetail(null);
    clearForm();
    setDateValue("")
  };

  const handleMenuClick = (rowData) => {
    setSelectedPostDetail(rowData);
    const filenamesArray = rowData.productfiledetails.map(
      (file) => file.filename
    );

    setlistimages(filenamesArray);
    setIsEditMode(true);
    setData({
      productname: rowData.productname,
      product_duration: rowData.product_duration,
      description: rowData.description,
    });
    const value1 = rowData.warrantyperiod;
    let startYear = moment(value1, "DD-MM-YYYY").toDate();
    setDateValue(startYear);
    setCost(rowData.cost);
    if (rowData.productfiledetails && rowData.productfiledetails.length > 0) {
      setUploadedImages(
        rowData.productfiledetails.map((file) => ({
          filePath: `${Assetsapi}/adimages/${file.filename}`,
          documentName: file.filename,
        }))
      );
    } else {
      setUploadedImages([]);
      setAdditionalImages([]);
    }
    setOpen(true);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${Nodeapi}/fetchproducts?id=${userId}`);
      if (response.data.code === "200" && response.data.status === "success") {
        const fetchdata = response.data.data.fetchdata;
        const mergedPostDetails = fetchdata.reduce((acc, current) => {
          const {
            id,
            productname,
            warrantyperiod,
            cost,
            product_duration,
            description,
            userid,
            file_id,
            productfiledetails,
          } = current;
          const existingIndex = acc.findIndex(
            (item) =>
              item.id === id &&
              item.productname === productname &&
              item.warrantyperiod === warrantyperiod &&
              item.cost === cost &&
              item.product_duration === product_duration &&
              item.description === description &&
              item.userid === userid &&
              item.file_id === file_id
          );
          if (existingIndex !== -1) {
            acc[existingIndex].productfiledetails.push(...productfiledetails);
          } else {
            acc.push({
              id,
              productname,
              warrantyperiod,
              cost,
              product_duration,
              description,
              userid,
              file_id,
              productfiledetails: [...productfiledetails],
            });
          }
          return acc;
        }, []);
        setPostDetails(mergedPostDetails);
      }
    } catch (error) {
      console.error("Failed to fetch product details:", error);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelectedPostDetail(rowData);
      menuLeft.current.toggle(event);
    };
    return (
      <div>
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <HiOutlineDotsVertical />
        </Button>
      </div>
    );
  };

  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleMenuClick(selectedPostDetail),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selectedPostDetail),
    },
  ];

  const deleteContactData = async (rowData) => {
    try {
      await axios.delete(`${Nodeapi}/deleteproducts/${rowData.id}`);
      fetchData(rowData.userid);
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
  };

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      productname: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData);
        Swal.fire({
          productname: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        fetchData(rowData.userid);
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const newErrors = {};
    if (!data.productname) newErrors.productname = "product name is required";
    if (!data.product_duration)
      newErrors.product_duration = "product duration is required";
    if (!data.description) newErrors.description = "Description is required";
    if (!cost) newErrors.cost = "cost is required";
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setIsSubmitting(false);
    };

    const allImages = [...uploadedImages, ...additionalImages];
    const totalFileSize = allImages.reduce((acc, image) => acc + image.size, 0);
    const maxFileSize = 8 * 1024 * 1024;
    const maxFileCount = 10;

    if (allImages.length > maxFileCount) {
      Swal.fire({
        position: "center",
        icon: "error",
        productname: "Too many files",
        text: `You can upload a maximum of ${maxFileCount} files.`,
      });
      return;
    }

    if (totalFileSize > maxFileSize) {
      Swal.fire({
        position: "center",
        icon: "error",
        productname: "File size limit exceeded",
        text: `Total file size must be less than ${
          maxFileSize / (1024 * 1024)
        } MB.`,
      });
      return;
    }

    const formData = new FormData();
    [...uploadedImages, ...additionalImages].forEach((image) =>
      formData.append("images", image.filePath)
    );
    formData.append("productname", data.productname);
    formData.append("description", data.description);
    formData.append("product_duration", data.product_duration);
    formData.append("warrantyperiod", data.warrantyperiod);
    formData.append("cost", cost);
    formData.append("userid", userId);
    if (isEditMode && selectedPostDetail && selectedPostDetail.id) {
      formData.append("product_id", selectedPostDetail.id);
    }
    const timeout = setTimeout(() => {
      Swal.fire({
        position: "center",
        icon: "error",
        productname: "Request Timeout",
        text: "The server is taking too long to respond. Please try again later.",
      });
      setIsSubmitting(false);
    }, 10000);

    try {
      if (isEditMode) {
        await axios.put(
          `${Nodeapi}/products/update/${selectedPostDetail.file_id}`,
          formData
        );
        clearTimeout(timeout);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Post updated Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        await axios.post(`${Nodeapi}/Product`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        clearTimeout(timeout);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Post Added Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsSubmitting(false);
      }
      fetchData();
      handleClose();
    } catch (error) {
      clearTimeout(timeout);
      console.error("Failed to add/update post:", error);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Failed to add/update post",
        text: error.message || "Something went wrong",
      });
      setIsSubmitting(false);
      fetchData();
    }
  };

  const handleUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      filePath: file,
      documentName: file.name,
    }));
    setUploadedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleRemove = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleAddMoreImage = (event) => {
    const files = Array.from(event.target.files);
    const newupdateImages = files.map((file) => ({
      filePath: file,
      documentName: file.name,
    }));
    setAdditionalImages([...additionalImages, ...newupdateImages]);
  };

  const handleuploadRemove = async (index, fileName) => {
    try {
      const result = await Swal.fire({
        productname: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        setlistimages((prevImages) => prevImages.filter((_, i) => i !== index));

        await axios.post(`${Nodeapi}/product/removeimage`, {
          filename: fileName,
        });
      }
    } catch (error) {
      console.error("Error deleting image from backend:", error);
    }
  };

  const imageUploadSection = (
    <>
      <div className="col-12 mt-md-0">
        <div className="d-flex flex-wrap">
          {uploadedImages.map((image, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <img
                src={
                  image.filePath instanceof File
                    ? URL.createObjectURL(image.filePath)
                    : image.filePath
                }
                alt={image.documentName}
                style={{
                  width: "126px",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemove(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  minWidth: 0,
                  padding: "5px",
                }}
              >
                <MdRemoveCircleOutline />
              </Button>
            </div>
          ))}
          <label
            htmlFor="upload-photo"
            className="upload-card p-0"
            style={{
              border: "1px dashed #68bcff",
              borderRadius: "10px",
              background: "#eff5fc",
              width: "7rem",
              height: "6rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "-7px",
              cursor: "pointer",
            }}
          >
            <input
              hidden
              multiple
              type="file"
              id="upload-photo"
              onChange={handleUpload}
            />
            <div
              className="icon"
              style={{
                fontSize: "1.5rem",
                marginRight: "0.5rem",
                color: "#2196f3",
              }}
            >
              <MdDriveFolderUpload />
            </div>
            <div className="overlay">
              <p style={{ color: "#2196f3" }}>Add</p>
            </div>
          </label>
        </div>
      </div>
    </>
  );

  const addmorimage = (
    <>
      <Button component="label" sx={{ height: 60 }}>
        <MdDriveFolderUpload
          style={{
            fontSize: "10px",
            marginRight: "0.5rem",
            border: "1px dashed #68bcff",
            borderRadius: "10px",
            background: "#eff5fc",
            width: "4rem",
            height: "3rem",
            marginLeft: "-479px",
            cursor: "pointer",
          }}
        />

        <input
          hidden
          multiple
          type="file"
          onChange={handleAddMoreImage}
        />
      </Button>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        {additionalImages.length &&
          additionalImages.map((image, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <img
                // key={index}
                // src={image.filePath}
                // alt={`Uploaded image ${index}`}
                src={
                  image.filePath instanceof File
                    ? URL.createObjectURL(image.filePath)
                    : image.filePath
                }
                alt={image.documentName}
                style={{
                  width: "126px",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemove(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  minWidth: 0,
                  padding: "5px",
                }}
              >
                <MdRemoveCircleOutline />
              </Button>
            </div>
          ))}
      </div>
    </>
  );

  return (
    <div>
      <div className="asset-table align-items-center mb-3 flex justify-content-end">
        <button
          className="btn btn-add"
          style={{ marginRight: "2px" }}
          onClick={handleOpen}
        >
          <IoMdAdd className="mb-1" />
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-productname"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h6 className="mb-2">
            {isEditMode
              ? "Edit Products & Services"
              : "Post Products & Services"}
            <Tooltip
              productname="Only 10 images with JPG format and an overall size of 10 MB are allowed."
              placement="top"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </h6>
          <div
            className="row"
            style={{
              height: "32rem",
              overflowY: "scroll",
            }}
          >
            <div className=" col-md-6 ">
              <label className="form-label labels mt-2" htmlFor="idNumber">
                Product Name
              </label>
              <input
                type="text"
                id="productname"
                className="form-control"
                name="productname"
                value={data.productname}
                onChange={(e) =>
                  setData({ ...data, productname: e.target.value })
                }
                style={{ padding: "7px" }}
              />
              {errors.productname && (
                <p className="text-danger">{errors.productname}</p>
              )}
            </div>
            <div className="col-md-6 ">
              <label className="form-label labels mt-2" htmlFor="idNumber">
                Warranty Period
              </label>
              <Calendar
                id="insurancevaliditydate"
                className=" input1 w-full"
                showIcon={false}
                name="warrantyperiod"
                yearNavigator
                yearRange="1500:2100"
                dateFormat="dd-mm-yy"
                inputId="in"
                value={dateValue}
                onChange={handleDateChange}
                style={{
                  height: "38px",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  border: "none",
                }}
              />
            </div>

            <div className="col-md-6 ">
              <label htmlFor="currency" className="labels mt-2 mb-1">
                Cost
              </label>
              <div className="flex mt-2">
                <InputNumber
                  inputId="currency-india"
                  name="vehiclecost"
                  className="w-full "
                  style={{ height: "40px" }}
                  value={cost}
                  onValueChange={(e) => {
                    setCost(e.target.value);
                  }}
                  currency="INR"
                  currencyDisplay="code"
                  locale="en-IN"
                />
              </div>
              {errors.cost && <p className="text-danger">{errors.cost}</p>}
            </div>
            <div className="col-md-6 ">
              <label className="form-label labels mt-2" htmlFor="duration">
                Delivery Duration
              </label>
              <input
                type="text"
                id="product_duration"
                className="form-control"
                name="product_duration"
                value={data.product_duration}
                onChange={(e) =>
                  setData({ ...data, product_duration: e.target.value })
                }
                style={{ padding: "7px" }}
              />
              {errors.product_duration && (
                <p className="text-danger">{errors.product_duration}</p>
              )}
            </div>
            <div className="col-md-12 ">
              <label className="form-label labels mt-2" htmlFor="issueDate">
                Description
              </label>
              <InputTextarea
                type="text"
                id="description"
                className="form-control w-full"
                name="Description"
                value={data.description}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                style ={{resize:"none"}}
              />
              {errors.description && (
                <p className="text-danger">{errors.description}</p>
              )}
            </div>
            <div className=" ">
              <label className="form-label labels mt-2" htmlFor="issueDate">
                Upload Images
              </label>
            </div>
            {!isEditMode && imageUploadSection}
            {isEditMode && addmorimage}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {[...listimages, ...additionalImages].map((imageObj, index) => (
                <div
                  key={index}
                  className="upload-card m-2 px-2"
                  style={{ position: "relative" }}
                >
                  <img
                    src={`${Assetsapi}/adimages/${imageObj}`}
                    alt={`Uploaded ${index + 1}`}
                    style={{
                      width: "7rem",
                      height: "7rem",
                      borderRadius: "10px",
                    }}
                  />
                  <MdRemoveCircleOutline
                    onClick={() => handleuploadRemove(index, listimages[index])}
                    style={{
                      color: "red",
                      position: "absolute",
                      top: "5px",
                      right: "14px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      fontSize: "1.3rem",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-2">
            <button className="btn btn-danger me-2" onClick={handleClose}>
              Cancel
            </button>
            {isEditMode ? (
              <button
                type="submit"
                value="update"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                value="add"
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            )}
          </div>
        </Box>
      </Modal>
      <DataTable value={postDetails} tableStyle={{ minWidth: "50rem" }}>
        <Column
          header="S.No"
          body={(rowData) => <span>{postDetails.indexOf(rowData) + 1}</span>}
        ></Column>
        <Column field="productname" header="Product"></Column>
        <Column field="warrantyperiod" header="Warranty Period"></Column>
        <Column field="cost" header="Cost" style={{ width: "200px" }}></Column>
        <Column field="product_duration" header="Product Duration"></Column>
        <Column field="description" header="Description"></Column>
        <Column body={actions} header="Actions"></Column>
      </DataTable>
    </div>
  );
};

export default AdvertisingDashboard;
