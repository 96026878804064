import React, { useState, useEffect, useContext, useRef } from "react";
import { ImageList, ImageListItem, IconButton } from "@mui/material";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { DataContext } from "../../../Admin/DataContext";
import { Assetsapi } from "../../../config/serverUrl";
import "./VehicleGallery.scss";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const VehicleGallery = ({ datavalue }) => {
  const { view_adpost, home_listimages } = useContext(DataContext);

  const [images, setImages] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const imageListRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true); 
  };

  const handleMouseLeave = () => {
    setIsPaused(false); 
  };

  useEffect(() => {
    fetchImages();
  }, [datavalue]);

  const fetchImages = async () => {
    try {
      const imageList = datavalue[0].images.map((src) => ({
        src: `${Assetsapi}/adimages/${src}`,
        alt: `Image ${src}`,
      }));
      setImages(imageList);
      if (imageList.length > 0) {
        setActiveStep(0); 
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step); 
  };

  const scrollLeft = () => {
    if (imageListRef.current) {
      imageListRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (imageListRef.current) {
      imageListRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  const shouldCenter = images.length <= 5;
  return (
    <div
      className="gallery-demo"
      style={{
        margin: "0 30px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        className="gallery-demo-card"
        style={{
          height: "410px",
          width: "100%",
          maxWidth: "417px",
          borderRadius: "5px",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <AutoPlaySwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          interval={3000}
          enableMouseEvents
          autoplay={!isPaused}
        >
          {images.map((image, index) => (
            <div key={index}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img
                  src={image.src}
                  alt={image.alt}
                  style={{
                    height: 410,
                    display: "block",
                    maxWidth: "417px",
                    width: "100%",
                    borderRadius: 2,
                    objectFit: "contain",
                    margin: "0 auto",
                    // cursor:"pointer"
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>

      {/* Thumbnail Image List */}
      <div
        className="image-list-container mt-4"
        style={{ position: "relative" }}
      >
        <IconButton
          onClick={scrollLeft}
          style={{
            position: "absolute",
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            zIndex: 1,
          }}
        >
          <ArrowBackIos style={{ marginLeft: "3px" }} />
        </IconButton>

        <ImageList
          ref={imageListRef}
          cols={8}
          gap={20}
          style={{
            height: "110px",
            padding: "0 10px 10px",
            display: "flex",
            justifyContent: shouldCenter ? "center" : "flex-start",
            overflowX: "hidden",
            overflowY: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {images.map((item, index) => (
            <ImageListItem
              key={item.src}
              onClick={() => setActiveStep(index)} 
              onMouseEnter={() => setActiveStep(index)}
            >
              <img
                src={item.src}
                alt={item.alt}
                style={{
                  width: "132px",
                  height: "100px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>

        <IconButton
          onClick={scrollRight}
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            zIndex: 1,
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </div>
    </div>
  );
};

export default VehicleGallery;
