import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import { useParams } from "react-router";

const Container = styled.div`
    padding: 20px;
    display: flex;
    height: 100vh;
    width: 90%;
    margin: auto;
    flex-wrap: wrap;
`;

const StyledVideo = styled.video`
    height: 40%;
    width: 50%;
`;

const Video = ({ peer }) => {
    const ref = useRef();

    useEffect(() => {
        if (!peer) {
            console.error("Peer object is not provided");
            return;
        }

        console.log("Setting up peer:", peer);

        const handleStream = (stream) => {
            console.log('Received stream from peer:', stream);
            if (ref.current) {
                ref.current.srcObject = stream;
            } else {
                console.error("Video ref is not set");
            }
        };

        peer.on("stream", handleStream);

        return () => {
            console.log("Cleaning up peer and removing event listeners");
            peer.off("stream", handleStream);
            peer.destroy();
        };
    }, [peer]);

    return (
        <video
            ref={ref}
            autoPlay
            playsInline
        />
    );
};

const videoConstraints = {
    height: window.innerHeight / 2,
    width: window.innerWidth / 2
};

const Room = () => {
    const [peers, setPeers] = useState([]);
    const socketRef = useRef();
    const userVideo = useRef();
    const peersRef = useRef([]);
    const { roomID } = useParams();

    useEffect(() => {
        const initialize = async () => {
            socketRef.current = io.connect("http://localhost:8001/groupvideo");

            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: videoConstraints, audio: true });
                userVideo.current.srcObject = stream;
                socketRef.current.emit("join room", roomID);

                socketRef.current.on("all users", users => {
                    const peers = [];
                    users.forEach(userID => {
                        const peer = createPeer(userID, socketRef.current.id, stream);
                        peersRef.current.push({
                            peerID: userID,
                            peer,
                        });
                        peers.push(peer);
                    });
                    setPeers(peers);
                });

                socketRef.current.on("user joined", payload => {
                    const peer = addPeer(payload.signal, payload.callerID, stream);
                    peersRef.current.push({
                        peerID: payload.callerID,
                        peer,
                    });
                    setPeers(users => [...users, peer]);
                });

                socketRef.current.on("receiving returned signal", payload => {
                    const item = peersRef.current.find(p => p.peerID === payload.id);
                    if (item) {
                        item.peer.signal(payload.signal);
                    }
                });

            } catch (error) {
                console.error("Error accessing media devices.", error);
            }
        };

        initialize();
    }, [roomID]);

    const createPeer = (userToSignal, callerID, stream) => {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
        });

        peer.on("signal", signal => {
            console.log("Sending signal:", signal);
            socketRef.current.emit("sending signal", { userToSignal, callerID, signal });
        });

        return peer;
    };

    const addPeer = (incomingSignal, callerID, stream) => {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
        });

        peer.on("signal", signal => {
            console.log("Returning signal:", signal);
            socketRef.current.emit("returning signal", { signal, callerID });
        });

        peer.signal(incomingSignal);

        return peer;
    };

    return (
        <Container>
            <StyledVideo muted ref={userVideo} autoPlay playsInline />
            {peers.map((peer, index) => (
                <Video key={index} peer={peer} />
            ))}
        </Container>
    );
};

export default Room;
