import React, { useState } from 'react'
import './Billing.css'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
function Billing() {
    const [products, setProducts] = useState([]);
    const columns = [
        { field: 'transactionId', header: 'Transaction ID', },
        { field: 'dateOfPurchase', header: 'Date' },
        { field: 'amount', header: 'Amount' },
        { field: 'numberOfAdsPurchased', header: 'Number of Ads' },
        { field: 'paymentMethod', header: 'Payment Method' },
        { field: 'transactionStatus', header: 'Transaction Status' },
    ];

    const staticData = [
        {
          transactionId: '12345',
          dateOfPurchase: '2023-07-24',
          amount: '₹100',
          numberOfAdsPurchased: '1',
          paymentMethod: 'Credit Card',
          transactionStatus: 'Completed',
        },
        // You can add more static entries if needed
      ];

    return (
        <>
         
            <div className='billing_card'>
            <h5 style={{ color: "#2196f3" }}>Billing Information</h5>
                <div className="ad-Panel mt-3">
                    <div className="row">
                        <div className="col-3">
                            <div className="ad-panel-card">
                                <div className="top" style={{ padding: "20px" }}>

                                    <p style={{ color: "#B5B5B5",fontSize:"20px"  }}>Total Ads Purchased</p>

                                    <p className='mt-2' style={{ color: "#2196f3", marginLeft: "12%", fontSize: "24px" }}>25</p>

                                    <p className='mt-2' style={{ color: "#B5B5B5" }}>Remaining Ads: 25</p>

                                </div>
                               
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="ad-panel-card">
                                <div className="top" style={{ padding: "17px" }}>

                                    <p style={{ color: "#B5B5B5",fontSize:"20px" }}>Amount of Purchase</p>
                                    <p className='mt-2' style={{ color: "#2196f3", marginLeft: "12%", fontSize: "24px" }}>₹200.00</p>
                                    <p className='mt-1' style={{ fontSize: "12px", color: "#B5B5B5" }} >You paid</p>

                                    <p className='mt-0' style={{ color: "#B5B5B5" }}>  on March 2,2022</p>
                                </div>
                              
                            </div>
                        </div>
                      
                      
                    </div>
                </div>
                <h5 className='mt-3' style={{ color: "#2196f3" }}>Payment history and Invoicing</h5>
                <div className='mt-3'>
                <DataTable  value={staticData} tableStyle={{ minWidth: '50rem' }}>
                {columns.map((col, i) => (
                    <Column key={col.field} field={col.field} header={col.header} />
                ))}
            </DataTable>
                </div>
                

                {/* <div style={{ padding: "11px",marginLeft:"76%" }}>
                <h6>Order Summary</h6>
                <div style={{ display: "flex" }}>
                    <p className='mt-2'>Total Ads:</p>
                    <p style={{marginLeft:"8%",marginTop:"8px"}}>2</p>
                </div>
               <div style={{ display: "flex" }}>
               <p className='mt-2'>Sub Total:</p>
               <p style={{marginLeft:"8%",marginTop:"8px"}}>200</p>

               </div>
                <div style={{ display: "flex" }}>
                <p className='mt-2'>Tax:</p>
                <p style={{marginLeft:"135px",marginTop:"8px"}}>20</p>
                </div>
               
                <div style={{ borderTop: '1px solid #dee2e6', width: "250px" }}></div>
                <div style={{ display: "flex" }}>
                <p className='mt-2'>Total:</p>
                <p style={{marginLeft:"10%",marginTop:"8px"}}>220</p>
                </div>
               
            </div> */}


            </div>
        </>
    )
}

export default Billing