import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Component/Main";
import Job from "./layouts/Job";
import Vehicle from "./layouts/Vehicle";
import Login from "./auth/Login";
import Demo from "./layouts/demo";
import Register from "./auth/Register";
import MyForm from "./utils/form";
import { useSelector, useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import DynamicInputFields from "./utils/appenform";
import PropertyDetailFormPage from "./Component/Profile/PropertyDetailForm";
import About from "./layouts/About";
import ContactUs from "./layouts/ContactUs";
import TermsPolicy from "./layouts/TermsPolicy";
import Maintainence from "./layouts/Maintainence";
import ResetPassword from "../src/auth/ResetPassword";
import ResetPasswordChange from "./auth/PasswordChange";
import MyFormData from "./Component/Profile/TestFormPage";
import SendOtpMobile from "./auth/ResetComponent/SendOtpMobile";
import DemoRegister from "./auth/DemoRegister";
import Verified from "./auth/Verified";
import DatePickerWithLeftIcon from "./utils/DatePicker";
import SchoolFormAdd from "./Component/Profile/SchoolFormAddForm";
import PrivateRoutes from "./Component/PrivateRoute/PrivateRoute";
import AdDashboard from "./layouts/AdDashboard/AdDashboard";
import Admin from "../src/Admin/Admin";
import CompanyProfile from "./Company_Profile/CompanyProfile";
import UserCanditate from "../src/Admin/UserCanditate";
import { DataProvider } from "../src/Admin/DataContext";
import Advertisment_Panel from "../src/Admin/Advertisment_Panel";
import { Privatechat } from "./utils/privatechat";
import GroupVideoCall from "./Component/Chat/GroupChatCall/GroupVideoCall.js";
import Room from "./Component/Chat/GroupChatCall/GroupVideoCall.js";
import LostAndFoundOverview from "./Component/LostAndFound/LostAndFoundOverview.jsx";
import LostAndFound from "./Component/LostAndFound/LostAndFound.jsx";


import View_CardDetails from './Admin/View_CardDetails.js'
import View_HomeAd_Details from '../src/layouts/View_HomeAd_Details.js'
import AccountSharing from '../src/Component/AccountSharing/AccountSharing.jsx'
import Accountsharingactivity from "../src/Component/AccountSharing/Accountsharingactivity.jsx"
import Video2 from "./Video2.jsx";
import Subcription from '../src/Component/Subcription/Subcription.jsx'
import { generateToken } from "./messageing_int_in_sw.js";
import { onMessage } from "firebase/messaging";
import { messaging } from "./messageing_int_in_sw.js";
import CallScreen from "./utils/CallScreen.js";
import AudioCallscreen from "./utils/AudioCallscreen.js";
import AudioCall from "./utils/CallScreen1.js";
import Aadhaar from "../src/Component/Aadhaar/Aadhaar.jsx"
import Aadhaar_details from '../src/Admin/Aadhaar_details.js'
import Activity from '../src/Component/MyActivity/Acitivity.jsx'
import VehicleOverview from '../src/Component/VehicleBoard/VehicleOverview/VehicleOverview.jsx'
import Privacy_Policy from './layouts/Privacy_Policy.jsx'
import Cancellation_policy from './layouts/Cancellation_policy.jsx'
import Billing from './Component/Subcription/Billing.jsx'
import Sales_details from './Admin/Sales_details.jsx'
import Loading from './Component/Subcription/Loading.jsx'
import Invoice from './layouts/invoice/invoice.jsx'
import CreateRoom from "./Component/Chat/GroupChatCall/NavigateGroupChat.js";
import Location from './Component/Subcription/Location.jsx'
import CineJob from './Component/CineJob/CineJob.jsx'
import ChatReports from "./Admin/Chat_Reports.jsx";
import ChatReportTeamM from "./Admin/ChatReportsTeamM.jsx";
import PostView from '../src/layouts/AdDashboard/PostMainOverview.jsx';
import PostDraftView from "../src/layouts/AdDashboard/AdMenus/DraftViewPage.jsx";
import BloodDonation from "./Component/BloodDonation/BloodDonation.jsx";
import BloodRequestList from "./Component/BloodDonation/BloodRequest/BloodRequestList.jsx";
import BloodRequestView from "./Component/BloodDonation/BloodRequest/BloodRequestView.jsx";
import DonorManagementList from "./Component/BloodDonation/DonorManagement/DonorManagementList.jsx";
import DonorManagementView from "./Component/BloodDonation/DonorManagement/DonorManagementView.jsx";
import CinePost from "./Component/CinePost/Cinepost.js";
import CinePostOverview from "./Component/CinePost/CinePostOverview.js";
import JobSeekingDetail from "./Component/CineJob/JobSeeking/JobSeekingDetail.jsx";
import CineJobOverview from "./layouts/AdDashboard/AdMenus/CineJobPost/CineJobOverview.jsx";
import CineJobProfile from "./layouts/AdDashboard/AdMenus/CineJobPost/CineJobProfile.jsx";
// import VehiChat from "../src/Component/VehiChat/index.jsx";
const ChatMain = React.lazy(() => import('../src/Component/Chat/ChatMain/ChatMain.jsx'));
function App() {

  const authdata = useSelector((state) => state.auth.user);
  const [onvideocall, setOnVideoCall] = useState(false);
  const [onAudiocall, setOnAudioCall] = useState(false);
  const [CallAccept, setCallAccept] = useState(false);
  const ringtoneRef = React.useRef(null);

  const ringtone = new Audio('./assets/Icons/phone-ringing-7006-2-62881.mp3');
  const [CallChatmasterid, setCallChatmasterid] = useState(null);
  const [Callerid, setCallerid] = useState(null);
  useEffect(() => {



    if (!onvideocall && !CallChatmasterid?.data?.callername) {
      setOnAudioCall(false);
    }

    if (authdata) {
      generateToken(authdata?.id);
    }

    onMessage(messaging, (payload) => {
      console.log(payload);
      setCallChatmasterid(payload);
      if (payload.data?.calltype == "video") {
        setOnVideoCall(true);

      }
      if (payload.data?.calltype == "audio") {
        setOnAudioCall(true);
        setOnVideoCall(false);
      }



    })
  }, [authdata])

  useEffect(() => {
    let timer;
    ringtoneRef.current = new Audio('https://h799xu-a.akamaihd.net/downloads/ringtones/files/mp3/phone-ringing-7006-2-62881.mp3');

    const playRingtone = () => {
      ringtoneRef.current.currentTime = 0;
      ringtoneRef.current.play().catch(error => {
        console.error('Error playing ringtone:', error);
      });
    };

    if (onAudiocall && CallChatmasterid?.data?.callername) {
      playRingtone();
      ringtoneRef.current.addEventListener('ended', playRingtone);

      timer = setTimeout(() => {
        setOnAudioCall(false);
        setOnVideoCall(false);
      }, !onAudiocall ? 0 : 28000); // 28 seconds in milliseconds
    }

    // Cleanup function
    return () => {
      if (ringtoneRef.current) {
        ringtoneRef.current.pause();
        ringtoneRef.current.removeEventListener('ended', playRingtone);
      }
      clearTimeout(timer);
    };
  }, [onAudiocall, onvideocall]);


  return (
    <div className="App">


      <Router>

        <DataProvider>
          <React.Suspense fallback={<div>Loading...</div>}>
            {onvideocall && CallChatmasterid?.data?.callername && <CallScreen setOnVideoCall={setOnVideoCall} CallChatmasterid={CallChatmasterid} />}
            {onAudiocall && CallChatmasterid?.data?.callername && <AudioCall setOnAudioCall={setOnAudioCall} setCallAccept={setCallAccept} callRoomid={CallChatmasterid?.data?.chatdata} CallerDetail={CallChatmasterid} />}
            {CallChatmasterid && CallChatmasterid.data?.calltype == "audio" && CallAccept && <AudioCallscreen
              Receiverid={CallChatmasterid.data.receivercallid.replace(/'/g, '')} setCallChatmasterid={setCallChatmasterid} setCallAccept={setCallAccept}
              CallerDetail={CallChatmasterid}
            />}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/AudioCallscreen" element={<AudioCallscreen />} />
              <Route path="/ap" element={<DynamicInputFields />} />
              <Route path="/register" element={<DemoRegister />} />
              <Route path="/verify" element={<Verified />} />
              <Route path="/demoregister" element={<DemoRegister />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/resetchange" element={<ResetPasswordChange />} />
              <Route path="/privatechat" element={<Privatechat />} />
              <Route path="/videocall" element={<Video2 />} />
              <Route path="/videocall/:id/:chatmasterid" element={<Video2 />} />
              <Route path="/groupvideocall" element={<GroupVideoCall />} />
              <Route path="/groupnavigate" element={<CreateRoom />} />
              <Route path="/room/:roomID" element={<Room />} />


              <Route path="/" element={<Main />}>
                <Route path="/main/ContactUs" element={<ContactUs />} />
                <Route path="/main/Cancellation_policy" element={<Cancellation_policy />} />
                <Route path="/main/About" element={<About />} />
                <Route path="/main/Terms" element={<TermsPolicy />} />
                <Route path="/main/Privacy_Policy" element={<Privacy_Policy />} />
                <Route index element={<Maintainence />} />

                <Route element={<PrivateRoutes />}>
                  <Route path="/main/video2" element={<Video2 />} />

                  <Route path="/main/profile" element={<Job />} />



                  <Route path="/main/Chat" element={<ChatMain setCallChatmasterid={setCallChatmasterid} setCallAccept={setCallAccept} />} />

                  <Route path="/main/vehicle" element={<Vehicle />} />
                  <Route path="/main/demo" element={<Demo />} />
                  <Route path="/main/form" element={<MyForm />} />
                  <Route path="/main/form1" element={<MyFormData />} />
                  <Route path="/main/otp" element={<SendOtpMobile />} />
                  <Route path="/main/date" element={<DatePickerWithLeftIcon />} />
                  <Route path="/main/schoolform" element={<SchoolFormAdd />} />
                  <Route path="/main/advDasboared" element={<AdDashboard />} />
                  <Route path="/main/AdminPanel" element={<Admin />} />
                  <Route path="/main/UserCandidate" element={<UserCanditate />} />
                  <Route path="/main/View_CardDetails" element={<View_CardDetails />} />
                  <Route path="/main/ViewPostDetails" element={<View_HomeAd_Details />} />
                  <Route path="/main/AccountSharing" element={<AccountSharing />} />
                  <Route path="/main/Accountsharingactivity" element={<Accountsharingactivity />} />
                  <Route path="/main/Subcription" element={<Subcription />} />
                  <Route path="/main/Aadhaar" element={<Aadhaar />} />
                  <Route path="/main/Aadhaar_details" element={<Aadhaar_details />} />
                  <Route path="/main/Activity" element={<Activity />} />
                  <Route path="/main/VehicleOverview/:id" element={<VehicleOverview />} />
                  <Route path="/main/PostOverview/:id" element={<PostView />} />
                  <Route path="/main/PostDraftOverview/:id" element={<PostDraftView />} />
                  <Route path="/main/Billing" element={<Billing />} />
                  <Route path="/main/Sales_details" element={<Sales_details />} />
                  <Route path="/main/Loading" element={<Loading />} />
                  <Route path="/main/Invoice" element={<Invoice />} />
                  <Route path="/main/Location" element={<Location />} />
                  <Route path="/main/ChatReports" element={<ChatReports />} />
                  <Route path="/main/ChatReportTeamM" element={<ChatReportTeamM />} />
                  <Route
                    path="/main/Advertisment_Panel"
                    element={<Advertisment_Panel />}
                  />
                  <Route
                    path="/main/CompanyProfile"
                    element={<CompanyProfile />}
                  />
                  <Route path="/main/LostAndFound" element={<LostAndFound />} />
                  <Route path="/main/LostAndFoundOverview/:id" element={<LostAndFoundOverview />} />
                  <Route path="/main/BloodDonation" element={<BloodDonation />} />
                  <Route path="/main/BloodRequestList" element={<BloodRequestList />} />
                  <Route path="/main/BloodRequestView/:id" element={<BloodRequestView />} />
                  <Route path="/main/DonorManagementList/:id" element={<DonorManagementList />} />
                  <Route path="/main/DonorManagementView/:id" element={<DonorManagementView />} />
                  <Route path="/main/cinePost" element={<CinePost />} />
                  <Route
                    path="/main/cinePost/view/:id"
                    element={<CinePostOverview />}
                  />
                  <Route path="/main/CineJob" element={<CineJob />} />
                  <Route path="/main/CineJob/:jobId" element={<JobSeekingDetail />} />
                  <Route path="/MyPost/CineJob/OverView/:jobId" element={<CineJobOverview />} />
                  <Route path="/CineJob/Profile" element={<CineJobProfile />} />
                  {/* <Route
                  path="/main/VehiChat"
                  element={<VehiChat />}
                /> */}
                </Route>
              </Route>
            </Routes>
          </React.Suspense>

        </DataProvider>
      </Router>
    </div>
  );
}

export default App;
