import { Grid } from "@mui/material";
import React,{useContext} from "react";
import { IoCalendarClear, IoKeySharp } from "react-icons/io5";
import "./VehicleOverviewCard.scss";
import { FaRoad, FaUserAstronaut } from "react-icons/fa";
import { MdAirlineSeatLegroomNormal } from "react-icons/md";
import { FaShield } from "react-icons/fa6";
import { PiGearSixDuotone } from "react-icons/pi";
import { IoIosSpeedometer } from "react-icons/io";
import { BsFuelPumpFill } from "react-icons/bs";
import { RiPaintFill } from "react-icons/ri";
// import { useVehicleContext } from "../../../../Context/VehicleContext";
import { DataContext } from "../../../Admin/DataContext";

const VehicleOverviewCard = () => {
  const {
    vehicleOption
  } = useContext(DataContext);

  return (
    <div className="VehicleOverviewCard" style={{ padding: "0px 20px" }}>
      <div className="card">
        <h4>Vehicle Overview</h4>
        {vehicleOption === "bicycle" ? (
          <div className="VehicleOverviewText">
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <IoCalendarClear />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Manufacturing year</h6>
                    <p className="mb-0">2016</p>
                  </div>
                </div>
              </Grid>

              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <PiGearSixDuotone />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Gear </h6>
                    <p className="mb-0">Non Gear</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <RiPaintFill />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Color</h6>
                    <p className="mb-0">Black</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className="VehicleOverviewText">
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <IoCalendarClear />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Manufacturing year</h6>
                    <p className="mb-0">2016</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <FaUserAstronaut />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Ownership</h6>
                    <p className="mb-0">2nd owner</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <FaRoad />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Reg Number</h6>
                    <p className="mb-0">TN13-F0000</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <MdAirlineSeatLegroomNormal />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Seats</h6>
                    <p className="mb-0">5 Person</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <FaShield />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Insurance </h6>
                    <p className="mb-0">Valid till - 2025</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <IoKeySharp />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Spare key</h6>
                    <p className="mb-0">No</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <PiGearSixDuotone />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Vehicle Type</h6>
                    <p className="mb-0">Manual</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <IoIosSpeedometer />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">KM Driven</h6>
                    <p className="mb-0">60,210 km</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <BsFuelPumpFill />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Fuel Type</h6>
                    <p className="mb-0">Petrol</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="VehicleOverCardText flex align-items-center ">
                  <p className="mb-0">
                    <RiPaintFill />
                  </p>
                  <div className="ms-3">
                    <h6 className="mb-0">Color</h6>
                    <p className="mb-0">Black</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleOverviewCard;
