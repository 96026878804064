// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VehicleOverviewReport .card h4 {
  font-weight: 400;
}
.VehicleOverviewReport .card ul {
  list-style: none;
  padding: 0;
}
.VehicleOverviewReport .card ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
}
.VehicleOverviewReport .card ul li p {
  margin: 0;
}
.VehicleOverviewReport .card ul li .icon-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  margin-left: 10px;
}
.VehicleOverviewReport .card ul li .icon-wrapper .danger {
  color: white;
  background-color: red;
  border-radius: 50%;
}
.VehicleOverviewReport .card ul li .icon-wrapper .success {
  color: white;
  background-color: green;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Component/VehicleBoard/VehicleOverview/VehicleOverviewReport.scss"],"names":[],"mappings":"AAEI;EACE,gBAAA;AADN;AAII;EACE,gBAAA;EACA,UAAA;AAFN;AAIM;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,gCAAA;AAFR;AAIQ;EACE,SAAA;AAFV;AAKQ;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;AAHV;AAKU;EACE,YAAA;EACA,qBAAA;EACA,kBAAA;AAHZ;AAMU;EACE,YAAA;EACA,uBAAA;EACA,kBAAA;AAJZ","sourcesContent":[".VehicleOverviewReport {\n  .card {\n    h4 {\n      font-weight: 400;\n    }\n\n    ul {\n      list-style: none;\n      padding: 0;\n\n      li {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        padding: 10px 0;\n        border-bottom: 1px solid #e6e6e6;\n\n        p {\n          margin: 0;\n        }\n\n        .icon-wrapper {\n          width: 40px;\n          height: 40px;\n          display: flex;\n          align-items: center;\n          justify-content: center;\n          border-radius: 50%;\n          font-size: 24px;\n          margin-left: 10px;\n\n          .danger {\n            color: white;\n            background-color: red;\n            border-radius: 50%;\n          }\n\n          .success {\n            color: white;\n            background-color: green;\n            border-radius: 50%;\n          }\n\n          .icon {\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
