// in a file named apiConfig.js



// const Nodeapi = 'http://localhost:8001/api';
// const Assetsapi = 'http://localhost:8001/assets'
// const ResumeApi = 'http://localhost:8001/assets/UserResume/';
// const ProfileImgApi = 'http://localhost:8001/assets/UserProfileImage';
// const BackendApi = 'http://localhost:8001/assets/SndRcvdImage';
// const BackendVidoeApi = 'http://localhost:8001/assets/SndRcvdVideo';
// const BackendAudioApi = 'http://localhost:8001/assets/SndRcvdAudio';
// const DocumentApi = 'http://localhost:8001/assets/SndRcvdPDF';
// const authapi = 'http://localhost:8001';
// const SocketApi = 'http://localhost:8001';
// module.exports = {
//   Nodeapi,
//   ResumeApi,
//   authapi,
//   ProfileImgApi,
//   Assetsapi,
//   BackendApi,
//   BackendVidoeApi,
//   BackendAudioApi,
//   DocumentApi,
//   SocketApi
// };


const Nodeapi = 'https://ktest.kodukku.com/api';
const Assetsapi = 'https://ktest.kodukku.com/assets' 
const ResumeApi = 'https://ktest.kodukku.com/assets/UserResume/';
const ProfileImgApi = 'https://ktest.kodukku.com/assets/UserProfileImage/';
const authapi = 'https://ktest.kodukku.com';
const SocketApi = 'https://ktest.kodukku.com';
const BackendApi = 'https://ktest.kodukku.com/assets/SndRcvdImage';
const BackendVidoeApi = 'https://ktest.kodukku.com/assets/SndRcvdVideo';
const BackendAudioApi = 'https://ktest.kodukku.com/assets/SndRcvdAudio';
const DocumentApi = 'https://ktest.kodukku.com/assets/SndRcvdPDF';

module.exports = {
Nodeapi,
ResumeApi,
authapi,
ProfileImgApi,
Assetsapi,
SocketApi,
BackendApi,
BackendVidoeApi,
BackendAudioApi,
DocumentApi
};


// const Nodeapi = 'https://www.kodukku.com/api';
// const Assetsapi = 'https://www.kodukku.com/assets' 
// const ResumeApi = 'https://www.kodukku.com/assets/UserResume/';
// const ProfileImgApi = 'https://www.kodukku.com/assets/UserProfileImage/';
// const authapi = 'https://www.kodukku.com';
// module.exports = {
//   Nodeapi,
//   ResumeApi,
//   authapi,
//   ProfileImgApi,
// Assetsapi
  
// };

