import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Nodeapi } from "../../../config/serverUrl";
import {Modal,Box,} from "@mui/material";
import {districtsInIndia,countries,indianStates,} from "../../Profile/Dropdown_list";
import PhoneInput from "react-phone-input-2";
import Swal from 'sweetalert2';
import { IoIosClose } from "react-icons/io";
import BloodUsestates from "../BloodUsestates";

const bloodgroup = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
const bloodComponent = ["Whole Blood", "Platelets", "Plasma", "Double Blood Cells", "Other"]
const urgencyLevel = ["Emergency", "Within 24 Hrs", "Within 48 Hrs", "Other"]
const gender = ["Male", "Female", "Others"]

const EditBloodRequest = ({ requestId, onClose, onUpdate }) => {
const { requestFormData, setRequestFormData,requestErrors, setRequestErrors, errors, setErrors, validateRequestForm} = BloodUsestates()
const [isRequestUpdating, setIsRequestUpdating] = useState(false);
const [fileNames, setFileNames] = useState({id_proof: '', donor_criteria: ''});
const [kidId, setKidId] = useState('');
const handleKidChange = async (event) => {
  const { value } = event.target;
  setKidId(value);
  try {
    const res = await axios.get(
      `${Nodeapi}/blood-donation/patientDetails?kidId=${value}`
    );
    const { name, fathername, Dob, gender, mobile_no, email } = res.data.fetchpersonal[0];
    setRequestFormData((prevData) => ({
      ...prevData,
      firstname: name,
      lastname: fathername,
      kidId: value,
      gender: gender?.toLowerCase(),
      dob:Dob,
      mobile_no:mobile_no,
      email:email
    }));
  } catch (error) {
    console.error("Error fetching patient details:", error);
  }};
const handleRequestChange = (event) => {
  const { name, type, files } = event.target;
  if (type === "file") {
    setRequestFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setFileNames(prevNames => ({
      ...prevNames,
      [name]: files[0]?.name || '',
    }));
  } else {
    setRequestFormData((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));}
  setRequestErrors((prevState) => ({
    ...prevState,
    [name]: "",
  }));};
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid email",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
      setRequestFormData((prevData) => ({
        ...prevData,
        [name]: value,
      })); 
    }
  };
  const validatePhoneNumber = (value) => {
    const cleanValue = value.replace(/^\+91/, "").replace(/^91/, "").replace(/\D/g, "");
    return cleanValue.length === 10;
  };
  const handleMobileNoChange = (value, field) => {
    if (field === "mobile_no") {
      if (!validatePhoneNumber(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "Invalid mobile number",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
      setRequestFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));}};
  
  useEffect(() => {
    const fetchRequestData = async () => {
      try {
        const { data } = await axios.get(`${Nodeapi}/blood-donation/request/${requestId}`);
        setRequestFormData(data);
        const extractFileName = (filePath) => {
          if (!filePath) return '';
          const parts = filePath.split(/[/\\]/); 
          return parts.pop(); 
        };
        if (data.id_proof) {
          setFileNames(prev => ({ ...prev, id_proof: extractFileName(data.id_proof) }));
        }
        if (data.donor_criteria) {
          setFileNames(prev => ({ ...prev, donor_criteria: extractFileName(data.donor_criteria) }));
        }
          setKidId(data.kid_id)
      } catch (error) {
        console.error("Error fetching request data", error);
      }};
    fetchRequestData();
  }, [requestId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
      setIsRequestUpdating(true);
      const isValid = validateRequestForm();
      if (!isValid) {
         setIsRequestUpdating(false)
         return
        };
      const formData = new FormData();
      for (const [key, value] of Object.entries(requestFormData)) {
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, value);
        } }
    try {
      const response = await axios.patch(`${Nodeapi}/blood-donation/request/${requestId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }});
        if (response) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          onUpdate();
          onClose();
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
        }
    } catch (error) {
      if(error.response && error.response.status === 400){
        setRequestErrors({patient_id: error.response.data.message})
      }
      else{
      console.error("Error updating request data", error);
      setErrors(error.response?.data || {});
      }
    }
    setIsRequestUpdating(false)
  };

  return (
    <div className="edit-form-container">
      <Modal open={Boolean(requestId)} onClose={onClose} aria-labelledby="edit-blood-request-title" aria-describedby="edit-blood-request-description">
      <Box className="scrollBarHidden modal-box">
      <div><div style={{display:'flex', justifyContent:'space-between'}}>
        <h5 className="mb-2 ">Blood Requirement Details</h5>
        <IoIosClose onClick={onClose} style={{cursor: 'pointer', fontSize:'30px', color:"#8B8B8B"}}/></div>
      <div className="row">
      <div className="col-md-6 col-sm-12 my-2">
        <label htmlFor="patient_id" className="mb-1 labels">Patient Id</label>
        <input type="text" name="patient_id" id="patient_id" className="form-control" placeholder="Patient Id" value={requestFormData.patient_id} onChange={handleRequestChange}/>
        {requestErrors.patient_id && (<span className="text-danger">{requestErrors.patient_id}</span>)}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <label htmlFor="blood_group" className="mb-1 labels">Blood Group</label>
        <div className="gap-2 ms-2" style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap' }}>
        {bloodgroup.sort((a, b) => a.localeCompare(b)).map((bg, index) => (
            <label key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <input type="radio" name="blood_group" value={bg} checked={requestFormData.blood_group === bg} onChange={handleRequestChange} style={{ marginRight: '8px' }}/>
              {bg}
            </label>))}
        </div>
        {requestErrors.blood_group && (<span className="text-danger">{requestErrors.blood_group}</span>)}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
          <label className="mb-1" htmlFor="blood_component_type">Blood Component</label>
          <div className="gap-2 ms-2" style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap' }}>
          {bloodComponent.map((component, index) => (
      <label key={index} style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          name="blood_component_type"
          value={component}
          checked={requestFormData.blood_component_type === component}
          onChange={handleRequestChange}
          style={{ marginRight: "8px" }}
        />
        {component}
      </label>))}
</div>
          {requestErrors.blood_component_type && (<span className="text-danger">{requestErrors.blood_component_type}</span>)}
        </div>
        <div className="col-md-6 col-sm-12 my-2">
          <label className="mb-1" htmlFor="units_required">
            Number of Units Required
          </label>
          <input
            type="number"
            name="units_required"
            className="form-control"
            value={requestFormData.units_required}
            onChange={handleRequestChange}
          />
           {requestErrors.units_required && (<span className="text-danger">{requestErrors.units_required}</span> )}
        </div>
        <div className="col-md-6 col-sm-12 my-2">
          <label className="mb-1" htmlFor="urgency_level">Urgency Level</label>
          <div className="gap-2 ms-2" style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap' }}>
{urgencyLevel.map((level, index) => (
      <label key={index} style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          name="urgency_level"
          value={level}
          checked={requestFormData.urgency_level === level}
          onChange={handleRequestChange}
          style={{ marginRight: "8px" }}/>
        {level}</label>))}
</div>
      {requestErrors.urgency_level && (<span className="text-danger">{requestErrors.urgency_level}</span>)}
        </div>
        <div className="col-md-6 col-sm-12 my-2">
          <label className="mb-1" htmlFor="from_date_required">From Date </label>
          <input
            type="date"
            name="from_date_required"
            className="form-control"
            value={requestFormData.from_date_required ? new Date(requestFormData.from_date_required).toISOString().split('T')[0] : ''}
            onChange={handleRequestChange}/>
           {requestErrors.from_date_required && (<span className="text-danger">{requestErrors.from_date_required}</span>)}
        </div>
        <div className="col-md-6 col-sm-12 my-2 row">
          <div className="col-md-6 col-sm-12"><label className="mb-1" htmlFor="to_date_required">To Date</label>
            <input
              type="date"
              name="to_date_required"
              className="form-control"
              value={requestFormData.to_date_required ? new Date(requestFormData.to_date_required).toISOString().split('T')[0] : ''}
              onChange={handleRequestChange}/>
            {requestErrors.to_date_required && (<span className="text-danger">{requestErrors.to_date_required} </span>)}</div>
          <div className="col-md-6 col-sm-12"><label className="mb-1" htmlFor="to_time_required"> To Time</label>
            <input
              type="time"
              name="to_time_required"
              className="form-control"
              value={requestFormData.to_time_required}
              onChange={handleRequestChange}/>
            {requestErrors.to_time_required && (<span className="text-danger">{requestErrors.to_time_required}</span>)}</div>
          </div>
        <div className="col-md-6 col-sm-12 my-2">
          <label>Is the patient undergoing Surgery?</label>
          <div className="gap-2 ms-2" style={{ display: 'flex', flexDirection: 'column' }} >
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              name="patient_underwent_surgery"
              value="Yes"
              checked={requestFormData.patient_underwent_surgery === "Yes"}
              onChange={handleRequestChange}
              style={{ marginRight: '8px' }}
            />Yes</label>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              name="patient_underwent_surgery"
              value="No"
              checked={requestFormData.patient_underwent_surgery === "No"}
              onChange={handleRequestChange}
              style={{ marginRight: '8px' }}
            />No</label>
          </div>  
        </div>
        {requestFormData.patient_underwent_surgery === "Yes" && (
          <div className="col-md-6 col-sm-12 my-2">
            <label htmlFor="issuesDetails" className="mb-1">Surgery Type</label>
            <input
              type="text"
              name="surgery_type"
              className="form-control"
              value={requestFormData.surgery_type}
              onChange={handleRequestChange} />
          </div>)}
        <div className="col-md-6 col-sm-12 my-2">
          <label className="mb-1" htmlFor="ward_room_number">Ward/Room Number</label>
          <input
            type="text"
            name="ward_room_number"
            className="form-control"
            value={requestFormData.ward_room_number}
            onChange={handleRequestChange}/>
        </div>
        <div className="col-md-6 col-sm-12 my-2">
          <label className="mb-1" htmlFor="doctor_name">Doctor's Name</label>
          <input
            type="text"
            name="doctor_name"
            className="form-control"
            value={requestFormData.doctor_name}
            onChange={handleRequestChange}/>
        </div>
        <div className="col-md-6 col-sm-12 my-2">
          <label className="mb-1" htmlFor="hospital_name">Hospital Name</label>
          <input
            type="text"
            name="hospital_name"
            className="form-control"
            value={requestFormData.hospital_name}
            onChange={handleRequestChange} />
            {requestErrors.hospital_name && (<span className="text-danger">{requestErrors.hospital_name}</span>)}
        </div>
        <div className="col-md-6 col-sm-12 my-2">
          <label className="mb-1" htmlFor="reason_for_requirement"> Reason for Requirement / Remarks</label>
          <textarea
            name="reason_for_requirement"
            className="form-control"
            value={requestFormData.reason_for_requirement}
            onChange={handleRequestChange}/>
        </div>
        <div className="col-md-6 col-sm-12 my-2">
        <label className="mb-1" htmlFor="donor_criteria"> Donor Crtiteria Instructions</label>
        <input
          className="form-control"
          type="file"
          name="donor_criteria"
          onChange={handleRequestChange}/>
         {fileNames.donor_criteria && (<p className="mt-2">Current file: {fileNames.donor_criteria}</p>)}
      </div>
      </div>
    </div>
    <div className="row" style={{ marginTop: "16px" }}>
    <h5 className="mb-2 ">Patient Details</h5>
    <div className="col-md-6 col-sm-12 my-2">
  <label className="mb-1" htmlFor="kidId">KID ID</label>
  <input
    className="form-control"
    type="text"
    name="kidId"
    value={kidId}
    onChange={handleKidChange}/>
   {requestErrors.kidId && (<span className="text-danger">{requestErrors.kidId}</span>)}
</div>
<div className="col-md-6 col-sm-12 my-2">
</div>
      <div className="col-md-6 col-sm-12 my-2">
        <label htmlFor="firstname" className="mb-1 labels"> Firstname</label>
        <input
          type="text"
          name="firstname"
          id="firstname"
          className="form-control"
          placeholder="Firstname"
          value={requestFormData?.firstname}
          onChange={handleRequestChange}/>
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <label htmlFor="lastname" className="mb-1 labels">Lastname</label>
        <input
          type="text"
          name="lastname"
          className="form-control"
          placeholder="Lastname"
          value={requestFormData?.lastname}
          onChange={handleRequestChange}/>
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <label htmlFor="dob" className="mb-1 labels">Date of Birth </label>
        <input
          type="date"
          name="dob"
          className="form-control"
          value={requestFormData.dob ? new Date(requestFormData.dob).toISOString().split('T')[0] : ''}
          onChange={handleRequestChange}/>
        {requestErrors.dob && (<span className="text-danger">{requestErrors.dob}</span>)}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <label className="mb-1 labels">Gender:</label>
        <div className="gap-2 ms-2" style={{ display: "flex", justifyContent: "space-between" }}>
          {gender.map((g, index) => (
      <label key={index} style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          name="gender"
          value={g}
          checked={requestFormData.gender === g}
          onChange={handleRequestChange}
          style={{ marginRight: "8px" }}/>
        {g}
      </label>))}
        </div>
        {requestErrors.gender && ( <div className="text-danger">{requestErrors.gender}</div>)}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <label className="mb-1" htmlFor="mobile_no"> Mobile Number</label>
        <PhoneInput className="border-example" country={"in"} value={requestFormData.mobile_no}
          onChange={(value) =>
            handleMobileNoChange(value, "mobile_no")
          }/>
       {requestErrors.mobile_no ? (<span className="text-danger">{requestErrors.mobile_no}</span>) :                                            (errors.mobile_no && (<div className="text-danger">{errors.mobile_no}</div>))}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <label className="mb-1" htmlFor="email">Email Address</label>
        <input
          className="form-control"
          type="email"
          name="email"
          value={requestFormData.email}
          onChange={handleEmailChange}/>
       {requestErrors.email ? (<span className="text-danger">{requestErrors.email}</span>) : (errors.email && (<div className="text-danger">{errors.email}</div>))}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <label className="labels ms-2">Address :</label>
        <input
          type="text"
          className="form-control"
          placeholder="Place/Village"
          name="place"
          value={requestFormData.place}
          onChange={handleRequestChange}/>
        {requestErrors.place && (<span className="text-danger">{requestErrors.place}</span>)}
      </div>
      <div className="col-md-6 col-sm-12 mb-2 mt-5">
        <select
          className="form-select"
          name="district"
          id="district"
          value={requestFormData.district}
          onChange={handleRequestChange}>
          <option value="">Select District</option>
          {districtsInIndia.map((district) => (
            <option key={district} value={district}>
              {district}
            </option>
          ))}
        </select>
        {requestErrors.district && (<span className="text-danger">{requestErrors.district}</span>)}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <select
          className="form-select"
          name="state"
          id="state"
          value={requestFormData.state}
          onChange={handleRequestChange}>
          <option value="">Select state</option>
          {indianStates.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
        {requestErrors.state && (<span className="text-danger">{requestErrors.state}</span>)}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <select
          className="form-select"
          name="country"
          id="country"
          value={requestFormData.country}
          onChange={handleRequestChange} >
          <option value="">Select Country</option>
          {countries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
        {requestErrors.country && (<span className="text-danger">{requestErrors.country}</span>)}
      </div>
      <div className="col-md-6 col-sm-12 my-2">
        <input
          type="number"
          className="form-control"
          placeholder="Zip Code"
          name="pincode"
          value={requestFormData.pincode}
          onChange={handleRequestChange}/>
        {requestErrors.pincode && (<span className="text-danger">{requestErrors.pincode}</span>)}
      </div>
    </div>
    <div className="col-md-6 col-sm-12 my-2">
        <label className="mb-1" htmlFor="id_proof">  ID Proof </label>
        <input
          className="form-control"
          type="file"
          name="id_proof"
          onChange={handleRequestChange} />
           {fileNames.id_proof && (<p className="mt-2">Current file: {fileNames.id_proof}</p>)}
      </div>  
              <div className="text-center mt-3">
                <button className="btn btn-danger me-4" onClick={onClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleUpdate}
                  disabled={isRequestUpdating} >
                  {isRequestUpdating ? "Update..." : "Update"}
                </button>
              </div>
        </Box>
     </Modal>
    </div>
  );
};
export default EditBloodRequest;
