import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import '../ChatSection/ChatHeader/ChatHeader.css';
import { v4 as uuidV4 } from 'uuid';

const CreateRoom = () => {
  const staticRoomId = '121112121'; // Static room ID
  const socketRef = useRef();
  const peersRef = useRef({});
  const localStreamRef = useRef();
  const [localStream, setLocalStream] = useState(null);
  const [remoteStreams, setRemoteStreams] = useState({});

  useEffect(() => {
    socketRef.current = io('http://localhost:8001/groupvideo'); // Adjust the server URL
    socketRef.current.on('user-connected', handleUserConnected);
    socketRef.current.on('offer', handleReceiveOffer);
    socketRef.current.on('answer', handleAnswer);
    socketRef.current.on('ice-candidate', handleNewICECandidateMsg);
    socketRef.current.on('user-disconnected', handleUserDisconnected);
    joinRoom();
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const joinRoom = async () => {
    await startLocalStream();
    socketRef.current.emit('join-room', staticRoomId, uuidV4());
  };

  const startLocalStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setLocalStream(stream);
      localStreamRef.current = stream;
    } catch (error) {
      console.error('Error accessing media devices.', error);
      alert('Could not start video source. Please check your camera and microphone permissions.');
    }
  };

  const createPeerConnection = (userId) => {
    const configuration = {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302'
        }
      ]
    };

    const peerConnection = new RTCPeerConnection(configuration);
    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketRef.current.emit('ice-candidate', { roomId: staticRoomId, candidate: event.candidate, userId });
      }
    };
    peerConnection.ontrack = (event) => {
      if (event.streams && event.streams[0]) {
        setRemoteStreams(prevStreams => ({ ...prevStreams, [userId]: event.streams[0] }));
      }
    };
    localStreamRef.current.getTracks().forEach(track => {
      peerConnection.addTrack(track, localStreamRef.current);
    });

    peersRef.current[userId] = {
      peerConnection,
      iceCandidatesQueue: []
    };

    return peerConnection;
  };

  const callUser = async (userId) => {
    const peerConnection = createPeerConnection(userId);
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(new RTCSessionDescription(offer));
    socketRef.current.emit('offer', { roomId: staticRoomId, sdp: offer, userId });
  };

  const handleReceiveOffer = async (data) => {
    const { sdp, userId } = data;
    const peerConnection = createPeerConnection(userId);
    await peerConnection.setRemoteDescription(new RTCSessionDescription(sdp));
    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(new RTCSessionDescription(answer));
    socketRef.current.emit('answer', { roomId: staticRoomId, sdp: answer, userId });
  };

  const handleAnswer = async (data) => {
    const { sdp, userId } = data;
    const peerConnection = peersRef.current[userId].peerConnection;
    if (peerConnection.signalingState === 'have-local-offer') {
      await peerConnection.setRemoteDescription(new RTCSessionDescription(sdp));
      // Add any queued ICE candidates
      peersRef.current[userId].iceCandidatesQueue.forEach(candidate => {
        peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      peersRef.current[userId].iceCandidatesQueue = [];
    } else {
      console.error('Peer connection is not in the expected state to handle the answer');
    }
  };

  const handleNewICECandidateMsg = async (data) => {
    const { candidate, userId } = data;
    const peerConnection = peersRef.current[userId].peerConnection;
    if (peerConnection.signalingState === 'stable' || peerConnection.signalingState === 'have-local-offer') {
      await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    } else {
      // Queue the ICE candidate      
      peersRef.current[userId].iceCandidatesQueue.push(candidate);
    }
  };

  const handleUserConnected = (userId) => {
    callUser(userId);
  };

  const handleUserDisconnected = (userId) => {
    if (peersRef.current[userId]) {
      peersRef.current[userId].peerConnection.close();
      delete peersRef.current[userId];
      setRemoteStreams(prevStreams => {
        const updatedStreams = { ...prevStreams };
        delete updatedStreams[userId];
        return updatedStreams;
      });
    }
  };

  return (
    <div className='videocallcontainer'>
      <div className="ch-video">
        <div className="ch-videocall">
          <video
            className="ch-mecall"
            ref={(video) => {
              if (video && localStream && !video.srcObject) {
                video.srcObject = localStream;
                video.play().catch(error => {
                  console.error('Error playing local video:', error);
                });
              }
            }}
            muted
          ></video>
          <div className="ch-ourcall">
            {Object.keys(remoteStreams).map(userId => (
              <video
                key={userId}
                className="ch-ourvideo"
                ref={(video) => {
                  if (video && remoteStreams[userId] && !video.srcObject) {
                    video.srcObject = remoteStreams[userId];
                    video.play().catch(error => {
                      console.error('Error playing remote video:', error);
                    });
                  }
                }}
              ></video>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRoom;
