import React from "react";
import { CgDanger } from "react-icons/cg";
import { FaCheckCircle } from "react-icons/fa";
import "./VehicleOverviewReport.scss";
import { CiCircleCheck } from "react-icons/ci";
import { MdKeyboardArrowRight } from "react-icons/md";

const VehicleOverviewReport = () => {
  return (
    <div className="VehicleOverviewReport mt-4" style={{ padding: "0px 20px" }}>
      <div className="card">
        <h4>Report of inspection</h4>
        <ul>
          <li>
            <p>Defects</p>
            <div className="icon-wrapper ">
              <CgDanger className="danger" />
            </div>
          </li>
          <li>
            <p>Refurbished parts</p>
            <div className="icon-wrapper ">
              <CgDanger className="danger" />
            </div>
          </li>
          <li>
            <p>Spare Tyres</p>
            <div className="icon-wrapper ">
              <CiCircleCheck className="success" />
            </div>
          </li>
        </ul>
        <div className="flex justify-content-center mt-3">
          <button
            className=""
            style={{
              padding: "10px 50px",
              borderRadius: "6px",
              background: "#2196f3",
              color: "white",
              border: "none",
            }}
          >
            View Details
            <span className="ms-2 fs-5">
              <MdKeyboardArrowRight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VehicleOverviewReport;
