import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterType: 'All', 
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterType: (state, action) => {
      state.filterType = action.payload;
    },
  },
});

export const { setFilterType } = filterSlice.actions;
export default filterSlice.reducer;
