import React, { useState,useEffect,useContext } from 'react';
import './ImageGrid.css';
import { BackendApi } from '../../../../../config/serverUrl';
import { DataContext } from '../../../../../Admin/DataContext';
import {
  CaretLeft,
  CaretRight,
  DownloadSimple,
  X} from "@phosphor-icons/react";
const ImageGrid = ({ initialImages, timestamp }) => {
  const [images, setImages] = useState(initialImages);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const {
    downloadFileAtURL
  } = useContext(DataContext);

  const displayImages = images.slice(0, 4);
  const additionalCount = images.length > 4 ? images.length - 3 : 0;

  const openFullscreen = (index) => {
    setSelectedIndex(index);
  };

  const closeFullscreen = () => {
    setSelectedIndex(null);
  };

  const deleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    if (index >= newImages.length) {
      setSelectedIndex(newImages.length - 1);
    }
  };

  const navigate = (direction) => {
    setSelectedIndex((prev) => {
      const newIndex = prev + direction;
      if (newIndex >= 0 && newIndex < images.length) {
        return newIndex;
      }
      return prev;
    });
  };


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedIndex !== null) {
        switch (event.key) {
          case 'ArrowRight': // Forward arrow key
            navigate(1);
            break;
          case 'ArrowLeft': // Backward arrow key
            navigate(-1);
            break;
          case 'Escape': // Escape key to close the fullscreen view
            closeFullscreen();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex, navigate, closeFullscreen]);


  return (
    <>
      <div className="image-grid">
        {displayImages.map((image, index) => (
          <div key={index} className="image-item" onClick={() => openFullscreen(index)}>
            <img src={`${BackendApi}/${image}`} alt={`Image ${index + 1}`} />
            {index === 3 && additionalCount > 0 && (
              <div className="overlaymultiimage" ><span style={{paddingBottom:"3px"}}>+</span>{additionalCount}</div>
            )}
          </div>
        ))}
      </div>
   

      {selectedIndex !== null && (
        <div className="fullscreen-view" style={{position:"fixed",zIndex:"9999"}}>
          <div className="top-controls">
            <button className="delete-btn" onClick={() => downloadFileAtURL(`${BackendApi}/${images[selectedIndex]}`)}><DownloadSimple /></button>
            <button className="close-btn" onClick={closeFullscreen}><X size={32} /></button>
          </div>
          <button className="nav-btn prev" onClick={() => navigate(-1)}><CaretLeft size={32} /></button>
          <div className="fullscreen-image">
            <img src={`${BackendApi}/${images[selectedIndex]}`} alt={`Fullscreen ${selectedIndex + 1}`} />
          </div>
          <button className="nav-btn next" onClick={() => navigate(1)}><CaretRight size={32} /></button>
        </div>
      )}
    </>
  );
};

export default ImageGrid;