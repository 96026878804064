import React, { useEffect, useRef, useState } from "react";
import { IconButton, ImageList, ImageListItem } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import { Assetsapi } from "../../config/serverUrl";
import "./cinepost.css";

const CinePostGallery = ({ imgVal }) => {
  const [mainImage, setMainImage] = useState(imgVal[0]?.image_url);
  const imageListRef = useRef(null);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const theme = useTheme();
  const baseUrl = `${Assetsapi}/adimages/`;
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (imgVal.length > 0) {
      setMainImage(imgVal[0]?.image_url);
    }
  }, [imgVal]);

  const handleImageClick = (image) => {
    setActiveStep(image);
    console.log(image);
  };

  const scrollLeft = () => {
    if (imageListRef.current) {
      imageListRef.current.scrollBy({
        left: -300, // Adjust scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (imageListRef.current) {
      imageListRef.current.scrollBy({
        left: 300, // Adjust scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const shouldCenter = imgVal.length <= 5;

  const [isPaused, setIsPaused] = useState(false);
  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div
      className="gallery-demo"
      style={{
        margin: "0 30px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        className="gallery-demo-card"
        style={{
          height: "410px",
          width: "100%",
          maxWidth: "417px",
          borderRadius: "5px",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        {
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={(index) => setActiveStep(index)}
            autoplay={!isPaused}
          >
            {imgVal && imgVal.length > 0 ? (
              imgVal.map((img, ind) => (
                <div
                  key={ind}
                  style={{
                    width: "100%",
                    height: "100%",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {Math.abs(activeStep - ind) <= 10 ? (
                    <img
                      src={`${baseUrl}${img.image_url}`}
                      alt="Main"
                      loading="lazy"
                      style={{
                        width: "417px",
                        height: "410px",
                        borderRadius: "5px",
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  ) : null}
                </div>
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ marginTop: "130px" }}>No Image Available</p>
              </div>
            )}
          </AutoPlaySwipeableViews>
        }
      </div>
      <div
        style={{
          position: "relative",
          marginTop: "16px",
        }}
      >
        <IconButton
          onClick={scrollLeft}
          style={{
            position: "absolute",
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            zIndex: "1",
          }}
        >
          <ArrowBackIos style={{ marginLeft: "3px" }} />
        </IconButton>
        <ImageList
          cols={8}
          gap={20}
          style={{
            padding: "0 10px 10px",
            display: "flex",
            justifyContent: shouldCenter ? "center" : "flex-start",
            height: "110px",
            overflowX: "hidden",
            overflowY: "hidden",
            whiteSpace: "nowrap",
          }}
          ref={imageListRef}
        >
          {imgVal?.map((image, index) => (
            <ImageListItem key={index}>
              <img
                src={`${baseUrl}${image.image_url}`}
                alt={`Item ${index + 1}`}
                cols={1}
                loading="lazy"
                style={{
                  width: "132px",
                  height: "100px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onMouseEnter={() => handleImageClick(index)}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <IconButton
          onClick={scrollRight}
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            zIndex: "1",
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </div>
    </div>
  );
};

export default CinePostGallery;
