const designation = [
  { label: "Accountant", value: "accountant" },
  { label: "Architect", value: "architect" },
  { label: "Artist", value: "artist" },
  { label: "Biologist", value: "biologist" },
  { label: "Chemical Engineer", value: "chemical_engineer" },
  { label: "Civil Engineer", value: "civil_engineer" },
  { label: "Data Analyst", value: "data_analyst" },
  { label: "Dentist", value: "dentist" },
  { label: "Doctor", value: "doctor" },
  { label: "Electrical Engineer", value: "electrical_engineer" },
  { label: "Graphic Designer", value: "graphic_designer" },
  { label: "Human Resources Manager", value: "human_resources_manager" },
  {
    label: "Information Technology Specialist",
    value: "information_technology_specialist",
  },
  { label: "Journalist", value: "journalist" },
  { label: "Lawyer", value: "lawyer" },
  { label: "Marketing Manager", value: "marketing_manager" },
  { label: "Nurse", value: "nurse" },
  { label: "Pharmacist", value: "pharmacist" },
  { label: "Project Manager", value: "project_manager" },
  { label: "Real Estate Agent", value: "real_estate_agent" },
  { label: "Sales Manager", value: "sales_manager" },
  { label: "Software Developer", value: "software_developer" },
  { label: "Teacher", value: "teacher" },
  { label: "Web Developer", value: "web_developer" },
  { label: "Veterinarian", value: "veterinarian" },
  { label: "Account Manager", value: "account_manager" },
  { label: "Business Analyst", value: "business_analyst" },
  { label: "Consultant", value: "consultant" },
  {
    label: "Customer Service Representative",
    value: "customer_service_representative",
  },
  { label: "Event Planner", value: "event_planner" },
  { label: "Financial Advisor", value: "financial_advisor" },
  { label: "Healthcare Administrator", value: "healthcare_administrator" },
  { label: "Insurance Agent", value: "insurance_agent" },
  { label: "Investment Banker", value: "investment_banker" },
  { label: "Operations Manager", value: "operations_manager" },
  {
    label: "Public Relations Specialist",
    value: "public_relations_specialist",
  },
  { label: "Quality Assurance Analyst", value: "quality_assurance_analyst" },
  { label: "Social Media Manager", value: "social_media_manager" },
  { label: "Supply Chain Manager", value: "supply_chain_manager" },
  {
    label: "Technical Support Specialist",
    value: "technical_support_specialist",
  },
  { label: "Training Coordinator", value: "training_coordinator" },
  { label: "UX Designer", value: "ux_designer" },
  { label: "Actuary", value: "actuary" },
  { label: "Air Traffic Controller", value: "air_traffic_controller" },
  { label: "Animator", value: "animator" },
  { label: "Architectural Designer", value: "architectural_designer" },
  { label: "Art Director", value: "art_director" },
  { label: "Chief Executive Officer", value: "chief_executive_officer" },
  { label: "Chief Financial Officer", value: "chief_financial_officer" },
  { label: "Chief Operating Officer", value: "chief_operating_officer" },
  { label: "Chief Technology Officer", value: "chief_technology_officer" },
  { label: "Clinical Psychologist", value: "clinical_psychologist" },
  { label: "Construction Manager", value: "construction_manager" },
  { label: "Content Writer", value: "content_writer" },
  { label: "Creative Director", value: "creative_director" },
  { label: "Database Administrator", value: "database_administrator" },
  { label: "Digital Marketer", value: "digital_marketer" },
  { label: "Editorial Assistant", value: "editorial_assistant" },
  { label: "Educational Consultant", value: "educational_consultant" },
  { label: "Environmental Scientist", value: "environmental_scientist" },
  { label: "Financial Analyst", value: "financial_analyst" },
  { label: "Food Scientist", value: "food_scientist" },
  { label: "Health Educator", value: "health_educator" },
  { label: "Industrial Designer", value: "industrial_designer" },
  { label: "Interior Designer", value: "interior_designer" },
  { label: "Investment Analyst", value: "investment_analyst" },
  { label: "Legal Secretary", value: "legal_secretary" },
  { label: "Logistics Coordinator", value: "logistics_coordinator" },
  { label: "Management Consultant", value: "management_consultant" },
  { label: "Market Research Analyst", value: "market_research_analyst" },
  { label: "Media Relations Specialist", value: "media_relations_specialist" },
  { label: "Medical Assistant", value: "medical_assistant" },
  { label: "Network Administrator", value: "network_administrator" },
  { label: "Operations Analyst", value: "operations_analyst" },
  { label: "Paralegal", value: "paralegal" },
  { label: "Product Manager", value: "product_manager" },
  { label: "Project Coordinator", value: "project_coordinator" },
  { label: "Public Health Specialist", value: "public_health_specialist" },
  { label: "Recruiter", value: "recruiter" },
  { label: "Sales Representative", value: "sales_representative" },
  {
    label: "Speech-Language Pathologist",
    value: "speech_language_pathologist",
  },
  { label: "Technical Writer", value: "technical_writer" },
  { label: "Travel Agent", value: "travel_agent" },
  { label: "User Interface Designer", value: "user_interface_designer" },
  { label: "Warehouse Manager", value: "warehouse_manager" },
  { label: "Web Designer", value: "web_designer" },
  { label: "Youth Counselor", value: "youth_counselor" },
  { label: "Administrative Assistant", value: "administrative_assistant" },
  {
    label: "Business Development Manager",
    value: "business_development_manager",
  },
  { label: "Career Advisor", value: "career_advisor" },
  { label: "Client Services Manager", value: "client_services_manager" },
  {
    label: "Community Outreach Coordinator",
    value: "community_outreach_coordinator",
  },
  { label: "Copy Editor", value: "copy_editor" },
  { label: "Customer Success Manager", value: "customer_success_manager" },
  { label: "Design Engineer", value: "design_engineer" },
  { label: "Event Coordinator", value: "event_coordinator" },
  { label: "Executive Assistant", value: "executive_assistant" },
  { label: "Fundraising Coordinator", value: "fundraising_coordinator" },
  { label: "Health Services Manager", value: "health_services_manager" },
  { label: "Human Resources Specialist", value: "human_resources_specialist" },
  {
    label: "Information Security Analyst",
    value: "information_security_analyst",
  },
  { label: "IT Manager", value: "it_manager" },
  { label: "Legal Advisor", value: "legal_advisor" },
  { label: "Medical Technologist", value: "medical_technologist" },
  { label: "Network Engineer", value: "network_engineer" },
  { label: "Operations Director", value: "operations_director" },
  { label: "Philanthropy Manager", value: "philanthropy_manager" },
  { label: "Product Developer", value: "product_developer" },
  { label: "Program Manager", value: "program_manager" },
  { label: "Public Policy Analyst", value: "public_policy_analyst" },
  { label: "Quality Control Inspector", value: "quality_control_inspector" },
  { label: "Research Scientist", value: "research_scientist" },
  { label: "SEO Specialist", value: "seo_specialist" },
  { label: "Service Delivery Manager", value: "service_delivery_manager" },
  { label: "Sociologist", value: "sociologist" },
  { label: "Systems Analyst", value: "systems_analyst" },
  { label: "Technical Project Manager", value: "technical_project_manager" },
  { label: "Training Specialist", value: "training_specialist" },
  { label: "User Experience Researcher", value: "user_experience_researcher" },
  { label: "Video Producer", value: "video_producer" },
  { label: "Virtual Assistant", value: "virtual_assistant" },
  { label: "Visual Merchandiser", value: "visual_merchandiser" },
  { label: "Welfare Officer", value: "welfare_officer" },
  { label: "Youth Program Coordinator", value: "youth_program_coordinator" },
  { label: "Account Executive", value: "account_executive" },
  { label: "Administrative Manager", value: "administrative_manager" },
  { label: "Application Developer", value: "application_developer" },
  {
    label: "Business Intelligence Analyst",
    value: "business_intelligence_analyst",
  },
  {
    label: "Clinical Research Coordinator",
    value: "clinical_research_coordinator",
  },
  { label: "Construction Engineer", value: "construction_engineer" },
  {
    label: "Customer Experience Manager",
    value: "customer_experience_manager",
  },
  { label: "Data Scientist", value: "data_scientist" },
  { label: "DevOps Engineer", value: "devops_engineer" },
  { label: "Event Manager", value: "event_manager" },
  { label: "Executive Director", value: "executive_director" },
  { label: "Fundraising Manager", value: "fundraising_manager" },
  { label: "Graphic Artist", value: "graphic_artist" },
  { label: "Health Coach", value: "health_coach" },
  { label: "Hotel Manager", value: "hotel_manager" },
  {
    label: "Information Systems Manager",
    value: "information_systems_manager",
  },
  { label: "Instructional Designer", value: "instructional_designer" },
  { label: "Legal Consultant", value: "legal_consultant" },
  { label: "Library Technician", value: "library_technician" },
  { label: "Market Development Manager", value: "market_development_manager" },
  { label: "Medical Secretary", value: "medical_secretary" },
  { label: "Nutritionist", value: "nutritionist" },
  {
    label: "Organizational Development Specialist",
    value: "organizational_development_specialist",
  },
  { label: "Payroll Specialist", value: "payroll_specialist" },
  {
    label: "Process Improvement Specialist",
    value: "process_improvement_specialist",
  },
  { label: "Production Coordinator", value: "production_coordinator" },
  { label: "Public Affairs Specialist", value: "public_affairs_specialist" },
  { label: "Quality Assurance Engineer", value: "quality_assurance_engineer" },
  { label: "Recruitment Specialist", value: "recruitment_specialist" },
  {
    label: "Regulatory Affairs Specialist",
    value: "regulatory_affairs_specialist",
  },
  { label: "Sales Director", value: "sales_director" },
  { label: "Security Analyst", value: "security_analyst" },
  { label: "Software Engineer", value: "software_engineer" },
  { label: "Strategic Planner", value: "strategic_planner" },
  { label: "Systems Administrator", value: "systems_administrator" },
  { label: "Tax Advisor", value: "tax_advisor" },
  {
    label: "Training and Development Manager",
    value: "training_and_development_manager",
  },
  { label: "UX/UI Designer", value: "ux_ui_designer" },
  { label: "Video Editor", value: "video_editor" },
  { label: "Virtual Event Coordinator", value: "virtual_event_coordinator" },
  { label: "Warehouse Supervisor", value: "warehouse_supervisor" },
  { label: "Web Content Manager", value: "web_content_manager" },
  { label: "Youth Advocate", value: "youth_advocate" },
  { label: "Advertising Manager", value: "advertising_manager" },
  {
    label: "Business Operations Manager",
    value: "business_operations_manager",
  },
  { label: "Campaign Manager", value: "campaign_manager" },
  {
    label: "Change Management Specialist",
    value: "change_management_specialist",
  },
  { label: "Claims Adjuster", value: "claims_adjuster" },
  { label: "Client Relations Manager", value: "client_relations_manager" },
  { label: "Data Engineer", value: "data_engineer" },
  { label: "Digital Content Creator", value: "digital_content_creator" },
  { label: "Education Manager", value: "education_manager" },
  {
    label: "Employee Relations Specialist",
    value: "employee_relations_specialist",
  },
  { label: "Engineering Manager", value: "engineering_manager" },
  { label: "Epidemiologist", value: "epidemiologist" },
  { label: "Financial Controller", value: "financial_controller" },
  { label: "Fundraising Director", value: "fundraising_director" },
  { label: "HR Director", value: "hr_director" },
  { label: "Innovation Manager", value: "innovation_manager" },
  { label: "Legal Manager", value: "legal_manager" },
  { label: "Medical Researcher", value: "medical_researcher" },
  { label: "Mortgage Broker", value: "mortgage_broker" },
  { label: "Operations Coordinator", value: "operations_coordinator" },
  {
    label: "Performance Improvement Specialist",
    value: "performance_improvement_specialist",
  },
  { label: "Product Marketing Manager", value: "product_marketing_manager" },
  { label: "Project Director", value: "project_director" },
  { label: "Public Relations Manager", value: "public_relations_manager" },
  { label: "Risk Manager", value: "risk_manager" },
  { label: "Sales Operations Manager", value: "sales_operations_manager" },
  { label: "Service Manager", value: "service_manager" },
  { label: "Social Worker", value: "social_worker" },
  { label: "Strategy Consultant", value: "strategy_consultant" },
  {
    label: "Talent Acquisition Specialist",
    value: "talent_acquisition_specialist",
  },
  { label: "Technical Recruiter", value: "technical_recruiter" },
  { label: "Training Manager", value: "training_manager" },
  { label: "User Experience Designer", value: "user_experience_designer" },
  { label: "Web Project Manager", value: "web_project_manager" },
  { label: "Workforce Analyst", value: "workforce_analyst" },
  { label: "Administrative Coordinator", value: "administrative_coordinator" },
  { label: "Audit Manager", value: "audit_manager" },
  { label: "Brand Manager", value: "brand_manager" },
  {
    label: "Business Development Director",
    value: "business_development_director",
  },
  { label: "Campaign Coordinator", value: "campaign_coordinator" },
  { label: "Chief Marketing Officer", value: "chief_marketing_officer" },
  { label: "Client Success Manager", value: "client_success_manager" },
  { label: "Compliance Officer", value: "compliance_officer" },
  { label: "Creative Strategist", value: "creative_strategist" },
  { label: "Customer Insights Analyst", value: "customer_insights_analyst" },
  { label: "Design Director", value: "design_director" },
  { label: "Digital Marketing Manager", value: "digital_marketing_manager" },
  { label: "Engineering Consultant", value: "engineering_consultant" },
  { label: "Environmental Consultant", value: "environmental_consultant" },
  { label: "Facilities Manager", value: "facilities_manager" },
  { label: "Finance Manager", value: "finance_manager" },
  { label: "Healthcare Consultant", value: "healthcare_consultant" },
  {
    label: "Information Systems Analyst",
    value: "information_systems_analyst",
  },
  { label: "IT Consultant", value: "it_consultant" },
  { label: "Legal Compliance Manager", value: "legal_compliance_manager" },
  { label: "Marketing Director", value: "marketing_director" },
  { label: "Medical Officer", value: "medical_officer" },
  { label: "Office Manager", value: "office_manager" },
  { label: "Operations Director", value: "operations_director" },
  {
    label: "Organizational Development Manager",
    value: "organizational_development_manager",
  },
  { label: "Policy Analyst", value: "policy_analyst" },
  {
    label: "Product Development Manager",
    value: "product_development_manager",
  },
  { label: "Project Supervisor", value: "project_supervisor" },
  { label: "Public Affairs Manager", value: "public_affairs_manager" },
  {
    label: "Quality Improvement Specialist",
    value: "quality_improvement_specialist",
  },
  {
    label: "Regulatory Compliance Manager",
    value: "regulatory_compliance_manager",
  },
  { label: "Sales Executive", value: "sales_executive" },
  { label: "Social Media Specialist", value: "social_media_specialist" },
  { label: "Sustainability Manager", value: "sustainability_manager" },
  { label: "Talent Manager", value: "talent_manager" },
  { label: "Training Consultant", value: "training_consultant" },
  { label: "Vendor Manager", value: "vendor_manager" },
  { label: "Web Content Specialist", value: "web_content_specialist" },
  { label: "Workforce Manager", value: "workforce_manager" },
  { label: "Agricultural Engineer", value: "agricultural_engineer" },
  { label: "Biochemical Engineer", value: "biochemical_engineer" },
  { label: "Brand Strategist", value: "brand_strategist" },
  {
    label: "Business Operations Director",
    value: "business_operations_director",
  },
  { label: "Chief Information Officer", value: "chief_information_officer" },
  { label: "Chief Sales Officer", value: "chief_sales_officer" },
  { label: "Claims Specialist", value: "claims_specialist" },
  { label: "Clinical Data Manager", value: "clinical_data_manager" },
  { label: "Corporate Trainer", value: "corporate_trainer" },
  { label: "Creative Services Manager", value: "creative_services_manager" },
  {
    label: "Customer Experience Director",
    value: "customer_experience_director",
  },
  { label: "Database Developer", value: "database_developer" },
  {
    label: "Digital Communications Manager",
    value: "digital_communications_manager",
  },
  { label: "Engineering Director", value: "engineering_director" },
  {
    label: "Environmental Health and Safety Manager",
    value: "environmental_health_and_safety_manager",
  },
  { label: "Executive Recruiter", value: "executive_recruiter" },
  { label: "Financial Planning Manager", value: "financial_planning_manager" },
  { label: "Health Policy Analyst", value: "health_policy_analyst" },
  {
    label: "Human Resources Business Partner",
    value: "human_resources_business_partner",
  },
  {
    label: "Information Technology Manager",
    value: "information_technology_manager",
  },
  { label: "Innovation Consultant", value: "innovation_consultant" },
  { label: "Insurance Underwriter", value: "insurance_underwriter" },
  {
    label: "Leadership Development Manager",
    value: "leadership_development_manager",
  },
  { label: "Market Analyst", value: "market_analyst" },
  {
    label: "Medical and Health Services Manager",
    value: "medical_and_health_services_manager",
  },
  {
    label: "Occupational Health and Safety Specialist",
    value: "occupational_health_and_safety_specialist",
  },
  { label: "Operations Analyst", value: "operations_analyst" },
  {
    label: "Pharmaceutical Sales Representative",
    value: "pharmaceutical_sales_representative",
  },
  { label: "Product Operations Manager", value: "product_operations_manager" },
  {
    label: "Project Management Office (PMO) Director",
    value: "project_management_office_director",
  },
  {
    label: "Public Relations Coordinator",
    value: "public_relations_coordinator",
  },
  { label: "Quality Manager", value: "quality_manager" },
  { label: "Recruitment Manager", value: "recruitment_manager" },
  { label: "Regulatory Affairs Manager", value: "regulatory_affairs_manager" },
  { label: "Sales Support Specialist", value: "sales_support_specialist" },
  { label: "Strategy Director", value: "strategy_director" },
  { label: "Tax Manager", value: "tax_manager" },
  { label: "Technical Account Manager", value: "technical_account_manager" },
  { label: "User Researcher", value: "user_researcher" },
  {
    label: "Warehouse Operations Manager",
    value: "warehouse_operations_manager",
  },
  { label: "Web Analyst", value: "web_analyst" },
  { label: "Workplace Safety Manager", value: "workplace_safety_manager" },
];
const department = [
  { label: "Accounts Payable", value: "Accounts Payable" },
  { label: "Accounts Receivable", value: "Accounts Receivable" },
  { label: "Acquisitions", value: "Acquisitions" },
  { label: "Administration", value: "Administration" },
  { label: "Advertising", value: "Advertising" },
  { label: "After-Sales Support", value: "After-Sales Support" },
  { label: "Agile Project Management", value: "Agile Project Management" },
  { label: "Agricultural Research", value: "Agricultural Research" },
  { label: "Air Quality Control", value: "Air Quality Control" },
  { label: "Analytical Chemistry", value: "Analytical Chemistry" },
  { label: "Analytics and Reporting", value: "Analytics and Reporting" },
  {
    label: "Animation and Motion Graphics",
    value: "Animation and Motion Graphics",
  },
  { label: "Antitrust and Competition", value: "Antitrust and Competition" },
  { label: "Application Development", value: "Application Development" },
  {
    label: "Apprenticeship and Internship",
    value: "Apprenticeship and Internship",
  },
  { label: "Arbitration and Mediation", value: "Arbitration and Mediation" },
  { label: "Architectural Design", value: "Architectural Design" },
  { label: "Asset Management", value: "Asset Management" },
  { label: "Audit and Compliance", value: "Audit and Compliance" },
  { label: "Automation and Robotics", value: "Automation and Robotics" },
  { label: "Auxiliary Services", value: "Auxiliary Services" },
  { label: "Balanced Scorecard", value: "Balanced Scorecard" },
  {
    label: "Banking and Financial Services",
    value: "Banking and Financial Services",
  },
  { label: "Benefits Administration", value: "Benefits Administration" },
  { label: "Board of Directors", value: "Board of Directors" },
  { label: "Branding and Identity", value: "Branding and Identity" },
  { label: "Business Consulting", value: "Business Consulting" },
  { label: "Business Continuity", value: "Business Continuity" },
  { label: "Business Development", value: "Business Development" },
  { label: "Business Intelligence", value: "Business Intelligence" },
  { label: "Business Operations", value: "Business Operations" },
  { label: "Business Planning", value: "Business Planning" },
  {
    label: "Business Process Improvement",
    value: "Business Process Improvement",
  },
  { label: "Business Transformation", value: "Business Transformation" },
  { label: "Call Center", value: "Call Center" },
  { label: "Capital Expenditure", value: "Capital Expenditure" },
  { label: "Career Development", value: "Career Development" },
  { label: "Cash Management", value: "Cash Management" },
  { label: "Change Management", value: "Change Management" },
  { label: "Channel Management", value: "Channel Management" },
  { label: "Civil Engineering", value: "Civil Engineering" },
  { label: "Clinical Trials", value: "Clinical Trials" },
  { label: "Coaching and Mentoring", value: "Coaching and Mentoring" },
  {
    label: "Communications and Public Relations",
    value: "Communications and Public Relations",
  },
  { label: "Community Engagement", value: "Community Engagement" },
  { label: "Compensation and Benefits", value: "Compensation and Benefits" },
  { label: "Competitive Analysis", value: "Competitive Analysis" },
  {
    label: "Compliance and Risk Management",
    value: "Compliance and Risk Management",
  },
  {
    label: "Computer-Aided Design (CAD)",
    value: "Computer-Aided Design (CAD)",
  },
  { label: "Condition Monitoring", value: "Condition Monitoring" },
  { label: "Construction Management", value: "Construction Management" },
  { label: "Consumer Insights", value: "Consumer Insights" },
  { label: "Content Creation", value: "Content Creation" },
  { label: "Contract Negotiation", value: "Contract Negotiation" },
  { label: "Corporate Affairs", value: "Corporate Affairs" },
  { label: "Corporate Citizenship", value: "Corporate Citizenship" },
  { label: "Corporate Governance", value: "Corporate Governance" },
  { label: "Corporate Security", value: "Corporate Security" },
  {
    label: "Corporate Social Responsibility (CSR)",
    value: "Corporate Social Responsibility (CSR)",
  },
  { label: "Cost Accounting", value: "Cost Accounting" },
  { label: "Cost Optimization", value: "Cost Optimization" },
  { label: "Creative Services", value: "Creative Services" },
  { label: "Crisis Management", value: "Crisis Management" },
  {
    label: "Cross-Functional Collaboration",
    value: "Cross-Functional Collaboration",
  },
  { label: "Customer Analytics", value: "Customer Analytics" },
  { label: "Customer Experience", value: "Customer Experience" },
  {
    label: "Customer Relationship Management (CRM)",
    value: "Customer Relationship Management (CRM)",
  },
  { label: "Customer Service", value: "Customer Service" },
  { label: "Cybersecurity", value: "Cybersecurity" },
  { label: "Data Analytics", value: "Data Analytics" },
  { label: "Data Architecture", value: "Data Architecture" },
  { label: "Data Governance", value: "Data Governance" },
  { label: "Data Management", value: "Data Management" },
  { label: "Data Mining", value: "Data Mining" },
  { label: "Data Science", value: "Data Science" },
  { label: "Data Visualization", value: "Data Visualization" },
  { label: "Database Administration", value: "Database Administration" },
  { label: "Decision Support Systems", value: "Decision Support Systems" },
  { label: "Demand Forecasting", value: "Demand Forecasting" },
  {
    label: "Deployment and Implementation",
    value: "Deployment and Implementation",
  },
  { label: "Design Thinking", value: "Design Thinking" },
  { label: "Digital Marketing", value: "Digital Marketing" },
  { label: "Digital Transformation", value: "Digital Transformation" },
  { label: "Diversity and Inclusion", value: "Diversity and Inclusion" },
  { label: "Document Management", value: "Document Management" },
  { label: "Donations and Sponsorships", value: "Donations and Sponsorships" },
  { label: "E-Commerce", value: "E-Commerce" },
  { label: "Economic Analysis", value: "Economic Analysis" },
  { label: "Electrical Engineering", value: "Electrical Engineering" },
  { label: "Employee Engagement", value: "Employee Engagement" },
  { label: "Employee Relations", value: "Employee Relations" },
  {
    label: "Employee Training and Development",
    value: "Employee Training and Development",
  },
  { label: "Energy Efficiency", value: "Energy Efficiency" },
  { label: "Energy Management", value: "Energy Management" },
  { label: "Engineering Design", value: "Engineering Design" },
  { label: "Enterprise Architecture", value: "Enterprise Architecture" },
  { label: "Enterprise Risk Management", value: "Enterprise Risk Management" },
  { label: "Environmental Compliance", value: "Environmental Compliance" },
  {
    label: "Environmental Health and Safety",
    value: "Environmental Health and Safety",
  },
  {
    label: "Environmental Impact Assessment",
    value: "Environmental Impact Assessment",
  },
  { label: "Environmental Monitoring", value: "Environmental Monitoring" },
  {
    label: "Environmental Sustainability",
    value: "Environmental Sustainability",
  },
  {
    label: "Ergonomics and Workplace Design",
    value: "Ergonomics and Workplace Design",
  },
  { label: "Estate Management", value: "Estate Management" },
  { label: "Event Management", value: "Event Management" },
  { label: "Executive Coaching", value: "Executive Coaching" },
  { label: "Facilities Management", value: "Facilities Management" },
  { label: "Factory Automation", value: "Factory Automation" },
  { label: "Factory Layout and Design", value: "Factory Layout and Design" },
  { label: "Feasibility Studies", value: "Feasibility Studies" },
  { label: "Finance and Accounting", value: "Finance and Accounting" },
  { label: "Financial Analysis", value: "Financial Analysis" },
  { label: "Financial Forecasting", value: "Financial Forecasting" },
  { label: "Financial Modeling", value: "Financial Modeling" },
  {
    label: "Financial Planning and Budgeting",
    value: "Financial Planning and Budgeting",
  },
  { label: "Financial Reporting", value: "Financial Reporting" },
  { label: "Financial Risk Management", value: "Financial Risk Management" },
  { label: "Fraud Investigation", value: "Fraud Investigation" },
  { label: "Freight and Logistics", value: "Freight and Logistics" },
  {
    label: "Fundraising and Development",
    value: "Fundraising and Development",
  },
  {
    label: "Futures and Options Trading",
    value: "Futures and Options Trading",
  },
  { label: "General Counsel", value: "General Counsel" },
  { label: "Geospatial Analysis", value: "Geospatial Analysis" },
  { label: "Graphic Design", value: "Graphic Design" },
  {
    label: "Green Building and LEED Certification",
    value: "Green Building and LEED Certification",
  },
  { label: "Green Energy Solutions", value: "Green Energy Solutions" },
  { label: "Grievance Handling", value: "Grievance Handling" },
  { label: "Groundwater Management", value: "Groundwater Management" },
  { label: "Growth Hacking", value: "Growth Hacking" },
  { label: "Health and Wellness", value: "Health and Wellness" },
  {
    label: "Healthcare Information Systems",
    value: "Healthcare Information Systems",
  },
  { label: "Healthcare Operations", value: "Healthcare Operations" },
  { label: "Hiring and Recruitment", value: "Hiring and Recruitment" },
  { label: "Hospitality and Tourism", value: "Hospitality and Tourism" },
  { label: "Human Capital Management", value: "Human Capital Management" },
  {
    label: "Human Resources Information Systems (HRIS)",
    value: "Human Resources Information Systems (HRIS)",
  },
  { label: "Hydrogeology", value: "Hydrogeology" },
  { label: "Impact Investing", value: "Impact Investing" },
  { label: "Industrial Automation", value: "Industrial Automation" },
  { label: "Industrial Design", value: "Industrial Design" },
  { label: "Industrial Engineering", value: "Industrial Engineering" },
  { label: "Industrial Safety", value: "Industrial Safety" },
  { label: "Information Architecture", value: "Information Architecture" },
  { label: "Information Security", value: "Information Security" },
  { label: "Information Systems Audit", value: "Information Systems Audit" },
  { label: "Innovation Management", value: "Innovation Management" },
  { label: "Instructional Design", value: "Instructional Design" },
  { label: "Information Technology", value: "Information Technology" },
  {
    label: "Insurance and Risk Management",
    value: "Insurance and Risk Management",
  },
  {
    label: "Integrated Marketing Communications",
    value: "Integrated Marketing Communications",
  },
  {
    label: "Intellectual Property Management",
    value: "Intellectual Property Management",
  },
  { label: "Intelligent Automation", value: "Intelligent Automation" },
  { label: "Internal Audit", value: "Internal Audit" },
  { label: "Internal Communications", value: "Internal Communications" },
  { label: "International Expansion", value: "International Expansion" },
  { label: "International Trade", value: "International Trade" },
  { label: "Inventory Control", value: "Inventory Control" },
  { label: "Investor Relations", value: "Investor Relations" },
  { label: "IT Infrastructure", value: "IT Infrastructure" },
  { label: "IT Operations", value: "IT Operations" },
  { label: "IT Project Management", value: "IT Project Management" },
  { label: "IT Service Management", value: "IT Service Management" },
  { label: "IT Strategy and Governance", value: "IT Strategy and Governance" },
  { label: "Knowledge Management", value: "Knowledge Management" },
  { label: "Labor Relations", value: "Labor Relations" },
  {
    label: "Land Acquisition and Leasing",
    value: "Land Acquisition and Leasing",
  },
  { label: "Land Use Planning", value: "Land Use Planning" },
  { label: "Language and Translation", value: "Language and Translation" },
  { label: "Leadership Development", value: "Leadership Development" },
  { label: "Lean Manufacturing", value: "Lean Manufacturing" },
  { label: "Legal Compliance", value: "Legal Compliance" },
  { label: "Legislative Affairs", value: "Legislative Affairs" },
  { label: "Licensing and Franchising", value: "Licensing and Franchising" },
  { label: "Life Cycle Assessment", value: "Life Cycle Assessment" },
  { label: "Litigation Support", value: "Litigation Support" },
  { label: "Logistics and Supply Chain", value: "Logistics and Supply Chain" },
  { label: "Machine Learning", value: "Machine Learning" },
  {
    label: "Maintenance and Reliability",
    value: "Maintenance and Reliability",
  },
  { label: "Management Consulting", value: "Management Consulting" },
  { label: "Manpower Planning", value: "Manpower Planning" },
  { label: "Manufacturing Engineering", value: "Manufacturing Engineering" },
  { label: "Market Analysis", value: "Market Analysis" },
  { label: "Market Entry Strategy", value: "Market Entry Strategy" },
  { label: "Market Research", value: "Market Research" },
  { label: "Marketing Analytics", value: "Marketing Analytics" },
  { label: "Marketing Automation", value: "Marketing Automation" },
  { label: "Marketing Communications", value: "Marketing Communications" },
  { label: "Marketing Operations", value: "Marketing Operations" },
  { label: "Marketing Research", value: "Marketing Research" },
  { label: "Media Planning and Buying", value: "Media Planning and Buying" },
  { label: "Mergers and Acquisitions", value: "Mergers and Acquisitions" },
  { label: "Metrology and Calibration", value: "Metrology and Calibration" },
  { label: "Microbiology", value: "Microbiology" },
  { label: "Microfinance", value: "Microfinance" },
  { label: "Motion and Time Study", value: "Motion and Time Study" },
  { label: "Network Administration", value: "Network Administration" },
  { label: "Nonprofit Management", value: "Nonprofit Management" },
  {
    label: "Occupational Health and Safety",
    value: "Occupational Health and Safety",
  },
  { label: "Office Administration", value: "Office Administration" },
  {
    label: "Online Reputation Management",
    value: "Online Reputation Management",
  },
  { label: "Open Innovation", value: "Open Innovation" },
  { label: "Operations Analysis", value: "Operations Analysis" },
  { label: "Operations Excellence", value: "Operations Excellence" },
  { label: "Operations Research", value: "Operations Research" },
  {
    label: "Organizational Change Management",
    value: "Organizational Change Management",
  },
  { label: "Organizational Development", value: "Organizational Development" },
  { label: "Outdoor Advertising", value: "Outdoor Advertising" },
  { label: "Packaging Design", value: "Packaging Design" },
  { label: "Partnering and Alliances", value: "Partnering and Alliances" },
  { label: "Patent and Trademark", value: "Patent and Trademark" },
  { label: "Payroll Administration", value: "Payroll Administration" },
  { label: "Performance Management", value: "Performance Management" },
  { label: "Philanthropy and Grants", value: "Philanthropy and Grants" },
  {
    label: "Photogrammetry and Remote Sensing",
    value: "Photogrammetry and Remote Sensing",
  },
  { label: "Physical Asset Management", value: "Physical Asset Management" },
  { label: "Physical Security", value: "Physical Security" },
  { label: "Plant Engineering", value: "Plant Engineering" },
  { label: "Policy and Compliance", value: "Policy and Compliance" },
  { label: "Portfolio Management", value: "Portfolio Management" },
  { label: "Predictive Analytics", value: "Predictive Analytics" },
  { label: "Price Optimization", value: "Price Optimization" },
  { label: "Pricing Strategy", value: "Pricing Strategy" },
  { label: "Print Production", value: "Print Production" },
  { label: "Procurement and Sourcing", value: "Procurement and Sourcing" },
  { label: "Process Improvement", value: "Process Improvement" },
  { label: "Process Optimization", value: "Process Optimization" },
  { label: "Product Development", value: "Product Development" },
  { label: "Product Management", value: "Product Management" },
  { label: "Product Marketing", value: "Product Marketing" },
  { label: "Product Operations", value: "Product Operations" },
  { label: "Product Planning", value: "Product Planning" },
  { label: "Product Roadmapping", value: "Product Roadmapping" },
  { label: "Product Strategy", value: "Product Strategy" },
  { label: "Production Engineering", value: "Production Engineering" },
  { label: "Production Scheduling", value: "Production Scheduling" },
  { label: "Program Management", value: "Program Management" },
  { label: "Project Accounting", value: "Project Accounting" },
  { label: "Project Controls", value: "Project Controls" },
  { label: "Project Financing", value: "Project Financing" },
  { label: "Project Management", value: "Project Management" },
  { label: "Property Management", value: "Property Management" },
  { label: "Proposal Management", value: "Proposal Management" },
  { label: "Public Affairs", value: "Public Affairs" },
  { label: "Public Health", value: "Public Health" },
  { label: "Public Policy", value: "Public Policy" },
  { label: "Public Relations", value: "Public Relations" },
  { label: "Public Sector Consulting", value: "Public Sector Consulting" },
  {
    label: "Public-Private Partnerships",
    value: "Public-Private Partnerships",
  },
  { label: "Quality Assurance", value: "Quality Assurance" },
  { label: "Quality Control", value: "Quality Control" },
  { label: "Quality Management", value: "Quality Management" },
  { label: "Real Estate Development", value: "Real Estate Development" },
  { label: "Real Estate Investment", value: "Real Estate Investment" },
  { label: "Regulatory Affairs", value: "Regulatory Affairs" },
  { label: "Regulatory Compliance", value: "Regulatory Compliance" },
  { label: "Rehabilitation Engineering", value: "Rehabilitation Engineering" },
  { label: "Renewable Energy", value: "Renewable Energy" },
  { label: "Rental and Leasing", value: "Rental and Leasing" },
  {
    label: "Research and Development (R&D)",
    value: "Research and Development (R&D)",
  },
  { label: "Reservoir Engineering", value: "Reservoir Engineering" },
  {
    label: "Resourcing and Talent Management",
    value: "Resourcing and Talent Management",
  },
  { label: "Retail Operations", value: "Retail Operations" },
  { label: "Revenue Management", value: "Revenue Management" },
  { label: "Risk Analysis", value: "Risk Analysis" },
  { label: "Risk Management", value: "Risk Management" },
  { label: "Robotics and Automation", value: "Robotics and Automation" },
  {
    label: "Sales and Channel Management",
    value: "Sales and Channel Management",
  },
  { label: "Sales Enablement", value: "Sales Enablement" },
  { label: "Sales Operations", value: "Sales Operations" },
  {
    label: "Sales Strategy and Planning",
    value: "Sales Strategy and Planning",
  },
  { label: "Sanitation and Hygiene", value: "Sanitation and Hygiene" },
  {
    label: "Security and Asset Protection",
    value: "Security and Asset Protection",
  },
  { label: "Service Delivery", value: "Service Delivery" },
  { label: "Service Design", value: "Service Design" },
  { label: "Service Level Management", value: "Service Level Management" },
  { label: "Service Operations", value: "Service Operations" },
  { label: "Service Strategy", value: "Service Strategy" },
  { label: "Shop Floor Management", value: "Shop Floor Management" },
  { label: "Six Sigma and Lean", value: "Six Sigma and Lean" },
  { label: "Social Impact Investing", value: "Social Impact Investing" },
  { label: "Social Media Marketing", value: "Social Media Marketing" },
  { label: "Social Responsibility", value: "Social Responsibility" },
  { label: "Software Development", value: "Software Development" },
  { label: "Solar Energy", value: "Solar Energy" },
  { label: "Space Planning and Design", value: "Space Planning and Design" },
  { label: "Spatial Data Management", value: "Spatial Data Management" },
  { label: "Stakeholder Management", value: "Stakeholder Management" },
  { label: "Strategic Alliances", value: "Strategic Alliances" },
  { label: "Strategic Communications", value: "Strategic Communications" },
  { label: "Strategic Foresight", value: "Strategic Foresight" },
  { label: "Strategic Human Resources", value: "Strategic Human Resources" },
  { label: "Strategic Initiatives", value: "Strategic Initiatives" },
  { label: "Strategic Marketing", value: "Strategic Marketing" },
  { label: "Strategic Partnerships", value: "Strategic Partnerships" },
  { label: "Strategic Planning", value: "Strategic Planning" },
  { label: "Strategic Procurement", value: "Strategic Procurement" },
  { label: "Strategic Sourcing", value: "Strategic Sourcing" },
  { label: "Strategy Consulting", value: "Strategy Consulting" },
  { label: "Structural Engineering", value: "Structural Engineering" },
  { label: "Supply Chain Analytics", value: "Supply Chain Analytics" },
  { label: "Supply Chain Management", value: "Supply Chain Management" },
  { label: "Sustainability Consulting", value: "Sustainability Consulting" },
  {
    label: "Nonprofit Management and Fundraising",
    value: "Nonprofit Management and Fundraising",
  },
  {
    label: "Occupational Health, Safety, and Wellness",
    value: "Occupational Health, Safety, and Wellness",
  },
  {
    label: "Office Management and Administrative Support",
    value: "Office Management and Administrative Support",
  },
  {
    label: "Online Reputation Management and Digital PR",
    value: "Online Reputation Management and Digital PR",
  },
  {
    label: "Open Innovation and Co-creation",
    value: "Open Innovation and Co-creation",
  },
  {
    label: "Operations Analysis and Optimization",
    value: "Operations Analysis and Optimization",
  },
  {
    label: "Organizational Change Management Consulting",
    value: "Organizational Change Management Consulting",
  },
  {
    label: "Outdoor Advertising and Media Solutions",
    value: "Outdoor Advertising and Media Solutions",
  },
  {
    label: "Packaging Design and Engineering",
    value: "Packaging Design and Engineering",
  },
  {
    label: "Partnering, Alliances, and Joint Ventures",
    value: "Partnering, Alliances, and Joint Ventures",
  },
  {
    label: "Patent, Trademark, and Intellectual Property",
    value: "Patent, Trademark, and Intellectual Property",
  },
  {
    label: "Payroll Administration and Compliance",
    value: "Payroll Administration and Compliance",
  },
  {
    label: "Performance Management and Compensation",
    value: "Performance Management and Compensation",
  },
  {
    label: "Philanthropy, Grants, and Corporate Social Impact",
    value: "Philanthropy, Grants, and Corporate Social Impact",
  },
  {
    label: "Photogrammetry, Remote Sensing, and GIS",
    value: "Photogrammetry, Remote Sensing, and GIS",
  },
  {
    label: "Physical Security and Asset Protection",
    value: "Physical Security and Asset Protection",
  },
  {
    label: "Plant Engineering and Facility Management",
    value: "Plant Engineering and Facility Management",
  },
  {
    label: "Policy Development and Regulatory Compliance",
    value: "Policy Development and Regulatory Compliance",
  },
  {
    label: "Portfolio Management and Investment Strategy",
    value: "Portfolio Management and Investment Strategy",
  },
  {
    label: "Predictive Analytics and Forecasting",
    value: "Predictive Analytics and Forecasting",
  },
  {
    label: "Pricing Strategy and Revenue Management",
    value: "Pricing Strategy and Revenue Management",
  },
  {
    label: "Print Production and Graphic Design",
    value: "Print Production and Graphic Design",
  },
  {
    label: "Procurement, Sourcing, and Supplier Management",
    value: "Procurement, Sourcing, and Supplier Management",
  },
  {
    label: "Process Improvement and Optimization",
    value: "Process Improvement and Optimization",
  },
  {
    label: "Product Development and Innovation",
    value: "Product Development and Innovation",
  },
  {
    label: "Product Management and Roadmapping",
    value: "Product Management and Roadmapping",
  },
  {
    label: "Product Marketing and Go-to-Market Strategy",
    value: "Product Marketing and Go-to-Market Strategy",
  },
  {
    label: "Product Operations and Supply Chain Integration",
    value: "Product Operations and Supply Chain Integration",
  },
  {
    label: "Product Planning and Strategic Alignment",
    value: "Product Planning and Strategic Alignment",
  },
  {
    label: "Production Engineering and Manufacturing",
    value: "Production Engineering and Manufacturing",
  },
  {
    label: "Production Scheduling and Capacity Planning",
    value: "Production Scheduling and Capacity Planning",
  },
  {
    label: "Program and Portfolio Management",
    value: "Program and Portfolio Management",
  },
  {
    label: "Project Accounting and Financial Controls",
    value: "Project Accounting and Financial Controls",
  },
  {
    label: "Project Controls and Cost Management",
    value: "Project Controls and Cost Management",
  },
  {
    label: "Project Financing and Investment",
    value: "Project Financing and Investment",
  },
  {
    label: "Project Management and Execution",
    value: "Project Management and Execution",
  },
  {
    label: "Property Management and Real Estate Investment",
    value: "Property Management and Real Estate Investment",
  },
  {
    label: "Proposal Management and Bid Support",
    value: "Proposal Management and Bid Support",
  },
  {
    label: "Public Affairs and Government Relations",
    value: "Public Affairs and Government Relations",
  },
  {
    label: "Public Health and Community Outreach",
    value: "Public Health and Community Outreach",
  },
  {
    label: "Public Policy and Regulatory Affairs",
    value: "Public Policy and Regulatory Affairs",
  },
  {
    label: "Public-Private Partnerships and Collaboration",
    value: "Public-Private Partnerships and Collaboration",
  },
  {
    label: "Quality Assurance and Quality Management",
    value: "Quality Assurance and Quality Management",
  },
  {
    label: "Quality Control and Inspection",
    value: "Quality Control and Inspection",
  },
  {
    label: "Real Estate Development and Construction",
    value: "Real Estate Development and Construction",
  },
  {
    label: "Real Estate Investment and Asset Management",
    value: "Real Estate Investment and Asset Management",
  },
  {
    label: "Regulatory Affairs and Compliance Management",
    value: "Regulatory Affairs and Compliance Management",
  },
  {
    label: "Rehabilitation Engineering and Assistive Tech",
    value: "Rehabilitation Engineering and Assistive Tech",
  },
  {
    label: "Renewable Energy and Sustainability Solutions",
    value: "Renewable Energy and Sustainability Solutions",
  },
  {
    label: "Rental and Leasing Operations Management",
    value: "Rental and Leasing Operations Management",
  },
  {
    label: "Research and Development (R&D) Management",
    value: "Research and Development (R&D) Management",
  },
  {
    label: "Reservoir Engineering and Geological Modeling",
    value: "Reservoir Engineering and Geological Modeling",
  },
  {
    label: "Resourcing, Talent Acquisition, and HR Information Systems",
    value: "Resourcing, Talent Acquisition, and HR Information Systems",
  },
  {
    label: "Retail Operations and Customer Experience",
    value: "Retail Operations and Customer Experience",
  },
  {
    label: "Revenue Management and Pricing Optimization",
    value: "Revenue Management and Pricing Optimization",
  },
  {
    label: "Risk Analysis and Enterprise Risk Management",
    value: "Risk Analysis and Enterprise Risk Management",
  },
  {
    label: "Robotics and Automation Engineering",
    value: "Robotics and Automation Engineering",
  },
  {
    label: "Sales and Channel Management Strategy",
    value: "Sales and Channel Management Strategy",
  },
  {
    label: "Sales Enablement and Performance Optimization",
    value: "Sales Enablement and Performance Optimization",
  },
  {
    label: "Sales Operations and Business Intelligence",
    value: "Sales Operations and Business Intelligence",
  },
  {
    label: "Sales Strategy, Planning, and Execution",
    value: "Sales Strategy, Planning, and Execution",
  },
  {
    label: "Sanitation, Hygiene, and Infection Control",
    value: "Sanitation, Hygiene, and Infection Control",
  },
  {
    label: "Security and Asset Protection Solutions",
    value: "Security and Asset Protection Solutions",
  },
  {
    label: "Service Delivery and Operations Management",
    value: "Service Delivery and Operations Management",
  },
  {
    label: "Service Design and User Experience",
    value: "Service Design and User Experience",
  },
  {
    label: "Service Level Management and Monitoring",
    value: "Service Level Management and Monitoring",
  },
  {
    label: "Service Operations and Continuous Improvement",
    value: "Service Operations and Continuous Improvement",
  },
  {
    label: "Service Strategy and Portfolio Management",
    value: "Service Strategy and Portfolio Management",
  },
  {
    label: "Shop Floor Management and Production Control",
    value: "Shop Floor Management and Production Control",
  },
  {
    label: "Six Sigma, Lean, and Process Improvement",
    value: "Six Sigma, Lean, and Process Improvement",
  },
  {
    label: "Social Impact Investing and Sustainable Finance",
    value: "Social Impact Investing and Sustainable Finance",
  },
  {
    label: "Social Media Marketing and Digital Engagement",
    value: "Social Media Marketing and Digital Engagement",
  },
  {
    label: "Social Responsibility and Corporate Citizenship",
    value: "Social Responsibility and Corporate Citizenship",
  },
  {
    label: "Software Development and Engineering",
    value: "Software Development and Engineering",
  },
  {
    label: "Solar Energy Systems Design and Integration",
    value: "Solar Energy Systems Design and Integration",
  },
  {
    label: "Space Planning, Interior Design, and Workplace Experience",
    value: "Space Planning, Interior Design, and Workplace Experience",
  },
  {
    label: "Spatial Data Management and GIS",
    value: "Spatial Data Management and GIS",
  },
  {
    label: "Stakeholder Management and Engagement",
    value: "Stakeholder Management and Engagement",
  },
  {
    label: "Strategic Alliances and Partnerships",
    value: "Strategic Alliances and Partnerships",
  },
  {
    label: "Strategic Communications and Branding",
    value: "Strategic Communications and Branding",
  },
  {
    label: "Strategic Foresight and Scenario Planning",
    value: "Strategic Foresight and Scenario Planning",
  },
  {
    label: "Strategic Human Resources and Talent Management",
    value: "Strategic Human Resources and Talent Management",
  },
  {
    label: "Strategic Initiatives and Transformation",
    value: "Strategic Initiatives and Transformation",
  },
  {
    label: "Strategic Marketing and Go-to-Market Planning",
    value: "Strategic Marketing and Go-to-Market Planning",
  },
  {
    label: "Strategic Procurement and Supply Chain Strategy",
    value: "Strategic Procurement and Supply Chain Strategy",
  },
  {
    label: "Strategic Sourcing and Supplier Relationship Management",
    value: "Strategic Sourcing and Supplier Relationship Management",
  },
  {
    label: "Strategy Consulting and Business Advisory",
    value: "Strategy Consulting and Business Advisory",
  },
  {
    label: "Structural Engineering and Design",
    value: "Structural Engineering and Design",
  },
  { label: "Supply Chain Analytics", value: "Supply Chain Analytics" },
];

module.exports = {
  designation,
  department,
};
