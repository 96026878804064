import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Users,
  UserCircle,
  GitFork,
  Envelope,
  LockOpen,
  CalendarBlank,
  EyeSlash,
  Eye,
} from "@phosphor-icons/react";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import debounce from "lodash.debounce";
import "react-phone-input-2/lib/style.css";
import { authapi } from "../config/serverUrl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { IoMdCheckmarkCircle } from "react-icons/io";
import SignUpTimer from "../utils/signupTimer";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { loginUser } from "../react-redux/actions";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import country from "./country.json";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";

const DemoRegister = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();

  const [DisableSubmit, setDisableSubmit] = useState(null);
  const [TimerStart, setTimerStart] = useState(null);

  const inintalvalue = {
    email: "",
    password: "",
    name: "",
    user_name: "",
    fathername: "",
    gender: "",
    familyname: "",
    confrim_password: "",
    dob: "",
    profile_name: "",
    marital_status: "",
  };
  const [usernameStatus, setUsernameStatus] = useState("");
  const [data, setdata] = useState(inintalvalue);
  const [formErrors, setFormErrors] = useState({});
  const [inputRefs, setInputRefs] = useState([]);
  const [error, setError] = useState("");
  const [valid, setvalid] = useState(true);
  const [afterRes, setafterRes] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showresetPassword, setShowresetPassword] = useState(false);
  // Mobile useState
  const [mobile_no, setmobile_no] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [touchedFields, setTouchedFields] = useState({
    password: false,
    confirm_password: false,
    name: false,
    user_name: false,
    fathername: false,
    familyname: false,
    email: false,
    mobile_no: false,
    dob: false,
    gender: false,
    profile_name: false,
    marital_status: false,
  });
  // OTP useState
  const bottomRef = useRef(null);

  const [timeOTP, settimeOTP] = useState("");
  const [remainingTime, setRemainingTime] = useState(120);
  const [arrowbutton, setarrowbutton] = useState(false);
  const navigate = useNavigate();

  console.log("Current data state:", data);

  const [hasLoader, setHasLoader] = useState(false);

  const [countryCode, setCountryCode] = useState("IND");

  useEffect(() => {
    setInputRefs((inputRefs) =>
      Array(6)
        .fill()
        .map((_, i) => inputRefs[i] || React.createRef())
    );
  }, []);

  const handlemobileno = (value, country) => {
    setmobile_no(value);
    setvalid(validationmobileno(value));
    setMobileError("");

    const datavaklue = getCountryName(country.dialCode);

    setCountryCode(datavaklue);
  };

  const getCountryName = (code) => {
    return country[code] || "Country not found";
  };

  const validationmobileno = (phonenumber) => {};

  useEffect(() => {
    let intervalId;
    if (timeOTP && remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timeOTP, remainingTime]);

  const handlesignup = (e) => {
    const { id, value, type, name } = e.target;

    if (type === "radio") {
      setdata((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setdata((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const checkUsernameAvailability = async (username) => {
    try {
      const response = await axios.get(`${authapi}/auth/check-username`, {
        params: { username },
      });
      return !response.data.exists;
    } catch (error) {
      console.error("Error checking username availability:", error);
      return false;
    }
  };

  const debouncedCheckUsernameAvailability = debounce(async (username) => {
    const isAvailable = await checkUsernameAvailability(username);
    if (isAvailable) {
      setFormErrors((prevErrors) => ({ ...prevErrors, user_name: "" }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        user_name: "Username already taken",
      }));
    }
  }, 300);

  const handleUserNameChange = async (event) => {
    const { value } = event.target;

    // Remove whitespace
    const trimmedValue = value.replace(/\s+/g, "");

    // Remove invalid characters (anything that's not a letter, number, dot, or underscore)
    const sanitizedValue = trimmedValue.replace(/[^a-zA-Z0-9._]/g, "");

    // Check if the username exceeds the max length of 30 characters
    if (sanitizedValue.length > 30) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        user_name: "Username can be a max of 30 characters.",
      }));
      setUsernameStatus(""); // Reset username status
      return; // Exit the function if the username is too long
    }

    setdata({ ...data, user_name: sanitizedValue });
    if (sanitizedValue.length >= 8) {
      debouncedCheckUsernameAvailability(sanitizedValue);
      setFormErrors((prevErrors) => ({ ...prevErrors, user_name: "" }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        user_name: "Username must be at least 8 characters long.",
      }));
      setUsernameStatus("");
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    setTouchedFields({
      password: true,
      confirm_password: true,
      name: true,
      user_name: true,
      fathername: true,
      familyname: true,
      grandfathername: true,
      email: true,
      mobile_no: true,
      dob: true,
      gender: true,
      profile_name: true,
      marital_status: true,
    });

    const errors = validate(data);

    if (mobile_no.length === 0) {
      setMobileError("Required Field!");
      errors.mobile_no = "Required Field!";
    } else {
      setMobileError("");
    }

    setFormErrors(errors);

    // const value1 = otp.join("");

    if (data.user_name.length < 8) {
      errors.user_name = "Username must be at least 8 characters long";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const isAvailable = await checkUsernameAvailability(data.user_name);
    if (!isAvailable) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        user_name: "Username already taken",
      }));
      return;
    }

    setHasLoader(true);
    setarrowbutton(true);

    const firstLetterName = data.name.charAt(0).toUpperCase();
    const firstLetterFatherName = data.fathername.charAt(0).toUpperCase();
    const firstLetterGrandfatherName = data.familyname.charAt(0).toUpperCase();
    const concatenatedLetters =
      firstLetterName +
      firstLetterFatherName +
      firstLetterGrandfatherName +
      countryCode;

    try {
      const res = await axios.post(`${authapi}/auth/signup`, {
        ...data,
        mobile_no: mobile_no,
        kid_id: concatenatedLetters,
      });

      const data1 = res.data;
      console.log(data1, "data1");
      settimeOTP(true);
      if (data1.code === "200") {
        setTimerStart(true);
        setafterRes(1);
        setHasLoader(false);
        setDisableSubmit(true);
        setError("");
      } else if (data1.code === "409") {
        setError("User already exists");
        setHasLoader(false);
      } else if (data1.code === "422") {
        setMobileError("User already exists");
        setHasLoader(false);
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Server responded with status code:",
          error.response.status
        );
        console.error("Response data:", error.response.data);
      }
    }
  };

  const schema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^[0-9]{6}$/, "Invalid OTP. Must be 6 digits.")
      .required("OTP is required"),
  });

  const initialValues = {
    otp: "",
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.name) {
      errors.name = "Required Field!";
    }
    if (!values.fathername) {
      errors.fathername = "Required Field!";
    }
    if (!values.user_name) {
      errors.user_name = "Required Field!";
    }
    if (!values.familyname) {
      errors.familyname = "Required Field!";
    }
    if (!values.dob) {
      errors.dob = "Required Field!";
    }
    if (!values.gender) {
      errors.gender = "Required Field!";
    }
    if (!values.profile_name) {
      errors.profile_name = "Required Field!";
    }
    if (!values.marital_status) {
      errors.marital_status = "Required Field!";
    }
    if (!values.email) {
      errors.email = "Required Field!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.password) {
      errors.password = "Required Field!";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 15) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    if (!values.confirm_password) {
      errors.confirm_password = "Required Field!";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Passwords do not match";
    }
    return errors;
  };

  const handleVerifys = async (values, { setSubmitting }) => {
    const res = await axios.post(`${authapi}/auth/verifyOtp`, {
      email: data.email,
      otp: values.otp,
    });

    if (res.data) {
      if (res.data) {
        if (res.data.code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your Account is Successfully Verified",
            showConfirmButton: false,
            timer: 2000,
          });
          dispatch(loginUser(res.data.data.user));
          setTimeout(() => {
            navigate("/");
          }, 1500);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.data.data.message,
            life: 3000,
          });
        }
      }
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const paragraphs = [
    <div>
      <div className="bottom" ref={bottomRef}>
        <h4>Kindly Provide your basic details</h4>
        <div className="row">
          <div className="col-md-4">
            <div className="">
              <div className="input-group mt-4 me-2">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{
                      borderColor:
                        touchedFields.name && formErrors.name ? "#F77256" : "",
                    }}
                  >
                    <UserCircle size={28} style={{ color: "#2196F3" }} />
                  </span>
                </div>
                <input
                  {...(DisableSubmit ? { readOnly: true } : {})}
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  onChange={handlesignup}
                  value={data.name}
                  style={{
                    borderColor:
                      touchedFields.name && formErrors.name ? "#F77256" : "",
                  }}
                />
              </div>
              {formErrors.name && (
                <p style={{ color: "#F77256" }}>{formErrors.name}</p>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div className="input-group mt-4">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{
                      borderColor:
                        touchedFields.fathername && formErrors.fathername
                          ? "#F77256"
                          : "",
                    }}
                  >
                    <Users size={28} style={{ color: "#2196F3" }} />
                  </span>
                </div>

                <input
                  {...(DisableSubmit ? { readOnly: true } : {})}
                  type="text"
                  className="form-control"
                  id="fathername"
                  placeholder="Father/Husband Name"
                  value={data.fathername}
                  onChange={handlesignup}
                  style={{
                    borderColor:
                      touchedFields.fathername && formErrors.fathername
                        ? "#F77256"
                        : "",
                  }}
                />
              </div>
              <p className="ms-2" style={{ color: "#F77256" }}>
                {formErrors.fathername}
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div className="input-group mt-4">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{
                      borderColor:
                        touchedFields.familyname && formErrors.familyname
                          ? "#F77256"
                          : "",
                    }}
                  >
                    <GitFork
                      size={28}
                      style={{ transform: "rotate(180deg)", color: "#2196F3" }}
                    />
                  </span>
                </div>
                <input
                  {...(DisableSubmit ? { readOnly: true } : {})}
                  type="text"
                  className="form-control"
                  id="familyname"
                  placeholder="Family/GrandFather Name"
                  value={data.familyname}
                  onChange={handlesignup}
                  style={{
                    borderColor:
                      touchedFields.familyname && formErrors.familyname
                        ? "#F77256"
                        : "",
                  }}
                />
              </div>
              <p style={{ color: "#F77256" }}>{formErrors.familyname}</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="DemoDate">
              <div className="input-group mt-4 me-2">
                <div className="d-flex w-100 w-full">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text "
                      style={{
                        borderRight: "0px",
                        height: "43px",
                        borderColor:
                          touchedFields.dob && formErrors.dob ? "#F77256" : "",
                      }}
                    >
                      <CalendarBlank style={{ color: "#2196F3" }} size={28} />
                    </span>
                  </div>

                  {/* 
                  <Calendar
                      id="dob"
                      className="datebirth w-100 date"
                      showIcon={false}
                      onChange={handlesignup}
                      name="dateOfBirth"
                      monthNavigator
                      placeholder="Date of Birth"
                      yearNavigator
                      yearRange="1500:2024"
                      dateFormat="dd/mm/yy"
                      value={data.dob}
                      inputId="in"
                      style={{
                        height: "43px",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        border: touchedFields.dob && formErrors.dob ? "2px solid #F77256 " : "",
                      }} 
                    /> */}
                  <Calendar
                    inputId="birth_date"
                    dateFormat="dd/mm/yy"
                    id="dob"
                    name="dateOfBirth"
                    className="datebirth  date"
                    value={data.dob}
                    onChange={handlesignup}
                    placeholder="Date of Birth"
                    style={{
                      height: "43px",
                      borderBottomRightRadius: "5px",
                      borderTopRightRadius: "5px",
                      width: "345px",
                      border:
                        touchedFields.dob && formErrors.dob
                          ? "2px solid #F77256 "
                          : "",
                    }}
                  />
                </div>
              </div>
              {formErrors.name && (
                <p style={{ color: "#F77256" }}>{formErrors.dob}</p>
              )}
            </div>
          </div>

          <div className="md:col-4 col-12 mt-1 md:mt-3">
            <h6 className="gender-font font-medium">Gender</h6>
            <div className="flex flex-wrap gap-3">
              <div className="flex align-items-center">
                <input
                  type="radio"
                  id="genderMale"
                  name="gender"
                  value="Male"
                  onChange={handlesignup}
                  checked={data.gender === "Male"}
                  class="form-check-input"
                />
                <label htmlFor="genderMale" className="ml-2 font-normal">
                  Male
                </label>
              </div>
              <div className="flex align-items-center">
                <input
                  type="radio"
                  id="genderFemale"
                  name="gender"
                  value="Female"
                  onChange={handlesignup}
                  checked={data.gender === "Female"}
                  class="form-check-input"
                />
                <label htmlFor="genderFemale" className="ml-2 font-normal">
                  Female
                </label>
              </div>
              <div className="flex align-items-center">
                <input
                  type="radio"
                  id="Others"
                  name="gender"
                  value="Others"
                  onChange={handlesignup}
                  checked={data.gender === "Others"}
                  class="form-check-input"
                />
                <label htmlFor="Others" className="ml-2 font-normal">
                  Others
                </label>
              </div>
            </div>
            {formErrors.gender && (
              <p style={{ color: "#F77256" }}>{formErrors.gender}</p>
            )}
          </div>

          <div className="md:col-4 col-12 mt-1 md:mt-3">
            <h6 className="gender-font font-medium">Marital Status</h6>
            <div className="flex flex-wrap gap-3">
              <div className="flex align-items-center">
                <input
                  type="radio"
                  id="Single"
                  name="marital_status"
                  value="Single"
                  onChange={handlesignup}
                  checked={data.marital_status === "Single"}
                  class="form-check-input"
                />
                <label htmlFor="Single" className="ml-2 font-normal">
                  Single
                </label>
              </div>
              <div className="flex align-items-center">
                <input
                  type="radio"
                  id="Married"
                  name="marital_status"
                  value="Married"
                  onChange={handlesignup}
                  checked={data.marital_status === "Married"}
                  class="form-check-input"
                />
                <label htmlFor="Married" className="ml-2 font-normal">
                  Married
                </label>
              </div>
            </div>
            {formErrors.marital_status && (
              <p style={{ color: "#F77256" }}>{formErrors.marital_status}</p>
            )}
          </div>
          <div className="col-md-4">
            <div className="">
              <div className="input-group mt-4">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{
                      borderColor:
                        touchedFields.profile_name && formErrors.profile_name
                          ? "#F77256"
                          : "",
                    }}
                  >
                    <UserCircle size={28} style={{ color: "#2196F3" }} />
                  </span>
                </div>
                <input
                  {...(DisableSubmit ? { readOnly: true } : {})}
                  type="text"
                  className="form-control"
                  id="profile_name"
                  placeholder="Profile Name"
                  value={data.profile_name}
                  onChange={handlesignup}
                  style={{
                    borderColor:
                      touchedFields.profile_name && formErrors.profile_name
                        ? "#F77256"
                        : "",
                  }}
                />
              </div>
              <p style={{ color: "#F77256" }}>{formErrors.profile_name}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div className="input-group mt-4">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{
                      borderColor:
                        touchedFields.email && formErrors.email
                          ? "#F77256"
                          : "",
                    }}
                  >
                    <Envelope style={{ color: "#2196F3" }} size={28} />
                  </span>
                </div>
                <input
                  {...(DisableSubmit ? { readOnly: true } : {})}
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder=" Enter your Email"
                  onChange={handlesignup}
                  value={data.email}
                  style={{
                    borderColor:
                      touchedFields.email && formErrors.email ? "#F77256" : "",
                  }}
                />
              </div>
              <p style={{ color: "#F77256" }}>{formErrors.email}</p>
              <p style={{ color: "#F77256" }}>{error}</p>
            </div>
          </div>
          <div className="col-md-4"></div>
          {/* <hr style ={{color:"#2196F3",fontSize:"10px",marginTop:"2px"}}></hr> */}
          <div className="col-md-4">
            <div className="">
              <div className="input-group mt-4 me-2">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{
                      borderColor:
                        touchedFields.user_name && formErrors.user_name
                          ? "#F77256"
                          : "",
                    }}
                  >
                    <UserCircle size={28} style={{ color: "#2196F3" }} />
                  </span>
                </div>
                <input
                  {...(DisableSubmit ? { readOnly: true } : {})}
                  type="text"
                  className="form-control"
                  id="user_name"
                  placeholder="User Name"
                  onChange={handleUserNameChange}
                  value={data.user_name}
                  style={{
                    borderColor:
                      touchedFields.user_name && formErrors.user_name
                        ? "#F77256"
                        : "",
                  }}
                />
              </div>
              {formErrors.user_name && (
                <p style={{ color: "#F77256" }}>{formErrors.user_name}</p>
              )}
              {usernameStatus && (
                <p style={{ color: "#F77256" }}>{usernameStatus}</p>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-4">
            <div className="input-group mt-4">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  style={{
                    borderColor:
                      touchedFields.password && formErrors.password
                        ? "#F77256"
                        : "",
                  }}
                >
                  <LockOpen style={{ color: "#2196F3" }} size={28} />
                </span>
              </div>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  {...(DisableSubmit ? { readOnly: true } : {})}
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="Create password"
                  onChange={handlesignup}
                  value={data.password}
                  // style={{ borderColor: formErrors.password ? 'red' : '' }}
                  style={{
                    borderColor:
                      touchedFields.password && formErrors.password
                        ? "#F77256"
                        : "",
                    height: "43px",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                />

                <span
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "28px",
                    zIndex: "1",
                    color: "#959595",
                  }}
                  className="password-toggle-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <Eye style={{ color: "#2196F3" }} size={22} />
                  ) : (
                    <EyeSlash style={{ color: "#2196F3" }} size={22} />
                  )}
                </span>
              </div>
            </div>
            <p style={{ color: "#F77256" }}>{formErrors.password}</p>
          </div>

          <div className="col-md-4">
            <div className="input-group mt-4">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  style={{
                    borderColor:
                      touchedFields.confirm_password &&
                      formErrors.confirm_password
                        ? "#F77256"
                        : "",
                  }}
                >
                  <LockOpen style={{ color: "#2196F3" }} size={28} />
                </span>
              </div>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  {...(DisableSubmit ? { readOnly: true } : {})}
                  type={showresetPassword ? "text" : "password"}
                  className="form-control"
                  id="confirm_password"
                  placeholder="Confirm password"
                  value={data.confirm_password}
                  onChange={handlesignup}
                  // style={{ borderColor: formErrors.confirm_password ? 'red' : '' }}
                  style={{
                    borderColor:
                      touchedFields.confirm_password &&
                      formErrors.confirm_password
                        ? "#F77256"
                        : "",
                    height: "43px",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "28px",
                    zIndex: "1",
                    color: "#959595",
                  }}
                  className="password-toggle-icon"
                  onClick={() => setShowresetPassword(!showresetPassword)}
                >
                  {showresetPassword ? (
                    <Eye style={{ color: "#2196F3" }} size={22} />
                  ) : (
                    <EyeSlash style={{ color: "#2196F3" }} size={22} />
                  )}
                </span>
              </div>
            </div>
            <p style={{ color: "#F77256" }}>{formErrors.confirm_password}</p>
          </div>
          <div className="col-md-4"></div>
          {/* <hr></hr> */}
          <div className="col-md-4">
            <div className="input-group mt-4">
              <PhoneInput
                country={"in"}
                value={mobile_no}
                onChange={handlemobileno}
                style={{
                  border:
                    touchedFields.mobile_no && mobileError
                      ? "2px solid #F77256"
                      : "",
                  borderRadius: "5px",
                }}
              />
            </div>
            {mobileError && <p style={{ color: "#F77256" }}>{mobileError}</p>}
          </div>

          <div
            className="d-flex justify-content-between col-md-4"
            style={{ marginTop: "1.6rem" }}
          >
            <div style={{ color: "#fff" }} className="">
              {!hasLoader ? (
                <button
                  disabled={DisableSubmit}
                  onClick={(e) => handlesubmit(e)}
                  className="btn btn-verify px-4"
                  type="submit"
                  style={{
                    backgroundColor: "#2196f3",
                    color: "white",
                  }}
                >
                  {afterRes == 0 ? (
                    "Get OTP"
                  ) : (
                    <IoMdCheckmarkCircle
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#2196F3",
                      }}
                    />
                  )}
                </button>
              ) : (
                <button
                  className="btn btn-verify px-4"
                  type="submit"
                  style={{
                    backgroundColor: "#2196f3",
                    color: "white",
                  }}
                >
                  <span class="loader"></span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>,
    <div></div>,
  ];

  return (
    <div>
      <Toast ref={toast} />
      <div className="Auth-form-container">
        <div className="Auth-forms">
          <div className="Auth-form-content">
            <div className="head d-flex">
              <Link to="/login" style={{ color: "#4A4A4A" }}>
                <ArrowBackIosIcon size={28} style={{ marginLeft: "30px" }} />
              </Link>

              <div className="d-flex justify-content-center w-100">
                <h5
                  className="text-center"
                  style={{ color: "#4A4A4A", marginLeft: "-91px" }}
                >
                  Create an Account
                </h5>
              </div>
            </div>
            <div className="content">
              {paragraphs[currentIndex]}
              {/* <button onClick={handleNextClick} disabled={currentIndex === paragraphs.length - 1}>
        Next
      </button> */}
              <h4 className="mt-5">Mobile Number Verification</h4>

              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleVerifys}
              >
                {({ errors }) => {
                  return (
                    <>
                      <Form>
                        <div className="row align-items-center mt-3">
                          <div className="col-lg-3 col-md-4">
                            <Field
                              type="text"
                              name="otp"
                              placeholder="Enter OTP"
                              style={{
                                borderLeft: "2px solid #e6e6e6",
                                padding: "9px",
                              }}
                              className={`form-control${
                                errors.otp && errors.otp ? "  is-invalid" : ""
                              }`}
                              maxLength={6}
                              onInput={(e) => {
                                if (e.target.value.length > 6) {
                                  e.target.value = e.target.value.slice(0, 6);
                                }
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                            />

                            <div className="d-md-none d-block">
                              <ErrorMessage
                                style={{ color: "red" }}
                                name="otp"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4">
                            <div className="mt-md-0 ">
                              <button
                                className="btn btn-verify px-4"
                                type="submit"
                                style={{
                                  backgroundColor: "#2196f3",
                                  color: "white",
                                }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          {TimerStart && (
                            <SignUpTimer
                              email={data.email}
                              mobile={mobile_no}
                            />
                          )}
                          <ErrorMessage
                            style={{ color: "red" }}
                            name="otp"
                            component="div"
                          />
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoRegister;