export const cities = [
  { name: "Select city" },
  { name: "Chennai" },
  { name: "Chengalpattu" },
  { name: "Ariyalur" },
  { name: "Coimbatore" },
  { name: "Cuddalore" },
  { name: "Dharmapuri" },
  { name: "Dindigul" },
  { name: "Erode" },
  { name: "Kallakurichi" },
  { name: "Kanchipuram" },
  { name: "Kanyakumari" },
  { name: "Karur" },
  { name: "Krishnagiri" },
  { name: "Madurai" },
  { name: "Nagapattinam" },
  { name: "Namakkal" },
  { name: "Nilgiris" },
  { name: "Perambalur" },
  { name: "Pudukkottai" },
  { name: "Ramanathapuram" },
  { name: "Ranipet" },
  { name: "Salem" },
  { name: "Sivaganga" },
  { name: "Tenkasi" },
  { name: "Thanjavur" },
  { name: "Theni" },
  { name: "Thoothukudi" },
  { name: "Tiruchirappalli" },
  { name: "Tirunelveli" },
  { name: "Tirupathur" },
  { name: "Tiruppur" },
  { name: "Tiruvallur" },
  { name: "Tiruvannamalai" },
  { name: "Tiruvarur" },
  { name: "Vellore" },
  { name: "Viluppuram" },
  { name: "Virudhunagar" },
];
