import React from 'react';
import { Dialog } from 'primereact/dialog';
import { IoVideocamOffOutline, IoVideocamOutline } from 'react-icons/io5';
import { CiMicrophoneOff, CiMicrophoneOn } from 'react-icons/ci';
import vcimg from '../assets/Icons/vcimage.jpg';
import { useState, useContext } from 'react';
import { MdOutlineCallEnd } from 'react-icons/md';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { v4 as uuidV4 } from 'uuid';
import { DataContext } from '../Admin/DataContext';
import { Nodeapi, ProfileImgApi } from '../config/serverUrl';

const AudioCallscreen = ({ Receiverid, setCallChatmasterid, setCallAccept, CallerDetail, profileimage }) => {
  const [visible, setVisible] = useState(false);
  const [mic, setMic] = useState(false);
  const [video, setVideo] = useState(false);

  const { id, chatmasterid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const { setVideoPage, CallRoomid, setAudioCall, CallfromUser, Callaccept, setCallaccept, calorname, handleSendCallmessage, callerprofile, setCallerprofile,CallStatus } = useContext(DataContext);
  const authdata = useSelector((state) => state.auth.user);
  const callerid = authdata?.id;
  const staticRoomId = CallRoomid;
  const [inCall, setInCall] = useState(false);
  const socketRef = useRef();
  const peerRef = useRef();
  const localStreamRef = useRef();
  const remoteStreamRef = useRef();
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [userId, setUserId] = useState(null);
  const [otherUserId, setOtherUserId] = useState('');
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);

  const ringtoneRef = React.useRef(null);

  useEffect(() => {
    let timer;
    ringtoneRef.current = new Audio('https://www.soundjay.com/phone/sounds/phone-calling-1.mp3');

    const playRingtone = () => {
      ringtoneRef.current.currentTime = 0;
      ringtoneRef.current.play().catch(error => {
        console.error('Error playing ringtone:', error);
      });
    };

    if (!Callaccept) {
      playRingtone();
      ringtoneRef.current.addEventListener('ended', playRingtone);

      timer = setTimeout(() => {}, 28000);
    }

    return () => {
      if (ringtoneRef.current) {
        ringtoneRef.current.pause();
        ringtoneRef.current.removeEventListener('ended', playRingtone);
      }
      clearTimeout(timer);
    };
  }, [Callaccept]);

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_VIDEOAPI);

    socketRef.current.on('receive_message', data => {
      console.log('receive_message', data);
    });
    socketRef.current.on('user-connected', handleUserConnected);
    socketRef.current.on('call-offer', handleCallOffer);
    socketRef.current.on('offer', handleReceiveOffer);
    socketRef.current.on('answer', handleAnswer);
    socketRef.current.on('ice-candidate', handleNewICECandidateMsg);
    socketRef.current.on('hang-up', handleRemoteHangUp);

    joinRoom();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const joinRoom = async () => {
    const newUserId = uuidV4();
    setUserId(newUserId);

    const res = await axios.get(`${Nodeapi}/getuser?id=${Receiverid}`);
    await startLocalStream();
    socketRef.current.emit('join-room', staticRoomId, authdata?.id, CallStatus, Receiverid, authdata?.name, "audio", "", '');
    setInCall(true);
  };

  const handleCallOffer = async (data) => {
    console.log('call offer', data);
  }

  const startLocalStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      setLocalStream(stream);
      localStreamRef.current = stream;
    } catch (error) {
      console.error('Error accessing media devices.', error);
      alert('Could not start video source. Please check your camera and microphone permissions.');
    }
  };

  const createPeerConnection = () => {
    const configuration = {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302'
        }
      ]
    };

    const peerConnection = new RTCPeerConnection(configuration);

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketRef.current.emit('ice-candidate', { roomId: staticRoomId, candidate: event.candidate });
      }
    };

    peerConnection.ontrack = (event) => {
      if (event.streams && event.streams[0]) {
        setRemoteStream(event.streams[0]);
        remoteStreamRef.current = event.streams[0];
      }
    };

    localStreamRef.current.getTracks().forEach(track => {
      peerConnection.addTrack(track, localStreamRef.current);
    });

    return peerConnection;
  };

  const callUser = async (userId) => {
    peerRef.current = createPeerConnection();

    const offer = await peerRef.current.createOffer();
    await peerRef.current.setLocalDescription(new RTCSessionDescription(offer));

    socketRef.current.emit('offer', { roomId: staticRoomId, sdp: offer, userId });
  };

  const handleReceiveOffer = async (data) => {
    peerRef.current = createPeerConnection();
    await peerRef.current.setRemoteDescription(new RTCSessionDescription(data.sdp));

    const answer = await peerRef.current.createAnswer();
    await peerRef.current.setLocalDescription(new RTCSessionDescription(answer));

    socketRef.current.emit('answer', { roomId: staticRoomId, sdp: answer, userId: data.userId });
  };

  const handleAnswer = async (data) => {
    try {
      const peerConnection = peerRef.current;

      if (peerConnection.signalingState !== 'stable') {
        await peerConnection.setRemoteDescription(new RTCSessionDescription(data.sdp));
        console.log('Remote description set successfully');
      } else {
        console.log('Attempted to set remote description in an incorrect state:');
      }
    } catch (error) {
      console.log('Failed to set remote description:', error);
    }
  };

  const handleNewICECandidateMsg = async (data) => {
    try {
      setCallaccept(true);
      console.log('ice-candidate', data);

      if (!peerRef.current) {
        throw new Error('RTCPeerConnection is not initialized.');
      }

      const candidate = new RTCIceCandidate(data.candidate);
      await peerRef.current.addIceCandidate(candidate);
      console.log('Added ICE candidate:', candidate);
    } catch (error) {
      console.log('Error handling new ICE candidate:', error);
    }
  };

  const handleUserConnected = (userId) => {
    console.log(`User connected: ${userId}`);
    callUser(userId);
  };

  const handleToggleAudio = () => {
    const audioTracks = localStreamRef.current.getAudioTracks();
    audioTracks.forEach(track => {
      track.enabled = !track.enabled;
    });
    setAudioMuted(!audioMuted);
  };

  const handleHangUp = () => {
    if (peerRef.current) {
      peerRef.current.close();
      peerRef.current = null;
    }
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => track.stop());
      localStreamRef.current = null;
    }
    if (remoteStreamRef.current) {
      remoteStreamRef.current.getTracks().forEach(track => track.stop());
      remoteStreamRef.current = null;
    }
    setVideoPage(false);
    setLocalStream(null);
    setRemoteStream(null);
    setInCall(false);
    socketRef.current.emit('hang-up', { roomId: staticRoomId });
    socketRef.current.emit('hang-up', { roomId: staticRoomId, callerid });

    setAudioCall(false);
    setCallChatmasterid(null);
    setCallAccept(false);
    setCallaccept(false);
    handleSendCallmessage(authdata?.id, Receiverid, formatTime(time), staticRoomId, "AudiocallRequest");
  };

  const handleRemoteHangUp = (data) => {
    console.log('hang up data', data);
    if (peerRef.current) {
      peerRef.current.close();
      peerRef.current = null;
    }
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => track.stop());
      localStreamRef.current = null;
    }
    if (remoteStreamRef.current) {
      remoteStreamRef.current.getTracks().forEach(track => track.stop());
      remoteStreamRef.current = null;
    }
    setLocalStream(null);
    setRemoteStream(null);
    setInCall(false);

    setAudioCall(false);
    setCallChatmasterid(null);
    setCallAccept(false);
    setCallaccept(false);

    if (data.userId == authdata?.id || data?.outsidecalldecline == "1") {
      handleSendCallmessage(authdata?.id, data.userId, "00:00", staticRoomId, "AudiocallRequest");
    }
  };

  const getuser = async () => {
    const res = await axios.get(`${Nodeapi}/getUserProfile?id=${CallerDetail?.data?.receivercallid.replace(/'/g, '')}`);
    setCallerprofile(res.data.data.message.profile_image);
  }

  const [time, setTime] = useState(0);
  useEffect(() => {
    let timer;
    if (Callaccept) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [Callaccept]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <div>
      <Dialog
        className="ch-audio"
        visible={true}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        showHeader={false}
        onHide={() => setVisible(false)}
      >
        <div className="ch-audiocall">
          <img src={vcimg} alt="" className="ch-mecall" />
          <div>
            <audio
              className="ch-mecall"
              ref={(audio) => {
                if (audio && localStream && !audio.srcObject) {
                  audio.srcObject = localStream;
                  audio.play().catch(error => {
                    console.error('Error playing local audio:', error);
                  });
                }
              }}
              muted
            ></audio>

            <audio
              className="ch-ouraudio"
              ref={(audio) => {
                if (audio && remoteStream && !audio.srcObject) {
                  audio.srcObject = remoteStream;
                  audio.play().catch(error => {
                    console.error('Error playing remote audio:', error);
                  });
                }
              }}
            ></audio>
          </div>

          <div className="ch-audio-controls">
            <button className="ch-audio-cont" onClick={handleToggleAudio}>
              {!audioMuted ? <CiMicrophoneOn /> : <CiMicrophoneOff />}
            </button>

            <button className="ch-audio-cont ch-audio-red" onClick={handleHangUp}>
              <MdOutlineCallEnd />
            </button>
          </div>
          <div className="ch-audio-name">
            <center>
              <img src={CallfromUser == 0 ? `${ProfileImgApi}/${callerprofile}` : profileimage} alt="" className="ch-audio-avatar" />
            </center>
            <h3 className="ch-audio-text">{CallerDetail && CallfromUser == 0 ? CallerDetail.data.callername : calorname}</h3>
            <p className="ch-audio-time">{Callaccept ? formatTime(time) : 'calling...'}</p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AudioCallscreen;
