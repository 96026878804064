import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  FetchPostDetails,
  addPostDetails,
  updatePostDetails,
} from "../../routes/profileRoutes";
import "react-toastify/dist/ReactToastify.css";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Nodeapi, Assetsapi } from "../../config/serverUrl";
import { MdDriveFolderUpload, MdRemoveCircleOutline } from "react-icons/md";
import axios from "axios";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Dropdown } from "primereact/dropdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
  overflowY: "scroll",
  width: "19rem",
};

const AdvertisingDashboard = ({
  open,
  handleClose,
  fetchPostCreated,
  createdPost,
  setCreatedPost,
}) => {
  const [postDetails, setPostDetails] = useState([]);
  const [data, setData] = useState({
    title: "",
    category: "",
    location: "",
    description: "",
  });
  const [selectedPostDetail, setSelectedPostDetail] = useState({});
  const [additionalImages, setAdditionalImages] = useState([]);
  const [mobile_no, setMobile_no] = useState("");
  const [selectedSalaryCurrency, setSelectedSalaryCurrency] = useState("INR");
  const [salary, setSalary] = useState("");
  const [listimages, setlistimages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [errors, setErrors] = useState({
    title: "",
    location: "",
    description: "",
    salary: "",
    mobile_no: "",
    category: "",
  });
  const menuLeft = useRef(null);
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;
  const username = authdata?.name;
  const kid_id = authdata?.userid;

  const [currencySymbols] = useState([
    { symbol: "₹", code: "INR" },
    { symbol: "$", code: "USD" },
    { symbol: "£", code: "GBP" },
    { symbol: "€", code: "EUR" },
    { symbol: "¥", code: "JPY" },
    { symbol: "₽", code: "RUB" },
    { symbol: "R", code: "ZAR" },
  ]);

  const clearForm = () => {
    setData({ title: "", location: "", description: "" });
    setMobile_no("");
    setSalary("");
    setSelectedSalaryCurrency("INR");
    setUploadedImages([]);
    setlistimages([]);
    setErrors({
      title: "",
      location: "",
      description: "",
      salary: "",
      mobile_no: "",
      category: "",
    });
  };

  const fetchData = useCallback(async () => {
    try {
      const postDetailsResponse = await FetchPostDetails(userId);
      const mergedPostDetails = postDetailsResponse.reduce((acc, current) => {
        const {
          post_title,
          post_description,
          post_location,
          post_price,
          mobile_no,
          salaryCurrency,
          image_path,
          post_category,
          post_id,
          username,
          userid,
          kid_id,
          isActive,
        } = current;

        const existingIndex = acc.findIndex(
          (item) =>
            item.post_title === post_title &&
            item.post_description === post_description &&
            item.post_category === post_category &&
            item.post_location === post_location &&
            item.post_price === post_price &&
            item.mobile_no === mobile_no &&
            item.salaryCurrency === salaryCurrency &&
            item.userid === userid &&
            item.post_id === post_id &&
            item.username === username &&
            item.kid_id === kid_id &&
            item.isActive === isActive
        );

        if (existingIndex !== -1) {
          acc[existingIndex].image_paths.push(image_path);
        } else {
          acc.push({
            post_title,
            post_description,
            post_category,
            post_location,
            post_price,
            mobile_no,
            salaryCurrency,
            userid,
            username,
            post_id,
            kid_id,
            isActive,
            image_paths: [image_path],
          });
        }

        return acc;
      }, []);

      setPostDetails(mergedPostDetails);
    } catch (error) {
      console.error("Failed to fetch job details:", error);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);

    const newErrors = {};
    if (!data.title) newErrors.title = "Title is required";
    if (!data.location) newErrors.location = "Location is required";
    if (!data.description) newErrors.description = "Description is required";
    if (!salary) newErrors.salary = "Salary is required";
    if (!mobile_no) newErrors.mobile_no = "Mobile number is required";
    if (!data.category) newErrors.category = "Category is required";
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setIsSubmitting(false);
      return;
    }
    const allImages = [...uploadedImages, ...additionalImages];
    const totalFileSize = allImages.reduce((acc, image) => acc + image.size, 0);
    const maxFileSize = 8 * 1024 * 1024;
    const maxFileCount = 10;

    if (allImages.length > maxFileCount) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Too many files",
        text: `You can upload a maximum of ${maxFileCount} files.`,
      });
      setIsSubmitting(false);
      return;
    }

    if (totalFileSize > maxFileSize) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "File size limit exceeded",
        text: `Total file size must be less than ${
          maxFileSize / (1024 * 1024)
          } MB.`,
      });
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    [...uploadedImages, ...additionalImages].forEach((image) =>
      formData.append("images", image.filePath)
    );
    formData.append("post_title", data.title);
    formData.append("post_description", data.description);
    formData.append("post_location", data.location);
    formData.append("post_category", data.category);
    formData.append("post_price", salary);
    formData.append("mobile_no", mobile_no);
    formData.append("username", username);
    formData.append("userid", userId);
    formData.append("kid_id", kid_id);
    formData.append("salaryCurrency", selectedSalaryCurrency);

    const timeout = setTimeout(() => {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Request Timeout",
        text: "The server is taking too long to respond. Please try again later.",
      });
      setIsSubmitting(false);
    }, 10000);

    try {
      if (isEditMode) {
        await updatePostDetails(selectedPostDetail.post_id, formData);
        clearTimeout(timeout);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Post updated Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        await addPostDetails(formData);
        clearTimeout(timeout);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Post Added Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      setCreatedPost(true);
      fetchPostCreated();
      handleClose();
    } catch (error) {
      clearTimeout(timeout);
      console.error("Failed to add/update post:", error);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Failed to add/update post",
        text: error.message || "Something went wrong",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      filePath: file,
      documentName: file.name,
    }));
    setUploadedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleRemove = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleAddMoreImage = (event) => {
    const files = Array.from(event.target.files);
    const newupdateImages = files.map((file) => ({
      filePath: file,
      documentName: file.name,
    }));
    setAdditionalImages([...additionalImages, ...newupdateImages]);
  };

  const handleuploadRemove = async (index, fileName) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        setlistimages((prevImages) => prevImages.filter((_, i) => i !== index));

        await axios.post(`${Nodeapi}/adpost/removeimage`, {
          file_name: fileName,
        });
      }
    } catch (error) {
      console.error("Error deleting image from backend:", error);
    }
  };

  const imageUploadSection = (
    <>
      <div className="col-12 mt-md-0">
        <div className="d-flex flex-wrap">
          {uploadedImages.map((image, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <img
                src={URL.createObjectURL(image.filePath)}
                alt={image.documentName}
                style={{
                  width: "126px",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemove(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  minWidth: 0,
                  padding: "5px",
                }}
              >
                <MdRemoveCircleOutline />
              </Button>
            </div>
          ))}
          <label
            htmlFor="upload-photo"
            className="upload-card p-0"
            style={{
              border: "1px dashed #68bcff",
              borderRadius: "10px",
              background: "#eff5fc",
              width: "7rem",
              height: "6rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "-7px",
              cursor: "pointer",
            }}
          >
            <input
              hidden
              multiple
              type="file"
              id="upload-photo"
              onChange={handleUpload}
            />
            <div
              className="icon"
              style={{
                fontSize: "1.5rem",
                marginRight: "0.5rem",
                color: "#2196f3",
              }}
            >
              <MdDriveFolderUpload />
            </div>
            <div className="overlay">
              <p style={{ color: "#2196f3" }}>Add</p>
            </div>
          </label>
        </div>
      </div>
    </>
  );

  const addmorimage = (
    <>
      <Button component="label" sx={{ height: 60 }}>
        <MdDriveFolderUpload
          style={{
            fontSize: "10px",
            marginRight: "0.5rem",
            border: "1px dashed #68bcff",
            borderRadius: "10px",
            background: "#eff5fc",
            width: "4rem",
            height: "3rem",
            marginLeft: "-479px",
            cursor: "pointer",
          }}
        />

        <input
          hidden
          multiple
          type="file"
          onChange={handleAddMoreImage}
        />
      </Button>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        {additionalImages.map((image, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            <img
              src={URL.createObjectURL(image.filePath)}
              alt={image.documentName}
              style={{
                width: "126px",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemove(index)}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                minWidth: 0,
                padding: "5px",
              }}
            >
              <MdRemoveCircleOutline />
            </Button>
          </div>
        ))}
      </div>
    </>
  );

  const postCategory = [
    {
      label: "Electronics",
      code: "DE",
      items: [
        { label: "Mobile Phones", value: "Mobile Phones" },
        { label: "Computers & Tablets", value: "Computers & Tablets" },
        { label: "Cameras & Photography", value: "Cameras & Photography" },
        { label: "TV, Audio & Video", value: "TV, Audio & Video" },
        { label: "Wearable Technology", value: "Wearable Technology" },
      ],
    },
    {
      label: "Vehicles",
      code: "US",
      items: [
        { label: "Cars", value: "Cars" },
        { label: "Motorcycles", value: "Motorcycles" },
        { label: "Trucks", value: "Trucks" },
        { label: "Bicycles", value: "Bicycles" },
        { label: "Boats", value: "Boats" },
      ],
    },
    {
      label: "Fashion & Beauty",
      code: "JP",
      items: [
        { label: "Clothing", value: "Clothing" },
        { label: "Shoes", value: "Shoes" },
        { label: "Accessories", value: "Accessories" },
        {
          label: "Health & Beauty Products",
          value: "Health & Beauty Products",
        },
        { label: "Jewelry", value: "Jewelry" },
      ],
    },
    {
      label: "Real Estate",
      code: "JP",
      items: [
        { label: "Houses for Sale", value: "Houses for Sale" },
        { label: "Apartments for Rent", value: "Apartments for Rent" },
        { label: "Commercial Properties", value: "Commercial Properties" },
        { label: "Land for Sale", value: "Land for Sale" },
        { label: "Vacation Rentals", value: "Vacation Rentals" },
      ],
    },

    {
      label: "Home & Garden",
      code: "JP",
      items: [
        { label: "Furniture", value: "Furniture" },
        { label: "Home Appliances", value: "Home Appliances" },
        { label: "Tools & DIY", value: "Tools & DIY" },
        { label: "Garden & Outdoor", value: "Garden & Outdoor" },
        { label: "Vacation Rentals", value: "Vacation Rentals" },
      ],
    },
    {
      label: "Sports & Outdoors",
      code: "JP",
      items: [
        { label: "Fitness Equipment", value: "Fitness Equipment" },
        { label: "Sporting Goods", value: "Sporting Goods" },
        { label: "Outdoor Gear", value: "Outdoor Gear" },
        { label: "Bicycles", value: "Bicycles" },
        { label: "Camping & Hiking", value: "Camping & Hiking" },
      ],
    },
    {
      label: "Toys & Games",
      code: "JP",
      items: [
        { label: "Action Figures", value: "Action Figures" },
        { label: "Board Games", value: "Board Games" },
        { label: "Puzzles", value: "Puzzles" },
        { label: "Video Games", value: "Video Games" },
        { label: "Dolls & Stuffed Animals", value: "Dolls & Stuffed Animals" },
      ],
    },
    {
      label: "Books & Media",
      code: "JP",
      items: [
        { label: "Books", value: "Books" },
        { label: "Magazines", value: "Magazines" },
        { label: "Music", value: "Music" },
        { label: "Movies", value: "Movies" },
        { label: "Video Games", value: "Video Games" },
      ],
    },
    {
      label: "Jobs",
      code: "JP",
      items: [
        { label: "Full-Time Jobs", value: "Full-Time Jobs" },
        { label: "Part-Time Jobs", value: "Part-Time Jobs" },
        { label: "Freelance & Remote Jobs", value: "Freelance & Remote Jobs" },
        { label: "Internships", value: "Internships" },
        { label: "Volunteer Opportunities", value: "Volunteer Opportunities" },
      ],
    },
    {
      label: "Others",
      code: "JP",
      items: [{ label: "Others", value: "Others" }],
    },
  ];

  const handleDescriptionChange = (e) => {
    let value = e.target.value;

    if (value.length > 0) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }

    value = value.split('. ').map((sentence, index) =>
      index === 0
        ? sentence.charAt(0).toUpperCase() + sentence.slice(1)
        : sentence.charAt(0).toUpperCase() + sentence.slice(1)
    ).join('. ');

    if (value.endsWith('.')) {
      value = value.slice(0, -1) + '. ';
    }

    setData({ ...data, description: value });
  };


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scrollBarHidden AdPost_modal">
          <h6 className="mb-2">
            {isEditMode ? "Edit Advertisement" : "Post Advertisement"}
            <Tooltip
              title="Only 10 images with JPG format and an overall size of 10 MB are allowed."
              placement="top"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </h6>
          <div
            className="row"
            style={{
              height: "32rem",
            }}
          >
            <div className="col-12 lg:col-6">
              <label className="form-label labels mt-2" htmlFor="title">
                Post Title
              </label>
              <input
                placeholder="Title"
                type="text"
                id="title"
                className="form-control"
                name="title"
                value={data.title}
                onChange={(e) => {
                  const value = e.target.value;
                  const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                  setData({ ...data, title: capitalizedValue });
                }}
                style={{ padding: "7px" }}
              />
              {errors.title && <p className="text-danger">{errors.title}</p>}
            </div>
            <div className="col-12 lg:col-6 ">
              <label className="form-label labels mt-2" htmlFor="idNumber">
                Post Category
              </label>
              <Dropdown
                value={data.category}
                onChange={(e) => setData({ ...data, category: e.target.value })}
                options={postCategory}
                className="w-full"
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                placeholder="Select a Category"
              />
              {errors.category && (
                <p className="text-danger">{errors.category}</p>
              )}
            </div>

            <div className="col-12 lg:col-6">
              <label htmlFor="currency" className="labels mt-2 mb-1">
                Price
              </label>
              <div className="flex mt-2">
                <select
                  id="salaryCurrency"
                  className="form-control"
                  value={selectedSalaryCurrency}
                  onChange={(e) => setSelectedSalaryCurrency(e.target.value)}
                  style={{
                    width: "35px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderRight: "0px",
                    height: "40px",
                  }}
                >
                  {currencySymbols.map((currencySymbol, index) => (
                    <option key={index} value={currencySymbol.code}>
                      {currencySymbol.symbol}
                    </option>
                  ))}
                </select>

                <InputNumber
                  inputId="currency-india"
                  placeholder="Price"
                  name="vehicleprice"
                  className="w-full"
                  style={{ height: "40px" }}
                  value={salary}
                  onValueChange={(e) => {
                    setSalary(e.target.value);
                  }}
                  currency="INR"
                  currencyDisplay="code"
                  locale="en-IN"
                />
              </div>
              {errors.salary && <p className="text-danger">{errors.salary}</p>}
            </div>

            <div className="col-12 lg:col-6">
              <label htmlFor="mobile_no" className="mt-2 mb-2">
                Contact Number
              </label>
              <PhoneInput
                country="in"
                value={mobile_no}
                onChange={(phone) => setMobile_no(phone)}
                inputClass="form-control"
              />
              {errors.mobile_no && (
                <span className="text-danger">{errors.mobile_no}</span>
              )}
            </div>

            <div className="col-12 lg:col-6">
              <label className="form-label labels mt-4" htmlFor="issueDate">
                Post Location
              </label>
              <input
                placeholder=" Location"
                type="text"
                id="location"
                className="form-control"
                name="postLocation"
                value={data.location}
                onChange={(e) => {
                  const value = e.target.value;
                  const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                  setData({ ...data, location: capitalizedValue });
                }}
                style={{ padding: "7px" }}
              />
              {errors.location && (
                <p className="text-danger">{errors.location}</p>
              )}
            </div>

            <div className="col-12 lg:col-6">
              <label className="form-label labels mt-2" htmlFor="description">
                Post Description
              </label>
              <InputTextarea
                placeholder=" Description"
                id="description"
                className="form-control"
                name="Post Description"
                style={{ resize: "none" }}
                value={data.description}
                onChange={handleDescriptionChange}
              />
              {errors.description && (
                <p className="text-danger">{errors.description}</p>
              )}
            </div>
            <div className="">
              <label className="form-label labels mt-2" htmlFor="issueDate">
                Upload Images
              </label>
            </div>
            {!isEditMode && imageUploadSection}
            {isEditMode && addmorimage}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {[...listimages, ...additionalImages].map((imageObj, index) => (
                <div
                  key={index}
                  className="upload-card m-2 px-2"
                  style={{ position: "relative" }}
                >
                  <img
                    src={`${Assetsapi}/adimages/${imageObj}`}
                    alt={`Uploaded ${index + 1}`}
                    style={{
                      width: "7rem",
                      height: "7rem",
                      borderRadius: "10px",
                    }}
                  />
                  <MdRemoveCircleOutline
                    onClick={() => handleuploadRemove(index, listimages[index])}
                    style={{
                      color: "red",
                      position: "absolute",
                      top: "5px",
                      right: "14px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      fontSize: "1.3rem",
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="text-center mt-2 p-4">
              <button className="btn btn-danger me-2" onClick={handleClose}>
                Cancel
              </button>
              {isEditMode ? (
                <button
                  type="submit"
                  value="update"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Update"}
                </button>
              ) : (
                <button
                  type="submit"
                  value="add"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdvertisingDashboard;