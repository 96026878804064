import React, { useRef, useState,useContext,useEffect } from "react";
import "./Message.css";
import { Image } from "primereact/image";
import {
  ArrowBendUpLeft,
  Copy,
  DotsThreeVertical,
  Info,
  Trash,
  ArrowBendDoubleUpRight,
  DownloadSimple,
  X
} from "@phosphor-icons/react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { BackendApi,BackendVidoeApi,BackendAudioApi,DocumentApi,Nodeapi,ProfileImgApi,Assetsapi } from "../../../../../config/serverUrl";
import { FaImage } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import docimg from '../../../../../assets/Icons/pdf-icon.png';
import audioreply from '../../../../../assets/Icons/audio-headset.png'
import videoreply from '../../../../../assets/Icons/play.png'
import incoming from '../../../../../assets/Icons/incoming-call.png'
import outgoing from '../../../../../assets/Icons/call-out.png'
import pdficon from "../../../../../assets/Icons/pdf-icon.png";
import videooutgoing from '../../../../../assets/Icons/outgoing.png'
import videoincoming from '../../../../../assets/Icons/phone-incoming-fill 2.png'
import videoincoming4 from '../../../../../assets/Icons/phone-incoming-fill 4.png'
import { AiFillAudio } from "react-icons/ai";
import { FaVideo } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button as PrimeButton } from 'primereact/button';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { VscCallIncoming } from "react-icons/vsc";
import { FaCircle } from "react-icons/fa";
import { DataContext } from "../../../../../Admin/DataContext";
import { MdEdit } from "react-icons/md";
import { HiOutlineGif } from "react-icons/hi2";
import { format, isToday, isYesterday, parse } from 'date-fns';
import axios from "axios";
import AudioPlayerWithVisualization from "./AudioPlayerWithVisualization";
import ImageGrid from "./ImageGrid";
import io from "socket.io-client";
import { SocketApi } from "../../../../../config/serverUrl";
import { useMemo } from 'react';
import missedVdeoCall from '../../../../../assets/Icons/phone-incoming-fill 3.png'
import audiocall_outgoing from '../../../../../assets/Icons/phone-outgoing-fill (2) 1.png'
import audiocall_incoming from '../../../../../assets/Icons/phone-outgoing-fill (2) 2.png'
import audiocall_incoming_missed from '../../../../../assets/Icons/phone-incoming-fill 3.png'
import { Document, Page } from "react-pdf"; // Adjust import based on your setup
import reaction from "../../../../../assets/Icons/Reaction.png";
import docicon from '../../../../../assets/Icons/doc-icon.png'


const socket = io(SocketApi);



const Reactions = ({el,setShowMenu,showMenu}) => {
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const handleReaction = (id, event) => {
    setShowMenu(!showMenu);
    const text = event.target.textContent; 
   const data={id,chatmaster_id:el.chatmaster_id,text}
  
   
try{
  axios.post(`${Nodeapi}/reaction`,data).then((res)=>{


  })
}catch{
}
  };

  return (
    <div style={{
      // position: 'absolute',
      top:menuPosition.x,
      left: menuPosition.y,
      backgroundColor: '#E0E0E0',
     height:"40px",
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     padding:"0 10px",
  
     borderRadius:"40px",
     marginRight:"10px"
    }}>
      <ul style={{listStyle:"none",outline:"none",border:"none",display:"flex",gap:"5px", }}>
        <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">👍</li>
        <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">💗</li>
        <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😂</li>
        <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😯</li>
        <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😥</li>
        <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">🔥</li>
      </ul>
    </div>
  )
}

const Reaction_show = ({isHovered,el,clickableRef,authdata,setShowMenu,showMenu}) => {
  const handleReactionRemove=(id,text)=>{
    const data={id,text,chatmaster_id:el.chatmaster_id}
    try{
      axios.post(`${Nodeapi}/reaction`,data).then((res)=>{
    console.log(res);
    
      })
    }catch{
    
    }
  }
  const handleReactionClick = (e) => {
    e.preventDefault();
    setShowMenu(!showMenu);
  };

  return (
    <>
      { isHovered && !el.react_emoji && <div  ref={clickableRef}
  onClick={(e)=>handleReactionClick(e)} className="reaction" style={{ zIndex:"9", height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointer"}}><img src={reaction} style={{height:"16px",width:"16px"}} alt="" /></div>}
  
{ el.react_emoji &&  <div className="reaction" onClick={(event) => handleReactionRemove(el.id )} style={{height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointe"}}><p style={{fontSize:"14px",cursor:"pointer"}}>{el.react_emoji}</p></div>}
    </>
  )
}

const TextMsg = ({
  el,
  setReplyData,
  handleReply,
  setReplyBtn,
  userName,
  setReplyNames,
  handleDelete,
  selectionMode,
  handleCheckboxChange,
}) => {
  const [readmore, setReadMore] = useState(false);
  const {
    convertTimezone,
    userTmZone,
    UserStatus,
    searchTerm,
    setShowTooltip,
  } = useContext(DataContext);
  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
  const groupParticipant = el.groupparticipant
    ? JSON.parse(el.groupparticipant)
    : [];
  const [time, SetTime] = useState("");
  const authdata = useSelector((state) => state.auth.user);
  const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
    setShowTooltip(false);
  };
  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;
    
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);
    
    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  // reaction 
  const [isHovered, setIsHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const clickableRef = useRef(null);

  const handleReactionClick = (e) => {
    e.preventDefault();
    setMenuPosition({ x: e.pageX, y: e.pageY });
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (clickableRef.current && !clickableRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  var chatmaster_id=el.chatmaster_id

  const handleReaction = (id, event) => {
    const text = event.target.textContent; 
   const data={id,chatmaster_id,text}
  
   
try{
  axios.post(`${Nodeapi}/reaction`,data).then((res)=>{


  })
}catch{
}
  };

  const handleReactionRemove=(id,text)=>{
    const data={id,text,chatmaster_id}
    try{
      axios.post(`${Nodeapi}/reaction`,data).then((res)=>{
    console.log(res);
    
      })
    }catch{
    
    }
  }


  
  return (
    <div
      className="msg-text"
      style={{
        justifyContent:
          el.sender_id == authdata?.id ? "flex-end" : "flex-start",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {showMenu  && el.sender_id == authdata?.id &&  (
  <div style={{
    // position: 'absolute',
    top:menuPosition.x,
    left: menuPosition.y,
    backgroundColor: '#E0E0E0',
   height:"40px",
   display:"flex",
   justifyContent:"center",
   alignItems:"center",
   padding:"0 10px",

   borderRadius:"40px",
   marginRight:"10px"
  }}>
    <ul style={{listStyle:"none",outline:"none",border:"none",display:"flex",gap:"5px", }}>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">👍</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">💗</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😂</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😯</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😥</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">🔥</li>
    </ul>
  </div>
      )}
      {selectionMode && (
        <div>
          <input
            type="checkbox"
            value={el.id}
            onChange={handleCheckboxClick}
            style={{
              width: "20px",
              height: "15px",
              padding: "5px",
              margin: "10px",
            }}
          />
        </div>
      )}

      <GroupProfile el={el} />

      <div
        className={el.sender_id == authdata?.id ? "right" : "left"}
        style={{
          padding:
            UserStatus == "group" && el.sender_id !== authdata?.id
              ? "10px 10px 4px 10px"
              : "10px 10px 4px 10px",
        }}
      >
        <div>
          <ChatSenderName el={el} />

          <div>
            {el.message.length >= 300 ? (
              <p
                className="text"
                style={{
                  whiteSpace: "pre-line",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }}
              >
                {readmore
                  ? highlightText(el.message, searchTerm)
                  : highlightText(
                      el.message.substring(0, 300),
                      searchTerm
                    )}{" "}
                <span
                  onClick={() => {
                    setReadMore(true);
                  }}
                  style={{
                    cursor: "pointer",
                    paddingLeft: "5px",
                  }}
                >
                  {readmore ? "" : "See More"}
                </span>
              </p>
            ) : (
              <p
                className="text"
                style={{
                  whiteSpace: "pre-line",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }}
              >
                {highlightText(el.message, searchTerm)}
              </p>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <p style={{ fontSize: "0.7rem" }}>
                {el.type == "forward" ? (
                  <span>
                    <span style={{ paddingRight: "3px", position: "relative" }}>
                      <ArrowBendDoubleUpRight size={15} />
                    </span>
                    {el.type}ed
                  </span>
                ) : (
                  ""
                )}
                {el.edit == "Edited" ? <span>{el.edit}</span> : null}
              </p>
              <p className="chat-time">
                {convertTimezone(el.time, userTmZone.timezone)}&nbsp;
                {el.sender_id === authdata?.id ? (
                  <>
                    <FaCircle
                      className={
                        el.status === "read" ||
                        (groupParticipant.length &&
                          groupStatus.length === groupParticipant.length)
                          ? "readcolor"
                          : "unreadcolor"
                      }
                      size={7.5}
                    />
                    {/* &#8722; */}
                    <FaCircle
                      style={{ marginLeft: "2px" }}
                      className={
                        el.status === "read" ||
                        (groupParticipant.length &&
                          groupStatus.length === groupParticipant.length)
                          ? "readcolor"
                          : "unreadcolor"
                      }
                      size={7.5}
                    />
                  </>
                ) : null}
              </p>
            </div>
          </div>
        </div>
        {el.sender_id == authdata?.id ? (
          <div class="triangle-right"></div>
        ) : (
          <div class="triangle-left"></div>
        )}
      { isHovered && !el.react_emoji && <div  ref={clickableRef}
        onClick={handleReactionClick} className="reaction" style={{ zIndex:"9", height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointer"}}><img src={reaction} style={{height:"16px",width:"16px"}} alt="" /></div>}
        
      { el.react_emoji &&  <div className="reaction" onClick={(event) => handleReactionRemove(el.id )} style={{height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointe"}}><p style={{fontSize:"14px",cursor:"pointer"}}>{el.react_emoji}</p></div>}
      </div>
      <ThreeDot
        handleReply={handleReply}
        setReplyBtn={setReplyBtn}
        setReplyData={setReplyData}
        authdata={authdata}
        el={el}
        userName={userName}
        setReplyNames={setReplyNames}
        handleDelete={handleDelete}
      />
       {showMenu  && el.sender_id != authdata?.id &&  (
  <div style={{
    // position: 'absolute',
    top:menuPosition.x,
    left: menuPosition.y,
    backgroundColor: '#E0E0E0',
   height:"40px",
   display:"flex",
   justifyContent:"center",
   alignItems:"center",
   padding:"0 10px",

   borderRadius:"40px"
  }}>
    
    <ul style={{listStyle:"none",outline:"none",border:"none",display:"flex",gap:"5px", }}>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">👍</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">💗</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😂</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😯</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">😥</li>
      <li  onClick={(event) => handleReaction(el.id, event)} className="react-menu">🔥</li>
    </ul>
  </div>
      )}
    </div>
  );
};


const AudioCall = ({ el, setReplyData, handleReply, setReplyBtn,userName,setReplyNames, handleDelete, selectionMode,handleCheckboxChange }) => {
  const { convertTimezone,userTmZone,UserStatus } = useContext(DataContext);
  const authdata = useSelector((state) => state.auth.user);
   const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };
   


  
  const convertTime = (time) => {
    const parts = time.split(':');
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);

    if (minutes === 0 && seconds < 60) {
        // Treat as seconds
        return `secs`;
    } else if (minutes > 0 && seconds < 60) {
        // Treat as minutes and seconds
        return `min`;
    } else {
        // Treat as hours and minutes
        return `hour`;
    }
};





  return (

<div className="video-call" style={{justifyContent:el.sender_id == authdata?.id ? "flex-end" : "flex-start"}}>
    {selectionMode && (
      <div >
        <input type="checkbox" value={el.id} onChange={handleCheckboxClick} style={{ width: "20px", height: "15px", padding: "5px", margin: "10px" }} />
        </div>
      )}
      <GroupProfile el={el} />
      <div className="right" style={{ padding: UserStatus == 'group' && el.sender_id !== authdata?.id ? '10px 10px 2px 10px' : '10px 10px 5px 10px' }}>

      
      <div className="d-flex justify-content-between" style={{ background: "#fff", padding: "8px", borderRadius: "4px",width:"170px" }}>
        <div className="right-head" style={{ display: "flex", justifyContent:"space-between",width:"100%",alignItems:"center" }}>
          <div className="icon-container">
          <img src={el.sender_id == authdata?.id ? audiocall_outgoing :(el.message=="00:00" ? audiocall_incoming_missed : audiocall_incoming) } style={{ width:"40px",height:"40px", }}/>

          </div>
          <div className="text-container">
            <p className="text">Voice Call</p>

            <div style={{ display:"flex" }}>
            <p className="text"> {el.message == '00:00' ?(el.sender_id === authdata?.id ? 'No Answer': <span style={{color:"red"}}>Missed Call</span>) : `${el.message} ${convertTime(el.message)}`}</p>
            </div>
           {/* Apply text-right class for right alignment */}
          </div>
        </div>
      </div>
            <p className="chat-time text-right time-color" style={{paddingTop:"4px"}}>{convertTimezone(el.time, userTmZone.timezone)}</p>  
    </div>
      <ThreeDot el={el} handleDelete={handleDelete}/>
    </div>
  );
};

const VideoCall = ({ el, setReplyData, handleReply, setReplyBtn,userName,setReplyNames, handleDelete, selectionMode,handleCheckboxChange }) => {
  const { convertTimezone,userTmZone,UserStatus } = useContext(DataContext);
  const authdata = useSelector((state) => state.auth.user);
   const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };
   



  const convertTime = (time) => {
    const parts = time.split(':');
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);

    if (minutes === 0 && seconds < 60) {
        // Treat as seconds
        return `secs`;
    } else if (minutes > 0 && seconds < 60) {
        // Treat as minutes and seconds
        return `min`;
    } else {
        // Treat as hours and minutes
        return `hour`;
    }
};





  return (

<div className="video-call" style={{justifyContent:el.sender_id == authdata?.id ? "flex-end" : "flex-start"}}>
    {selectionMode && (
      <div >
        <input type="checkbox" value={el.id} onChange={handleCheckboxClick} style={{ width: "20px", height: "15px", padding: "5px", margin: "10px" }} />
        </div>
      )}
 
      <GroupProfile el={el} />
      <div className="right" style={{ padding: UserStatus == 'group' && el.sender_id !== authdata?.id ? '1px 10px 10px 10px' : '10px 10px 4px 10px' }}>
      <div className="d-flex justify-content-between" style={{ background: "#fff", padding: "8px", borderRadius: "4px",width:"170px" }}>
        <div className="right-head" style={{ display: "flex",width:"100%",justifyContent:"center",alignItems:"center"}}>
          <div className="icon-container">
          <img src={el.sender_id == authdata?.id ? videoincoming :(el.message=="00:00" ? missedVdeoCall : videoincoming4) } style={{ width:"40px",height:"40px" }}/>

          </div>
          <div className="text-container">
            <p className="text">Video Call</p>

            <div style={{ display:"flex" }}>
            <p className="text"> {el.message == '00:00' ?(el.sender_id === authdata?.id ? 'No Answer': <span style={{color:"red"}}>Missed Call</span>) : `${el.message} ${convertTime(el.message)}`}</p>

            </div>
          </div>
        </div>
      </div>
            <p className="chat-time text-right time-color" style={{paddingTop:"4px"}}>{convertTimezone(el.time, userTmZone.timezone)}</p> 
    </div>
      <ThreeDot el={el} handleDelete={handleDelete}/>
    </div>

  );
};

const LinkMsg = ({ el, setReplyData, handleReply, setReplyBtn,userName,setReplyNames, handleDelete, selectionMode,handleCheckboxChange }) => {
  const { convertTimezone,userTmZone,UserStatus } = useContext(DataContext);
  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
const groupParticipant = el.groupparticipant ? JSON.parse(el.groupparticipant) : [];
  const authdata = useSelector((state) => state.auth.user);
   const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };
  

  // Example paragraph with multiple URLs
  const paragraph = el.message;

  // Function to extract URLs from a string
  const extractUrls = (text) => {
    const urlRegex = /https?:\/\/[^\s]+/g;
    return text.match(urlRegex) || [];
  };

  // Function to replace URLs with styled links
  const highlightUrlsInText = (text, urls) => {
    if (!urls.length) return text;

    // Create a regex pattern to match any of the URLs
    const escapedUrls = urls.map((url) =>
      url.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    );
    const regex = new RegExp(`(${escapedUrls.join("|")})`, "g");

    // Split the text and replace URLs with styled links
    const parts = text.split(regex);
    return parts.map((part, index) =>
      urls.includes(part) ? (
        <a
          key={index}
          href={part}
          style={{ color: "#2196f3", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        <span style={{ color: "black" }} key={index}>
          {part}
        </span>
      )
    );
  };

  // Extract URLs and store them in a variable
  const urls = extractUrls(paragraph);


  return (
    <div className="msg-text" style={{justifyContent:el.sender_id == authdata?.id ? "flex-end" : "flex-start"}}>
    {selectionMode && (
      <div >
        <input type="checkbox" value={el.id} onChange={handleCheckboxClick} style={{ width: "20px", height: "15px", padding: "5px", margin: "10px" }} />
        </div>
      )}

      <GroupProfile el={el} />

      <div className={el.sender_id == authdata?.id ?'right':"left"} style={{ padding: UserStatus == 'group' && el.sender_id !== authdata?.id ? '10px 10px 4px 10px' : '10px 10px 4px 10px' }}>
       
        <div >
        <ChatSenderName el={el} />
        <p
              style={{
                whiteSpace: "pre-line",
                overflowWrap: "break-word",
                wordBreak: "break-word",
              }}>
              <p>{highlightUrlsInText(paragraph, urls)}</p>
            </p>
          <p style={{ fontSize: "0.7rem" }}>
                {el.type === "forward" ? (
                  <span>
                    <span style={{ paddingRight: "5px" }}>
                      <ArrowBendDoubleUpRight size={18} />
                    </span>
                    {el.type}ed
                  </span>
                ) : (
                  ""
                )}
              </p>
          <p className="chat-time">{convertTimezone(el.time, userTmZone.timezone)}  {el.sender_id === authdata?.id ? (
    <>
      <FaCircle className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
      <FaCircle style={{ marginLeft:"2px" }} className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
    </>
  ) : null}</p>
         
        </div>
        {el.sender_id == authdata?.id ?<div class="triangle-right"></div>:<div class="triangle-left"></div>} 
      </div>
      <ThreeDot
        handleReply={handleReply}
        setReplyBtn={setReplyBtn}
        setReplyData={setReplyData}
        authdata={authdata}
        el={el}
        userName={userName}
        setReplyNames={setReplyNames}
        handleDelete={handleDelete}
      />
    </div>
  );
};

const Timeline = ({ el, ReplyData, setReplyData, handleReply }) => {
  const formatDate = (dateString) => {
    const date = parse(dateString, 'EEE MMM dd yyyy', new Date());
    if (isToday(date)) {
      return 'Today';
    } else if (isYesterday(date)) {
      return 'Yesterday';
    } else {
      return format(date, 'MMMM dd yyyy');
    }
  };
  return (
    <div className="text-center" style={{position: 'sticky',top: '0px'}}>
      <p className="timeline">
      <span>{formatDate(el.date)}</span>
      {/* <span>{el.date}</span> */}
      </p>
    </div>
  );
};

const CreateGroupTimeline = ({ el }) => {

  return (
    <div className="text-center" style={{ marginTop: '10px', marginBottom: "10px" }}>
      <p className="timeline">
        {/* <span>{el.sender_id === authdata?.id ? `You ${el.message}` : `${userName} ${el.message}`}</span> */}
        <span>{el.username} {el.message}</span>
      </p>
    </div>
  );
};

//fd
const MediaMsg = ({ el, ReplyData, setReplyData, handleReply,setImagereplydata,setReplyNames, handleDelete,userName, selectionMode,handleCheckboxChange }) => {
  const { convertTimezone,userTmZone,UserStatus,downloadFileAtURL } = useContext(DataContext);
  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
  const groupParticipant = el.groupparticipant ? JSON.parse(el.groupparticipant) : [];
  const authdata = useSelector((state) => state.auth.user);
   const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };


  const [fullScreenImg,setFullScreenImg]=useState(null);
  const openFullscreen = (img) => {
    setFullScreenImg(img)
  };
  const closeFullscreen = () => {
    setFullScreenImg(null)
  };


// reaction 
const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
const [isHovered, setIsHovered] = useState(false);
const [showMenu, setShowMenu] = useState(false);
const clickableRef = useRef(null);

const handleReactionClick = (e) => {
  
  e.preventDefault();
  setMenuPosition({ x: e.pageX, y: e.pageY });
  setShowMenu(!showMenu);
};

const handleReactionRemove=(id,text)=>{
  const data={id,text,chatmaster_id:el.chatmaster_id}
  try{
    axios.post(`${Nodeapi}/reaction`,data).then((res)=>{
  console.log(res);
  
    })
  }catch{
  
  }
}


  return (
    <div
      className="msg-img"
      style={{
        justifyContent:
          el.sender_id == authdata?.id ? "flex-end" : "flex-start",
      }}
       onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      >
      {
        showMenu  && el.sender_id == authdata?.id && (
          <Reactions el={el}  setShowMenu={setShowMenu} showMenu={showMenu} />
        )
      }
      {selectionMode && (
        <div>
          <input
            type="checkbox"
            value={el.id}
            onChange={handleCheckboxClick}
            style={{
              width: "20px",
              height: "15px",
              padding: "5px",
              margin: "10px",
            }}
          />
        </div>
      )}

      <GroupProfile el={el} />
      <div className={el.sender_id == authdata?.id ?'right':"left"} style={{ padding: UserStatus == 'group' && el.sender_id !== authdata?.id ? '10px 10px 4px 10px' : '10px 10px 4px 10px' }}>
        <div>
        <ChatSenderName el={el} />
          <div>
          {el.img && (
              <img
                src={`${BackendApi}/${el.img}`}
                alt=""
                className="img-chat"
                width="100%"
                
                onClick={() => openFullscreen(`${BackendApi}/${el.img}`)}
              />
            )}
            {el.message && <p className="chat-text-img">{el.message}</p>}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "5px",
            }}>
            <p style={{ fontSize: "0.7rem" }}>
              {el.type == "forward" ? (
                <span>
                  <span style={{ paddingRight: "5px" }}>
                    <ArrowBendDoubleUpRight size={18} />
                  </span>
                  {el.type}ed
                </span>
              ) : (
                ""
              )}
            </p>
            <p className="chat-time">{convertTimezone(el.time, userTmZone.timezone)}  {el.sender_id === authdata?.id ? (
    <>
      <FaCircle className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
      <FaCircle style={{ marginLeft:"2px" }} className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
    </>
  ) : null}</p>
            
          </div>
        </div>
        {el.sender_id == authdata?.id ?<div class="triangle-right"></div>:<div class="triangle-left"></div>} 
        { isHovered && !el.react_emoji && <div  ref={clickableRef}
        onClick={(e)=>handleReactionClick(e)} className="reaction" style={{ zIndex:"9", height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointer"}}><img src={reaction} style={{height:"16px",width:"16px"}} alt="" /></div>}
        
      { el.react_emoji &&  <div className="reaction" onClick={(event) => handleReactionRemove(el.id )} style={{height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointe"}}><p style={{fontSize:"14px",cursor:"pointer"}}>{el.react_emoji}</p></div>}
      </div>
      
      <ThreeDot
        handleReply={handleReply}
        el={el}
        setReplyNames={setReplyNames}
        setReplyData={setReplyData}
        handleDelete={handleDelete}
        userName={userName}
      />
        {fullScreenImg !== null && (
        <div className="fullscreen-view" style={{position:"fixed",zIndex:"9999"}}>
          <div className="top-controls">
            <button className="delete-btn" onClick={()=>{downloadFileAtURL(`${fullScreenImg}`)}}><DownloadSimple size={32} /></button>
            <button className="close-btn" onClick={closeFullscreen}><X size={32} /></button>
          </div>
          
          <div className="fullscreen-image">
            <img src={`${fullScreenImg}`} alt={`Fullscreen ${fullScreenImg}`} />
          </div>
        </div>
      )}
    </div>
  );
};

const MultipleMediaMsg = ({ el, ReplyData, setReplyData, handleReply,setImagereplydata,setReplyNames, handleDelete,userName, selectionMode,handleCheckboxChange }) => {
  const { convertTimezone,userTmZone,UserStatus,downloadFileAtURL } = useContext(DataContext);
 
  const authdata = useSelector((state) => state.auth.user);
   const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };

  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
  const groupParticipant = el.groupparticipant ? JSON.parse(el.groupparticipant) : [];

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
const [isHovered, setIsHovered] = useState(false);
const [showMenu, setShowMenu] = useState(false);
const clickableRef = useRef(null);

const handleReactionClick = (e) => {
  
  e.preventDefault();
  setMenuPosition({ x: e.pageX, y: e.pageY });
  setShowMenu(!showMenu);
};

const handleReactionRemove=(id,text)=>{
  const data={id,text,chatmaster_id:el.chatmaster_id}
  try{
    axios.post(`${Nodeapi}/reaction`,data).then((res)=>{
  console.log(res);
  
    })
  }catch{
  
  }
} 


  return (
    <div
      className="msg-img"
      style={{
        justifyContent:
          el.sender_id == authdata?.id ? "flex-end" : "flex-start",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      >
      {
        showMenu  && el.sender_id == authdata?.id && (
          <Reactions el={el}  setShowMenu={setShowMenu} showMenu={showMenu} />
        )
      }
      {selectionMode && (
        <div>
          <input
            type="checkbox"
            value={el.id}
            onChange={handleCheckboxClick}
            style={{
              width: "20px",
              height: "15px",
              padding: "5px",
              margin: "10px",
            }}
          />
        </div>
      )}

      <GroupProfile el={el} />
      <div className={el.sender_id == authdata?.id ?'right':"left"} style={{ padding: UserStatus == 'group' && el.sender_id !== authdata?.id ? '10px 10px 10px 10px' : '10px 10px 4px 10px' }}>
        <div>
        <ChatSenderName el={el} />
          <div>
            <ImageGrid initialImages={JSON.parse(el.img)} /> 
            {el.message && <p className="chat-text-img">{el.message}</p>}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "5px",
            }}>
            <p style={{ fontSize: "0.7rem" }}>
              {el.type == "forward" ? (
                <span>
                  <span style={{ paddingRight: "5px" }}>
                    <ArrowBendDoubleUpRight size={18} />
                  </span>
                  {el.type}ed
                </span>
              ) : (
                ""
              )}
            </p>
            <p className="chat-time">{convertTimezone(el.time, userTmZone.timezone)}  {el.sender_id === authdata?.id ? (
    <>
      <FaCircle className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
      <FaCircle style={{ marginLeft:"2px" }} className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
    </>
  ) : null}</p>
            
          </div>
        </div>
        {el.sender_id == authdata?.id ?<div class="triangle-right"></div>:<div class="triangle-left"></div>} 
        { isHovered && !el.react_emoji && <div  ref={clickableRef}
        onClick={(e)=>handleReactionClick(e)} className="reaction" style={{ zIndex:"9", height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointer"}}><img src={reaction} style={{height:"16px",width:"16px"}} alt="" /></div>}
        
      { el.react_emoji &&  <div className="reaction" onClick={(event) => handleReactionRemove(el.id )} style={{height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointe"}}><p style={{fontSize:"14px",cursor:"pointer"}}>{el.react_emoji}</p></div>}
      </div>
      
      <ThreeDot
        handleReply={handleReply}
        el={el}
        setReplyNames={setReplyNames}
        setReplyData={setReplyData}
        handleDelete={handleDelete}
        userName={userName}
      />
    </div>
  );
};

const GifMsg = ({ el, ReplyData, setReplyData, handleReply,setImagereplydata,setReplyNames, handleDelete,userName, selectionMode,handleCheckboxChange }) => {
  const { convertTimezone,userTmZone,UserStatus } = useContext(DataContext);
  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
  const groupParticipant = el.groupparticipant ? JSON.parse(el.groupparticipant) : [];
  const authdata = useSelector((state) => state.auth.user);
   const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
      const [isHovered, setIsHovered] = useState(false);
      const [showMenu, setShowMenu] = useState(false);
      const clickableRef = useRef(null);
      
      const handleReactionClick = (e) => {
        
        e.preventDefault();
        setMenuPosition({ x: e.pageX, y: e.pageY });
        setShowMenu(!showMenu);
      };
      
      const handleReactionRemove=(id,text)=>{
        const data={id,text,chatmaster_id:el.chatmaster_id}
        try{
          axios.post(`${Nodeapi}/reaction`,data).then((res)=>{
        console.log(res);
        
          })
        }catch{
        
        }
      } 

  return (
    <div
      className="msg-img"
      style={{
        justifyContent:
          el.sender_id == authdata?.id ? "flex-end" : "flex-start",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      >
      {
        showMenu  && el.sender_id == authdata?.id && (
          <Reactions el={el}  setShowMenu={setShowMenu} showMenu={showMenu} />
        )
      }
      {selectionMode && (
        <div>
          <input
            type="checkbox"
            value={el.id}
            onChange={handleCheckboxClick}
            style={{
              width: "20px",
              height: "15px",
              padding: "5px",
              margin: "10px",
            }}
          />
        </div>
      )}

      <GroupProfile el={el} />

      <div className={el.sender_id == authdata?.id ?'right':"left"} style={{ padding: UserStatus == 'group' && el.sender_id !== authdata?.id ? '10px 10px 4px 10px' : '10px 10px 4px 10px' }}>
        <div>
        <ChatSenderName el={el} />
          <div>
            {el.img && (
              <Image
                src={el.img}
                alt=""
                className="gif-img-chat"
                width="100%"
                preview
              />
            )}
            {el.message && <p className="chat-text-img">{el.message}</p>}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "5px",
            }}>
            <p style={{ fontSize: "0.7rem" }}>
              {el.type == "forward" ? (
                <span>
                  <span style={{ paddingRight: "5px" }}>
                    <ArrowBendDoubleUpRight size={18} />
                  </span>
                  {el.type}ed
                </span>
              ) : (
                ""
              )}
            </p>
            <p className="chat-time">{convertTimezone(el.time, userTmZone.timezone)}  {el.sender_id === authdata?.id ? (
    <>
      <FaCircle className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
      <FaCircle style={{ marginLeft:"2px" }} className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
    </>
  ) : null}</p>
            
          </div>
        </div>
        {el.sender_id == authdata?.id ?<div class="triangle-right"></div>:<div class="triangle-left"></div>} 
        { isHovered && !el.react_emoji && <div  ref={clickableRef}
      onClick={(e)=>handleReactionClick(e)} className="reaction" style={{ zIndex:"9", height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointer"}}><img src={reaction} style={{height:"16px",width:"16px"}} alt="" /></div>}
      
    { el.react_emoji &&  <div className="reaction" onClick={(event) => handleReactionRemove(el.id )} style={{height:"24px",width:"24px",position:"absolute",left:el.sender_id == authdata?.id ? "-10px" : "",right:el.sender_id == authdata?.id ? "" : "-10px",bottom:"-13px",backgroundColor:"#E6E6E6",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",cursor:"pointe"}}><p style={{fontSize:"14px",cursor:"pointer"}}>{el.react_emoji}</p></div>}
      </div>
      
      <ThreeDot
        handleReply={handleReply}
        el={el}
        setReplyNames={setReplyNames}
        setReplyData={setReplyData}
        handleDelete={handleDelete}
        userName={userName}
      />
    </div>
  );
};

const VideoMsg = ({ el, ReplyData, setReplyData, handleReply, handleDelete,setReplyNames,userName, selectionMode,handleCheckboxChange }) => {
  
  const { convertTimezone,userTmZone,UserStatus } = useContext(DataContext);
  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
const groupParticipant = el.groupparticipant ? JSON.parse(el.groupparticipant) : [];
  const authdata = useSelector((state) => state.auth.user);
  const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const clickableRef = useRef(null);

  return (
    <div className="msg-vdo" style={{justifyContent:el.sender_id == authdata?.id ? "flex-end" : "flex-start"}}  onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
{
        showMenu  && el.sender_id == authdata?.id && (
          <Reactions el={el}  setShowMenu={setShowMenu} showMenu={showMenu} />
        )
      }

    {selectionMode && (
      <div >
        <input type="checkbox" value={el.id} onChange={handleCheckboxClick} style={{ width: "20px", height: "15px", padding: "5px", margin: "10px" }} />
        </div>
      )}

      <GroupProfile el={el} />

      <div className={el.sender_id == authdata?.id ?'right':"left"} style={{ padding: UserStatus == 'group' && el.sender_id !== authdata?.id ? '10px 10px 4px 10px' : '10px 10px 4px 10px' }}>
       <div>
       <ChatSenderName el={el} />
       <div>
          {el.img && <video src={`${BackendVidoeApi}/${el.img}`} controls className="chat-vdo" />}
          {el.message && <p className="chat-text-vdo">{el.message}</p>}
         
        </div>
        <div>
         
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
           
            >
            <p style={{ fontSize: "0.7rem" }}>
              {el.type == "forward" ? (
                <span>
                  <span style={{ paddingRight: "5px" }}>
                    <ArrowBendDoubleUpRight size={18} />
                  </span>
                  {el.type}ed
                </span>
              ) : (
                ""
              )}
            </p>
            <p className="chat-time">{convertTimezone(el.time, userTmZone.timezone)}  {el.sender_id === authdata?.id ? (
    <>
      <FaCircle className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
      <FaCircle style={{ marginLeft:"2px" }} className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
    </>
  ) : null}</p>
           
          </div>
        </div>
       </div>
       {el.sender_id == authdata?.id ?<div class="triangle-right"></div>:<div class="triangle-left"></div>} 
       <Reaction_show isHovered={isHovered} el={el} clickableRef={clickableRef} authdata={authdata} setShowMenu={setShowMenu} showMenu={showMenu} />
       
      </div>
     
      <ThreeDot handleReply={handleReply} el={el} setReplyData={setReplyData} handleDelete={handleDelete} setReplyNames={setReplyNames} userName={userName} />
    </div>
  );
};

const AudioMsg = ({ el, ReplyData, setReplyData, handleReply,handleDelete,setReplyNames,userName, selectionMode,handleCheckboxChange }) => {

  const { convertTimezone,userTmZone,UserStatus } = useContext(DataContext);
  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
  const groupParticipant = el.groupparticipant ? JSON.parse(el.groupparticipant) : [];
  const authdata = useSelector((state) => state.auth.user);
   const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };


  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const clickableRef = useRef(null);

  return (
    <div className="msg-ado" style={{justifyContent:el.sender_id == authdata?.id ? "flex-end" : "flex-start"}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
    {
        showMenu  && el.sender_id == authdata?.id && (
          <Reactions el={el}  setShowMenu={setShowMenu} showMenu={showMenu} />
        )
      }
    {selectionMode && (
      <div >
        <input type="checkbox" value={el.id} onChange={handleCheckboxClick} style={{ width: "20px", height: "15px", padding: "5px", margin: "10px" }} />
        </div>
      )}

      <GroupProfile el={el} />

      <div className={el.sender_id == authdata?.id ?'right':"left"} style={{ padding: UserStatus == 'group' && el.sender_id !== authdata?.id ? '10px 10px 4px 10px' : '10px 10px 4px 10px' }}>
      <div>
      <ChatSenderName el={el} />
          <div>
          <AudioPlayerWithVisualization
              audioFile={`${BackendAudioApi}/${el.img}`}
              profile={`${ProfileImgApi}/${el.userprofile}`}
            />
            {el.message && <p className="chat-text-ado">{el.message}</p>}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            <p style={{ fontSize: "0.7rem" }}>
              {el.type == "forward" ? (
                <span>
                  <span style={{ paddingRight: "5px" }}>
                    <ArrowBendDoubleUpRight size={18} />
                  </span>
                  {el.type}ed
                </span>
              ) : (
                ""
              )}
            </p>
            <p style={{paddingTop:"4px"}} className="chat-time">{convertTimezone(el.time, userTmZone.timezone)}  {el.sender_id === authdata?.id ? (
    <>
      <FaCircle className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
      <FaCircle style={{ marginLeft:"2px" }} className={el.status === 'read' || (groupParticipant.length && groupStatus.length === groupParticipant.length) ? 'readcolor' : 'unreadcolor'} size={7.5} />
    </>
  ) : null}</p>
           
          </div>
        </div>
        {el.sender_id == authdata?.id ?<div class="triangle-right"></div>:<div class="triangle-left"></div>} 
        <Reaction_show isHovered={isHovered} el={el} clickableRef={clickableRef} authdata={authdata} setShowMenu={setShowMenu} showMenu={showMenu} />
      </div>
     
      <ThreeDot handleReply={handleReply} el={el} setReplyData={setReplyData} handleDelete={handleDelete} setReplyNames={setReplyNames} userName={userName} />
    </div>
  );
};

const PdfMsg = ({
  el,
  ReplyData,
  setReplyData,
  handleReply,
  handleDelete,
  setReplyNames,
  userName,
  selectionMode,
  handleCheckboxChange,
}) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const { convertTimezone, userTmZone, UserStatus } = useContext(DataContext);
  const authdata = useSelector((state) => state.auth.user);

  const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };
  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
  const groupParticipant = el.groupparticipant
    ? JSON.parse(el.groupparticipant)
    : [];

  const fileType = el.img.split(".").pop().toLowerCase();
  const isPdf = fileType === "pdf";
  const fileIcon = isPdf ? pdficon : docicon;

   const get_file_size = JSON.parse(el.file_size)

   const fileSize = get_file_size && get_file_size[0] ? Number(get_file_size[0].size) : null;
   // Convert file_size to a number

  const formattedSize =
    fileSize >= 1024 * 1024
      ? (fileSize / (1024 * 1024)).toFixed(1) + " MB"
      : fileSize >= 1024
      ? (fileSize / 1024).toFixed(1) + " KB"
      : fileSize + " bytes";

  // Handle file download
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `http://localhost:8001/assets/SndRcvdPdf/${el.img}`; // Assuming 'el.img' contains the URL of the PDF file
    // link.download = el.img.split("/").pop(); // Get the file name from the URL
    // link.click();
    window.open(link.href, "_blank");
  };

  // Load PDF
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  console.log(`http://localhost:8001/assets/SndRcvdPdf/${el.img}`);

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const clickableRef = useRef(null);

  return (
    <div
      className="msg-pdf"
      style={{
        justifyContent:
          el.sender_id === authdata?.id ? "flex-end" : "flex-start",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
     {
        showMenu  && el.sender_id == authdata?.id && (
          <Reactions el={el}  setShowMenu={setShowMenu} showMenu={showMenu} />
        )
      }

      {selectionMode && (
        <div>
          <input
            type="checkbox"
            value={el.id}
            onChange={handleCheckboxClick}
            style={{
              width: "20px",
              height: "15px",
              padding: "5px",
              margin: "10px",
            }}
          />
        </div>
      )}

      <GroupProfile el={el} />

      <div
        className={el.sender_id == authdata?.id ? "right" : "left"}
        style={{
          padding:
            UserStatus === "group" && el.sender_id !== authdata?.id
              ? "10px 10px 4px 10px"
              : "10px 10px 4px 10px",
        }}
      >
        <div>
          <ChatSenderName el={el} />
          <div>
            {el.img && (
              <div>
                

                <div className="pdf-chat-side">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <img
                        src={fileIcon}
                        alt="PDF-icon"
                        className="pdf-chat-img"
                      />
                    </div>
                    <div className="text" style={{}} onClick={handleDownload}>
                    {get_file_size && get_file_size.length > 0 ? (
  <p className="pdf-name">{get_file_size[0].filename}</p>
) : (
  <p className="pdf-name">No file available</p> // or leave this blank or handle it differently
)}

                      <p className="pdf-size">{formattedSize}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {el.message && <p className="chat-text-pdf">{el.message}</p>}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "6px",
              }}
            >
              <p style={{ fontSize: "0.7rem" }}>
                {el.type === "forward" ? (
                  <span>
                    <span style={{ paddingRight: "5px" }}>
                      <ArrowBendDoubleUpRight size={18} />
                    </span>
                    {el.type}ed
                  </span>
                ) : (
                  ""
                )}
              </p>
              <p className="chat-time">
                {convertTimezone(el.time, userTmZone.timezone)}&nbsp;{" "}
                {el.sender_id === authdata?.id ? (
                  <>
                    <FaCircle
                      className={
                        el.status === "read" ||
                        (groupParticipant.length &&
                          groupStatus.length === groupParticipant.length)
                          ? "readcolor"
                          : "unreadcolor"
                      }
                      size={7.5}
                    />
                    <FaCircle
                      style={{ marginLeft: "2px" }}
                      className={
                        el.status === "read" ||
                        (groupParticipant.length &&
                          groupStatus.length === groupParticipant.length)
                          ? "readcolor"
                          : "unreadcolor"
                      }
                      size={7.5}
                    />
                  </>
                ) : null}
              </p>
            </div>
          </div>
        </div>
        {el.sender_id == authdata?.id ? (
          <div class="triangle-right"></div>
        ) : (
          <div class="triangle-left"></div>
        )}
        <Reaction_show isHovered={isHovered} el={el} clickableRef={clickableRef} authdata={authdata} setShowMenu={setShowMenu} showMenu={showMenu} />
      </div>

      {/* PDF Viewer Modal */}
      {pdfUrl && (
        <div className="pdf-viewer-modal">
          <div className="pdf-viewer-content">
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
            <button onClick={() => setPdfUrl(null)}>Close</button>
          </div>
        </div>
      )}

      <ThreeDot
        handleReply={handleReply}
        el={el}
        setReplyData={setReplyData}
        handleDelete={handleDelete}
        setReplyNames={setReplyNames}
        userName={userName}
      />
    </div>
  );
};

const ReplyImg = ({
  el,
  handleDelete,
  selectionMode,
  handleCheckboxChange,
  userName,
}) => {
  const groupStatus = el.group_status ? JSON.parse(el.group_status) : [];
  const groupParticipant = el.groupparticipant
    ? JSON.parse(el.groupparticipant)
    : [];
  let icon;
  let text;
  let source;
  if (el.subtype == "img_reply") {
    icon = <FaImage />;
    text = "image";
    source = (
      <img src={`${BackendApi}/${el.img}`} alt="" className="img-reply-img" />
    );
  } else if (el.subtype == "doc_reply") {
    icon = <IoDocumentText />;
    text = el.img;
    source = <img src={docimg} alt="" className="img-reply-img" />;
  } else if (el.subtype == "audio_reply") {
    icon = <AiFillAudio />;
    text = "Audio";
    source = <img src={audioreply} alt="" className="img-reply-img" />;
  } else if (el.subtype == "video_reply") {
    icon = <FaVideo />;
    text = "Video";
    source = <img src={videoreply} alt="" className="img-reply-img" />;
  } else if (el.subtype == "gif_reply") {
    icon = <HiOutlineGif />;
    text = "Gif";
    source = <img src={el.img} alt="" className="img-reply-img" />;
  } else if (el.subtype == "reply") {
    text = el.message;
  } else if (el.subtype == "") {
    text = <p className="pdf-name">{el.img.split("/").pop()}</p>; //sarath
    icon = <IoDocumentText />;
  }

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `${Assetsapi}/SndRcvdPDF/${el.img}`; // Assuming 'el.img' contains the URL of the PDF file
    // link.download = el.img.split("/").pop(); // Get the file name from the URL
    // link.click();
    window.open(link.href, "_blank");
  };

  const authdata = useSelector((state) => state.auth.user);
  const handleCheckboxClick = (e) => {
    handleCheckboxChange(el.id, el, e.target.checked);
  };

  const allowedAudioExtensions = [".mp3", ".wav", ".ogg", ".aac"];

  function isValidAudioFile(filename) {
    const fileExtension = filename
      .slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
    return allowedAudioExtensions.includes(`.${fileExtension}`);
  }

  const allowedDocExtensions = [
    ".pdf",
    ".doc",
    ".docx",
    ".txt",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".odt",
    ".ods",
    ".odp",
    ".rtf",
  ];

  function isValidDocFile(filename) {
    const fileExtension = filename
      .slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
    return allowedDocExtensions.includes(`.${fileExtension}`);
  }

  const {
    convertTimezone,
    userTmZone,
    UserStatus,
    isValidImageFile,
    isValidVideoFile,
    downloadFileAtURL
  } = useContext(DataContext);

    

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const clickableRef = useRef(null);

  const [fullScreenImg,setFullScreenImg]=useState(null);
  const openFullscreen = (img) => {
    setFullScreenImg(img)
  };
  const closeFullscreen = () => {
    setFullScreenImg(null)
  };

  return (
    <div
      className="reply-img"
      style={{
        justifyContent:
          el.sender_id == authdata?.id ? "flex-end" : "flex-start",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
     {
        showMenu  && el.sender_id == authdata?.id && (
          <Reactions el={el}  setShowMenu={setShowMenu} showMenu={showMenu} />
        )
      }

      {selectionMode && (
        <div>
          <input
            type="checkbox"
            value={el.id}
            onChange={handleCheckboxClick}
            style={{
              width: "20px",
              height: "15px",
              padding: "5px",
              margin: "10px",
            }}
          />
        </div>
      )}

      <GroupProfile el={el} />

      <div
        className={el.sender_id == authdata?.id ? "right" : "left"}
        style={{
          padding:
            UserStatus == "group" && el.sender_id !== authdata?.id
              ? "10px 10px 4px 10px"
              : "10px 10px 4px 10px",
        }}
      >
        <ChatSenderName el={el} />
        <div
          className="d-flex justify-content-between chat-reply"
          style={{ background: "#fff", padding: "8px", borderRadius: "4px" }}
        >
          <div className="right-head">
            <h6>{el.sender_id == authdata?.id ? "You" : `@${userName}`}</h6>
            <div className="d-flex ">
              <div className="reply-icon">{icon}</div>
              <p>{text} </p>
            </div>
          </div>
          {el.subtype != "reply" && (
            <div className="img-reply">
              <div className="img-reply-img">{source}</div>
            </div>
          )}
        </div>
        <div className="" style={{ display: "flex" }}>
          {isValidImageFile(el.reply) ||
          isValidVideoFile(el.reply) ||
          isValidAudioFile(el.reply) ||
          isValidDocFile(el.reply) ? (
            <>
              {isValidImageFile(el.reply) && (
                <div className="image_to_image">
                <img
                src={`${BackendApi}/${el.reply}`}
                alt=""
                className="img-chat"
                width="100%"
               
                onClick={() => openFullscreen(`${BackendApi}/${el.reply}`)}
              />
                </div>
              )}

              {isValidVideoFile(el.reply) && (
                <div className="image_to_video">
                  <video
                    src={`${BackendVidoeApi}/${el.reply}`}
                    controls
                    className="chat-vdo"
                  />
                </div>
              )}

              {isValidAudioFile(el.reply) && `${BackendAudioApi}/${el.img}` && (
                <div className="image_to_image">
                  <AudioPlayerWithVisualization
                    audioFile={`${BackendAudioApi}/${el.reply}`}
                  />
                </div>
              )}
              {isValidDocFile(el.reply) && (
                <div className="image_to_image">
                  
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "70px",
                      background: "#fff",
                      padding: "7px",
                      borderRadius: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={handleDownload}
                    >
                      <div>
                        <img
                          style={{
                            width: "54px !important",
                            height: "54px",
                            top: "8px",
                            left: "4px",
                          }}
                          src={pdficon}
                          alt="PDF-icon"
                        />
                      </div>
                      <div
                        className="text"
                        style={{
                          width: "240px",
                          height: "44px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          marginLeft: "4px",
                        }}
                      >
                        <p className="pdf-name">{el.img.split("/").pop()}</p>
                        <p className="pdf-size">1.0 mb</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <p style={{ fontSize: "0.8rem" }}>{el.reply}</p>
          )}
        </div>
        <p style={{ fontSize: "12px" }}>
          {el.type === "forward" ? (
            <span>
              <span style={{ paddingRight: "5px" }}>
                <ArrowBendDoubleUpRight size={18} />
              </span>
              {el.type}ed
            </span>
          ) : (
            ""
          )}
        </p>
        <p className="chat-time chattime-reply" style={{ fontSize: "0.7rem" }}>
          {convertTimezone(el.time, userTmZone.timezone)}{" "}
          {el.sender_id === authdata?.id ? (
            <>
              <FaCircle
                className={
                  el.status === "read" ||
                  (groupParticipant.length &&
                    groupStatus.length === groupParticipant.length)
                    ? "readcolor"
                    : "unreadcolor"
                }
                size={7}
              />
              <FaCircle
                style={{ marginLeft: "2px" }}
                className={
                  el.status === "read" ||
                  (groupParticipant.length &&
                    groupStatus.length === groupParticipant.length)
                    ? "readcolor"
                    : "unreadcolor"
                }
                size={7}
              />
            </>
          ) : null}
        </p>
        {el.sender_id == authdata?.id ? (
          <div class="triangle-right"></div>
        ) : (
          <div class="triangle-left"></div>
        )}
        <Reaction_show isHovered={isHovered} el={el} clickableRef={clickableRef} authdata={authdata} setShowMenu={setShowMenu} showMenu={showMenu} />
      </div>
      <ThreeDot el={el} handleDelete={handleDelete} />
      {fullScreenImg !== null && (
        <div className="fullscreen-view" style={{position:"fixed",zIndex:"9999"}}>
          <div className="top-controls">
            <button className="delete-btn" onClick={()=>{downloadFileAtURL(`${fullScreenImg}`)}}><DownloadSimple size={32} /></button>
            <button className="close-btn" onClick={closeFullscreen}><X size={32} /></button>
          </div>
          
          <div className="fullscreen-image">
            <img src={`${fullScreenImg}`} alt={`Fullscreen ${fullScreenImg}`} />
          </div>
        </div>
      )}
    </div>
  );
};

const GroupProfile = ({ el }) => {

  const { convertTimezone,userTmZone,UserStatus } = useContext(DataContext);
  const authdata = useSelector((state) => state.auth.user);
  return (
    <>
      {
      el.sender_id !== authdata?.id && UserStatus == 'group' && (
        <div
        style={{
          backgroundColor: "gray",
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          overflow: "hidden",
         marginRight:"5px"
        }}>
        <img
          style={{ height: "30px", width: "30px" }}
          src={`${ProfileImgApi}/${el.userprofile}`}
          alt=""
        />
      </div>
      )
    }
    </>
  )
}


const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};
const ChatSenderName =   ({ el }) => {
  
  const { UserStatus } = useContext(DataContext);
  const authdata = useSelector((state) => state.auth.user);

 
  
  const usernameColor = useMemo(() => stringToColor(el.username), [el.username]);

  return (
    <>
      {
          UserStatus == 'group' && el.sender_id !== authdata?.id && (
            <div>
            <p className="grouptext" style={{ color: usernameColor }}>{el.username}</p>
           </div>
          )
        }
    </>
  )
}


const convertInfoTime = (updated_date) =>{
  const dateStr = updated_date;
const date = new Date(dateStr);

// Options for formatting the date
const options = {
  day: '2-digit',
  month: 'short',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
};

// Formatting the date
const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

// Customize the format as "26 Sep, 03:04 PM"
const [monthDay, time] = formattedDate.split(', ');
const [month, day] = monthDay.split(' ');
const finalFormattedDate = `${day} ${month}, ${time.toLowerCase()}`;

return finalFormattedDate;

}

const ThreeDot = ({
  handleReply,
  ReplyData,
  setReplyData,
  el,
  userName,
  setReplyNames,
  handleDelete,
}) => {
  const {
    setEdit,
    setEditMsg,
    setInputfield,
    setTextReply,
    convertTimezone,
    userTmZone,
    UserStatus,
    setFwmessage,
    setForward,
    forwardListData
  } = useContext(DataContext);

  const authdata = useSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [groupusers, setgroupusers] = useState(() => {
    try {
      return el.group_status ? JSON.parse(el.group_status) : [];
    } catch (error) {
      console.error("Failed to parse group_status:", error);
      return [];
    }
  });
  
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const open = Boolean(anchorEl);
  
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [GroupinfoMessage, setGroupinfoMessage] = useState(false);
  const [deliveredList, setDeliveredList] = useState([]);
  const submenuOpen = Boolean(submenuAnchorEl);

  const toast = useRef(null);

  const handleSubmenuClick = async(event) => {
   
    setAnchorEl(null); // Close the main menu
    setSubmenuAnchorEl(event.currentTarget);
    const res = await axios.get(`${Nodeapi}/groupparticipant?chatmaster_id=${el.chatmaster_id}`);
    setGroupinfoMessage(res.data.data.message);
    // console.log(res.data.data.message);
  };

  useEffect(() => {
    const fetchParticipants = async () => {
     
      if (submenuAnchorEl) {

        try {
          const chatmaster_id = el.chatmaster_id; // Replace this with the actual chatmaster_id if needed
          const res = await axios.get(
            `${Nodeapi}/groupparticipant?chatmaster_id=${chatmaster_id}`
          );

          const participants = res.data.data.message.map((participant) => ({
            name: participant.name,
            profile: participant.profile,

            time: el.time, // Ensure this field exists
            status: el.status, // Ensure this field exists
          }));

          setDeliveredList(participants);

        } catch (error) {
          console.error("Error fetching participants:", error);
          // Handle the error appropriately
        }
      }
    };

    fetchParticipants();
  }, [submenuAnchorEl]);

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
    setDeliveredList([]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReplyClose = () => {
    handleClose();
    handleReply();
    setReplyData(el);
    setTextReply(el.subtype);
    const replyname = el.sender_id == authdata?.id ? "You" : `@${userName}`;
    setReplyNames(replyname);
  };
  const handleDeleteDialogOpen = () => {
    setDeleteDialogVisible(true);
    handleClose();
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogVisible(false);
  };

  const handleDeleteForMe = () => {
    setDeleteDialogVisible(false);
  };
  const handleDeleteForAll = () => {};

  const handleCopy = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        handleClose();
        console.log("Message copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy message: ", err);
      });
  };

  const handleEditClick = () => {
    setAnchorEl(false);
    setInputfield(el.message);
    setEditMsg(el);
    setEdit(true);
  };

  const allowedTime = 5 * 60 * 1000; // 5 minutes in milliseconds
  const currentTime = new Date();
  const createdAt = new Date(el.date_added);

  const timeDifference = currentTime - createdAt;

   

  useEffect(() => {
    const receiveMessageHandler = (data) => {
     
      
      try {
        if (data.message === "message_info") {
        
         setgroupusers(data.messageInfo)
        }
      } catch (error) {
        console.error("Error in receiveMessageHandler:", error);
      }
    };

    if (socket.connected) {
      socket.on("receive_message", receiveMessageHandler);
    } else {
      socket.on("connect", () => {
        socket.on("receive_message", receiveMessageHandler);
      });
    }

    // Cleanup function
    return () => {
      socket.off("receive_message", receiveMessageHandler);
    };
  }, [socket]);


  

  return (
    <div className="msg-three" style={{ position: "relative !important" }}>
      <button
        className="threedots-msg"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <DotsThreeVertical />
      </button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {el.sender_id === authdata?.id && UserStatus == "group" && (
          <MenuItem onClick={handleSubmenuClick}>
            <Info size={16} style={{ marginRight: "8px" }} />
            Info
          </MenuItem>
        )}
        <MenuItem
          onClick={() => handleCopy(el.message ? el.message : el.reply)}
        >
          <Copy size={16} style={{ marginRight: "8px" }} />
          Copy
        </MenuItem>
        <MenuItem onClick={handleReplyClose}>
          <ArrowBendUpLeft size={16} style={{ marginRight: "8px" }} />
          Reply
        </MenuItem>
        <MenuItem onClick={()=>{setFwmessage([el]);setForward(true); forwardListData(authdata?.id);}}>
        <ArrowBendDoubleUpRight size={16} style={{ marginRight: "8px" }} />
        Forward
        </MenuItem>
        {el.sender_id === authdata?.id &&
        el.subtype === "" &&
        el.type !== "forward" ? (
          <MenuItem onClick={handleEditClick}>
            <MdEdit size={16} style={{ marginRight: "8px" }} />
            Edit
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleDeleteDialogOpen}>
          <Trash size={16} style={{ marginRight: "8px" }} />
          Delete
        </MenuItem>
      </Menu>

      <Menu
      id="submenu"
      anchorEl={submenuAnchorEl}
      open={submenuOpen}
      onClose={handleSubmenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className="sub-menu"
    >
      <div style={{height:"350px", width:"250px", backgroundColor:"#FFFFFF", borderRadius:"5px", }}>
        <p style={{
      paddingTop: "7px",
      paddingBottom: "10px",
      paddingLeft: "15px",
      color: "#2196F3",
      position: "sticky",
      top: 0,
      backgroundColor: "#FFFFFF",
      zIndex: 10, // Ensure the p tag is above other elements
      margin: 0 // Remove any default margins
    }}>Delivered to</p>
       {
  GroupinfoMessage && GroupinfoMessage
    .filter(infouser => infouser && infouser.id !== authdata?.id)
    .sort((a, b) => {
      const userA = Array.isArray(groupusers) ? groupusers.find(user => user.id === a.id) : null;
      const userB = Array.isArray(groupusers) ? groupusers.find(user => user.id === b.id) : null;

      const dateA = userA ? new Date(userA.date_added) : new Date(0);
      const dateB = userB ? new Date(userB.date_added) : new Date(0);

      return dateB - dateA; // Sort in descending order (latest date first)
    })
    .map((infouser) => {

      const matchedUser = Array.isArray(groupusers) 
          ? groupusers.find(user => user.id === infouser.id) 
          : null;

        

              return (
                <div className="delivered-item" style={{display:"flex",justifyContent:"space-between"}}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={`${ProfileImgApi}/${infouser.profile}`}
              alt="Friend Name"
              className="profile-pic"
              style={{ borderRadius: "50%", marginRight: "10px",objectFit:"cover" }}
            />
            <div>
            <div className="name">{infouser.name}</div>
            <div style={{ fontSize:"11px" }}>{matchedUser ? convertInfoTime(matchedUser.date_added) : null}</div>
            </div>
            
          </div>
          <div className="time" style={{display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
         
            <div className="circle">
            <FaCircle className={Array.isArray(groupusers) && groupusers.some(user => user.id === infouser.id) ? 'info_readcolor' : 'info_unreadcolor'} style={{ marginLeft: "2px" }} />
            <FaCircle className={Array.isArray(groupusers) && groupusers.some(user => user.id === infouser.id) ? 'info_readcolor' : 'info_unreadcolor'} style={{ marginLeft: "2px" }} />
              
            </div>
          </div>
        </div>
        
              )
            })
          }
       
      </div>
    </Menu>




      <Dialog
        header="Confirm Delete"
        visible={deleteDialogVisible}
        onHide={handleDeleteDialogClose}
        style={{ width: "25vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>Are you sure you want to delete this message?</div>
        <div className="flex  mt-3">
          {el.sender_id === authdata?.id &&
            el.message !== "This message was deleted" && (
              <PrimeButton
                className="p-button-delete"
                onClick={() => {
                  handleDelete(el, "deleteForEveryone");
                  setDeleteDialogVisible(false);
                }}
              >
                Delete For Everyone
              </PrimeButton>
            )}

          <PrimeButton
            className="p-button-delete"
            onClick={() => {
              handleDelete(el, "deleteForMe");
              setDeleteDialogVisible(false);
            }}
          >
            Delete for me
          </PrimeButton>
          <PrimeButton
            className="p-button-delete"
            onClick={handleDeleteDialogClose}
          >
            Cancel
          </PrimeButton>
        </div>
      </Dialog>
    </div>
  );
};

export { TextMsg, Timeline, MediaMsg, VideoMsg, AudioMsg, PdfMsg,ReplyImg,LinkMsg,VideoCall,AudioCall,GifMsg,CreateGroupTimeline,MultipleMediaMsg };
