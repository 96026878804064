import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Modal } from "@mui/material";
import { Nodeapi, ProfileImgApi } from "../config/serverUrl";
import axios from "axios";
import { Toast } from "primereact/toast";
import PersonalDetailsForm1 from "../Component/Profile/PersonalDetails";
import UpdatedContactinfo from "../Component/Profile/UpdatedContactinfo";
import Documentation from "../Component/Profile/Documentation";
import RelationshipDetails from "../Component/Profile/RelationshipDetails";
import UploadDocument from "../Component/Profile/UploadDocument";
import EducationForm from "../Component/Profile/EducationForm";
import JobDetails from "../Component/Profile/JobDetails";
import HomeApplianceFormPage from "../Component/Profile/HomeApplianceForm";
import VehicleDetailsFormPage from "../Component/Profile/VehicleDetailsForm";
import PropertyDetailFormPage from "../Component/Profile/PropertyDetailForm";
import AdvertisingDashboard from "../Component/Profile/AdvertisingDashboard";
import Jobusestates from "../useStates/JobUsestate";
import ExpenseTracker from "../Component/Profile/ExpenseTracker";
import { loginUser } from "../react-redux/slice";
import { style } from "../Styles/Jobformstyle";
import "./vehicles.css";
import { IoAddCircle } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import Swal from "sweetalert2";
import { FaCircleInfo } from "react-icons/fa6";
import TabsGroup from "../Component/Profile/TabsGroup";

function Job() {
  const dispatch = useDispatch();
  const authdata = useSelector((state) => state.auth.user);
  const userid = authdata.userid;
  const { selectedImage1, setSelectedImage1 } = Jobusestates();
  const [profiledata, setprofiledata] = useState([]);
  const fileInputRef = useRef(null);
  const toast = useRef(null);
  const [Name, setName] = React.useState(false);
  const handleNameClose = () => setName(false);
  const [data, setdata] = useState({
    user_name: "",
    name: "",
    fathername: "",
    familyname: "",
  });
  const [activeSection, setActiveSection] = useState("personal");
  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    const res = await axios.get(
      `${Nodeapi}/getprofiledata?userid=${authdata.userid}`
    );
    const data1 = res.data.result;

    setprofiledata(data1[0]);
    setdata(data1[0]);
  };

  const handlesubmit = async () => {
    try {
      const res = await axios.post(`${Nodeapi}/updateprofiledata`, {
        ...data,
        userid: userid,
      });

      const data1 = res.data;

      if (data1.success == true) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Updated Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        setName(false);
        fetchdata();
      }

      console.log("datadatadata:", data1);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handlechange = (e) => {
    setdata({ ...data, [e.target.id]: e.target.value });
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleProfileFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage1(event.target.result);
    };

    const imgData = {
      id: authdata?.id,
      profile_image: selectedFile,
      token: authdata?.token,
    };
    const res = await axios.post(`${Nodeapi}/upload_profile_img`, imgData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.data) {
      if (res.data.code == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.data.data.message,
          life: 3000,
        });
        await dispatch(loginUser(res.data.data.user));
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: res.data.data.message,
        life: 3000,
      });
    }
  };

  const handleedit = () => {
    setName(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="job_jb" style={{ overflow: "auto", padding: "0 16px" }}>
        <div className="image_cards mt-4">
          <div
            style={{
              position: "relative",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "28vh",
            }}
          >
            <label htmlFor="fileUpload">
              <div
                style={{
                  position: "relative",
                }}
                className="img-container"
              >
                <img
                  src={
                    authdata && authdata.profile_image
                      ? `${ProfileImgApi}/${authdata.profile_image}`
                      : ""
                  }
                  alt="Selected Profile"
                  className="profile-img"
                  style={{
                    objectFit: "cover",
                    boxShadow: "rgba(0, 0, 0, 0.09) 0px 4px 50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                />
                {authdata && (
                  <div
                    className="camera-icon"
                    style={{
                      position: "absolute",
                      bottom: "0",
                      right: "0",
                      margin: "10px",
                      overflow: "hidden",
                    }}
                    onClick={handleImageClick}
                  >
                    <IoAddCircle
                      style={{
                        // width: "20px",
                        // height: "20px",
                        cursor: "pointer",
                        overflow: "hidden",
                        color: "#1877f2",
                        fontSize: "33px",
                      }}
                    />
                    {/* Hidden file input */}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleProfileFileChange}
                    />
                  </div>
                )}
              </div>
            </label>

            <div className="ms-2 profile-details" style={{ display: "flex" }}>
              <h5 className=" text-center">
                {profiledata ? (
                  <>
                    <table className="test-table">
                      <tbody className="">
                        <tr>
                          <td
                            className="profile-content"
                            style={{
                              textAlign: "left",
                              fontWeight: "bolder",
                              fontFamily: "Roboto, sans-serif",
                            }}
                          >
                            User Name
                          </td>
                          <td
                            className="profile-content profile-cmt-view"
                            style={{
                              textAlign: "left",
                              fontFamily: "Roboto, sans-serif",
                            }}
                          >
                            {profiledata?.name?.charAt(0).toUpperCase() +
                              profiledata?.name?.slice(1)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="profile-content"
                            style={{
                              textAlign: "left",
                              fontWeight: "bolder",
                              // fontFamily: "Roboto, sans-serif",
                            }}
                          >
                            Father Name
                          </td>
                          <td
                            className="profile-content profile-cmt-view"
                            style={{
                              textAlign: "left",
                              // fontFamily: "Roboto, sans-serif",
                            }}
                          >
                            {profiledata?.fathername?.charAt(0).toUpperCase() +
                              profiledata?.fathername?.slice(1)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="profile-content"
                            style={{
                              border: "1px ",
                              textAlign: "left",
                              fontWeight: "bolder",
                              paddingRight: "10px",
                              // fontFamily: "Roboto, sans-serif",
                            }}
                          >
                            Family Name
                          </td>
                          <td
                            className="profile-content profile-cmt-view"
                            style={{
                              border: "1px ",
                              textAlign: "left",
                              // fontFamily: "Roboto, sans-serif",
                            }}
                          >
                            {profiledata?.familyname?.charAt(0).toUpperCase() +
                              profiledata?.familyname?.slice(1)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="profile-content"
                            style={{
                              border: "1px ",
                              textAlign: "left",
                              fontWeight: "bolder",
                              paddingRight: "10px",
                              // fontFamily: "Roboto, sans-serif",
                            }}
                          >
                            KID Number
                          </td>
                          <td
                            className="profile-content profile-cmt-view"
                            style={{
                              border: "1px ",
                              textAlign: "left",
                              // fontFamily: "Roboto, sans-serif",
                            }}
                          >
                            {authdata?.userid}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  "Profile"
                )}

                <Modal
                  open={Name}
                  onClose={handleNameClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
          <Box
                    sx={style}
                    style={{
                      height: "52vh",
                    }}
                    className="w-23rem md:w-30rem scrollBarHidden"
                  >
                    <div className="col-8">
                      <label className="labels mb-1">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={data?.user_name}
                        onChange={handlechange}
                      />
                      <div className="text-blue-500 text-xs relative mt-2">
                        <FaCircleInfo className="absolute top-[3px] mt-1" />
                        <span className="ml-4">
                          This is your user login username
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="col-6 ">
                        <label className="labels mb-1">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={data?.name}
                          onChange={handlechange}
                        />
                      </div>
                      <div className="col-6 ">
                        <label className="labels mb-1">Father Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fathername"
                          value={data?.fathername}
                          onChange={handlechange}
                        />
                      </div>
                    </div>
                    <div className="col-6 ">
                      <label className="labels mb-1">Family Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="familyname"
                        value={data?.familyname}
                        onChange={handlechange}
                      />
                    </div>
                    <div className="text-center mt-3">
                      <button
                        className="btn btn-danger me-2"
                        onClick={handleNameClose}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        value="add"
                        className="btn btn-primary"
                        onClick={handlesubmit}
                      >
                        Update
                      </button>
                    </div>
                  </Box>
                </Modal>
              </h5>
              <div className="profile-edit">
                {/* <button className="edit_btnn">Edit</button> */}
                <MdModeEdit className="edit_btnn" onClick={handleedit} />
              </div>
            </div>
          </div>
          <div className="tabsgrp">
            <TabsGroup
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
          </div>

          <div className="tabs-details-jd">
            {activeSection === "personal" && <PersonalDetailsForm1 />}
            {activeSection === "contact" && <UpdatedContactinfo />}
            {activeSection === "education" && <EducationForm />}
            {activeSection === "job" && <JobDetails />}
            {activeSection === "homeAppliance" && <HomeApplianceFormPage />}
            {activeSection === "vehicle" && <VehicleDetailsFormPage />}
            {activeSection === "property" && <PropertyDetailFormPage />}
            {activeSection === "uploadDocument" && <UploadDocument />}
            {activeSection === "expenses" && <ExpenseTracker />}
            {activeSection === "advertising" && <AdvertisingDashboard />}
            {activeSection === "documentation" && <Documentation />}
            {activeSection === "relationship" && <RelationshipDetails />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Job;
