import React from "react";
import { IoShareSocialOutline } from "react-icons/io5";
import { PiShareFat } from "react-icons/pi";
import { IoIosHeartEmpty } from "react-icons/io";

function MainCard(prop) {
  const { cur, handleShare, cineUrl } = prop;
  return (
    <div key={cur.id} class="vehiGal_right">
      <div className="movie-post-card-design-content-side">
        <h5>{cur.title}</h5>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100px",
          }}
        >
          <IoShareSocialOutline
            style={{ color: "#2196f3", cursor: "pointer" }}
            className="cine-post-share"
            onClick={() => handleShare(cineUrl)}
          />
          <PiShareFat
            className="cine-post-share"
            style={{ color: "#2196f3", cursor: "pointer" }}
          />
          <IoIosHeartEmpty
            className="cine-post-share"
            style={{ color: "#2196f3", cursor: "pointer" }}
          />
        </span>
      </div>
      <p className="cine-post-card-design-content-director">
        <span className="cine-post-content-director-key"> Director</span>
        <span style={{ marginLeft: "42px", marginRight: "5px" }}>:</span>
        <span className="cine-post-content-director-val">{cur.director}</span>
      </p>
      <p className="cine-post-card-design-content-director">
        <span className="cine-post-content-director-key"> Writer</span>
        <span style={{ marginLeft: "57px", marginRight: "5px" }}>:</span>
        <span className="cine-post-content-director-val">{cur.writer}</span>
      </p>

      <p className="movie-post-content-release">
        <span className="movie-post-design-content-release-key">
          {" "}
          Release Date
        </span>
        <span>:</span>
        <span className="movie-post-design-content-release-value">
          {cur.release_date}
        </span>
      </p>

      <p className="cine-post-card-design-content-director">
        <span className="cine-post-content-director-key"> Music By</span>
        <span style={{ marginLeft: "40px", marginRight: "10px" }}>:</span>
        <span className="cine-post-content-director-val">
          {cur.music_director}
        </span>
      </p>
      <p className="cine-post-card-design-content-director">
        <span className="cine-post-content-director-key"> Producer</span>
        <span style={{ marginLeft: "38px", marginRight: "10px" }}>:</span>
        <span className="cine-post-content-director-val">{cur.producer}</span>
      </p>

      <p className="cine-post-card-design-content-director">
        <span className="cine-post-content-director-key"> Company</span>
        <span style={{ marginLeft: "35px", marginRight: "10px" }}>:</span>
        <span className="cine-post-content-director-val">
          {cur.production_company}
        </span>
      </p>

      <p
        className="movie-post-card-design-content-genre"
        style={{ marginTop: "12px" }}
      >
        <span className="movie-post-card-design-content-genre-key">
          {" "}
          Genres
        </span>
        <span style={{ marginLeft: "52px" }}>:</span>
        <span style={{ marginLeft: "5px" }}>
          {" "}
          {cur.genre
            .replace(/^"|"$/g, "")
            .split(",")
            .map((val, idx) => (
              <span
                className="movie-post-card-design-content-genre-value"
                key={idx}
                style={{
                  marginLeft: "2px",
                  // display: "flex",
                  // flexDirection: "column",
                  // flexWrap: "wrap",
                }}
              >
                {val.trim()}
              </span>
            ))}
        </span>
      </p>

      <div
        className="cine-post-card-design-content-censor-side-one"
        style={{ marginTop: "10px" }}
      >
        <span className="cine-post-card-design-key-censor-certificate">
          {cur.movie_certificate}
        </span>
        <span className="cine-post-card-design-content-censor-eclipse-two-one"></span>

        <span
          className="cine-post-card-design-content-lan-value"
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "8px",
          }}
        >
          {cur.language
            .replace(/^"|"$/g, "")
            .split(",")
            .map((val, idx) => (
              <span
                key={idx}
                style={{
                  marginLeft: "4px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {val.trim()}
              </span>
            ))}
        </span>
      </div>
    </div>
  );
}

export default MainCard;
