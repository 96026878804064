import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataContext } from "../Admin/DataContext";
import axios from "axios";
import Swal from 'sweetalert2';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import '../Admin/Admin.css'
import '../Admin/View_Adpost.css'
import { Image } from 'primereact/image';
import { Assetsapi, Nodeapi } from '../config/serverUrl'
import { Getimagenames } from '../routes/profileRoutes'
import { Galleria } from 'primereact/galleria';
import { GrContact } from "react-icons/gr";
import { useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function View_HomeAd_Details() {

  const {
    Ad_Details,
    setdisplaypost,
    displaypost,
    home_listimages,
    selectedData,
    setview_adpost,
    view_adpost,
    sethome_listimages,
    setOpenchat,
    setChatDetails,
    category,
    postiddata
  } = useContext(DataContext);

  const navigate = useNavigate()

  const galleria = useRef(null);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImageIndex1, setSelectedImageIndex1] = useState(0);
  const authdata = useSelector((state) => state.auth.user);
  const [products, setProducts] = useState([]);
  const [Similarposts, setSimilarposts] = useState([])
  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  const itemTemplate = (item) => {
    return <img src={`${Assetsapi}/adimages/${item}`} alt="" style={{ width: '100%' }} />;
  };

  const thumbnailTemplate = (item) => {
    return <img src={`${Assetsapi}/adimages/${item}`} alt="" style={{ display: 'block', width: '80px', height: '80px' }} />;
  };


  useEffect(() => {
    const fetchdata = async () => {
      const res = await axios.get(`${Nodeapi}/getsimilarads`)
      const data = res.data.data.result
      console.log('Data:', data);

      const filterdata = data.filter((item) => item.post_category === category);
      const finaldata = filterdata.filter((data) => data.post_id !== postiddata)
      console.log('finaldata:', finaldata);
      console.log('view_adpost.post_id:', view_adpost.post_id);
      setSimilarposts(finaldata)
    }
    fetchdata()
  }, [])

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    if (galleria.current) {
      galleria.current.show();
    }
  };
  const handleImageClick1 = (index) => {
    setSelectedImageIndex1(index);
    if (galleria.current) {
      galleria.current.show();
    }
  };
  const handlenext = async () => {
    const currentIndex = displaypost.indexOf(view_adpost[0]);

    if (currentIndex < displaypost.length - 1) {
      const newIndex = currentIndex + 1;
      // setCurrentIndex(newIndex);

      const val = [displaypost[newIndex]]
      setview_adpost(val)
      const value = displaypost[newIndex]

      try {
        const data = await Getimagenames(value.post_id)
        const imageFileNames = data.map(image => image.file_name);
        sethome_listimages(imageFileNames)

      } catch (error) {
        console.log('error:', error);
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: 'No further ads from this user.',
        showConfirmButton: false,
        timer: 2000
      });
    }
  };


  const handleback = async () => {
    setview_adpost("")
    const currentIndex = displaypost.indexOf(view_adpost[0]);
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      // setCurrentIndex(newIndex);
      let val = [displaypost[newIndex]];
      setview_adpost(val);
      const value = displaypost[newIndex]
      try {
        const data = await Getimagenames(value.post_id)
        const imageFileNames = data.map(image => image.file_name);
        sethome_listimages(imageFileNames)

      } catch (error) {
        console.log('error:', error);
      }
    } else {
      navigate('/');
    }
  }


  const handlecontact = async (userid) => {
    try {
      const senderdata = authdata.id;
      const receiverdata = Number(userid);

      const insertdata = {
        sender_id: senderdata,
        receiver_id: receiverdata
      }

      const params = new URLSearchParams({
        sender_id: senderdata,
        receiver_id: receiverdata
      }).toString();
      console.log("error");
      const res = await axios.get(`${Nodeapi}/checkchatmasterid?${params}`);
      const data = res.data.results[0];
      const username = res.data.username[0].name



      if (data) {
        setOpenchat(true);
        setChatDetails({
          retrievedId: senderdata,
          clickedid: receiverdata,
          chatmasterid: data,
          username: username
        });
        navigate("/main/Chat")
      } else {
        console.log("error1");
        const response = await axios.post(`${Nodeapi}/StarChat`, insertdata)

        const val = response.data.data.chatmasterid
        console.log("value:", val);
        setOpenchat(true);
        setChatDetails({
          retrievedId: senderdata,
          clickedid: receiverdata,
          chatmasterid: val,
          username: username
        });
        navigate("/main/Chat")

      }


    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      <div style={{ padding: "0px" }}>
        {/* <h3 style={{ color:0"#2196F3" }}>Advertisement Details</h3> */}




        {view_adpost?.map((listing, index) => (

          <div className='adpost_details' key={index} >


            <div className='justify-content-between' style={{ display: "flex ", marginTop: "-27px" }}>
              <div style={{ display: "flex" }}>
                <MdOutlineArrowBackIos className='arrow' />
                <button className='back_view_btn' onClick={handleback}>
                  Back
                </button>

              </div>

              {/* <div style={{ display: "flex", alignItems: "center", marginTop: "18px" }}>
                <button className='next_view_btn' onClick={handlenext} >
                  Next
                </button>
                <MdKeyboardArrowRight style={{ fontSize: "31px", color: "#2196F3" }} />
              </div> */}
            </div>


            <p style={{ color: "#2196F3", fontSize: "22px", marginLeft: "20px", marginTop: "15px" }}>Ads Details:</p>
            <div className='postcontent' >


              <div style={{ display: "flex", flexWrap: "wrap", width: "22%" }}>

                {home_listimages.map((src, index) => (
                  <div key={index}>
                    <Image
                      src={`${Assetsapi}/adimages/${src}`}
                      alt={`House ${index + 1}`}
                      className="card-image img-fluid"
                      zoomSrc={`${Assetsapi}/adimages/${src}`}
                      height="80"
                      onClick={() => handleImageClick(index)}
                      width="80"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))}
                <Galleria
                  ref={galleria}
                  value={home_listimages}
                  numVisible={9}
                  style={{ maxWidth: '50%' }}
                  circular
                  fullScreen
                  showItemNavigators
                  showThumbnails={false}
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                  activeIndex={selectedImageIndex}
                  onItemChange={(e) => setSelectedImageIndex(e.index)}
                />
              </div>
              <div style={{ marginLeft: "15px" }}>
                <div style={{ display: "flex" }}>
                  <h4 style={{ color: "#2196F3", marginLeft: "-3px" }}>Title:</h4>

                  <h5 style={{ color: "#8b8b8b", width: "250px", marginLeft: "6px", marginTop: "3px" }}>{listing.post_title}</h5>

                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", marginTop: "4px" }}>
                    <p >Category:</p>
                    <p style={{ color: "#8b8b8b", marginLeft: "6px" }}>{listing.post_category}</p>
                  </div>

                  <div style={{ display: "flex", marginTop: "5px", marginLeft: "20px" }}>
                    <p style={{}}>Price:  </p>
                    <p style={{ color: "#8b8b8b", marginLeft: "6px" }}> ₹ {listing.post_price}</p>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", marginTop: "12px" }}>
                    <p>Date:</p>
                    <p style={{ color: "#8b8b8b", marginLeft: "6px" }}>
                      {(() => {
                        const date = new Date(listing.created_at);
                        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
                        return formattedDate;
                      })()}
                    </p>
                  </div>


                  <div style={{ display: "flex", marginTop: "12px", marginLeft: "23px" }}>
                    <p style={{}}>Location:</p>
                    <p style={{ color: "#8b8b8b", marginLeft: "6px" }}>{listing.post_location.toUpperCase()}</p>
                  </div>
                </div>


                <div style={{ display: "flex", marginTop: "12px" }}>
                  <p style={{}}>Description:</p>
                  <p style={{ color: "#8b8b8b", marginLeft: "6px", width: "381px" }}>{listing.post_description}</p>
                </div>



              </div>
              <div style={{ marginLeft: "370px", marginTop: "-22px" }} >

                <button onClick={() => handlecontact(listing.userid)} className='call_btn'>Contact

                </button>
                {/* <button className='chat_btn'>Chat</button> */}
              </div>

            </div>

            <h4 style={{ marginTop: "35px",color:"#dc3545" }}>Similar Ads:</h4>

            {Similarposts.map((item, index) => (
              <div style={{ marginTop: "30px" }} key={index}>
                <div className='justify-content-between' style={{ display: "flex ", marginTop: "-27px" }}>
                  {/* <div style={{ display: "flex" }}>
                <MdOutlineArrowBackIos className='arrow' />
                <button className='back_view_btn' onClick={handleback}>
                  Back
                </button>

              </div> */}

                  {/* <div style={{ display: "flex", alignItems: "center", marginTop: "18px" }}>
                <button className='next_view_btn' onClick={handlenext} >
                  Next
                </button>
                <MdKeyboardArrowRight style={{ fontSize: "31px", color: "#2196F3" }} />
              </div>*/}
                </div>


                <p style={{ color: "#2196F3", fontSize: "22px", marginLeft: "20px", marginTop: "15px" }}>Ads Details:</p>
                <div className='postcontent' >


                  <div style={{ display: "flex", flexWrap: "wrap", width: "22%" }}>

                    {item.images.map((src, index) => (
                      <div key={index}>
                        <Image
                          src={`${Assetsapi}/adimages/${src}`}
                          alt={`House ${index + 1}`}
                          className="card-image img-fluid"
                          zoomSrc={`${Assetsapi}/adimages/${src}`}
                          height="80"
                          onClick={() => handleImageClick1(index)}
                          width="80"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    ))}
                    <Galleria
                      ref={galleria}
                      value={item.images}
                      numVisible={9}
                      style={{ maxWidth: '50%' }}
                      circular
                      fullScreen
                      showItemNavigators
                      showThumbnails={false}
                      item={itemTemplate}
                      thumbnail={thumbnailTemplate}
                      activeIndex={selectedImageIndex1}
                      onItemChange={(e) => setSelectedImageIndex1(e.index)}
                    />
                  </div>
                  <div style={{ marginLeft: "15px" }}>
                    <div style={{ display: "flex" }}>
                      <h4 style={{ color: "#2196F3", marginLeft: "-3px" }}>Title:</h4>

                      <h5 style={{ color: "#8b8b8b", width: "250px", marginLeft: "6px", marginTop: "3px" }}>{item.post_title}</h5>

                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", marginTop: "4px" }}>
                        <p >Category:</p>
                        <p style={{ color: "#8b8b8b", marginLeft: "6px" }}>{item.post_category}</p>
                      </div>

                      <div style={{ display: "flex", marginTop: "5px", marginLeft: "20px" }}>
                        <p style={{}}>Price:  </p>
                        <p style={{ color: "#8b8b8b", marginLeft: "6px" }}> ₹ {item.post_price}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", marginTop: "12px" }}>
                        <p>Date:</p>
                        <p style={{ color: "#8b8b8b", marginLeft: "6px" }}>
                          {(() => {
                            const date = new Date(item.created_at);
                            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
                            return formattedDate;
                          })()}
                        </p>
                      </div>


                      <div style={{ display: "flex", marginTop: "12px", marginLeft: "23px" }}>
                        <p style={{}}>Location:</p>
                        <p style={{ color: "#8b8b8b", marginLeft: "6px" }}>{item.post_location.toUpperCase()}</p>
                      </div>
                    </div>


                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <p style={{}}>Description:</p>
                      <p style={{ color: "#8b8b8b", marginLeft: "6px", width: "381px" }}>{item.post_description}</p>
                    </div>



                  </div>
                  <div style={{ marginLeft: "370px", marginTop: "-22px" }} >

                    <button onClick={() => handlecontact(listing.userid)} className='call_btn'>Contact

                    </button>
                    {/* <button className='chat_btn'>Chat</button> */}
                  </div>

                </div>
              </div>
            ))}

         <div className='nextpage'>
        
         <Stack spacing={2}>
        <Pagination count={10} shape="rounded" />
       </Stack>
         </div>
     

          </div>

        ))}



      </div>

      <div>

      </div>

    </>
  )
}

export default View_HomeAd_Details