import React, { useState, useContext, useEffect, useRef } from "react";
import { ImageList, ImageListItem, IconButton } from "@mui/material";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { DataContext } from "../../Admin/DataContext";
import { Assetsapi } from "../../config/serverUrl";

const VehicleGallery = ({ datavalue }) => {
  const { view_adpost, home_listimages } = useContext(DataContext);

  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const imageListRef = useRef(null);

  useEffect(() => {
    fetchImages();
  }, [datavalue]);
  const fetchImages = async () => {
    try {
      const images = datavalue[0].images.map((src) => ({
        src: `${Assetsapi}/adimages/${src}`,
        alt: `Image ${src}`,
      }));
      setImages(images);
      if (images.length > 0) {
        setMainImage(images[0].src);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const scrollLeft = () => {
    if (imageListRef.current) {
      imageListRef.current.scrollBy({
        left: -300, 
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (imageListRef.current) {
      imageListRef.current.scrollBy({
        left: 300, 
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className="gallery-demo"
      style={{
        margin: "0px 30px",
        backgroundColor: "#fff",
        width:"800px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        className="gallery-demo-card"
        style={{
          background: "#080808BD",
          height: "410px",
          width: "800px",
          borderRadius: "5px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={mainImage}
            alt="Main"
            style={{
              width: "417px",
              height: "410px",
              borderRadius: "5px",
            }}
          />
        </div>
      </div>
      <div
        className="image-list-container mt-4"
        style={{ position: "relative" }}
      >
        <IconButton
          onClick={scrollLeft}
          style={{
            position: "absolute",
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
          }}
        >
          <ArrowBackIos style={{marginLeft:"3px"}}/>
        </IconButton>
        <ImageList
          ref={imageListRef}
          cols={8}
          gap={20}
          style={{
            width: "850px",
            height: "100px",
            padding:"0 10px 10px",
            display:"flex",
            justifyContent:"center",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          {images.map((item) => (
            <ImageListItem
              key={item.src}
              cols={1}
              style={{
                borderRadius: "8px",
                overflow: "hidden",
                height: "100%",
              }}
              onMouseEnter={() => setMainImage(item.src)}
            >
              <img
                src={item.src}
                alt={item.alt}
                style={{
                  width: "132px",
                  height: "100px",
                  borderRadius: "10px",
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>

        <IconButton
          onClick={scrollRight}
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </div>
    </div>
  );
};

export default VehicleGallery;
