import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import { Nodeapi, Assetsapi } from "../../../config/serverUrl";
import "../blooddonation.css";
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from 'react-icons/io5';
import { RiMenuFill } from 'react-icons/ri';
import { useSelector } from "react-redux";
import bloodTransfusion from "../../../assets/Icons/bloodtransfusion.svg"
import redBloodCells from "../../../assets/Icons/redbloodcells.svg"
import bloodDrop from "../../../assets/Icons/bloodDrop.svg";
import { formatDate, formatTimeTo12Hour, calculateAge, bloodRequestFields } from "../BloodCommon";

const getFilenameFromPath = (path) => {
  if (!path) {
    return "-";
  }
  const segments = path.split(/[/\\]/);
  return segments.pop();
};
const dateFields = new Set(["dob", "from_date_required", "to_date_required"]);

const DonorManagementView = () => {
  const { id } = useParams();
  const [requestData, setRequestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;

  const navigate = useNavigate();

  const handleNextRequest = () => {
    const currentIndex = requests.findIndex((request) => request.id == id);

    if (currentIndex === -1) {
      console.error(
        "Current request ID not found in the filtered requests array."
      );
      return;
    }

    if (currentIndex < requests.length - 1) {
      const nextRequestId = requests[currentIndex + 1].id;
      navigate(`/main/BloodRequestView/${nextRequestId}`);
    } else {
      console.log("No more requests available.");
    }
  };

  

  useEffect(() => {
    const fetchAllRequests = async () => {
      try {
        const response = await axios.get(`${Nodeapi}/blood-donation/requests`);
        const userRequests = response.data.filter(
          (request) => request.userid == userId
        );

        setRequests(userRequests);
      } catch (err) {
        console.error("Error fetching requests:", err);
      }
    };

    if (userId) {
      fetchAllRequests();
    }
  }, [userId]);


  useEffect(() => {
    const fetchRequestData = async () => {
      try {
        const response = await axios.get(`${Nodeapi}/blood-donation/request/${id}`);
        setRequestData(response.data);
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchRequestData();
    }
  }, [id]);

  if (loading)
    return (
      <Container>
        Loading...
      </Container>
    );
  if (!requestData)
    return (
      <Container>
        <Typography>No data found</Typography>
      </Container>
    );

    const formatValue = (key, value) => {
      if (dateFields.has(key) && value) {
        if (key === "dob") {
          return calculateAge(value) + " years";
        }
        return formatDate(value);
      }
      if(key === "to_time_required"){
        return formatTimeTo12Hour(value);
      }
      if (key === "id_proof" || key === "donor_criteria") {
        const filename = getFilenameFromPath(value);
        if (filename === "-") {
          return filename;
        }
    
        const fileURL = `${Assetsapi}/adimages/${filename}`;
        if (
          value.endsWith(".png") ||
          value.endsWith(".jpg") ||
          value.endsWith(".jpeg") ||
          value.endsWith(".webp")
        ) {
          return (
            <a href={fileURL} target="_blank" rel="noopener noreferrer">
              <img
                src={fileURL}
                alt={filename}
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            </a>
          );
        }
    
        return (
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
            {filename}
          </a>
        );
      }
      return value === null || value === undefined || value === ""
        ? "-"
        : value.toString();
    };
    
  const firstColumnFields = bloodRequestFields.slice(0, 3);
  const secondColumnFields = bloodRequestFields.slice(3, 15);
  const thirdColumnFields = bloodRequestFields.slice(15);

  const iconMap = {
    blood_group: (
      <img
        src={bloodDrop}
        alt="Blood Group Icon"
        style={{ width: "34px", height: "34px" }}
      />
    ),
    blood_component_type: (
      <img
        src={redBloodCells}
        alt="Blood Component Icon"
        style={{ width: "34px", height: "34px" }}
      />
    ),
    units_required: (
      <img
        src={bloodTransfusion}
        alt="Blood Units Icon"
        style={{ width: "34px", height: "34px" }}
      />
    )
  };
  return (
  <div style={{marginBottom:"10px", paddingBottom:"10px"}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 3,
          marginLeft: 2,
          marginRight: 2,
          marginBottom: 2,
        }}
        className="back"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={{
              marginLeft: 2,
              color: "#000",
              "&:hover": {
                color: "#000",
                textDecoration: "none",
              },
            }}
          >
            Blood Request Details
          </Typography>
        </Box>
        <Box>
                    <IconButton onClick={() => navigate('/main/BloodDonation?menu=donors')}>
                        <IoChevronBackCircleOutline />
                    </IconButton>
                    <IconButton onClick={() => navigate('/main/BloodDonation')}>
                        <RiMenuFill />
                    </IconButton>
                    <IconButton onClick={handleNextRequest}
          disabled={requests.length <= 1}>
                        <IoChevronForwardCircleOutline />
                    </IconButton>
                </Box>
      </Box>

      <Card
        sx={{
          maxWidth: "auto",
          margin: "2px",
          padding: 1,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)"
        }}
      >
        <CardContent>
          <Grid container spacing={2} sx={{ marginBottom: 3 }}>
            <Grid item xs={12}>
              <Card sx={{ flex: "1", padding: 2, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)"}}>
                <Grid container spacing={2} sx={{paddingLeft:14}}>
                  {firstColumnFields.map(({ key, label }) => (
                    <Grid item xs={12} sm={6} md={4} key={key} sx={{ display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                      <div style={{
                        backgroundColor: "#F4F7FE",
                        height: "56px",
                        width: "56px",
                        borderRadius: "50%",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '16px' 
                      }}>
                        {iconMap[key]}
                      </div>

                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body1" sx={{ fontWeight: "500", fontSize: "14px", color: "#A3AED0"}}>
                            {label}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1" sx={{ fontWeight: "700", fontSize: "18px", color: "#2B3674"}}>
                            {formatValue(key, requestData[key])}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{display:"flex"}}>
            <Grid item xs={12} sm={6} sx={{display:"flex"}}>
              <Card sx={{flex:"1", padding:2, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)"}}>
                {secondColumnFields.map(({ key, label }) => (
                  <Grid
                    container
                    spacing={1}
                    key={key}
                    sx={{
                      padding: 1
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography variant="body1" sx={{ fontWeight: "500"}}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ fontWeight: "500"}}>
                        {formatValue(key, requestData[key])}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} sx={{display:"flex"}}>
              <Card sx={{flex:"1", padding:2, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)"}}>
                {thirdColumnFields.map(({ key, label }) => (
                  <Grid
                    container
                    spacing={1}
                    key={key}
                    sx={{
                      padding: 1
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "500"}}>
                        {formatValue(key, requestData[key])}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      </div>
  );};
export default DonorManagementView;
