const categories = {
  "Directors": [
    "Director",
    "Assistant Director (AD)",
    "Second Unit Director",
    "First Assistant Director (1st AD)",
    "Second Assistant Director (2nd AD)",
    "Second Unit Director of Photography",
    "Director of Photography (DP)",
    "Art Director",
    "Production Designer",
    "Casting Director",
    "Music Director",
    "Commercial Director",
    "Documentary Director",
    "Television Director",
    "Stage Director",
    "Choreographer",
    "Virtual Reality (VR) Director",
    "Augmented Reality (AR) Director",
    "Interactive Media Director",
    "Creative Director",
    "Editorial Director",
    "Script Supervisor (also known as Script Continuity)"
  ],
  "Production": [
    "Producer",
    "Director",
    "Assistant Director",
    "Line Producer",
    "Unit Production Manager",
    "Production Manager",
    "Production Coordinator",
    "Production Assistant",
    "Production Supervisor",
    "Production Accountant",
    "Production Designer",
    "Assistant Production Coordinator",
    "Script Supervisor",
    "Transportation Coordinator",
    "Casting Associate"
  ],
  "Writing and Script Development": [
    "Screenwriter",
    "Script Supervisor",
    "Storyboard Artist",
    "Script Consultant",
    "Story Editor",
    "Script Coordinator",
    "Dialogue Coach",
    "Script Analyst",
    "Development Executive",
    "Script Reader",
    "Script Doctor"
  ],
  "Cinematography and Camera Operations": [
    "Cinematographer / Director of Photography (DP)",
    "Camera Operator",
    "Camera Assistant",
    "Steadicam Operator",
    "Drone Operator",
    "Still Photographer",
    "Digital Imaging Technician (DIT)",
    "High-Speed Camera Technician",
    "Camera Loader",
    "Camera Utility",
    "B Camera Operator",
    "C Camera Operator",
    "2nd Unit DP",
    "Clapper Loader"
  ],
  "Editing and Post-Production": [
    "Film Editor",
    "Assistant Editor",
    "Colorist",
    "Visual Effects (VFX) Supervisor",
    "VFX Artist",
    "Compositor",
    "Rotoscope Artist",
    "Matte Painter",
    "VFX Producer",
    "Post-Production Supervisor",
    "Sound Editor",
    "Music Editor",
    "Editorial Assistant",
    "Title Designer",
    "Motion Graphics Artist"
  ],
  "Sound and Music": [
    "Sound Designer",
    "Sound Mixer",
    "Boom Operator",
    "Composer",
    "Music Supervisor",
    "Foley Artist",
    "ADR (Automated Dialogue Replacement) Technician",
    "Music Editor",
    "Sound Effects Editor",
    "Re-recording Mixer",
    "Music Producer",
    "Sound Engineer",
    "Dialogue Editor",
    "Mixing Engineer",
    "Audio Post-Production Coordinator"
  ],
  "Art Department": [
    "Production Designer",
    "Art Director",
    "Set Designer",
    "Set Decorator",
    "Props Master",
    "Scenic Artist",
    "Carpenter",
    "Scenic Carpenter",
    "Metal Worker",
    "Prop Maker",
    "Art Assistant",
    "Set Dresser",
    "Art Department Coordinator",
    "Property Master",
    "Set Painter",
    "Construction Coordinator"
  ],
  "Costume and Makeup": [
    "Costume Designer",
    "Wardrobe Supervisor",
    "Makeup Artist",
    "Hair Stylist",
    "Special Effects Makeup Artist",
    "Costume Assistant",
    "Makeup Assistant",
    "Wardrobe Assistant",
    "Costume Illustrator",
    "Hair and Makeup Supervisor"
  ],
"Acting and Performance": [
    "Actor",
    "Actress",
    "Voice Actor",
    "Stunt Coordinator",
    "Stunt Performer",
    "Stand-in",
    "Double",
    "Body Double",
    "Background Actor",
    "Performance Coach",
    "Acting Coach",
    "Lead Actor",
    "Supporting Actor",
    "Character Actor",
    "Method Actor",
    "Voice Over Artist",
    "Animation Voice Actor",
    "Narrator",
    "Dubbing Actor",
    "Foley Artist (for live action performance)",
    "Fight Choreographer",
    "Dance Choreographer",
    "Theater Actor",
    "Television Actor",
    "Film Actor",
    "Commercial Actor",
    "Model (for acting)",
    "Impersonator",
    "Look-alike",
    "Stand-in for Stunt Work",
    "Audition Coach",
    "Dialect Coach",
    "Acting Teacher",
    "Actor's Agent",
    "Performance Artist",
    "Stage Performer",
    "Improvisational Actor",
    "Experimental Actor",
    "Voice Director"
  ],
  "Casting": [
    "Casting Director",
    "Casting Assistant",
    "Talent Agent",
    "Casting Associate",
    "Audition Coordinator",
    "Casting Director's Assistant"
  ],
  "Distribution and Exhibition": [
    "Distribution Executive",
    "Distribution Manager",
    "Film Buyer",
    "Theater Manager",
    "Film Festival Coordinator",
    "Projectionist",
    "Market Manager",
    "Festival Programmer",
    "Sales Agent",
    "Exhibition Coordinator",
    "Film Rights Manager",
    "Distribution Coordinator"
  ],
  "Marketing and Publicity": [
    "Marketing Executive",
    "Publicist",
    "Marketing Manager",
    "Sales Agent",
    "Social Media Manager",
    "Advertising Executive",
    "Digital Campaign Manager",
    "Fan Engagement Specialist",
    "Influencer Partnership Manager",
    "Promotions Coordinator",
    "Brand Strategist",
    "Public Relations Manager",
    "Media Buyer"
  ],
  "Administration and Support": [
    "Studio Executive",
    "Office Manager",
    "Legal Advisor",
    "Finance Manager",
    "Production Accountant",
    "Payroll Manager",
    "Budget Analyst",
    "IT Support Specialist",
    "Software Developer (for production tools)",
    "Network Administrator",
    "Administrative Assistant",
    "Office Coordinator",
    "Executive Assistant",
    "HR Manager"
  ],
  "Specialty Jobs": [
    "Location Manager",
    "Location Scout",
    "Animal Trainer (for films involving animals)",
    "Dialect Coach",
    "Intimacy Coordinator",
    "Marine Coordinator (for underwater filming)",
    "Aviation Coordinator (for aerial shots)",
    "Military Advisor (for films involving military content)",
    "Historical Consultant",
    "Cultural Consultant",
    "Legal Consultant",
    "Firearms Specialist",
    "Weather Consultant"
  ],
  "Lighting and Electrical": [
    "Gaffer (Chief Lighting Technician)",
    "Best Boy (Assistant to the Gaffer)",
    "Lighting Technician",
    "Electrician",
    "Best Boy Electric",
    "Rigging Gaffer",
    "Key Grip",
    "Grip",
    "Lighting Assistant",
    "Electrical Coordinator",
    "Dolly Grip",
    "Jib Operator"
  ],
  "Technical and Engineering": [
    "Broadcast Engineer",
    "Video Technician",
    "Data Wrangler",
    "Media Manager",
    "Robotics Operator",
    "Advanced Motion Capture Technician",
    "Technical Director",
    "Video Engineer",
    "Systems Engineer",
    "Network Engineer",
    "Sound Engineer"
  ],
  "Special Effects": [
    "Special Effects Coordinator",
    "Special Effects Technician",
    "Fireworks Specialist",
    "Mechanical Effects Artist",
    "Explosives Specialist",
    "Creature Effects Specialist",
    "Effects Coordinator",
    "Effects Supervisor"
  ],
  "Education and Research": [
    "Film Historian",
    "Film Critic",
    "Film Studies Professor",
    "Film Researcher",
    "Film School Instructor",
    "Workshop Instructor",
    "Training Coordinator",
    "Industry Mentor",
    "Screenwriting Coach",
    "Film Archivist",
    "Documentary Historian"
  ],
  "Environmental and Sustainability": [
    "Environmental Steward",
    "Sustainability Coordinator",
    "Green Production Advisor",
    "Eco Consultant",
    "Waste Management Specialist",
    "Energy Efficiency Consultant",
    "Sustainable Production Specialist"
  ],
  "Security and Safety": [
    "Security Officer",
    "Health and Safety Advisor",
    "Fire Safety Officer",
    "Safety Coordinator",
    "On-Set Medic",
    "Risk Management Specialist",
    "Emergency Response Coordinator"
  ],
  "Legal and Compliance": [
    "Entertainment Lawyer",
    "Contract Manager",
    "Compliance Officer",
    "Rights Clearance Specialist",
    "Intellectual Property Manager",
    "Licensing Coordinator",
    "Legal Assistant",
    "Contract Analyst",
    "Regulatory Compliance Specialist",
    "Corporate Lawyer"
  ],
  "Archival and Preservation": [
    "Film Archivist",
    "Preservation Specialist",
    "Digital Archivist",
    "Historical Consultant",
    "Film Restoration Specialist",
    "Archive Researcher",
    "Digital Preservation Specialist",
    "Archival Technician",
    "Restoration Technician"
  ],
  "Public Relations and Communications": [
    "Public Relations Specialist",
    "Communications Director",
    "Press Agent",
    "Media Relations Manager",
    "Spokesperson",
    "Public Affairs Specialist",
    "Crisis Communications Manager",
    "Communications Coordinator"
  ],
  "Audience and Market Research": [
    "Market Research Analyst",
    "Audience Development Specialist",
    "Data Analyst",
    "Consumer Insights Analyst",
    "Audience Engagement Specialist",
    "Demographics Analyst",
    "Market Research Manager"
  ],
  "Event Planning and Coordination": [
    "Event Coordinator",
    "Festival Planner",
    "Premiere Organizer",
    "Red Carpet Coordinator",
    "Award Show Planner",
    "Screening Room Manager",
    "Event Producer",
    "Venue Manager",
    "Logistics Coordinator",
    "Special Events Coordinator"
  ],
  "Creative Development and Content Strategy": [
    "Development Executive",
    "Content Strategist",
    "Creative Director",
    "Creative Producer",
    "Story Editor",
    "Concept Developer",
    "Creative Consultant",
    "Content Creator",
    "Story Analyst"
  ],
  "Cultural and Community Engagement": [
    "Community Outreach Coordinator",
    "Diversity and Inclusion Officer",
    "Cultural Consultant",
    "Local Liaison",
    "Public Affairs Specialist",
    "Community Relations Manager",
    "Cultural Outreach Coordinator"
  ],
  "Emerging Technology and Innovation": [
    "Interactive Media Producer",
    "Virtual Reality (VR) Developer",
    "Augmented Reality (AR) Designer",
    "AI Specialist for Media",
    "Blockchain Consultant (for film rights and distribution)",
    "Tech Innovator",
    "Emerging Tech Specialist",
    "Digital Experience Designer",
    "Tech Integration Specialist",
    "Innovation Consultant"
  ],
  "Cinematic Education and Mentorship": [
    "Workshop Leader",
    "Mentorship Coordinator",
    "Film Educator",
    "Cinema Instructor",
    "Guest Lecturer",
    "Cinematic Tutor",
    "Film Education Specialist"
  ],
  "Cine Tourism and Experiences": [
    "Location Tour Guide",
    "Experience Designer (for theme parks, attractions)",
    "Cine Tourism Coordinator",
    "Film Location Consultant",
    "Tourism Marketing Specialist",
    "Visitor Experience Manager",
    "Film Tour Coordinator"
  ],
  "Management and Executive Roles": [
    "Studio Executive",
    "CEO of a Production Company",
    "Head of Development",
    "Chief Content Officer",
    "Executive Producer",
    "Chief Operating Officer (COO)",
    "Chief Financial Officer (CFO)",
    "Chief Creative Officer (CCO)",
    "Head of Production",
    "Production Executive",
    "Creative Executive"
  ],
  "Others": []
}


export default categories;
