import React, { useState, useEffect } from "react";
import "./AdDashboard.css";
import { useSelector, useDispatch } from "react-redux";
import { AiFillPlusCircle } from "react-icons/ai";
import AddPost from "./AdPost";
import axios from "axios";
import CreatedPost from "./AdMenus/CreatedPost";
import LivePost from "./AdMenus/LivePost";
import UnPost from "./AdMenus/UnPost";
import ClosedPost from "./AdMenus/ClosedPost";
import DraftPost from "./AdMenus/DraftPost";
import RejectedPost from "./AdMenus/RejectedPost";
import DeletedDraft from "./AdMenus/DeletedPost";
import { Nodeapi } from "../../config/serverUrl";
import { setActiveTab } from "../../react-redux/slices/postAddSlice";
import { Button } from "primereact/button";

const AdDashboardContent = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tab.activeTab)
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;
  const [postCreatedDetails, setPostCreatedDetails] = useState([]);
  const [createdPost, setCreatedPost] = useState(false);

  const fetchPostCreated = async () => {
    try {
      const response = await axios.get(`${Nodeapi}/post-created?id=${userId}`);
      setPostCreatedDetails(response.data);
    } catch (error) {
      console.error("Failed to fetch job details:", error);
    }
  };

  useEffect(() => {
    fetchPostCreated();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTabClick = (tab) => {
    dispatch(setActiveTab(tab));
  };

  return (
    <div style={{ padding: "16", width: "97" }}>


      <div className="menu-cards">
        <div className="left-buttons">
          <Button
            className={`outlined text-primary-500 bg-primary-50 hover:text-primary-700 font-medium border-round border-1 border-primary-400 ${activeTab === "1" ? "active ast-btn" : "ast-btn"}`}
            onClick={() => handleTabClick("1")}
          >
            All Post
          </Button>
          <Button
            className={`text-green-500 hover:text-green-700 bg-green-50 font-medium border-round border-1 border-green-400 ${activeTab === "2" ? "active ast-btn" : "ast-btn"}`}
            onClick={() => handleTabClick("2")}
          >
            Active Listings
          </Button>
          <Button
            className={`text-yellow-500 hover:text-yellow-700 bg-yellow-50 font-medium border-round border-1 border-yellow-500 ${activeTab === "3" ? "active ast-btn" : "ast-btn"}`}
            onClick={() => handleTabClick("3")}
          >
            Pending Post
          </Button>
          <Button
            className={`text-bluegray-500 hover:text-bluegray-700 bg-bluegray-50 font-medium border-round border-1 border-bluegray-400 ${activeTab === "4" ? "active ast-btn" : "ast-btn"}`}
            onClick={() => handleTabClick("4")}
          >
            Draft
          </Button>
          <Button
            className={`text-teal-500 hover:text-teal-700 bg-teal-50 font-medium border-round border-1 border-teal-400 ${activeTab === "5" ? "active ast-btn" : "ast-btn"}`}
            onClick={() => handleTabClick("5")}
          >
            Closed
          </Button>
          <Button
            className={`text-pink-500 hover:text-pink-700 bg-teal-50 font-medium border-round border-1 border-pink-400 ${activeTab === "6" ? "active ast-btn" : "ast-btn"}`}
            onClick={() => handleTabClick("6")}
          >
            Reject
          </Button>
          <Button
            className={`text-red-500 hover:text-red-700 bg-red-50 font-medium border-round border-1 border-red-400 ${activeTab === "7" ? "active ast-btn" : "ast-btn"}`}
            onClick={() => handleTabClick("7")}
          >
            Deleted
          </Button>
        </div>
        {activeTab === "1" && (
          <button
            className="btn-post"
            onClick={handleOpenModal}
          >
            <AiFillPlusCircle
              className="me-2"
              style={{ fontSize: "20px", color: "#fff" }}
            />
            Post
          </button>
        )}
      </div>
      {isModalOpen && (
        <AddPost
          open={isModalOpen}
          handleClose={handleCloseModal}
          fetchPostCreated={fetchPostCreated}
          setCreatedPost={setCreatedPost}
          createdPost={createdPost}
        />
      )}
      <div className="Asset">
        <div className="card1 mt-4">
          <div className="ass-content mt-3">
            <div className="">
              <div className="asset-content">
                {activeTab === "1" && <CreatedPost displaypost={postCreatedDetails} fetchPostCreated={fetchPostCreated} />}
                {activeTab === "2" && <LivePost />}
                {activeTab === "3" && <UnPost />}
                {activeTab === "4" && <DraftPost />}
                {activeTab === "5" && <ClosedPost />}
                {activeTab === "6" && <RejectedPost />}
                {activeTab === "7" && <DeletedDraft />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdDashboardContent;
