const postCategory = [
    {
      label: "Electronics",
      code: "DE",
      items: [
        { label: "Mobile Phones", value: "Mobile Phones" },
        { label: "Computers & Tablets", value: "Computers & Tablets" },
        { label: "Laptops", value: "Laptops" },
        { label: "Cameras & Photography", value: "Cameras & Photography" },
        { label: "TV, Audio & Video", value: "TV, Audio & Video" },
        { label: "Wearable Technology", value: "Wearable Technology" },
      ],
    },
    {
      label: "Vehicles",
      code: "US",
      items: [
        { label: "Cars", value: "Cars" },
        { label: "Motorcycles", value: "Motorcycles" },
        { label: "Trucks", value: "Trucks" },
        { label: "Bicycles", value: "Bicycles" },
        { label: "Boats", value: "Boats" },
      ],
    },
    {
      label: "Fashion & Beauty",
      code: "JP",
      items: [
        { label: "Clothing", value: "Clothing" },
        { label: "Shoes", value: "Shoes" },
        { label: "Accessories", value: "Accessories" },
        {
          label: "Health & Beauty Products",
          value: "Health & Beauty Products",
        },
        { label: "Jewelery", value: "Jewelery" },
      ],
    },
    {
      label: "Documents",
      code: "JP",
      items: [
        { label: "Personal Documents", value: "Personal Documents" },
        { label: "Property Documents", value: "Property Documents" },
        { label: "Documents", value: "Documents" },
      ],
    },
    {
      label: "Furniture",
      code: "JP",
      items: [
        { label: "Furniture", value: "Furniture" },
        { label: "Home Appliances", value: "Home Appliances" },
        { label: "Tools & DIY", value: "Tools & DIY" },
      ],
    },
    {
      label: "Sports & Outdoors",
      code: "JP",
      items: [
        { label: "Fitness Equipment", value: "Fitness Equipment" },
        { label: "Sporting Goods", value: "Sporting Goods" },
        { label: "Outdoor Gear", value: "Outdoor Gear" },
        { label: "Bicycles", value: "Bicycles" },
        { label: "Camping & Hiking", value: "Camping & Hiking" },
      ],
    },
    {
      label: "Toys & Games",
      code: "JP",
      items: [
        { label: "Action Figures", value: "Action Figures" },
        { label: "Board Games", value: "Board Games" },
        { label: "Video Games", value: "Video Games" },
      ],
    },
    {
      label: "Books & Media",
      code: "JP",
      items: [
        { label: "Books", value: "Books" },
        { label: "Movies", value: "Movies" },
        { label: "Video Games", value: "Video Games" },
      ],
    },
    {
      label: "Living Being",
      code: "JP",
      items: [
        { label: "Human", value: "Human" },
        { label: "Child", value: "Child" },
        { label: "Animal", value: "Animal" },
      ],
    },  {
      label: "Others",
      code: "JP",
      items: [{ label: "Others", value: "Others" }],
    },
  ];

  export default postCategory;