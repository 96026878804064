
import React, { useState } from "react";
import AssetGroup from "./AssetGroup";
import AssetType from "./AssetType";
import AssetItem from "./AssetItem";
import EmployeeAsset from "./EmployeeAsset";
import Product from "./Product";
import CompanyContact from "./CompanyContact"
import './companyprofile.css'
function CompanyProfile() {
    const [activeTab, setActiveTab] = useState("1");

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
  
    return (
      <div className="Asset">
        <div className="card1 mt-2">
        <div className="menu-card flex">
            <button
              className={activeTab === "1" ? "active ast-btn" : "ast-btn"}
              onClick={() => handleTabClick("1")}
            >
              About Us
            </button>
            <button
              className={activeTab === "2" ? "active ast-btn" : "ast-btn"}
              onClick={() => handleTabClick("2")}
            >
             Management
            </button>
            <button
              className={activeTab === "3" ? "active ast-btn" : "ast-btn"}
              onClick={() => handleTabClick("3")}
            >
             Product & Services
            </button>
            <button
              className={activeTab === "4" ? "active ast-btn" : "ast-btn"}
              onClick={() => handleTabClick("4")}
            >
             Enquiries
            </button>
            <button
              className={activeTab === "5" ? "active ast-btn" : "ast-btn"}
              onClick={() => handleTabClick("5")}
            >
             Quotations
            </button>
            <button
              className={activeTab === "6" ? "active ast-btn" : "ast-btn"}
              onClick={() => handleTabClick("6")}
            >
             Orders
            </button>
            <button
              className={activeTab === "7" ? "active ast-btn" : "ast-btn"}
              onClick={() => handleTabClick("7")}
            >
              Contact Us
            </button>
          </div>
          <div className="ass-content mt-3">
            <div className="">
              <div className="asset-content">
                {activeTab === "1" && (
                  <>
                    <AssetGroup />
                  </>
                )}
                {activeTab === "2" && (
                  <>
                    <EmployeeAsset />
                  </>
                )}
                {activeTab === "3" && (
                  <>
                    <Product />
                  </>
                )}
                 {activeTab === "7" && (
                  <>
                    <CompanyContact />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  
}

export default CompanyProfile