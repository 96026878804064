import { MdAdd } from "react-icons/md";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "../../Data/ProductService";
import "../../Component/AddEducationDetails/School.css";
import "./Relationship.css";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { Nodeapi } from "../../config/serverUrl";
import { Calendar } from "primereact/calendar";
import { useRef } from "react";
import { Menu } from "primereact/menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import Swal from "sweetalert2";
import moment from "moment/moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  backgroundColor: "#fff",
  border: "0px",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
  height: "90vh",
  overflowY: "scroll",
};

const RelationshipDetails = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [phone, setphone] = useState("");
  const [serialNumbers, setSerialNumbers] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false); //to handle multiple form submission

  // const calculateAge = (dob, dod) => {
  //   if (!dod) {
  //     const today = moment();
  //     const birthDate = moment(dob);
  //     return today.diff(birthDate, "years");
  //   } else {
  //     const startDate = moment(dob);
  //     const endDate = moment(dod);
  //     return endDate.diff(startDate, "years");
  //   }
  // };
  const calculateAge = (DOB, DOD) => {
    if (!DOD) {
      const today = new Date();
      const birthDate = new Date(DOB);
      let calculatedAge = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();

      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        calculatedAge--;
      }
      if (calculatedAge < 0 || calculatedAge > 120) {
        return "invalid age";
      }

      return calculatedAge;
    } else {
      const startDate = moment(DOB);
      const endDate = moment(DOD);
      return endDate.diff(startDate, "years");
    }
  };

  useEffect(() => {
    ProductService.getProductsMini().then((data) => setProducts(data));
  }, []);

  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);
  const relationships = [
    { name: "Father", value: "father" },
    { name: "Mother", value: "mother" },
    { name: "Son", value: "son" },
    { name: "Daughter", value: "daughter" },
    { name: "Brother", value: "brother" },
    { name: "Grandmother / Grandma", value: "grandmother" },
    { name: "Grandfather / Grandpa", value: "grandfather" },
    { name: "Grandchild", value: "grandchild" },
    { name: "Aunt", value: "aunt" },
    { name: "Uncle", value: "uncle" },
    { name: "Niece", value: "niece" },
    { name: "Nephew", value: "nephew" },
    { name: "First Cousin", value: "first cousin" },
    { name: "Second Cousin", value: "second cousin" },
    { name: "Mother-in-law", value: "mother in law" },
    { name: "Father-in-law", value: "father in law" },
    { name: "Brother-in-law", value: "brother in law" },
    { name: "Sister-in-law", value: "sister in law" },
    { name: "Wife", value: "wife" },
    { name: "Husband", value: "husband" },
    { name: "Stepmother / Stepmom", value: "stepmother" },
    { name: "Stepfather / Stepdad", value: "stepfather" },
    { name: "Stepchild", value: "stepchild" },
    { name: "Guardian", value: "guardian" },
    { name: "Ward", value: "ward" },
  ];

  const DeleteButton = ({ rowIndex, handleDelete }) => {
    const handleClick = () => {
      handleDelete(rowIndex);
    };

    return (
      <div className="">
        <Button onClick={handleClick}>
          <i
            className="fi fi-rr-trash"
            style={{
              color: "red",
              background: "#ffc0c0",
              fontSize: "21px",
              padding: "4px 10px 0px 10px",
              borderRadius: "14%",
            }}
          />
        </Button>
      </div>
    );
  };

  const [relationForm, setRelationForm] = useState({
    userid: "",
    firstname: "",
    fathername: "",
    familyname: "",
    DOB: "",
    DOD: "",
    age: "",
    time: "",
    importedNote: "",
    bloodGroup: "",
    phonenumber: "",
    occupation: "",
    isDeceased: false,
    relation: "",
    isEmergencyContact: "", // Initialize isEmergencyContact
  });

 

  const [products, setProducts] = useState([]);

  const handleButtonClick = () => {
    const newProduct = {
      id: Date.now(),
      firstname: "",
      fathername: "",
      familyname: "",
      DOB: "",
      DOD: "",
      age: "",
      time: "",
      importedNote: "",
      bloodGroup: "",
      phonenumber: "",
      occupation: "",
      isDeceased: false,
      relation: "",
      isEmergencyContact: "",
    };
    handleClose();
    setProducts([...products, newProduct]);
  };

  const authdata = useSelector((state) => state.auth.user);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRelationForm({
      ...relationForm,
      [name]: value,
      userid: authdata?.id,
      candidate_id: authdata.userid,
    });
  };

  const handleRelationshipChange = (e) => {
    setRelationForm({
      ...relationForm,
      relation: e.target.value,
    });
  };

  const handleBloodgroup = (e) => {
    setRelationForm({
      ...relationForm,
      bloodGroup: e.target.value,
    });
  };
  const [relationdata, setRelationData] = useState([]);
  const [date_birth, setdate_birth] = useState("");
  const [date_of_death, setdate_of_death] = useState("");
  //

  // const handleDateChange = (event) => {
  //   const newDob = event.value;
  //   const formattedDate = moment(newDob).format("DD-MM-YYYY"); // Format date

  //   setRelationForm({
  //     ...relationForm,
  //     DOB: formattedDate, // Set formatted date
  //     age: calculateAge(newDob),
  //   });
  // };
  // const handledateofdeathChange = (event) => {
  //   const newDod = event.value;
  //   const formattedDate = moment(newDod).format("DD-MM-YYYY");
  //   setRelationForm({
  //     ...relationForm,
  //     DOD: formattedDate,
  //     age: calculateAge(newDod),
  //   });
  // };

  const handleDateChange = (event) => {
    const newDob = event.value;
    const formattedDate = moment(newDob).format("DD-MM-YYYY");
    setRelationForm({
      ...relationForm,
      DOB: formattedDate,
      age: calculateAge(newDob),
    });
  };

  const handledateofdeathChange = (event) => {
    const newDod = event.value;
    const formattedDate = moment(newDod).format("DD-MM-YYYY");
    setRelationForm({
      ...relationForm,
      DOD: formattedDate,
      age: calculateAge(relationForm.DOB, newDod), // Pass DOB and new DOD
    });
  };

  const handlemobileno = (value) => {
    let valuno = value;

    setphone(valuno);
    setRelationForm((prevstate) => ({
      ...prevstate,
      phonenumber: valuno,
    }));
  };

  //ADDrelation

  const addrelationsdata = async (e) => {
    e.preventDefault();

    if(isSubmitting) return;   //Prevent multiple submission
    setIsSubmitting(true);

    const action = e.target.value;
    if (action === "Submit") {
      const isEmpty = Object.values(relationForm).every(
        (value) => value === ""
      );

      if (isEmpty) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Fill Out All Required Fields",
          showConfirmButton: false,
          timer: 2000,
        });
        return handleClose();
      }

      const res = await axios.post(`${Nodeapi}/addRelationdetails`, {
        relationdata: relationForm,
        userid: authdata?.id,
      });
      if (res.data) {
        if (res.data.code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "information added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          setRelationData(res.data.data.result);

          setRelationForm("");
          setphone("");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
          handleClose();
        }
      }
    } else {
      const res = await axios.put(`${Nodeapi}/updateData/${relationForm.id}`, {
        updaterelationdta: relationForm,
        userid: relationForm.userid,
      });

      if (res.data) {
        if (res.data.code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          setRelationData(res.data.data.result);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
          handleClose();
        }
      }
    }
    
    setIsSubmitting(false);
  };

  //fetchD

  useEffect(() => {
    fetchRelationInformation();
  }, []);

  const fetchRelationInformation = async () => {
    const res = await axios.get(`${Nodeapi}/fetchdata?id=${authdata?.id}`);

    setRelationData(res.data.data.result);
  };

  const deleteRelationData = async (id) => {
    try {
      await axios.delete(`${Nodeapi}/deleteData/${id}`);

      fetchRelationInformation();
    } catch (error) {
      console.error("Error deleting relation data:", error);
    }
  };

  const [buttonvalue, setButtonValu] = useState("");

  const handleEdit = (rowData) => {
    handleOpen();
    let value1 = rowData.DOB;
    let value2 = rowData.DOD;
    // Convert DOB string to Date object
    let dobDate = moment(value1, "DD-MM-YYYY").toDate();
    let doddate = moment(value2, "DD-MM-YYYY").toDate();
    setdate_birth(dobDate); // Set the Date object
    setdate_of_death(doddate);
    setphone(rowData.phonenumber);
    setRelationForm(rowData);
    setButtonValu("update");
  };

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    // Handle delete action with rowData
    if (result.isConfirmed) {
      try {
        await deleteRelationData(rowData.id);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelectedJobDetail(rowData);
      menuLeft.current.toggle(event);
    };
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (relationdata) {
      setSerialNumbers(
        Array.from({ length: relationdata.length }, (_, index) => index + 1)
      );
    }
  }, [relationdata]);

  const menuLeft = useRef(null);
  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selectedJobDetail),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selectedJobDetail),
    },
  ];

  const handleOpenform = async () => {
    setButtonValu("Submit");
    handleOpen();
    setRelationForm({
      userid: "",
      firstname: "",
      fathername: "",
      familyname: "",
      DOB: "",
      DOD: "",
      age: "",
      time: "",
      importedNote: "",
      bloodGroup: "",
      phonenumber: "",
      occupation: "",
      isDeceased: false,
      relation: "",
      isEmergencyContact: "",
    });
    setphone("");
    setdate_birth("");
    setdate_of_death("");
  };

  const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const formatFirstName = (firstName) => {
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  };

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <h5>Family Members Details</h5>
          <p>
            <Button onClick={handleOpenform}>
              <MdAdd size={22} />
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="w-23rem md:w-30rem scrollBarHidden">
                <h6 htmlFor="" className="mb-2">
                  Relationship Details :
                </h6>
                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Firstname
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    className="form-control"
                    placeholder="Firstname"
                    id=""
                    value={relationForm.firstname}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Fathername
                  </label>
                  <input
                    type="text"
                    name="fathername"
                    className="form-control"
                    placeholder="Fathername"
                    id=""
                    value={relationForm.fathername}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Familyname
                  </label>
                  <input
                    type="text"
                    name="familyname"
                    className="form-control"
                    placeholder="Familyname"
                    id=""
                    value={relationForm.familyname}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-12" d-flex justify-content-center>
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor=""
                      className=" labels"
                      style={{ paddingRight: "10px" }}
                    >
                      Deceased
                    </label>
                    <input
                      type="checkbox"
                      name="isDeceased"
                      style={{ width: "1rem", height: "1rem" }}
                      checked={relationForm.isDeceased}
                      onChange={(e) => {
                        const { checked } = e.target;
                        setRelationForm({
                          ...relationForm,
                          isDeceased: checked,
                          DOD: checked ? "" : null, // Clear Date of Death if unchecked
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    DOB
                  </label>
                  <Calendar
                    id="dob"
                    className="w-full input2"
                    showIcon={false}
                    name="DOB"
                    monthNavigator
                    placeholder="Date of birth"
                    yearNavigator
                    yearRange="1500:2024"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={date_birth}
                    onChange={handleDateChange}
                    style={{
                      width: "88%",
                      height: "42px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      border: "none",
                    }} // Set the width to 300px
                  />
                  {relationForm.isDeceased && ( // Conditionally render Date of Death input
                    <div className="col-12">
                      <label htmlFor="" className="mb-1 labels">
                        DOD
                      </label>
                      <Calendar
                        id="dod"
                        className="w-full input2"
                        showIcon={false}
                        name="dod"
                        monthNavigator
                        placeholder="Date of Death"
                        yearNavigator
                        yearRange="1900:2100"
                        dateFormat="dd-mm-yy"
                        inputId="dodInput"
                        value={date_of_death}
                        onChange={handledateofdeathChange}
                        style={{
                          width: "88%",
                          height: "42px",
                          borderTopRightRadius: "4px",
                          borderBottomRightRadius: "4px",
                          border: "none",
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Age
                  </label>
                  <input
                    type="text"
                    name="age"
                    className="form-control"
                    placeholder="Age"
                    id=""
                    value={relationForm.age}
                    readOnly
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Birth Of Time
                  </label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Time"
                    name="time"
                    value={relationForm.time}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-12">
                  <label className="labels mb-1">Select Blood Group:</label>
                  <select
                    id="bloodGroup"
                    className="form-select"
                    value={relationForm.bloodGroup}
                    onChange={handleBloodgroup}
                  >
                    <option value="">Select...</option>
                    {bloodGroups.map((group, index) => (
                      <option key={index} value={group}>
                        {group}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Occupation
                  </label>
                  <input
                    type="text"
                    name="occupation"
                    className="form-control"
                    placeholder="Occupation"
                    id=""
                    onChange={handleInputChange}
                    value={relationForm.occupation}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Relation
                  </label>
                  <select
                    className="form-control"
                    id="relationship"
                    name="relation"
                    value={relationForm.relation}
                    onChange={handleRelationshipChange}
                  >
                    <option value="" disabled>
                      Select Relationship
                    </option>
                    <option value="father">Father</option>
                    <option value="mother">Mother</option>
                    <option value="son">Son</option>
                    <option value="daughter">Daughter</option>
                    <option value="brother">Brother</option>
                    <option value="sister">Sister</option>
                    <option value="grandmother">Grandmother / Grandma</option>
                    <option value="grandfather">Grandfather / Grandpa</option>
                    <option value="grandchild">Grandchild</option>
                    <option value="aunt">Aunt</option>
                    <option value="uncle">Uncle</option>
                    <option value="niece">Niece</option>
                    <option value="nephew">Nephew</option>
                    <option value="first cousin">First Cousin</option>
                    <option value="Second cousin">Second Cousin</option>
                    <option value="mother in law">Mother-in-law</option>
                    <option value="father in law">Father-in-law</option>
                    <option value="brother in law">Brother-in-law</option>
                    <option value="sister in law">Sister-in-law</option>
                    <option value="wife">Wife</option>
                    <option value="husband">Husband</option>
                    <option value="stepmother">Stepmother / Stepmom</option>
                    <option value="stepfather">Stepfather / Stepdad</option>
                    <option value="stepchild">Stepchild</option>
                    <option value="guardian">Guardian</option>
                    <option value="ward">Ward</option>
                  </select>
                </div>

                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Important Note
                  </label>
                  <textarea
                    name="importedNote"
                    rows="2"
                    className="form-control"
                    placeholder="Important Note"
                    style={{resize:'none'}}
                    value={relationForm.importedNote}
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="col-12">
                  <label htmlFor="" className="mb-1 labels">
                    Phonenumber
                  </label>
                  <PhoneInput
                    country={"in"}
                    value={phone}
                    onChange={handlemobileno}
                    className="border-example "
                  />
                </div>
                <div className="col-12" d-flex justify-content-center>
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="labels"
                      style={{ paddingRight: "1px" }}
                    >
                      Emergency Contact
                    </label>
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Emergency Contact"
                  >
                    <button
                      type="button"
                      className={`btn ${
                        relationForm.isEmergencyContact === "yes"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                      onClick={() =>
                        setRelationForm({
                          ...relationForm,
                          isEmergencyContact: "yes",
                        })
                      }
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        relationForm.isEmergencyContact === "no"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                      onClick={() =>
                        setRelationForm({
                          ...relationForm,
                          isEmergencyContact: "no",
                        })
                      }
                    >
                      No
                    </button>
                  </div>
                </div>

                <div className="text-center mt-2">
                  <button className="btn btn-danger me-2" onClick={handleClose}>
                    Cancel
                  </button>

                  <button
                    type="submit"
                    value={buttonvalue}
                    className="btn btn-primary"
                    onClick={addrelationsdata}
                    disabled={isSubmitting}
                  >
                       {isSubmitting? "submitting": buttonvalue}
                  </button>
                </div>
              </Box>
            </Modal>
          </p>
        </div>
        <div className="expand" style={{ marginTop: "7px" }}>
          <DataTable value={relationdata} tableStyle={{ minWidth: "50rem" }}>
            <Column
              header="S.No"
              body={(rowData) => (
                <span>{relationdata.indexOf(rowData) + 1}</span>
              )}
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="firstname"
              header="First Name"
              body={(rowData) => formatFirstName(rowData.firstname)}
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="fathername"
              header="Father Name"
              body={(rowData) => formatFirstName(rowData.fathername)}
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="familyname"
              header="Family Name"
              body={(rowData) => formatFirstName(rowData.familyname)}
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="relation"
              header="Relation"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="DOB"
              header="DOB"
            ></Column>

            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="age"
              header="Age"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="time"
              header="Birth of Time"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="bloodGroup"
              header="Blood Group"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="importedNote"
              header="Important Note"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="phonenumber"
              header="Phone Number"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="occupation"
              header="Occupation"
            ></Column>

            <Column
              body={(rowData) => actions(rowData)}
              header="Actions"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default RelationshipDetails;
