import React, { useEffect, useState, useContext } from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import { Nodeapi } from '../../config/serverUrl';
import { useSelector } from "react-redux";
import './Billing.css'
import { DataContext } from "../../Admin/DataContext";
import { useNavigate } from "react-router-dom";

function Loading() {
    const {
        setclientdetails
    } = useContext(DataContext);

    const navigate = useNavigate()

    const [display, setdisplay] = useState(true)
    const authdata = useSelector((state) => state.auth.user);
    const userid = authdata.userid

    useEffect(() => {

        const Fetchdata = async () => {

            const Response = await axios.get(`${Nodeapi}/getclientpay_details?userid=${userid}`)
            const data = Response.data
            const finaldata = Response.data.data.result
            let filterdata = finaldata.filter((item) => item.payment === "Success")
            if (data.status == "success") {
                setTimeout(() => {
                    setdisplay(false)
                    setclientdetails(filterdata)
        navigate('/main/Subcription', { replace: true }); // Use replace here
                }, 6000);
            }
           
        }
        Fetchdata()
    }, [userid])
    return (
        <>
            {display && (
                <div className='spinner-container'>
                    <div className='spin'></div>
                </div>


            )}
        </>



    )
}


export default Loading