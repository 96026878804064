import React, { useState, useEffect } from "react";
import "./Aadhaar.css";
import { Calendar } from "primereact/calendar";
import aadhaar from "../../assets/images/aadhaar.webp";
import { VscSync } from "react-icons/vsc";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { valid } from "joi";

function Aadhaar() {
  const [visible, setvisible] = useState(false);
  const [display, setdisplay] = useState(true);
  const [visible1, setvisible1] = useState(false);
  const [datavalue, setdatavalue] = useState({
    aadhaarno: "",
    captchano: "",
  });
  const [refereceId, setrefereceId] = useState("");
  const [otpno, setotpno] = useState("");
  const [aadhaardetails, setaadhaardetails] = useState([{}]);
  const authdata = useSelector((state) => state.auth.user);
  const [captcha, setCaptcha] = useState({ text: "", image: "" });
  const [captcha_text, setcaptcha_text] = useState({ text: "" });
  const [errorvalue, seterrorvalue] = useState("");
  const [otperror, setotperror] = "";
  const [timer, setTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [showresend, setshowresend] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeoutMessage, setTimeoutMessage] = useState("");

  useEffect(() => {
    let interval;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
      setshowresend(true);
    }

    return () => clearInterval(interval);
  }, [isTimerActive, timer]);
  useEffect(() => {
    fetchcaptcha();
  }, []);

  const fetchcaptcha = async () => {
    const res = await axios.get(`${Nodeapi}/captcha`);
    const data = res.data;
    setCaptcha({ text: res.data.captchaText, image: res.data.captchaImage });
    setcaptcha_text({ text: res.data.captchaText });
  };

  const userid = authdata?.id;

  const handlechange = (e) => {
    setdatavalue({ ...datavalue, [e.target.id]: e.target.value });

    if (e.target.id === "aadhaarno") {
      seterrorvalue((prevErrors) => ({
        ...prevErrors,
        aadhaarno: "",
      }));
    }
    if (e.target.id === "captchano") {
      seterrorvalue((prevErrors) => ({
        ...prevErrors,
        captchano: "", 
      }));
    }
  };

  const habdlechange1 = (e) => {
    const val = e.target.value;
    setotpno(val);
  };
  function NetworkSecurityConfig() {
    return (
      <network-security-config>
        <base-config cleartextTrafficPermitted="true">
          <trust-anchors>
            <certificates src="system" />
          </trust-anchors>
        </base-config>
      </network-security-config>
    );
  }

  const handleclick = async (e) => {
    const errors = validdata(datavalue);

    if (Object.keys(errors).length > 0) {
      seterrorvalue(errors);
      return;
    }

    const checkvalue = 0;

    if (Object.keys(errors).length === 0) {
      setvisible(true);
      setdisplay(false);
      setTimer(120);
      setIsTimerActive(true);
      setshowresend(false);

      try {
        NetworkSecurityConfig();
        const response = await axios.post(`${Nodeapi}/aadhaar_otp`, {
          ...datavalue,
          userid: userid,
          checkvalue: checkvalue,
        });
        let val = response.data;
        const data = response.data.result;
        console.log("data:", val);
        setrefereceId(data);
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  const validdata = (values) => {
    const errors = {};
    if (!values.aadhaarno) {
      errors.aadhaarno = "Enter the Aadhaar Number";
    } else if (values.aadhaarno.length !== 12) {
      errors.aadhaarno = "Enter the 12 Digits Aadhaar Number";
    } else if (!/^\d+$/.test(values.aadhaarno)) {
      errors.aadhaarno = "Aadhaar Number must contain only numbers";
    }
    if (!values.captchano) {
      errors.captchano = "Enter the Captcha";
    } else if (captcha_text.text != values.captchano) {
      errors.captchano = "Captcha is Invalid";
    }
    return errors;
  };

  const handlesubmit = async () => {
    setIsSubmitting(true);

    if (!otpno) {
      otperror("Enter the 6 Digit OTP Number");
    }

    if (!/^\d+$/.test(otpno)) {
      otperror("OTP Number must contain only numbers");
    }

    const timeout = setTimeout(() => {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Request Timeout",
        text: "The server is taking too long to respond. Please try again later.",
      });
      setIsSubmitting(false);
    }, 10000);

    try {
      const res = await axios.post(`${Nodeapi}/aadhaar_verify`, {
        otpno: otpno,
        refereceId: refereceId,
        userid: userid,
      });
      const val = res.data;
      const data = res.data.result;

      if (val.success == true) {
        setaadhaardetails(data[0]);
        setvisible1(true);
        clearTimeout(timeout);
        setIsSubmitting(false);
      }
    } catch (error) {
      clearTimeout(timeout);
      console.log("Error:", error);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Failed to Aadhaar Verification",
        text: error.message || "Something went wrong",
      });
      setIsSubmitting(false);
    }
  };

  const handlecaptcha = () => {
    fetchcaptcha();
  };

  const handleconfrim = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Aadhaar Verified Successfully",
      showConfirmButton: false,
      timer: 2000,
    });
    setdisplay(true);
    setvisible1(false);
    setvisible(false);
    setdatavalue({
      aadhaarno: "",
      captchano: "",
    });
    fetchcaptcha();
  };
  const handleclose = () => {
    setvisible1(true);
  };

  const handleresend = async () => {
    setIsTimerActive(true);
    setshowresend(false);
    setTimer(120);

    const checkvalue = 1;

    try {
      const response = await axios.post(`${Nodeapi}/aadhaar_otp`, {
        ...datavalue,
        userid: userid,
        checkvalue: checkvalue,
      });

      const data = response.data.result;
      setrefereceId(data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className="aadhar_card">
      <h3 style={{ color: "#CC0000" }}>Aadhaar Verification</h3>
      {display && (
        <div className="aadhar-content" style={{ display: "flex" }}>
          <img src={aadhaar} className="image" alt="Aadhaar card"></img>
          <div className="aadhar-details" style={{ marginTop: "8px" }}>
            <div>
              <label style={{ fontSize: "17px" }}>Aadhaar Number:</label>
              <input
                type="text"
                className="aadhaar-input"
                id="aadhaarno"
                value={datavalue.aadhaarno}
                onChange={handlechange}
              />
            </div>
            <p style={{ color: "#F77256" }}>{errorvalue.aadhaarno}</p>

            <div className="aadhar-captcha">
              <label>Enter Security Code:</label>
              <div className="aadhar-captcha-content">
                <input
                  type="text"
                  className="captcha_text"
                  id="captchano"
                  value={datavalue.captchano}
                  onChange={handlechange}
                />
                <div className="captcha-img">
                  <img
                    src={`data:image/png;base64,${captcha.image}`}
                    alt="captcha"
                  />
                  <VscSync className="loading" onClick={handlecaptcha} />
                </div>
              </div>
            </div>
            <p style={{ color: "#F77256" }}>{errorvalue.captchano}</p>

            <button className="submit" onClick={handleclick}>
              Get OTP
            </button>
          </div>
        </div>
      )}

      {visible && (
        <>
          <div className="aadhar-container">
            <img src={aadhaar} className="image" alt="Aadhaar card" />
            <div className="aadhar-details">
              <label className="otp-label">
                Enter the 6-digit OTP received on the phone number linked to
                your Aadhaar +91xxxxxxxx234
              </label>
              <input
                type="text"
                className="aadhaar-input"
                onChange={habdlechange1}
              />

              {!showresend && isTimerActive && (
                <p className="timer-text">
                  Time remaining: {formatTime(timer)}
                </p>
              )}
              {showresend && (
                <button className="resend-button" onClick={handleresend}>
                  Resend
                </button>
              )}
            </div>
            <p className="otp-error">{otperror}</p>
            <div className="submit-container">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handlesubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </>
      )}

      <Dialog
        header="Confrim Aadhaar Details"
        visible={visible1}
        style={{ width: "35vw"}}
        onHide={() => setvisible1(false)}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      >
        <table className ="aadhar-confirm-details" style={{ marginLeft: "10px" }}>
          <tbody>
            <div className ="aadhar-confirm-details-content">
              <tr>
                <td
                  style={{
                    border: "1px ",
                    textAlign: "left",
                    fontWeight: "bold",
                    paddingRight: "5px",
                  }}
                >
                  Name
                </td>
                <td
                  style={{
                    border: "1px ",
                    textAlign: "left",
                  }}
                >{`:  ${aadhaardetails.Name}`}</td>
              </tr>
            </div>
            <div  className ="aadhar-confirm-details-content" style={{ marginTop: "7px" }}>
              <tr>
                <td
                  style={{
                    border: "1px ",
                    textAlign: "left",
                    fontWeight: "bold",
                    paddingRight: "5px",
                  }}
                >
                  Date of Birth
                </td>
                <td
                  style={{
                    border: "1px ",
                    textAlign: "left",
                  }}
                >{`:  ${aadhaardetails.DOB}`}</td>
              </tr>
            </div>
            <div className ="aadhar-confirm-details-content" style={{ marginTop: "7px" }}>
              <tr>
                <td
                  style={{
                    border: "1px ",
                    textAlign: "left",
                    fontWeight: "bold",
                    paddingRight: "5px",
                  }}
                >
                  Gender
                </td>
                <td
                  style={{
                    border: "1px ",
                    textAlign: "left",
                  }}
                >{`:  ${aadhaardetails.Gender}`}</td>
              </tr>
            </div>

            <div className ="aadhar-confirm-details-content" style={{ marginTop: "7px" }}>
              <tr>
                <td
                  style={{
                    border: "1px ",
                    textAlign: "left",
                    fontWeight: "bold",
                    paddingRight: "5px",
                  }}
                >
                  Address
                </td>
                <td
                  style={{
                    border: "1px ",
                    textAlign: "left",
                  }}
                >{`:  ${aadhaardetails.Address}`}</td>
              </tr>
            </div>
          </tbody>
        </table>

        <div
          style={{ display: "flex", marginTop: "20px",justifyContent:"flex-end"}}
        >
          <button onClick={handleconfrim} className="btn btn-primary">
            Confrim
          </button>
          <button
            onClick={handleclose}
            style={{ marginLeft: "20px", width: "85px" }}
            className="btn btn-danger me-2"
          >
            Cancel
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default Aadhaar;
