import React, { useState,useContext,useMemo } from "react";
import "./ChatSection.css";
import { useRef } from "react";
import axios from "axios";
import { Nodeapi } from "../../../config/serverUrl";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { SocketApi } from "../../../config/serverUrl";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import { DataContext } from "../../../Admin/DataContext";
import { useDispatch } from "react-redux";
import { updateMessageStatus,removeMessages,markMessageDeleted,addMessage,removeMessageById,updateChatMessage,updateReactionMessage } from "../../../react-redux/slices/chatSlice";

const ChatHeader = React.lazy(() => import('./ChatHeader/ChatHeader'));
const ChatFooter = React.lazy(() => import('./ChatFooter/ChatFooter'));
const ChatArea = React.lazy(() => import('./ChatArea/ChatArea'));
const ChatSidebar = React.lazy(() => import('./ChatSidebar'));

const socket = io(SocketApi);

const ChatSection = ({
  setTheme,
  themeChange,
  setShowChat,
  Receiverid,
  startchatmasterid,
  setReplyNames,
  ReplyNames,
  selectionMode,
  setSelectionMode,
  setMessageIds,
  messageIds,
  handleSelectionClose,
  ScroolDown,
  setScroolDown,
  setLastseen,
  Lastseen,
  setCallAccept,
  setCallChatmasterid,
  blocked,
  setBlocked,
  blockedArray,
  setBlockedArray
}) => {
  const {  setSelectedGif,Gifsubtype, setMainScrool,setFwmessage,SelectedContact,setSelectedContact,handleUpdatemessage,inputfield,setInputfield,setMessageDeliver,convertToIST,userTmZone,setEmoji,setGifsubtype,setTextReply,Profileimg,collapse,
    textRows, setTextRows,userName,setSelectedImage,SelectFile, setSelectFile,chathistory, setChathistory,scrollViewRef,setTime,setRecordedBlob,microphone, setClickmicrophone,handleEditSendMessage,edit,editMsg,
   } = useContext(DataContext);

  const authdata = useSelector((state) => state.auth.user);
  const chatdata = useSelector((state) => state);
  const toast = useRef(null);
  const receiveMessageHandlerRef = useRef(null);

  
  const [Subtype, setSubtype] = useState('');
  const [inputKey, setInputKey] = useState(0);
  
  const dispatch = useDispatch();

 
  const containsLink = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return urlPattern.test(text);
  };

  const [ReplyBtn, setReplyBtn] = useState(false);

  const handleReply = () => {
    setReplyBtn(!ReplyBtn);
  };

  console.log('chatdata',chatdata);
  

  const [ReplyData, setReplyData] = useState("");
 
  const [ScroolBottom, setScroolBottom] = useState("");
  const [replymessageinput, setReplyMsgInput] = useState(false);
  const [imagereplydata, setImagereplydata] = useState({});

 
  const isInArray = true;
  const [sendimage,setsenImage] = useState(false);
  const [sendidoc,setsenDoc] = useState(false);
  const [sendVideo,setVideo] = useState(false);
  const [sendAudio,setAudio] = useState(false);

  const [hideScrollButton, setHideScrollButton] = useState(false);


  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isTypingid, setIsTypingId] = useState(false);
  const typingTimeoutRef = useRef(null);


 

  
//   useEffect(() => {
//   const userId = authdata?.id;
  
  
//   socket.emit('joinChatRoom', { userId, roomId: startchatmasterid });


//   socket.on('receiveMessage', receiveMessageHandler);

//   const handleBeforeUnload = () => {
    
//     socket.emit('leaveRoom', { userId, roomId: startchatmasterid });
//   };

//   window.addEventListener('beforeunload', handleBeforeUnload);

//   return () => {
    
//     socket.emit('leaveRoom', { userId, roomId: startchatmasterid });
//     socket.off('receiveMessage', receiveMessageHandler);
//     window.removeEventListener('beforeunload', handleBeforeUnload);
//   };
// }, [authdata, startchatmasterid]);





useEffect(() => {
  if (!startchatmasterid) return; // Ensure startchatmasterid is not null or undefined

  const userId = authdata?.id;
  const isGroup = startchatmasterid.substring(0, 5) === 'GROUP';

  const joinRoomEvent = isGroup ? 'GroupjoinChatRoom' : 'joinChatRoom';
  const leaveRoomEvent = isGroup ? 'GroupleaveRoom' : 'leaveRoom';

  socket.emit(joinRoomEvent, { userId, roomId: startchatmasterid });

  socket.on('receiveMessage', receiveMessageHandler);

  const handleBeforeUnload = () => {
    socket.emit(leaveRoomEvent, { userId, roomId: startchatmasterid });
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    socket.emit(leaveRoomEvent, { userId, roomId: startchatmasterid });
    socket.off('receiveMessage', receiveMessageHandler);
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, [authdata, startchatmasterid]);

  useEffect(() => {
    socket.on('typing', (id) => {
      setIsTyping(true);
      setIsTypingId(id);
    });

    socket.on('stop typing', (id) => {
      setIsTyping(false);
      setIsTypingId(id);
    });

    return () => {
      socket.off('typing');
      socket.off('stop typing');
    };
  }, []);

  const handleInputChange = (e) => {
    setInputfield(e.target.value);
    if (e.target.value) {
      if (!typing) {
        setTyping(true);
        socket.emit('typing',{sender_id:authdata?.id,receiverid:Receiverid});
      }
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        setTyping(false);
        socket.emit('stop typing',{sender_id:authdata?.id,receiverid:Receiverid});
      }, 1000);
    } else {
      setTyping(false);
      socket.emit('stop typing',{sender_id:authdata?.id,receiverid:Receiverid});
    }
  };

   

  const handleKeyDown = () => {
    if (!typing) {
      setTyping(true);
      socket.emit('typing',{sender_id:authdata?.id,receiverid:Receiverid});
    }
    clearTimeout(typingTimeoutRef.current);
  };

  const handleKeyUp = () => {
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      setTyping(false);
      socket.emit('stop typing',{sender_id:authdata?.id,receiverid:Receiverid});
    }, 1000);
  };

  const handleBlur = () => {
    setTyping(false);
    socket.emit('stop typing',{sender_id:authdata?.id,receiverid:Receiverid});
  };



  useEffect(() => {
    const handleKeyPress = async(event) => {
      if (event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        if(textRows < 4){
          setTextRows(previous => previous + 1);
        }
        const { selectionStart, selectionEnd } = event.target;
        const newText = `${inputfield.substring(
          0,
          selectionStart
        )}\n${inputfield.substring(selectionEnd)}`;

        setInputfield(newText);

        // Maintain cursor position after inserting line break
        const newPosition = selectionStart + 1;
        event.target.setSelectionRange(newPosition, newPosition);
      }
      else if (event.key === "Enter" ) {
        if (!edit) {      
          handleSendmessage();
        } else {
          handleEditSendMessage(editMsg);
          
        }
      } else if(event.key === "Backspace"){
        const { selectionStart } = event.target;
      const lines = inputfield.split('\n');
      let currentLineStart = 0;

      for (let i = 0; i < lines.length; i++) {
        const lineLength = lines[i].length;
        if (selectionStart <= currentLineStart + lineLength) {
          if (selectionStart === currentLineStart) {
            if (textRows > 1) {
              setTextRows((prevCount) => prevCount - 1);
            }
          }
          break;
        }
        currentLineStart += lineLength + 1; // +1 for the newline character
      }
      }else if (event.key === "Delete") {
        const { selectionStart, selectionEnd, value } = event.target;
        const lines = value.split('\n');
        let currentLineStart = 0;
  
        for (let i = 0; i < lines.length; i++) {
          const lineLength = lines[i].length;
          if (selectionStart < currentLineStart + lineLength) {
            if (selectionStart === currentLineStart + lineLength - 1) {
              if (textRows > 1) {
                setTextRows((prevCount) => prevCount - 1);
              }
            }
            break;
          }
          currentLineStart += lineLength + 1; // +1 for the newline character
        }
      } 
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [inputfield]);


 






  const receiveMessageHandler = useMemo(() => {
    return (data) => {
      console.log('success response socket',data);
      
      try {
          
        if (data.message === "success" && data.chatmaster_id === startchatmasterid) {
      
          setSubtype(" ");

          setSubtype("");
          
          fetchLastMessage(startchatmasterid, authdata?.id, data.status);
        }
        else if (data.message === "OpenChatlist" && data.chatmaster_id === startchatmasterid) {
          dispatch(updateMessageStatus({ receiver_id: data.receiver_id, authId: authdata?.id }));
        } else if (data.message == "doubletick") {
        } else if (data.message == "SelectDelete" && data.chatmaster_id === startchatmasterid && data.sender_id ==authdata?.id) {
          const idsToRemove = data.id;
          dispatch(removeMessages(idsToRemove));
        } else if (data.message == "deleteForMe" && data.chatmaster_id === startchatmasterid) {
          if (
            Array.isArray(data.deleted_for) &&
            data.deleted_for.includes(authdata.id) && data.chatmaster_id === startchatmasterid
          ) {
            dispatch(removeMessageById({ id: data.id }));
          }
        } else if (data.message === "deleteForEveryone" && data.chatmaster_id === startchatmasterid) {
          dispatch(markMessageDeleted({
            id: data.id,
            chatmaster_id: data.chatmaster_id,
            currentChatmasterId:startchatmasterid,
        }));

        }
        else if (data.message === "EditMessage") {
          dispatch(updateChatMessage({id:data.id, newMessage:data.updatedMessage}));
        }else if (data.message==="success_reaction"){
          dispatch(updateReactionMessage({id:data.id, newMessage:data.text}));
        }
      } catch (error) {
        console.error("Error in receiveMessageHandler:", error);
      }
    };
  }, [startchatmasterid, authdata?.id]);

  useEffect(() => {
    if (socket.connected) {
      // Ensure that we don't add multiple event listeners
      if (receiveMessageHandlerRef.current !== receiveMessageHandler) {
        if (receiveMessageHandlerRef.current) {
          socket.off("receive_message", receiveMessageHandlerRef.current);
        }
        socket.on("receive_message", receiveMessageHandler);
        receiveMessageHandlerRef.current = receiveMessageHandler;
      }

      // Cleanup function
      return () => {
        if (receiveMessageHandlerRef.current) {
          socket.off("receive_message", receiveMessageHandlerRef.current);
          receiveMessageHandlerRef.current = null;
        }
      };
    }
  }, [socket.connected, receiveMessageHandler]);




  const handleCheckboxChange = (id, data,checked) => {
    setMessageIds((prevIds) =>
      checked ? [...prevIds, id] : prevIds.filter((messageId) => messageId !== id)
  );
  setFwmessage((prevDatas) =>
    checked
      ? [...prevDatas, data]
      : prevDatas.filter((prevDatas) => prevDatas.id !== data.id)
  );
};
   

   

    const handleClearChat = async(userid) => {
      const clearid = [userid];
      const res = await axios.post(`${Nodeapi}/clearchat`,{
        chatmaster_id:startchatmasterid,
        userid:JSON.stringify(clearid),
      });
      if(res.data){
        if(res.data.code == 200){
          toast.current.show({severity:'success', summary: 'Success', detail: res.data.data.message , life: 3000});
        }else{
          toast.current.show({severity:'error', summary: 'Error', detail: res.data.data.message , life: 3000});
        }
      }
    }
    const validSubtypes = ["reply", "img_reply", "video_reply", "audio_reply", "doc_reply","link","audio","gif","gif_reply"];


    const handleMultiForward = async (chatData) => {
      const sortedData = chatData.sort((a, b) => a.id - b.id);
      try {

        for (let i = 0; i < SelectedContact.length; i++) {
          for (let j = 0; j < sortedData.length; j++) {
            const ch = SelectedContact[i];
            const msg = sortedData[j];
    
            const currentTime = new Date();
            const hours = currentTime.getHours();
            const minutes = currentTime.getMinutes();
            const ampm = hours >= 12 ? "pm" : "am";
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = String(minutes).padStart(2, "0"); // Pad minutes with leading zero if needed
    
            const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    
            // data replace
            const formData = new FormData();
            formData.append("senderid", authdata?.id);
            formData.append("receiverid", ch.receiver_id);
            formData.append("type", "forward");
            formData.append("message", msg.message);
            formData.append("time", formattedTime);
            formData.append("incoming", false); // Use boolean value instead of string
            formData.append("outgoing", true); // Use boolean value instead of string
            formData.append("seen", false);
            formData.append("subtype", msg.subtype);
            formData.append("chatmaster_id", ch.chatmasterid);
            formData.append("image", msg.img);
            formData.append("audio", "");
            formData.append(
              "reply",
              imagereplydata.msg
                ? imagereplydata.msg
                : validSubtypes.includes(Subtype)
                ? inputfield
                : msg.reply
            );
            formData.append("Msgtype", "User");
            formData.append("replyimg", imagereplydata.img);
            formData.append("delivert_status", "");
          
    
            const res = await axios.post(`${Nodeapi}/msgconversation`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
    
            if (res.data) {
              if (res.data.code == 200) {
                setInputfield("");
                setMessageDeliver(true);
                setSubtype("");
              } else {
                console.log(res);
              }
            }
    
            setEmoji(false);
            setReplyData("");
            setImagereplydata("");
            setInputKey((prevKey) => prevKey + 1);
            setSubtype("");
            setSelectFile(" ");
            setsenImage(false);
            setsenDoc(false);
            setAudio(false);
            setVideo(false);
            setMainScrool(true);
            setReplyBtn(false);
            setClickmicrophone(false);
            setSelectedContact([]);
            setFwmessage([]);
          }
        }
      } catch (error) {
        console.error('Error in handleMultiForward:', error);
      }
    };
    



  const handleSendmessage = async () => {
    console.log(Subtype,Gifsubtype);
    
    setTyping(false);
    socket.emit('stop typing');
    setInputfield("");
    setSelectFile("");
    const message = { text: inputfield, sender: authdata?.id, receiver: Receiverid, roomId:startchatmasterid };
    setTextRows(1);
    try {
      const currentTime = new Date();
      const istTime = convertToIST(currentTime, userTmZone.timezone);
    

      const formData = new FormData();
      formData.append("senderid", authdata?.id);     
      formData.append("receiverid", Receiverid);
      formData.append("type", "msg");
      formData.append("message",imagereplydata.message ? imagereplydata.message : inputfield);
      formData.append("time", istTime);
      formData.append("incoming", false); // Use boolean value instead of string
      formData.append("outgoing", true); // Use boolean value instead of string
      formData.append("subtype", validSubtypes.includes(Subtype) ? Subtype : containsLink(inputfield) ? 'link' : Gifsubtype == 'gif' ? Gifsubtype : '');
      formData.append("chatmaster_id", startchatmasterid);
      formData.append("image", SelectFile ? SelectFile : "");
      formData.append("file_size", SelectFile ? SelectFile.size : "");
      formData.append("audio", "");
      formData.append("reply", imagereplydata.msg ? imagereplydata.msg : validSubtypes.includes(Subtype) ? Gifsubtype == 'gif' ? SelectFile : inputfield : '');
      formData.append("Msgtype", "User");
      formData.append("replyimg", imagereplydata.img);  
      formData.append("delivert_status", "");
      
      const res = await axios.post(`${Nodeapi}/msgconversation`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
      }
      setSelectedImage(null);
      setRecordedBlob(null);
      setTime(0);
      setTextReply('text');
      setEmoji(false);
      setReplyData("");
      setImagereplydata("");
      setInputKey((prevKey) => prevKey + 1);
      setSubtype("");
      setGifsubtype("");
      setSelectFile(" ");
      setFwmessage([]);
      setsenImage(false); 
      setsenDoc(false);
      setAudio(false);
      setVideo(false);
      setSelectedGif(false);
      setMainScrool(true);
      setReplyBtn(false);
      setClickmicrophone(false);
      if (res.data) {
        if (res.data.code == 200) {
        // socket.emit('sendMessage', message);
          setInputfield("");
          setSubtype("");
        } else {
          console.log(res);
        }
      }
    } catch (err) {
    
      console.log("err of insert data", err);
    }
  };


  const handleMutiDelete = async() => {
    try {
      const data = {
        messageIds: messageIds,
        chatmasterid: startchatmasterid,
        deleteType: "deleteForMe", // "deleteForEveryone" or "deleteForMe"
        userId: authdata.id,
      };
      const res = await axios.post(`${Nodeapi}/deleteMultiCnvMsg`, data);
      if (res.data) {
        if (res.data.code == 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.data.message,
            life: 2000,
          });
        } else {
          
          // toast.current.show({
          //   severity: "error",
          //   summary: "Error in else",
          //   detail: res.data.data.message,
          //   life: 2000,
          // });
        }
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error in catch",
        detail: err.data.data.message,
        life: 2000,
      });
    }
  };

  const handleBlock = async () => {
    try {
      const data = {
        chatmasterid: startchatmasterid,
        userId: authdata.id,
      };

      const res = await axios.post(`${Nodeapi}/blockContact`, data);

      if (res.data) {
        if (res.data.code == 200) {
          const isBlocked = Array.isArray(res.data.data.blocked_by) && res.data.data.blocked_by.includes(authdata.id);

          setBlockedArray((prevArray) => {
            if (isBlocked) {
              if (!prevArray.includes(startchatmasterid)) {
                setBlocked(true);
                return [...prevArray, startchatmasterid];
              }
            } else {
              setBlocked(false);
              return prevArray.filter(id => id !== startchatmasterid);
            }
            return prevArray;
          });

          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.data.message,
            life: 2000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error in else",
            detail: res.data.data.message,
            life: 2000,
          });
        }
      }
    
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error in catch",
        detail: err.message,  // Changed to err.message for better error detail
        life: 2000,
      });
    }
  };

  const handleDelete = async (id, deleteType) => {
    try {
      const data = {
        message_id: id.id,
        chatmasterid: id.chatmaster_id,
        deleteType: deleteType, // "deleteForEveryone" or "deleteForMe"
        userId: authdata.id,
      };
      const res = await axios.post(`${Nodeapi}/deleteOneCnvMsg`, data);
      if (res.data) {
        if (res.data.code == 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.data.message,
            life: 2000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.data.data.message,
            life: 2000,
          });
        }
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err.data.data.message,
        life: 2000,
      });
    }
  };

  

  const fetchLastMessage = async (chat_masterid, s_id, status) => {
    try {
      const chathistoryids = {
        params: {
          chatmasterid: chat_masterid,
          sender_id: s_id,
        },
      };

      const response = await axios.get(
        `${Nodeapi}/fetchlastmsg`,
        chathistoryids
      );
      setMainScrool(true);


      const newMessage = response.data.data.lastMsg;
      newMessage.status = status; // Replace with actual status
      dispatch(addMessage(newMessage));
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
      }
      if(chat_masterid.substring(0, 5) !== 'GROUP'){
        handleUpdatemessage(response.data.data.lastMsg.id,status);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="main-chat">
     <React.Suspense fallback={<div>Loading ....</div>}>
     <Toast ref={toast}/>
      <div className="chatsection">
        <ChatHeader
        isTypingid={isTypingid}
         isTyping={isTyping}
          userName={userName}
          setShowChat={setShowChat}
          startchatmasterid={startchatmasterid}
          socket={socket}
          Receiverid={Receiverid}
          handleClearChat={handleClearChat}
          Profileimg={Profileimg}
          setSelectionMode= {setSelectionMode}
          selectionMode ={selectionMode}
          handleSelectionClose={handleSelectionClose}
          messageIds ={messageIds}
          handleMutiDelete={handleMutiDelete}
          setLastseen={setLastseen}
          Lastseen={Lastseen}
          blocked={blocked}
          setCallAccept={setCallAccept}
          setCallChatmasterid={setCallChatmasterid}
          handleBlock={handleBlock}
          handleMultiForward={handleMultiForward}
          setChathistory={setChathistory}
        />
        <ChatArea
        isTyping={isTyping}
       
         Receiverid={Receiverid}
         startchatmasterid={startchatmasterid}
         setChathistory={setChathistory}
         ScroolBottom={ScroolBottom}
          chathistory={chathistory}
          handleReply={handleReply}
          setReplyBtn={setReplyBtn}
          ReplyBtn={ReplyBtn}
          setReplyData={setReplyData}
          ReplyData={ReplyData}
          userName={userName}
          setReplyNames={setReplyNames}
          handleDelete={handleDelete}
          
          selectionMode ={selectionMode}
          handleCheckboxChange={handleCheckboxChange}
          handleSelectionClose={handleSelectionClose}
          ScroolDown={ScroolDown}
          setScroolDown={setScroolDown}
          setHideScrollButton={setHideScrollButton}
          hideScrollButton={hideScrollButton}
          
        />
         {blocked ? (<p className="blocked">This contact is Blocked</p>) : (
          <ChatFooter
          socket={socket}
          isTyping={isTyping}
          handleKeyDown={handleKeyDown}
           handleKeyUp={handleKeyUp}
            handleBlur={handleBlur}
          handleInputChange={handleInputChange}
            ReplyNames={ReplyNames}
            microphone={microphone}
            inputfield={inputfield}
            Subtype={Subtype}
            Receiverid={Receiverid}
            handleReply={handleReply}
            ReplyBtn={ReplyBtn}
            setReplyBtn={setReplyBtn}
            setReplyData={setReplyData}
            ReplyData={ReplyData}
            setSubtype={setSubtype}
            handleSendmessage={handleSendmessage}
            inputKey={inputKey}
            setReplyMsgInput={setReplyMsgInput}
            setImagereplydata={setImagereplydata}
            sendimage={sendimage}
            setsenImage={setsenImage}
            setScroolDown={setScroolDown}
            sendidoc={sendidoc}
            setsenDoc={setsenDoc}
            sendVideo={sendVideo}
            setVideo={setVideo}
            sendAudio={sendAudio}
            setAudio={setAudio}
            userName={userName}
            hideScrollButton={hideScrollButton}
            startchatmasterid={startchatmasterid}
          />
        )}
      </div>
      {collapse ? (
        <ChatSidebar
        Receiverid={Receiverid}
          userName={userName}
          setTheme={setTheme}
          themeChange={themeChange}
          startchatmasterid={startchatmasterid}
        />
      ) : null}
     </React.Suspense>

      
    </div>
  );
};

export default ChatSection;
