import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  FormControl,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInput from "react-phone-input-2";
import { IoClose } from "react-icons/io5";
import { MdDriveFolderUpload } from "react-icons/md";
import { City, Country, State } from "country-state-city";
import { DataContext } from "../../Admin/DataContext";
import { Assetsapi } from "../../config/serverUrl";
import postCategory from  "./Data/ModalCategories"

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  minWidth: 400,
  maxWidth: "800vw",
  maxHeight: "669px",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const LostAndFoundModal = ({
  open,
  handleClose,
  reportType,
  formData,
  setFormData,
  errors,
  handleChange,
  handleRemoveImage,
  handleSubmit,
  mode,
}) => {
  const [countryNames, setCountryNames] = useState([]);
  const [stateValues, setStateValues] = useState([]);
  const [cityValues, setCityValues] = useState([]);

  useEffect(() => {
    getlocation();
  }, []);

  useEffect(() => {
    if (mode === "edit" && formData.country) {
      const selectedCountry = countryNames.find(
        (country) => country.name === formData.country
      );
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry.isoCode);
        setStateValues(states);
        if (formData.state) {
          const selectedState = states.find(
            (state) => state.name === formData.state
          );
          if (selectedState) {
            const cities = City.getCitiesOfState(
              selectedState.countryCode,
              selectedState.isoCode
            );
            setCityValues(cities);
          }
        }
      }
    }
  }, [mode, formData.country, formData.state, countryNames]);

  const { setcountry_check } = useContext(DataContext);

  const getlocation = () => {
    const countries = Country.getAllCountries();
    const countryNames = countries.map((country) => ({
      name: country.name,
      isoCode: country.isoCode,
    }));
    setCountryNames(countryNames);
  };

  const handleCountryDropdown = (e) => {
    const selectedCountryIsoCode = e.target.value;
    const selectedCountry = countryNames.find(
      (country) => country.isoCode === selectedCountryIsoCode
    );
    if (selectedCountry) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: selectedCountry.name,
      }));
      const states = State.getStatesOfCountry(selectedCountryIsoCode);
      setStateValues(states);
      setCityValues([]);
      setcountry_check(selectedCountry.name === "India");
    } else {
      setStateValues([]);
      setCityValues([]);
    }
  };

  const handleStateDropdown = (e) => {
    const selectedStateIsoCode = e.target.value;
    const selectedState = stateValues.find(
      (state) => state.isoCode === selectedStateIsoCode
    );
    if (selectedState) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        state: selectedState.name,
      }));
      const cities = City.getCitiesOfState(
        selectedState.countryCode,
        selectedState.isoCode
      );
      setCityValues(cities);
    } else {
      setCityValues([]);
    }
  };

  const formatDateTimeForInput = (isoDateTime) => {
    if (!isoDateTime) return "";
    const date = new Date(isoDateTime);
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60000);
    return localDate.toISOString().slice(0, 16);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", top: 8, right: 8, padding: "25px" }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-title"
          variant="h6"
          component="h2"
          className="mb-3"
          sx={{ fontWeight: "600" }}
        >
          {reportType.toLowerCase() === "lost" ? "Report Lost" : "Report Found"}
        </Typography>
        <form noValidate autoComplete="on" onSubmit={handleSubmit}>
          <Box sx={{ marginBottom: 2 }}>
            <div className="flex flex-wrap gap-2">
              <div style={{ flex: "1 1 48%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="itemName">Item Name</label>
                  <input
                    type="text"
                    name="itemName"
                    id="itemName"
                    value={formData.itemName}
                    onChange={handleChange}
                    style={{
                      flex: 1,
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                  />
                  {errors.itemName && (
                    <span className="error-text_lnf">{errors.itemName}</span>
                  )}
                </div>
              </div>
              <div style={{ flex: "1 1 48%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="category">Category</label>
                  <select
                    name="category"
                    id="category"
                    value={formData.category || ""}
                    onChange={handleChange}
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  >
                    <option value="" disabled>
                      {formData.category ? "" : "Select Category"}
                    </option>
                    {postCategory.map((category) => (
                      <optgroup key={category.code} label={category.label}>
                        {category.items.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                  {errors.category && (
                    <span className="error-text_lnf">{errors.category}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-2" style={{ marginTop: "10px" }}>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="country">Country</label>
                  <select
                    name="country"
                    id="country"
                    value={
                      formData.country
                        ? countryNames.find((c) => c.name === formData.country)
                            ?.isoCode
                        : ""
                    }
                    onChange={handleCountryDropdown}
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                  >
                    <option value="" disabled>
                      Select a Country
                    </option>
                    {countryNames.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <span className="error-text_lnf">{errors.country}</span>
                  )}
                </div>
              </div>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="state">State</label>
                  <select
                    name="state"
                    id="state"
                    value={
                      stateValues.find((state) => state.name === formData.state)
                        ?.isoCode || ""
                    }
                    onChange={handleStateDropdown}
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                    disabled={!formData.country}
                  >
                    <option
                      value=""
                      disabled
                      selected={stateValues.length === 0}
                    >
                      Select a State
                    </option>
                    {stateValues.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <span className="error-text_lnf">{errors.state}</span>
                  )}
                </div>
              </div>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="district">District</label>
                  <select
                    name="district"
                    id="district"
                    value={formData.district}
                    onChange={(e) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        district: e.target.value,
                      }))
                    }
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                    disabled={!formData.state}
                  >
                    <option
                      value=""
                      disabled
                      selected={cityValues.length === 0}
                    >
                      Select a District
                    </option>
                    {cityValues.length > 0 &&
                      cityValues.map((district) => (
                        <option key={district.isoCode} value={district.name}>
                          {district.name}
                        </option>
                      ))}
                  </select>
                  {errors.district && (
                    <span className="error-text_lnf">{errors.district}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-2" style={{ marginTop: "10px" }}>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleChange}
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                  />
                  {errors.city && (
                    <span className="error-text_lnf">{errors.city}</span>
                  )}
                </div>
              </div>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  {reportType.toLowerCase() === "lost" ? (
                    <div>
                      <label htmlFor="lostAt">Lost At</label>
                      <input
                        type="text"
                        name="lostAt"
                        id="lostAt"
                        value={formData.lostAt}
                        onChange={handleChange}
                        style={{
                          height: "39px",
                          marginTop: "2px",
                          width: "100%",
                        }}
                      />
                      {errors.lostAt && (
                        <span className="error-text_lnf">{errors.lostAt}</span>
                      )}
                    </div>
                  ) : reportType.toLowerCase() === "found" ? (
                    <div>
                      <label htmlFor="foundAt">Found At</label>
                      <input
                        type="text"
                        name="foundAt"
                        id="foundAt"
                        value={formData.foundAt}
                        onChange={handleChange}
                        style={{
                          height: "39px",
                          marginTop: "2px",
                          width: "100%",
                        }}
                      />
                      {errors.foundAt && (
                        <span className="error-text_lnf">{errors.foundAt}</span>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="street">Street</label>
                  <input
                    type="text"
                    name="street"
                    id="street"
                    value={formData.street}
                    onChange={handleChange}
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                  />
                  {errors.street && (
                    <span className="error-text_lnf">{errors.street}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-2" style={{ marginTop: "10px" }}>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="area">Area</label>
                  <input
                    type="text"
                    name="area"
                    id="area"
                    value={formData.area}
                    onChange={handleChange}
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                  />
                  {errors.area && (
                    <span className="error-text_lnf">{errors.area}</span>
                  )}
                </div>
              </div>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="landmark">Landmark</label>
                  <input
                    type="text"
                    name="landmark"
                    id="landmark"
                    value={formData.landmark}
                    onChange={handleChange}
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                  />
                  {errors.landmark && (
                    <span className="error-text_lnf">{errors.landmark}</span>
                  )}
                </div>
              </div>
              <div style={{ flex: "1 1 30%" }}>
                <div className="p-field_lnf">
                  <label htmlFor="pincode">Pincode</label>
                  <input
                    type="text"
                    name="pincode"
                    id="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    style={{
                      height: "39px",
                      marginTop: "2px",
                      width: "100%",
                    }}
                  />
                  {errors.pincode && (
                    <span className="error-text_lnf">{errors.pincode}</span>
                  )}
                </div>
              </div>
            </div>
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <div className="flex flex-wrap gap-2">
              <div className="p-field_lnf" style={{ width: "100%" }}>
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows={4}
                  style={{
                    width: "100%",
                    marginTop: "2px",
                    resize: "none",
                    overflowY: "auto",
                  }}
                />
                {errors.description && (
                  <span className="error-text_lnf">{errors.description}</span>
                )}
              </div>
            </div>
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <div className="flex flex-wrap gap-2">
              <div className="p-field_lnf" style={{ marginBottom: "15px" }}>
                <label
                  htmlFor="dateTime"
                  style={{
                    display: "block",
                    marginBottom: "2px",
                  }}
                >
                  Date and Time
                </label>
                <input
                  type="datetime-local"
                  name="dateTime"
                  id="dateTime"
                  value={formatDateTimeForInput(formData.dateTime)}
                  onChange={handleChange}
                  style={{
                    flex: 1,
                    height: "39px",
                    marginTop: "2px",
                  }}
                />
                {errors.dateTime && (
                  <span
                    className="error-text_lnf"
                    style={{ display: "block", marginTop: "2px" }}
                  >
                    {errors.dateTime}
                  </span>
                )}
              </div>
            </div>
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <div className="flex flex-wrap gap-2">
              <div className="p-field_lnf" style={{ flex: "1 1 20%" }}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    height: "39px",
                    marginTop: "2px",
                  }}
                />
                {errors.name && (
                  <span className="error-text_lnf">{errors.name}</span>
                )}
              </div>
              <div className="p-field_lnf" style={{ flex: "1 1 20%" }}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    height: "39px",
                    marginTop: "2px",
                  }}
                />
                {errors.email && (
                  <span className="error-text_lnf">{errors.email}</span>
                )}
              </div>
              <div className="p-field_lnf" style={{ flex: "1 1 25%" }}>
                <label htmlFor="contactNo">Contact No</label>
                <PhoneInput
                  country={"in"}
                  value={formData.contactNo}
                  name="contactNo"
                  onChange={(value) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      contactNo: value,
                    }));
                  }}
                  className="border-example"
                />
                {errors.contactNo && (
                  <span className="error-text_lnf">{errors.contactNo}</span>
                )}
              </div>
            </div>
            <div>
              <FormControl fullWidth margin="normal" error={!!errors.image}>
                <label style={{ marginBottom: "2px" }}>Upload Images</label>
                {(mode === "edit" || mode === "add") &&
                  Array.isArray(formData.images) &&
                  formData.images.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                      <div className="flex flex-row flex-wrap gap-2">
                        {Array.from(formData.images).map((image, index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: "10px",
                              position: "relative",
                              maxWidth: "100px",
                            }}
                          >
                            <img
                              src={
                                typeof image === "string"
                                  ? `${Assetsapi}/adimages/${image}`
                                  : URL.createObjectURL(image)
                              }
                              alt={`Uploaded ${index}`}
                              style={{ width: "100%", height: "auto" }}
                            />
                            <Button
                              onClick={() => handleRemoveImage(index)}
                              sx={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                background: "#f44336",
                                borderRadius: "50%",
                                minWidth: "24px",
                                height: "24px",
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "white",
                              }}
                            >
                              <IoClose style={{ fontSize: "16px" }} />
                            </Button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                <label
                  htmlFor="upload-photo"
                  className="upload-card p-0"
                  style={{
                    border: "1px dashed #68bcff",
                    borderRadius: "10px",
                    background: "#eff5fc",
                    width: "7rem",
                    height: "6rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "-7px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    hidden
                    multiple
                    // accept="image/*"
                    type="file"
                    id="upload-photo"
                    name="images"
                    onChange={handleChange}
                  />
                  <div
                    className="icon"
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "0.5rem",
                      color: "#2196f3",
                    }}
                  >
                    <MdDriveFolderUpload />
                  </div>
                  <div className="overlay">
                    <p style={{ color: "#2196f3" }}>Add</p>
                  </div>
                </label>
              </FormControl>
            </div>
          </Box>
          <Box sx={{ marginTop: 2 }} className="flex justify-content-center">
            <Button
              variant="outlined"
              sx={{
                mt: 4,
                background: "#dc3545",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#dc3545",
                  border: "1px solid red",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: 4,
                background: "#2196F3",
                "&:hover": {
                  backgroundColor: "#2196f3",
                },
                marginLeft: "10px",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default LostAndFoundModal;