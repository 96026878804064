import React, { useEffect, useRef, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import WaveSurfer from "wavesurfer.js";
import { Pause, Play } from "@phosphor-icons/react";
import "./Message.css";

const AudioPlayerWithVisualization = ({ audioFile,profile }) => {
  const waveformRef = useRef(null);
  const waveSurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (waveSurfer.current) {
      waveSurfer.current.destroy();
    }

    waveSurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#ddd",
      progressColor: "#24BDF0",
      barWidth: 1,
      barHeight: 13,
      height: 30,
      width: 120,
      responsive: true,
    });

    waveSurfer.current.load(audioFile);

    // Reset UI when audio finishes
    waveSurfer.current.on("finish", () => {
      setIsPlaying(false);
      setCurrentTime(0);
      waveSurfer.current.seekTo(0);
    });

    return () => waveSurfer.current.destroy();
  }, [audioFile]);

  const handlePlayPause = () => {
    if (isPlaying) {
      waveSurfer.current.pause();
    } else {
      waveSurfer.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        if (waveSurfer.current) {
          setCurrentTime(waveSurfer.current.getCurrentTime());
          setDuration(waveSurfer.current.getDuration());
        }
      }, 1000);
      
      return () => clearInterval(interval);
    }
  }, [isPlaying]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        backgroundColor: "#ffffff",
        padding: "10px",
        borderRadius: "5px",
      }}>
      <div
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          overflow: "hidden",
          paddingRight: "5px",
        }}>
        <img
          style={{ height: "30px", width: "30px", objectFit: "cover" }}
          src={profile}
          alt=""
        />
      </div>
      <div onClick={handlePlayPause}>
        {isPlaying ? (
          <Pause
            className={isPlaying ? "time-color" : "#24BDF0"}
            size={20}
            weight="fill"
          />
        ) : (
          <Play size={20} weight="fill" />
        )}
      </div>
      <div>
        <ReactAudioPlayer
          className={isPlaying ? "time-color" : "#24BDF0"}
          src={audioFile}
          // controls
          onPlay={() => waveSurfer.current.play()}
          onPause={() => waveSurfer.current.pause()}
          onLoadedMetadata={() => {
            if (waveSurfer.current) {
              setDuration(waveSurfer.current.getDuration());
            }
          }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <div ref={waveformRef} />
        <span
          className={isPlaying ? "time-color" : "#24BDF0"}
          style={{
            fontSize: "10px",
            position: "absolute",
            bottom: "-13px",
          }}>
          {formatTime(currentTime)}
        </span>
      </div>
    </div>
  );
};

export default AudioPlayerWithVisualization;
