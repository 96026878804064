// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VehicleOverviewCard .card h4 {
  font-weight: 400;
}
.VehicleOverviewCard .card .VehicleOverviewText .VehicleOverCardText {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.VehicleOverviewCard .card .VehicleOverviewText .VehicleOverCardText p {
  font-weight: 400;
}
.VehicleOverviewCard .card .VehicleOverviewText .VehicleOverCardText p:nth-child(1) {
  color: #2196f3;
  font-size: 1.1rem;
}
.VehicleOverviewCard .card .VehicleOverviewText .VehicleOverCardText p:nth-child(2) {
  color: #8b8b8b;
}`, "",{"version":3,"sources":["webpack://./src/Component/VehicleBoard/VehicleOverview/VehicleOverviewCard.scss"],"names":[],"mappings":"AAEI;EACE,gBAAA;AADN;AAKM;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;AAHR;AAKQ;EACE,gBAAA;AAHV;AAIU;EACE,cAAA;EACA,iBAAA;AAFZ;AAIU;EACE,cAAA;AAFZ","sourcesContent":[".VehicleOverviewCard {\n  .card {\n    h4 {\n      font-weight: 400;\n    }\n\n    .VehicleOverviewText {\n      .VehicleOverCardText {\n        display: flex;\n        margin-top: 10px;\n        align-items: center;\n\n        p {\n          font-weight: 400;\n          &:nth-child(1) {\n            color: #2196f3;\n            font-size: 1.1rem;\n          }\n          &:nth-child(2) {\n            color: #8b8b8b;\n          }\n        }\n\n        h6 {\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
