// expenseOption.js

export const expenseNames = [
  "School Fees",
  "Transport Fees",
  "Rent",
  "Credit Card EMI",
  "Housing Loan EMI",
  "Gas Bill",
  "Petrol",
  "Groceries",
  "Dining Out",
  "Internet",
  "Mobile Phone Bill",
  "Gym Membership",
  "Insurance",
  "Medical Expenses",
  "Entertainment",
  "Personal Care",
  "Clothing",
  "Travel Expenses",
  "Home Maintenance",
  "Subscriptions",
  "Loan Payments",
  "Childcare",
  "Pet Expenses",
  "Marriage Gifts",
  "Birthday Gifts",
  "Donation",
];

export const paymentMode = ["Liabilities", "Investment", "Expenses"];

export const typeOfNewLiable = [
  "Home Loan",
  "Personal Loan",
  "Home Mortgage",
  "Auto Loan",
  "Student Loan",
  "Credit Card",
  "Payday Loan",
  "Business Loan",
  "Secured Loan",
  "Unsecured Loan",
  "Car Loan",
  "Bike Loan",
  "Short-Term Fixed Loans",
  "12-Month Loans",
  "Salaries Payable",
  "Security Deposits",
  "Environmental Cleanup Costs",
  "Environmental Cleanup Obligations",
  "Regulatory Fines",
  "Software Subscriptions",
  "Revenue Recognition Differences",
  "Pension Obligation",
  "Legal Settlements",
  "Import Duties Payable",
  "Promissory Notes",
  "Miscellaneous Vendors",
  "Short-Term Borrowings",
  "Interest on Loans",
  "Interest on Bonds",
  "Income Taxes Payable",
  "Sales Taxes Payable",
  "Advance Payments from Customers",
  "Real Estate Loans",
  "Real Estate Mortgages",
  "Commercial Mortgages",
  "Lease Payments",
  "Pending Litigation",
  "Product Warranties",
  "Service Warranties",
  "Expense Recognition Differences",
  "Advance Payments for Long-Term Agreements",
  "Deferred Bonuses",
  "Deferred Salaries",
  "Deferred Income",
  "Deferred Expenses",
  "Reimbursements",
  "Coverage",
  "Policy",
  "Assurance",
  "Safeguard",
  "Indemnity Bond",
  "Beneficiary Coverage",
  "Risk Management",
  "Insurance Premiums",
  "Bank Loans",
  "Bonds Payable",
  "Long-Term Loans",
  "Mortgage Payable",
  "Lawsuits",
  "Warranty Obligations",
  "Long-Term Deferred Revenue",
  "Long-Term Deposits",
  "Operating Leases",
  "Finance Leases",
  "Accrued Interest",
  "Accrued Taxes",
  "Defined Benefit Plans",
  "Post-Retirement Benefits",
  "Education Loan",
  "Credit Card Bill Payment",
  "Insurance Renewal",
  "Travel Insurance",
  "Health Insurance",
  "Debt Repayments",
];

export const typeOfNewInvestment = [
  "Physical Cash",
  "Stocks",
  "Bonds",
  "Government Bonds",
  "Corporate Bonds",
  "Municipal Bonds",
  "Mutual Funds",
  "Exchange-Traded Funds (ETFs)",
  "Real Estate",
  "Commodities",
  "Cryptocurrencies",
  "Savings Accounts and CDs (Certificates of Deposit)",
  "Retirement Accounts",
  "Gold Purchase",
  "Gold Mutual Funds",
  "Joint Ventures",
  "Undeveloped Land",
  "Agricultural Land",
  "Land Held for Future Development",
  "Office Buildings",
  "Retail Spaces",
  "Industrial Properties",
  "Precious Metals Investments",
  "Publicly Traded Stocks",
  "Privately Held Stocks",
  "Growth Funds",
  "Value Funds",
  "Dividend Funds",
  "Energy Commodity ETFs",
  "Agricultural Commodity ETFs",
  "Physical Gold",
  "Physical Silver",
  "Physical Platinum",
  "Fixed Deposit",
  "Provident Fund (PF)",
  "National Pension System (NPS)",
  "Post Office Schemes",
  "Recurring Deposits",
  "National Savings Certificate (NSC)",
  "Forex Trading",
  "Systematic Investment Plans (SIPs)",
  "Infrastructure Investment Trusts (InvITs)",
  "Digital Gold",
  "Sovereign Wealth Funds",
  "Credit Risk Funds",
  "Crowdfunding",
  "Angel Funds",
  "Common Stock",
  "Preferred Stock",
  "Rental Properties",
  "Single-Family Homes",
  "Multi-Family Units",
  "Venture Capital",
  "Buyouts",
  "Growth Capital",
  "Agricultural Products",
  "Individual Retirement Account (IRA)",
  "Energy Products",
  "Equity Funds",
  "Hedge Funds",
  "Portfolio Management Service (PMS)",
  "Lumpsum Investing",
  "Index Fund",
];

export const typeOfNewExpense = [
  "Tuition Fees",
  "Books",
  "Supplies",
  "Uniforms",
  "Clothing",
  "Lunch",
  "Snacks",
  "Extra-Curricular Activities",
  "Medical",
  "Health Costs",
  "Technology",
  "Equipment",
  "Field Trips",
  "Events",
  "Food",
  "Groceries",
  "Transportation",
  "Healthcare",
  "Education",
  "Personal Care",
  "Leisure",
  "Communication",
  "Entertainment",
  "Travel",
  "Gifts",
  "Operational Expenses",
  "Administrative Expenses",
  "Marketing",
  "Advertising",
  "Transaction Fees",
  "Maintenance Costs",
  "Luxury Items",
  "Subscriptions",
  "Legal Fees",
  "Consulting Fees",
  "Audit Fees",
  "Salary",
  "Home Rent",
  "Utilities",
  "Office Supplies",
  "Warehouse Rent",
  "Software Subscriptions",
  "Professional Journals",
  "Miscellaneous Expenses",
  "Office Rent",
  "Retail Space Rent",
  "Electricity",
  "Water",
  "Gas",
  "Stationery",
  "Office Equipment",
  "Promotional Expenses",
  "Accommodation",
  "Meals",
  "Property Insurance",
  "Tax Preparation Fees",
  "Financial Statement Preparation",
  "Social Security Contributions",
  "Currency Conversion Fees",
  "Shipping Costs",
  "Charity",
  "Seasonal Expenses",
  "Professional Development",
  "Fitness",
  "Pet Care",
  "Retirement Planning",
  "Home Security",
  "Emergency Fund Contributions",
  "Cleaning Services",
  "Fashion",
  "Outfits",
  "Mobile Recharge",
  "OTT Subscription",
  "Raw Materials",
  "Direct Labour",
  "Manufacturing Overhead",
  "Fastag Recharge",
  "Broadband",
  "Postpaid Mobile Bill",
  "Provisions",
  "Edibles",
  "PG Room Rent",
  "Bus Fair",
  "Train Fair",
  "Metro Fair",
];

export const emiPeriod = [
  { month: 0, word: "Full payment" },
  { month: 1, word: "Monthly" },
  { month: 2, word: "Bi-Monthly" },
  { month: 4, word: "Quarterly" },
  { month: 6, word: "Half-Yearly" },
  { month: 12, word: "Annually" },
];
