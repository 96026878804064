import React,{useState,useEffect,useContext} from 'react'
import { Country, State, City } from 'country-state-city';
import { Dropdown } from 'primereact/dropdown';
import './Billing.css'
import { DataContext } from "../../Admin/DataContext";
import { useNavigate } from "react-router";
import Swal from 'sweetalert2';
function Location() {
    const [countryNames, setCountryNames] = useState([]);
    const [countryNameValue, setCountryNameValue] = useState('');
    const [stateValues, setStateValues] = useState([]);
    const [stateNameValue, setStateNameValue] = useState('');
    const [cityValues, setCityValues] = useState([]);
    const [cityNameValues, setCityNameValues] = useState('');

    const {
        setInsidestate,
        setouterstate,
        setcountry_check,
        country_check,
        setunionterritory,
        setusercountry
      } = useContext(DataContext);

      const navigate = useNavigate()

  useEffect(()=>{
    getlocation();
  },[])

 

    const getlocation = () => {
        const countries = Country.getAllCountries();
        const countryNames = countries.map((country) => ({ name: country.name, isoCode: country.isoCode }));
        setCountryNames(countryNames);
       
      }
    
      const handleCountryDropdown = (e) => {
        const countryName = e.target.value;
       
         const val = countryName.name
         setusercountry(val)
        setCountryNameValue(countryName);
    
        const selectedCountry = countryNames.find(country => country.name === val);
        if (selectedCountry) {
          const states = State.getStatesOfCountry(selectedCountry.isoCode);
          setStateValues(states);
          setStateNameValue(''); 
          setCityValues([]); 
          if(selectedCountry.name === 'India' ){
            setcountry_check(true)
          }else{
            setcountry_check(false)
          }
        } else {
          setStateValues([]);
          setCityValues([]);
        }
      }
    
      const handleStateDropdown = (e) => {
        const stateName = e.target.value;
      
        const val = stateName.name
        setStateNameValue(stateName);
    
        const selectedState = stateValues.find(state => state.name === val);
       
        if (selectedState) {
          const cities = City.getCitiesOfState(selectedState.countryCode, selectedState.isoCode);
          setCityValues(cities);
         
        } else {
          setCityValues([]);
        }
      }
    
    
      const handleCityDropdown = (e) => {
        const cityname = e.target.value
        setCityNameValues(cityname)
      }

      const handlesubmit = (statename)=>{


        if(countryNameValue === ''){
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Select the Country Name',
            showConfirmButton: false,
            timer: 2000
          });
          return
        }
        if(stateNameValue === ''){
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Select the State ',
            showConfirmButton: false,
            timer: 2000
          });
          return
        }
        if(cityNameValues === ''){
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Select the City',
            showConfirmButton: false,
            timer: 2000
          });
          return
        }

      

     
        const datavalue = statename.name
        if(country_check){
          if(datavalue === 'Tamil Nadu'){
              setInsidestate(true)
              console.log('inerrstae');
          }else if(datavalue === 'Andaman and Nicobar Islands') {
              setunionterritory(true)
              console.log('outer');
          }else if(datavalue === 'Chandigarh'){
            setunionterritory(true)
          }else if(datavalue === 'Dadra and Nagar Haveli and Daman and Diu'){
            setunionterritory(true)
          }else if(datavalue === 'Lakshadweep'){
            setunionterritory(true)
          }else if(datavalue === 'Ladakh'){
            setunionterritory(true)
          }else if(datavalue === 'Delhi'){
            setInsidestate(true)
          }else if(datavalue === 'Puducherry'){
            setInsidestate(true)
          }else if(datavalue === 'Jammu and Kashmir'){
            setInsidestate(true)
          }else{
            setouterstate(true)
          }

        
        }
         navigate('/main/Subcription')
      }

  return (
   
       <div className="location-card">
      <h2 className="location-title" style={{color:"#002F34"}}>Select the Location</h2>
      <Dropdown 
        onChange={handleCountryDropdown} 
        options={countryNames} 
        value={countryNameValue} 
        optionLabel="name"
        placeholder="Select a Country" 
        className="dropdown w-full"
      />
      <Dropdown 
        onChange={handleStateDropdown} 
        options={stateValues} 
        value={stateNameValue} 
        optionLabel="name"
        placeholder="Select a State" 
        className="dropdown w-full"
      />
      <Dropdown 
        onChange={handleCityDropdown} 
        options={cityValues} 
        value={cityNameValues} 
        optionLabel="name"
        placeholder="Select a City" 
        className="dropdown w-full"
      />

<button className="submit-button location-submit mt-2" onClick={()=>handlesubmit(stateNameValue)} >Continue</button>

    </div>


   
  )
}

export default Location