import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import {
  UpdateDetails,
  AddDetails,
  FetchDetails,
} from "../../routes/profileRoutes";
import Jobusestates from "../../useStates/JobUsestate";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import { style } from "../../Styles/Jobformstyle";
import { useMediaQuery } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdModeEdit } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import { Menu } from "primereact/menu";
import "./Relationship.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import Swal from 'sweetalert2';
import { Calendar } from 'primereact/calendar';
import moment from "moment/moment";
import './Relationship.css'
const HomeApplianceFormPage = () => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const [Home, setHome] = React.useState(false);
  const handleHomeOpen = () => setHome(true);
  const handleHomeClose = () => setHome(false);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [dateofpurchase,setdateofpurchase] = useState('')
  const [startperiod,setstartperiod] = useState('');
  const [endperiod,setendperiod] = useState('')
  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false); //to handle multiple form submission 

  const authdata = useSelector((state) => state.auth.user);
  const toast = useRef(null);

  const {
    HomeApllianceDetailsForm,
    setHomeApllianceDetailsForm,
    HomeApplianceDetails,
    setHomeApplianceDetails,
    UpdateButton,
    setUpdateButton,
  } = Jobusestates();


  const handleaddHomeDetails = (e) => {
    e.preventDefault();
    setUpdateButton("Add");
    setHomeApllianceDetailsForm("");
    setdateofpurchase("")
    setstartperiod("")
    setendperiod("")
  };

  useEffect(() => {
    fetchHomeAppliance();
  }, []);

  const fetchHomeAppliance = async () => {
    const fetchHomeDetails = await axios.post(`${Nodeapi}/FetchLoopDetails`, {
      id: authdata?.id,
      table: "HomeApplianceDetails",
    });
    if (fetchHomeDetails) {
      setHomeApplianceDetails(fetchHomeDetails.data.data.response);
    }
  };


  const handleHomeApplianceDetails = (e) => {
    const { name, value } = e.target;
    setHomeApllianceDetailsForm({
      ...HomeApllianceDetailsForm,
      [name]: value,
      userid: authdata?.id,
    });
  };


  const HandleHomeApplianceDetails = async (e) => {
    e.preventDefault();

    if(isSubmitting) return;  //Prevent multiple form submissions
    setIsSubmitting(true);

    const action = e.nativeEvent.submitter.value;

    if (action.trim() === "update") {
      const json_data = {
        formdata: HomeApllianceDetailsForm,
        table: "HomeApplianceDetails",
        id: HomeApllianceDetailsForm.id,
        userid: HomeApllianceDetailsForm.userid,
        message: "Home Appliance Updated Successfully...!",
      };

      try {
        const updateDetailsResult = await axios.post(
          `${Nodeapi}/updateHAD`,
          json_data
        );

        if (updateDetailsResult) {
          setHome(false);

          setHomeApplianceDetails(updateDetailsResult.data.data.response);

          Swal.fire({
            position: "center",
             icon: "success",
             title: 'Updated Successfully',
             showConfirmButton: false,
             timer: 2000
           });
        } else {
         
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Error 404',
            showConfirmButton: false,
            timer: 2000
          });
          setHome(false);
        }
      } catch (error) {
        console.error("Error updating details:", error);
      }
    } else {


      if(!HomeApllianceDetailsForm){
        Swal.fire({
          position: "center",
          icon: "error",
          title: 'Please Fill Out All Required Fields',
          showConfirmButton: false,
          timer: 2000
        });
        return setHome(false);
      }


      const json_data = {
        insertdata: HomeApllianceDetailsForm,
        table: "HomeApplianceDetails",
        insertMessage: "Home ApplianceDetails Details Inserted Successfully",
      };

      try {
        const addDetailsResult = await AddDetails(json_data);

        if (addDetailsResult) {
          setHome(false);

          setHomeApplianceDetails(addDetailsResult.data.data.response);
          Swal.fire({
            position: "center",
            icon: "success",
            title: 'Home ApplianceDetails Details Inserted Successfully',
            showConfirmButton: false,
            timer: 2000
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Error 404',
            showConfirmButton: false,
            timer: 2000
          });
          setHome(false);
        }
      } catch (error) {
        console.error("Error adding details:", error);
      }
    }
    setIsSubmitting(false)
  };

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelectedJobDetail(rowData);
      menuLeft.current.toggle(event);
    };
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };
   
    
  useEffect(() => {
    if(HomeApplianceDetails){
      setSerialNumbers(
        Array.from({ length: HomeApplianceDetails.length }, (_, index) => index + 1)
      );
    }
  }, [HomeApplianceDetails]);
  

  const menuLeft = useRef(null);
  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selectedJobDetail),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selectedJobDetail),
    },
  ];

  const handleEdit = (rowData) => {
    
    handleHomeOpen();
    const value1 = rowData.date_of_purchase
    let startYear = moment(value1, 'DD-MM-YYYY').toDate()
    const startperiod = rowData.start_period
    let startingperiod = moment(startperiod, 'DD-MM-YYYY').toDate()
    const endperiod = rowData.end_period
    let endingperiod = moment(endperiod, 'DD-MM-YYYY').toDate()
    setdateofpurchase(startYear)
    setstartperiod(startingperiod)
    setendperiod(endingperiod)
    setHomeApllianceDetailsForm(rowData);
    setUpdateButton("update");
  };

  const deleteContactData = async (id) => {
    try {
      await axios.delete(`${Nodeapi}//deleteHomeApplianceForm/${id}`);
      fetchHomeAppliance();
     
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
  };

  

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
   
    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData.id);
        Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success'
        });
      
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  }
  const handleDateChange = (event)=>{
    const newDob = event.value;
    const formattedDate = moment(newDob).format('DD-MM-YYYY'); 
    setstartperiod(event.value)
   setHomeApllianceDetailsForm({
      ...HomeApllianceDetailsForm,
      date_of_purchase: formattedDate,
      start_period: formattedDate,
    });
   }
  
   const handlestartperiod = (event)=>{
    setstartperiod(event.value)
    const newDob = event.value;
    const formattedDate = moment(newDob).format('DD-MM-YYYY'); 

      setHomeApllianceDetailsForm({
      ...HomeApllianceDetailsForm,
      start_period: formattedDate, 
    });
   }

   const handleendperiod = (event)=>{
    setendperiod(event.value)
    const newDob = event.value;
    const formattedDate = moment(newDob).format('DD-MM-YYYY'); 
  
     setHomeApllianceDetailsForm({
      ...HomeApllianceDetailsForm,
      end_period: formattedDate, 
    });
   }

   const minRelievingDate = startperiod ? new Date(startperiod.getTime()) : null;

  return (
    <div>
      <Toast ref={toast} />
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Electrical and Electronics Gadget</h5>
          <p>
            <Button
              onClick={(e) => {
                handleHomeOpen();
                handleaddHomeDetails(e);
              }}
            >
              <MdAdd size={22} />
            </Button>
            <Modal
              open={Home}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              onClose={handleHomeClose}
            >
              <Box
                sx={style}
                style={{
                  width: isSmallScreen ? "95%" : "",
                }}
                className="scrollBarHidden"
              >
                <h6>Electrical and Electronics Gadget</h6>
                <form onSubmit={HandleHomeApplianceDetails}>
                  <div className="row">
                    <div className="col-md-6 my-2">
                      <label htmlFor="gadgetBrand" className="pb-2 labels">
                        Appliance Type
                      </label>
                      <input
                        placeholder="Appliance Type"
                        className="form-control"
                        type="text"
                        id="Appliance_type"
                        name="Appliance_type"
                        value={HomeApllianceDetailsForm?.Appliance_type}
                        onChange={handleHomeApplianceDetails}
                      />
                    </div>

                    <div className="col-md-6 my-2">
                      <label htmlFor="gadgetBrand" className="pb-2 labels">
                        Brand
                      </label>
                      <input
                        placeholder="Brand"
                        className="form-control"
                        type="text"
                        id="brand"
                        name="brand"
                        onChange={handleHomeApplianceDetails}
                        value={HomeApllianceDetailsForm?.brand}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="model" className="pb-2 labels">
                        Model
                      </label>
                      <input
                        placeholder="Model"
                        className="form-control"
                        type="text"
                        id="model"
                        name="model"
                        onChange={handleHomeApplianceDetails}
                        value={HomeApllianceDetailsForm?.model}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="purchaseDate" className="pb-2 labels">
                        Date of Purchase
                      </label>
                    
                   < Calendar
                   id="date_of_purchase"
                    className="w-full input2"
                    showIcon={false} 
                    name="date_of_purchase"
                    monthNavigator
                    placeholder="Date of Purchase"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={dateofpurchase}
                    onChange={handleDateChange}
                    style={{ width: '88%',height:"42px" , border:"none" }} // Set the width to 300px
                  />
                     
                    </div>
                    <label> Warranty Period:</label>
                    <div className="col-md-6 my-2">
                      <label htmlFor="model" className="pb-2 labels">
                        Warranty Provider Name
                      </label>
                      <input
                        placeholder="Warranty Provider Name"
                        className="form-control"
                        type="text"
                        id="model"
                        name="warrantyprovider"
                        onChange={handleHomeApplianceDetails}
                        value={HomeApllianceDetailsForm?.warrantyprovider}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="model" className="pb-2 labels">
                        Service Provider Name
                      </label>
                      <input
                        placeholder="Service Provider Name"
                        className="form-control"
                        type="text"
                        id="model"
                        name="serviceprovider"
                        onChange={handleHomeApplianceDetails}
                        value={HomeApllianceDetailsForm?.serviceprovider}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="model" className="pb-2 labels">
                        Sold By
                      </label>
                      <input
                        placeholder="Sold By"
                        className="form-control"
                        type="text"
                        id="model"
                        name="soldby"
                        onChange={handleHomeApplianceDetails}
                        value={HomeApllianceDetailsForm?.soldby}
                      />
                    </div>
                    <div className="col-md-6 my-2"></div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="warrantyPeriod" className="pb-2 labels">
                        Start Period
                      </label>

                  
                   < Calendar
                      id="start_period"
                    className="w-full input2"
                    showIcon={false} 
                    name="start_period"
                    monthNavigator
                    placeholder="Start Period"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={startperiod}
                    onChange={handlestartperiod}
                    style={{ width: '88%',height:"42px" , borderTopRightRadius:"4px" ,borderBottomRightRadius:"4px" , border:"none" }} // Set the width to 300px
                  />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="warrantyPeriod" className="pb-2 labels">
                        End Period
                      </label>
                     
                 < Calendar
                    id="end_period"
                    className="w-full input2"
                    showIcon={false} 
                    name="end_period"
                    monthNavigator
                    placeholder="End Period"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={endperiod}
                    onChange={handleendperiod}
                    minDate={minRelievingDate}
                    style={{ width: '88%',height:"42px" , borderTopRightRadius:"4px" ,borderBottomRightRadius:"4px" , border:"none" }} // Set the width to 300px
                  />

                    </div>
                    <div className="col-md-12 my-2">
                      <label htmlFor="agencyOrCompany" className="pb-2 labels">
                        Description
                      </label>
                      <textarea
                        placeholder="Description"
                        className="form-control"
                        type="text"
                        id="description"
                        name="description"
                        onChange={handleHomeApplianceDetails}
                        value={HomeApllianceDetailsForm?.description}
                      />
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <button
                      type="button"
                      className="btn btn-danger me-2"
                      onClick={handleHomeClose}
                    >
                      Cancel
                    </button>

                    {UpdateButton == "Add" ? (
                      <button
                        type="submit"
                        value="add"
                        className="btn btn-primary"
                         disabled={isSubmitting}
                      >
                        {isSubmitting?"Submitting":"Submit"}
                      
                      </button>
                    ) : (
                      <button
                        type="submit"
                        value="update"
                        className="btn btn-primary"
                         disabled={isSubmitting}
                      >
                        {isSubmitting?"Submitting":"Update"}
                      </button>
                    )}
                  </div>
                </form>
              </Box>
            </Modal>
          </p>
        </div>

        <div className="expand" style={{marginTop:"7px"}}>
          <DataTable
            value={HomeApplianceDetails}
            tableStyle={{ minWidth: "50rem" }}
          >
             
          <Column
            header="S.No"
            body={(rowData) => <span>{HomeApplianceDetails.indexOf(rowData) + 1}</span>}
          ></Column>
          
            {/* <Column style={{ minWidth: "" }} field="id" header="ID"></Column> */}
            <Column
              style={{ minWidth: "130px" }}
              field="Appliance_type"
              header="Appliance Type"
            ></Column>
            <Column
              style={{ minWidth: "100px" }}
              field="brand"
              header="Brand"
            ></Column>
            <Column
              style={{ minWidth: "100px" }}
              field="model"
              header="Model"
            ></Column>
             <Column
              style={{ minWidth: "150px" }}
              field="date_of_purchase"
              header="Date Of Purchase"
            ></Column>
            <Column
              style={{ minWidth: "130px" }}
              field="warrantyprovider"
              header="Warranty Name"
            ></Column>
            <Column
              style={{ minWidth: "130px" }}
              field="serviceprovider"
              header="Service Name"
            ></Column>
            <Column
              style={{ minWidth: "100px" }}
              field="soldby"
              header="Sold By"
            ></Column>
            <Column
              style={{ minWidth: "130px" }}
              field="start_period"
              header="Start Period"
            ></Column>
            <Column
              style={{ minWidth: "100px" }}
              field="end_period"
              header="End Period"
            ></Column>
            <Column
              style={{ minWidth: "300px" }}
              field="description"
              header="Description"
            ></Column>
            <Column
              style={{ minWidth: "100px" }}
              body={(rowData) => actions(rowData)}
              header="Actions"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default HomeApplianceFormPage;
