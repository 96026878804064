import React, { useEffect, useState } from 'react'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from 'axios';
import { Nodeapi } from '../config/serverUrl';
import './Admin.css'
function Aadhaar_details() {
    const [details,setdetails] = useState([])
   
    useEffect(()=>{
        fetachdata()
    },[])

    const fetachdata = async ()=>{
        const res = await axios.get(`${Nodeapi}/getaadhaardetails`)
        const data = res.data.result
        setdetails(data)
    }

  return (
    
        <div style={{ marginTop: "-28px" }}>
        <div className="aadhardetails">
        <div className="grid">
            <div className="col-9 flex justify-content-between" >
            <h2 className="my-3" style={{ color: "#2196F3" }}>Aadhaar Details</h2>
            
            </div>
            <div className="col-2">

            </div>
        </div>
        {details.length > 0 && (
            <DataTable
            value={details}
            paginator
            rows={10}
            tableStyle={{ minWidth: "50rem" }}
            className="data"
        >
            <Column
            header="S.No"
            className="font-bold"
            body={(rowData) => <span>{details.indexOf(rowData) + 1}</span>}

            />
            <Column field="Name" header="User Name" className="font-bold" />
            <Column field="Aadhaar_No" header="Aadhaar No" className="font-bold" />
            <Column field="DOB" header="Date of Birth"   style={{ minWidth: "137px" }} />
            <Column field="Gender" header="Gender" />
            <Column field="care_of" header="Care Of" style={{ minWidth: "137px" }} />
            <Column field="house" header="House No" style={{ minWidth: "137px" }}/>
            <Column field="street" header="Street" />
            <Column field="Pincode" header="Pincode" />
            <Column field="po" header="Post Office" style={{ minWidth: "137px" }} />
            <Column field="District" header="District" />
            <Column field="subdist" header="SubDistrict" />
            <Column field="vtc" header="VTC" />
            <Column field="State" header="State" />
            <Column field="Country" header="Country" />
            {/* <Column body={actions} header="Actions"></Column> */}
            {/* <Column field="Dob" header="Date of Birth" /> */}
        </DataTable>
        )}






        </div>
        </div>
  )
}

export default Aadhaar_details