import React from "react";
// import AdDashboardNav from "./AdDashboardNav";
import AdDashboardContent from "./AdDashboardContent";

const AdDashboard = () => {
  return (
    <div>
      {/* <AdDashboardNav /> */}
      <AdDashboardContent />
    </div>
  );
};

export default AdDashboard;
