import { Grid } from "@mui/material";
import React, { useState, useContext, useEffect, useRef } from "react";
import { DataContext } from "../../../Admin/DataContext";
import { CiLocationOn } from "react-icons/ci";
import { IoMdHeart } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import { Assetsapi, Nodeapi } from '../../../config/serverUrl'
import axios from 'axios'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from "react-router-dom";
import { IoIosHeartEmpty } from "react-icons/io";
import { useSelector } from 'react-redux';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import './VehicleSimilarCard.scss'

const VehicleSimilarCard = () => {

  const {
    view_adpost,
    displaypost,
    category,
    setdatavalue,
    fetchdata,
    setaddfavorites,
    addfavorites,

  } = useContext(DataContext);

  const authdata = useSelector((state) => state.auth.user);
  const Navigate = useNavigate()
  const { id } = useParams();
  const [selectfavorite, setselectfavorite] = useState([])
  const [Similarposts, setSimilarposts] = useState([])
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectedIndices1, setSelectedIndices1] = useState([]);
  const [simalradsfavorites, setsimalradsfavorites] = useState(false)
  const scrollRef = useRef(null);

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const theme = useTheme();

  useEffect(() => {
    const fetchdata = async () => {
      const postiddata = localStorage.getItem('Postiddata');
      try {
        const res = await axios.get(`${Nodeapi}/getdisplaypost`);
        const data = res.data.getdata;
        const finaldata = data.filter(item => item.post_id !== postiddata);
        setSimilarposts(finaldata);
        const newAddfavorites = [...addfavorites];
        const newSelectedIndices1 = [];
        const newSelectfavorite = [];
        finaldata.forEach((post, index) => {
          if (post.Save_data === 1) {
            newAddfavorites[index] = true;
            newSelectedIndices1.push(index);
            newSelectfavorite.push(post);
          }
        });
        setSelectedIndices1(newSelectedIndices1);
        setselectfavorite(newSelectfavorite);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchdata();
  }, []);


  const [heart, setHeart] = useState(false);

  const handleHeartClick = () => {
    setHeart(!heart);
  };

  const handleBookmarkClick = async (index) => {
    const newBookmarkStates = [...simalradsfavorites];
    newBookmarkStates[index] = !newBookmarkStates[index];
    setsimalradsfavorites(newBookmarkStates);

    if (newBookmarkStates[index]) {

      await handlelike(index);
      setSelectedIndices1([...selectedIndices1, index]);
      setselectfavorite([...selectfavorite, Similarposts[index]]);
    } else {
      await handleunave(index);
      console.log('Unsavepost');
      const updatedIndices = selectedIndices1.filter((i) => i !== index);
      const updatedData = selectfavorite.filter((data) => data !== Similarposts[index]);
      setSelectedIndices1(updatedIndices);
      setselectfavorite(updatedData);
    }
  };

  const handlelike = async (data) => {
    const val = Similarposts[data]

    const today = new Date().toISOString().split('T')[0];

    try {
      const res = await axios.post(`${Nodeapi}/Savethepost`, {
        id: val.id,
        date: today,
        kid_id: authdata.userid,
        image_id: val.post_id
      })
      const data = res.data
    } catch (error) {
      console.error('error:', error);
    }

  }

  const handleunave = async (data) => {

    const val = Similarposts[data]

    try {
      const res = await axios.post(`${Nodeapi}/Unsavethepost`, {
        id: val.id,
        kid_id: authdata.userid,
      })
      const data = res.data
    } catch (error) {
      console.log('error:', error);
    }
  }


  const handleimage = async (data, id) => {
    const val = Similarposts[data]

    const category = val.post_category
    const postid = val.post_id

    localStorage.setItem('Category', category);
    localStorage.setItem('Postiddata', postid);
    localStorage.setItem('PostID', val.id);
    window.scrollTo(0, 0);
    Navigate(`/main/VehicleOverview/${id}`)
    setdatavalue(val)
    fetchdata()
  }
  const formatPrice = (price) => {
    const number = Number(price);
    const priceStr = number.toString();

    if (priceStr.length > 7) {
      return priceStr.slice(0, 7) + "...";
    }
    return priceStr;
  };
  const truncateText = (text, maxLength = 15) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const handleScroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: direction === 'left' ? -200 : 200,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="VehicleSimilarCard mb-4" style={{ padding: "0px 20px" }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h4 className="mb-3 mt-5">Similar Ads</h4>
        <div>
          <button onClick={() => handleScroll('left')} style={{ border: "none", background: "none" }} >
            <MdKeyboardDoubleArrowLeft style={{ fontSize: "22px", marginTop: "-8%" }} />
          </button>
          <button onClick={() => handleScroll('right')} style={{ border: "none", background: "none" }} >
            <MdKeyboardDoubleArrowRight style={{ fontSize: "22px", marginTop: "-8%" }} />
          </button>
        </div>
      </div>
      <div className="ad-content_VSC" ref={scrollRef} style={{ display: 'flex', overflowX: 'scroll', scrollBehavior: 'smooth', }}>
          {Similarposts.map((listing, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <div className="ad-card-listing" style={{ margin: "0 15px", minWidth: '250px' }}>
                <div className="ad-card-heart">
                  <div style={{ backgroundColor: "#E6F2FC", padding: "15px", borderRadius: "10px 10px 0 0", }}>
                    <AutoPlaySwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      enableMouseEvents
                      onClick={() => { handleimage(index, listing.id.toString()); }}
                      style={{ cursor: "pointer" }}
                    >
                      {listing.images.map((step, index) => (
                        <div key={step.label}>
                          {Math.abs(activeStep - index) <= 10 ? (
                            <Box
                              component="img"
                              sx={{
                                height: 180,
                                display: 'block',
                                maxWidth: 400,
                                overflow: 'hidden',
                                width: '100%',
                                borderRadius: 2
                              }}
                              src={`${Assetsapi}/adimages/${step}`}
                              alt={`House ${index + 1}`}

                            />
                          ) : null}
                        </div>
                      ))}
                    </AutoPlaySwipeableViews>

                  </div>
                  <button className="btn-heart" onClick={() => { handleBookmarkClick(index) }}>
                    {simalradsfavorites[index] ? (
                      <IoMdHeart style={{ color: "#FF3B3C" }} checked={simalradsfavorites[index]} />
                    ) : (
                      <IoIosHeartEmpty style={{ color: "#BBB9B9" }} checked={simalradsfavorites[index]} />
                    )}
                  </button>
                </div>
                <div style={{ padding: "12px", cursor: "pointer" }} onClick={() => { handleimage(index, listing.id.toString()); }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="cates">
                      Category:
                      <span className="card-tit ms-1">{truncateText(listing.post_category)}</span>
                    </p>
                    <button className="btn-buy">{listing.action}</button>
                  </div>
                  <p className="title" style={{ marginTop: "8px" }}>
                    {truncateText(listing.post_title)}
                  </p>
                  <p
                    className="card-desp"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {listing.post_description}
                  </p>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      borderBottom: "1px solid #E6E6E6",
                      paddingBottom: "12px",
                      marginTop: "16px",
                    }}
                  >
                    <p className="card-price">₹{formatPrice(listing.post_price)}</p>

                    {/* <button className="btn-view">View Details</button> */}
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-1">
                    <p className="botm-text">
                      <CiLocationOn style={{ color: "#8f8f8f" }} />
                      <span className="icons ms-1">{truncateText(listing.post_location)}</span>
                    </p>
                    {listing.created_at && (
                      <p className="botm-text">
                        <IoCalendarOutline style={{ color: "#8f8f8f" }} />
                        <span className="icons ms-1">
                          {(() => {
                            const date = new Date(listing.created_at);
                            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
                            return formattedDate;
                          })()}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
      </div>
    </div>
  );
};

export default VehicleSimilarCard;
