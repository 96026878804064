import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "@mui/material";
import { ProductService } from "../../Data/ProductService";
import '../AddEducationDetails/School.css'
import "./Relationship.css";
import { BsThreeDotsVertical } from "react-icons/bs";

function SchoolFormAdd({ setSchoolData }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    ProductService.getProductsMini().then((data) => setProducts(data));
  }, []);

  const handleSpecializationChange = (value, field, rowData) => {
    const updatedProducts = products.map((product) =>
      product.id === rowData.id ? { ...product, [field]: value } : product
    );
    setProducts(updatedProducts);
    setSchoolData(updatedProducts);
  };

  const handleDelete = (rowId) => {
    const updatedProducts = products.filter((product) => product.id !== rowId);
    setProducts(updatedProducts);
  };

  const handleButtonClick = () => {
    const newProduct = {
      id: Date.now(),
      schoolSpecialization: "",
      schoolqualification: "",
      schoolselection: "",
      schoolstart: "",
      schoolend: "",
      schoolname: "",
      schoolpercentage: "",
    };
    setProducts([...products, newProduct]);
  };

  const schoolSpecialization = (rowData) => {
    return (
      <div className="table-input">
        <InputText
          type="text"
          value={rowData.schoolSpecialization}
          onChange={(e) =>
            handleSpecializationChange(
              e.target.value,
              "schoolSpecialization",
              rowData
            )
          }
        />
      </div>
    );
  };
  const schoolqualification = (rowData) => {
    return (
      <div className="table-input">
        <InputText
          type="text"
          value={rowData.schoolqualification}
          onChange={(e) =>
            handleSpecializationChange(
              e.target.value,
              "schoolqualification",
              rowData
            )
          }
        />
      </div>
    );
  };


  const schoolstart = (rowData) => {
    return (
      <div className="table-input">
        <InputText
          type="text"
          value={rowData.schoolstart}
          onChange={(e) =>
            handleSpecializationChange(e.target.value, "schoolstart", rowData)
          }
        />
      </div>
    );
  };

  const schoolend = (rowData) => {
    return (
      <div className="table-input">
        <InputText
          type="text"
          value={rowData.schoolend}
          onChange={(e) =>
            handleSpecializationChange(e.target.value, "schoolend", rowData)
          }
        />
      </div>
    );
  };

  const schoolname = (rowData) => {
    return (
      <div className="table-input">
        <InputText
          type="text"
          value={rowData.schoolname}
          onChange={(e) =>
            handleSpecializationChange(e.target.value, "schoolname", rowData)
          }
        />
      </div>
    );
  };

  const schoolselection = (rowData) => {
    return (
      <div className="table-input">
        <InputText
          type="text"
          value={rowData.schoolselection}
          onChange={(e) =>
            handleSpecializationChange(
              e.target.value,
              "schoolselection",
              rowData
            )
          }
        />
      </div>
    );
  };

  const schoolpercentage = (rowData) => {
    return (
      <div className="table-input">
        <InputText
          type="text"
          value={rowData.schoolpercentage}
          onChange={(e) =>
            handleSpecializationChange(
              e.target.value,
              "schoolpercentage",
              rowData
            )
          }
        />
      </div>
    );
  };

  return (
    <div style={{ marginTop: "20px" }} className="schoolform">
      <DataTable value={products} tableStyle={{ minWidth: "50rem" }}>
        <Column
          body={schoolSpecialization}
          field="schoolSpecialization"
          style={{
            width: "15%",
            fontSize: "14px",
            textAlign: "center",
            padding: "10px 20px",
          }}
          header="School Specialization"
        />
        <Column
          body={schoolqualification}
          style={{
            width: "15%",
            fontSize: "14px",
            textAlign: "center",
            padding: "10px 7px",
          }}
          header="School Qualification"
        />
        <Column
          body={schoolstart}
          style={{
            width: "15%",
            fontSize: "14px",
            textAlign: "center",
            padding: "10px 7px",
          }}
          header="School Start"
        />
        <Column
          body={schoolend}
          style={{
            width: "15%",
            fontSize: "14px",
            textAlign: "center",
            padding: "10px 7px",
          }}
          header="School End"
        />
        <Column
          body={schoolname}
          style={{
            width: "15%",
            fontSize: "14px",
            textAlign: "center",
            padding: "10px 7px",
          }}
          header="School Name"
        />
        <Column
          body={schoolselection}
          style={{
            width: "15%",
            fontSize: "14px",
            textAlign: "center",
            padding: "10px 7px",
          }}
          header="School Section"
        />
        <Column
          body={schoolpercentage}
          style={{
            width: "15%",
            fontSize: "14px",
            textAlign: "center",
            padding: "10px 7px",
          }}
          header="Percentage"
        />
        {/* Add other columns as needed */}
        <Column
          header={
            <Button onClick={handleButtonClick} className="p-0 m-0">
              <i
                className="fi fi-sr-square-plus"
                style={{ fontSize: "32px" }}
              />
            </Button>
          }
          body={(rowData) => (
            <Button
              onClick={() => handleDelete(rowData.id)}
              className="p-0 m-0"
            >
              <i
                className="fi fi-rr-trash"
                style={{
                  color: "red",
                  background: "#ffc0c0",
                  fontSize: "21px",
                  padding: "4px 10px 0px 10px",
                  borderRadius: "14%",
                }}
              />
            </Button>
          )}
        />
      </DataTable>
    </div>
  );
}

export default SchoolFormAdd;
