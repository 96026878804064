import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { ASSETS_API, CINE_JOB_API } from '../../../../config/api';
import { Box, Card, Divider, Typography, Tabs, Tab, useTheme, useMediaQuery, Button, IconButton } from '@mui/material';
import moment from 'moment';
import { CiLocationOn, CiShare2 } from 'react-icons/ci';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdContentPasteSearch, MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { LiaUserCheckSolid } from 'react-icons/lia';
import { RiShareBoxLine } from 'react-icons/ri';
import { GiSandsOfTime, GiDiscussion } from 'react-icons/gi';
import CineJobUserCard from './CineJobUserCard';
import { useSelector } from 'react-redux';
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { RiMenuFill } from "react-icons/ri";
const CineJobOverview = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('xl'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [job, setJob] = useState({});
  const [sharing, setSharing] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [statusCounts, setStatusCounts] = useState({
    applied: 0,
    shortlisted: 0,
    onHold: 0,
    interviewed: 0,
    hire: 0,
  });

  const [usersByStatus, setUsersByStatus] = useState({
    applied: [],
    shortlisted: [],
    onHold: [],
    interviewed: [],
    hire: [],
  });
  const userId = useSelector((state) => state?.auth?.user)?.id;
  const [jobList, setJobList] = useState([]);

  const fetchJobDetails = async () => {
    try {
      const { data } = await axios.get(CINE_JOB_API.GET_CINE_JOB_DETAIL(jobId));
      setJob(data[0]);
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };
  const fetchALLJobPost = async () => {
    try {
      const { data } = await axios.get(CINE_JOB_API.GET_USER_ID_CINE_JOB(userId));
      setJobList(data);
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };

  useEffect(() => {
    fetchJobDetails();
    fetchALLJobPost()
  }, [jobId]);

  useEffect(() => {
    if (job?.usersApplied) {
      const latestStatuses = new Map();

      job.usersApplied.forEach(item => {
        const userId = item.user.id;
        item.userStatus.forEach(userStatus => {
          const { status, statusDate } = userStatus;
          const date = moment(statusDate.split("Z")[0]);

          if (!latestStatuses.has(userId) || date.isAfter(moment(latestStatuses.get(userId).statusDate.split("Z")[0]))) {
            latestStatuses.set(userId, {
              ...userStatus,
              user: item.user,
              ...item,
            });
          }
        });
      });

      const latestStatusArray = Array.from(latestStatuses.values());

      const updatedStatusCounts = {
        applied: latestStatusArray.filter(user => user.status === 'applied'),
        shortlisted: latestStatusArray.filter(user => user.status === 'shortlisted'),
        onHold: latestStatusArray.filter(user => user.status === 'onHold'),
        interviewed: latestStatusArray.filter(user => user.status === 'interviewed'),
        hire: latestStatusArray.filter(user => user.status === 'hire'),
      };

      setUsersByStatus(updatedStatusCounts);
      setStatusCounts({
        applied: updatedStatusCounts.applied.length,
        shortlisted: updatedStatusCounts.shortlisted.length,
        onHold: updatedStatusCounts.onHold.length,
        interviewed: updatedStatusCounts.interviewed.length,
        hire: updatedStatusCounts.hire.length,
      });
    }
  }, [job]);

  const handleShare = async (url) => {
    if (navigator.share && !sharing) {
      setSharing(true);
      try {
        await navigator.share({
          title: 'Check out this job posting!',
          url: url,
        });
      } catch (error) {
        console.error('Error sharing the job:', error);
      } finally {
        setSharing(false);
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const jobDetail = job?.jobDetail || {};
  const companyDetail = job?.companyDetail || {};
  const createdAt = job?.createdAt || '';

  const workType = jobDetail?.workType?.[0] || 'Not specified';
  const salary = jobDetail?.minSalary && jobDetail?.maxSalary
    ? `${jobDetail.minSalary} - ${jobDetail.maxSalary} INR`
    : 'Not specified';
  const jobUrl = `${window.location.origin}/main/CineJob/${jobId}`;

  const currentIndex = jobList.findIndex(
    (job) => String(job.id) === String(jobId)
  );

  const hasPreviousJob = currentIndex > 0;
  const hasNextJob = currentIndex < jobList.length - 1;

  const handlePreviousJob = () => {
    const currentIndex = jobList.findIndex(
      (job) => String(job.id) === String(jobId)
    );

    if (currentIndex > 0) {
      const prevJobId = jobList[currentIndex - 1].id;
      navigate(`/MyPost/CineJob/OverView/${prevJobId}`);
    }
  };

  const handleNextJob = () => {
    const currentIndex = jobList.findIndex(
      (job) => String(job.id) === String(jobId)
    );

    if (currentIndex < jobList.length - 1) {
      const nextJobId = jobList[currentIndex + 1].id;
      navigate(`/MyPost/CineJob/OverView/${nextJobId}`);
    }
  };

  return (
    <div>
      <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"} pt={{ xs: 5, md: 3 }}>
        <Box>
          <span onClick={() => navigate('/main/advDasboared')} style={{ cursor: 'pointer' }}>
            <MdOutlineKeyboardArrowLeft style={{ width: '40px', height: '40px' }} />
            <span style={{ fontSize: '20px' }}>Back</span>
          </span>
        </Box>
        <Box>
          <IconButton onClick={handlePreviousJob} disabled={!hasPreviousJob}>
            <IoChevronBackCircleOutline />
          </IconButton>
          <IconButton onClick={() => navigate('/main/advDasboared')}>
            <RiMenuFill />
          </IconButton>
          <IconButton onClick={handleNextJob} disabled={!hasNextJob}>
            <IoChevronForwardCircleOutline />
          </IconButton>
        </Box>
      </Box>

      <Card sx={{ padding: 2, marginTop: '16px' }}>
        <Card style={{ position: 'relative' }}>
          <Box display="flex" justifyContent={"space-evenly"} gap={4} flexWrap="wrap" alignItems="center" padding={2}>
            <Box
              bgcolor="#EFF5FC"
              borderRadius="10px"
              width="180px"
              height="180px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              overflow="hidden"
            >
              <div style={{
                position: 'relative',
                width: 'calc(100% - 32px)',
                height: 'calc(100% - 32px)',
                borderRadius: '8px',
                overflow: 'hidden',
                backgroundColor: '#EFF5FC',
              }}>
                <span style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${ASSETS_API.ASSETS_AD_IMAGES(companyDetail?.file)})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  filter: 'blur(2px)',
                  zIndex: 0,
                  opacity: 0.9,
                  borderRadius: '8px',
                }}></span>
                <img
                  src={`${ASSETS_API.ASSETS_AD_IMAGES(companyDetail?.file)}`}
                  alt="Company Logo"
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    zIndex: 1,
                    borderRadius: '8px',
                  }}
                />
              </div>
            </Box>

            <Box display="flex" flexDirection="column" mb={1}>
              <Box display="flex" borderRadius="8px" bgcolor="#EFF5FC" padding={0.6}>
                <Typography color="#4b4b4b" variant="body2">
                  ID No :
                </Typography>
                <Typography color="#2196f3" variant="body2">
                  {" "}#{jobId}
                </Typography>
              </Box>
              <Typography
                variant="h6"
                component="div"
                fontWeight={500}
                color="#4a4a4a"
                textTransform="capitalize"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: {
                    xs: 'calc(100% - 50px)',
                    sm: 'calc(100% - 100px)',
                    md: '100%',
                  },
                }}
              >
                {jobDetail.jobTitle || 'Job Title'}
              </Typography>

              <Typography
                textTransform="capitalize"
                variant="body2"
                color="#4a4a4a"
                mb={1}
                sx={{
                  color: '#4B4B4B',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: {
                    xs: 'calc(100% - 50px)',
                    md: '100%',
                  },
                }}
              >
                {workType} | {salary}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" mb={1}>
                <Typography component="div" variant="body2" color="#8B8B8B">Vacancy: {jobDetail.jobOpening}</Typography>
                <Typography component="div" variant="body2" color="#8B8B8B">{jobDetail.jobOpening} People interested</Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: '#8B8B8B',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <CiLocationOn
                  style={{
                    color: '#8B8B8B',
                    marginBottom: '3px',
                    fontSize: '19px',
                    marginRight: '4px',
                  }}
                />
                {jobDetail.jobAddress || 'Not specified'}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem sx={{
              borderWidth: 1,
              display: isLargeScreen ? 'block' : 'none',
            }} />
            <Box display="flex" alignItems="center" gap={{ xs: 1, md: 3 }} flexWrap="wrap">
              {["applied", "onHold", "shortlisted"].map(statusKey => (
                <Box key={statusKey} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <Typography color="#9554FF" fontSize="38px">
                    {statusCounts[statusKey] < 10 ? `0${statusCounts[statusKey]}` : statusCounts[statusKey]}
                  </Typography>
                  <Typography color={getStatusColor(statusKey)} variant="body1">
                    {capitalizeFirstLetter(statusKey)}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem sx={{
              borderWidth: 1,
              display: isLargeScreen ? 'block' : 'none',
            }} />
            <Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body2" color="#b5b5b5">
                  Posted on :
                </Typography>
                <Typography variant="body2">
                  {moment(createdAt).format('DD-MM-YYYY')}
                </Typography>
              </Box>
              <Divider sx={{ my: 1, borderWidth: 1, }} />
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body2" color="#b5b5b5">
                  Expires on :
                </Typography>
                <Typography variant="body2">
                  {moment(createdAt).format('DD-MM-YYYY')}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box position="absolute" display="flex" gap={1} alignItems="center" top={5} right={5}>
            <CiShare2 style={{ color: '#2196f3', fontSize: '24px', cursor: 'pointer' }} onClick={() => handleShare(jobUrl)} />
            <BsThreeDotsVertical style={{ color: '#2196f3', fontSize: '24px', cursor: 'pointer' }} />
          </Box>
        </Card>

        <Box sx={{ my: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="job post details"
            sx={{
              '& .MuiTab-root': {
                color: '#8b8b8b',
                textTransform: 'capitalize',
                height: {
                  xs: '40px',
                  sm: '45px',
                  md: '50px',
                  lg: '55px',
                },
                minHeight: {
                  xs: '40px',
                  sm: '45px',
                  md: '50px',
                  lg: '55px',
                },
                display: 'flex',
                alignItems: 'center',
                fontSize: {
                  xs: '14px',
                  sm: '16px',
                  md: '18px',
                  lg: '20px',
                },
              },
              '& .Mui-selected': {
                color: '#2196F3',
                borderBottomColor: '#2196F3',
                background: 'rgba(33, 150, 243, 0.1)',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#2196F3',
              },
            }}
            variant={isLgUp ? 'fullWidth' : 'scrollable'}
            scrollButtons
            allowScrollButtonsMobile
            centered={isLgUp}
          >
            {Object.keys(statusCounts).map((statusKey, index) => (
              <Tab
                key={statusKey}
                icon={getStatusIcon(statusKey)}
                iconPosition="start"
                label={`${capitalizeFirstLetter(statusKey)} (${statusCounts[statusKey]})`}
                sx={{
                  color: tabValue === index ? '#2196f3' : '#8b8b8b',
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box>
          {Object.keys(usersByStatus).map((statusKey, index) => (
            tabValue === index && (
              <CineJobUserCard
                key={statusKey}
                usersData={usersByStatus[statusKey]}
                status={statusKey}
                jobDetail={jobDetail}
                createdAt={createdAt}
                companyDetail={companyDetail}
                candidateDetail={job.candidateDetail}
                jobId={jobId}
                fetchData={fetchJobDetails}
              />
            )
          ))}
        </Box>
      </Card>
    </div>
  );
};

const getStatusIcon = (status) => {
  switch (status) {
    case 'applied': return <RiShareBoxLine />;
    case 'shortlisted': return <MdContentPasteSearch />;
    case 'onHold': return <GiSandsOfTime />;
    case 'interviewed': return <GiDiscussion />;
    case 'hire': return <LiaUserCheckSolid />;
    default: return null;
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'applied': return '#4b4b4b';
    case 'shortlisted': return '#07c271';
    case 'onHold': return '#2196f3';
    case 'interviewed': return '#FF9800';
    case 'hire': return '#4CAF50';
    default: return '#8b8b8b';
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default CineJobOverview;
