import React, { useState, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import { MdAdd } from "react-icons/md";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import pdfImage from "../../assets/images/pdfred.png";
import image from "../../assets/images/imageup.png";
import { X } from "@phosphor-icons/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import axios from "axios";
import { useSelector } from "react-redux";
import { Nodeapi,Assetsapi } from "../../config/serverUrl";
import { BsThreeDotsVertical } from "react-icons/bs";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import Swal from 'sweetalert2';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

const UploadDocument = () => {
  const [open, setOpen] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedDocumentPath, setSelectedDocumentPath] = useState(""); 
  const [serialNumbers, setSerialNumbers] = useState([]); 
  const [documenttype, setDocumenttype] = useState({
    documenttype: "",
    id: "",
    documentname: "",
    filename: "",
    othertype:""
  });
  const fileInputRef = useRef(null);
  const [updatebutton, setupdatebutton] = useState(false);
  const [filename, setfilename] = useState("");
 console.log('documenttype:',documenttype);
  const [valudata, setvaluedata] = useState({
    id: "",
    documentname: "",
  });
  const authdata = useSelector((state) => state.auth.user);

  const [isSubmitting, setIsSubmitting] = useState(false); 

  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);
  const handleOpen = () => {
    setOpen(true);
    setDocumenttype({
      documenttype: "",
      id: "",
      filename: "",
      othertype:""
    });
    setSelectedDocuments([]);
    setfilename("");
    setupdatebutton(false);
    setUploadFiles([])
  };

  const handleClose = () => setOpen(false);
  const [tabledata, setTableData] = useState([]);
  const [UploadFiles, setUploadFiles] = useState([]);


  console.log('tabledata:',tabledata);

  const fetchTableData = async () => {
    try {
      const response = await fetch(
        `${Nodeapi}/getalluserdocuments?id=${authdata?.id}`,
       
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setTableData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    fetchTableData();
  }, []);

  
  useEffect(() => {
    if(tabledata){
      
    setSerialNumbers(
      Array.from({ length: tabledata.length }, (_, index) => index + 1)
    );

    }
  }, [documenttype]);
  const handleUpload = async (e) => {
    const files = e.target.files;
    if(files.length === 0){
      return
    }
    setUploadFiles(files);
    const newDocuments = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSizeMB = file.size / (1024 * 1024); 
      if (fileSizeMB > 5) {
        Swal.fire({
            position: "center",
            icon: "warning",
            title: 'File size exceeds the limit of 5 MB. Please upload a smaller file',
            showConfirmButton: false,
            timer: 2500
          });
        
        handleClose()
        continue; 
      }
      const fileName = file.name.split(".").slice(0, -1).join(".");
      const filePath = URL.createObjectURL(file); 
      setfilename(fileName);
      newDocuments.push({
        file,
        type: file.type.includes("pdf") ? "pdf" : "image",
        documentName: fileName, 
        filePath: filePath, 
      });
    }

    setSelectedDocuments([...selectedDocuments, ...newDocuments]);
  };
   const id_data = authdata?.id;

  const handledocumentuploadadat = async () => {

    if(isSubmitting) return;
    setIsSubmitting(true);

    const isEmpty = Object.values(documenttype).every(value => value === "");

    if (UploadFiles.length === 0) {
      Swal.fire({
          position: "center",
          icon: "error",
          title: 'Please select at least one file to upload.',
          showConfirmButton: false,
          timer: 2000
      });
      setIsSubmitting(false);
      return; 
  }

    if(isEmpty){
      Swal.fire({
        position: "center",
        icon: "error",
        title: 'Please Fill Out All Required Fields',
        showConfirmButton: false,
        timer: 2000
      });
      setIsSubmitting(false);
      return 
    }




    const formData = new FormData();
    for (let file of UploadFiles) {
      formData.append("images", file);
    }
    formData.append("documentname", documenttype.documenttype);

    if (documenttype.documenttype === "Other") {
    
      formData.append("othertype", documenttype.othertype);
    }
    formData.append("userid", id_data);
    
    const res = await axios.post(
      `${Nodeapi}/upload`,
      formData,{
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${authdata.token}`
        },}
    );
    
    if(res.data){
      if(res.data.code == 200){
        Swal.fire({
          position: "center",
          icon: "success",
          title: 'Document added Successfully',
          showConfirmButton: false,
          timer: 3000
        });
        handleClose();
        
         setTableData(res.data.data.result);
      }else if(res.data.code == 401){
        Swal.fire({
          position: "center",
          icon: "error",
          title: 'Session time has expired. Please log in again.',
          showConfirmButton: true, 
          timer: 2000
        }).then((result) => {
          if (result.isConfirmed) {
            
            window.location.href = '/login'; 
          }
        });
        
        handleClose();
       

      }
      else{
        Swal.fire({
          position: "center",
          icon: "error",
          title: 'Error 404',
          showConfirmButton: false,
          timer: 2000
        });
        handleClose();
      }
    }
  
    setIsSubmitting(false);
  };



  const handleDelete1 = (index) => {
    const newDocuments = [...selectedDocuments];
    newDocuments.splice(index, 1);
    setSelectedDocuments(newDocuments);
  };

  const handleButtonClick = () => {
   
    fileInputRef.current.click();
  };

  const FileType = (tableData) => {
    const fileType = tableData.file.split(".").pop().toLowerCase(); 

    let text = ""; 
    let backgroundColor = ""; 

   
    if (fileType === "pdf") {
      text = "PDF";
      backgroundColor = "#a82a2a"; 
    } else if (
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "png"
    ) {
      text = "IMG";
      backgroundColor = "#27ba27"; 
    } else if (fileType === "doc" || fileType === "docx") {
      text = "DOC";
      backgroundColor = "#7CB9E8"; 
    } else {
      text = "UNKNOWN";
      backgroundColor = "gray"; 
    }

    return (
      <p>
        <span
          style={{
            background: backgroundColor,
            color: "white",
            padding: "2px 12px",
            fontSize: "12px",
            fontWeight: 600,
            borderRadius: "6px",
          }}
        >
          {text}
        </span>
      </p>
    );
  };




  const actions = (rowdata) => {
    const handleMenuClick = (event) => {
      setSelectedJobDetail(rowdata);
      menuLeft.current.toggle(event);
    };
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          // onClick={(event) => menuLeft.current.toggle(event)}
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  const menuLeft = useRef(null);
  const items = [
    {
      // label: "View",
      // icon: "pi pi-fw pi-pencil",
     
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selectedJobDetail),
    },
  ];

  const handleView = (filePath) => {
    const fileType =  filePath.split(".").pop().toLowerCase()

    if (fileType === "pdf") {
      var url = `${Assetsapi}/UserResume/${filePath}`;
      
    } else if (
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "png"
    ) {
      var url = `${Assetsapi}/UserProfileImage/${filePath}`;
     
    }
    
    window.open(url, "_blank");
    
    // Open the new window with the specified URL
    
  };

  const handleEdit = (rowData) => {
    const value = rowData.file;
   
    handleOpen();
    setDocumenttype({
      documenttype: rowData.documenttype,
      id: rowData.id,
      documentname: rowData.documentname,
    });
    setSelectedDocuments([rowData]);
    setupdatebutton(true);
    setSelectedDocumentPath(rowData.filePath); 
  };

  const deleteContactData = async (id) => {
    const res = await axios.delete(`${Nodeapi}/deleteDocuments/${id}`);
    if(res.data){
     if(res.data.code == 200){
    
     }else{
       console.log(res);
     }
    }
   
   fetchTableData();
  };

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
   
    if (result.isConfirmed) {
      try {
        await deleteContactData (rowData.id);
       
        Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success'
        });
        
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  const handledocument = (e) => {
    const { name, value } = e.target;
    if (name === "documenttype" && value === " othertype") {
      setDocumenttype({
        ...documenttype,
        documenttype: value,
        othertype: "", 
      });
    } else {
      setDocumenttype({
        ...documenttype,
        [name]: value,
      });
    }
  };
  


  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Upload Documents</h5>
          <p>
            <Button onClick={handleOpen}>
              <MdAdd size={22} />
            </Button>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="w-22rem md:w-30rem scrollBarHidden">
                <div className="flex">
                  <h6>Documents</h6>
                  <Tooltip title={<>
                  <p style={{fontSize:"12px"}}>Only JPG and JPEG and PDF file of minimumu 500 KB & maximum 5 Mb size is supported</p>
                  
                  </>}>
                    <IconButton
                      style={{
                        fontSize: "12px",
                        padding: "0",
                        marginLeft: "10px",
                      }}
                    >
                      <InfoIcon style={{ fontSize: "17px", color: "#0d6efd" }} />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="row">
                  <div className="col-md-7">
                    <div className="">
                      <label className="labels mt-2 mb-1" htmlFor="">
                        Choose Document Name
                      </label>
                      <select
                        className="form-select"
                        name="documenttype"
                        // onChange={(e) => setDocumenttype(e.target.value)}

                        onChange={handledocument}
                        value={documenttype.documenttype}
                      >
                        <option value="">Select Document Name</option>
                        <option value="VehicleDocument">
                          Vehicle Document
                        </option>
                        <option value="Property Document">
                          Property Document
                        </option>
                        <option value="College Document">
                          College Document
                        </option>
                        <option value="Gadget Document">Gadget Document</option>
                        <option value="Other">Other</option>

                      </select>
                      {documenttype.documenttype === "Other" && (
                      <input
                        type="text"
                        name="othertype"
                        placeholder="Document Type"
                        className="form-control mt-3"
                        value={documenttype.othertype}
                        onChange={handledocument}
                        required
                      />
                    )}
                    </div>
                  </div>
                  <div className="col-md-5 mt-2 md:mt-0">
                    <label htmlFor="" className="labels mt-2 mb-1">
                      Choose Document
                    </label>
                    <input
                      type="file"
                      onChange={handleUpload}
                      multiple
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    <button
                      className="btn btn-primary ml-2 md:ml-3"
                      onClick={handleButtonClick}
                    >
                      Choose File
                    </button>
                  </div>
                </div>
                {selectedDocuments.map((document, index) => (
                  <div
                    key={index}
                    className="card-upload mt-3"
                    style={{
                      padding: "4px 14px",
                      background: "#ece6e6",
                      color: "black",
                      borderRadius: "8px",
                      fontSize: "15px",
                      width: "56%",
                    }}
                  >
                    <div className="flex justify-content-between align-items-center">
                      <div className="flex" style={{ flex: "1", overflow: "hidden" }}>
                        <div className="document">
                          <img
                            src={document.type === "pdf" ? pdfImage : image}
                            alt=""
                            style={{ width: "1rem" }}
                          />
                        </div>
                        <div className="document name">
                          <p
                            className="ms-2"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          >
                            {document.file.name}
                          </p>
                        </div>
                      </div>
                      <div
                        className="delete icon"
                        onClick={() => handleDelete1(index)}
                      >
                        <p style={{ color: "#4A4A4A",
                        cursor: "pointer" }}>
                          <X size={20} />
                        </p>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="text-center mt-3">
                  <button className="btn btn-danger me-2" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value="add"
                    className="btn btn-primary"
                    onClick={handledocumentuploadadat}
                    disabled={isSubmitting}
                  >
                     {updatebutton ? (isSubmitting?"Submitting":"Update") : (isSubmitting?"Submitting":"Upload")}
                  </button>
                </div>
              </Box>
            </Modal>
          </p>
        </div>
        <div className="expand mt-2">
          <DataTable value={tabledata} tableStyle={{ minWidth: "50rem" }}>
          <Column
            header="S.No"
            body={(rowData) => <span>{tabledata.indexOf(rowData) + 1}</span>}
          ></Column>
            {/* <Column field="documenttype" header="Document Type"></Column> */}
            <Column 
            field={rowData => rowData.documenttype === 'Other' ? rowData.othertype : rowData.documenttype}  
            header="Document Type"
          ></Column>
            <Column field="documentname" header="Document Name"></Column>
            {/* <Column field="updated_at" header="validity Date"></Column> */}
            <Column body={FileType} header="File Type"></Column>
            {/* Include actions column */}
            <Column
              body={(rowdata) => (
                <div>
                  <Button onClick={() => handleView(rowdata.file)}>View</Button>
                </div>
              )}
              header="Preview"
            ></Column>
            <Column
              body={(rowdata) => actions(rowdata)}
              header="Actions"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
