import React, { useState, useEffect } from "react";
import "./login.css";
import logo from "../assets/images/KodukkuLogo.svg";
import { UserCircle, LockOpen, Eye, EyeSlash } from "@phosphor-icons/react"; 
import { Link } from 'react-router-dom';
import axios from "axios";
import { loginUser } from '../react-redux/slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Nodeapi, authapi } from "../config/serverUrl";
import { useLocation } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { Toast } from "primereact/toast";
import { useRef } from "react";
import HomeIcon from '@mui/icons-material/Home';
import CryptoJS from 'crypto-js';


const Login = () => {
  const toast = useRef(null);
  const location = useLocation();
  const { state } = location;
  const initialValue = { user_name: '', password: '' };
  const [value, setValue] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError,setloginError] = useState('')
  const [islogin,setislogin] = useState(false)
  const [userlogin,setuserlogin] = useState(false)
  const  navigate = useNavigate()

  useEffect(() => {
    const savedEmail = localStorage.getItem("user_name");
    const encryptedPassword = localStorage.getItem("encryptedPassword");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (savedEmail) {
      let decryptedPassword = "";
      if (encryptedPassword) {
        const bytes = CryptoJS.AES.decrypt(encryptedPassword, '#&$21fb@#');
        decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
      }
      setValue({ user_name: savedEmail, password: decryptedPassword });
    }
    setRememberMe(savedRememberMe);
  }, []);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem("user_name", value.user_name);
      if (value.password) {
        const encryptedPassword = CryptoJS.AES.encrypt(value.password, '#&$21fb@#').toString();
        localStorage.setItem("encryptedPassword", encryptedPassword);
      } else {
        localStorage.removeItem("encryptedPassword");
      }
      localStorage.setItem("rememberMe", rememberMe);
    }
  }, [value, rememberMe]);
  const handleChange = (e) => {

    const { id, value } = e.target;
  
    setValue(prevData => ({
      ...prevData,
      [id]: value
    }));
  
    if (formErrors[id]) {
      
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [id]: ''
      }));
    }
    if (id === "password" && rememberMe) {
      const encryptedPassword = CryptoJS.AES.encrypt(value, '#&$21fb@#').toString();
      localStorage.setItem("encryptedPassword", encryptedPassword);
    }
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const errors = validate(value);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        
        const response = await axios.post(`${authapi}/auth/signin`, value);
        const data = response.data;
      
        const userdata = response.data.data.user
        if(data.code == 200){

          const username = userdata.name
          const emailid = userdata.user_name
          const kid_id = userdata.userid
      
          dispatch(loginUser(response.data.data.user));
          const result = new Promise((resolve,reject)=>{
            setTimeout(() => {
              resolve( userlogindetails(username,emailid,kid_id))
            }, 3000);
          })
          
          setuserlogin(true)
        
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.data.message,
            life: 3000,
          });

        }else if( data.code == 401){
         
          
           toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.data.message,
            life: 3000,
          });
        }
        else if( data.code == 404){
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.data.message,
            life: 3000,
          });
          
        }
  
      if (data.code == 200){
        navigate('/')
        setislogin(true)
      }

       } catch (error) {
        console.log(error);
      }
    }
  }

  

  
  const userlogindetails = async (username,emailid,kid_id)=>{
    
    const now = new Date();
    const timing = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }); 
    const date = now.toISOString().split('T')[0]; 
    
    
    const userLoginDetails = {
    username,
    emailid,
    kid_id,
    timing,
    date
    }

    try {
      const res = await axios.post(`${Nodeapi}/UserLoginDetails`,userLoginDetails)

    } catch (error) {
      console.log('error:',error);
    }
    
  }

  const validate = (values) => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.user_name) {
      errors.user_name = "User name is required!";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } 
    return errors;
  };

  const dispatch = useDispatch();


  return (
   <>
   
    <Toast ref={toast} />
    <div className="Auth-form-container">
      
      <form className="Auth-form" onSubmit={handleSubmit}>
      {
      state && (
        <Alert variant="filled" severity="success">
        Password Reset Successfully...!
      </Alert>
      )
    }
        <div className="Auth-form-content">
          <div className="head">
       <div style={{display: "flex", justifyContent: "center",marginLeft:"-71px" }}>
       <Link to="/"> <HomeIcon className="home_icon" style={{width:"28px",height:"37px",marginTop:"-4px",color: "#2196F3"}}/></Link>
            <h5 className="text-center login" style={{ color: "#4A4A4A" }}>
              Log In
            </h5>
       </div>
          </div>
          <div className="content">
            <div className="title d-flex align-items-center justify-content-center mb-3">
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "4rem",
                  border: "2px solid #68BCFF",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              />
              <h3 style={{ color: "#2196F3", marginLeft: "10px" }}>Welcome!</h3>
            </div>
            {loginError && <p style={{ color: 'red' , textAlign:"center" }}>{loginError}</p>}
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text"                 
                style={{ borderColor: (isSubmit && formErrors.user_name) ? 'red' : '' }}
>
                  <UserCircle style={{color:"#2196F3"}} size={28} />
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                id="user_name"
                placeholder="Email or Username"
                onChange={handleChange}
                value={value.user_name}
                style={{ borderColor: (isSubmit && formErrors.user_name) ? 'red' : '' }}
              />
            </div>
            {isSubmit && <p style={{ color: 'red' }}>{formErrors.user_name}</p>}

            <div className="input-group mt-4">
              <div className="input-group-prepend" style={{position:"relative"}}>
                <span className="input-group-text"                
                style={{ borderColor: (isSubmit && formErrors.password) ? 'red' : '' }}
>
                  <LockOpen style={{color:"#2196F3"}} size={28} />
                </span>
              </div>
              <div  style={{ position: "relative",width:"100%" }}>
              <input
                type={showPassword ? "text" : "password"} 
                className="form-control eyeicon_static"
                id="password"
                placeholder="Password"
                onChange={handleChange}
                value={value.password}
                style={{ borderColor: (isSubmit && formErrors.password) ? 'red' : '' }}
              />
              <span style={{position:"absolute",top:"8px",right:"28px",zIndex:"1",color:"#959595"}} className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Eye  style={{color:"#2196F3"}}  size={22} /> : <EyeSlash  style={{color:"#2196F3"}} size={22} />}
              </span>
            </div>
              </div>
            
            {isSubmit && <p style={{ color: 'red' }}>{formErrors.password}</p>}
            <div className="reset-pass d-flex justify-content-between my-3">
              <div>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  id="rememberMeCheckbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label htmlFor="rememberMeCheckbox">Remember me</label>
              </div>
              <Link to="/resetpassword">
                <p style={{ cursor: "pointer" }}>Reset Password</p>
              </Link>
            </div>
            <button className="btn continue btn-primary mt-4" >Continue</button>
            <p className="mt-3">
              <Link to="/register" style={{ color: "#2196F3" }}>
                New User Registration
              </Link>
            </p>
          </div>
        </div>
        
      </form>
     
    </div>
    
    </>
  );
};

export default Login;

