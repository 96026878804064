const religions = [
  "Christianity",
  "Islam",
  "Hinduism",
  "Buddhism",
  "Sikhism",
  "Judaism",
  "BaháFaith",
  "Jainism",
  "Shinto",
  "Taoism",
  "Zoroastrianism",
  "Confucianism",
  "Rastafari",
  "Animism",
  "Wicca",
  "Druidry",
  "Cao Dai",
  "Tenrikyo",
  "Falun Gong",
  "Scientology",
];

const nationalities = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivan",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian/Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
];

const communities = [
  "SC (Scheduled Castes)",
  "ST (Scheduled Tribes)",
  "MBC (Most Backward Classes)",
  "DC (Denotified Communities)",
  "BC (Backward Classes)",
  "BCM (Backward Classes Muslims)",
];

const nationalPartyNames = [
  "Aam Aadmi Party (AAP)",
  "Bahujan Samaj Party (BSP)",
  "Bharatiya Janata Party (BJP)",
  "Communist Party of India (Marxist) (CPI(M))",
  "Indian National Congress (INC)",
  "National People's Party (NPP)",
  "All India Trinamool Congress",
  "Communist Party of India",
  "Janata Dal (Secular)",
  "Janata Dal (United)",
  "All India Anna Dravida Munnetra Kazhagam",
  "Dravida Munnetra Kazhagam",
  "Lok Janshakti Party (Ram Vilas)",
  "Naga People's Front",
  "Nationalist Congress Party",
  "Rashtriya Janata Dal",
  "Telugu Desam Party",
  "All India Forward Bloc",
  "All India Majlis-e-Ittehadul Muslimeen",
  "All India N.R. Congress",
  "All India United Democratic Front",
  "All Jharkhand Students Union",
  "Apna Dal (Sonelal)",
  "Asom Gana Parishad",
  "Bharat Rashtra Samithi",
  "Biju Janata Dal",
  "Bodoland People's Front",
  "Communist Party of India (Marxist–Leninist) Liberation",
  "Desiya Murpokku Dravida Kazhagam",
  "Goa Forward Party",
  "Hill State People's Democratic Party",
  "Indian National Lok Dal",
  "Indian Union Muslim League",
  "Indigenous People's Front of Tripura",
  "Jammu & Kashmir National Conference",
  "Jammu and Kashmir National Panthers Party",
  "Jammu and Kashmir Peoples Democratic Party",
  "Janta Congress Chhattisgarh",
  "Jannayak Janta Party",
  "Jharkhand Mukti Morcha",
  "Kerala Congress (M)",
  "Maharashtra Navnirman Sena",
  "Maharashtrawadi Gomantak Party",
  "Mizo National Front",
  "Nationalist Democratic Progressive Party",
  "People's Party of Arunachal",
  "Rashtriya Lok Janshakti Party",
  "Rashtriya Loktantrik Party",
  "Revolutionary Socialist Party",
  "Samajwadi Party",
  "Shiromani Akali Dal",
  "Sikkim Democratic Front",
  "Sikkim Krantikari Morcha",
  "Shiv Sena",
  "Shiv Sena (UBT)",
  "Tipra Motha Party",
  "United Democratic Party",
  "United People's Party Liberal",
  "Voice of the People Party",
  "YSR Congress Party",
  "Zoram Nationalist Party",
  "Zoram People's Movement",
  "Akhil Bharat Hindu Mahasabha",
  "All India Hindustan Congress Party",
  "All India Mahila Empowerment Party",
  "Amma Makkal Munnettra Kazhagam",
  "Amra Bangali",
  "Azad Adhikar Sena",
  "Azad Samaj Party (Kanshi Ram)",
  "Bahujan Mukti Party",
  "Bharatiya Minorities Suraksha Mahasangh",
  "Gorkha Janmukti Morcha",
  "Goa Suraksha Manch",
  "Gondwana Ganatantra Party",
  "Hindu Sena",
  "Hindustani Awam Morcha",
  "Indhiya Jananayaga Katchi",
  "Indian Gandhiyan Party",
  "Indian Secular Front",
  "Ittehad-e-Millat Council",
  "Jammu and Kashmir Apni Party",
  "Jammu and Kashmir Workers Party",
  "Jan Adhikar Party (Loktantrik)",
  "Jan Shakti Party of India",
  "Jana Sena Party",
  "Jansatta Dal (Loktantrik)",
  "Karnataka Rashtra Samithi",
  "Kerala Congress",
  "Kerala Congress (B)",
  "Kerala Congress (Jacob)",
  "Kongunadu Makkal Desia Katchi",
  "Kongunadu Makkal Katchi",
  "Lok Satta Party",
  "Lok Insaaf Party",
  "Makkal Needhi Maiam",
  "Manipur Peoples Party",
  "Manithaneya Makkal Katchi",
  "Marumalarchi Dravida Munnetra Kazhagam",
  "Naam Tamilar Katchi",
  "NISHAD Party",
  "Odisha Jan Morcha",
  "Param Digvijay Dal",
  "Pattali Makkal Katchi",
  "Peace Party of India",
  "People's Democratic Alliance (Manipur)",
  "People's Democratic Front",
  "Plurals Party",
  "Puthiya Tamilagam",
  "Raijor Dal",
  "Rashtriya Jan Jan Party",
  "Rashtriya Lok Dal",
  "Rashtriya Mahaswaraj Bhumi Party",
  "Rashtriya Samaj Paksha",
  "Rashtriya Ulama Council",
  "Republican Party of India (Athawale)",
  "Revolutionary Goans Party",
  "Right to Recall Party",
  "Samata Party",
  "Shiromani Akali Dal (Amritsar)",
  "Social Democratic Party of India",
  "Socialist Party (India)",
  "Socialist Unity Centre of India (Communist)",
  "Suheldev Bharatiya Samaj Party",
  "Swaraj Abhiyan",
  "Tamil Maanila Congress",
  "Tamilaga Vettri Kazhagam",
  "Tamil Nadu Kongu Ilaingar Peravai",
  "Uttarakhand Kranti Dal",
  "Vanchit Bahujan Aaghadi",
  "Viduthalai Chiruthaigal Katchi",
  "Vikassheel Insaan Party",
  "Welfare Party of India",
];


const maritalStatus = [
  "Single",
  "Married",
  "Divorced",
  "Separated",
  "Widowed",
  "Engaged",
];

const idProofs = [
  "Aadhaar Card",
  "Passport",
  "Voter ID Card",
  "Driving License",
  "PAN Card (Permanent Account Number)",
  "Ration Card",
  "Bank Passbook",
  "Employee ID Card",
  "Student ID Card",
  "National Population Register (NPR) Card",
  "Social Security Card",
  "Birth Certificate",
  "Marriage Certificate",
  "Electricity Bill",
  "Water Bill",
  "Telephone Bill",
  "Gas Connection Bill",
  "Property Tax Receipt",
  "Income Tax Assessment Order",
  "Vehicle Registration Certificate",
  "Identity Certificate (issued by government departments)",
  "Caste Certificate",
  "Domicile Certificate",
  "Arms License",
  "Senior Citizen Card",
  "Freedom Fighter Card",
  "Health Insurance Card",
  "Pension Document",
  "Disability ID Card",
  "Income Certificate",
  "Insurance Policy Document",
  "Legal Heir Certificate",
  "Railway Identity Card",
  "Identity Cards issued by Educational Institutions",
  "Identity Cards issued by Employers",
];



const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const bloodgroup =[
  "A positive (A+)",
  "A negative (A-)",
  "B positive (B+)",
  "B negative (B-)",
  "AB positive (AB+)",
  "AB negative (AB-)",
  "O positive (O+)",
  "O negative (O-)",
]


const education  = [
"school",
"College",
"Course/Skill Development",
"Certification",
"Specialization"
]


const collegesInIndia = [
  // Engineering Colleges
  "Indian Institutes of Technology (IITs)",
  "National Institutes of Technology (NITs)",
  "Indian Institutes of Information Technology (IIITs)",
  "Birla Institute of Technology and Science (BITS)",
  "Delhi Technological University (DTU)",
  "Anna University, Chennai",
  "Vellore Institute of Technology (VIT)",
  "Manipal Institute of Technology",
  "Indian Institute of Engineering Science and Technology (IIEST), Shibpur",
  "Visvesvaraya National Institute of Technology (VNIT), Nagpur",
  "College of Engineering, Pune (COEP)",
  "Jadavpur University, Kolkata",
  "Netaji Subhas University of Technology (NSUT), New Delhi",
  "National Institute of Foundry and Forge Technology (NIFFT), Ranchi",
  "Indian School of Mines (ISM), Dhanbad",
  "Birla Institute of Technology, Mesra",
  "Thapar Institute of Engineering and Technology, Patiala",
  "Punjab Engineering College (PEC), Chandigarh",
  "PEC University of Technology, Chandigarh",
  "R.V. College of Engineering (RVCE), Bangalore",
  "BMS College of Engineering, Bangalore",
  "Sardar Vallabhbhai National Institute of Technology (SVNIT), Surat",
  "Maulana Azad National Institute of Technology (MANIT), Bhopal",
  "National Institute of Technology Calicut (NIT Calicut)",
  "National Institute of Technology Rourkela (NIT Rourkela)",
  "National Institute of Technology Kurukshetra (NIT Kurukshetra)",
  "National Institute of Technology Warangal (NIT Warangal)",
  "Motilal Nehru National Institute of Technology (MNNIT), Allahabad",
  "National Institute of Technology Durgapur (NIT Durgapur)",
  "National Institute of Technology Jamshedpur (NIT Jamshedpur)",
  "Indian Institute of Space Science and Technology (IIST), Thiruvananthapuram",
  "Indian Institute of Science (IISc), Bangalore",
  "Indraprastha Institute of Information Technology (IIIT), Delhi",
  "Nirma University, Ahmedabad",
  "PES University, Bangalore",
  "Sathyabama Institute of Science and Technology, Chennai",
  "SRM Institute of Science and Technology, Chennai",
  "University Visvesvaraya College of Engineering (UVCE), Bangalore",
  "Veer Surendra Sai University of Technology (VSSUT), Burla",
  "West Bengal University of Technology (WBUT), Kolkata",

  // Medical Colleges
  "All India Institute of Medical Sciences (AIIMS)",
  "Christian Medical College (CMC), Vellore",
  "Armed Forces Medical College (AFMC), Pune",
  "Maulana Azad Medical College (MAMC), New Delhi",
  "Jawaharlal Institute of Postgraduate Medical Education and Research (JIPMER), Puducherry",
  "King George's Medical University (KGMU), Lucknow",
  "Grant Medical College, Mumbai",
  "Kasturba Medical College (KMC), Manipal",
  "Lady Hardinge Medical College (LHMC), New Delhi",
  "Madras Medical College (MMC), Chennai",
  "Seth Gordhandas Sunderdas Medical College (GSMC), Mumbai",
  "Government Medical College and Hospital (GMCH), Chandigarh",
  "Institute of Medical Sciences, Banaras Hindu University (IMS-BHU), Varanasi",
  "Osmania Medical College (OMC), Hyderabad",
  "Government Medical College, Thiruvananthapuram",
  "Bangalore Medical College and Research Institute (BMCRI)",
  "Government Medical College, Nagpur",
  "Postgraduate Institute of Medical Education and Research (PGIMER), Chandigarh",
  "Rajendra Institute of Medical Sciences (RIMS), Ranchi",
  "Sawai Man Singh Medical College (SMS Medical College), Jaipur",
  "Vardhman Mahavir Medical College (VMMC), New Delhi",

  // Arts and Science Colleges
  "St. Stephen's College, Delhi",
  "Loyola College, Chennai",
  "St. Xavier's College, Mumbai",
  "Presidency College, Chennai",
  "Miranda House, Delhi",
  "Madras Christian College (MCC), Chennai",
  "Hansraj College, Delhi",
  "Christ University, Bangalore",
  "Ramjas College, Delhi",
  "Fergusson College, Pune",
  "Hindu College, Delhi",
  "St. Xavier's College, Kolkata",
  "Lady Shri Ram College for Women (LSR), Delhi",
  "Mount Carmel College, Bangalore",
  "St. Joseph's College, Bangalore",
  "Sri Venkateswara College, Delhi",
  "Madras Presidency College, Chennai",
  "Symbiosis College, Pune",
  "Shri Ram College of Commerce (SRCC), Delhi",
  "Madurai Kamaraj University (MKU), Madurai",
  "Jamia Millia Islamia, Delhi",
  "University College, Thiruvananthapuram",
  "Delhi College of Arts and Commerce (DCAC)",
  "Scottish Church College, Kolkata",
  "Hindu College, Chennai",
  "Indraprastha College for Women, Delhi",
  "St. Xavier's College, Ahmedabad",
  "Madras Christian College (MCC), Tambaram",
  "Loreto College, Kolkata",
  "Stella Maris College, Chennai",
  "Nizam College, Hyderabad",
  "Fergusson College, Pune",
  "Mount Carmel College, Bangalore",
  "Presidency College, Kolkata",
  "St. Joseph's College, Bangalore",

  // Law Colleges
  "National Law School of India University (NLSIU), Bangalore",
  "National Academy of Legal Studies and Research (NALSAR), Hyderabad",
  "Faculty of Law, University of Delhi",
  "Symbiosis Law School, Pune",
  "Indian Law Society's Law College, Pune",
  "Gujarat National Law University (GNLU), Gandhinagar",
  "Amity Law School, Delhi",
  "Government Law College, Mumbai",
  "ILS Law College, Pune",
  "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata",
  "National Law University, Delhi (NLU Delhi)",
  "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow",
  "The Rajiv Gandhi National University of Law (RGNUL), Patiala",
  "Faculty of Law, Aligarh Muslim University",
  "Bishop Cotton Women's Christian Law College, Bangalore",
  "Army Institute of Law, Mohali",
  "Symbiosis Law School, Hyderabad",
  "University Law College, Bangalore University",
  "Ambedkar Law University, Chennai",
  "Faculty of Law, Jamia Millia Islamia, Delhi",
  "Hidayatullah National Law University (HNLU), Raipur",
  "Faculty of Law, University of Mumbai",
  "KIIT School of Law, Bhubaneswar",

  // Other Colleges
  "Indian Institute of Management (IIMs)",
  "Indian Institutes of Management (IITs)",
  "Indian Institutes of Technology (IITs)",
  "Indian Institutes of Information Technology (IIITs)",
  "Indian Institutes of Science Education and Research (IISERs)",
  "Indian Statistical Institute (ISI)",
  "National Institute of Fashion Technology (NIFT)",
  "National Institute of Design (NID)",
  "All India Institute of Speech and Hearing (AIISH), Mysore",
  "Tata Institute of Fundamental Research (TIFR)",
  "Tata Institute of Social Sciences (TISS), Mumbai",
  "Indian Institute of Science (IISc), Bangalore",
  "Indian Institute of Technology (Banaras Hindu University), Varanasi",
  "Indian Institute of Technology (Indian School of Mines), Dhanbad",
  "Indian Institute of Information Technology (IIIT), Allahabad",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Kolkata",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Shillong",
  "Indian Institute of Management (IIM), Raipur",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Rohtak",
  "Indian Institute of Management (IIM), Tiruchirappalli",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Nagpur",
  "Indian Institute of Management (IIM), Visakhapatnam",
  "Indian Institute of Management (IIM), Bodh Gaya",
  "Indian Institute of Management (IIM), Amritsar",
  "Indian Institute of Management (IIM), Sirmaur",
  "Indian Institute of Management (IIM), Jammu",
  "Indian Institute of Management (IIM), Sambalpur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Goa",
  "Indian Institute of Management (IIM), Jodhpur",
  "Indian Institute of Management (IIM), Bharuch",
  "Indian Institute of Management (IIM), Visakhapatnam",
  "Indian Institute of Management (IIM), Tirupati",
  "Indian Institute of Management (IIM), Amritsar",
  "Indian Institute of Management (IIM), Dharwad",
  "Indian Institute of Management (IIM), Bodh Gaya",
  "Indian Institute of Management (IIM), Sambalpur",
  "Indian Institute of Management (IIM), Jammu",
  "Indian Institute of Management (IIM), Sirmaur",
  "Indian Institute of Management (IIM), Vishakhapatnam",
  "Indian Institute of Management (IIM), Nagpur",
  "Indian Institute of Management (IIM), Amritsar",
  "Indian Institute of Management (IIM), Sambalpur",
  "Indian Institute of Management (IIM), Sirmaur",
  "Indian Institute of Management (IIM), Vishakhapatnam",
  "Indian Institute of Management (IIM), Nagpur",
  "Indian Institute of Management (IIM), Jammu",
  "Indian Institute of Management (IIM), Sirmaur",
  "Indian Institute of Management (IIM), Vishakhapatnam",
  "Indian Institute of Management (IIM), Amritsar",
  "Indian Institute of Management (IIM), Sambalpur",
  "Indian Institute of Management (IIM), Sirmaur",
  "Indian Institute of Management (IIM), Vishakhapatnam",
  "Indian Institute of Management (IIM), Nagpur",
  "Indian Institute of Management (IIM), Jammu",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Tiruchirappalli",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Ahmedabad",
  "Indian Institute of Management (IIM), Calcutta",
  "Indian Institute of Management (IIM), Ranchi",
  "Indian Institute of Management (IIM), Udaipur",
  "Indian Institute of Management (IIM), Kashipur",
  "Indian Institute of Management (IIM), Kozhikode",
  "Indian Institute of Management (IIM), Lucknow",
  "Indian Institute of Management (IIM), Indore",
];
const degreeSpecializations = [
  // Arts and Humanities
  "Art History",
  "Literature",
  "Music",
  "Philosophy",
  "Religious Studies",
  "Theatre",
  "Visual Arts",

  // Social Sciences
  "Anthropology",
  "Economics",
  "Geography",
  "Political Science",
  "Psychology",
  "Sociology",

  // Business
  "Accounting",
  "Finance",
  "Human Resources",
  "Marketing",
  "Operations Management",
  "Entrepreneurship",
  "International Business",
  "Business Analytics",
  "Supply Chain Management",

  // Education
  "Elementary Education",
  "Secondary Education",
  "Special Education",
  "Early Childhood Education",
  "Educational Leadership",
  "Curriculum and Instruction",
  "Higher Education Administration",

  // Science and Engineering
  "Computer Science",
  "Information Technology",
  "Engineering",
  "Biomedical Engineering",
  "Chemical Engineering",
  "Civil Engineering",
  "Electrical Engineering",
  "Mechanical Engineering",
  "Aerospace Engineering",
  "Environmental Engineering",
  "Materials Science and Engineering",
  "Nuclear Engineering",
  "Software Engineering",
  "Data Science",
  "Artificial Intelligence",
  "Robotics",
  "Cybersecurity",
  "Biotechnology",
  "Bioinformatics",

  // Health and Medicine
  "Medicine",
  "Nursing",
  "Pharmacy",
  "Dentistry",
  "Physical Therapy",
  "Occupational Therapy",
  "Speech-Language Pathology",
  "Public Health",
  "Health Informatics",
  "Healthcare Administration",
  "Healthcare Management",
  "Healthcare Policy",

  // Law
  "Criminal Law",
  "Corporate Law",
  "International Law",
  "Family Law",
  "Environmental Law",
  "Intellectual Property Law",
  "Tax Law",

  // Creative Arts
  "Graphic Design",
  "Illustration",
  "Animation",
  "Game Design",
  "Fashion Design",
  "Interior Design",
  "Industrial Design",
  "Film Production",
  "Photography",
  "Writing",
  "Journalism",

  // Others
  "Architecture",
  "Landscape Architecture",
  "Urban Planning",
  "Public Policy",
  "Library Science",
  "Information Science",
  "Data Analytics",
  "Financial Engineering",
  "Urban Design",
  "Real Estate Development",
  "Forensic Science",
  "Criminology",
  "Emergency Management",
  "Disaster Management",
];

const universitiesInIndia = [
  // Andhra Pradesh
  "Andhra University",
  "Sri Venkateswara University",
  "Acharya Nagarjuna University",
  "Jawaharlal Nehru Technological University, Kakinada",
  "Sri Krishnadevaraya University",

  // Arunachal Pradesh
  "Rajiv Gandhi University",

  // Assam
  "Gauhati University",
  "Dibrugarh University",
  "Assam Agricultural University",
  "Assam Don Bosco University",

  // Bihar
  "Patna University",
  "Babasaheb Bhimrao Ambedkar Bihar University",
  "Aryabhatta Knowledge University",
  "Magadh University",

  // Chhattisgarh
  "Pt. Ravishankar Shukla University",
  "Guru Ghasidas Vishwavidyalaya",
  "Chhattisgarh Swami Vivekanand Technical University",

  // Goa
  "Goa University",

  // Gujarat
  "Gujarat University",
  "Sardar Patel University",
  "Maharaja Sayajirao University of Baroda",
  "Dhirubhai Ambani Institute of Information and Communication Technology",

  // Haryana
  "Kurukshetra University",
  "Maharshi Dayanand University",
  "Chaudhary Charan Singh Haryana Agricultural University",
  "National Institute of Technology, Kurukshetra",

  // Himachal Pradesh
  "Himachal Pradesh University",
  "Indian Institute of Technology Mandi",

  // Jammu and Kashmir
  "University of Jammu",
  "University of Kashmir",
  "Sher-e-Kashmir University of Agricultural Sciences and Technology of Kashmir",

  // Jharkhand
  "Ranchi University",
  "Jharkhand Rai University",
  "Birla Institute of Technology, Mesra",

  // Karnataka
  "Bangalore University",
  "Visvesvaraya Technological University",
  "University of Mysore",
  "Indian Institute of Science Bangalore",

  // Kerala
  "University of Kerala",
  "Cochin University of Science and Technology",
  "Mahatma Gandhi University",
  "Indian Institute of Space Science and Technology",

  // Madhya Pradesh
  "Devi Ahilya Vishwavidyalaya",
  "Rajiv Gandhi Proudyogiki Vishwavidyalaya",
  "Barkatullah University",

  // Maharashtra
  "University of Mumbai",
  "Savitribai Phule Pune University",
  "Shivaji University",
  "Tata Institute of Social Sciences",

  // Manipur
  "Manipur University",

  // Meghalaya
  "North-Eastern Hill University",

  // Mizoram
  "Mizoram University",

  // Nagaland
  "Nagaland University",

  // Odisha
  "Utkal University",
  "Sambalpur University",
  "National Institute of Technology Rourkela",

  // Punjab
  "Panjab University",
  "Punjabi University",
  "Thapar Institute of Engineering and Technology",

  // Rajasthan
  "University of Rajasthan",
  "Rajasthan Technical University",
  "Maharaja Ganga Singh University",

  // Sikkim
  "Sikkim University",

  // Tamil Nadu
  "Anna University",
  "University of Madras",
  "Bharathiar University",
  "Madurai Kamaraj University",

  // Telangana
  "Osmania University",
  "University of Hyderabad",
  "Jawaharlal Nehru Technological University, Hyderabad",

  // Tripura
  "Tripura University",

  // Uttar Pradesh
  "University of Lucknow",
  "Banaras Hindu University",
  "Aligarh Muslim University",
  "Indian Institute of Technology Kanpur",

  // Uttarakhand
  "Kumaun University",
  "Uttarakhand Technical University",

  // West Bengal
  "University of Calcutta",
  "Jadavpur University",
  "West Bengal University of Technology",
  "Indian Institute of Technology Kharagpur",
];

const degreeTypes = [
  "Associate Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctoral Degree",
  "Professional Degree",
  "Certificate",
  "Diploma",
  "Postgraduate Diploma",
  "Advanced Diploma",
  "Honorary Degree",
  "Joint Degree",
  "Double Degree",
  "Dual Degree",
  "Undergraduate Certificate",
  "Graduate Certificate",
  "Specialist Degree",
  "Licentiate Degree",
  "Pharmaceutical Degree",
  "Medical Degree",
  "Law Degree",
  "Engineering Degree",
  "Business Degree",
  "Computer Science Degree",
  "Arts Degree",
  "Science Degree",
  "Education Degree",
  "Nursing Degree",
  "Social Work Degree",
  "Music Degree",
  "Fine Arts Degree",
  "Theology Degree",
  "Aviation Degree",
  "Architecture Degree",
  "Environmental Science Degree",
  "Healthcare Administration Degree",
  "Public Health Degree",
  "Public Policy Degree",
  "Library Science Degree",
  "Communication Degree",
  "Psychology Degree",
  "Counseling Degree",
  "Criminal Justice Degree",
  "Information Technology Degree",
  "Human Resources Degree",
  "Marketing Degree",
  "Finance Degree",
  "Accounting Degree",
  "Economics Degree",
  "Mathematics Degree",
  "Statistics Degree",
  "Chemistry Degree",
  "Biology Degree",
  "Physics Degree",
  "Geology Degree",
  "Anthropology Degree",
  "Sociology Degree",
  "Political Science Degree",
  "History Degree",
  "Philosophy Degree",
  "English Degree",
  "Foreign Language Degree",
  "Linguistics Degree",
  "Creative Writing Degree",
  "Journalism Degree",
  "Film Studies Degree",
  "Graphic Design Degree",
  "Animation Degree",
  "Fashion Design Degree",
  "Interior Design Degree",
  "Industrial Design Degree",
  "Product Design Degree",
  "Urban Planning Degree",
  "Real Estate Degree",
  "Hospitality Management Degree",
  "Tourism Management Degree",
  "Sports Management Degree",
  "Recreation Management Degree",
  "Event Management Degree",
  "Museum Studies Degree",
  "Archeology Degree",
  "Paleontology Degree",
  "Forensic Science Degree",
  "Veterinary Medicine Degree",
  "Dentistry Degree",
  "Pharmacy Degree",
  "Naturopathy Degree",
  "Chiropractic Degree",
  "Optometry Degree",
  "Podiatry Degree",
  "Acupuncture Degree",
  "Naturopathic Medicine Degree",
  "Osteopathic Medicine Degree",
  "Physical Therapy Degree",
  "Occupational Therapy Degree",
  "Speech-Language Pathology Degree",
  "Audiology Degree",
  "Clinical Psychology Degree",
  "Counseling Psychology Degree",
  "Organizational Psychology Degree",
  "School Psychology Degree",
  "Applied Behavior Analysis Degree",
  "Marriage and Family Therapy Degree",
  "Social Work Degree",
  "Public Health Degree",
  "Health Education Degree",
  "Healthcare Administration Degree",
  "Nutrition Degree",
  "Exercise Science Degree",
  "Kinesiology Degree",
  "Sports Medicine Degree",
  "Athletic Training Degree",
  "Personal Training Degree",
  "Healthcare Management Degree",
  "Clinical Research Degree",
  "Biomedical Science Degree",
  "Clinical Laboratory Science Degree",
  "Medical Technology Degree",
  "Radiologic Technology Degree",
  "Nuclear Medicine Technology Degree",
  "Diagnostic Medical Sonography Degree",
  "Medical Imaging Degree",
  "Medical Dosimetry Degree",
  "Health Information Management Degree",
  "Health Informatics Degree",
  "Medical Coding Degree",
  "Medical Billing Degree",
  "Healthcare Data Analytics Degree",
  "Medical Writing Degree",
  "Biostatistics Degree",
  "Epidemiology Degree",
  "Global Health Degree",
  "Environmental Health Degree",
  "Occupational Health Degree",
  "Public Health Policy Degree",
  "Health Policy Degree",
  "Healthcare Law Degree",
  "Healthcare Ethics Degree",
  "Healthcare Compliance Degree",
  "Healthcare Risk Management Degree",
  "Healthcare Quality Improvement Degree",
  "Healthcare Informatics Degree",
  "Nursing Informatics Degree",
  "Healthcare Technology Management Degree",
  "Healthcare Innovation Degree",
  "Healthcare Entrepreneurship Degree",
  "Healthcare Finance Degree",
  "Healthcare Economics Degree",
  "Healthcare Marketing Degree",
  "Healthcare Leadership Degree",
  "Healthcare Consulting Degree",
  "Healthcare Project Management Degree",
  "Healthcare Operations Management Degree",
  "Healthcare Supply Chain Management Degree",
  "Healthcare Logistics Degree",
  "Healthcare Facilities Management Degree",
  "Healthcare Real Estate Degree",
  "Healthcare Construction Management Degree",
  "Healthcare Administration and Management Degree",
  "Health Services Administration Degree",
  "Hospital Administration Degree",
  "Nursing Administration Degree",
  "Healthcare Administration and Policy Degree",
  "Healthcare Administration and Leadership Degree",
  "Healthcare Administration and Informatics Degree",
  "Healthcare Administration and Entrepreneurship Degree",
  "Healthcare Administration and Finance Degree",
  "Healthcare Administration and Marketing Degree",
  "Healthcare Administration and Consulting Degree",
  "Healthcare Administration and Project Management Degree",
  "Healthcare Administration and Operations Management Degree",
  "Healthcare Administration and Supply Chain Management Degree",
  "Healthcare Administration and Logistics Degree",
  "Healthcare Administration and Facilities Management Degree",
  "Healthcare Administration and Real Estate Degree",
  "Healthcare Administration and Construction Management Degree",
  "Healthcare Policy and Management Degree",
  "Public Health Administration Degree",
  "Health Information Administration Degree",
  "Health Information Technology Degree",
  "Health Information Systems Degree",
  "Health Information Science Degree",
  "Health Information Technology Management Degree",
  "Health Information Technology Leadership Degree",
  "Health Information Technology and Informatics Degree",
  "Health Information Technology and Entrepreneurship Degree",
  "Health Information Technology and Finance Degree",
  "Health Information Technology and Marketing Degree",
  "Health Information Technology and Consulting Degree",
  "Health Information Technology and Project Management Degree",
  "Health Information Technology and Operations Management Degree",
  "Health Information Technology and Supply Chain Management Degree",
  "Health Information Technology and Logistics Degree",
  "Health Information Technology and Facilities Management Degree",
  "Health Information Technology and Real Estate Degree",
  "Health Information Technology and Construction Management Degree",
];



const districtsInIndia = [
  // Andaman and Nicobar Islands
  "Nicobar",
  "North and Middle Andaman",
  "South Andaman",

  // Andhra Pradesh
  "Anantapur",
  "Chittoor",
  "East Godavari",
  "Guntur",
  "Kadapa",
  "Krishna",
  "Kurnool",
  "Nellore",
  "Prakasam",
  "Srikakulam",
  "Visakhapatnam",
  "Vizianagaram",
  "West Godavari",

  // Arunachal Pradesh
  "Tawang",
  "West Kameng",
  "East Kameng",
  "Papum Pare",
  "Kurung Kumey",
  "Kra Daadi",
  "Lower Subansiri",
  "Upper Subansiri",
  "West Siang",
  "East Siang",
  "Siang",
  "Upper Siang",
  "Lower Siang",
  "Lower Dibang Valley",
  "Dibang Valley",
  "Anjaw",
  "Lohit",
  "Namsai",
  "Changlang",
  "Tirap",
  "Longding",

  // Assam
  "Baksa",
  "Barpeta",
  "Biswanath",
  "Bongaigaon",
  "Cachar",
  "Charaideo",
  "Chirang",
  "Darrang",
  "Dhemaji",
  "Dhubri",
  "Dibrugarh",
  "Goalpara",
  "Golaghat",
  "Hailakandi",
  "Hojai",
  "Jorhat",
  "Kamrup",
  "Kamrup Metropolitan",
  "Karbi Anglong",
  "Karimganj",
  "Kokrajhar",
  "Lakhimpur",
  "Majuli",
  "Morigaon",
  "Nagaon",
  "Nalbari",
  "Dima Hasao",
  "Sivasagar",
  "Sonitpur",
  "South Salmara-Mankachar",
  "Tinsukia",
  "Udalguri",
  "West Karbi Anglong",

  // Bihar
  "Araria",
  "Arwal",
  "Aurangabad",
  "Banka",
  "Begusarai",
  "Bhagalpur",
  "Bhojpur",
  "Buxar",
  "Darbhanga",
  "East Champaran (Motihari)",
  "Gaya",
  "Gopalganj",
  "Jamui",
  "Jehanabad",
  "Kaimur (Bhabua)",
  "Katihar",
  "Khagaria",
  "Kishanganj",
  "Lakhisarai",
  "Madhepura",
  "Madhubani",
  "Munger (Monghyr)",
  "Muzaffarpur",
  "Nalanda",
  "Nawada",
  "Patna",
  "Purnia (Purnea)",
  "Rohtas",
  "Saharsa",
  "Samastipur",
  "Saran",
  "Sheikhpura",
  "Sheohar",
  "Sitamarhi",
  "Siwan",
  "Supaul",
  "Vaishali",
  "West Champaran",

  // Chandigarh (Union Territory)
  "Chandigarh",

  // Chhattisgarh
  "Balod",
  "Baloda Bazar",
  "Balrampur",
  "Bastar",
  "Bemetara",
  "Bijapur",

  "Dantewada (South Bastar)",
  "Dhamtari",
  "Durg",
  "Gariyaband",
  "Janjgir-Champa",
  "Jashpur",
  "Kabirdham (Kawardha)",
  "Kanker (North Bastar)",
  "Kondagaon",
  "Korba",
  "Koriya",
  "Mahasamund",
  "Mungeli",
  "Narayanpur",
  "Raigarh",
  "Raipur",
  "Rajnandgaon",
  "Sukma",
  "Surajpur",
  "Surguja",

  // Dadra and Nagar Haveli and Daman and Diu (Union Territory)
  "Dadra and Nagar Haveli",
  "Daman",
  "Diu",

  // Delhi (National Capital Territory)
  "Central Delhi",
  "East Delhi",
  "New Delhi",
  "North Delhi",
  "North East Delhi",
  "North West Delhi",
  "Shahdara",
  "South Delhi",
  "South East Delhi",
  "South West Delhi",
  "West Delhi",

  // Goa
  "North Goa",
  "South Goa",

  // Gujarat
  "Ahmedabad",
  "Amreli",
  "Anand",
  "Aravalli",
  "Banaskantha (Palanpur)",
  "Bharuch",
  "Bhavnagar",
  "Botad",
  "Chhota Udepur",
  "Dahod",
  "Dangs (Ahwa)",
  "Devbhoomi Dwarka",
  "Gandhinagar",
  "Gir Somnath",
  "Jamnagar",
  "Junagadh",
  "Kachchh",
  "Kheda (Nadiad)",
  "Mahisagar",
  "Mehsana",
  "Morbi",
  "Narmada (Rajpipla)",
  "Navsari",
  "Panchmahal (Godhra)",
  "Patan",
  "Porbandar",
  "Rajkot",
  "Sabarkantha (Himmatnagar)",
  "Surat",
  "Surendranagar",
  "Tapi (Vyara)",
  "Vadodara",
  "Valsad",

  // Haryana
  "Ambala",
  "Bhiwani",
  "Charkhi Dadri",
  "Faridabad",
  "Fatehabad",
  "Gurugram (Gurgaon)",
  "Hisar",
  "Jhajjar",
  "Jind",
  "Kaithal",
  "Karnal",
  "Kurukshetra",
  "Mahendragarh",
  "Nuh",
  "Palwal",
  "Panchkula",
  "Panipat",
  "Rewari",
  "Rohtak",
  "Sirsa",
  "Sonipat",
  "Yamunanagar",

  // Himachal Pradesh
  "Bilaspur",
  "Chamba",
  "Hamirpur",
  "Kangra",
  "Kinnaur",
  "Kullu",
  "Lahaul and Spiti",
  "Mandi",
  "Shimla",
  "Sirmaur (Sirmour)",
  "Solan",
  "Una",

  // Jammu and Kashmir
  "Anantnag",
  "Bandipore",
  "Baramulla",
  "Budgam",
  "Doda",
  "Ganderbal",
  "Jammu",
  "Kathua",
  "Kishtwar",
  "Kulgam",
  "Kupwara",
  "Pulwama",
  "Rajouri",
  "Ramban",
  "Reasi",
  "Samba",
  "Shopian",
  "Srinagar",
  "Udhampur",

  // Jharkhand
  "Bokaro",
  "Chatra",
  "Deoghar",
  "Dhanbad",
  "Dumka",
  "East Singhbhum",
  "Garhwa",
  "Giridih",
  "Godda",
  "Gumla",
  "Hazaribag",
  "Jamtara",
  "Khunti",
  "Koderma",
  "Latehar",
  "Lohardaga",
  "Pakur",
  "Palamu",
  "Ramgarh",
  "Ranchi",
  "Sahibganj",
  "Seraikela-Kharsawan",
  "Simdega",
  "West Singhbhum",

  // Karnataka
  "Bagalkot",
  "Ballari (Bellary)",
  "Belagavi (Belgaum)",
  "Bengaluru (Bangalore) Rural",
  "Bengaluru (Bangalore) Urban",
  "Bidar",
  "Chamarajanagar",
  "Chikballapur",
  "Chikkamagaluru (Chikmagalur)",
  "Chitradurga",
  "Dakshina Kannada",
  "Davangere",
  "Dharwad",
  "Gadag",
  "Hassan",
  "Haveri",
  "Kalaburagi (Gulbarga)",
  "Kodagu",
  "Kolar",
  "Koppal",
  "Mandya",
  "Mysuru (Mysore)",
  "Raichur",
  "Ramanagara",
  "Shivamogga (Shimoga)",
  "Tumakuru (Tumkur)",
  "Udupi",
  "Uttara Kannada (Karwar)",
  "Vijayapura (Bijapur)",
  "Yadgir",

  // Kerala
  "Alappuzha",
  "Ernakulam",
  "Idukki",
  "Kannur",
  "Kasaragod",
  "Kollam",
  "Kottayam",
  "Kozhikode",
  "Malappuram",
  "Palakkad",
  "Pathanamthitta",
  "Thiruvananthapuram",
  "Thrissur",
  "Wayanad",

  // Ladakh (Union Territory)
  "Kargil",
  "Leh",

  // Lakshadweep (Union Territory)
  "Agatti Island",
  "Amini",
  "Andrott",
  "Bithra",
  "Chetlat",
  "Kadmat",
  "Kalpeni",
  "Kavaratti",
  "Kiltan",
  "Minicoy",

  // Madhya Pradesh
  "Agar Malwa",
  "Alirajpur",
  "Anuppur",
  "Ashoknagar",
  "Balaghat",
  "Barwani",
  "Betul",
  "Bhind",
  "Bhopal",
  "Burhanpur",
  "Chhatarpur",
  "Chhindwara",
  "Damoh",
  "Datia",
  "Dewas",
  "Dhar",
  "Dindori",
  "Guna",
  "Gwalior",
  "Harda",
  "Hoshangabad",
  "Indore",
  "Jabalpur",
  "Jhabua",
  "Katni",
  "Khandwa",
  "Khargone",
  "Mandla",
  "Mandsaur",
  "Morena",
  "Narsinghpur",
  "Neemuch",
  "Panna",
  "Raisen",
  "Rajgarh",
  "Ratlam",
  "Rewa",
  "Sagar",
  "Satna",
  "Sehore",
  "Seoni",
  "Shahdol",
  "Shajapur",
  "Sheopur",
  "Shivpuri",
  "Sidhi",
  "Singrauli",
  "Tikamgarh",
  "Ujjain",
  "Umaria",
  "Vidisha",

  // Maharashtra
  "Ahmednagar",
  "Akola",
  "Amravati",

  "Beed",
  "Bhandara",
  "Buldhana",
  "Chandrapur",
  "Dhule",
  "Gadchiroli",
  "Gondia",
  "Hingoli",
  "Jalgaon",
  "Jalna",
  "Kolhapur",
  "Latur",
  "Mumbai City",
  "Mumbai Suburban",
  "Nagpur",
  "Nanded",
  "Nandurbar",
  "Nashik",
  "Osmanabad",
  "Palghar",
  "Parbhani",
  "Pune",
  "Raigad",
  "Ratnagiri",
  "Sangli",
  "Satara",
  "Sindhudurg",
  "Solapur",
  "Thane",
  "Wardha",
  "Washim",
  "Yavatmal",

  // Manipur
  "Bishnupur",
  "Chandel",
  "Churachandpur",
  "Imphal East",
  "Imphal West",
  "Jiribam",
  "Kakching",
  "Kamjong",
  "Kangpokpi",
  "Noney",
  "Pherzawl",
  "Senapati",
  "Tamenglong",
  "Tengnoupal",
  "Thoubal",
  "Ukhrul",

  // Meghalaya
  "East Garo Hills",
  "East Jaintia Hills",
  "East Khasi Hills",
  "North Garo Hills",
  "Ri Bhoi",
  "South Garo Hills",
  "South West Garo Hills",
  "South West Khasi Hills",
  "West Garo Hills",
  "West Jaintia Hills",
  "West Khasi Hills",

  // Mizoram
  "Aizawl",
  "Champhai",
  "Hnahthial",
  "Khawzawl",
  "Kolasib",
  "Lawngtlai",
  "Lunglei",
  "Mamit",
  "Saiha",
  "Saitual",
  "Serchhip",

  // Nagaland
  "Dimapur",
  "Kiphire",
  "Kohima",
  "Longleng",
  "Mokokchung",
  "Mon",
  "Peren",
  "Phek",
  "Tuensang",
  "Wokha",
  "Zunheboto",

  // Odisha
  "Angul",
  "Balangir",
  "Balasore",
  "Bargarh",
  "Bhadrak",
  "Boudh",
  "Cuttack",
  "Deogarh",
  "Dhenkanal",
  "Gajapati",
  "Ganjam",
  "Jagatsinghapur",
  "Jajpur",
  "Jharsuguda",
  "Kalahandi",
  "Kandhamal",
  "Kendrapara",
  "Kendujhar (Keonjhar)",
  "Khordha",
  "Koraput",
  "Malkangiri",
  "Mayurbhanj",
  "Nabarangpur",
  "Nayagarh",
  "Nuapada",
  "Puri",
  "Rayagada",
  "Sambalpur",
  "Subarnapur (Sonepur)",
  "Sundargarh",

  // Puducherry (Union Territory)
  "Karaikal",
  "Mahe",
  "Puducherry",
  "Yanam",

  // Punjab
  "Amritsar",
  "Barnala",
  "Bathinda",
  "Faridkot",
  "Fatehgarh Sahib",
  "Fazilka",
  "Ferozepur",
  "Gurdaspur",
  "Hoshiarpur",
  "Jalandhar",
  "Kapurthala",
  "Ludhiana",
  "Mansa",
  "Moga",
  "Muktsar",
  "Pathankot",
  "Patiala",
  "Rupnagar",
  "Sahibzada Ajit Singh Nagar (Mohali)",
  "Sangrur",
  "Shaheed Bhagat Singh Nagar (Nawanshahr)",
  "Sri Muktsar Sahib",
  "Tarn Taran",

  // Rajasthan
  "Ajmer",
  "Alwar",
  "Banswara",
  "Baran",
  "Barmer",
  "Bharatpur",
  "Bhilwara",
  "Bikaner",
  "Bundi",
  "Chittorgarh",
  "Churu",
  "Dausa",
  "Dholpur",
  "Dungarpur",
  "Hanumangarh",
  "Jaipur",
  "Jaisalmer",
  "Jalore",
  "Jhalawar",
  "Jhunjhunu",
  "Jodhpur",
  "Karauli",
  "Kota",
  "Nagaur",
  "Pali",
  "Pratapgarh",
  "Rajsamand",
  "Sawai Madhopur",
  "Sikar",
  "Sirohi",
  "Sri Ganganagar",
  "Tonk",
  "Udaipur",

  // Sikkim
  "East Sikkim",
  "North Sikkim",
  "South Sikkim",
  "West Sikkim",

  // Tamil Nadu
  "Ariyalur",
  "Chengalpattu",
  "Chennai",
  "Coimbatore",
  "Cuddalore",
  "Dharmapuri",
  "Dindigul",
  "Erode",
  "Kallakurichi",
  "Kanchipuram",
  "Kanyakumari",
  "Karur",
  "Krishnagiri",
  "Madurai",
  "Nagapattinam",
  "Namakkal",
  "Nilgiris",
  "Perambalur",
  "Pudukkottai",
  "Ramanathapuram",
  "Ranipet",
  "Salem",
  "Sivaganga",
  "Tenkasi",
  "Thanjavur",
  "Theni",
  "Thoothukudi",
  "Tiruchirappalli",
  "Tirunelveli",
  "Tirupathur",
  "Tiruppur",
  "Tiruvallur",
  "Tiruvannamalai",
  "Tiruvarur",
  "Vellore",
  "Viluppuram",
  "Virudhunagar",

  // Telangana
  "Adilabad",
  "Bhadradri Kothagudem",
  "Hyderabad",
  "Jagtial",
  "Jangaon",
  "Jayashankar Bhupalapally",
  "Jogulamba Gadwal",
  "Kamareddy",
  "Karimnagar",
  "Khammam",
  "Komaram Bheem",
  "Mahabubabad",
  "Mahbubnagar",
  "Mancherial",
  "Medak",
  "Medchal-Malkajgiri",
  "Mulugu",
  "Nagarkurnool",
  "Nalgonda",
  "Narayanpet",
  "Nirmal",
  "Nizamabad",
  "Peddapalli",
  "Rajanna Sircilla",
  "Rangareddy",
  "Sangareddy",
  "Siddipet",
  "Suryapet",
  "Vikarabad",
  "Wanaparthy",
  "Warangal Rural",
  "Warangal Urban",
  "Yadadri Bhuvanagiri",

  // Tripura
  "Dhalai",
  "Gomati",
  "Khowai",
  "North Tripura",
  "Sepahijala",
  "South Tripura",
  "Unakoti",
  "West Tripura",

  // Uttar Pradesh
  "Agra",
  "Aligarh",
  "Ambedkar Nagar",
  "Amethi (Chatrapati Sahuji Mahraj Nagar)",
  "Amroha (J.P. Nagar)",
  "Auraiya",
  "Ayodhya (Faizabad)",
  "Azamgarh",
  "Baghpat",
  "Bahraich",
  "Ballia",

  "Banda",
  "Barabanki",
  "Bareilly",
  "Basti",
  "Bhadohi",
  "Bijnor",
  "Budaun",
  "Bulandshahr",
  "Chandauli",
  "Chitrakoot",
  "Deoria",
  "Etah",
  "Etawah",
  "Farrukhabad",
  "Fatehpur",
  "Firozabad",
  "Gautam Buddha Nagar",
  "Ghaziabad",
  "Ghazipur",
  "Gonda",
  "Gorakhpur",

  "Hapur (Panchsheel Nagar)",
  "Hardoi",
  "Hathras",
  "Jalaun",
  "Jaunpur",
  "Jhansi",
  "Kannauj",
  "Kanpur Dehat",
  "Kanpur Nagar",
  "Kasganj",
  "Kaushambi",
  "Kushinagar (Padrauna)",
  "Lakhimpur - Kheri",
  "Lalitpur",
  "Lucknow",
  "Maharajganj",
  "Mahoba",
  "Mainpuri",
  "Mathura",
  "Mau",
  "Meerut",
  "Mirzapur",
  "Moradabad",
  "Muzaffarnagar",
  "Pilibhit",

  "Prayagraj (Allahabad)",
  "Raebareli",
  "Rampur",
  "Saharanpur",
  "Sambhal (Bhim Nagar)",
  "Sant Kabir Nagar",
  "Shahjahanpur",
  "Shamali (Prabuddh Nagar)",
  "Shravasti",
  "Siddharth Nagar",
  "Sitapur",
  "Sonbhadra",
  "Sultanpur",
  "Unnao",
  "Varanasi",

  // Uttarakhand
  "Almora",
  "Bageshwar",
  "Chamoli",
  "Champawat",
  "Dehradun",
  "Haridwar",
  "Nainital",
  "Pauri Garhwal",
  "Pithoragarh",
  "Rudraprayag",
  "Tehri Garhwal",
  "Udham Singh Nagar",
  "Uttarkashi",

  // West Bengal
  "Alipurduar",
  "Bankura",
  "Birbhum",
  "Cooch Behar",
  "Dakshin Dinajpur (South Dinajpur)",
  "Darjeeling",
  "Hooghly",
  "Howrah",
  "Jalpaiguri",
  "Jhargram",
  "Kalimpong",
  "Kolkata",
  "Malda",
  "Murshidabad",
  "Nadia",
  "North 24 Parganas",
  "Paschim Medinipur (West Medinipur)",
  "Purba Medinipur (East Medinipur)",
  "Purulia",
  "South 24 Parganas",
  "Uttar Dinajpur (North Dinajpur)",
];

const indianStates = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

module.exports = {
  religions,
  nationalities,
  communities,
  nationalPartyNames,
  maritalStatus,
  idProofs,
  countries,
  bloodgroup,
  collegesInIndia,
  degreeSpecializations,
  universitiesInIndia,
  degreeTypes,
  districtsInIndia,
  indianStates,
  education
}