import React, { useState, useEffect } from "react";
import { MdAdd } from "react-icons/md";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import "moment-duration-format";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import "../../Component/AddEducationDetails/School.css";
import "./Relationship.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import Swal from "sweetalert2";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import {
  FetchJobDetails,
  FetchJobRoleDetails,
  FetchJobSkillDetails,
  addJobDetails,
  updateJobDetails,
  deleteJobDetails,
} from "../../routes/profileRoutes";
import {
  preDesignations,
  postDesignations,
  industries,
  currencySymbols,
} from "./jobDetailsData";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "#fff",
  border: "0px",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
  height: "90vh",
  overflowY: "scroll",
};

const JobDetails = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;
  const candidate_id = authdata?.userid;
  const [jobDetails, setJobDetails] = useState([]);
  const [jobRoleDetails, setJobRoleDetails] = useState([]);
  const [jobSkillDetails, setJobSkillDetails] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedPrefixDesignation, setSelectedPrefixDesignation] =
    useState("");
  const [selectedPostfixDesignation, setSelectedPostfixDesignation] =
    useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const issueDateRef = useRef(null);
  const validityDateRef = useRef(null);
  const companyNameRef = useRef(null);
  const jobResponsibilitiesRef = useRef(null);
  const [verificationemail, setverificationemail] = useState("");

  const [otherPrefixDesignation, setOtherPrefixDesignation] = useState("");
  const [otherPostfixDesignation, setOtherPostfixDesignation] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");
  const [salary, setSalary] = useState("");
  const [totalSalary, setTotalSalary] = useState("");
  const [selectedSalaryCurrency, setSelectedSalaryCurrency] = useState("₹");
  const [selectedTotalSalaryCurrency, setSelectedTotalSalaryCurrency] =
    useState("₹");
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [new_issuedate, setnew_issuedate] = useState("");
  const [test, settest] = useState("");
  const [new_validity, setnew_validity] = useState("");
  const [test1, settest1] = useState("");
  const [joinDate, setJoinDate] = useState(null);
  const [relieveDate, setRelieveDate] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false); //to handle multiple form submission
  const [error, setError] = useState("");

  const handleverifyemail = (e) => {
    let val = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(val)) {
      setverificationemail(val);
      setError("");
    } else {
      setverificationemail(val);
      setError("Enter a valid email address");
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setJoinDate("");
    setRelieveDate("");
  };
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setSelectedJobDetail(null);
    resetFormFields();
  };
  const handleEdit = (jobDetail) => {
    setSelectedJobDetail(jobDetail);
    setEditMode(true);
    setOpen(true);
  };
  const handlePrefixDesignationChange = (event) => {
    const value = event.target.value;
    setSelectedPrefixDesignation(value);
    if (value !== "Other") {
      setOtherPrefixDesignation("");
    }
  };
  const handleOtherIndustryChange = (event) => {
    const value = event.target.value;
    setSelectedIndustry(value);
    if (value !== "Other") {
      setOtherIndustry("");
    }
  };
  const handlePostfixDesignationChange = (event) => {
    const value = event.target.value;
    setSelectedPostfixDesignation(value);
    if (value !== "Other") {
      setOtherPostfixDesignation("");
    }
  };
  const [otherRole, setOtherRole] = useState("");
  const [totalExperience, setTotalExperience] = useState("");

  const calculateTotalExperience = (joinDate, relieveDate) => {
    const issueDate = moment(joinDate, "DD-MM-YYYY");
    const validityDate = moment(relieveDate, "DD-MM-YYYY");

    const duration = moment.duration(validityDate.diff(issueDate));

    const years = validityDate.diff(issueDate, "years");
    const months = validityDate.diff(issueDate.add(years, "years"), "months");
    const days = validityDate.diff(issueDate.add(months, "months"), "days");

    let totalExperience = "";
    if (years > 0) {
      totalExperience += `${years} ${years === 1 ? "year" : "years"}`;
    }
    if (months > 0) {
      totalExperience += ` ${months} ${months === 1 ? "month" : "months"}`;
    }
    if (days > 0) {
      totalExperience += ` ${days} ${days === 1 ? "day" : "days"}`;
    }

    return totalExperience.trim();
  };

  const handleDateChange = (event) => {
    setJoinDate(event.value);
    const newJoinDate = moment(event.value, "DD-MM-YYYY").format("DD-MM-YYYY");

    setnew_issuedate(newJoinDate);
    issueDateRef.current = newJoinDate;

    const totalExperience = calculateTotalExperience(newJoinDate, relieveDate);
    setTotalExperience(totalExperience);
  };

  const handleDateChange1 = (event) => {
    const newRelieveDate = moment(event.value, "DD-MM-YYYY").format(
      "DD-MM-YYYY"
    );
    setRelieveDate(event.value);

    validityDateRef.current = newRelieveDate;
    setnew_validity(validityDateRef.current);
    // Calculate total experience
    const totalExperience = calculateTotalExperience(joinDate, newRelieveDate);
    setTotalExperience(totalExperience);
  };

  const minRelievingDate = joinDate ? new Date(joinDate.getTime()) : null;

  const resetFormFields = () => {
    setSelectedRole("");
    setSelectedSkills([]);
    setOtherRole("");
    setSelectedPrefixDesignation("");
    setSelectedPostfixDesignation("");
    setOtherPrefixDesignation("");
    setOtherPostfixDesignation("");
    setSelectedIndustry("");
    setOtherIndustry("");
    setSalary("");
    setTotalSalary("");
    setSelectedSalaryCurrency("₹");
    setSelectedTotalSalaryCurrency("₹");
    setValidationErrors({});
    setTotalExperience("");
    setverificationemail("");
    setError("");
  };

  const handleRoleChange = (event) => {
    const value = event.target.value;
    setSelectedRole(value);
    if (value !== "Other") {
      setOtherRole("");
    }
  };

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelectedJobDetail(rowData);
      menuLeft.current.toggle(event);
    };
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (jobDetails) {
      setSerialNumbers(
        Array.from({ length: jobDetails.length }, (_, index) => index + 1)
      );
    }
  }, [jobDetails]);

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await deleteJobDetails(rowData.jobDetails_id);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        fetchData();
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  const menuLeft = useRef(null);
  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selectedJobDetail),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selectedJobDetail),
    },
  ];

  const fetchData = async () => {
    try {
      const jobDetailsResponse = await FetchJobDetails(userId);
      if (jobDetailsResponse && jobDetailsResponse.data) {
        setJobDetails(jobDetailsResponse.data);
      } else {
        console.error("Invalid jobDetailsResponse:", jobDetailsResponse);
      }
    } catch (error) {
      console.error("Failed to fetch job details:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchRoleData = async () => {
    try {
      const jobRoleDetailsResponse = await FetchJobRoleDetails();

      if (jobRoleDetailsResponse && jobRoleDetailsResponse) {
        setJobRoleDetails(jobRoleDetailsResponse);
      } else {
        console.error("Invalid jobDetailsResponse:", jobRoleDetailsResponse);
      }
    } catch (error) {
      console.error("Failed to fetch job details:", error);
    }
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  const fetchSkilData = async () => {
    try {
      const jobRoleDetailsResponse = await FetchJobSkillDetails();
      if (jobRoleDetailsResponse && jobRoleDetailsResponse) {
        setJobSkillDetails(jobRoleDetailsResponse);
      } else {
        console.error("Invalid jobDetailsResponse:", jobRoleDetailsResponse);
      }
    } catch (error) {
      console.error("Failed to fetch job details:", error);
    }
  };

  useEffect(() => {
    fetchSkilData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);

    const errors = {};
    if (!selectedRole) {
      errors.selectedRole = "Please select a role";
    }
    if (selectedRole === "Other" && !otherRole) {
      errors.otherRole = "Please enter an other role";
    }
    if (!issueDateRef.current) {
      errors.issueDate = "Please select an issue date";
    }
    if (!validityDateRef.current) {
      errors.validityDate = "Please select a validity date";
    }
    if (!companyNameRef.current.value) {
      errors.companyName = "Please enter a company name";
    }
    if (!jobResponsibilitiesRef.current.value) {
      errors.jobResponsibilities = "Please enter job responsibilities";
    }
    if (selectedSkills.length === 0) {
      errors.selectedSkills = "Please select at least one skill";
    }
    if (selectedPrefixDesignation === "Other" && !otherPrefixDesignation) {
      errors.otherPrefixDesignation = "Please enter an other PrefixDesignation";
    }
    if (selectedPostfixDesignation === "Other" && !otherPostfixDesignation) {
      errors.otherPostfixDesignation =
        "Please enter an other PostfixDesignation";
    }
    if (!selectedIndustry) {
      errors.selectedIndustry = "Please select a selected Industry";
    }
    if (selectedIndustry === "Other" && !otherIndustry) {
      errors.otherIndustry = "Please enter an industry";
    }
    if (!salary) {
      errors.salary = "Please select a salary";
    }
    if (!totalSalary) {
      errors.totalSalary = "Please select a totalSalary";
    }
    // if (Object.keys(errors).length > 0) {
    //   setValidationErrors(errors);
    //   return;
    // }
    const errorMessages = Object.values(errors);

    if (errorMessages.length > 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out All Required Fields",
        showConfirmButton: false,
        timer: 2000,
      });
      return handleClose();
    }
    if (editMode && selectedJobDetail) {
      const updatedJobDetails = {
        jobDetails_id: selectedJobDetail.jobDetails_id,
        jobDetails_issue_date: issueDateRef.current,
        jobDetails_validity_date: validityDateRef.current,
        jobDetails_company_name: companyNameRef.current.value,
        job_role_id: selectedRole === "Other" ? selectedRole : selectedRole,
        prefixDesignation:
          selectedPrefixDesignation === "Other"
            ? selectedPrefixDesignation
            : selectedPrefixDesignation,
        postfixDesignation:
          selectedPostfixDesignation === "Other"
            ? selectedPostfixDesignation
            : selectedPostfixDesignation,
        jobDetails_skills_id: selectedSkills
          .map((skill) => skill.value)
          .join(","),
        JobResponsibilities: jobResponsibilitiesRef.current.value,
        userid: userId,
        otherRole: otherRole,
        otherPrefixDesignation: otherPrefixDesignation,
        otherPostfixDesignation: otherPostfixDesignation,
        industry:
          selectedIndustry === "Other" ? selectedIndustry : selectedIndustry,
        otherIndustry: otherIndustry,
        salary: salary,
        salaryCurrency: selectedSalaryCurrency,
        totalSalary: totalSalary,
        totalSalaryCurrency: selectedTotalSalaryCurrency,
        workExperience: totalExperience,
        verificationemail: verificationemail,
      };

      try {
        const result = await updateJobDetails(
          selectedJobDetail.jobDetails_id,
          updatedJobDetails
        );
        if (result) {
          fetchData();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } catch (error) {
        console.error("Failed to update job details:", error);
      }
    } else {
      const jobDetails = {
        jobDetails_issue_date: new_issuedate,
        jobDetails_validity_date: new_validity,
        jobDetails_company_name: companyNameRef.current.value,
        job_role_id: selectedRole,
        jobDetails_skills_id: selectedSkills
          .map((skill) => skill.value)
          .join(","),
        JobResponsibilities: jobResponsibilitiesRef.current.value,
        userid: userId,
        candidate_id: candidate_id,
        otherRole: selectedRole === "Other" ? otherRole : "",
        prefixDesignation: selectedPrefixDesignation,
        postfixDesignation: selectedPostfixDesignation,
        otherPrefixDesignation:
          selectedPrefixDesignation === "Other" ? otherPrefixDesignation : "",
        otherPostfixDesignation:
          selectedPostfixDesignation === "Other" ? otherPostfixDesignation : "",
        industry: selectedIndustry,
        otherIndustry: selectedIndustry === "Other" ? otherIndustry : "",
        salary: salary,
        salaryCurrency: selectedSalaryCurrency,
        totalSalary: totalSalary,
        totalSalaryCurrency: selectedTotalSalaryCurrency,
        workExperience: totalExperience,
        verificationemail: verificationemail,
      };
      try {
        const response = await addJobDetails(jobDetails);
        if (response) {
          fetchData();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Job Details Details Inserted Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          setValidationErrors({});
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } catch (error) {
        console.error("Failed to add job details:", error);
      }
    }
    setIsSubmitting(false);
    setValidationErrors({});
    handleClose();
  };

  const handleSkillChange = (selectedOptions) => {
    const selectedSkills = selectedOptions.map((option) => ({
      value: option.value,
      label: option.label,
    }));
    setSelectedSkills(selectedSkills);
  };
  useEffect(() => {
    if (editMode && selectedJobDetail) {
      setSelectedRole(selectedJobDetail.job_role_id);
      setOtherRole(selectedJobDetail.otherRole);
      setSelectedPrefixDesignation(selectedJobDetail.prefixDesignation);
      setSelectedPostfixDesignation(selectedJobDetail.postfixDesignation);
      setOtherPrefixDesignation(selectedJobDetail.otherPrefixDesignation || "");
      setOtherPostfixDesignation(
        selectedJobDetail.otherPostfixDesignation || ""
      );
      setSalary(selectedJobDetail.salary);
      setSelectedSalaryCurrency(selectedJobDetail.salaryCurrency);
      setTotalSalary(selectedJobDetail.totalSalary);
      setSelectedTotalSalaryCurrency(selectedJobDetail.totalSalaryCurrency);
      setSelectedIndustry(selectedJobDetail.industry);
      setOtherIndustry(selectedJobDetail.otherIndustry || "");
      setTotalExperience(selectedJobDetail.workExperience || "");
      setverificationemail(selectedJobDetail.verificationemail || "");

      const value1 = selectedJobDetail.jobDetails_issue_date;

      let startYear = moment(value1, "DD-MM-YYYY").toDate();
      setJoinDate(startYear);

      const value2 = selectedJobDetail.jobDetails_validity_date;
      let startYears = moment(value2, "DD-MM-YYYY").toDate();
      setRelieveDate(startYears);

      setSelectedSkills(
        selectedJobDetail.jobDetails_skills_id.split(",").map((skillId) => {
          const skill = jobSkillDetails.find(
            (skill) => skill.jobDetails_skills_id === parseInt(skillId)
          );

          return {
            value: skillId,
            label: skill ? skill.jobDetails_skills_name : "",
          };
        })
      );

      issueDateRef.current = selectedJobDetail.jobDetails_issue_date;
      validityDateRef.current = selectedJobDetail.jobDetails_validity_date;

      if (companyNameRef.current) {
        companyNameRef.current.value =
          selectedJobDetail.jobDetails_company_name;
      }
      if (jobResponsibilitiesRef.current) {
        jobResponsibilitiesRef.current.value =
          selectedJobDetail.JobResponsibilities;
      }
    }
  }, [editMode, selectedJobDetail, jobSkillDetails]);

  return (
    <div>
      <div className=" mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Job Details</h5>
          <p>
            <Button onClick={handleOpen}>
              <MdAdd size={22} />
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="w-23rem md:w-30rem scrollBarHidden">
                <h6>{editMode ? "Edit Job Details" : "Add Job Details"}</h6>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="issuingAuthority"
                  >
                    Company Name
                  </label>
                  <input
                    placeholder="Company Name"
                    type="text"
                    id="companyName"
                    name="companyName"
                    className="form-control"
                    ref={companyNameRef}
                    defaultValue={
                      selectedJobDetail
                        ? selectedJobDetail.jobDetails_company_name
                        : ""
                    }
                  />
                  {validationErrors.companyName && (
                    <div className="text-danger">
                      {validationErrors.companyName}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="issuingAuthority"
                  >
                    Designation
                  </label>
                  <select
                    id="role"
                    value={selectedRole}
                    className="form-select"
                    onChange={handleRoleChange}
                  >
                    <option value="">Select...</option>
                    {jobRoleDetails
                      .sort((a, b) =>
                        a.job_role_name.localeCompare(b.job_role_name)
                      )
                      .map((role, index) => (
                        <option key={index} value={role.job_role_id}>
                          {role.job_role_name}
                        </option>
                      ))}
                    <option value="Other">Other</option>
                  </select>
                  {validationErrors.selectedRole && (
                    <div className="text-danger">
                      {validationErrors.selectedRole}
                    </div>
                  )}
                  {selectedRole === "Other" && (
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter other role"
                        value={otherRole}
                        onChange={(e) => setOtherRole(e.target.value)}
                      />
                      {validationErrors.otherRole && (
                        <div className="text-danger">
                          {validationErrors.otherRole}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="prefixDesignation"
                  >
                    Prefix Designation
                  </label>
                  <select
                    className="form-select"
                    id="prefixDesignation"
                    value={selectedPrefixDesignation}
                    onChange={handlePrefixDesignationChange}
                  >
                    <option value="">Select Prefix Designation</option>
                    {preDesignations?.map((preDesignation, index) => (
                      <option key={index} value={preDesignation}>
                        {preDesignation}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </select>
                  {selectedPrefixDesignation === "Other" && (
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter other prefix designation"
                        value={otherPrefixDesignation}
                        onChange={(e) =>
                          setOtherPrefixDesignation(e.target.value)
                        }
                      />
                      {validationErrors.otherPrefixDesignation && (
                        <div className="text-danger">
                          {validationErrors.otherPrefixDesignation}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="postfixDesignation"
                  >
                    Postfix Designation
                  </label>
                  <select
                    className="form-select"
                    id="postfixDesignation"
                    value={selectedPostfixDesignation}
                    onChange={handlePostfixDesignationChange}
                  >
                    <option value="">Select Postfix Designation</option>
                    {postDesignations?.map((postDesignation, index) => (
                      <option key={index} value={postDesignation}>
                        {postDesignation}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </select>
                  {selectedPostfixDesignation === "Other" && (
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter other postfix designation"
                        value={otherPostfixDesignation}
                        onChange={(e) =>
                          setOtherPostfixDesignation(e.target.value)
                        }
                      />
                      {validationErrors.otherPostfixDesignation && (
                        <div className="text-danger">
                          {validationErrors.otherPostfixDesignation}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="Designation"
                  >
                    Industry{" "}
                  </label>
                  <select
                    className="form-select"
                    value={selectedIndustry}
                    onChange={handleOtherIndustryChange}
                  >
                    <option value="">Select an industry</option>
                    {industries.map((industry, idx) => (
                      <option key={idx} value={industry}>
                        {industry}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </select>
                  {validationErrors.selectedIndustry && (
                    <div className="text-danger">
                      {validationErrors.selectedIndustry}
                    </div>
                  )}
                  {selectedIndustry === "Other" && (
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter other industry"
                        value={otherIndustry}
                        onChange={(e) => setOtherIndustry(e.target.value)}
                      />
                      {validationErrors.otherIndustry && (
                        <div className="text-danger">
                          {validationErrors.otherIndustry}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="issuingAuthority"
                  >
                    Select skills
                  </label>
                  <Select
                    value={selectedSkills.map((skillId) => skillId)}
                    isMulti
                    name="skills"
                    options={jobSkillDetails
                      .map((skill) => ({
                        value: skill.jobDetails_skills_id,
                        label: skill.jobDetails_skills_name,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    className="basic-multi-select form-control p-0"
                    classNamePrefix="select"
                    onChange={handleSkillChange}
                  />
                  {validationErrors.selectedSkills && (
                    <div className="text-danger">
                      {validationErrors.selectedSkills}
                    </div>
                  )}
                </div>

                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="issuingAuthority"
                  >
                    Job Responsibilities
                  </label>
                  <input
                    placeholder="Job Responsibilities"
                    type="text"
                    id="jobResponsibilities"
                    name="jobResponsibilities"
                    className="form-control"
                    ref={jobResponsibilitiesRef}
                    defaultValue={
                      selectedJobDetail
                        ? selectedJobDetail.JobResponsibilities
                        : ""
                    }
                  />
                  {validationErrors.jobResponsibilities && (
                    <div className="text-danger">
                      {validationErrors.jobResponsibilities}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label htmlFor="currency" className="labels mt-2 mb-1">
                    Salary Per Month
                  </label>
                  <div className="flex mt-2">
                    <select
                      id="salaryCurrency"
                      className="form-control"
                      value={selectedSalaryCurrency}
                      onChange={(e) =>
                        setSelectedSalaryCurrency(e.target.value)
                      }
                      style={{
                        width: "35px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "0px",
                      }}
                    >
                      {currencySymbols.map((currencySymbol, index) => (
                        <option key={index} value={currencySymbol}>
                          {currencySymbol}
                        </option>
                      ))}
                    </select>
                    <InputNumber
                      inputId="currency-india"
                      placeholder="Salary"
                      name="vehicleprice"
                      className="w-full "
                      style={{ height: "38px" }}
                      value={salary}
                      onValueChange={(e) => {
                        setSalary(e.target.value);
                      }}
                      currency="INR"
                      currencyDisplay="code"
                      locale="en-IN"
                    />
                  </div>
                  {validationErrors.salary && (
                    <div className="text-danger">{validationErrors.salary}</div>
                  )}
                </div>
                <div className=" ">
                  <label htmlFor="currency" className="labels mt-2 mb-1">
                    Total Salary Earned
                  </label>
                  <div className="flex mt-2">
                    <select
                      id="totalSalaryCurrency"
                      className="form-control"
                      value={selectedTotalSalaryCurrency}
                      onChange={(e) =>
                        setSelectedTotalSalaryCurrency(e.target.value)
                      }
                      style={{
                        width: "35px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "0px",
                      }}
                    >
                      {currencySymbols.map((currencySymbol, index) => (
                        <option key={index} value={currencySymbol}>
                          {currencySymbol}
                        </option>
                      ))}
                    </select>

                    <InputNumber
                      inputId="currency-india"
                      placeholder="Total Salary"
                      name="vehicleprice"
                      className="w-full "
                      style={{ height: "38px" }}
                      value={totalSalary}
                      onValueChange={(e) => {
                        setTotalSalary(e.target.value);
                      }}
                      currency="INR"
                      currencyDisplay="code"
                      locale="en-IN"
                    />
                  </div>
                  {validationErrors.totalSalary && (
                    <div className="text-danger">
                      {validationErrors.totalSalary}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label className="form-label labels mt-2" htmlFor="issueDate">
                    Date of Joining:
                  </label>
                  <Calendar
                    id="issueDate"
                    className="w-full date1"
                    showIcon={false}
                    name="issueDate"
                    monthNavigator
                    placeholder="Date of Joining"
                    yearNavigator
                    yearRange="1500:2050"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    style={{ height: "43px" }}
                    onChange={handleDateChange}
                    // value={test}
                    value={joinDate}
                  />

                  {validationErrors.issueDate && (
                    <div className="text-danger">
                      {validationErrors.issueDate}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="validityDate"
                  >
                    Date Of Relieving
                  </label>

                  <Calendar
                    id="dateofrelieving"
                    className="w-full date1"
                    showIcon={false}
                    name="dateofrelieving"
                    monthNavigator
                    placeholder="Date of Relieving"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    minDate={minRelievingDate}
                    // value={test1}
                    value={relieveDate}
                    onChange={handleDateChange1}
                    style={{
                      width: "88%",
                      height: "42px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      border: "none",
                      borderRadius: "6px",
                    }}
                  />
                  {validationErrors.validityDate && (
                    <div className="text-danger">
                      {validationErrors.validityDate}
                    </div>
                  )}
                </div>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="Total Month"
                  >
                    Work Experience
                  </label>
                  <input
                    type="text"
                    id="Total Month"
                    className="form-control"
                    name="Total Month"
                    value={totalExperience}
                    style={{ height: "39px" }}
                    readOnly
                  />
                </div>
                <div className=" ">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="issuingAuthority"
                  >
                    Company Hr or Verification Email
                  </label>
                  <input
                    placeholder="Verification Email "
                    type="text"
                    id="verificationemail"
                    name="verificationemail"
                    className="form-control"
                    value={verificationemail}
                    onChange={handleverifyemail}
                    defaultValue={
                      selectedJobDetail
                        ? selectedJobDetail.verificationemail
                        : ""
                    }
                  />
                  <p className="text-danger">{error}</p>
                </div>
                <div className="text-center mt-3">
                  <button className="btn btn-danger me-2" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value="add"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </button>
                  {/* <button
                    type="submit"
                    value="add"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    
                  >
                   Submit
                  </button> */}
                </div>
              </Box>
            </Modal>
          </p>
        </div>
        <div className="expand mt-2">
          <DataTable value={jobDetails} tableStyle={{ minWidth: "50rem" }}>
            <Column
              header="S.No"
              body={(rowData) => <span>{jobDetails.indexOf(rowData) + 1}</span>}
            ></Column>

            <Column
              field="jobDetails_company_name"
              style={{ width: "15%", minWidth: "141px" }}
              header="Company Name"
            ></Column>
            <Column
              field="job_role_id"
              style={{ width: "15%", minWidth: "141px" }}
              header="Designation"
              body={(rowData) => {
                const jobRoleId = Number(rowData.job_role_id);
                const jobRole = jobRoleDetails.find(
                  (role) => role.job_role_id === jobRoleId
                );
                const prefix = rowData.prefixDesignation
                  ? `${rowData.prefixDesignation} `
                  : "";
                const postfix = rowData.postfixDesignation
                  ? ` ${rowData.postfixDesignation}`
                  : "";
                return jobRole
                  ? `${prefix}${jobRole.job_role_name}${postfix}`
                  : rowData.otherRole;
              }}
            ></Column>
            <Column
              field="industry"
              style={{ width: "15%", minWidth: "141px" }}
              header="Industry"
              body={(rowData) => {
                if (rowData.industry === "Other") {
                  return rowData.otherIndustry;
                } else {
                  return rowData.industry;
                }
              }}
            ></Column>
            <Column
              field="jobDetails_skills_id"
              style={{ width: "15%", minWidth: "141px" }}
              header="Skills"
              body={(rowData) => {
                const jobSkillIds = rowData.jobDetails_skills_id
                  .split(",")
                  .map(Number);
                const jobSkills = jobSkillDetails
                  .filter((skill) =>
                    jobSkillIds.includes(skill.jobDetails_skills_id)
                  )
                  .map((skill) => skill.jobDetails_skills_name);

                return jobSkills.length > 0
                  ? jobSkills.join(", ")
                  : "Unknown Skills";
              }}
            ></Column>
            <Column
              field="JobResponsibilities"
              header="Job Responsibilities"
              style={{ width: "18%", minWidth: "161px" }}
            ></Column>
            <Column
              field="jobDetails_issue_date"
              style={{ width: "15%", minWidth: "141px" }}
              header="Date Of Joining"
            ></Column>
            <Column
              field="jobDetails_validity_date"
              style={{ width: "15%", minWidth: "141px" }}
              header="Date Of Relieving"
            ></Column>
            <Column
              field="workExperience"
              header="Work Experience"
              style={{ width: "15%", minWidth: "141px" }}
            ></Column>
            <Column
              field="verificationemail"
              header="HR Verification Email"
              style={{ width: "15%", minWidth: "141px" }}
            ></Column>
            <Column
              body={(rowData) => actions(rowData)}
              header="Actions"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;