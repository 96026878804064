// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-datepicker {
  z-index: 3000 !important;
}

.date_data {
  width: 45%;
  height: 52px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  margin-top: 175px;
  margin-left: 296px;
}

`, "",{"version":3,"sources":["webpack://./src/Component/Profile/Vehicle.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,4BAA4B;EAC5B,+BAA+B;EAC/B,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".p-datepicker {\n  z-index: 3000 !important;\n}\n\n.date_data {\n  width: 45%;\n  height: 52px;\n  border-top-right-radius: 4px;\n  border-bottom-right-radius: 4px;\n  border: none;\n  margin-top: 175px;\n  margin-left: 296px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
