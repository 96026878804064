import React, { useState } from 'react';
import { Typography, Box, Divider, Avatar, Menu, IconButton, MenuItem, Button } from '@mui/material';
import { ASSETS_API, CINE_JOB_API } from '../../../../config/api';
import { CiMail } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import moment from 'moment';
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import axios from 'axios';

const CineJobUserCard = ({ usersData, createdAt, jobId, fetchData, status }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setCurrentUserId(userId);
  };

  const menuItems = [
    { status: 'shortlisted', label: 'Move to Shortlisted' },
    { status: 'onHold', label: 'Move to On Hold' },
    { status: 'interviewed', label: 'Move to Interviewed' },
    { status: 'hire', label: 'Move to Hire' },
  ];

  const isActionAllowed = (actionStatus) => {
    const allowedTransitions = {
      applied: ['shortlisted', 'onHold', 'interviewed', 'hire'],
      shortlisted: ['onHold', 'interviewed', 'hire'],
      onHold: ['interviewed', 'hire'],
      interviewed: ['hire'],
      hire: [],
    };
    return allowedTransitions[status]?.includes(actionStatus);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAction = (status) => {
    axios.post(CINE_JOB_API.UPDATE_STATUS_APPLIED, { jobAppliedUserId: currentUserId, status, jobId })
      .then(() => fetchData())
      .catch(error => console.error('Error updating status:', error));
    handleClose();
  };


  const getColor = (count) => {
    if (count === 0) return { color: '#FF763C', backgroundColor: '#FFEFE8' };
    if (count <= 40) return { color: '#EBAB05', backgroundColor: '#FAF6CD' };
    if (count <= 75) return { color: '#EBAB05', backgroundColor: '#FAF6CD' };
    return { color: '#0BAB66', backgroundColor: '#C8F4E1' };
  };

  const groupByDate = (posts) => {
    return posts?.reduce((acc, post) => {
      const date = moment(post?.statusDate).format('YYYY-MM-DD');
      if (!acc[date]) acc[date] = [];
      acc[date].push(post);
      return acc;
    }, {}) || {};
  };

  const sortedDates = Object.keys(groupByDate(usersData || [])).sort((a, b) => moment(b).diff(moment(a)));

  const groupedUsers = sortedDates.reduce((acc, date) => {
    acc[date] = groupByDate(usersData || [])[date];
    return acc;
  }, {});

  const handleCardClick = (userData, createdAt) => {
    navigate(`/CineJob/Profile`, { state: { userData, createdAt } });
  };
  const getStatusColor = (status) => {
    switch (status) {
      case 'applied':
        return '#17A2B8';
      case 'shortlisted':
        return '#07c271';
      case 'onHold':
        return '#EEC006';
      case 'interviewed':
        return '#9554FF';
      case 'hire':
        return '#07C271';
      default:
        return '#8b8b8b';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 2
      }}
    >
      {Object.entries(groupedUsers).map(([date, users]) => {
        const isToday = moment(date).isSame(moment(), 'day');
        return (
          <Box key={date}>
            <Typography
              variant="body2"
              fontWeight={300}
              mb={1}
              color="#8B8B8B"
              bgcolor={"#FAFAFA"}
              padding={1}
              borderRadius={"12px"}
              display="inline-block"
            >
              {isToday ? "Today" : moment(date).format('D MMMM YYYY')}
            </Typography>

            {users.map(userData => {
              const {
                profileCount,
                user: {
                  id,
                  name,
                  email,
                  mobile_no,
                  profile_image,
                },
                userStatus
              } = userData;

              const statusDate = userStatus[0]?.statusDate;
              const timeStringCustom = moment(statusDate.split("Z")[0]).format('MMM DD YYYY hh:mmA');

              const profileImageUrl = profile_image ? ASSETS_API.ASSETS_PROFILE_IMAGES(profile_image) : 'default-profile-image.jpg';

              const experienceText = userData.jobInfo?.work_experiance ? `${userData.jobInfo.work_experiance} yrs exp` : 'Not specified';
              const salaryText = userData.jobInfo?.salary_expectation ? `INR ${userData.jobInfo.salary_expectation}` : 'Not specified';
              const formattedString = `${experienceText} | ${salaryText}`;

              const addressParts = [
                userData.contactInfo?.taluk,
                userData.contactInfo?.district && `,${userData.contactInfo.district}`,
                userData.contactInfo?.pincode && `-${userData.contactInfo.pincode}`
              ].filter(Boolean);

              return (
                <Box
                  key={id}
                  bgcolor="#fff"
                  width="400px"
                  height="170px"
                  boxShadow="0px 1px 4px 0px #00000029"
                  p={2}
                  borderRadius={2}
                  position={"relative"}
                  mb={2}
                >
                  <Box display="flex" flexWrap={"wrap"} alignItems="center" gap={2}>
                    <Box
                      onClick={() => handleCardClick(userData, createdAt)}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{
                        height: 125,
                        width: 130,
                        borderRadius: '8px',
                        backgroundColor: '#EFF5FC',
                        padding: 8,
                        textAlign: 'center',
                        position: 'relative',
                        cursor: 'pointer'
                      }}
                    >
                      <Avatar
                        src={profileImageUrl}
                        alt=""
                        style={{
                          objectFit: 'contain',
                          width: 75,
                          height: 75,
                        }}
                      />
                      <Typography
                        style={{
                          textAlign: "center",
                          position: 'absolute',
                          top: 75,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          ...getColor(parseInt(profileCount, 10)),
                          padding: '4px 7px 3px 7px',
                          borderRadius: 2,
                          fontSize: '16px',
                          lineHeight: '10px'
                        }}
                      >
                        {profileCount}%
                      </Typography>
                      <Typography variant="body1" style={{ marginTop: 8 }}>
                        {name}
                      </Typography>
                    </Box>

                    <Box flex="1">
                      <Typography
                        variant="h6"
                        component="div"
                        fontWeight={500}
                        color="#2824df"
                        textTransform="capitalize"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: {
                            xs: 'calc(100% - 50px)',
                            sm: 'calc(100% - 100px)',
                            md: '100%'
                          }
                        }}
                      >
                        {userData.jobInfo?.current_role || "Role"}
                      </Typography>
                      <Typography
                        textTransform="capitalize"
                        variant="body2"
                        color="#4a4a4a"
                        mb={1}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: {
                            xs: 'calc(100% - 50px)',
                            md: '100%'
                          }
                        }}
                      >
                        {formattedString}
                      </Typography>
                      <Typography
                        textTransform="capitalize"
                        variant="body2"
                        color="#2196fc"
                        mb={1}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: {
                            xs: 'calc(100% - 50px)',
                            md: '100%'
                          }
                        }}
                      >
                        <CiMail /> {email}
                      </Typography>
                      <Typography
                        textTransform="capitalize"
                        variant="body2"
                        color="#2196fc"
                        mb={1}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: {
                            xs: 'calc(100% - 50px)',
                            md: '100%'
                          }
                        }}
                      >
                        <FiPhone /> {mobile_no}
                      </Typography>
                      <Box onClick={() => handleCardClick(userData, createdAt)} sx={{ cursor: 'pointer', position: 'absolute', top: 103, right: 40 }}>
                        <FaRegArrowAltCircleRight style={{ cursor: 'pointer', color: '#2196f3', width: '25px', height: '25px' }} />
                      </Box>
                    </Box>
                  </Box>

                  <Divider sx={{ my: .5 }} />
                  <Box position={"absolute"} top={0} right={0.5}>
                    <IconButton
                      aria-controls={open ? 'simple-menu' : undefined}
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, id)}
                      style={{
                        marginLeft: 'auto', color: "#BBB9B9"
                      }}
                    >
                      <BsThreeDotsVertical
                      />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && currentUserId === id}
                      onClose={handleClose}
                    >
                      {menuItems.map(({ status, label }) => (
                        isActionAllowed(status) && (
                          <MenuItem key={status} onClick={() => handleMenuAction(status)}>
                            {label}
                          </MenuItem>
                        )
                      ))}
                    </Menu>
                  </Box>

                  <Box display="flex" alignItems={"center"} justifyContent={"space-between"} gap={1}>
                    <Typography
                      textTransform="capitalize"
                      variant="body2"
                      sx={{
                        color: getStatusColor(status),
                        fontSize: "10px",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: 500,
                        maxWidth: {
                          xs: 'calc(100% - 50px)',
                          md: '100%'
                        }
                      }}
                    >
                      {status}
                    </Typography>
                    <Typography
                      textTransform="capitalize"
                      variant="body2"
                      sx={{
                        fontSize: "10px",
                        color: "#8b8b8b",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: {
                          xs: 'calc(100% - 50px)',
                          md: '100%'
                        }
                      }}
                    >
                      {addressParts.length > 0 ? (
                        <span>
                          <IoLocationOutline /> {addressParts.join(' ')}
                        </span>
                      ) : (
                        'Not specified'
                      )}
                    </Typography>
                    <Typography
                      textTransform="capitalize"
                      variant="body2"
                      sx={{
                        fontSize: "10px",
                        color: "#8b8b8b",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: {
                          xs: 'calc(100% - 50px)',
                          md: '100%'
                        }
                      }}
                    >
                      Applied at {timeStringCustom}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default CineJobUserCard;
