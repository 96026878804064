import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Nodeapi } from "../../../config/serverUrl";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { districtsInIndia } from "../../Profile/Dropdown_list";
import EditBloodRequest from "./EditBloodRequest";
import "../blooddonation.css";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IoIosClose } from "react-icons/io";
import { formatDate } from "../BloodCommon";

const bloodgroup = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const BloodRequestList = ({ fetchRequest, onFetchRequestUpdate }) => {
  const [bloodRequests, setBloodRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [editingId, setEditingId] = useState(null);
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;

  const navigate = useNavigate();
  const fetchBloodRequests = async () => {
    try {
      const response = await axios.get(`${Nodeapi}/blood-donation/requests`);
      setBloodRequests(response.data);
      onFetchRequestUpdate();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBloodRequests();
  }, [fetchRequest]);
  
  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
  };

  const handleBloodGroupChange = (event) => {
    setSelectedBloodGroup(event.target.value);
  };

  const handleClear = (field) => {
    if (field === "district") {
      setSelectedDistrict("");
    } else if (field === "bloodGroup") {
      setSelectedBloodGroup("");
    }
  };

  const filteredBloodRequests = bloodRequests.filter((request) => {
    return (
      request.userid == userId &&
      (selectedDistrict === "" || request.district === selectedDistrict) &&
      (selectedBloodGroup === "" || request.blood_group === selectedBloodGroup)
    );
  });

  const handleEdit = (id) => {
    setEditingId(id);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${Nodeapi}/blood-donation/request/${id}`);
        setBloodRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== id)
        );
        Swal.fire({
          title: "Deleted!",
          text: "The request has been deleted.",
          icon: "success",
        });
      } catch (error) {
        console.error("Error deleting the blood request:", error);
        Swal.fire({
          title: "Error!",
          text: "Failed to delete the request. Please try again later.",
          icon: "error",
        });
      }
    }
  };

  const handleCloseEditForm = () => {
    setEditingId(null);
  };

  const handleUpdate = () => {
    setEditingId(null);
    fetchBloodRequests();
  };

  const handleCellClick = (params, event) => {
    if (params.field !== "actions") {
      navigate(`/main/BloodRequestView/${params.row.id}`);
    }
  };
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  const statusStyles = {
    Created: {
      backgroundColor: "#EDF7FF",
      borderColor: "#2188E7",
      color: "#2188E7",
    },
    Approved: {
      backgroundColor: "#EDFFF3",
      borderColor: "#17A536",
      color: "#17A536",
    },
    Pending: {
      backgroundColor: "#fff3cd",
      borderColor: "#FFA502",
      color: "#FFA502",
    },
    Rejected: {
      backgroundColor: "#FFEDED",
      borderColor: "#E72121",
      color: "#E72121",
    },
    Default: {
      backgroundColor: "#ffffff",
      borderColor: "#e0e0e0",
      color: "#000000",
    },
  };
  const columns = [
    { field: "display_order", headerName: "ID", width: 90 },
    { field: "firstname", headerName: "Name", width: 150 },
    { field: "blood_group", headerName: "Blood Group", width: 130 },
    { field: "district", headerName: "District", width: 150 },
    { field: "date_required", headerName: "Date", width: 150 },
    { field: "urgency_level", headerName: "Urgency Level", width: 150 },
    { field: "mobile_no", headerName: "Contact", width: 150 },
    {
      field: "approval_status",
      headerName: "Approval Status",
      width: 150,
      renderCell: (params) => {
        const status = params.value;
        const { backgroundColor, borderColor, color } =
          statusStyles[status] || statusStyles.Default;

        return (
          <div
            style={{
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              color: color,
              width: "80px",
              padding: "4px",
              borderRadius: "4px",
              border: "1px solid",
              textAlign: "center",
            }}
          >
            {status}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        const isApproved = params.row.approval_status === "Approved";

        const handleEditClick = () => {
          if (isApproved) {
            Swal.fire({
              title: `Can't Edit`,
              text: `Request is already approved.
              You can unpost and post it again`,
              icon: "info",
              confirmButtonText: "OK",
            });
          } else {
            handleEdit(params.row.id);
          }
        };

        return (
          <div style={{ display: "flex", gap: "20px" }}>
            <span
              style={{
                color: isApproved ? "#B0B0B0" : "#2196F3",
                cursor: isApproved ? "not-allowed" : "pointer",
                fontSize: "18px",
              }}
              onClick={handleEditClick}
            >
              <FaEdit />
            </span>

            <span
              style={{ color: "#E72121", cursor: "pointer", fontSize: "18px" }}
              onClick={() => handleDelete(params.row.id)}
            >
              <FaTrash />
            </span>
          </div>
        );
      },
    },
  ];

  const rows = filteredBloodRequests.map((request, index) => ({
    display_order: index + 1,
    id: request.id,
    firstname: request.firstname,
    blood_group: request.blood_group,
    district: request.district,
    date_required: formatDate(request.to_date_required),
    urgency_level: request.urgency_level,
    mobile_no: request.mobile_no,
    approval_status: request.approval_status,
  }));

  const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    borderRadius: "12px",
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#2196F3",
      color: "#FFF",
      fontWeight: "500",
      fontSize: "16px",
      padding: "5px",
    },
    "& .MuiDataGrid-cell": {
      padding: "20px",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: theme.palette.grey[200],
    },
    "& .MuiDataGrid-toolbarContainer": {
      marginBottom: "16px",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  }));

  return (
    <div>
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          marginRight: "5px",
          gap: "10px",
        }}
      >
        <div
          style={{ position: "relative", flex: "1 1 100%", maxWidth: "350px" }}
        >
          <select
            className="form-select"
            name="district"
            id="district"
            value={selectedDistrict}
            onChange={handleDistrictChange}
          >
            <option value="">Select District</option>
            {districtsInIndia.map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
          </select>
          {selectedDistrict && (
            <IoIosClose
              onClick={() => handleClear("district")}
              style={{
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "30px",
              }}
            />
          )}
        </div>

        <div
          style={{ position: "relative", flex: "1 1 100%", maxWidth: "150px" }}
        >
          <select
            className="form-select"
            id="bloodGroup"
            name="bloodGroup"
            value={selectedBloodGroup}
            onChange={handleBloodGroupChange}
          >
            <option value="">Blood Group</option>
            {bloodgroup
              .sort((a, b) => a.localeCompare(b))
              .map((bg, index) => (
                <option key={index} value={bg}>
                  {bg}
                </option>
              ))}
          </select>
          {selectedBloodGroup && (
            <IoIosClose
              onClick={() => handleClear("bloodGroup")}
              style={{
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "30px",
              }}
            />
          )}
        </div>
      </div>
      <div
        style={{
          height: "calc(100vh - 250px)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {rows.length > 0 ? (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            onCellClick={handleCellClick}
            style={{ flex: 1 }}
          />
        ) : (
          <div className="no-request">No blood requests found</div>
        )}
      </div>
      {editingId && (
        <EditBloodRequest
          requestId={editingId}
          onClose={handleCloseEditForm}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

export default BloodRequestList;
