import React, { useEffect, useRef, useState } from "react";
import { IconButton, ImageList, ImageListItem, Box } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import { Assetsapi } from "../../config/serverUrl";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const LostAndFoundGallery = ({ imgValue }) => {
  const [activeStep, setActiveStep] = useState(0);
  const imageListRef = useRef(null);
  const theme = useTheme();
  const [isPaused, setIsPaused] = useState(false);
  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const baseUrl = `${Assetsapi}/adimages`;

  useEffect(() => {
    if (imgValue.length > 0) {
      setActiveStep(0);
    }
  }, [imgValue]);

  const handleImageClick = (index) => {
    setActiveStep(index);
  };

  const scroll = (direction) => {
    if (imageListRef.current) {
      const scrollAmount = direction === "left" ? -300 : 300;
      imageListRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const shouldCenter = imgValue.length <= 5;

  return (
    <div
      className="gallery-demo"
      style={{
        margin: "0 30px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        className="gallery-demo-card"
        style={{
          height: "410px",
          width: "100%", 
          maxWidth: "417px", 
          borderRadius: "5px",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center", 
          margin: "0 auto", 
        }}
      >
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={(index) => setActiveStep(index)}
          autoplay={!isPaused}
        >
          {imgValue.map((image, index) => (
            <div key={index}>
              {Math.abs(activeStep - index) <= 10 ? (
                <Box
                  component="img"
                  sx={{
                    height: 410,
                    display: "block",
                    maxWidth: "417px",
                    width: "100%",
                    borderRadius: 2,
                    objectFit: "contain", 
                    margin: "0 auto",
                  }}
                  src={`${baseUrl}/${image}`}
                  alt={`Item ${index + 1}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>
      <div style={{ position: "relative", marginTop: "16px" }}>
        <IconButton
          onClick={() => scroll("left")}
          style={{
            position: "absolute",
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            zIndex: "1",
          }}
        >
          <ArrowBackIos style={{ marginLeft: "3px" }} />
        </IconButton>
        <ImageList
          cols={8}
          gap={20}
          style={{
            padding: "0 10px 10px",
            display: "flex",
            justifyContent: shouldCenter ? "center" : "flex-start",
            height: "110px",
            overflowX: "hidden",
            overflowY: "hidden",
            whiteSpace: "nowrap",
          }}
          ref={imageListRef}
        >
          {imgValue.map((image, index) => (
            <ImageListItem key={index}>
              <img
                src={`${baseUrl}/${image}`}
                alt={`Item ${index + 1}`}
                style={{
                  width: "132px",
                  height: "100px",
                  borderRadius: "10px",
                  cursor: "pointer", 
                }}
                onMouseEnter={() => handleImageClick(index)}
              />
            </ImageListItem>
          ))}
        </ImageList>

        <IconButton
          onClick={() => scroll("right")}
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            zIndex: "1",
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </div>
    </div>
  );
};

export default LostAndFoundGallery;
