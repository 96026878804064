export const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  export const formatTimeTo12Hour = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const hours24 = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12;
    const minutesFormatted = minutes.toString().padStart(2, "0");
    return `${hours12}:${minutesFormatted} ${period}`;
  };

  export const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  export const bloodRequestFields = [
    { key: "blood_group", label: "Blood Group" },
    { key: "blood_component_type", label: "Blood Component" },
    { key: "units_required", label: "Units Required" },
    { key: "firstname", label: "First Name" },
    { key: "lastname", label: "Last Name" },
    { key: "dob", label: "Age" },
    { key: "gender", label: "Gender" },
    { key: "mobile_no", label: "Mobile No" },
    { key: "email", label: "Email" },
    { key: "place", label: "Place" },
    { key: "district", label: "District" },
    { key: "state", label: "State" },
    { key: "country", label: "Country" },
    { key: "pincode", label: "Pincode" },
    { key: "id_proof", label: "ID Proof" },
    { key: "patient_id", label: "Patient ID" },
    { key: "urgency_level", label: "Urgency Level" },
    { key: "from_date_required", label: "From Date Required" },
    { key: "to_date_required", label: "To Date Required" },
    { key: "to_time_required", label: "To Time Required" },
    { key: "reason_for_requirement", label: "Reason for Requirement" },
    { key: "patient_underwent_surgery", label: "Patient Underwent Surgery" },
    { key: "surgery_type", label: "Surgery Type" },
    { key: "ward_room_number", label: "Ward/Room Number" },
    { key: "doctor_name", label: "Doctor Name" },
    { key: "hospital_name", label: "Hospital Name" },
    { key: "donor_criteria", label: "Donor Criteria Instructions" },
  ];
  