import React, { useState, useContext } from "react";
import logo from "../../assets/images/Updated Logo.png";
import { GoBell } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../react-redux/slice";
import {
  RiArrowRightSLine,
  RiArrowUpSLine,
  RiLoginCircleLine,
} from "react-icons/ri";
import { BiLogOutCircle } from "react-icons/bi";
import { useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaUserShield } from "react-icons/fa6";
import avatar from "../../assets/Icons/busi-Avatar.svg";
import company_profile from "../../assets/sidebarImages/company_profile.svg";
import my_post from "../../assets/sidebarImages/myactivity.svg";
import my_activity from "../../assets/sidebarImages/my_activity.svg";
import advertisement from "../../assets/sidebarImages/Advertiesment.svg";
import sales_details from "../../assets/sidebarImages/Sales Details.svg";
import aadhaar_details from "../../assets/sidebarImages/Aadhar Details.svg";
import aadharr_verification from "../../assets/sidebarImages/Aadhar Verification.svg";
import myProfile from "../../assets/sidebarImages/myprofile.svg";
import account_activity from "../../assets/sidebarImages/Account activity.svg";
import account_sharing from "../../assets/sidebarImages/Account sharing.svg";
import admin_panel from "../../assets/sidebarImages/Admin panel.svg";
import user_list from "../../assets/sidebarImages/User List.svg";
import view_candidate from "../../assets/sidebarImages/view candidate.svg";
import account_access_icon from "../../assets/sidebarImages/Account Access.svg";
import classifiedIcon from "../../assets/sidebarImages/classifieds.svg";
import BloodDonation from "../../assets/sidebarImages/bloodDonation.svg";
import LostFound from "../../assets/sidebarImages/lost_found.svg";

import MyPost from "../../assets/sidebarImages/mypost_icon1.svg";
import ChartMain from "../../assets/sidebarImages/chat_icon.svg";
import BellIcon from "../../assets/sidebarImages/solar_bell-bold.png";
import ChatReport from "../../assets/sidebarImages/chat-report.png";
import Subscription from "../../assets/sidebarImages/Subscription_Icon.png";
import About_us from "../../assets/sidebarImages/About_us.png";
import Contact_us from "../../assets/sidebarImages/Contact_us.png";
import Cancellation from "../../assets/sidebarImages/Cancellation.png";
import Privacy from "../../assets/sidebarImages/Privacy&Policy.png";
import terms_condition from "../../assets/sidebarImages/Terms&Condition.png";
import { GoDotFill } from "react-icons/go";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import { useEffect } from "react";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import "./Side.css";
import io from "socket.io-client";
import { SocketApi } from "../../config/serverUrl";
import { DataContext } from "../../Admin/DataContext";
import { IoNewspaperOutline } from "react-icons/io5";
import { RiInformationLine } from "react-icons/ri";
import { Sidebar, SubMenu, Menu, MenuItem } from "react-pro-sidebar";
import { RiMovie2Fill } from "react-icons/ri";
import { GoReport } from "react-icons/go";
import { setActiveTab } from '../../react-redux/slices/postAddSlice';
import { RiHome4Line } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import { FiChevronsRight } from "react-icons/fi/";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { PiDotsNineBold } from "react-icons/pi";
import { Card } from "primereact/card";
import { Tooltip } from "primereact/tooltip";
import { MdOutlineClear } from "react-icons/md";
const socket = io(SocketApi);

const Sidebar_sample = ({ setSidebarOpen, toggleDrawer }) => {
  const toast = useRef(null);

  const authdata = useSelector((state) => state.auth.user);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [showadmin, setshowadmin] = useState(false);
  const [Role, setRole] = useState("");
  const navigate = useNavigate();
  const [Notification, setNotification] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [notify, setNotify] = useState(false);
  const [account_access, setaccount_access] = useState([]);
  const [display, setdisplay] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [access, setaccess] = useState(false);
  const overlayPanel = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const handleNotify = (event) => {
    overlayPanel.current.toggle(event);
  };

  const [openMenu, setOpenMenu] = useState(null);
  const [openMainMenu, setOpenMainMenu] = useState(null);

  const handleMenuClick = (menuName) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };
  const handleMainMenuClick = (menuName) => {
    setOpenMainMenu(openMenu === menuName ? null : menuName);
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (path) => {
    setSelectedItem(path);
    navigate(path);
    dispatch(setActiveTab("1"));
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaChange = (e) => {
      if (e.matches) {
        setDialogVisible(false);
      }
    };
    mediaQuery.addListener(handleMediaChange);
    if (mediaQuery.matches) {
      setDialogVisible(false);
    }
    return () => {
      mediaQuery.removeListener(handleMediaChange);
    };
  }, []);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const handleopen = () => {
    setOpenSubMenu(true);
  };

  const { setview_adpost, listcompanies, setlistcompanies, setaddfavorites } =
    useContext(DataContext);

  useEffect(() => {
    // fetchhrmsuser();
    fetchNotification();
    getaccess_account();
    getcompanyname();
  }, []);

  const getcompanyname = async () => {
    try {
      const res = await axios.get(
        `${Nodeapi}/getcompanyname?id=${authdata?.id}`
      );
      const name = res.data.getdata;

      if (Array.isArray(name)) {
        const companyNames = name.map((item) => item.companyname);
        setlistcompanies(companyNames);
      } else {
        console.error("Expected an array for datavalue, but got:", name);
      }
    } catch (error) {
      console.error("Error fetching company names:", error);
    }
  };

  const getaccess_account = async () => {
    const res = await axios.get(
      `${Nodeapi}/getaccess_account?kid_id=${authdata?.userid}`
    );
    const data = res.data.result;

    let val = data[0];
    setaccount_access(val);
  };

  // const fetchhrmsuser = async () => {
  //   const url = window.location.href;
  //   const segments = url.split("/");
  //   if (authdata) {
  //     const response = await axios.get(
  //       `https://khrms.kodukku.com/api/auth/getuser?kcplid=${authdata?.userid}`
  //     );
  //     const data = response.data.data.data;
  //     setRole(data);
  //   }
  // };

  useEffect(() => {
    const receiveMessageHandler = (data) => {
      try {
        if (data.message === "NotificationAccept") {
          fetchNotification();
        }
      } catch (error) {
        console.error("Error in receiveMessageHandler:", error);
      }
    };

    if (socket.connected) {
      socket.on("receive_message", receiveMessageHandler);
    } else {
      socket.on("connect", () => {
        socket.on("receive_message", receiveMessageHandler);
      });
    }

    // Cleanup function
    return () => {
      socket.off("receive_message", receiveMessageHandler);
    };
  }, [socket]);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const logout = async () => {
    dispatch(loginUser(null));
    navigate("/");
    setaddfavorites([]);
  };

  const admin_value = authdata?.isAdmin;

  const fetchNotification = async () => {
    const res = await axios.get(`${Nodeapi}/getnotification`, {
      params: {
        receiver_id: authdata?.id,
      },
    });
    if (res.data) {
      if (res.data.code == 200) {
        setNotification(res.data.data.message);
      } else {
        setNotification([]);
      }
    }
  };

  const AcceptRequest = async (sender_id, receiver_id, chatmaster_id, id) => {
    const res = await axios.post(`${Nodeapi}/acceptRequest`, {
      sender_id: sender_id,
      receiver_id: receiver_id,
      chatmaster_id: chatmaster_id,
    });
    removeById(Notification, id);
    if (res.data) {
      if (res.data.code == 200) {
        toast.current.clear();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.data.data.message,
          life: 3000,
        });
        navigate("/main/Chat");
      } else {
        toast.current.clear();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.data.data.message,
          life: 3000,
        });
        navigate("/");
      }
    }
  };
  function removeById(arr, id) {
    const updatedArray = arr.filter((item) => item.id !== id);
    setNotification(updatedArray);
    return updatedArray;
  }

  const CancelRequest = async (chatmaster_id, id) => {
    try {
      const res = await axios.patch(`${Nodeapi}/canceltRequest`, {
        chatmaster_id: chatmaster_id,
      });
      removeById(Notification, id);

      if (res.data) {
        if (res.data.code === 200) {
          toast.current.clear();
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.data.message,
            life: 3000,
          });
        } else {
          toast.current.clear();
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.data.data.message,
            life: 3000,
          });
        }
      }
    } catch (error) {
      toast.current.clear();
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while processing your request.",
        life: 3000,
      });
    }
  };

  const OkRequest = async (chatmaster_id, id) => {
    const res = await axios.patch(`${Nodeapi}/okrequest`, {
      chatmaster_id: chatmaster_id,
    });
    if (res.data) {
      if (res.data.code == 200) {
        removeById(Notification, id);
        navigate("/main/chat");
      } else {
        console.log(res.data.data.message);
      }
    }
  };

  const handlclick = async () => {
    // window.location.href = "https://hrms.kodukku.com/admin/dashboard/KCPL001"
    window.location.href = `https://khrms.kodukku.com/${Role.role}/dashboard/${authdata?.userid}`;
  };
  const [expand, setExpand] = useState(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleaccess = () => {
    setaccess(!access);
  };

  const handledisplay = () => {
    setdisplay(!display);
  };

  const handlehome = () => {
    setview_adpost("");
    navigate("/");
  };

  const [collapsed, setCollapsed] = useState(false);

  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleToggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const clearSearch = () => {
    setSearchValue("");
  };

  const CustomFooter = () => (
    <div>
      <div style={{ borderTop: "1px solid #dee2e6", opacity: ".25" }}></div>
      <div>
        <main>
          <Menu>
            {authdata ? (
              <MenuItem
                className="side-bar-main-menu"
                style={{ color: "white" }}
                icon={<BiLogOutCircle />}
                onClick={logout}
              >
                Logout
              </MenuItem>
            ) : (
              <MenuItem
                className="side-bar-main-menu"
                style={{ color: "white" }}
                icon={<RiLoginCircleLine />}
                onClick={() => navigate("/login")}
              >
                Login
              </MenuItem>
            )}

            <div
              className="side-bar-main-menu info-menu-submenu"
              style={{ color: "white" }}
            >
              <div
                className="info-menu-btn"
                onClick={() => handleMenuClick("info-menu")}
              >
                <span className="info-menu-icon">
                  <RiInformationLine />
                </span>
                <span className="info-menu-label">Informations</span>
                <span>
                  {openMenu === "info-menu" ? (
                    <RiArrowUpSLine />
                  ) : (
                    <RiArrowRightSLine />
                  )}
                </span>
              </div>
              {openMenu === "info-menu" && (
                <div className="info-menu-submenu-popup">
                  <div
                    className={`side-bar-main-menu info-menu-item ${selectedItem === "/main/About" ? "selected" : ""
                      }`}
                    onClick={() => handleItemClick("/main/About")}
                  >
                    <img
                      src={About_us}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <span className="info-menu">About Us</span>
                  </div>
                  <div
                    className={`side-bar-main-menu info-menu-item ${selectedItem === "/main/ContactUs" ? "selected" : ""
                      }`}
                    onClick={() => handleItemClick("/main/ContactUs")}
                  >
                    <img
                      src={Contact_us}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <span className="info-menu">Contact Us</span>
                  </div>
                  <div
                    className={`side-bar-main-menu info-menu-item ${selectedItem === "/main/Terms" ? "selected" : ""
                      }`}
                    onClick={() => handleItemClick("/main/Terms")}
                  >
                    <img
                      src={terms_condition}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <span className="info-menu">Terms & Policy</span>
                  </div>
                  <div
                    className={`side-bar-main-menu info-menu-item ${selectedItem === "/main/Privacy_Policy" ? "selected" : ""
                      }`}
                    onClick={() => handleItemClick("/main/Privacy_Policy")}
                  >
                    <img
                      src={Privacy}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <span className="info-menu">Privacy & Policy</span>
                  </div>
                  <div className="tooltip-container">
                    <Tooltip
                      target=".cancel-refund"
                      content="Cancellation & Refund Policy"
                    />
                    <div
                      className={`side-bar-main-menu info-menu-item ${selectedItem === "/main/Cancellation_policy"
                        ? "selected"
                        : ""
                        }`}
                      onClick={() =>
                        handleItemClick("/main/Cancellation_policy")
                      }
                    >
                      <img
                        src={Cancellation}
                        alt=""
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      <span className="info-menu cancel-refund">
                        Cancellation & Refund Policy
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Menu>
        </main>
      </div>
    </div>
  );

  return (
    <div className="">
      <Toast ref={toast} />
      <Sidebar
        className={`app maindiv  ${toggled ? "toggled" : ""}`}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 1,
          top: 0,
          bottom: 0,
        }}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <main>
          <Menu>
            {collapsed ? (
              <MenuItem
                icon={<FiChevronsRight />}
                onClick={handleCollapsedChange}
              />
            ) : (
              <MenuItem>
                <div
                  style={{
                    padding: "0px",
                    fontWeight: "bold",
                    fontSize: 14,
                    letterSpacing: "1px",
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={logo}
                    className="logo"
                    onClick={handlehome}
                    style={{ cursor: "pointer" }}
                    alt="logo"
                  />
                  {authdata && (
                    <PiDotsNineBold
                      className=""
                      style={{
                        color: "white",
                        fontSize: "30px",
                        marginTop: "4px",
                        marginRight: "21px",
                      }}
                      onClick={() => handleMenuClick("main-menu")}
                    />
                  )}

                  {authdata && (
                    <div className="ts-icons " style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                      <div className="" style={{}}>
                        <Link to="/main/chat">
                          <img
                            src={ChartMain}
                            alt="User Profile"
                            className="ts-icon text-white"
                          // style={{ marginTop: "2px" }}
                          />
                        </Link>
                      </div>
                      <div className="notifi d-none d-md- flex ">
                        <div className="notification-container">
                          {Notification[0] && (
                            <GoDotFill className="ts-icon-dot" />
                          )}
                        </div>
                        <div
                          className="notification-icon-mobile-hide"
                          onClick={handleNotify}
                        >
                          <img
                            src={BellIcon}
                            style={{ color: "white", width: "24px", height: "24px", objectFit: "contain" }}
                            className="ts-icon"
                            onClick={handleNotify}
                          />
                        </div>
                        <OverlayPanel
                          ref={overlayPanel}
                          id="overlay_panel"
                          className="notification-desk-view"
                        >
                          <div
                            className="notifi-modal"
                            style={{
                              overflow: "auto",
                              maxHeight: "278px",
                            }}
                          >
                            {Notification.length > 0 ? (
                              Notification.map((notifydata) => (
                                <div
                                  className="noti-list"
                                  key={notifydata.id}
                                  style={{
                                    padding: "8px 0px",
                                    borderBottom: "1px solid #ecece6",
                                  }}
                                >
                                  <div className="img-profile d-flex">
                                    <img
                                      src={avatar}
                                      alt=""
                                      style={{
                                        width: "2rem",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <p
                                      style={{
                                        color: "#333",
                                        fontSize: "14px",
                                        marginLeft: "12px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      <span>
                                        {notifydata.accept === "Accept"
                                          ? notifydata.receiver_name
                                          : notifydata.sender_name}
                                      </span>
                                      &nbsp;
                                      {notifydata.accept === "Accept"
                                        ? "has accepted the request"
                                        : "has requested to have a conversation"}
                                    </p>
                                  </div>
                                  <div className="side-noti-btn d-flex justify-content-end">
                                    {notifydata.accept === "Accept" ? (
                                      <Button
                                        label="Ok"
                                        className="noti-button"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          background: "#1877f2",
                                          color: "white",
                                        }}
                                        onClick={() =>
                                          OkRequest(
                                            notifydata.chatmaster_id,
                                            notifydata.id
                                          )
                                        }
                                      />
                                    ) : (
                                      <>
                                        <Button
                                          label="Reject"
                                          className="noti-button"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            background: "red",
                                            color: "white",
                                          }}
                                          onClick={() =>
                                            CancelRequest(
                                              notifydata.chatmaster_id,
                                              notifydata.id
                                            )
                                          }
                                        />
                                        <Button
                                          label="Accept"
                                          className="noti-button"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            background: "#22c55e",
                                            color: "white",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() =>
                                            AcceptRequest(
                                              notifydata.sender_id,
                                              notifydata.receiver_id,
                                              notifydata.chatmaster_id,
                                              notifydata.id
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>No notifications</p>
                            )}
                          </div>
                        </OverlayPanel>
                      </div>
                      {authdata && (
                        <>
                          <Link>
                            <IoCloseSharp
                              className="ts-icon text-white d-md-none"
                              onClick={toggleDrawer(false)}
                            />
                          </Link>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </MenuItem>
            )}
            <hr />
          </Menu>
          <div className="flex justify-content-between align-items-center flex-column">
            {openMenu === "main-menu" && (
              <div
                className="flex align-items-center flex-column"
                style={{
                  height: "71vh",
                  width: "228px",
                  borderRadius: "6px",
                  gap: "4px",
                }}
              >
                <Card
                  className="expend-main-menu-card"
                  // style={{ marginRight: "1px" }}>
                  style={{
                    marginRight: "1px",
                    height: authdata?.isAdmin === "Admin" ? "66vh" : "",
                  }}
                >
                  {authdata && (
                    <Menu className="post-main-menu" style={{ width: "225px" }}>
                      <SubMenu
                        className="side-bar-main-menu post-menu main-menu-item"
                        label={"My Activity"}
                        icon={
                          <img
                            src={my_post}
                            alt=""
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        }
                        onClick={() => handleMainMenuClick("my-post-menu")}
                      >
                        {openMainMenu === "my-post-menu" && (
                          <div>
                            <MenuItem
                              icon={
                                <img
                                  src={MyPost}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              }
                              className={`side-bar-main-menu menu-item  add-posts-menu ${selectedItem === "/main/advDasboared"
                                ? "selected"
                                : ""
                                }`}
                              onClick={() =>
                                handleItemClick("/main/advDasboared")
                              }
                            >
                              My Posts
                            </MenuItem>
                            <div className="tooltip-container">
                              <Tooltip
                                target=".subscript"
                                content="Subscription"
                              />
                              <MenuItem
                                icon={
                                  <img
                                    src={Subscription}
                                    alt=""
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                }
                                className={`side-bar-main-menu menu-item subscript ${selectedItem === "/main/Location"
                                  ? "selected"
                                  : ""
                                  }`}
                                onClick={() =>
                                  handleItemClick("/main/Location")
                                }
                              >
                                Subscription
                              </MenuItem>
                            </div>
                            <div className="tooltip-container">
                              <Tooltip
                                target=".aadhar-verify-menu"
                                content="Aadhaar Verification"
                              />
                              <MenuItem
                                className={`side-bar-main-menu menu-item aadhar-verify-menu ${selectedItem === "/main/Aadhaar"
                                  ? "selected"
                                  : ""
                                  }`}
                                icon={
                                  <img
                                    src={aadharr_verification}
                                    alt=""
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                }
                                onClick={() => handleItemClick("/main/Aadhaar")}
                              >
                                Aadhaar Verification
                              </MenuItem>
                            </div>
                            <MenuItem
                              icon={
                                <img
                                  src={my_activity}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              }
                              className={`side-bar-main-menu menu-item main-menu-item activity-menu ${selectedItem === "/main/Activity"
                                ? "selected"
                                : ""
                                }`}
                              onClick={() => handleItemClick("/main/Activity")}
                            >
                              {" "}
                              Activities
                            </MenuItem>
                          </div>
                        )}
                      </SubMenu>

                      <MenuItem
                        icon={
                          <img
                            src={company_profile}
                            alt=""
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        }
                        className={`side-bar-main-menu menu-item main-menu-item company-profile-menu ${selectedItem === "/main/CompanyProfile"
                          ? "selected"
                          : ""
                          }`}
                        onClick={() => handleItemClick("/main/CompanyProfile")}
                      >
                        {" "}
                        Company Profile
                      </MenuItem>
                      <MenuItem
                        className={`side-bar-main-menu menu-item main-menu-item my-profile-menu ${selectedItem === "/main/profile" ? "selected" : ""
                          }`}
                        icon={
                          <img
                            src={myProfile}
                            alt=""
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        }
                        onClick={() => handleItemClick("/main/profile")}
                      >
                        {" "}
                        My Profile
                      </MenuItem>
                      {/* <MenuItem
                        icon={
                          <img
                            src={chat}
                            alt=""
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        }
                        className={`side-bar-main-menu menu-item main-menu-item  chat-menu ${
                          selectedItem === "/main/chat" ? "selected" : ""
                        }`}
                        onClick={() => handleItemClick("/main/chat")}
                      >
                        {" "}
                        Chat{" "}
                      </MenuItem> */}

                      {admin_value && (
                        <SubMenu
                          className="side-bar-main-menu main-menu-item"
                          label={"Chat Reports"}
                          icon={
                            <FaUserShield className="menu-icon-custom-color" style={{ width: "20px", height: "20px" }} />
                          }
                          onClick={() =>
                            handleMainMenuClick("chat-report-menu")
                          }
                        >
                          {openMainMenu === "chat-report-menu" && (
                            <div>
                              <div className="tooltip-container">
                                <Tooltip
                                  target=".chat-report"
                                  content="Chat Reports-Team Lead"
                                />
                                <MenuItem
                                  icon={
                                    <img
                                      src={ChatReport}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                  className={`side-bar-main-menu menu-item  chat-report ${selectedItem === "/main/chatReports"
                                    ? "selected"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    handleItemClick("/main/chatReports")
                                  }
                                >

                                  Chat Reports-Team Lead
                                </MenuItem>
                              </div>
                              <div className="tooltip-container">
                                <Tooltip
                                  target=".chat-report-team"
                                  content="Chat Reports-Team Mem"
                                />
                                <MenuItem
                                  icon={
                                    <img
                                      src={ChatReport}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                  className={`side-bar-main-menu menu-item  chat-report-team ${selectedItem === "/main/ChatReportTeamM"
                                    ? "selected"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    handleItemClick("/main/ChatReportTeamM")
                                  }
                                >
                                  {" "}
                                  Chat Reports-Team Mem{" "}
                                </MenuItem>
                              </div>
                            </div>
                          )}
                        </SubMenu>
                      )}
                      {/* <MenuItem
                    className="side-bar-main-menu"
                    style={{ color: "white" }}
                    icon={<RiHome4Line />}
                    onClick={() => navigate("/main/advDasboared")}
                  >
                    {" "}
                    My Listings
                  </MenuItem> */}
                      {admin_value && (
                        <SubMenu
                          className="side-bar-main-menu main-menu-item"
                          label={" Admin Panel"}
                          icon={
                            <img
                              src={admin_panel}
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          }
                          onClick={() =>
                            handleMainMenuClick("admin-panel-menu")
                          }
                        >
                          {openMainMenu === "admin-panel-menu" && (
                            <div>
                              <MenuItem
                                icon={
                                  <img
                                    src={user_list}
                                    alt=""
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                }
                                className={`side-bar-main-menu menu-item admin-panel-menu ${selectedItem === "/main/AdminPanel"
                                  ? "selected"
                                  : ""
                                  }`}
                                onClick={() =>
                                  handleItemClick("/main/AdminPanel")
                                }
                              >
                                {" "}
                                User List{" "}
                              </MenuItem>
                              <div className="tooltip-container">
                                <Tooltip
                                  target=".user-candidate-menu"
                                  content="View Candidate"
                                />
                                <MenuItem
                                  className={`side-bar-main-menu menu-item user-candidate-menu ${selectedItem === "/main/UserCandidate"
                                    ? "selected"
                                    : ""
                                    }`}
                                  icon={
                                    <img
                                      src={view_candidate}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    handleItemClick("/main/UserCandidate")
                                  }
                                >
                                  View Candidate
                                </MenuItem>
                              </div>
                              <div className="tootip-container">
                                <Tooltip
                                  target=".aadhaar-details-menu"
                                  content="Aadhaar Details"
                                />
                                <MenuItem
                                  className={`side-bar-main-menu menu-item aadhaar-details-menu ${selectedItem === "/main/Aadhaar_details"
                                    ? "selected"
                                    : ""
                                    }`}
                                  icon={
                                    <img
                                      src={aadhaar_details}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    handleItemClick("/main/Aadhaar_details")
                                  }
                                >
                                  {" "}
                                  Aadhaar Details
                                </MenuItem>
                              </div>
                            </div>
                          )}
                        </SubMenu>
                      )}
                      {admin_value && (
                        <SubMenu
                          className="side-bar-main-menu main-menu-item"
                          label={"  Account Access"}
                          icon={
                            <img
                              src={account_access_icon}
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          }
                          onClick={() =>
                            handleMainMenuClick("admin-access-menu")
                          }
                        >
                          {openMainMenu === "admin-access-menu" && (
                            <div>
                              <div className="tooltip-container">
                                <Tooltip
                                  target=".account-access-menu"
                                  content="Account Sharing"
                                />
                                <MenuItem
                                  className={`side-bar-main-menu menu-item account-access-menu ${selectedItem === "/main/AccountSharing"
                                    ? "selected"
                                    : ""
                                    }`}
                                  icon={
                                    <img
                                      src={account_sharing}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    handleItemClick("/main/AccountSharing")
                                  }
                                >
                                  Account Sharing
                                </MenuItem>
                              </div>
                              <div className="tooltip-container">
                                <Tooltip
                                  target=".account-activity-menu"
                                  content="Account Activity"
                                />
                                <MenuItem
                                  className={`side-bar-main-menu menu-item account-activity-menu ${selectedItem ===
                                    "/main/Accountsharingactivity"
                                    ? "selected"
                                    : ""
                                    }`}
                                  icon={
                                    <img
                                      src={account_activity}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    handleItemClick(
                                      "/main/Accountsharingactivity"
                                    )
                                  }
                                >
                                  {" "}
                                  Account Activity
                                </MenuItem>
                              </div>
                            </div>
                          )}
                        </SubMenu>
                      )}

                      {admin_value && (
                        <div className="tooltip-container">
                          <Tooltip
                            target=".ad-panel"
                            content="Advertisement Panel"
                          />
                          <MenuItem
                            className={`side-bar-main-menu menu-item main-menu-item ad-panel ad-panel-menu ${selectedItem === "/main/Advertisment_Panel"
                              ? "selected"
                              : ""
                              }`}
                            icon={
                              <img
                                src={advertisement}
                                alt=""
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            }
                            onClick={() =>
                              handleItemClick("/main/Advertisment_Panel")
                            }
                          >
                            {" "}
                            Advertisement Panel
                          </MenuItem>
                        </div>
                      )}
                      {/* {(account_access?.HRMS === "1" || admin_value) && (
                     <MenuItem className="side-bar-main-menu" style={{ color: "white" }} icon={<IoChatboxEllipsesOutline />} onClick={() => navigate('/main/chat')}> Chat </MenuItem>

                      )} */}
                      {/* <MenuItem className="side-bar-main-menu" style={{ color: "white" }} icon={<Stack />} onClick={() => navigate('/main/chat')}> Chat</MenuItem> */}
                      {admin_value && (
                        <>
                          {/* <MenuItem
                        className="side-bar-main-menu"
                        style={{ color: "white" }}
                        icon={<Money />}
                        onClick={() => navigate("/main/Billing")}
                      >
                        {" "}
                        Billing
                      </MenuItem> */}
                          <MenuItem
                            className={`side-bar-main-menu menu-item main-menu-item sales-details-menu ${selectedItem === "/main/Sales_details"
                              ? "selected"
                              : ""
                              }`}
                            icon={
                              <img
                                src={sales_details}
                                alt=""
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            }
                            onClick={() =>
                              handleItemClick("/main/Sales_details")
                            }
                          >
                            {" "}
                            Sales details
                          </MenuItem>
                        </>
                      )}
                    </Menu>
                  )}
                </Card>
              </div>
            )}
            {openMenu !== "main-menu" && (
              <div
                className="p-inputgroup"
                style={{
                  width: "93%",
                  // marginLeft: "9px",
                  paddingTop: "4px",
                }}
              >
                <div className="input-wrapper">
                  <CiSearch className="search-icon-category" />
                  <InputText
                    type="text"
                    className="search-input p-inputtext p-component"
                    placeholder="Search here"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    style={{
                      borderTopLeftRadius: "6px",
                      borderBottomLeftRadius: "6px",
                      fontWeight: "400",
                      height: "40px",
                      width:"218px",
                    }}
                  />
                  {searchValue && (
                    <MdOutlineClear
                      onClick={clearSearch}
                      style={{
                        cursor: "pointer",
                        marginLeft: "-30px",
                        fontSize: "18px",
                        color: "#999",
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {openMenu !== "main-menu" && (
              <div
                className=""
                style={{
                  height: "65vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                {authdata && (
                  <Menu
                    style={{
                      scrollbarColor: "#1877f2 #1877f2",
                      marginLeft: "-3%",
                    }}
                    className="menu-item-category"
                  >
                    {admin_value && (
                      <div className="scrollable-menu-wrapper">
                        <div className="scrollable-menu">
                          <SubMenu
                            style={{ color: "white", marginTop: "10px" }}
                            label="Classifieds"
                            icon={
                              <img
                                src={classifiedIcon}
                                alt=""
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                className="categories-icon"
                              />
                            }
                            onClick={() => handleMenuClick("category-menu")}
                          >
                            {openMenu === "category-menu" && (
                              <div>
                                <div className="tooltip-container">
                                  <Tooltip
                                    target=".blood-donation-menu"
                                    content="Blood Donations"
                                  />
                                  <MenuItem
                                    className="menu-item-category blood-donation-menu"
                                    style={{ color: "white" }}
                                    icon={
                                      <img
                                        src={BloodDonation}
                                        alt=""
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        className="categories-icon"
                                      />
                                    }
                                    onClick={() => navigate("/main/BloodDonation")}
                                  >
                                    Blood Donations
                                  </MenuItem>
                                </div>
                                <div className="tooltip-container">
                                  <Tooltip
                                    target=".lost-found"
                                    content="Lost and Found"
                                  />
                                  <MenuItem
                                    className="menu-item-category lost-found"
                                    style={{ color: "white" }}
                                    icon={
                                      <img
                                        src={LostFound}
                                        alt=""
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        className="categories-icon"
                                      />
                                    }
                                    onClick={() => navigate("/main/LostAndFound")}
                                  >
                                    Lost and Found
                                  </MenuItem>
                                </div>
                                <MenuItem
                                  className="menu-item-category"
                                  style={{ color: "white" }}
                                  icon={
                                    <RiMovie2Fill
                                      style={{ fontSize: "20px" }}
                                    />
                                  }
                                  onClick={() => navigate("/main/cinePost")}
                                >
                                  Cine Posts
                                </MenuItem>
                                <MenuItem
                                  className="menu-item-category"
                                  style={{ color: "white" }}
                                  icon={
                                    <RiMovie2Fill
                                      style={{ fontSize: "20px" }}
                                    />
                                  }
                                  onClick={() => navigate("/main/CineJob")}
                                >
                                  Cine Jobs
                                </MenuItem>
                              </div>
                            )}
                          </SubMenu>
                        </div>
                      </div>
                    )}
                  </Menu>
                )}
              </div>
            )}
            <CustomFooter />
          </div>
        </main>
      </Sidebar>
      {/* <NotificationDialog
          dialogVisible={dialogVisible}
          setDialogVisible={setDialogVisible}
          Notification={Notification}
          OkRequest={OkRequest}
          CancelRequest={CancelRequest}
          AcceptRequest={AcceptRequest}
        /> */}
    </div>
  );
};

export default Sidebar_sample;