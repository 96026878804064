import React, { useEffect, useState, useContext, useRef } from "react";
import "./Activity.css";
import lambo from "../../assets/images/Altja_jõgi_Lahemaal.jpg";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { Nodeapi, Assetsapi } from "../../config/serverUrl";
import moment from 'moment';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { DataContext } from "../../Admin/DataContext"
import { useNavigate } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoHeart } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import { BsCalendar2Date } from "react-icons/bs";
import DatePicker from 'react-datepicker';
import { Calendar } from 'primereact/calendar';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import 'react-datepicker/dist/react-datepicker.css'; const Activity = () => {

  const [date, setDate] = useState(null);
  const [products, setProducts] = useState([])
  const [yesterdayproducts, setyesterdayProducts] = useState([])
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [savedata, setsavedata] = useState([])
  const [display, setdisplay] = useState(false)
  const [savehistory, setsavehistory] = useState(false)
  const authdata = useSelector((state) => state.auth.user);
  const [selectedads, setselectedads] = useState([])
  const userid = authdata.userid
  const [contacted, setcontacted] = useState([])
  const [yesterdaycontact, setyesterdaycontact] = useState([])
  const [contacthistores, setcontacthistores] = useState(false)
  const {
    setdisplaypost,
    displaypost,
    setview_adpost,
    view_adpost,
    sethome_listimages,
    back_btn,
    setback_btn
  } = useContext(DataContext);

  const [bookmarkStates, setBookmarkStates] = useState(
    Array(displaypost.length).fill(false)
  );
  const Navigate = useNavigate()




  useEffect(() => {

    fetchdata();
  }, [userid])

  const fetchdata = async () => {
    setview_adpost([])
    let val = 1
    const res = await axios.get(`${Nodeapi}/getviewposts?userid=${userid}`)
    const data = res.data.getdata

    setselectedads(data)
    const today = new Date().toISOString().split('T')[0];
    const filterdata = data.filter((item) => item.View_date == today)
    if (filterdata) {
      setProducts(filterdata)
    }


    const previousdata = data.filter((item) => item.View_date !== today)

    if (previousdata) {
      setyesterdayProducts(previousdata)
    }

  }



  const handlesave = async () => {
    try {
      setsavehistory(true)
      setdisplay(false)
      setcontacthistores(false)
      const res = await axios.get(`${Nodeapi}/getsavedpost?userid=${authdata.userid}`)
      const data = res.data.getdata
      const today = new Date().toISOString().split('T')[0];
      const filterdata = data.filter((item) => item.View_date == today)

      // setsavedata(data)
      if (filterdata) {
        setsavedata(filterdata)
      }


      const previousdata = data.filter((item) => item.View_date !== today)

      if (previousdata) {
        setyesterdayProducts(previousdata)
      }
    } catch (error) {

    }
  }


  const handlecontact = async () => {
    try {
      setcontacthistores(true);
      setsavehistory(false);
      setdisplay(false);
      const res = await axios.get(`${Nodeapi}/getcontactedpost?userid=${authdata.userid}`);
      const data = res.data.getdata;

      console.log('data:', data);
      const today = new Date().toISOString().split('T')[0];

      const filterdata = data.filter((item) => item.View_date === today);
      setcontacted(filterdata);

      const previousdata = data.filter((item) => item.View_date !== today);
      console.log('previousdata:', previousdata);

      setyesterdayProducts(previousdata);

    } catch (error) {
      console.error('Failed to handle contact:', error);
    }

  };
  const handleview = (index, userid) => {

    const newBookmarkStates = [...bookmarkStates];
    newBookmarkStates[index] = !newBookmarkStates[index];
    setBookmarkStates(newBookmarkStates);

    if (newBookmarkStates[index]) {

      setSelectedIndices([...selectedIndices, index]);
      setview_adpost([...view_adpost, products[index]]);
    } else {

      const updatedIndices = selectedIndices.filter((i) => i !== index);
      const updatedData = products.filter((data, i) => i !== index);

      setSelectedIndices(updatedIndices);
      setview_adpost(updatedData);


    }

    if (authdata) {
      Navigate(`/main/VehicleOverview/${userid}`)
    } else {
      Navigate('/login')

    }

  }


  const deletePostViewHistory = async (image_id) => {

    if (!image_id) {
      console.error("Post ID is missing.");
      return;
    }

    try {
      const response = await axios.delete(`${Nodeapi}/deletePostViewHistory`, {
        params: { image_id }
      });

      if (response.status === 200 && response.data.success) {
        console.log("All user view history has been deleted successfully!");
        setdisplay(false);

        if (savehistory) {
          handlesave();
        } else {
          fetchdata();
        }
      } else {
        console.error("Error clearing user view history:", response.data.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error while clearing view history:", error.response?.data?.message || error.message || "Unknown error");
    }
  };




  const handleDeleteAllUserViewHistory = async () => {
    const userid = authdata.userid;
    try {
      const response = await axios.delete(`${Nodeapi}/deleteAllUserViewHistory`, {
        params: { userid }
      });

      if (response.data.success) {
        console.log("All user view history has been deleted successfully!");
        setdisplay(false);

        if (savehistory) {
          handlesave();
        } else {
          fetchdata();
        }
      } else {
        console.error("Error clearing user view history:", response.data.message);
      }
    } catch (error) {
      console.error("Error while clearing view history:", error);
    }
  };


  const handleimage = async (data) => {
    const val = products[data]
    const category = val.post_category
    const postid = val.post_id
    setback_btn(true)
    localStorage.setItem('Category', category);
    localStorage.setItem('Postiddata', postid);
    localStorage.setItem('PostID', val.id);

    try {
      const res = await axios.get(`${Nodeapi}/getimagename?userid=${val.post_id}`)
      const data = res.data.result
      const imageFileNames = data.map(image => image.file_name);
      sethome_listimages(imageFileNames)

    } catch (error) {
      console.log('error:', error);
    }

  }

  const handleview_yesterday = (index, userid) => {

    const newBookmarkStates = [...bookmarkStates];
    newBookmarkStates[index] = !newBookmarkStates[index];
    setBookmarkStates(newBookmarkStates);

    if (newBookmarkStates[index]) {

      setSelectedIndices([...selectedIndices, index]);
      setview_adpost([...view_adpost, yesterdayproducts[index]]);
    } else {

      const updatedIndices = selectedIndices.filter((i) => i !== index);
      const updatedData = yesterdayproducts.filter((data, i) => i !== index);

      setSelectedIndices(updatedIndices);
      setview_adpost(updatedData);


    }

    if (authdata) {
      Navigate(`/main/VehicleOverview/${userid}`)
    } else {
      Navigate('/login')

    }

  }

  const handleimage_yesterday = async (data) => {
    const val = yesterdayproducts[data]
    const category = val.post_category
    const postid = val.post_id
    setback_btn(true)
    localStorage.setItem('Category', category);
    localStorage.setItem('Postiddata', postid);
    localStorage.setItem('PostID', val.id);

    try {
      const res = await axios.get(`${Nodeapi}/getimagename?userid=${val.post_id}`)
      const data = res.data.result
      const imageFileNames = data.map(image => image.file_name);
      sethome_listimages(imageFileNames)

    } catch (error) {
      console.log('error:', error);
    }

  }

  const handleactiveview = () => {
    setsavehistory(false)
    setdisplay(false)
    setcontacthistores(false)
    fetchdata();
  }


  const handleChange = (index) => {
    const updatedProducts = [...products];
    updatedProducts[index].isFavorited = !updatedProducts[index].isFavorited;
    setProducts(updatedProducts);
  };

  const handleChanges = (index) => {
    const updatedProducts = [...products];
    updatedProducts[index].isFavorited = !updatedProducts[index].isFavorited;
    // setVehicles(updatedProducts);
  };


  const handleDateChange = async (date) => {

    const formattedDate = date.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-');


    if (savehistory) {
      try {
        const res = await axios.post(`${Nodeapi}/getselected_saveads`, {
          userid: authdata.userid,
          date: formattedDate
        })
        const data = res.data.getdata
        const result = res.data

        if (result.success == true) {
          setyesterdayProducts(data)
          setdisplay(true)
        }
      } catch (error) {
        console.log(error);
      }
    } else if (contacthistores) {
      try {
        const res = await axios.post(`${Nodeapi}/contacted_dates`, {
          userid: authdata.userid,
          date: formattedDate
        })
        const data = res.data.getdata
        const result = res.data
        if (result.success == true) {
          setyesterdayProducts(data)
          setdisplay(true)
        }
      } catch (error) {

      }
    } else {
      try {
        const res = await axios.post(`${Nodeapi}/getselected_dateads`, {
          userid: authdata.userid,
          date: formattedDate
        })
        const data = res.data.getdata
        const result = res.data
        if (result.success == true) {
          setyesterdayProducts(data)
          setdisplay(true)
        }
      } catch (error) {

      }
    }



  };



  const [startDate, setStartDate] = useState(null);

  const currencySymbols = {
    INR: '₹',
    USD: '$',
    GBP: '£',
    EUR: '€',
    JPY: '¥',
    RUB: '₽',
    ZAR: 'R'
  };


  const handleClickOpen = () => {
    Swal.fire({
      title: 'Are you sure you want to clear all history?',

      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Clear All'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteAllUserViewHistory();
      }
    });
  };



  return (

    <div className="container-fluid ">
      <div className="d-flex justify-content-between">
        <h4 className="Activity">My Activity</h4>

        <div className="d-flex align-items-center datapicker">
          <DatePicker
            selected={startDate}
            onChange={(date) => handleDateChange(date)}
            popperPlacement="bottom-start" // Adjust the placement as needed
            popperClassName="your-custom-class" // Add your custom class for styling
            calendarClassName="calendar-custom-class" // Add custom class for calendar styling
            customInput={
              <div style={{ display: "inline-block" }}>
                <p style={{ cursor: "pointer" }} className="mx-3">
                  <BsCalendar2Date style={{ marginLeft: "-22%", marginTop: "-4px", color: "#2196F3" }} />
                  <span style={{ marginLeft: "6%" }}> Date</span>
                </p>
              </div>
            }
          />

          <Button
            style={{
              border: "none",
              color: "#2196F3",
              textDecoration: "underline",
              cursor: "pointer",
              backgroundColor: "#fff"
            }}
            onClick={handleClickOpen}
          >
            Clear All
          </Button>


        </div>
      </div>

      <div className="card" style={{ marginTop: "0px" }}>

        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {/* <p style={{ cursor: "pointer" }}>
              <i
                className="fi fi-rr-settings-sliders me-2"
                style={{ color: "#2196F3" }}
              ></i>
              Filter
            </p> */}
          </div>
        </div>

        <div className="tabs-activity mt-3">
          <ul
            className="nav nav-pills justify-content-between mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">

              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={handleactiveview}
              >
                <FiEye style={{ marginLeft: "-14%" }} /> <span style={{ marginLeft: "7%" }}>Viewed</span>
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                onClick={handlecontact}
              >
                <BiSolidPhoneCall style={{ fontSize: "19px", marginLeft: "-14%", marginTop: "-2px" }} /> <span style={{ marginLeft: "3%" }}>Contacted</span>
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                onClick={handlesave}
              >
                <IoHeart style={{ fontSize: "17px", marginLeft: "-14%" }} /> <span style={{ marginLeft: "4%" }}>Saved</span>
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-search-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-search"
                type="button"
                role="tab"
                aria-controls="pills-search"
                aria-selected="false"
              >
                <FiSearch style={{ marginLeft: "-14%", fontSize: "18px", marginTop: "-2px" }} /> <span style={{ marginLeft: "4%" }}> Recent Search</span>
              </button>
            </li>
          </ul>
          {!display && products && (
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active w-full"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >

                {products != "" && (
                  <button
                    className="h6 mb-3"
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "0.5rem",
                      background: "#f1f1f1b3",
                      border: "none",
                      color: "#8B8B8B",
                      padding: "5px 10px",
                      borderRadius: "6px",
                    }}
                  >
                    Today
                  </button>
                )}

                <div className="grid">
                  {products.map((product, index) => (
                    <div className="col-12 md:col-6 lg:col-6 xl:col-4" key={index} style={{ cursor: "pointer" }}

                    >
                      <div
                        className="card p-2 mt-0"
                        style={{ borderRadius: "8px" }}

                      >
                        <div className="d-sm-flex">
                          <div className="img" style={{ position: "relative", cursor: "pointer" }} onClick={() => { handleview(index, product.userid); handleimage(index) }}>
                            {/* <AutoPlaySwipeableViews
                               axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                               enableMouseEvents
                             >
                               {product.images.map((step, index) => (
                               <div key={step.label}>
                                 {Math.abs(activeStep - index) <= 10 ? (
                                   <Box
                                     component="img"
                                     sx={{
                                       
                                       display: 'block',
                                       // maxWidth: 400,
                                       overflow: 'hidden',
                                       width: "125px",
                                       height: "125px",
                                       borderRadius: 2
                                     }}
                                     src={`${Assetsapi}/adimages/${step}`}
                                     alt={`House ${index + 1}`}
                                    
                                   />
                                 ) : null}
                               </div>
                             ))}
                             </AutoPlaySwipeableViews> */}

                            <img className="img-fluid w-100 pb-2"

                              src={`${Assetsapi}/adimages/${product.images[0]}`}
                              alt={`House ${index + 1}`}

                              style={{
                                width: "125px",
                                height: "125px",
                                borderRadius: "8px",
                              }}
                            />


                            <button
                              onClick={() => handleChange(index)}
                              className="heart"
                              style={{
                                position: "absolute",
                                top: "4px",
                                right: "4px",
                                padding: "0px 5px",
                              }}
                            >
                              {product.isFavorited ? (
                                <i
                                  className="fi fi-sr-heart"
                                  style={{ color: "red", fontSize: "13px" }}
                                ></i>
                              ) : (
                                <i
                                  className="fi fi-rr-heart"
                                  style={{ color: "#8B8B8B", fontSize: "13px" }}
                                ></i>
                              )}
                            </button>
                          </div>
                          <div className="card-left w-100 ms-sm-2">
                            <div className="d-flex justify-content-between align-items-start">
                              <div className="d-flex">
                                <p
                                  className="cate "
                                  style={{
                                    color: "#BBB9B9",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    // borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  Category :
                                  <span
                                    className="title ms-1"
                                    style={{
                                      color: "#2824DF",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                    }}
                                  >

                                    {product.post_category.length > 10
                                      ? `${product.post_category.substring(0, 10)}...`
                                      : product.post_category}
                                  </span>
                                </p>

                              </div>
                              <div className="d-flex gap-2">
                                <button className="ms-2 buy ">Buy</button>
                                {/* <button className=""> */}
                                {/* <i className="fi fi-rr-trash"></i> */}
                                {/* </button> */}
                                {/* <i
                                   class="fi fi-sr-cross-small"
                                   style={{ color: "#8B8B8B", fontSize: "18px" }}
                                 ></i> */}
                                <button
                                  style={{ border: 'none', backgroundColor: "white" }}
                                  onClick={() => deletePostViewHistory(product.post_id)}
                                >
                                  <RxCross2 style={{ color: "#8B8B8B", fontSize: "18px" }} />
                                </button>


                              </div>
                            </div>
                            <p
                              style={{
                                color: "#4A4A4A",
                                fontWeight: 700,
                                fontSize: "16px",
                              }}
                            >

                              {product.post_title.length > 15
                                ? `${product.post_title.substring(0, 15)}...`
                                : product.post_title}
                            </p>

                            <div class="d-flex">
                              <div class="cate2">
                                <p
                                  className=""
                                  style={{
                                    color: "#8B8B8B",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                    // borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  12,000 Kms
                                </p>
                              </div>
                              <p
                                className="ms-2"
                                style={{
                                  color: "#8B8B8B",
                                  fontWeight: 400,
                                  fontSize: "13px",
                                }}
                              >
                                Electric charging
                              </p>
                            </div>
                            <p
                              style={{
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#4A4A4A",
                              }}
                              className="mb-1"
                            >
                              {currencySymbols[product.salaryCurrency] || product.salaryCurrency}
                              {product.post_price.length > 9
                                ? `${product.post_price.substring(0, 9)}...`
                                : product.post_price}
                            </p>

                            <div
                              className="d-flex justify-content-between pb-4"
                              style={{ borderTop: "1px solid #E6E6E6" }}
                            >
                              <p
                                className="dot mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-marker"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {product.post_location.length > 7
                                  ? `${product.post_location.substring(0, 7)}...`
                                  : product.post_location}
                              </p>
                              <p
                                className="dot ms-2 mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-calendar"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {(() => {
                                  const date = moment(product.created_at);
                                  const formattedDate = date.format('DD MMMM YYYY');
                                  return formattedDate; // Return the formatted date
                                })()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >

                {contacted != "" && (
                  <button
                    className="h6 mb-3"
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "0.5rem",
                      background: "#f1f1f1b3",
                      border: "none",
                      color: "#8B8B8B",
                      padding: "5px 10px",
                      borderRadius: "6px",
                    }}
                  >
                    Today
                  </button>)}
                <div className="row">

                  {contacted.map((vehicle, index) => (
                    <div className="col-12 md:col-6 lg:col-6 xl:col-4" key={index}>
                      <div className="card p-2 mt-0" style={{ borderRadius: "8px" }}>
                        <div className="d-sm-flex">
                          <div className="img" style={{ position: "relative", cursor: "pointer" }} onClick={() => { handleview(index, vehicle.userid); handleimage(index) }}>
                            <img className="img-fluid w-100 pb-2"
                              src={`${Assetsapi}/adimages/${vehicle.images[0]}`}
                              alt={`House ${index + 1}`}

                              style={{
                                width: "125px",
                                height: "125px",
                                borderRadius: "8px",
                              }}
                            />
                            <button
                              onClick={() => handleChanges(index)}
                              className="heart"
                              style={{
                                position: "absolute",
                                top: "3px",
                                right: "4px",
                                padding: "0px 5px",
                              }}
                            >
                              <i
                                className={
                                  vehicle.isSold
                                    ? "fi fi-sr-heart"
                                    : "fi fi-rr-heart"
                                }
                                style={{
                                  color: vehicle.isSold ? "red" : "#8B8B8B",
                                  fontSize: "13px",
                                }}
                              ></i>
                            </button>
                          </div>
                          <div className="card-left w-100 ms-sm-2">
                            <div className="d-flex justify-content-between align-items-start">
                              <div className="d-flex">
                                <p
                                  className="cate"
                                  style={{
                                    color: "#BBB9B9",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    // borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  Category :
                                  <span
                                    className="title ms-1"
                                    style={{
                                      color: "#2824DF",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                    }}
                                  >
                                    {vehicle.post_category.length > 10
                                      ? `${vehicle.post_category.substring(0, 10)}...`
                                      : vehicle.post_category}
                                  </span>
                                </p>

                              </div>
                              <div className="d-flex gap-2">
                                <button className="ms-2 buy">Buy</button>
                                <button
                                  style={{ border: 'none', backgroundColor: "white" }}
                                  onClick={() => deletePostViewHistory(vehicle.post_id)}
                                >
                                  <RxCross2 style={{ color: "#8B8B8B", fontSize: "18px" }} />
                                </button>
                              </div>
                            </div>
                            <p
                              style={{
                                color: "#4A4A4A",
                                fontWeight: 700,
                                fontSize: "16px",
                              }}
                            >

                              {vehicle.post_title.length > 15
                                ? `${vehicle.post_title.substring(0, 15)}...`
                                : vehicle.post_title}
                            </p>

                            <div class="d-flex">
                              <div class="">
                                <p
                                  className=""
                                  style={{
                                    color: "#8B8B8B",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                                >
                                  12,000 Kms
                                </p>
                              </div>
                            </div>

                            {/* 
                            <div className="d-flex ">
                              <p>
                                <i
                                  className="fi fi-rr-phone-call me-1"
                                  style={{ color: "#2196F3", fontSize: "12px" }}
                                ></i>
                                <span
                                  style={{ color: "#2196F3", fontSize: "12px" }}
                                >
                                  9001234567
                                </span>
                              </p>

                              <p className="ms-3">
                                <i
                                  className="fi fi-rr-comment-dots me-1"
                                  style={{ color: "#2196F3", fontSize: "12px" }}
                                ></i>
                                <span
                                  style={{ color: "#2196F3", fontSize: "12px" }}
                                >
                                  Message
                                </span>
                              </p>
                            </div> */}
                            {/* 
                            <button
                              className=""
                              style={{
                                color: "#8B8B8B",
                                backgroundColor: "#E6E6E6",
                                borderRadius: "4px",
                                border: "none",
                                fontSize: "12px",
                                marginBottom: "6px",
                              }}
                            >
                              <i className="fi fi-rs-ban"></i>
                              <span className="ms-1">Rented out</span>
                            </button> */}
                            <p
                              style={{
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#4A4A4A",
                              }}
                              className="mb-1"
                            >
                              {currencySymbols[vehicle.salaryCurrency] || vehicle.salaryCurrency}
                              {vehicle.post_price.length > 9
                                ? `${vehicle.post_price.substring(0, 9)}...`
                                : vehicle.post_price}
                            </p>

                            <div
                              className="d-flex justify-content-between pb-4"
                              style={{ borderTop: "1px solid #E6E6E6" }}
                            >
                              <p
                                className="dot mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-marker"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {vehicle.post_location.length > 7
                                  ? `${vehicle.post_location.substring(0, 7)}...`
                                  : vehicle.post_location}
                              </p>
                              <p
                                className="dot ms-2 mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-calendar"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {(() => {
                                  const date = moment(vehicle.created_at);
                                  const formattedDate = date.format('DD MMMM YYYY');
                                  return formattedDate; // Return the formatted date
                                })()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                style={{ width: "100%" }}
              >
                {savedata != "" && (
                  <button
                    className="h6 mb-3"
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "0.5rem",
                      background: "#f1f1f1b3",
                      border: "none",
                      color: "#8B8B8B",
                      padding: "5px 10px",
                      borderRadius: "6px",
                    }}
                  >
                    Today
                  </button>
                )}
                <div className="row">
                  {savedata.map((product, index) => (
                    <div className="col-12 md:col-6 lg:col-6 xl:col-4" key={index} >
                      <div
                        className="card p-2 mt-0"
                        style={{ borderRadius: "8px" }}
                      >
                        <div className="d-sm-flex">
                          <div className="img" style={{ position: "relative", cursor: "pointer" }} onClick={() => { handleview(index, product.userid); handleimage(index) }}>
                            <img className="img-fluid w-100 pb-2"
                              src={`${Assetsapi}/adimages/${product.images[0]}`}
                              alt={`House ${index + 1}`}

                              style={{
                                width: "125px",
                                height: "125px",
                                borderRadius: "8px",
                              }}
                            />
                            <button
                              onClick={() => handleChange(index)}
                              className="heart"
                              style={{
                                position: "absolute",
                                top: "3px",
                                right: "4px",
                                padding: "0px 5px",
                              }}
                            >
                              {product.isFavorited ? (
                                <i
                                  className="fi fi-sr-heart"
                                  style={{ color: "red", fontSize: "13px" }}
                                ></i>
                              ) : (
                                <i
                                  className="fi fi-rr-heart"
                                  style={{ color: "#8B8B8B", fontSize: "13px" }}
                                ></i>
                              )}
                            </button>
                          </div>
                          <div className="card-left w-100 ms-sm-2">
                            <div className="d-flex justify-content-between align-items-start">
                              <div className="d-flex">
                                <p
                                  className="cate"
                                  style={{
                                    color: "#BBB9B9",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    // borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  Category :
                                  <span
                                    className="title ms-1"
                                    style={{
                                      color: "#2824DF",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                    }}
                                  >
                                    {product.post_category.length > 10
                                      ? `${product.post_category.substring(0, 10)}...`
                                      : product.post_category}
                                  </span>
                                </p>

                              </div>
                              <div className="d-flex gap-2">
                                <button className="ms-2 buy">Buy</button>
                                {/* <button className=""> */}
                                {/* <i className="fi fi-rr-trash"></i> */}
                                {/* </button> */}
                                {/* <i
                                   class="fi fi-sr-cross-small"
                                   style={{ color: "#8B8B8B", fontSize: "18px" }}
                                 ></i> */}
                                <button
                                  style={{ border: 'none', backgroundColor: "white" }}
                                  onClick={() => deletePostViewHistory(product.post_id)}
                                >
                                  <RxCross2 style={{ color: "#8B8B8B", fontSize: "18px" }} />
                                </button>
                              </div>
                            </div>
                            <p
                              style={{
                                color: "#4A4A4A",
                                fontWeight: 700,
                                fontSize: "16px",
                              }}
                            >

                              {product.post_title.length > 15
                                ? `${product.post_title.substring(0, 15)}...`
                                : product.post_title}

                            </p>

                            <div class="d-flex">
                              <div class="">
                                <p
                                  className=""
                                  style={{
                                    color: "#8B8B8B",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                    borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  12,000 Kms
                                </p>
                              </div>
                              <p
                                className="ms-2"
                                style={{
                                  color: "#8B8B8B",
                                  fontWeight: 400,
                                  fontSize: "13px",
                                }}
                              >
                                Electric charging
                              </p>
                            </div>
                            <p
                              style={{
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#4A4A4A",
                              }}
                              className="mb-1"
                            >
                              {currencySymbols[product.salaryCurrency] || product.salaryCurrency}
                              {product.post_price.length > 9
                                ? `${product.post_price.substring(0, 9)}...`
                                : product.post_price}
                            </p>

                            <div
                              className="d-flex justify-content-between pb-4"
                              style={{ borderTop: "1px solid #E6E6E6" }}
                            >
                              <p
                                className="dot mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-marker"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {product.post_location.length > 7
                                  ? `${product.post_location.substring(0, 7)}...`
                                  : product.post_location}
                              </p>
                              <p
                                className="dot ms-2 mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-calendar"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {(() => {
                                  const date = moment(product.created_at);
                                  const formattedDate = date.format('DD MMMM YYYY');
                                  return formattedDate; // Return the formatted date
                                })()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-search"
                role="tabpanel"
                aria-labelledby="pills-search-tab"
              >
                {/* Recent search content */}
              </div>
            </div>
          )}

          {!display && yesterdayproducts && (
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active w-full"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {yesterdayproducts != '' && (
                  <button
                    className="h6 mb-3"
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "0.5rem",
                      background: "#f1f1f1b3",
                      border: "none",
                      color: "#8B8B8B",
                      padding: "5px 10px",
                      borderRadius: "6px",
                    }}
                  >
                    Yesterday
                  </button>
                )}

                <div className="grid">
                  {yesterdayproducts.map((product, index) => (
                    <div className="col-12 md:col-6 lg:col-6 xl:col-4" key={index} >
                      <div
                        className="card p-2 mt-0"
                        style={{ borderRadius: "8px" }}
                      >
                        <div className="d-sm-flex">
                          <div className="img" style={{ position: "relative", cursor: "pointer" }} onClick={() => { handleimage_yesterday(index); handleview_yesterday(index, product.userid) }}>


                            <img className="img-fluid w-100 pb-2"
                              src={`${Assetsapi}/adimages/${product.images[0]}`}
                              alt={`House ${index + 1}`}

                              style={{
                                width: "125px",
                                height: "125px",
                                borderRadius: "8px",
                              }}
                            />


                            <button
                              onClick={() => handleChange(index)}
                              className="heart"
                              style={{
                                position: "absolute",
                                top: "4px",
                                right: "4px",
                                padding: "0px 5px",
                              }}
                            >
                              {product.isFavorited ? (
                                <i
                                  className="fi fi-sr-heart"
                                  style={{ color: "red", fontSize: "13px" }}
                                ></i>
                              ) : (
                                <i
                                  className="fi fi-rr-heart"
                                  style={{ color: "#8B8B8B", fontSize: "13px" }}
                                ></i>
                              )}
                            </button>

                          </div>
                          <div className="card-left w-100 ms-sm-2">
                            <div className="d-flex justify-content-between align-items-start">
                              <div className="d-flex">
                                <p
                                  className="cate"
                                  style={{
                                    color: "#BBB9B9",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    // borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  Category :
                                  <span
                                    className="title ms-1"
                                    style={{
                                      color: "#2824DF",
                                      fontWeight: 500,
                                      fontSize: "16px",

                                    }}
                                  >
                                    {product.post_category.length > 10
                                      ? `${product.post_category.substring(0, 10)}...`
                                      : product.post_category}
                                  </span>
                                </p>
                              </div>
                              <div className="d-flex gap-2">

                                <button className="ms-2 buy">Buy</button>
                                <button
                                  style={{ border: 'none', backgroundColor: "white" }}
                                  onClick={() => deletePostViewHistory(product.post_id)}
                                >
                                  <RxCross2 style={{ color: "#8B8B8B", fontSize: "18px" }} />
                                </button>
                              </div>
                            </div>
                            <p
                              style={{
                                color: "#4A4A4A",
                                fontWeight: 700,
                                fontSize: "16px",
                              }}
                            >

                              {product.post_title.length > 15
                                ? `${product.post_title.substring(0, 15)}...`
                                : product.post_title}
                            </p>

                            <div class="d-flex">
                              <div class="cate2">
                                <p
                                  className=""
                                  style={{
                                    color: "#8B8B8B",
                                    fontWeight: 400,
                                    fontSize: "13px",

                                    paddingRight: "6px",
                                  }}
                                >
                                  12,000 Kms
                                </p>
                              </div>
                              <p
                                className="ms-2"
                                style={{
                                  color: "#8B8B8B",
                                  fontWeight: 400,
                                  fontSize: "13px",
                                }}
                              >
                                Electric charging
                              </p>
                            </div>
                            <p
                              style={{
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#4A4A4A",
                              }}
                              className="mb-1"
                            >
                              {currencySymbols[product.salaryCurrency] || product.salaryCurrency}
                              {product.post_price.length > 9
                                ? `${product.post_price.substring(0, 9)}...`
                                : product.post_price}
                            </p>

                            <div
                              className="d-flex justify-content-between pb-4"
                              style={{ borderTop: "1px solid #E6E6E6" }}
                            >
                              <p
                                className="dot mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-marker"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {product.post_location.length > 7
                                  ? `${product.post_location.substring(0, 7)}...`
                                  : product.post_location}
                              </p>
                              <p
                                className="dot ms-2 mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-calendar "
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {(() => {
                                  const date = moment(product.created_at);
                                  const formattedDate = date.format('DD MMMM YYYY');
                                  return formattedDate; // Return the formatted date
                                })()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <button
                  className="h6 mb-0"
                  style={{
                    borderBottom: "1px solid black",
                    marginTop: "0.5rem",
                    background: "#f1f1f1b3",
                    border: "none",
                    color: "#8B8B8B",
                    padding: "5px 10px",
                    borderRadius: "6px",
                  }}
                >
                  Yesterday
                </button>
                <div className="row">
                  {yesterdaycontact.map((vehicle, index) => (
                    <div className="col-12 md:col-6 lg:col-6 xl:col-4" key={index}>
                      <div
                        className="card p-2 mt-0"
                        style={{ borderRadius: "8px" }}
                      >
                        <div className="d-sm-flex">
                          <div className="img" style={{ position: "relative", cursor: "pointer" }} onClick={() => { handleview(index, vehicle.userid); handleimage(index) }}>
                            <img className="img-fluid w-100 pb-2"
                              src={`${Assetsapi}/adimages/${vehicle.images[0]}`}
                              alt={`House ${index + 1}`}

                              style={{
                                width: "125px",
                                height: "125px",
                                borderRadius: "8px",
                              }}
                            />
                            <button
                              onClick={() => handleChanges(index)}
                              className="heart"
                              style={{
                                position: "absolute",
                                top: "3px",
                                right: "4px",
                                padding: "0px 5px",
                              }}
                            >
                              <i
                                className={
                                  vehicle.isSold
                                    ? "fi fi-sr-heart"
                                    : "fi fi-rr-heart"
                                }
                                style={{
                                  color: vehicle.isSold ? "red" : "#8B8B8B",
                                  fontSize: "13px",
                                }}
                              ></i>
                            </button>
                          </div>
                          <div className="card-left w-100 ms-sm-2">
                            <div className="d-flex justify-content-between align-items-start">
                              <div className="d-flex">
                                <p
                                  className="cate"
                                  style={{
                                    color: "#BBB9B9",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    // borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  Category :
                                  <span
                                    className="title ms-1"
                                    style={{
                                      color: "#2824DF",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                    }}
                                  >
                                    {vehicle.post_category.length > 10
                                      ? `${vehicle.post_category.substring(0, 10)}...`
                                      : vehicle.post_category}
                                  </span>
                                </p>
                              </div>
                              <div className="d-flex gap-2">
                                <button className="ms-2 buy">Buy</button>
                                <button
                                  style={{ border: 'none', backgroundColor: "white" }}
                                  onClick={() => deletePostViewHistory(vehicle.post_id)}
                                >
                                  <RxCross2 style={{ color: "#8B8B8B", fontSize: "18px" }} />
                                </button>
                              </div>
                            </div>
                            <p
                              style={{
                                color: "#4A4A4A",
                                fontWeight: 700,
                                fontSize: "16px",
                              }}
                            >
                              {vehicle.post_title.length > 15
                                ? `${vehicle.post_title.substring(0, 15)}...`
                                : vehicle.post_title}
                            </p>

                            <div class="d-flex">
                              <div class="">
                                <p
                                  className=""
                                  style={{
                                    color: "#8B8B8B",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                                >
                                  12,000 Kms
                                </p>
                              </div>
                            </div>

                            {/* 
                            <div className="d-flex ">
                              <p>
                                <i
                                  className="fi fi-rr-phone-call me-1"
                                  style={{ color: "#2196F3", fontSize: "12px" }}
                                ></i>
                                <span
                                  style={{ color: "#2196F3", fontSize: "12px" }}
                                >
                                  9001234567
                                </span>
                              </p>

                              <p className="ms-3">
                                <i
                                  className="fi fi-rr-comment-dots me-1"
                                  style={{ color: "#2196F3", fontSize: "12px" }}
                                ></i>
                                <span
                                  style={{ color: "#2196F3", fontSize: "12px" }}
                                >
                                  Message
                                </span>
                              </p>
                            </div> */}
                            {/* 
                            <button
                              className=""
                              style={{
                                color: "#8B8B8B",
                                backgroundColor: "#E6E6E6",
                                borderRadius: "4px",
                                border: "none",
                                fontSize: "12px",
                                marginBottom: "6px",
                              }}
                            >
                              <i className="fi fi-rs-ban"></i>
                              <span className="ms-1">Rented out</span>
                            </button> */}
                            <p
                              style={{
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#4A4A4A",
                              }}
                              className="mb-1"
                            >
                              {currencySymbols[vehicle.salaryCurrency] || vehicle.salaryCurrency}
                              {vehicle.post_price.length > 9
                                ? `${vehicle.post_price.substring(0, 9)}...`
                                : vehicle.post_price}
                            </p>

                            <div
                              className="d-flex justify-content-between pb-4"
                              style={{ borderTop: "1px solid #E6E6E6" }}
                            >
                              <p
                                className="dot mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-marker"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {vehicle.post_location.length > 7
                                  ? `${vehicle.post_location.substring(0, 7)}...`
                                  : vehicle.post_location}
                              </p>
                              <p
                                className="dot ms-2 mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-calendar"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {(() => {
                                  const date = moment(vehicle.created_at);
                                  const formattedDate = date.format('DD MMMM YYYY');
                                  return formattedDate; // Return the formatted date
                                })()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                style={{ width: "100%" }}
              >

                <div className="row">
                  {savedata.map((product, index) => (
                    <div className="col-12 md:col-6 lg:col-6 xl:col-4" key={index} >
                      <div
                        className="card p-2 mt-0"
                        style={{ borderRadius: "8px" }}
                      >
                        <div className="d-flex">
                          <div className="img" style={{ position: "relative", cursor: "pointer" }} onClick={() => { handleview(index, product.userid); handleimage(index) }}>
                            <img
                              src={`${Assetsapi}/adimages/${product.images[0]}`}
                              alt={`House ${index + 1}`}

                              style={{
                                width: "125px",
                                height: "125px",
                                borderRadius: "8px",
                              }}
                            />
                            <button
                              onClick={() => handleChange(index)}
                              className="heart"
                              style={{
                                position: "absolute",
                                top: "3px",
                                right: "4px",
                                padding: "0px 5px",
                              }}
                            >
                              {product.isFavorited ? (
                                <i
                                  className="fi fi-sr-heart"
                                  style={{ color: "red", fontSize: "13px" }}
                                ></i>
                              ) : (
                                <i
                                  className="fi fi-rr-heart"
                                  style={{ color: "#8B8B8B", fontSize: "13px" }}
                                ></i>
                              )}
                            </button>
                          </div>
                          <div className="card-left w-100 ms-sm-2">
                            <div className="d-flex justify-content-between align-items-start">
                              <div className="d-flex">
                                <p
                                  className="cate"
                                  style={{
                                    color: "#BBB9B9",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    // borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  Category :
                                  <span
                                    className="title ms-1"
                                    style={{
                                      color: "#2824DF",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                    }}
                                  >
                                    {product.post_category}
                                  </span>
                                </p>
                              </div>
                              <div className="d-flex gap-2">
                                <button className="ms-2 buy">Buy</button>
                                {/* <button className=""> */}
                                {/* <i className="fi fi-rr-trash"></i> */}
                                {/* </button> */}
                                {/* <i
                                 class="fi fi-sr-cross-small"
                                 style={{ color: "#8B8B8B", fontSize: "18px" }}
                               ></i> */}
                                <button
                                  style={{ border: 'none', backgroundColor: "white" }}
                                  onClick={() => deletePostViewHistory(product.post_id)}
                                >
                                  <RxCross2 style={{ color: "#8B8B8B", fontSize: "18px" }} />
                                </button>
                              </div>
                            </div>
                            <p
                              style={{
                                color: "#4A4A4A",
                                fontWeight: 700,
                                fontSize: "16px",
                              }}
                            >
                              {product.post_title.length > 15
                                ? `${product.post_title.substring(0, 15)}...`
                                : product.post_title}
                            </p>

                            <div class="d-flex">
                              <div class="">
                                <p
                                  className=""
                                  style={{
                                    color: "#8B8B8B",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                    borderRight: "2px solid #E6E6E6",
                                    paddingRight: "6px",
                                  }}
                                >
                                  12,000 Kms
                                </p>
                              </div>
                              <p
                                className="ms-2"
                                style={{
                                  color: "#8B8B8B",
                                  fontWeight: 400,
                                  fontSize: "13px",
                                }}
                              >
                                Electric charging
                              </p>
                            </div>
                            <p
                              style={{
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#4A4A4A",
                              }}
                              className="mb-1"
                            >
                              {currencySymbols[product.salaryCurrency] || product.salaryCurrency}
                              {product.post_price.length > 9
                                ? `${product.post_price.substring(0, 9)}...`
                                : product.post_price}
                            </p>

                            <div
                              className="d-flex justify-content-between pb-4"
                              style={{ borderTop: "1px solid #E6E6E6" }}
                            >
                              <p
                                className="dot mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-marker"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {product.post_location.length > 7
                                  ? `${product.post_location.substring(0, 7)}...`
                                  : product.post_location}
                              </p>
                              <p
                                className="dot ms-2 mt-1"
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#8B8B8B",
                                }}
                              >
                                <i
                                  className="fi fi-rr-calendar"
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                {(() => {
                                  const date = moment(product.created_at);
                                  const formattedDate = date.format('DD MMMM YYYY');
                                  return formattedDate; // Return the formatted date
                                })()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-search"
                role="tabpanel"
                aria-labelledby="pills-search-tab"
              >
                Recent search content
              </div>
            </div>
          )}

          {display && (
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active w-full"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <button
                  className="h6 mb-3"
                  style={{
                    borderBottom: "1px solid black",
                    marginTop: "0.5rem",
                    background: "#f1f1f1b3",
                    border: "none",
                    color: "#8B8B8B",
                    padding: "5px 10px",
                    borderRadius: "6px",
                  }}
                >
                  History
                </button>
                {yesterdayproducts.length === 0 ? (
                  <p style={{ textAlign: "center", color: "#8B8B8B", padding: "20px" }}>
                    History not found
                  </p>
                ) : (
                  <div className="grid">
                    {yesterdayproducts.map((product, index) => (
                      <div className="lg:col-4 md:col-6 col-12" key={product.post_id}>
                        <div className="card p-2 mt-0" style={{ borderRadius: "8px" }}>
                          <div className="d-sm-flex">
                            <div className="img" style={{ position: "relative", cursor: "pointer" }} onClick={() => { handleview(index, product.userid); handleimage(index) }}>
                              <img
                                className="img-fluid w-100 pb-2"
                                src={`${Assetsapi}/adimages/${product.images[0]}`}
                                alt={`Product ${index + 1}`}
                                style={{
                                  width: "125px",
                                  height: "125px",
                                  borderRadius: "8px",
                                }}
                              />
                              <button
                                onClick={() => handleChange(index)}
                                className="heart"
                                style={{
                                  position: "absolute",
                                  top: "4px",
                                  right: "4px",
                                  padding: "0px 5px",
                                }}
                              >
                                {product.isFavorited ? (
                                  <i className="fi fi-sr-heart" style={{ color: "red", fontSize: "13px" }}></i>
                                ) : (
                                  <i className="fi fi-rr-heart" style={{ color: "#8B8B8B", fontSize: "13px" }}></i>
                                )}
                              </button>
                            </div>
                            <div className="card-left w-100 ms-sm-2">
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="d-flex">
                                  <p
                                    className="cate"
                                    style={{
                                      color: "#BBB9B9",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      paddingRight: "6px",
                                    }}
                                  >
                                    Category :
                                    <span
                                      className="title ms-1"
                                      style={{
                                        color: "#2824DF",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                      }}
                                    >
                                      {product.post_category}
                                    </span>
                                  </p>
                                </div>
                                <div className="d-flex gap-2">
                                  <button className="ms-2 buy">Buy</button>
                                  <button
                                    style={{ border: 'none', backgroundColor: "white" }}
                                    onClick={() => deletePostViewHistory(product.post_id)}
                                  >
                                    <RxCross2 style={{ color: "#8B8B8B", fontSize: "18px" }} />
                                  </button>
                                </div>
                              </div>
                              <p
                                style={{
                                  color: "#4A4A4A",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                }}
                              >
                                {product.post_title.length > 15
                                  ? `${product.post_title.substring(0, 15)}...`
                                  : product.post_title}
                              </p>
                              <div className="d-flex">
                                <p
                                  className=""
                                  style={{
                                    color: "#8B8B8B",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                    paddingRight: "6px",
                                  }}
                                >
                                  12,000 Kms
                                </p>
                                <p
                                  className="ms-2"
                                  style={{
                                    color: "#8B8B8B",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                                >
                                  Electric charging
                                </p>
                              </div>
                              <p
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#4A4A4A",
                                }}
                                className="mb-1"
                              >
                                {currencySymbols[product.salaryCurrency] || product.salaryCurrency}
                                {product.post_price.length > 9
                                  ? `${product.post_price.substring(0, 9)}...`
                                  : product.post_price}
                              </p>
                              <div className="d-flex justify-content-between pb-4" style={{ borderTop: "1px solid #E6E6E6" }}>
                                <p
                                  className="dot mt-1"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                  }}
                                >
                                  <i
                                    className="fi fi-rr-marker"
                                    style={{
                                      fontSize: "12px",
                                      color: "#8B8B8B",
                                      marginRight: "4px",
                                    }}
                                  ></i>
                                  {product.post_location.length > 7
                                    ? `${product.post_location.substring(0, 7)}...`
                                    : product.post_location}
                                </p>
                                <p
                                  className="dot ms-2 mt-1"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                  }}
                                >
                                  <i
                                    className="fi fi-rr-calendar"
                                    style={{
                                      fontSize: "12px",
                                      color: "#8B8B8B",
                                      marginRight: "4px",
                                    }}
                                  ></i>
                                  {moment(product.created_at).format('DD MMMM YYYY')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}



        </div>
      </div>
    </div>
  );
};

export default Activity;