import React, { useEffect, useRef, useState } from "react";
import logo from "../../src/assets/images/KodukkuLogo.svg";
import { cities } from "../Data/DistrictService";
import { IoIosArrowDown, IoIosArrowUp, } from "react-icons/io";
import under from "../assets/images/Development.svg";
import "./Maintainence.css"
import Card from './Card'
import { AiFillPlusCircle } from "react-icons/ai";
import Propert_data from './Buy.json'
import listings from './home.json'
const style = {
  border: "none",
  width: "6rem",
  padding: "5px 11px 5px 9px",
  color: "rgb(187, 185, 185)",
  textWrap: "nowrap",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};




const Maintainence = () => {

  const [selectedCity, setSelectedCity] = useState("");
  const [listvechicles, setlistvechicles] = useState([])
  const [display, setdisplay] = useState(false)
  const [display1, setdisplay1] = useState(false)
  const [searchInput, setSearchInput] = useState("");
  const [suggestedResults, setSuggestedResults] = useState([]);
  const [properties, setproperties] = useState("")
  const [typevalue, settypevalue] = useState("")


  const handleChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const [post, setPost] = useState(false);

  const postToggle = () => {
    setPost(!post);
  };

  const handleSelection = (type, option) => {
    if (type === "Buy") {
      const filteredBuy = Propert_data.Buy.filter(data => data === option);
      console.log(filteredBuy);
      setproperties(filteredBuy)
      settypevalue(type)
      setdisplay1(true)

    } else if (type === "Rent/Lease") {
      const filteredRent = Propert_data.Rent.filter(data => data === option);
      console.log(filteredRent);
      setproperties(filteredRent)
      settypevalue(type)
      setdisplay1(true)

    } else {
      console.log("Invalid selection type");
    }
  }

  const handlevechicles = (e) => {
    let val = e.target.textContent
    const Vehiclevalue = listings.filter((data) => data.type === val)
    setlistvechicles(Vehiclevalue)
    setdisplay(true)

  }

  //  const handleSearch = () => {
  //   const filteredListings = listings.filter((data) =>
  //     data.category && data.category.toLowerCase().includes(searchInput.toLowerCase())
  //   );
  //   setlistvechicles(filteredListings);
  //   setdisplay(true);
  // };

  const handleSearch = () => {
    const filteredListings = listings.filter((data) => {
      const isCategoryMatch = data.category.toLowerCase().includes(searchInput.toLowerCase());
      const isTitleMatch = data.title.toLowerCase().includes(searchInput.toLowerCase());
      return isCategoryMatch || isTitleMatch;
    });
    setlistvechicles(filteredListings);
    setdisplay(true);
  };

  // const handleInputChange = (input) => {
  //   setSearchInput(input);
  //   // Filter suggestions based on the input
  //   const filteredSuggestions = suggestions.filter((suggestion) =>
  //     suggestion.toLowerCase().includes(input.toLowerCase())
  //   );
  //   setSuggestedResults(filteredSuggestions);
  // };



  return (
    // <div className="Maintain">
    //   <div className="card d-flex justify-content-between">
    //     <div className="text-container" >
    //       <h2
    //         className="text-center"
    //         style={{ fontSize: "30px", fontWeight: "500" }}
    //       >
    //         Welcome To
    //       </h2>
    //       <h2
    //         className="text-center text-kod"
    //         style={{ fontWeight: "800", color: "#1877F2" }}
    //       >
    //         Kodukku
    //       </h2>
    //       <p
    //         className="text-center"
    //         style={{ fontSize: "14px", fontWeight: "500" }}
    //       >
    //         Our Website is under Development
    //       </p>
    //     </div>
    //     <div className="img-under" style={{ margin:" 0 auto"}}>
          
    //         <img src={under} alt="" className="img-fluid" />
          
    //     </div>
    //   </div>
    // </div>
    <>
{/*    
    <div className="adnav">
      <nav className="d-md-flex  align-items-center">
        <div className="img">
          <img
            src={logo}
            alt=""
            style={{
              border: "2px solid #2196F3",
              borderRadius: "10px",
              padding: "8px",
              width: "3.4em",
            }}
          />
        </div>
        <div className="d-md-flex align-items-center maintain-card">
          <button className="btn-Categories mt-md-0 my-2" onClick={toggleVisibility}>
            Categories (3)
            {isVisible ? (
              <IoIosArrowDown className="ms-2" />
            ) : (
              <IoIosArrowUp className="ms-2" />
            )}
            {isVisible && (
              <div className="catepop">
                <div className="scroll">
                  <div className="d-md-flex ">
                    <div className="line-card">
                      <h5>Properties</h5>
                      <div className="d-md-flex justify-content-between">
                        <div className="">
                          <h6>Buy</h6>
                          <ul>
                            {Propert_data.Buy.map((data, index) => (
                               <li onClick={() => handleSelection("Buy", data)} key={index}>{data}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="">
                          <h6>Rent/Lease</h6>
                          <ul>
                            {Propert_data.Rent.map((data, index) => (
                             <li onClick={() => handleSelection("Rent/Lease", data)} key={index}>{data}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="">
                          <h6>PG/Hostel</h6>
                          <ul>
                            {Propert_data.Hostel.map((data, index) => (
                              <li key={index}>{data}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="line-card">
                      <h5>Vehicles</h5>
                      <ul>
                      {Propert_data.Vehicles.map((data, index) => (
                              <li onClick={handlevechicles} key={index}>{data}</li>
                            ))}
                      </ul>
                    </div>
                    <div className="">
                      <h5>Top Management Levels</h5>
                      <ul>
                        <li>Information Technology</li>
                        <li>Banking & Finance</li>
                        <li>Health Care</li>
                        <li>Human Resource</li>
                        <li>Logistics & Transports</li>
                        <li>Other Jobs</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </button>

          <div className="search-container">
            <div>
              <div className="d-flex justify-content">
                <div className="d-flex align-items-center search-bar-home">
                  <FaMapMarkerAlt
                style={{
                  fontSize: "33px",
                  marginRight: "5px",
                  color: "#BBB9B9",
                }}
              />
                  <img src={location} alt="" style={{ width: "20px" }} />
                  <div className="input-container">
                    <select
                      value={selectedCity}
                      className="form-control search-loco"
                      onChange={handleChange}
                      style={style}
                    >
                      <option value="">Select a city</option>
                      {cities.map((city, index) => (
                        <option key={index} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="ps-2 form-control search"
                    placeholder="Search for Anything"
                    style={{
                      borderTop: "none",
                      borderRight: "none",
                      borderBottom: "none",
                      borderImage: "initial",
                      borderLeft: "2px solid rgb(187, 185, 185)",
                      borderRadius: "0px",
                      color: "#8B8B8B",
                      textTransform: "capitalize",
                      width: "24rem",
                    }}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                   <button className="btn-search" onClick={handleSearch}>Search</button>

                   {suggestedResults.length > 0 && (
                  <ul>
                    {suggestedResults.map((suggestion, index) => (
                      <li key={index}>{suggestion}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <button className="btn-post-home" onClick={() => setPost(true)}>
          <AiFillPlusCircle
            className="me-2"
            style={{ fontSize: "20px", color: "#fff" }}
          />
          Post
        </button> 
    
       
        <Dialog
          // header="Header"
          showHeader={false}
          visible={post}
          onHide={() => setPost(false)}
          className="post-modal"
          style={{ width: "30vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className="">
            <div className="flex justify-content-between">
              <h6>Select a Categories</h6>
              <RxCross2
                style={{ fontSize: "22px" }}
                onClick={() => setPost(false)}
              />
            </div>
            <div className="mod-content">
              <div className="flex justify-content-center">
                <div className="mo-ca">
                  <div className="img-card-mod">
                    <img src={suitcase} alt="" className="mod-img" />
                  </div>
                  <p>Jobs</p>
                </div>
                <div className="mo-ca">
                  <div className="img-card-mod">
                    <img src={car} alt="" className="mod-img" />
                  </div>
                  <p>Vehicle</p>
                </div>
                <div className="mo-ca">
                  <div className="img-card-mod">
                    <img src={house} alt="" className="mod-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog> 
      </nav>
    </div> */}
 
         <div className="child_contanier2">
           <Card listvechicles={listvechicles} display={display} properties={properties} typevalue={typevalue} display1={display1} />
         </div>




    </>



  );
};

export default Maintainence;
