export const BASE_URL = "https://ktest.kodukku.com/api";

const ASSETS_URL = BASE_URL.split("/api")[0]

export const ASSETS_API = {
    ASSETS_AD_IMAGES: (url) => `${ASSETS_URL}/assets/adimages/${url}`,
    ASSETS_PROFILE_IMAGES: (url) => `${ASSETS_URL}/assets/UserProfileImage/${url}`
};

export const CINE_JOB_API = {
    GET_ALL_CINE_JOB: `${BASE_URL}/cinejob`,
    GET_BY_CINE_JOB: (id) => `${BASE_URL}/cinejob/${id}`,
    GET_CINE_JOB_DETAIL: (id) => `${BASE_URL}/cinejob/detail/${id}`,
    GET_USER_ID_CINE_JOB: (userId) => `${BASE_URL}/cinejob/mypost/${userId}`,
    POST_CINE_JOB: `${BASE_URL}/cinejob`,
    POST_CINE_JOB_APPLIED: `${BASE_URL}/cinejob/status`,
    UPDATE_STATUS_APPLIED: `${BASE_URL}/cinejob/status/update`,
    CHECK_APPLICATION_STATUS: (jobId, userId) => `${BASE_URL}/cinejob/status/${jobId}?userId=${userId}`
};
