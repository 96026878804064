import React, { useState, useEffect, useContext } from 'react';
import { DataContext } from '../Admin/DataContext';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { useRef } from 'react';
import '../utils/threeDot.css'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Nodeapi,ProfileImgApi } from '../config/serverUrl';
import { PhoneCall, PhoneDisconnect } from "@phosphor-icons/react";



const AudioCall = ({ CallerDetail, setOnAudioCall, CallChatmasterid, setCallAccept, callRoomid }) => {
  const authdata = useSelector((state) => state.auth.user);
  
  const { setCallStatus, setAudioCall, CallfromUser, setCallfromUser, setCallRoomid,callerprofile,setCallerprofile } = useContext(DataContext);
  const navigate = useNavigate();

  const socketRef = useRef();
  
  useEffect(() => {
    getuser();
    

    socketRef.current = io(process.env.REACT_APP_VIDEOAPI);
    
    socketRef.current.on('connect', () => {
      console.log('Connected to server');
    });

    socketRef.current.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

    const getuser = async() => {
      const res = await axios.get(`${Nodeapi}/getUserProfile?id=${CallerDetail?.data?.receivercallid.replace(/'/g, '')}`);
      setCallerprofile(res.data.data.message.profile_image);
    }


  const handleAccept = () => {
    setOnAudioCall(false);
    setCallAccept(true);
    setAudioCall(true);
    setCallfromUser(0);
    setCallRoomid(callRoomid);
    setCallStatus('call_attan');
  };

  const handleDecline = () => {
    setOnAudioCall(false);
    console.log('Attempting to emit hang-up event',callRoomid);
    socketRef.current.emit('join-room', callRoomid,"","","","","","decline");
    // handleSendCallmessage(authdata?.id,CallerDetail?.data?.receivercallid.replace(/'/g, ''),"No Answer",callRoomid,"AudiocallRequest");
  };

  

  return (
    <div
    style={{
      backgroundColor: "#4A4A4A",
      width: "280px",
      height: "310px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "32px 20px",
      borderRadius: "10px",
      position:"absolute",
      zIndex:"9999",
      top:"0",
      right:"0",
    }}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
      }}>
      <div
        style={{
          backgroundColor: "white",
          height: "80px",
          width: "80px",
          borderRadius: "50%",
          overflow: "hidden",
        }}>
        <img
          style={{
            height: "80px",
            width: "80px",
          }}
          src={`${ProfileImgApi}/${callerprofile}`}
          alt=""
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <h6 style={{ color: "#FFFFFF", fontSize: "16px" }}>{CallerDetail?.data?.callername}</h6>
        <p style={{ color: "#BBB9B9", fontSize: "14px" }}>Voice Call</p>
      </div>
    </div>

    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        gap: "16px",
      }}>
      <button
      onClick={handleDecline}
        className="callActionBtnAccept"
        style={{
          border: "none",
          outline: "none",
          backgroundColor: "#FF3B3C",
          borderRadius: "10px",
          padding: "10px 18px",
          color: "white",
          fontSize: "14px",
        }}>
        <div>
        <PhoneDisconnect size={20} style={{ marginRight: "5px" }} />{" "}
          Decline
        </div>
      </button>
      <button
      onClick={handleAccept}
        className="callActionBtnDecline"
        style={{
          border: "none",
          outline: "none",
          backgroundColor: "#07C271",
          borderRadius: "10px",
          padding: "10px 18px",
          color: "white",
          fontSize: "14px",
        }}>
        <div>
          <PhoneCall size={20} style={{ marginRight: "5px" }} /> Accept
        </div>
      </button>
    </div>
  </div>
  );
};

export default AudioCall;
