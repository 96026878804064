import React, { useEffect, useState } from 'react';
import JobSeekingCard from './JobSeekingCard';
import axios from 'axios';
import { Nodeapi } from '../../../config/serverUrl';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import CategoryIcon from '@mui/icons-material/Category';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import SchoolIcon from '@mui/icons-material/School';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Tooltip from '@mui/material/Tooltip';
import categories from '../data/Category';
import { Box, Button, Grid, Container } from '@mui/material';
import { CINE_JOB_API } from '../../../config/api';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const checkBoxStyle = {
  color: '#BBB9B9',
  '&.MuiCheckbox-root': {
    '&.Mui-checked': {
      color: '#2196f3',
    },
  },
};

// Mapping for salary ranges
const salaryRanges = {
  '₹ 0-3 Lakhs': [0, 300000],
  '₹ 3-6 Lakhs': [300000, 600000],
  '₹ 6-10 Lakhs': [600000, 1000000],
  '₹ 10-15 Lakhs': [1000000, 1500000],
  '₹ 15+ Lakhs': [1500000, Infinity],
};

// Mapping for experience ranges
const experienceRanges = {
  'Fresher': [0, 0],
  '1-2 Years': [1, 2],
  '2-5 Years': [2, 5],
  '5-7 Years': [5, 7],
  '7-15 Years': [7, 15],
  '15+ Years': [15, Infinity],
};

const JobSeeking = () => {
  const [jobPostDetails, setJobPostDetails] = useState([]);
  const [openCategory, setOpenCategory] = useState(true);
  const [openWorkShifts, setOpenWorkShifts] = useState(false);
  const [openWorkModes, setOpenWorkModes] = useState(false);
  const [openWorkType, setOpenWorkType] = useState(false);
  const [openExperience, setOpenExperience] = useState(false);
  const [openSalary, setOpenSalary] = useState(false);
  const [openQualification, setOpenQualification] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedWorkShifts, setSelectedWorkShifts] = useState({});
  const [selectedWorkModes, setSelectedWorkModes] = useState({});
  const [selectedWorkType, setSelectedWorkType] = useState({});
  const [selectedExperience, setSelectedExperience] = useState({});
  const [selectedSalary, setSelectedSalary] = useState({});
  const [selectedQualification, setSelectedQualification] = useState({});

  const [showMoreCategories, setShowMoreCategories] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleToggle = (section, setter) => {
    setter((prev) => !prev);
  };

  const handleCheckboxChange = (setter, selectedState) => (item) => {
    setter((prevState) => {
      const newState = { ...prevState };
      if (newState[item]) {
        delete newState[item];
      } else {
        newState[item] = true;
      }
      return newState;
    });
  };

  const handleClearAll = () => {
    setSelectedCategories({});
    setSelectedWorkShifts({});
    setSelectedWorkModes({});
    setSelectedWorkType({});
    setSelectedExperience({});
    setSelectedSalary({});
    setSelectedQualification({});
  };

  const isTextTooLong = (text, maxLength = 20) => text.length > maxLength;

  const renderList = (items, selectedState, setter, handleCheckboxChange) => (
    <List component="div" disablePadding>
      {items.map((item) => (
        <ListItemButton key={item}
          sx={{
            pl: 4,
            py: 0.1,
          }} onClick={() => handleCheckboxChange(item)}>
          <ListItemIcon sx={{ minWidth: "auto" }}>
            <Checkbox
              edge="start"
              checked={!!selectedState[item]}
              tabIndex={-1}
              disableRipple
              sx={checkBoxStyle}
            />
          </ListItemIcon>
          <Tooltip title={isTextTooLong(item) ? item : ''} arrow placement="right">
            <ListItemText primary={item} sx={{
              color: '#8b8b8b',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }} />
          </Tooltip>
        </ListItemButton>
      ))}
    </List>
  );

  const fetchPostData = async () => {
    try {
      const { data } = await axios.get(CINE_JOB_API.GET_ALL_CINE_JOB);
      setJobPostDetails(data);
    } catch (err) {
      console.error('Error fetching job details:', err);
    }
  }

  useEffect(() => {
    fetchPostData()
  }, []);

  const filteredCategories = Object.keys(categories).filter(category =>
    category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleClear = () => {
    setSearchQuery('');
  };
  const filterJobs = (jobs) => {
    return jobs.filter(job => {
      const jobDetails = job.jobDetail;
      const candidateDetails = job.candidateDetail;

      const categoryMatch = Object.keys(selectedCategories).length === 0 ||
        selectedCategories[jobDetails.industry];

      const workShiftsMatch = Object.keys(selectedWorkShifts).length === 0 ||
        jobDetails.workShifts.some(shift => selectedWorkShifts[shift]);

      const workModesMatch = Object.keys(selectedWorkModes).length === 0 ||
        jobDetails.workModes.some(mode => selectedWorkModes[mode]);

      const workTypeMatch = Object.keys(selectedWorkType).length === 0 ||
        jobDetails.workType.some(type => selectedWorkType[type]);
      console.log({ selectedExperience, selectedSalary })
      const experienceMatch = Object.keys(selectedExperience).length === 0 ||
        (parseInt(candidateDetails.minExperience) >= parseInt(selectedExperience.min) &&
          parseInt(candidateDetails.maxExperience) <= parseInt(selectedExperience.max));

      const salaryMatch = Object.keys(selectedSalary).length === 0 ||
        (parseInt(jobDetails.minSalary) >= parseInt(selectedSalary.min) &&
          parseInt(jobDetails.maxSalary) <= parseInt(selectedSalary.max));

      const qualificationMatch = Object.keys(selectedQualification).length === 0 ||
        candidateDetails.qualification.some(qual => selectedQualification[qual]);

      return categoryMatch && workShiftsMatch && workModesMatch && workTypeMatch && experienceMatch && salaryMatch && qualificationMatch;
    });
  };
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const { data } = await axios.get(CINE_JOB_API.GET_ALL_CINE_JOB);
        setJobPostDetails(filterJobs(data));
      } catch (err) {
        console.error('Error fetching job details:', err);
      }
    };

    fetchPostData();
  }, [selectedCategories, selectedWorkShifts, selectedWorkModes, selectedWorkType, selectedExperience, selectedSalary, selectedQualification]);

  return (
    <Grid container spacing={{ sm: 2, lg: 3, xl: 4 }} gap={{ xs: 2, md: 0 }}>
      <Grid item xs={12} md={4} lg={3}>
        <List
          sx={{
            maxWidth: { xs: 360, sm: 300, md: 500 },
            bgcolor: 'white',
            borderRadius: 2,
            boxShadow: 1,
            p: 1
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" sx={{ color: '#2196f3', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Filter
              <Button
                onClick={handleClearAll}
                size="small"
                sx={{ textTransform: 'none', color: '#2196f3', '&:hover': { bgcolor: 'transparent' } }}
              >
                Clear All
              </Button>
            </ListSubheader>
          }
        >
          {/* Job Category */}
          <ListItemButton onClick={() => handleToggle('category', setOpenCategory)}>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Job Category" />
            {openCategory ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCategory} sx={{
            maxHeight: '255px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
          }}>
            {/* Search Input for Categories */}
            <Box padding={"4px 20px"}>
              <TextField
                variant="outlined"
                placeholder="Search Categories..."
                value={searchQuery}
                size='small'
                style={{
                  '& .MuiOutlinedInput-root': {
                    width: '100%',
                    borderRadius: '10px',
                    borderColor: '#2196f3',
                    '&:hover fieldset': {
                      borderColor: '#2196f3',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#2196f3',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#2196f3',
                  },
                }}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      edge="end"
                      aria-label="clear"
                      onClick={handleClear}
                      style={{ visibility: searchQuery ? 'visible' : 'hidden' }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            {renderList(
              filteredCategories.slice(0, showMoreCategories ? filteredCategories.length : 4),
              selectedCategories,
              setSelectedCategories,
              handleCheckboxChange(setSelectedCategories, selectedCategories)
            )}
            {filteredCategories.length > 4 && !showMoreCategories && (
              <ListItemButton
                sx={{ pl: 4, py: 0.1 }}
                onClick={() => setShowMoreCategories(true)}
              >
                <ListItemText primary="More" sx={{ color: '#2196f3' }} />
              </ListItemButton>
            )}
            {showMoreCategories && (
              <ListItemButton
                sx={{ pl: 4, py: 0.1 }}
                onClick={() => setShowMoreCategories(false)}
              >
                <ListItemText primary="Less" sx={{ color: '#2196f3' }} />
              </ListItemButton>
            )}
          </Collapse>

          {/* Work Shifts */}
          <ListItemButton onClick={() => handleToggle('workShifts', setOpenWorkShifts)}>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary="Work Shifts" />
            {openWorkShifts ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openWorkShifts} timeout="auto" unmountOnExit>
            {renderList(['Day Shift', 'Night Shift', 'Rotational Shift'], selectedWorkShifts, setSelectedWorkShifts, handleCheckboxChange(setSelectedWorkShifts, selectedWorkShifts))}
          </Collapse>

          {/* Work Modes */}
          <ListItemButton onClick={() => handleToggle('workModes', setOpenWorkModes)}>
            <ListItemIcon>
              <BusinessCenterIcon />
            </ListItemIcon>
            <ListItemText primary="Work Modes" />
            {openWorkModes ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openWorkModes} timeout="auto" unmountOnExit>
            {renderList(['In Office', 'Virtual', 'Hybrid', 'Onsite'], selectedWorkModes, setSelectedWorkModes, handleCheckboxChange(setSelectedWorkModes, selectedWorkModes))}
          </Collapse>

          {/* Work Type */}
          <ListItemButton onClick={() => handleToggle('workType', setOpenWorkType)}>
            <ListItemIcon>
              <WorkOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Work Type" />
            {openWorkType ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openWorkType} timeout="auto" unmountOnExit>
            {renderList(['Permanent', 'Temporary', 'Full-time', 'Part-time', 'Internship'], selectedWorkType, setSelectedWorkType, handleCheckboxChange(setSelectedWorkType, selectedWorkType))}
          </Collapse>

          {/* Experience */}
          <ListItemButton onClick={() => handleToggle('experience', setOpenExperience)}>
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Experience" />
            {openExperience ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openExperience} timeout="auto" unmountOnExit>
            {renderList(Object.keys(experienceRanges), selectedExperience, setSelectedExperience, handleCheckboxChange(setSelectedExperience, selectedExperience))}
          </Collapse>

          {/* Salary */}
          <ListItemButton onClick={() => handleToggle('salary', setOpenSalary)}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Salary" />
            {openSalary ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSalary} timeout="auto" unmountOnExit>
            {renderList(Object.keys(salaryRanges), selectedSalary, setSelectedSalary, handleCheckboxChange(setSelectedSalary, selectedSalary))}
          </Collapse>

          {/* Qualification */}
          <ListItemButton onClick={() => handleToggle('qualification', setOpenQualification)}>
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Qualification" />
            {openQualification ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openQualification} timeout="auto" unmountOnExit>
            {renderList(['Below 10th Grade', '10th/12th Grade', 'Under Graduate', 'Post Graduate', 'Diploma', 'ITI', 'Skilled Labour', 'Unskilled Labour'], selectedQualification, setSelectedQualification, handleCheckboxChange(setSelectedQualification, selectedQualification))}
          </Collapse>
        </List>
      </Grid>
      <Grid item xs={12} md={8} lg={9} >
        {jobPostDetails.map(item =>
          <JobSeekingCard job={item} />
        )}
      </Grid>
    </Grid>
  )
}

export default JobSeeking;
