import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chathistory: [],
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        updateChat: (state, action) => {
            state.chathistory = action.payload;
        },
        addMessage: (state, action) => {
            state.chathistory.push(action.payload);
        },
        updateMessageStatus: (state, action) => {
            const { receiver_id, authId } = action.payload;
            state.chathistory = state.chathistory.map((msg) => {
                if (receiver_id === authId) {
                    return { ...msg, status: 'read' };
                }
                return msg;
            });
        },
        removeMessages: (state, action) => {
            const idsToRemove = action.payload;
            state.chathistory = state.chathistory.filter(
                (message) => !idsToRemove.includes(message.id)
            );
        },
        markMessageDeleted: (state, action) => {
            const { id, chatmaster_id, currentChatmasterId } = action.payload;
            if (chatmaster_id === currentChatmasterId) {
                state.chathistory = state.chathistory.map((message) => {
                    if (message.id === id) {
                        return {
                            ...message,
                            message: "This message was deleted",
                            img: "",
                        };
                    }
                    return message;
                });
            }
        },
        removeMessageById: (state, action) => {
            const { id } = action.payload;
            state.chathistory = state.chathistory.filter(
                (message) => message.id !== id
            );
        },
        updateChatMessage: (state, action) => {
            const { id, newMessage } = action.payload;

            const chatIndex = state.chathistory.findIndex(
                (chat) => chat.id === id
            );

            if (chatIndex !== -1) {
                const updatedChat = {
                    ...state.chathistory[chatIndex],
                    message: newMessage,
                    edit: "Edited",
                };

                state.chathistory = [
                    ...state.chathistory.slice(0, chatIndex),
                    updatedChat,
                    ...state.chathistory.slice(chatIndex + 1),
                ];
            }
        },
        updateReactionMessage: (state, action) => {
            const { id, newMessage } = action.payload;

            const chatIndex = state.chathistory.findIndex(
                (chat) => chat.id === id
            );

            if (chatIndex !== -1) {
                const updatedChat = {
                    ...state.chathistory[chatIndex],
                    react_emoji: newMessage,
                };

                state.chathistory = [
                    ...state.chathistory.slice(0, chatIndex),
                    updatedChat,
                    ...state.chathistory.slice(chatIndex + 1),
                ];
            }
        },
        chatScroll: (state, action) => {
            const newHistory = action.payload;
            state.chathistory = [...newHistory, ...state.chathistory];
        },
    },
});

export const { updateChat,addMessage,updateMessageStatus,removeMessages,removeMessageById,markMessageDeleted,updateChatMessage,chatScroll,updateReactionMessage } = chatSlice.actions;

export default chatSlice.reducer;
