import React, { useEffect, useState } from 'react';
import { Box, Container, Divider, Typography, Card, CardContent, CardMedia, Avatar, Chip, Skeleton, styled, Button, IconButton } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { Nodeapi, Assetsapi } from '../../../config/serverUrl';
import LocationIcon from '../../../assets/Icons/CineJobLocation.png';
import RupeeIcon from '../../../assets/Icons/CineJobRupee.png';
import SkillIcon from '../../../assets/Icons/CineJobSkill.png';
import ExperienceIcon from '../../../assets/Icons/CineJobSuitcase.png';
import TimeIcon from '../../../assets/Icons/CineJobTime.png';
import BookmarkIcon from '../../../assets/Icons/CineJobBookmark.png';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import { ASSETS_API } from '../../../config/api';

// Custom styled components
const JobCard = styled(Card)(({ theme }) => ({
  borderRadius: '15px',
  height: '280px',
  width: 400,
  border: '1px solid #e6e6e6',
  backgroundColor: '#ffffff',
  padding: '14px',
  marginBottom: '24px',
  position: 'relative',
  '&:hover': {
    border: '1px solid #68bcff',
    backgroundColor: 'transparent',
  },
}));

const JobIcon = styled(Avatar)(({ theme }) => ({
  width: '25px',
  height: '25px',
  marginRight: '4px',
}));

const JobChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgb(239, 245, 252)',
  color: '#4a4a4a',
  fontSize: '14px',
}));

const JobTitleContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

const CompanyLogo = styled(CardMedia)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  [theme.breakpoints.up('xs')]: {
    width: 70,
    height: 70
  },
}));

const capitalizeText = {
  textTransform: 'capitalize',
};

const JobContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginBottom: '0px',
}));

const JobSeekingSimilar = ({ industry, jobId }) => {
  const [jobPostDetails, setJobPostDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const { data } = await axios.get(`${Nodeapi}/cinejob`);
        let filteredData = data.filter(item => item.jobDetail.industry === industry && item.id !== parseInt(jobId));
        if (filteredData.length <= 4) {
          filteredData = data.filter(item => item.id !== parseInt(jobId));
        }

        setJobPostDetails(filteredData);
      } catch (err) {
        console.error('Error fetching job details:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPostData();
  }, [industry, jobId]);

  const formatPostDate = (createdAt) => {
    const postDate = moment(createdAt);
    const now = moment();
    const minutesAgo = now.diff(postDate, 'minutes');
    const hoursAgo = now.diff(postDate, 'hours');
    const daysAgo = now.diff(postDate, 'days');

    if (minutesAgo < 60) {
      return minutesAgo <= 1 ? 'Posted a minute ago' : `Posted ${minutesAgo} minutes ago`;
    } else if (hoursAgo < 24) {
      return hoursAgo <= 1 ? 'Posted an hour ago' : `Posted ${hoursAgo} hours ago`;
    } else if (daysAgo < 7) {
      return daysAgo === 1 ? 'Posted a day ago' : `Posted ${daysAgo} days ago`;
    } else {
      return postDate.format('DD-MM-YYYY');
    }
  };

  if (loading) {
    return (
      <Container>
        <Box my={4} display="flex" alignItems="center">
          <Typography variant="body2" color="#2196f3" sx={{ flexShrink: 0 }}>
            Similar Jobs
          </Typography>
          <Divider orientation="horizontal" sx={{ flex: 1, mx: 2, borderColor: "#2196f3", borderWidth: '1px' }} />
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box sx={{
            display: 'flex',
            gap: '20px',
            overflowX: 'auto',
            paddingBottom: '16px',
            scrollBehavior: 'smooth',
            position: 'relative',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            width: '100%',
            maxWidth: {
              xs: '100%',
              sm: 450,
              md: 600,
              lg: 900,
              xl: 1200
            },
          }}>
            {[...Array(3)].map((_, index) => (
              <JobCard key={index}>
                <CardContent sx={{ padding: '0px', '&:last-child': { paddingBottom: '0px' } }}>
                  <JobTitleContainer>
                    <Skeleton variant="text" width="60%" height={40} sx={{ mb: 1 }} />
                    <CompanyLogo component="div">
                      <Skeleton variant="rectangular" width={75} height={40} />
                    </CompanyLogo>
                  </JobTitleContainer>

                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={1}>
                    <Box display="flex" alignItems="center" flex="1">
                      <Skeleton variant="text" width="40%" height={20} />
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                    <Skeleton variant="text" width="50%" height={20} sx={{ ml: 1 }} />
                  </Box>

                  <Box display="flex" justifyContent="start" mb={1} gap={2}>
                    <Box display="flex" alignItems="center">
                      <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                      <Skeleton variant="text" width="50%" height={20} sx={{ ml: 1 }} />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                      <Skeleton variant="text" width="50%" height={20} sx={{ ml: 1 }} />
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="start" mb={1}>
                    <Box display="flex" alignItems="center">
                      <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                      <Box display="flex" gap={1}>
                        <Skeleton variant="text" width="30%" height={20} />
                        <Skeleton variant="text" width="30%" height={20} />
                      </Box>
                    </Box>
                  </Box>

                  <hr />

                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                      <Skeleton variant="text" width="30%" height={20} sx={{ ml: 1 }} />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                      <Skeleton variant="text" width="30%" height={20} sx={{ ml: 1 }} />
                    </Box>
                  </Box>
                </CardContent>
              </JobCard>
            ))}
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={{ padding: '0px' }}>
      <Box my={4} display="flex" alignItems="center">
        <Typography variant="body2" color="#2196f3" sx={{ flexShrink: 0 }}>
          Similar Jobs
        </Typography>
        <Divider orientation="horizontal" sx={{ flex: 1, mx: 2, borderColor: "#2196f3", borderWidth: '1px' }} />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Box sx={{
          display: 'flex',
          gap: '20px',
          overflowX: 'scroll',
          paddingBottom: '16px',
          scrollBehavior: 'smooth',
          position: 'relative',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          width: '100%',
          maxWidth: {
            xs: '100%',
            sm: 450,
            md: 600,
            lg: 900,
            xl: 1200
          },
        }}>
          {jobPostDetails.slice(0, 8).map((item) => {
            const { jobDetail, candidateDetail, companyDetail, createdAt } = item;
            const jobUrl = `${window.location.origin}/main/CineJob/${item.id}`;

            return (
              <Link to={`/main/CineJob/${item.id}`} style={{ color: 'none', textDecoration: 'none' }} key={item.id}>
                <JobCard>
                  <CardContent sx={{ padding: '0px', '&:last-child': { paddingBottom: '0px' } }}>
                    <JobTitleContainer>
                      <Typography
                        variant="h6"
                        component="div"
                        fontWeight={500}
                        color="#4a4a4a"
                        sx={{
                          ...capitalizeText,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: 'calc(100% - 200px)',
                        }}
                      >
                        {jobDetail?.jobTitle || "Job Title"}
                      </Typography>
                      <CompanyLogo>
                        <Box
                          bgcolor="#EFF5FC"
                          borderRadius="10px 10px 10px 10px"
                          width="100%"
                          height="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <div style={{
                            position: 'relative',
                            width: 'calc(100% - 16px)',
                            height: 'calc(100% - 16px)',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            backgroundColor: '#EFF5FC',
                          }}>
                            <span
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${ASSETS_API.ASSETS_AD_IMAGES(companyDetail?.file)})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                filter: 'blur(2px)',
                                zIndex: 0,
                                opacity: 0.9,
                                borderRadius: '8px',
                              }}
                            ></span>
                            <img
                              src={`${ASSETS_API.ASSETS_AD_IMAGES(companyDetail?.file)}`}
                              alt="Company Logo"
                              style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                zIndex: 1,
                                borderRadius: '8px',
                              }}
                            />
                          </div>
                        </Box>
                      </CompanyLogo>
                    </JobTitleContainer>

                    <Box display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={1}>
                      <Box display="flex" alignItems="center" flex="1">
                        <Typography variant="subtitle1" color="#2196f3" mr={2} sx={{
                          ...capitalizeText, overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: 'calc(100% - 250px)',
                        }}>
                          {companyDetail?.companyName || "Company Name"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center" mb={1}>
                      <JobIcon src={LocationIcon} alt="" variant='square' />
                      <Typography variant="body2" color="#4a4a4a" ml={1} sx={{
                        ...capitalizeText, overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: 'calc(100% - 200px)',
                      }}>
                        {jobDetail?.jobAddress || "Job Address"}
                      </Typography>
                      {jobDetail?.workType.length > 0 && (
                        <JobContainer ml={1}>
                          {jobDetail.workType.slice(0, 1).map((skill, idx) => (
                            <JobChip key={idx} label={skill} sx={capitalizeText} />
                          ))}
                          {jobDetail.workType.length > 1 && (
                            <JobChip
                              label={`+${jobDetail.workType.length - 1}`}
                              sx={capitalizeText}
                            />
                          )}
                        </JobContainer>
                      )}
                    </Box>
                    <Box display="flex" justifyContent="start" mb={1} gap={2}>
                      <Box display="flex" alignItems="center">
                        <JobIcon src={ExperienceIcon} alt="" variant='square' />
                        <Typography variant="body2" color="#4a4a4a" ml={1} sx={capitalizeText}>
                          {candidateDetail?.minExperience && candidateDetail?.maxExperience
                            ? `${candidateDetail.minExperience} - ${candidateDetail.maxExperience} yrs`
                            : 'Not specified'}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <JobIcon src={RupeeIcon} alt="" />
                        <Typography variant="body2" color="#4a4a4a" ml={1} sx={capitalizeText}>
                          {jobDetail?.minSalary && jobDetail?.maxSalary
                            ? `${jobDetail.minSalary} - ${jobDetail.maxSalary} INR`
                            : 'Not specified'}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" justifyContent="start" mb={1}>
                      <Box display="flex" alignItems="center">
                        <JobIcon src={SkillIcon} alt="" variant='square' />
                        <JobContainer>
                          {candidateDetail?.preferredSkills.length > 0 ? (
                            <>
                              {candidateDetail.preferredSkills.slice(0, 1).map((skill, idx) => (
                                <JobChip key={idx} label={skill} sx={capitalizeText} />
                              ))}
                              {candidateDetail.preferredSkills.length > 1 && (
                                <JobChip
                                  label={`+${candidateDetail.preferredSkills.length - 1}`}
                                  sx={capitalizeText}
                                />
                              )}
                            </>
                          ) : <Typography variant="body2" color="#4a4a4a" ml={1}>
                            Not specified
                          </Typography>}
                        </JobContainer>
                      </Box>
                    </Box>
                    <hr />
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <JobIcon src={TimeIcon} alt="" variant='square' />
                        <Typography variant="body2" color="#4a4a4a">
                          {formatPostDate(createdAt)}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <JobIcon src={BookmarkIcon} alt="" variant='square' />
                        <Typography variant="body2" color="#4a4a4a" sx={capitalizeText}>
                          Save
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </JobCard>
              </Link>
            );
          })}
        </Box>
      </Box>
      {jobPostDetails.length > 5 && (
        <Box display="flex" justifyContent="center" mb={2}>
          <Link to="/main/CineJob">
            <Button variant="outlined" color="primary" sx={{
              mb: 2, borderColor: '#2196f3',
              color: '#2196f3',
              '&:hover': {
                backgroundColor: 'rgba(33, 150, 243, 0.1)',
                borderColor: '#2196f3',
              },
            }}>
              View More Jobs
            </Button>
          </Link>
        </Box>
      )}
    </Container>
  );
};

export default JobSeekingSimilar;
