import { MdAdd } from "react-icons/md";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../Component/AddEducationDetails/School.css";
import { useSelector } from "react-redux";
import "./Relationship.css";
import Swal from "sweetalert2";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment/moment";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import { Calendar } from "primereact/calendar";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import {
  collegesInIndia,
  degreeSpecializations,
  degreeTypes,
  universitiesInIndia,
  education,
} from "./Dropdown_list";
const skillOptions = [
  { value: "html", label: "HTML" },
  { value: "css", label: "CSS" },
  { value: "js", label: "JavaScript" },
  { value: "react", label: "React" },
  { value: "angular", label: "Angular" },
  { value: "flutter", label: "Flutter" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  backgroundColor: "#fff",
  border: "0px",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
  height: "90vh",
  overflowY: "scroll",
};

const EducationDetail = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setButtonValue("");
  };

  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);
  const authdata = useSelector((state) => state.auth.user);
  const token = authdata?.token;

  const [educationdata, setEducationData] = useState({
    userid: "",
    education: "",
    rollno: "",
    collegename: "",
    collegedegree: "",
    collegespecialization: "",
    collegestartYear: "",
    collegeendYear: "",
    collegepercentage: "",
    collegesection: "",
    university: "",
    otherDegree: "",
    otherCollegeName: "",
    otherSpecialization: "",
    otherUniversity: "",
    candidate_id: "",
    // othereducation: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); //to handle multiple form submission 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue =
      name === "collegepercentage"
        ? value !== ""
          ? value + "%"
          : value
        : value;
    setEducationData({
      ...educationdata,
      [name]: formattedValue,
      userid: authdata?.id,
      candidate_id: authdata.userid,
    });
  };
  // console.log("can_id:", authdata.userid)
  // console.log("edu:", educationdata);
  const [buttonvalue, setButtonValue] = useState("");
  const [clg_start, setclg_start] = useState("");
  const [clg_end, setclg_end] = useState("");
  const handleStartdate = (event) => {
    setclg_start(event.value);
    const newDob = event.value;
    const formattedDate = moment(newDob).format("DD-MM-YYYY");

    setEducationData({
      ...educationdata,
      collegestartYear: formattedDate,
    });
  };

  const handleEnddate = (event) => {
    setclg_end(event.value);
    const newDob = event.value;
    const formattedDate = moment(newDob).format("DD-MM-YYYY");

    setEducationData({
      ...educationdata,
      collegeendYear: formattedDate,
    });
  };

  const minRelievingDate = clg_start ? new Date(clg_start.getTime()) : null;

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelectedJobDetail(rowData);
      menuLeft.current.toggle(event);
    };
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (educationdata) {
      setSerialNumbers(
        Array.from({ length: educationdata.length }, (_, index) => index + 1)
      );
    }
  }, [educationdata]);

  const menuLeft = useRef(null);
  const items = [
    {
      label: "Edit",

      command: () => handleEdit(selectedJobDetail),
    },
    {
      label: "Delete",

      command: () => handleDelete(selectedJobDetail),
    },
  ];

  const handleEdit = (rowData) => {
    handleOpen();
    let value1 = rowData.collegestartYear;
    let value2 = rowData.collegeendYear;
    let startYear = moment(value1, "DD-MM-YYYY").toDate();
    let endYear = moment(value2, "DD-MM-YYYY").toDate();
    setclg_end(endYear);
    setclg_start(startYear);

    setEducationData(rowData);
    setButtonValue("Update");
  };

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await deleteEducationData(rowData.id);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  //dropdown useState
  const [showOtherdegreeInput, setshowOtherdegreeInput] = useState(false);
  const [showOthercollegenameInput, setshowOthercollegenameInput] =
    useState(false);
  const [
    showOthercollegespecializationInput,
    setshowOthercollegespecializationInput,
  ] = useState(false);
  const [showOtherunivercity, setshowOtherunivercity] = useState(false);
  //handle degree

  const handelchange = (e) => {
    const { name, value } = e.target;

    setEducationData({
      ...educationdata,
      [name]: value,
      userid: authdata.id,
    });
  };

  const handleCollegeCourseChange = (e) => {
    const value = e.target.value;
    if (value === "otherDegree") {
      setshowOtherdegreeInput(true);
      setEducationData({
        ...educationdata,
        collegedegree: value,
        otherDegree: "",
      });
    } else {
      setshowOtherdegreeInput(false);
      setEducationData({
        ...educationdata,
        collegedegree: value,
      });
    }
  };
  const handleOtherDegreeChange = (e) => {
    const value = e.target.value;
    setEducationData({
      ...educationdata,
      collegedegree: value,
      otherDegree: value,
    });
  };

  //collegname
  const handleCollegeName = (e) => {
    const value = e.target.value;
    if (value === "otherCollegeName") {
      setshowOthercollegenameInput(true);
      setEducationData({
        ...educationdata,
        collegename: value,
        otherCollegeName: "",
      });
    } else {
      setshowOthercollegenameInput(false);
      setEducationData({
        ...educationdata,
        collegename: value,
      });
    }
  };

  const handleOtherCollegeName = (e) => {
    const value = e.target.value;
    setEducationData({
      ...educationdata,
      collegename: value,
      otherCollegeName: value,
    });
  };

  //collegespecialization
  const handlecollegSpecialization = (e) => {
    const value = e.target.value;
    if (value === "otherSpecialization") {
      setshowOthercollegespecializationInput(true);
      setEducationData({
        ...educationdata,
        collegespecialization: value,
      });
    }
  };

  const handleOtherCollegeSpecialization = (e) => {
    const value = e.target.value;
    setEducationData({
      ...educationdata,
      collegespecialization: value,
      otherSpecialization: value,
    });
  };

  //univercity

  const handleUniversity = (e) => {
    const value = e.target.value;
    if (value === "otherUniversity") {
      setshowOtherunivercity(true);
      setEducationData({
        ...educationdata,
        otherUniversity: "",
      });
    } else {
      setshowOtherunivercity(false);
      setEducationData({
        ...educationdata,
        university: value,
      });
    }
  };
  const handleOtherUniversity = (e) => {
    const value = e.target.value;
    setEducationData({
      ...educationdata,
      university: value,
      otherUniversity: value,
    });
  };
  const handleEducationform = async () => {
    setButtonValue("Submit");
    handleOpen();
    setEducationData({
      userid: "",
      rollno: "",
      education: "",
      collegename: "",
      collegedegree: "",
      collegespecialization: "",
      collegestartYear: "",
      collegeendYear: "",
      collegepercentage: "",
      collegesection: "",
      university: "",
      otherDegree: "",
      otherCollegeName: "",
      otherSpecialization: "",
      otherUniversity: "",
      // othereducation: "",
    });
    setclg_start("");
    setclg_end("");
  };

  const [updateEducation, setupdateEducation] = useState([]);

  //fetch
  useEffect(() => {
    fetchEducation();
  }, []);

  const addEducationDetails = async (e) => {
    e.preventDefault();

    if(isSubmitting) return;   //Prevent multiple submission
     setIsSubmitting(true);

    const action = e.target.value;

    if (action === "Update") {
      const res = await axios.put(
        `${Nodeapi}/updateEducation/${educationdata.id}`,
        {
          updateEducation: educationdata,
          userid: educationdata.userid,
        }
      );

      if (res.data) {
        if (res.data.code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          const updatedData = updateEducation.map((item) =>
            item.id === educationdata.id ? educationdata : item
          );
          setupdateEducation(updatedData);
          console.log(res.data.data.result);
        }
      } else {
        console.log(res.data.data.message);
      }
    } else if (action === "Submit") {
      const isEmpty = Object.values(educationdata).every(
        (value) => value == ""
      );

      if (isEmpty) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Fill Out All Required Fields",
          showConfirmButton: false,
          timer: 2000,
        });
        //  return handleClose();
      }

      const res = await axios.post(`${Nodeapi}/addEducationDetails`, {
        updateEducation: educationdata,
        userid: authdata?.id,
      });
      if (res.data) {
        if (res.data.code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "information added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          setupdateEducation(res.data.data.result);

          setEducationData({
            userid: "",
            rollno: "",
            education: "",
            collegename: "",
            collegedegree: "",
            collegespecialization: "",
            collegestartYear: "",
            collegeendYear: "",
            collegepercentage: "",
            collegesection: "",
            university: "",
            otherDegree: "",
            otherCollegeName: "",
            otherSpecialization: "",
            otherUniversity: "",
            // othereducation: "",
          });
        } else {
          console.log(res.data.data.message);
        }
      }
    }

    setIsSubmitting(false)
  };

  const fetchEducation = async () => {
    const res = await axios.get(`${Nodeapi}/fetcheducation?id=${authdata?.id}`);

    setupdateEducation(res.data.data.result);

    if (res.data) {
      if (res.data.code == 200) {
        // console.log(res.data.data.result);
      } else {
        console.log(res.data.data.message);
      }
    }
  };

  //delete
  const deleteEducationData = async (id) => {
    try {
      await axios.delete(`${Nodeapi}/deleteEducation/${id}`);
      fetchEducation();
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
  };

  return (
    <div>
      <div className=" mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Education Details</h5>
          <p>
            <Button onClick={handleEducationform}>
              <MdAdd size={22} />
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="w-23rem md:w-30rem scrollBarHidden">
                <h6>Education details</h6>
                <div className="mt-3">
                  <label htmlFor=" education" className="labels mb-2 ">
                    Education :
                  </label>
                  <select
                    className="form-select"
                    name="education"
                    value={educationdata.education}
                    onChange={handelchange}
                  >
                    <option value="Select">Select Education Name</option>
                    {education.map((college, index) => (
                      <option key={index} value={college}>
                        {college}
                      </option>
                    ))}
                    {/* <option value="otherEducation">others</option>
                
                  {educationdata.education === "otherEducation" && (
                    <input
                      type="text"
                      placeholder="Enter the education"
                      className="form-control mt-3"
                      name="otherEducation"
                      value={educationdata.othereducation}
                      onChange={handelchange}
                      required
                    />
                  )} */}
                  </select>
                </div>

                <div className="mt-3">
                  <label htmlFor="collegeName" className="labels mb-2 ">
                    Institution Name:
                  </label>
                  <select
                    className="form-select"
                    name="collegename"
                    value={educationdata.collegename}
                    onChange={handelchange}
                  >
                    <option value="Select">Select Institution Name</option>
                    {collegesInIndia.map((college, index) => (
                      <option key={index} value={college}>
                        {college}
                      </option>
                    ))}
                    <option value="otherCollegeName">others</option>
                  </select>
                  {educationdata.collegename === "otherCollegeName" && (
                    <input
                      type="text"
                      placeholder="Enter the institutionname"
                      className="form-control mt-3"
                      name="otherCollegeName"
                      value={educationdata.otherCollegeName}
                      onChange={handelchange}
                      required
                    />
                  )}
                </div>
                <div className="mt-3">
                  <label htmlFor="collegeCourse" className="labels mb-2 ">
                    Degree:
                  </label>
                  <select
                    className="form-select"
                    name="collegedegree"
                    value={educationdata.collegedegree}
                    onChange={handelchange}
                    required
                  >
                    <option value="">Select Institution Degree</option>
                    {degreeTypes.map((degree, index) => (
                      <option key={index} value={degree}>
                        {degree}
                      </option>
                    ))}
                    <option value="otherDegree">others</option>
                  </select>
                  {educationdata.collegedegree === "otherDegree" && (
                    <input
                      type="text"
                      placeholder="Enter the degree"
                      className="form-control mt-3"
                      name="otherDegree"
                      value={educationdata.otherDegree}
                      onChange={handelchange}
                      required
                    />
                  )}
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="collegeSpecialization"
                    className="labels mb-2 "
                  >
                    Specialization:
                  </label>
                  <select
                    className="form-select"
                    name="collegespecialization"
                    value={educationdata.collegespecialization}
                    onChange={handelchange}
                    required
                  >
                    <option value="">Select Specialization</option>
                    {degreeSpecializations.map((specialization, index) => (
                      <option key={index} value={specialization}>
                        {specialization}
                      </option>
                    ))}
                    <option value="otherSpecialization">others</option>
                  </select>
                  {educationdata.collegespecialization ===
                    "otherSpecialization" && (
                    <input
                      type="text"
                      placeholder="Enter the Specialization"
                      className="form-control mt-3"
                      name="otherSpecialization"
                      value={educationdata.otherSpecialization}
                      onChange={handelchange}
                      required
                    />
                  )}
                </div>

                <div className=" mt-3">
                  <label className="form-label labels mt-2" htmlFor="startDate">
                    Start Date:
                  </label>
                  <Calendar
                    className="w-full input2"
                    showIcon={false}
                    name="collegestartYear"
                    monthNavigator
                    placeholder="Start Date"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={clg_start}
                    onChange={handleStartdate}
                    required
                    style={{
                      width: "88%",
                      height: "42px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      border: "none",
                    }} // Set the width to 300px
                  />
                </div>

                <div className=" mt-3">
                  <label className="form-label labels mt-2" htmlFor="endDate">
                    End Date:
                  </label>
                  <Calendar
                    className="w-full input2"
                    showIcon={false}
                    name="collegeendYear"
                    monthNavigator
                    placeholder="End Date"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={clg_end}
                    onChange={handleEnddate}
                    minDate={minRelievingDate}
                    required
                    style={{
                      width: "88%",
                      height: "42px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      border: "none",
                    }} // Set the width to 300px
                  />
                </div>
                <div className=" mt-3">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="Percentage"
                  >
                    Percentage
                  </label>
                  <input
                    type="text"
                    placeholder="Percentage"
                    name="collegepercentage"
                    className="form-control"
                    value={educationdata.collegepercentage.replace("%", "")}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      // Check if input value is empty or not
                      if (e.target.value !== "") {
                        // If not empty, replace non-digit characters and append '%'
                        e.target.value = e.target.value.replace(
                          /[^0-9.%]/g,
                          ""
                        );
                      }
                    }}
                  />
                </div>
                <div className=" mt-3">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="Percentage"
                  >
                    Roll Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Roll Number"
                    name="rollno"
                    value={educationdata.rollno}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      // Check if input value is empty or not
                      if (e.target.value !== "") {
                        // If not empty, replace non-digit characters and append '%'
                        e.target.value = e.target.value.replace(
                          /[^0-9.%]/g,
                          ""
                        );
                      }
                    }}
                    required
                  />
                </div>
                <div className=" mt-3">
                  <label
                    className="form-label labels mt-2"
                    htmlFor="collegeSection"
                  >
                    Section
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="College Section"
                    name="collegesection"
                    value={educationdata.collegesection}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="university" className="labels mb-2 ms-2">
                    University:
                  </label>
                  <select
                    className="form-select"
                    name="university"
                    value={educationdata.university}
                    onChange={handelchange}
                    required
                  >
                    <option value="">Select University</option>
                    {universitiesInIndia.map((universityName, index) => (
                      <option key={index} value={universityName}>
                        {universityName}
                      </option>
                    ))}
                    <option value="otherUniversity">others</option>
                  </select>
                  {educationdata.university === "otherUniversity" && (
                    <input
                      type="text"
                      placeholder="Enter the degree"
                      className="form-control mt-3"
                      name="otherUniversity"
                      value={educationdata.otherUniversity}
                      onChange={handelchange}
                      required
                    />
                  )}
                </div>

                <div className="text-center mt-3">
                  <button className="btn btn-danger me-2" onClick={handleClose}>
                    Cancel
                  </button>
                
                  <button
                    type="Submit"
                    value={buttonvalue}
                    className="btn btn-primary"
                    onClick={addEducationDetails}
                    disabled={isSubmitting}
                  >
                   {isSubmitting? "submitting": buttonvalue}
                    
                  </button>
                </div>
              </Box>
            </Modal>
          </p>
        </div>
        <div className="expand mt-2">
          <DataTable value={updateEducation} tableStyle={{ minWidth: "50rem" }}>
            <Column
              header="S.No"
              body={(rowData) => (
                <span>{updateEducation.indexOf(rowData) + 1}</span>
              )}
            ></Column>
            <Column
              field="rollno"
              header="Roll Number"
              style={{ minWidth: "150px " }}
            ></Column>
            <Column
              style={{ minWidth: "150px " }}
              field="education"
              header="education"
            ></Column>
            <Column
              // field="collegename"
              field={(rowData) =>
                rowData.collegename === "otherCollegeName"
                  ? rowData.otherCollegeName
                  : rowData.collegename
              }
              header="Institution Name"
              style={{ minWidth: "200px " }}
            ></Column>
            <Column
              field={(rowData) =>
                rowData.collegedegree === "otherDegree"
                  ? rowData.otherDegree
                  : rowData.collegedegree
              }
              header=" Course"
              style={{ minWidth: "190px " }}
            ></Column>
            <Column
              field={(rowData) =>
                rowData.collegespecialization === "otherSpecialization"
                  ? rowData.otherSpecialization
                  : rowData.collegespecialization
              }
              header=" Specialization"
              style={{ minWidth: "220px " }}
            ></Column>
            <Column
              field="collegestartYear"
              header="Start Year"
              style={{ minWidth: "180px " }}
            ></Column>
            <Column
              field="collegeendYear"
              header="End Year"
              style={{ minWidth: "160px " }}
            ></Column>
            <Column
              field="collegepercentage"
              header="Percentage"
              style={{ minWidth: "100px " }}
            ></Column>
            <Column
              field="collegesection"
              header=" Section"
              style={{ minWidth: "190px " }}
            ></Column>
            <Column
              field={(rowData) =>
                rowData.university === "otherUniversity"
                  ? rowData.otherUniversity
                  : rowData.university
              }
              header="University"
              style={{ minWidth: "190px " }}
            ></Column>
            <Column
              body={(rowData) => actions(rowData)}
              header="Actions"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default EducationDetail;
