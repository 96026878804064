import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useRef } from "react";
import { style } from "../../Styles/Jobformstyle";
import { useMediaQuery } from "@mui/material";
import { Menu } from "primereact/menu";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdAdd } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import "./Relationship.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import Swal from "sweetalert2";
import { districtsInIndia, countries, indianStates } from "./Dropdown_list";

const UpdatedContactinfo = () => {
  const [phone, setphone] = useState("");

  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);

  const [ContactForm, setContactForm] = useState({
    userid: "",
    addresstype: "",
    residentialtype: "",
    email: "",
    phonenumber: "",
    doorno: "",
    houseno: "",
    street: "",
    place: "",
    taluk: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlemobileno = (value) => {
    let valuno = value;

    setphone(valuno);
    setContactForm((prevstate) => ({
      ...prevstate,
      phonenumber: valuno,
    }));
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setphone("");
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [serialNumbers, setSerialNumbers] = useState([]);

  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const authdata = useSelector((state) => state.auth.user);
  const token = authdata?.token;
  const [data, setData] = useState("");

  //handlephone number
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactForm({
      ...ContactForm,
      [name]: value,
      userid: authdata?.id,
    });
  };

  const handleAddressType = (e) => {
    setContactForm({
      ...ContactForm,
      addresstype: e.target.value,
    });
  };
  const handleResidentialType = (e) => {
    setContactForm({
      ...ContactForm,
      residentialtype: e.target.value,
    });
  };
  const handleSubCategoryType = (e) => {
    setContactForm({
      ...ContactForm,
      sub_category: e.target.value,
    });
  };
  const handleDistric = (e) => {
    setContactForm({
      ...ContactForm,
      district: e.target.value,
    });
  };
  const handleState = (e) => {
    setContactForm({
      ...ContactForm,
      state: e.target.value,
    });
  };
  const handlecountry = (e) => {
    setContactForm({
      ...ContactForm,
      country: e.target.value,
    });
  };

  const handleopencontactForm = async () => {
    setButtonValue("Submit");
    handleOpen();
    setContactForm({
      userid: "",
      addresstype: "",
      residentialtype: "",
      email: "",
      phonenumber: "",
      doorno: "",
      houseno: "",
      street: "",
      place: "",
      taluk: "",
      district: "",
      state: "",
      country: "",
      pincode: "",
    });
  };

  //connect to back end
  const [updatedContactData, setupdatedContactData] = useState([]);

  const addUpdateContactForm = async (e) => {
    e.preventDefault();

    if(isSubmitting) return;
    setIsSubmitting(true);

    const action = e.target.value;
    if (action === "Update") {
      const res = await axios.put(
        `${Nodeapi}/updateContactData/${ContactForm.id}`,
        {
          updatedContactData: ContactForm,
          userid: ContactForm.userid,
        }
      );

      if (res.data) {
        if (res.data.code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          fetchContactData();
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Error 404",
          showConfirmButton: false,
          timer: 2000,
        });
        handleClose();
      }
    } else if (action === "Submit") {
      const isEmpty = Object.values(ContactForm).every((value) => value === "");

      if (isEmpty) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Fill Out All Required Fields",
          showConfirmButton: false,
          timer: 2000,
        });
        return handleClose();
      }

      const res = await axios.post(`${Nodeapi}/addContactinfo`, {
        updatedContactData: ContactForm,
        userid: authdata?.id,
      });
      if (res.data) {
        if (res.data.code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "information added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          setupdatedContactData(res.data.data.result);

          setContactForm({
            userid: "",
            addresstype: "",
            residentialtype: "",
            email: "",
            phonenumber: "",
            doorno: "",
            houseno: "",
            street: "",
            place: "",
            taluk: "",
            district: "",
            state: "",
            country: "",
            pincode: "",
          });
          setphone("");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
          handleClose();
        }
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    fetchContactData();
  }, []);

  const fetchContactData = async () => {
    const res = await axios.get(
      `${Nodeapi}/fetchContactData?id=${authdata?.id}`
    );

    setupdatedContactData(res.data.data.result);
  };
  //delete

  useEffect(() => {
    if (updatedContactData) {
      setSerialNumbers(
        Array.from(
          { length: updatedContactData.length },
          (_, index) => index + 1
        )
      );
    }
  }, [updatedContactData]);

  const deleteContactData = async (id) => {
    try {
      await axios.delete(`${Nodeapi}/deleteContactData/${id}`);
      fetchContactData();
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
  };

  const [buttonvalue, setButtonValue] = useState("");

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelectedJobDetail(rowData);
      menuLeft.current.toggle(event);
    };
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  const menuLeft = useRef(null);
  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selectedJobDetail),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selectedJobDetail),
    },
  ];

  const handleEdit = (rowData) => {
    handleOpen();
    setphone(rowData.phonenumber);
    setContactForm(rowData);
    setButtonValue("Update");
  };
  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData.id);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  return (
    <>
      <div className=" mt-4 contact">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Contact Information</h5>
          <p>
            <Button onClick={handleopencontactForm}>
              <MdAdd size={22} />
            </Button>
          </p>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              style={{
                overflowY: "scroll",
                height: "90vh",
                width: isSmallScreen ? "95%" : "",
              }}className="scrollBarHidden"
            >
              <form>
                <div className="row">
                  <h6 className="p-0 ">Contact Information</h6>

                  <div className="col-md-6 col-12">
                    <label htmlFor="addressType" className="labels mb-2 ">
                      Address Category:
                    </label>
                    <select
                      className="form-select"
                      name="addresstype"
                      id="addresstype"
                      value={ContactForm.addresstype}
                      onChange={handleAddressType}
                    >
                      <option value="">Select Address Type</option>
                      <option value="Residential">Residential</option>
                      <option value="Business">Business</option>
                      {/* <option value="Apartment">Apartment</option>
                      <option value="Condo">Condo</option>
                      <option value="Townhouse">Townhouse</option>
                      <option value="Duplex">Duplex</option>
                      <option value="Triplex">Triplex</option>
                      <option value="Cottage">Cottage</option>
                      <option value="Bungalow">Bungalow</option>
                      <option value="Ranch">Ranch</option>
                      <option value="Mobile Home">Mobile Home</option> */}
                    </select>
                  </div>
                  <div className="col-md-6 col-12">
                    <label htmlFor="addressType" className="labels mb-2 ">
                      Category Type:
                    </label>
                    <select
                      className="form-select"
                      name="residentialtype"
                      value={ContactForm.residentialtype}
                      onChange={handleResidentialType}
                    >
                      <option value="">Select Residential Type</option>
                      <option value="Business House">Business House</option>
                      <option value="Company Owned">Company Owned</option>
                      <option value="Lease">Lease</option>
                      <option value="Occupied">Occupied</option>
                      <option value="Owned">Owned</option>
                      <option value="Personal">Personal</option>
                      <option value="Possesessed">Possesessed</option>
                      <option value="Private">Private</option>
                      <option value="Rented">Rent</option>
                      <option value="Singular">Singular</option>
                      <option value="Sole">Sole</option>
                      <option value="Solely">Solely</option>
                      <option value="Staying in Hotel">Staying in Hotel</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-12">
                    <label htmlFor="addressType" className="labels mb-2 ">
                      Sub Category:
                    </label>
                    <select
                      className="form-select"
                      name="residentialtype"
                      value={ContactForm.sub_category}
                      onChange={handleSubCategoryType}
                    >
                      <option value="">Select Sub Category Type</option>
                      <option value="Individual">Individual</option>
                      <option value="Individual">Apartment</option>
                    </select>
                  </div>

                  <label className="mt-3 labels ms-2">Address :</label>
                  <div className="col-md-6 col-12 ">
                    <input
                      type="text"
                      className="form-control col-12 col-md-3"
                      placeholder="Door Number"
                      name="doorno"
                      value={ContactForm.doorno}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 col-12 ">
                    <input
                      type="text"
                      className="form-control col-12 col-md-3"
                      placeholder="House Name"
                      name="houseno"
                      value={ContactForm.houseno}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-12 ">
                    <input
                      type="text"
                      className="form-control col-12 col-md-3"
                      placeholder="Street"
                      name="street"
                      value={ContactForm.street}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-12 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Place/Village"
                      name="place"
                      value={ContactForm.place}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-12 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Taluk"
                      name="taluk"
                      value={ContactForm.taluk}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-12 ">
                    <select
                      className="header_company form-control"
                      name="district"
                      id="district"
                      value={ContactForm.district}
                      onChange={handleDistric}
                    >
                      <option value="">Select District</option>
                      {districtsInIndia.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 col-12">
                    <select
                      className="header_company form-control"
                      name="state"
                      id="state"
                      value={ContactForm.state}
                      onChange={handleState}
                    >
                      <option value="">Select state</option>
                      {indianStates.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 col-12 ">
                    <select
                      className="header_company form-control"
                      name="country"
                      id="country"
                      value={ContactForm.country}
                      onChange={handlecountry}
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-6 col-12 ">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Zip Code"
                      name="pincode"
                      value={ContactForm.pincode}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-12 ">
                    <label className="mt-3 labels mb-2" for="email">
                      Email Address
                    </label>

                    <input
                      type="email"
                      className="form-control col-12 col-md-3"
                      placeholder="email@gmail.com"
                      name="email"
                      value={ContactForm.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <label className="mt-3 labels ms-2">Phone Number</label>
                  <div className="col-md-6 col-12 ">
                    <PhoneInput
                      country={"in"}
                      value={phone}
                      onChange={handlemobileno}
                      className="border-example "
                    />
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button className="btn btn-danger me-2" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="Submit"
                    value={buttonvalue}
                    className="btn btn-primary"
                    onClick={addUpdateContactForm}
                    disabled={isSubmitting}
                  >
                     {isSubmitting? "submitting": buttonvalue}
                  </button>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
        <div className="expand" style={{ marginTop: "7px" }}>
          <DataTable
            value={updatedContactData}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              header="S.No"
              body={(rowData) => (
                <span>{updatedContactData.indexOf(rowData) + 1}</span>
              )}
            ></Column>

            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="addresstype"
              header="Address Type"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "141px" }}
              field="residentialtype"
              header="Residential Type"
            ></Column>
            <Column
              field="email"
              header="Email"
              body={(rowData) => (
                <span style={{ textTransform: "lowercase" }}>
                  {rowData.email}
                </span>
              )}
            />

            <Column
              field="phonenumber"
              header="Phone No"
              style={{ width: "15%", minWidth: "141px" }}
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "90px" }}
              field="doorno"
              header="Door No"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "130px" }}
              field="houseno"
              header="House Name"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "100px" }}
              field="street"
              header="Street"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "100px" }}
              field="place"
              header="Place"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "100px" }}
              field="taluk"
              header="Taluk"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "100px" }}
              field="district"
              header="District"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "100px" }}
              field="state"
              header="State"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "100px" }}
              field="country"
              header="Country"
            ></Column>
            <Column
              style={{ width: "15%", minWidth: "100px" }}
              field="pincode"
              header="PinCode"
            ></Column>
            <Column
              body={(rowData) => actions(rowData)}
              header="Actions"
            ></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default UpdatedContactinfo;
