import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment/moment";
import {
  expenseNames,
  paymentMode,
  typeOfNewLiable,
  typeOfNewInvestment,
  typeOfNewExpense,
  emiPeriod,
} from "./expenseOption";
import "./Vehicle.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

const ExpenseTracker = () => {
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState(null);
  const [checkdate, setCheckdate] = useState([]);
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;
  const [buttonvalue, setButtonValu] = useState("");

  const [documentation, setDocumentation] = useState({
    expensename: "",
    otherExpenseName: "",
    price: "",
    whom: "",
    emioption: "",
    payment: "",
    subpayment: "",
    subSubpayment: "",
  });
  const [expenseDate, setExpensedate] = useState(null);
  const [expenseDue, setExpenseDue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsedit] = useState(false);
  const menuLeft = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setDocumentation({
      expensename: "",
      otherExpenseName: "",
      price: "",
      whom: "",
      emioption: "",
      payment: "",
      subpayment: "",
      subSubpayment: "",
    });
    setOpen(false);
    setExpensedate("");
    setExpenseDue("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocumentation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (event) => {
    setExpensedate(event.value);
  };
  const handleDueDateChange = (event) => {
    setExpenseDue(event.value);
  };

  const [products, setProducts] = useState([]);
  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    const res = await axios.get(`${Nodeapi}/expense/${userId}`);
    setProducts(res.data.data.response);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsedit(true);
    const action = event.target.value;
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (action === "Submit") {
      const isEmpty = Object.values(documentation).reduce((acc, cur) => {
        if (cur != "") {
          ++acc;
        }
        return acc;
      }, 0);

      if (isEmpty < 6 || !expenseDate) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Fill Out All Required Fields",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsSubmitting(false);
        return setOpen(true);

      } else {
        const formattedDate = moment(expenseDate).format("DD-MM-YYYY");
        let formattedDate2 = moment(expenseDue).format("DD-MM-YYYY");
        var check = new Date(formattedDate.split("-").reverse().join("-"));
        var check2 = new Date(formattedDate2.split("-").reverse().join("-"));

        if (check > check2) {
          setExpensedate("");
          setExpenseDue("");
          setIsSubmitting(false);

          Swal.fire({
            position: "center",
            icon: "error",
            title:
              "Start date cannot be after the end date. Please Enter valid date",
            showConfirmButton: false,
            timer: 2000,
          });
          setIsSubmitting(false);
          return setOpen(true);
        }
        if (documentation.emioption == 0) {
          formattedDate2 = "";
        }

        if (documentation.emioption > 0 && formattedDate2 == "Invalid date") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please Enter Due Month Correctly",
            showConfirmButton: false,
            timer: 2000,
          });
          setIsSubmitting(false);
          return setOpen(true);
        }
        const res = await axios.post(`${Nodeapi}/expense`, {
          userid: userId,
          expense_name: documentation.expensename,
          expense_amt: documentation.price,
          expense_to: documentation.whom,
          other_expense: documentation.otherExpenseName,
          emi_option: documentation.emioption,
          payment_method: documentation.payment,
          expense_month: formattedDate,
          expense_due: formattedDate2,
          sub_payment: documentation.subpayment,
          other_sub_payment: documentation.subSubpayment,
        });

        if (res.data) {
          if (res.data.code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Expenses added Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            handleClose();
            setDocumentation("");
            setExpensedate("");
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Error 404",
              showConfirmButton: false,
              timer: 2000,
            });
            handleClose();
          }
        }
        setIsSubmitting(false);
      }
    } else {
      const formattedDate = moment(expenseDate).format("DD-MM-YYYY");
      let formattedDate2 = moment(expenseDue).format("DD-MM-YYYY");
      var check = new Date(formattedDate.split("-").reverse().join("-"));
      var check2 = new Date(formattedDate2.split("-").reverse().join("-"));
      if (check > check2) {
        setIsSubmitting(false);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Start date cannot be after the end date. Enter valid date",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsSubmitting(false);
        return setOpen(true);
      }
      if (documentation.emioption == 0) {
        formattedDate2 = "";
      }

      if (documentation.emioption > 0 && formattedDate2 == "Invalid date") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Enter Due Month Correctly",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsSubmitting(false);
        return setOpen(true);
      }
      const isEmpty = Object.values(documentation).reduce((acc, cur) => {
        if (cur != "") {
          ++acc;
        }
        return acc;
      }, 0);
      if (isEmpty < 6 || !expenseDate) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Fill Out All Required Fields",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsSubmitting(false);
        return setOpen(true);
      }

      const res = await axios.put(
        `${Nodeapi}/expense/${selectedJobDetail.id}`,
        {
          expense_name: documentation.expensename,
          expense_amt: documentation.price,
          expense_to: documentation.whom,
          other_expense: documentation.otherExpenseName,
          emi_option: documentation.emioption,
          payment_method: documentation.payment,
          sub_payment: documentation.subpayment,
          other_sub_payment: documentation.subSubpayment,
          expense_month: formattedDate,
          expense_due: formattedDate2,
        }
      );
      if (res.data) {
        if (res.data.code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
          handleClose();
        }
      }
    }

    fetchdata();
    handleClose();
    setIsSubmitting(false);
  };

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      menuLeft.current.toggle(event);
      setSelectedJobDetail(rowData);
    };

    const menuItems = [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => {
          handleEdit(selectedJobDetail);
        },
      },
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => handleDelete(selectedJobDetail),
      },
    ];

    return (
      <div>
        <Menu model={menuItems} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup="true"
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  const handleDelete = async (val) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        await deleteExpenseData(val.id);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  const deleteExpenseData = async (ide) => {
    try {
      await axios.delete(`${Nodeapi}/expense/${ide}`);
      fetchdata();
    } catch (error) {
      console.error("Error deleting relation data:", error);
    }
  };

  const handleEdit = (rowData) => {
    handleOpen();
    setIsedit(true);

    const value1 = rowData.expense_month;
    const value2 = rowData.expense_due;
    const monthDate = moment(value1, "DD-MM-YYYY").toDate();
    const monthDateDue = moment(value2, "DD-MM-YYYY").toDate();
    setDocumentation({
      expensename: rowData.expense_name,
      otherExpenseName: rowData.other_expense,
      price: rowData.expense_amt,
      whom: rowData.expense_to,
      emioption: rowData.emi_option,
      payment: rowData.payment_method,
      subpayment: rowData.sub_payment,
      subSubpayment: rowData.other_sub_payment,
    });
    setExpensedate(monthDate);
    setExpenseDue(monthDateDue);
    setButtonValu("update");
  };

  // Filter logic
  const filteredProductsone = products?.filter((product) => {
    var start = new Date(product.expense_month.split("-").reverse().join("-"));
    var endDate = new Date(product.expense_due.split("-").reverse().join("-"));
    const monthInterval = parseInt(product.emi_option, 10);
    const targetMonth = parseInt(checkdate[0]); // For example, June
    const targetYear = parseInt(checkdate[1]);

    if (monthInterval === 0) {
      const isMonthMatch = !targetMonth || targetMonth === start.getMonth() + 1;
      const isYearMatch = !targetYear || targetYear === start.getFullYear();
      return isMonthMatch && isYearMatch;
    }

    const intervals = findInterval(start, endDate, monthInterval);

    const isProductMatch = intervals?.some((interval) => {
      const [day, month, year] = interval.split("-").map(Number);
      const isMonthMatch = !targetMonth || targetMonth === month;
      const isYearMatch = !targetYear || targetYear == year;
      return isMonthMatch && isYearMatch;
    });
    return isProductMatch;
  });

  function findInterval(start, endDate, eachMonth) {
    const result = [];

    const monthInterval = parseInt(eachMonth, 10);

    if (isNaN(monthInterval) || monthInterval <= 0) {
      return;
    }

    let current = new Date(start);

    while (current <= endDate) {
      result.push(
        `${String(current.getDate()).padStart(2, "0")}-${String(
          current.getMonth() + 1
        ).padStart(2, "0")}-${current.getFullYear()}`
      );

      current.setMonth(current.getMonth() + monthInterval);
    }

    return result;
  }

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          colSpan={6}
          footerStyle={{
            textAlign: "right",
            background: "none",
          }}
          footer={`Total :`}
        />
        <Column
          footer={`₹${filteredProductsone?.reduce(
            (sum, exp) => sum + exp.expense_amt,
            0
          )}`}
          footerStyle={{ background: "none" }}
        />
      </Row>
    </ColumnGroup>
  );

  const handleOpenform = () => {
    setIsedit(false);
    handleOpen();
    setButtonValu("Submit");
  };

  const handleFilterDate = (e) => {
    setDate(e.value);
    const formattedDate = moment(e.value).format("MM-YYYY");
    const val = formattedDate.split("-");
    setCheckdate(val);
  };

  return (
    <div className="expense-tracker">
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Expenses</h5>
          <div className="d-flex justify-content-end">
            <Calendar
              value={date}
              style={{ width: "37%", height: "40px" }}
              onChange={handleFilterDate}
              view="month"
              dateFormat="mm/yy"
              placeholder="MM/YYYY"
              showButtonBar
            />
            <Button onClick={handleOpenform}>
              <MdAdd size={22} />
            </Button>
          </div>
        </div>
        <div className="expand mt-2">
          <DataTable
            value={filteredProductsone}
            footerColumnGroup={footerGroup}
            tableStyle={{ minWidth: "50rem" }}
            style={{ textAlign: "center" }}
          >
            <Column
              field={(rowData) =>
                rowData.expense_name === "other"
                  ? rowData.other_expense
                  : rowData.expense_name
              }
              header="Expense Name"
            />
            <Column field="expense_to" header="Expense To" />
            <Column
              header="Payment Mode"
              field={(row) => (
                <p
                  className={
                    row.payment_method === `Expenses`
                      ? `box-expense-tracker-shadow-red`
                      : row.payment_method === "Liabilities"
                      ? `box-expense-tracker-shadow-blue`
                      : row.payment_method === "Investment"
                      ? `box-expense-tracker-shadow-green`
                      : ""
                  }
                >
                  {row.payment_method}
                </p>
              )}
            />
            <Column
              field={(rowData) =>
                rowData.sub_payment === "other"
                  ? rowData.other_sub_payment
                  : rowData.sub_payment
              }
              header="Category"
            />
            <Column header="Start Due Month" field="expense_month" />

            <Column
              field={(row) =>
                row.expense_due == "Invalid date" ? " " : row.expense_due
              }
              header="Last Due Month"
            />
            <Column
              field="expense_amt"
              header="Expense Amount"
              body={(rowData) => <span>{rowData.expense_amt}</span>}
            />
            <Column body={(rowData) => actions(rowData)} header="Actions" />
          </DataTable>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "background.paper",
            border: "0px",
            boxShadow: 24,
            borderRadius: "12px",
            p: 4,
            maxHeight: "auto",
          }}
        >
          <h6 className="mb-2">{isEdit ? "Edit" : "Add"} Expenses</h6>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%", marginRight: "10px" }}>
              <label className="form-label labels mt-3" htmlFor="expenseName">
                Expense Name:
              </label>
              <select
                id="expenseName"
                className="form-select col-md-4 form-control"
                name="expensename"
                value={documentation.expensename}
                onChange={handleChange}
              >
                <option value="">Select Expense Name</option>
                {expenseNames
                  .sort((a, b) => a.localeCompare(b))
                  .map((expense, index) => (
                    <option key={index} value={expense}>
                      {expense}
                    </option>
                  ))}
                <option value="other">Other</option>
              </select>

              {documentation.expensename === "other" && (
                <input
                  type="text"
                  id="otherExpense"
                  className="form-control mt-3"
                  name="otherExpenseName"
                  placeholder="Type the Expense Name"
                  value={documentation.otherExpenseName}
                  onChange={handleChange}
                />
              )}
            </div>
            <div style={{ width: "50%" }}>
              <label className="form-label labels mt-3">Expense Amount:</label>
              <input
                placeholder="Enter your Price"
                type="number"
                id="price"
                className="form-control"
                name="price"
                value={documentation.price}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <label className="form-label labels mt-3">Expense To:</label>
          <input
            placeholder="Enter the person name"
            type="text"
            id="whom"
            className="form-control"
            name="whom"
            value={documentation.whom}
            onChange={handleChange}
            required
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%", marginRight: "10px" }}>
              <label className="form-label labels mt-3">Payment Mode:</label>
              <select
                id="expenseName"
                className="form-select col-md-4 form-control"
                name="payment"
                value={documentation.payment}
                onChange={handleChange}
              >
                <option value="">Select Payment Mode</option>
                {paymentMode
                  .sort((a, b) => a.localeCompare(b))
                  .map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <label className="form-label labels mt-3">Category:</label>
              {documentation.payment === "" && (
                <select
                  id="expenseName"
                  className="form-select col-md-4 form-control"
                  name="subpayment"
                  value={documentation.subpayment}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                </select>
              )}
              {documentation.payment === "Liabilities" && (
                <select
                  id="expenseName"
                  className="form-select col-md-4 form-control"
                  name="subpayment"
                  value={documentation.subpayment}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {typeOfNewLiable
                    .sort((a, b) => a.localeCompare(b))
                    .map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  <option value="other">Other</option>
                </select>
              )}
              {documentation.payment === "Investment" && (
                <select
                  id="expenseName"
                  className="form-select col-md-4 form-control"
                  name="subpayment"
                  value={documentation.subpayment}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {typeOfNewInvestment
                    .sort((a, b) => a.localeCompare(b))
                    .map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  <option value="other">Other</option>
                </select>
              )}
              {documentation.payment === "Expenses" && (
                <select
                  id="expenseName"
                  className="form-select col-md-4 form-control"
                  name="subpayment"
                  value={documentation.subpayment}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {typeOfNewExpense
                    .sort((a, b) => a.localeCompare(b))
                    .map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  <option value="other">Other</option>
                </select>
              )}
            </div>
          </div>
          <div style={{ width: "49%", marginTop: "10px" }}>
            {documentation.subpayment === "other" && (
              <input
                type="text"
                id="otherExpense"
                className="form-control mt-3"
                name="subSubpayment"
                value={documentation.subSubpayment}
                placeholder={`Type the ${documentation.payment} Name`}
                onChange={handleChange}
              />
            )}
          </div>
          <div>
            <label className="form-label labels mt-3">Time Period</label>
            <select
              id="expenseName"
              className="form-select col-md-4 form-control"
              name="emioption"
              value={documentation.emioption}
              onChange={handleChange}
            >
              <option value="">Select Time Period</option>
              {emiPeriod.map((expense, index) => (
                <option key={index} value={expense.month}>
                  {expense.word}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%", marginRight: "10px" }}>
              <label className="form-label labels mt-3">Due Start Month:</label>
              <Calendar
                id="monthdate"
                className="w-full input2"
                showIcon={false}
                monthNavigator
                yearNavigator
                placeholder="Select Expense Month"
                yearRange="1500:2100"
                dateFormat="dd-mm-yy"
                inputId="monthdate"
                value={expenseDate}
                style={{
                  width: "100%",
                  height: "42px",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  border: "none",
                }}
                onChange={handleDateChange}
                required
              />
            </div>
            {documentation.emioption > 0 ? (
              <div style={{ width: "50%" }}>
                <label className="form-label labels mt-3">Due End Month:</label>
                <Calendar
                  id="monthdate"
                  className="w-full input2"
                  showIcon={false}
                  monthNavigator
                  yearNavigator
                  placeholder="Select Expense Month"
                  yearRange="1500:2100"
                  dateFormat="dd-mm-yy"
                  inputId="monthdate"
                  value={expenseDue}
                  style={{
                    width: "100%",
                    height: "42px",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                    border: "none",
                  }}
                  onChange={handleDueDateChange}
                  required
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="text-center mt-4">
            <button className="btn btn-danger me-2" onClick={handleClose}>
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
              value={buttonvalue}
              disabled={isSubmitting}
            >
              {isSubmitting ? "submitting" : buttonvalue}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ExpenseTracker;
