import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {Container,Typography,Card, CardContent, Grid, Box, TableCell, TableRow, TableBody, Table, TableHead, IconButton,} from "@mui/material";
import { Nodeapi, Assetsapi } from "../../../config/serverUrl";
import "../blooddonation.css";
import Swal from "sweetalert2";
import {IoChevronBackCircleOutline, IoChevronForwardCircleOutline} from "react-icons/io5";
import { RiMenuFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import bloodTransfusion from "../../../assets/Icons/bloodtransfusion.svg";
import redBloodCells from "../../../assets/Icons/redbloodcells.svg";
import bloodDrop from "../../../assets/Icons/bloodDrop.svg";
import { formatDate, formatTimeTo12Hour, calculateAge, bloodRequestFields } from "../BloodCommon";


const BloodRequestView = () => {
  const { id } = useParams();
  const [requestData, setRequestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [requestStatuses, setRequestStatuses] = useState({});
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;
  const [donors, setDonors] = useState([]);
  const navigate = useNavigate();

  const getFilenameFromPath = (path) => {
    if (!path) {
      return "-";
    }
    const segments = path.split(/[/\\]/);
    return segments.pop();
  };
  const dateFields = new Set(["dob", "from_date_required", "to_date_required"]);
  const handlePostRequest = async () => {
    try {
      const response = await axios.patch(
        `${Nodeapi}/blood-donation/request/${id}`,
        { approval_status: "Pending" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Request Posted Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        setRequestStatuses((prev) => ({ ...prev, [id]: "Pending" }));
        //on approved
        const requestData = await axios.get(`${Nodeapi}/blood-donation/request/${id}`);
        const { district, state, blood_group } = requestData.data;
        const bloodgroup = blood_group;
        const matchedDonors = await axios.get(`${Nodeapi}/blood-donation/searchDonors`,{params: { district, state, bloodgroup },});
        const donorsList = matchedDonors.data;
        const validDonors = donorsList.filter((donor) => donor.userid != null);
        for (const donor of validDonors) {
          try {
            await axios.post(`${Nodeapi}/blood-donation/donorManagement`, {donor_id: donor.userid, donor_status: "Pending", request_id: id,});
          } catch (error) {
            console.error( `Error inserting donor management entry for donor ${donor.userid}:`, error);}}
        navigate("/main/BloodDonation");
      }
    } catch (error) {
      console.error("Error handling the request:", error);
    }};

  const handleNextRequest = () => {
    const currentIndex = requests.findIndex((request) => request.id == id);
    if (currentIndex === -1) {
      console.error("Current request ID not found in the filtered requests array.");
      return;
    }
    if (currentIndex < requests.length - 1) {
      const nextRequestId = requests[currentIndex + 1].id;
      navigate(`/main/BloodRequestView/${nextRequestId}`);
    } else {
      console.log("No more requests available.");
    }};
  useEffect(() => {
    const fetchAllRequests = async () => {
      try {
        const response = await axios.get(`${Nodeapi}/blood-donation/requests`);
        const userRequests = response.data.filter((request) => request.userid == userId);
        setRequests(userRequests);
        const statuses = userRequests.reduce((acc, request) => {
          acc[request.id] = request.approval_status;
          return acc;
        }, {});
        setRequestStatuses(statuses);
      } catch (err) {
        console.error("Error fetching requests:", err);
      }};
    if (userId) {
      fetchAllRequests();
    }
  }, [userId]);

  useEffect(() => {
    const fetchDonorsData = async () => {
      try {
        const donorManagementResponse = await axios.get(`${Nodeapi}/blood-donation/donorManagementByRequestId`,{ params: { request_id: id } });
        const acceptedDonors = donorManagementResponse.data.filter( (donor) => donor.donor_status === "Accepted");
        const donorIds = acceptedDonors.map((donor) => donor.donor_id);
        if (donorIds.length > 0) {
          const donorInfoResponse = await axios.get(`${Nodeapi}/blood-donation/donorDetailsByDonorId`,{ params: { ids: donorIds.join(",") } });
          const donorDetails = donorInfoResponse.data;
          const combinedDonors = acceptedDonors.map((acceptedDonor) => {
            const donorDetail = donorDetails.find((detail) => detail.userid === acceptedDonor.donor_id);
            return {
              ...donorDetail,
              available_date: acceptedDonor.available_date,
              available_time: acceptedDonor.available_time,
              blood_units: acceptedDonor.blood_units,
            };
          });
          setDonors(combinedDonors);
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchRequestData = async () => {
      try {
        const response = await axios.get(`${Nodeapi}/blood-donation/request/${id}`);
        setRequestData(response.data);
        await fetchDonorsData();
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }};
    if (id) {
      fetchRequestData();
    }
  }, [id]);
  if (loading) return <Container>Loading...</Container>;
  if (!requestData)
    return (
      <Container>
        <Typography>No data found</Typography>
      </Container>
    );
  const formatValue = (key, value) => {
    if (dateFields.has(key) && value) {
      if (key === "dob") {
        return calculateAge(value) + " years";
      }
      return formatDate(value);
    }
    if (key === "to_time_required") {
      return formatTimeTo12Hour(value);
    }
    if (key === "id_proof" || key === "donor_criteria") {
      const filename = getFilenameFromPath(value);
      if (filename === "-") {
        return filename;
      }

      const fileURL = `${Assetsapi}/adimages/${filename}`;
      if (value.endsWith(".png") ||value.endsWith(".jpg") ||value.endsWith(".jpeg") ||value.endsWith(".webp")) {
        return (
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
            <img src={fileURL} alt={filename} style={{ maxWidth: "100px", maxHeight: "100px" }}/>
          </a>
        );}
      return (
        <a href={fileURL} target="_blank" rel="noopener noreferrer">{filename}</a>
      ); }
    return value === null || value === undefined || value === ""? "-" : value.toString();
  };
  const firstColumnFields = bloodRequestFields.slice(0, 3);
  const secondColumnFields = bloodRequestFields.slice(3, 15);
  const thirdColumnFields = bloodRequestFields.slice(15);

  const iconMap = {
    blood_group: (
      <img src={bloodDrop} alt="Blood Group Icon" style={{ width: "34px", height: "34px" }}/> ),
    blood_component_type: (
      <img src={redBloodCells} alt="Blood Component Icon" style={{ width: "34px", height: "34px" }}/>),
    units_required: (
      <img src={bloodTransfusion} alt="Blood Units Icon" style={{ width: "34px", height: "34px" }}/>),};

  return (
    <div style={{ marginBottom: "10px", paddingBottom: "10px" }}>
      <Box className="back"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 3,
          marginLeft: 2,
          marginRight: 2,
          marginBottom: 2,
        }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={{
              marginLeft: 2,
              color: "#000",
              "&:hover": {
                color: "#000",
                textDecoration: "none",
              },
            }}>
            Blood Request Details
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => navigate(-1)}>
            <IoChevronBackCircleOutline />
          </IconButton>
          <IconButton onClick={() => navigate("/main/BloodDonation")}>
            <RiMenuFill />
          </IconButton>
          <IconButton
            onClick={handleNextRequest}
            disabled={requests.length <= 1}>
            <IoChevronForwardCircleOutline />
          </IconButton>
        </Box>
      </Box>
      <Card
        sx={{
          maxWidth: "auto",
          margin: "2px",
          padding: 1,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
        }} >
        <CardContent>
          <Grid container spacing={2} sx={{ marginBottom: 3 }}>
            <Grid item xs={12}>
              <Card
                sx={{
                  flex: "1",
                  padding: 2,
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                }}>
                <Grid container spacing={2} sx={{ paddingLeft: 14 }}>
                  {firstColumnFields.map(({ key, label }) => (
                    <Grid item xs={12} sm={6} md={4} key={key} sx={{display: "flex",justifyContent: "center", alignItems: "center",}}>
                      <div className="blood-view-icon"
                        style={{
                          backgroundColor: "#F4F7FE",
                          height: "56px",
                          width: "56px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "16px",}}>
                        {iconMap[key]}
                      </div>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body1"
                            sx={{fontWeight: "500",fontSize: "14px",color: "#A3AED0"}} >
                            {label}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1" sx={{fontWeight: "700", fontSize: "18px", color: "#2B3674",}}>
                            {formatValue(key, requestData[key])}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ display: "flex" }}>
            <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
              <Card sx={{flex: "1", padding: 2, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",}}>
                {secondColumnFields.map(({ key, label }) => (
                  <Grid container spacing={1} key={key} sx={{padding: 1}}>
                    <Grid item xs={4}>
                      <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        {formatValue(key, requestData[key])}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
              <Card
                sx={{
                  flex: "1",
                  padding: 2,
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                }}>
                {thirdColumnFields.map(({ key, label }) => (
                  <Grid container spacing={1} key={key}
                    sx={{
                      padding: 1,
                    }}>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        {formatValue(key, requestData[key])}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
            <button className="blood-request-btn" onClick={handlePostRequest} disabled={requestStatuses[id] !== "Created"}>
              {requestStatuses[id] !== "Created" ? "Requested" : "Request"}
            </button>
          </Box>
        </CardContent>
      </Card>
      <div style={{ marginTop: "15px", paddingTop: "15px" }}>
        <Card
          sx={{
            maxWidth: "auto",
            margin: "2px",
            marginBottom: "30px",
            padding: 2,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
          }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Accepted Donors
          </Typography>
          {donors.length > 0 ? (
            <Table>
              <TableHead sx={{ backgroundColor: "#E8F0FE", fontWeight: "700" }}>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Blood Group</TableCell>
                  <TableCell>Units</TableCell>
                  <TableCell>Available Date</TableCell>
                  <TableCell>Available Time</TableCell>
                  <TableCell>Contact Number</TableCell>
                  <TableCell>District</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {donors.map((donor, index) => (
                  <TableRow key={donor.userid}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{donor.bloodgroup}</TableCell>
                    <TableCell>
                      {donor.blood_units ? donor.blood_units : "-"}
                    </TableCell>
                    <TableCell>{formatDate(donor.available_date)}</TableCell>
                    <TableCell>
                      {formatTimeTo12Hour(donor.available_time)}
                    </TableCell>
                    <TableCell>{donor.phonenumber}</TableCell>
                    <TableCell>{donor.district}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>No accepted donors found.</Typography>
          )}
        </Card>
      </div>
    </div>
  );};
export default BloodRequestView;
