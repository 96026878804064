import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { IoShareSocialOutline } from "react-icons/io5"; // Assuming you're using react-icons for share icons
import VehicleGallery from "../PostViewPage";
import { TiArrowForwardOutline } from "react-icons/ti";
import { IoMdHeartEmpty, IoMdPin } from "react-icons/io";
import "../PostView.scss";
import { RiDeleteBin2Line } from "react-icons/ri";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { DataContext } from "../../../Admin/DataContext";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import EditPost from "../EditPost";
import { deleteDraft, reActivateDelete } from "../../../routes/profileRoutes";
import Swal from "sweetalert2";
import { BiRepost } from "react-icons/bi";

const DraftOverview = () => {
  const authdata = useSelector((state) => state.auth.user);
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    setview_adpost,
    datavalue,
    title,
    description,
    place,
    Price,
    fetchdata,
    loading,
    back_btn,
    setback_btn,
  } = useContext(DataContext);
  console.log(datavalue, "datavalue delete");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPostDetail, setSelectedPostDetail] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);

  const handleOpenModal = (post_id) => {
    setIsModalOpen(true);
    setSelectedPostDetail(datavalue.find((post) => post.post_id === post_id));
    setIsEditMode(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formattedPrice = (price) => {
    return new Intl.NumberFormat("en-IN").format(price);
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const handleback = () => {
    navigate("/main/advDasboared");
    setview_adpost([]);
  };
  const handlebacktoacitivity = () => {
    navigate("/main/Activity");
    setview_adpost([]);
    setback_btn(false);
  };

  const handleDelete = async (datavalue) => {
    try {
      const postId = datavalue[0].id;

      await deleteDraft(postId);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Post Removed Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      fetchdata();
    } catch (error) {
      console.error("Failed to deactivate post:", error);
    }
  };

  const handleReActivate = async (datavalue) => {
    try {
      const postId = datavalue[0].post_id;

      await reActivateDelete(postId);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Post Removed Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      fetchdata();
    } catch (error) {
      console.error("Failed to deactivate post:", error);
    }
  };
  const firstItem =
    Array.isArray(datavalue) && datavalue.length > 0 ? datavalue[0] : {};

  return (
    <div className="VehicleOverview">
      {back_btn ? (
        <div
          className="mt-2"
          style={{ marginLeft: "2%" }}
          onClick={handlebacktoacitivity}
        >
          <button style={{ border: "none", background: "none" }}>
            <MdOutlineKeyboardArrowLeft
              style={{ fontSize: "28px", marginTop: "-8%" }}
            />
            <span style={{ fontSize: "20px", marginLeft: "-5px" }}>Back</span>
          </button>
        </div>
      ) : (
        <div className="mt-2" style={{ marginLeft: "2%" }} onClick={handleback}>
          <button style={{ border: "none", background: "none" }}>
            <MdOutlineKeyboardArrowLeft
              style={{ fontSize: "28px", marginTop: "-8%" }}
            />
            <span style={{ fontSize: "20px", marginLeft: "-5px" }}>Back</span>
          </button>
        </div>
      )}

      {loading ? (
        <div className="spinner-container">
          <div className="spin"></div>
        </div>
      ) : (
        <Grid className="mt-0" container spacing={2}>
          <Grid item xs={12} sm={8}>
            <VehicleGallery datavalue={datavalue} />
            <div
              className="VehiDesciption mt-4"
              style={{ padding: "0px 20px" }}
            >
              <div className="card">
                <h4>Description</h4>
                <p>{description}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="vehiGal_right">
              <div className="card">
                <div className="flex align-items-center justify-content-between mb-2">
                  <h5>{title}</h5>
                  <div className="vehigal_buttons flex align-items-center justify-content-between">
                    <p>
                      <IoShareSocialOutline className="vehibut-icon" />
                    </p>
                    <p>
                      <TiArrowForwardOutline className="vehibut-icon" />
                    </p>
                    <p>
                      <IoMdHeartEmpty className="vehibut-icon" />
                    </p>
                  </div>
                </div>
                <div className="vehimodal"></div>
                <div className="vehikms"></div>
                <div className="vehiloco">
                  <p>
                    <IoMdPin />
                    <span>{place}</span>
                  </p>
                </div>
                <div className="vehiprice">
                  <p>
                    <MdOutlineCurrencyRupee />
                    <span>{formattedPrice(Price)}</span>
                  </p>
                </div>
                <div
                  className="post-btn-container"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="postbtnsend"
                    onClick={() => {
                      handleOpenModal(firstItem.post_id);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <FaRegEdit style={{ marginBottom: "3px" }} />
                    <span>Edit Post</span>
                  </button>
                  {isModalOpen && (
                    <EditPost
                      open={isModalOpen}
                      handleClose={handleCloseModal}
                      setIsModalOpen={setIsModalOpen}
                      selectedPostDetail={selectedPostDetail}
                      setSelectedPostDetail={setSelectedPostDetail}
                      setIsEditMode={setIsEditMode}
                      isEditMode={isEditMode}
                      fetchPost={fetchdata}
                    />
                  )}
                  {(firstItem.post_draft == 0 ||
                    firstItem.post_draft == null) && (
                    <button
                      className="postbtnsend"
                      onClick={() => {
                        handleDelete(datavalue);
                      }}
                      style={{
                        backgroundColor: "red",
                        border: "2px solid red",
                      }}
                    >
                      <RiDeleteBin2Line style={{ marginBottom: "3px" }} />
                      <span>Delete</span>
                    </button>
                  )}

                  {firstItem.post_draft == 1 && (
                    <button
                      className="postbtnsend"
                      onClick={() => {
                        handleReActivate(datavalue);
                      }}
                    >
                      <BiRepost style={{ marginBottom: "3px",fontSize:"24px" }} />
                      <span>Reactivate</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default DraftOverview;
