import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Nodeapi, Assetsapi } from '../../../config/serverUrl';
import {
    Card,
    CardContent,
    Typography,
    Container,
    CardMedia,
    Box,
    Chip,
    Avatar,
    styled,
    Button, Grid,
    Divider, Link,
    Skeleton,
    IconButton
} from '@mui/material';
import LocationIcon from '../../../assets/Icons/CineJobLocation.png';
import RupeeIcon from '../../../assets/Icons/CineJobRupee.png';
import SkillIcon from '../../../assets/Icons/CineJobSkill.png';
import ExperienceIcon from '../../../assets/Icons/CineJobSuitcase.png';
import TimeIcon from '../../../assets/Icons/CineJobTime.png';
import AppliedIcon from '../../../assets/Icons/CineJobApplied.png';
import ViewIcon from '../../../assets/Icons/CineJobView.png';
import PhoneIcon from '../../../assets/Icons/CineJobPhone.png';
import EmailIcon from '../../../assets/Icons/CineJobEmail.png';
import moment from 'moment';
import JobSeekingSimilar from './JobSeekingSimilar';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { ASSETS_API, CINE_JOB_API } from '../../../config/api';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from 'react-icons/io5';
import { RiMenuFill } from 'react-icons/ri';

// Custom styled components
const JobCard = styled(Card)(({ theme }) => ({
    borderRadius: '15px',
    border: '1px solid #e6e6e6',
    backgroundColor: '#ffffff',
    padding: '14px',
    marginBottom: '24px',
    '&:hover': {
        border: '1px solid #68bcff',
        backgroundColor: 'transparent',
    },
}));

const JobIcon = styled(Avatar)(({ theme }) => ({
    width: '25px',
    height: '25px',
    marginRight: '4px'
}));

const JobTimeChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'rgb(239, 245, 252)',
    color: '#4a4a4a',
    fontSize: '14px',
}));

const JobContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '0px'
}));

const JobChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'rgb(239, 245, 252)',
    color: '#4a4a4a',
    fontSize: '14px',
}));

const SkillChip = styled(Chip)(({ theme }) => ({
    border: '2px solid #E6E6E6',
    color: '#4a4a4a',
    fontSize: '14px',
    backgroundColor: '#fff',
    borderRadius: '8px'
}));

const capitalizeText = {
    textTransform: 'capitalize',
};

const CompanyLogo = styled(CardMedia)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        width: 75,
        height: 75
    },
    [theme.breakpoints.up('sm')]: {
        width: 80,
        height: 80
    },
    [theme.breakpoints.up('md')]: {
        width: 90,
        height: 90
    },
    [theme.breakpoints.up('lg')]: {
        width: 120,
        height: 120
    },
}));

const JobSeekingDetail = () => {
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [jobPost, setJobPost] = useState([]);
    const navigate = useNavigate();
    const userId = useSelector((state) => state.auth.user)?.id
    const [hasApplied, setHasApplied] = useState(false);

    useEffect(() => {
        const checkApplicationStatus = async () => {
            try {
                const response = await axios.get(CINE_JOB_API.CHECK_APPLICATION_STATUS(jobId, userId));
                console.log(jobId, userId)
                if (response.status === 200) {
                    setHasApplied(response.data.hasApplied);
                    console.log(response.data.hasApplied)
                }
            } catch (error) {
                console.error('Error checking application status:', error);
            }
        };

        if (userId) {
            checkApplicationStatus();
        }
    }, [jobId, userId]);

    const showAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [jobId]);

    useEffect(() => {
        const getJobDetails = async () => {
            try {
                const { data } = await axios.get(CINE_JOB_API.GET_BY_CINE_JOB(jobId));
                setJob(data[0]);
            } catch (error) {
                console.error("Error fetching job details:", error);
            } finally {
                setLoading(false);
            }
        };
        const fetchPostData = async () => {
            try {
                const { data } = await axios.get(CINE_JOB_API.GET_ALL_CINE_JOB);
                setJobPost(data);
            } catch (err) {
                console.error('Error fetching job details:', err);
            }
        };

        fetchPostData();

        getJobDetails();
    }, [jobId]);

    if (loading) {
        return (
            <Container sx={{ mt: 4 }}>
                <JobCard>
                    <CardContent sx={{ padding: '0px', '&:last-child': { paddingBottom: '0px' } }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={1} >
                            <Box display="flex" alignItems="center" sx={{ gap: 3 }} >
                                <Skeleton variant="rectangular" width={100} height={60} sx={{ mb: 2 }} />
                                <Box>
                                    <Skeleton variant="text" width="60%" sx={{ mb: 1 }} />
                                    <Skeleton variant="text" width="40%" sx={{ mb: 2 }} />
                                </Box>
                            </Box>
                            <Box display="flex" alignItems={"center"} gap={2}>
                                <Skeleton variant="rectangular" width={100} height={40} sx={{ mb: 2 }} />
                                <Skeleton variant="rectangular" width={100} height={40} sx={{ mb: 2 }} />
                            </Box>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={3} alignItems="center">
                            {[...Array(4)].map((_, index) => (
                                <Grid item xs={12} sm={3} key={index}>
                                    <Skeleton variant="text" width="80%" sx={{ mb: 1 }} />
                                </Grid>
                            ))}
                        </Grid>
                        <Divider sx={{ mt: 2 }} />
                        <Card sx={{ p: 1, my: 1 }}>
                            <Box display="flex" alignItems="center" mb={1}>
                                <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                                <Skeleton variant="text" width="50%" sx={{ mb: 2 }} />
                            </Box>
                            <Box display="flex" justifyContent="start" mb={1} gap={2}>
                                <Box display="flex" alignItems="center">
                                    <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                                    <Skeleton variant="text" width="40%" sx={{ mb: 2 }} />
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Skeleton variant="circular" width={25} height={25} sx={{ mb: 2 }} />
                                    <Skeleton variant="text" width="40%" sx={{ mb: 2 }} />
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="start" mb={1}>
                                <Skeleton variant="text" width="40%" sx={{ mb: 2 }} />
                            </Box>
                            <Box display="flex" justifyContent="start" gap={{ sm: 1, md: 2 }}>
                                <Skeleton variant="text" width="20%" sx={{ mb: 2 }} />
                                <Skeleton variant="text" width="30%" sx={{ mb: 2 }} />
                                <Skeleton variant="text" width="30%" sx={{ mb: 2 }} />
                            </Box>
                        </Card>
                        <Box>
                            <Skeleton variant="text" width="80%" sx={{ mb: 2 }} />
                            <Skeleton variant="text" width="90%" sx={{ mb: 2 }} />
                            <Skeleton variant="text" width="70%" sx={{ mb: 2 }} />
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                        <Skeleton variant="text" width="60%" sx={{ mb: 2 }} />
                        <Skeleton variant="text" width="70%" sx={{ mb: 2 }} />
                        <Skeleton variant="text" width="50%" sx={{ mb: 2 }} />
                    </CardContent>
                </JobCard>
                <JobSeekingSimilar />
            </Container>
        );
    }

    if (!job) {
        return (
            <Container>
                <Typography variant="h6">No job data available.</Typography>
            </Container>
        );
    }

    const { jobDetail, companyDetail, candidateDetail, createdAt, id } = job;

    const postDate = moment(createdAt);
    let formattedDate;
    const now = moment();
    const hoursAgo = now.diff(postDate, 'hours');
    const daysAgo = now.diff(postDate, 'days');

    if (hoursAgo < 24) {
        formattedDate = hoursAgo <= 1 ? 'Posted an hour ago' : `Posted ${hoursAgo} hours ago`;
    } else if (daysAgo < 7) {
        formattedDate = daysAgo === 1 ? 'Posted a day ago' : `Posted ${daysAgo} days ago`;
    } else {
        formattedDate = postDate.format('DD-MM-YYYY');
    }

    const handleApply = async (jobId) => {
        if (!userId) {
            showAlert('error', 'Unauthorized', 'You are not authorized to submit this job. Please log in.');
            console.log("error")
            return;
        }

        try {
            const response = await axios.post(CINE_JOB_API.POST_CINE_JOB_APPLIED, { userId, jobId });

            if (response.status === 201) {
                showAlert('success', 'Job Applied Successfully', 'Your job application was successful. View the My Activity for more details.');
                setHasApplied(true);
            } else {
                showAlert('error', 'Submission Failed', 'Something went wrong. Please try again.');
            }
        } catch (error) {
            showAlert('error', 'Error', 'An error occurred while posting the job. Please try again.');
            console.error('Failed to post job:', error);
        }
    }

    const currentIndex = jobPost.findIndex(
        (job) => String(job.id) === String(jobId)
    );

    const hasPreviousJob = currentIndex > 0;
    const hasNextJob = currentIndex < jobPost.length - 1;

    const handlePreviousJob = () => {
        const currentIndex = jobPost.findIndex(
            (job) => String(job.id) === String(jobId)
        );

        if (currentIndex > 0) {
            const prevJobId = jobPost[currentIndex - 1].id;
            navigate(`/main/CineJob/${prevJobId}`);
        }
    };

    const handleNextJob = () => {
        const currentIndex = jobPost.findIndex(
            (job) => String(job.id) === String(jobId)
        );

        if (currentIndex < jobPost.length - 1) {
            const nextJobId = jobPost[currentIndex + 1].id;
            navigate(`/main/CineJob/${nextJobId}`);
        }
    };

    return (
        <div>
            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"} pt={{ xs: 5, md: 3 }} mb={2}>
                <Box>
                    <span onClick={() => navigate('/main/CineJob')} style={{ cursor: 'pointer' }}>
                        <MdOutlineKeyboardArrowLeft style={{ width: '40px', height: '40px' }} />
                        <span style={{ fontSize: '20px' }}>Back</span>
                    </span>
                </Box>
                <Box>
                    <IconButton onClick={handlePreviousJob} disabled={!hasPreviousJob}>
                        <IoChevronBackCircleOutline />
                    </IconButton>
                    <IconButton onClick={() => navigate('/main/CineJob')}>
                        <RiMenuFill />
                    </IconButton>
                    <IconButton onClick={handleNextJob} disabled={!hasNextJob}>
                        <IoChevronForwardCircleOutline />
                    </IconButton>
                </Box>
            </Box>
            <JobCard>
                <CardContent sx={{ padding: '0px', '&:last-child': { paddingBottom: '0px' } }}>
                    <Box display="flex" justifyContent="space-between" flexWrap={"wrap"} alignItems="center" mt={1} mb={1} >
                        <Box display="flex" alignItems="center" sx={{ gap: 3 }} >
                            <CompanyLogo>
                                <Box
                                    bgcolor="#EFF5FC"
                                    borderRadius="10px 10px 10px 10px"
                                    width="100%"
                                    height="100%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <div style={{
                                        position: 'relative',
                                        width: 'calc(100% - 16px)',
                                        height: 'calc(100% - 16px)',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        backgroundColor: '#EFF5FC',
                                    }}>
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundImage: `url(${ASSETS_API.ASSETS_AD_IMAGES(companyDetail?.file)})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                filter: 'blur(2px)',
                                                zIndex: 0,
                                                opacity: 0.9,
                                                borderRadius: '8px',
                                            }}
                                        ></span>
                                        <img
                                            src={`${ASSETS_API.ASSETS_AD_IMAGES(companyDetail?.file)}`}
                                            alt="Company Logo"
                                            style={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                zIndex: 1,
                                                borderRadius: '8px',
                                            }}
                                        />
                                    </div>
                                </Box>
                            </CompanyLogo>
                            <Box>
                                <Typography variant="h6" component="div" fontWeight={500} color="#4a4a4a" sx={capitalizeText}>
                                    {jobDetail?.jobTitle || ""}
                                </Typography>
                                <Typography variant="subtitle1" color="#2196f3" mr={2} sx={{ ...capitalizeText, cursor: "pointer" }} >
                                    {companyDetail?.companyName || "Company Name"}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems={"center"} flexWrap={"wrap"} gap={{ xs: 1, md: 2 }}>
                            <Button
                                sx={{
                                    mt: 3, mb: 2, backgroundColor: '#2196f3',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                    },
                                    textTransform: 'capitalize', '&.Mui-disabled': {
                                        backgroundColor: '#a0c4ff',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#a0c4ff',
                                        },
                                    },
                                }}
                                variant="contained"
                                onClick={() => handleApply(id)}
                                disabled={hasApplied}
                            >
                                {hasApplied ? 'Already Applied' : 'Apply Now'}
                            </Button>
                            <Button
                                sx={{
                                    mt: 3, mb: 2, borderColor: '#2196f3',
                                    color: '#2196f3',
                                    '&:hover': {
                                        backgroundColor: 'rgba(33, 150, 243, 0.1)',
                                        borderColor: '#2196f3',
                                    },
                                    textTransform: 'capitalize',
                                }}
                                variant="outlined"
                            >
                                Save Job
                            </Button>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 3 }} />
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={12} sm={3}>
                            <Link
                                href="#Highlights"
                                underline="none"
                                color="#4A4A4A"
                                sx={{
                                    '&:hover': {
                                        color: '#2196F3',
                                    },
                                }}
                            >
                                <Typography variant="body2">Highlights</Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Link href="#JobDescription" underline="none"
                                color="#4A4A4A"
                                sx={{
                                    '&:hover': {
                                        color: '#2196F3',
                                    },
                                }}>
                                <Typography variant="body2">Job Description</Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Link href="#JobParticulars" underline="none"
                                color="#4A4A4A"
                                sx={{
                                    '&:hover': {
                                        color: '#2196F3',
                                    },
                                }}>
                                <Typography variant="body2">Job Particulars</Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Link href="#RecruiterInformation" underline="none"
                                color="#4A4A4A"
                                sx={{
                                    '&:hover': {
                                        color: '#2196F3',
                                    },
                                }}>
                                <Typography variant="body2">Recruiter Information</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: 3 }} />
                    <Card sx={{ p: 1, my: 1 }} id="Highlights">
                        <Box display="flex" alignItems="center" flexWrap={"wrap"} mb={1} gap={"4px"}>
                            <JobIcon src={LocationIcon} alt="" variant="square" />
                            <Typography variant="body2" color="#4a4a4a" ml={1} sx={capitalizeText}>
                                {jobDetail?.jobAddress || "Job Address"}
                            </Typography>

                            {jobDetail?.workType?.length > 0 && (
                                <JobContainer ml={1}>
                                    {jobDetail?.workType?.map((type, idx) => (
                                        <JobChip key={idx} label={type} sx={capitalizeText} />
                                    ))}
                                </JobContainer>
                            )}
                        </Box>

                        <Box display="flex" justifyContent="start" mb={1} gap={2} flexWrap={"wrap"}>
                            <Box display="flex" alignItems="center">
                                <JobIcon src={ExperienceIcon} alt="" variant="square" />
                                <Typography variant="body2" color="#4a4a4a" ml={1} sx={capitalizeText}>
                                    {candidateDetail?.minExperience && candidateDetail?.maxExperience
                                        ? `${candidateDetail.minExperience} - ${candidateDetail.maxExperience} yrs`
                                        : 'Not specified'}                                    </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <JobIcon src={RupeeIcon} alt="" variant="square" />
                                <Typography variant="body2" color="#4a4a4a" ml={1} sx={capitalizeText}>
                                    {jobDetail?.minSalary && jobDetail?.maxSalary
                                        ? `${jobDetail.minSalary} - ${jobDetail.maxSalary} INR`
                                        : 'Not specified'}                                    </Typography>
                            </Box>
                        </Box>

                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Box display="flex" alignItems="center">
                                <JobIcon src={SkillIcon} alt="" variant="square" />
                                <JobContainer>
                                    {candidateDetail?.preferredSkills > 0 ? candidateDetail?.preferredSkills?.map((skill, idx) => (
                                        <JobChip key={idx} label={skill} sx={capitalizeText} />
                                    )) : <Typography variant="body2" color="#4a4a4a" ml={1}>
                                        Not specified
                                    </Typography>}
                                </JobContainer>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Box display="flex" alignItems="center">
                                <JobIcon src={TimeIcon} alt="" variant="square" />
                                <JobContainer>
                                    {jobDetail?.workModes > 0 ? jobDetail?.workModes?.map((mode, idx) => (
                                        <JobChip key={idx} label={mode} sx={capitalizeText} />
                                    )) : <Typography variant="body2" color="#4a4a4a" ml={1}>
                                        Not specified
                                    </Typography>}
                                </JobContainer>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="start" flexWrap={"wrap"} gap={{ sm: 1, md: 2 }}>
                            <Box display="flex" alignItems="center">
                                <JobIcon src={TimeIcon} alt="" variant="square" />
                                <Typography variant="body2" color="#4a4a4a">
                                    {formattedDate}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <JobIcon src={ViewIcon} alt="" variant="square" />
                                <Typography variant="body2" color="#4a4a4a">
                                    Viewed
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <JobIcon src={AppliedIcon} alt="" variant="square" />
                                <Typography variant="body2" color="#4a4a4a">
                                    Applied
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Box id="JobDescription">
                        <Typography variant="h6" color="#2196f3" my={2} sx={capitalizeText}>
                            Job Description
                        </Typography>
                        <Typography variant="body2" color="#4a4a4a" ml={1} sx={capitalizeText}>
                            {jobDetail?.jobDescription}
                        </Typography>
                    </Box>
                    <Divider sx={{ mt: 2 }} />

                    <Box id="JobParticulars">
                        <Typography variant="h6" color="#2196f3" my={2} sx={capitalizeText}>
                            Job Particular
                        </Typography>
                        <Box display='flex' gap={{ sm: 1, md: 2 }} my={1}>
                            <Typography variant="body2" color="#8B8B8B" ml={1} sx={capitalizeText}>
                                Industry
                            </Typography>
                            <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                {jobDetail?.industry != "" ? jobDetail.industry : 'Not specified'}
                            </Typography>
                        </Box>
                        <Box display='flex' gap={{ sm: 1, md: 2 }} my={1}>
                            <Typography variant="body2" color="#8B8B8B" ml={1} sx={capitalizeText}>
                                Job Title
                            </Typography>
                            <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                {jobDetail?.jobTitle != "" ? jobDetail.jobTitle : 'Not specified'}
                            </Typography>
                        </Box>
                        <Box display='flex' gap={{ sm: 1, md: 2 }} my={1}>
                            <Typography variant="body2" color="#8B8B8B" ml={1} sx={capitalizeText}>
                                Job Type
                            </Typography>
                            <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                {
                                    Array.isArray(jobDetail?.workType) && jobDetail?.workType.length > 0
                                        ? jobDetail.workType.join(', ')
                                        : 'Not specified'
                                }
                            </Typography>
                        </Box>
                        <Box display='flex' gap={{ sm: 1, md: 2 }} my={1}>
                            <Typography variant="body2" color="#8B8B8B" ml={1} sx={capitalizeText}>
                                Job Mode
                            </Typography>
                            <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                {job?.jobDetail?.workModes && Array.isArray(job.jobDetail.workModes) && job.jobDetail.workModes.length > 0
                                    ? job.jobDetail.workModes.join(', ')
                                    : 'Not specified'}
                            </Typography>
                        </Box>
                        <Box display='flex' gap={{ sm: 1, md: 2 }} my={1}>
                            <Typography variant="body2" color="#8B8B8B" ml={1} sx={capitalizeText}>
                                Qualification
                            </Typography>
                            <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                {Array.isArray(candidateDetail?.qualification) && candidateDetail.qualification.length > 0
                                    ? candidateDetail.qualification.join(', ')
                                    : candidateDetail?.qualification || 'Not specified'}
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' gap={{ sm: 1, md: 2 }} my={1}>
                            <Typography variant="body2" color="#8B8B8B" ml={1} sx={capitalizeText}>
                                Key Skill
                            </Typography>
                            <JobContainer>
                                {candidateDetail?.preferredSkills?.length > 0 ? (
                                    candidateDetail.preferredSkills.map((skill, idx) => (
                                        <SkillChip key={idx} label={skill} sx={capitalizeText} />
                                    ))
                                ) : (
                                    <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                        Not specified
                                    </Typography>
                                )}
                            </JobContainer>
                        </Box>
                    </Box>
                    <Divider sx={{ mt: 2 }} />
                    <Box id="RecruiterInformation">
                        <Typography variant="h6" color="#2196f3" my={2} sx={capitalizeText}>
                            Recruiter Information
                        </Typography>
                        <Box my={1}>
                            <Typography variant="body2" color="#8B8B8B" sx={capitalizeText}>
                                About Company
                            </Typography>
                            <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                {companyDetail?.overview ? companyDetail.overview : 'Not specified'}
                            </Typography>
                        </Box>
                        <Box my={1}>
                            <Typography variant="body2" color="#8B8B8B" sx={capitalizeText}>
                                Company Address
                            </Typography>
                            <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                {companyDetail?.address ? companyDetail.address : 'Not specified'}
                            </Typography>
                        </Box>
                        <Box my={1}>
                            <Typography variant="body2" color="#8B8B8B" sx={capitalizeText}>
                                Company Website
                            </Typography>
                            {companyDetail?.companyUrl ? (
                                <Link
                                    href={companyDetail.companyUrl}
                                    sx={{ textDecoration: 'none', color: '#2196f3', cursor: 'pointer' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {companyDetail.companyUrl}
                                </Link>
                            ) : (
                                <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                    Not specified
                                </Typography>
                            )}
                        </Box>
                        <Box my={1}>
                            <Typography variant="body2" color="#8B8B8B" sx={capitalizeText}>
                                Contact Person
                            </Typography>
                            <Typography variant="body2" color="#4A4A4A" sx={capitalizeText}>
                                {companyDetail?.recruiterName ? companyDetail.recruiterName : 'Not specified'}
                            </Typography>
                            <Box display="flex" flexWrap={"wrap"} gap={1} my={1}>
                                <Box display="flex" alignItems="center">
                                    <JobIcon src={PhoneIcon} alt="" variant="square" />
                                    <Typography variant="body2" color="#4a4a4a" ml={1}>
                                        {companyDetail?.recruiterContact ? companyDetail.recruiterContact : 'Not specified'}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <JobIcon src={EmailIcon} alt="" variant="square" />
                                    <Typography variant="body2" color="#4a4a4a" ml={1}>
                                        {companyDetail?.emailId ? companyDetail.emailId : 'Not specified'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"} flexWrap={"wrap"} gap={{ xs: 1, md: 2 }}>
                        <Button
                            sx={{
                                mt: 3, mb: 2, backgroundColor: '#2196f3',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#1976d2',
                                }, textTransform: 'capitalize',
                                '&.Mui-disabled': {
                                    backgroundColor: '#a0c4ff',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#a0c4ff',
                                    },
                                },
                            }}
                            variant="contained"
                            onClick={() => handleApply(id)}
                            disabled={hasApplied}
                        >
                            {hasApplied ? 'Already Applied' : 'Apply Now'}
                        </Button>
                        <Button
                            sx={{
                                mt: 3, mb: 2, borderColor: '#2196f3',
                                color: '#2196f3',
                                '&:hover': {
                                    backgroundColor: 'rgba(33, 150, 243, 0.1)',
                                    borderColor: '#2196f3',
                                }, textTransform: 'capitalize'
                            }}
                            variant="outlined"
                        >
                            Save Job
                        </Button>
                    </Box>
                </CardContent>
            </JobCard>
            <JobSeekingSimilar industry={jobDetail?.industry} jobId={jobId} />
        </div>
    );
};

export default JobSeekingDetail;
