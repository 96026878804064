import { createSlice } from "@reduxjs/toolkit";

const initialState = '';

const socketSlice = createSlice({
    name: 'chat',
    initialState,
    reducers:{
        setSocketid(state,action){
            state = action.payload
        }
    }
});

export const { setSocketid } = socketSlice.actions;

export default socketSlice.reducer;