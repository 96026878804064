import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Box, Modal } from '@mui/material';
import JobDetailsForm from './JobDetailsForm';
import CandidateRequirement from './CandidateRequirement';
import CompanyDetails from './CompanyDetails';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Nodeapi } from '../../../config/serverUrl';
import { CINE_JOB_API } from '../../../config/api';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";

const steps = ['Job Details', 'Candidate Requirements', 'Company Details'];

const JobPosting = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [jobDetails, setJobDetails] = useState({
        industry: '',
        jobTitle: '',
        jobOpening: '',
        workShifts: [],
        workModes: [],
        workType: [],
        minSalary: '',
        maxSalary: '',
        workingDays: [],
        jobDescription: '',
        jobAddress: '',
    });
    const [candidateRequirement, setCandidateRequirement] = useState({
        qualification: [],
        degreeSpecialization: '',
        specifyCertificate: '',
        preferredSkills: [],
        preferredGender: [],
        minExperience: '',
        maxExperience: '',
        languages: [],
        hiringRange: [],
        bonusOffered: [],
        minAge: '',
        maxAge: '',
        resumeRequired: false,
        photoUploadRequired: false,
    });
    const [companyDetails, setCompanyDetails] = useState({
        companyName: '',
        file: null,
        companyUrl: '',
        address: '',
        landmark: '',
        overview: '',
        recruiterName: '',
        recruiterContact: '',
        phoneNumber: '',
        emailId: '',
        hireCandidatesWithin: [],
    });
    const [errorsJobDetails, setErrorsJobDetails] = useState({});
    const [errorsCandidateReq, setErrorsCandidateReq] = useState({});
    const [errorsCompanyDetails, setErrorsCompanyDetails] = useState({});
    const authdata = useSelector((state) => state.auth.user);
    const userId = authdata?.id;
    const showAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
        });
    };

    const validateJobDetails = () => {
        const newErrorsJobDetails = {};

        if (!jobDetails.industry) newErrorsJobDetails.industry = 'Industry is required';
        if (!jobDetails.jobTitle) newErrorsJobDetails.jobTitle = 'Job Title is required';
        if (!jobDetails.jobOpening) newErrorsJobDetails.jobOpening = 'Job Opening is required';
        if (!jobDetails.jobDescription) newErrorsJobDetails.jobDescription = 'Job Description is required';
        if (!jobDetails.jobAddress) newErrorsJobDetails.jobAddress = 'Job Address is required';

        const minSalary = parseFloat(jobDetails.minSalary);
        const maxSalary = parseFloat(jobDetails.maxSalary);

        if (minSalary > 0 && isNaN(maxSalary) || !maxSalary) {
            newErrorsJobDetails.salary = 'Max Salary is required when Min Salary is provided';
        } else if (minSalary > 0 && maxSalary < minSalary) {
            newErrorsJobDetails.salary = 'Minimum Salary cannot be greater than Maximum Salary';
        }

        setErrorsJobDetails(newErrorsJobDetails);

        return Object.keys(newErrorsJobDetails).length === 0
    };

    const CandidateReq = () => {
        const newErrors = {};

        const minExp = parseFloat(candidateRequirement.minExperience);
        const maxExp = parseFloat(candidateRequirement.maxExperience);

        if (minExp > 0 && isNaN(maxExp)) {
            newErrors.experience = 'Max Experience is required when Min Experience is provided';
        } else if (minExp > 0 && maxExp < minExp) {
            newErrors.experience = 'Max Experience cannot be less than Min Experience';
        }

        const minAge = parseFloat(candidateRequirement.minAge);
        const maxAge = parseFloat(candidateRequirement.maxAge);

        if (minAge > 0 && isNaN(maxAge)) {
            newErrors.age = 'Max Age is required when Min Age is provided';
        } else if (minAge > 0 && maxAge < minAge) {
            newErrors.age = 'Max Age cannot be less than Min Age';
        }

        setErrorsCandidateReq(newErrors);

        return Object.keys(newErrors).length === 0
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateCompanyDetails = () => {
        const newErrors = {};
        if (!companyDetails.companyName) newErrors.companyName = 'Company name is required';
        if (!companyDetails.address) newErrors.address = 'Company address is required';
        if (!companyDetails.landmark) newErrors.landmark = 'Landmark is required';
        if (!companyDetails.recruiterName) newErrors.recruiterName = 'Recruiter name is required';
        if (!companyDetails.recruiterContact) newErrors.recruiterContact = 'Recruiter contact number is required';
        if (!companyDetails.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        if (!companyDetails.emailId) newErrors.emailId = 'Email ID is required';
        if (!companyDetails.overview) newErrors.overview = 'Company overview is required';

        if (companyDetails.emailId && !validateEmail(companyDetails.emailId)) {
            newErrors.emailId = 'Invalid email address.';
        }

        setErrorsCompanyDetails(newErrors);
        return Object.keys(newErrors).length === 0
    }

    const handleNext = () => {
        if (activeStep === 0) {
            const isValid = validateJobDetails();
            if (!isValid) {
                return;
            }
            else {
                setActiveStep(prevStep => prevStep + 1);
            }
        } else if (activeStep === 1) {
            const isValid = CandidateReq();
            if (!isValid) {
                return;
            }
            else {
                setActiveStep(prevStep => prevStep + 1);
            }
        }
        else if (activeStep === steps.length - 1) {
            const isValid = validateCompanyDetails();
            if (!isValid) {
                return;
            }
            else {
                handleSubmit('active');
            }
        }
    };

    const handleBack = () => {
        setActiveStep(prevStep => prevStep - 1);
    };

    const handleReset = () => {
        setCandidateRequirement({
            qualification: [],
            preferredSkills: [],
            preferredGender: [],
            languages: [],
            resumeRequired: false,
            photoUploadRequired: false,
            minExperience: '',
            maxExperience: '',
            bonusOffered: [],
            hiringRange: [],
            minAge: '',
            maxAge: '',
        })
        setJobDetails({
            industry: '',
            jobTitle: '',
            jobOpening: '',
            workShifts: [],
            workType: [],
            minExperience: '',
            maxSalary: '',
            workingDays: [],
            jobDescription: '',
            jobAddress: '',
            workModes: [],
        })
        setCompanyDetails({
            companyName: '',
            companyUrl: '',
            address: '',
            landmark: '',
            overview: '',
            recruiterName: '',
            recruiterContact: '',
            phoneNumber: '',
            emailId: '',
            hireCandidatesWithin: [],
            file: ''
        })
        setErrorsJobDetails({})
        setErrorsCandidateReq({})
        setErrorsCompanyDetails({})
        setActiveStep(0);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        handleReset();
    };

    const handleSubmit = async (status) => {
        if (!userId) {
            showAlert('error', 'Unauthorized', 'You are not authorized to submit this job. Please log in.');
            return;
        }
        try {
            const updatedJobDetails = { ...jobDetails, status };

            const formData = new FormData();

            formData.append('jobDetails', JSON.stringify(updatedJobDetails));
            formData.append('candidateRequirement', JSON.stringify(candidateRequirement));
            formData.append('companyDetails', JSON.stringify(companyDetails));

            if (companyDetails.file) {
                formData.append('file', companyDetails.file);
            }
            formData.append('userId', userId);
            
            const response = await axios.post(CINE_JOB_API.POST_CINE_JOB, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                setIsModalOpen(true);
            } else {
                showAlert('error', 'Submission Failed', 'Something went wrong. Please try again.');
            }
        } catch (error) {
            showAlert('error', 'Error', 'An error occurred while posting the job. Please try again.');
            console.error('Failed to post job:', error);
        }

    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                    '& .MuiStepLabel-root': {
                        color: '#7c7c7c',
                    },
                    '& .MuiStepIcon-root': {
                        color: '#7c7c7c',
                    },
                    '& .Mui-active .MuiStepIcon-root': {
                        color: '#2196F3',
                    },
                    '& .Mui-completed .MuiStepIcon-root': {
                        color: '#2196F3',
                    },
                    '& .Mui-active .MuiStepLabel-label': {
                        color: '#2196F3',
                    },
                    '& .Mui-completed .MuiStepLabel-label': {
                        color: '#2196F3',
                    },
                }}
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ p: 3 }}>
                {activeStep === 0 && <JobDetailsForm jobDetails={jobDetails} setJobDetails={setJobDetails} setErrorsJobDetails={setErrorsJobDetails} errorsJobDetails={errorsJobDetails} />}

                {activeStep === 1 && <CandidateRequirement setCandidateRequirement={setCandidateRequirement} candidateRequirement={candidateRequirement}
                    errorsCandidateReq={errorsCandidateReq} setErrorsCandidateReq={setErrorsCandidateReq}
                />}

                {activeStep === 2 && <CompanyDetails companyDetails={companyDetails} setCompanyDetails={setCompanyDetails} errorsCompanyDetails={errorsCompanyDetails} setErrorsCompanyDetails={setErrorsCompanyDetails} />}

                {isModalOpen ? (
                    <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '90%', sm: 400 },
                            maxWidth: '90%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: { xs: 2, sm: 4 },
                        }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CloseIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleCloseModal}
                                />
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <img
                                    src="your-success-image-url"
                                    alt="Success"
                                    style={{ width: '50px', height: '50px' }}
                                />
                                <Typography
                                    id="modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{ mt: 2 }}
                                >
                                    Your Job has been posted successfully
                                </Typography>
                                <Typography
                                    id="modal-description" z
                                    sx={{ mt: 1 }}
                                >
                                    Your job will go live within 5 minutes
                                </Typography>
                                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: '#2196f3', color: '#fff', '&:hover': { backgroundColor: '#1976d2' }, textTransform: 'none' }}
                                    >
                                        Edit Job
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        sx={{ borderColor: '#2196f3', color: '#2196f3', '&:hover': { borderColor: '#1976d2', color: '#1976d2' }, textTransform: 'none' }}
                                    >
                                        <Link to="/main/advDasboared" style={{ color: 'none', textDecoration: 'none' }}> See Posted Jobs
                                        </Link>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {activeStep >= 0 && activeStep !== steps.length - 1 &&
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{
                                    mt: 3, mb: 2, borderColor: '#2196f3',
                                    color: '#2196f3',
                                    '&:hover': {
                                        backgroundColor: 'rgba(33, 150, 243, 0.1)',
                                        borderColor: '#2196f3',
                                    },
                                }}
                                variant="outlined"
                            >
                                Back
                            </Button>
                        }
                        {activeStep === steps.length - 1 &&
                            <Button
                                onClick={() => handleSubmit('draft')}
                                sx={{
                                    mt: 3, mb: 2, borderColor: '#2196f3',
                                    color: '#2196f3',
                                    '&:hover': {
                                        backgroundColor: 'rgba(33, 150, 243, 0.1)',
                                        borderColor: '#2196f3',
                                    },
                                }}
                                variant="outlined"
                            >
                                Save as Draft
                            </Button>
                        }
                        <Button
                            onClick={handleNext}
                            sx={{
                                mt: 3, mb: 2, backgroundColor: '#2196f3',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#1976d2',
                                },
                            }}
                            variant="contained"
                        >
                            {activeStep === steps.length - 1 ? 'Confirm & Post the Job' : 'Continue'}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default JobPosting;
