import React, { useState, useEffect,useContext,useRef } from 'react';
import { DataContext } from '../Admin/DataContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { PhoneCall, PhoneDisconnect } from "@phosphor-icons/react";


// Define styles as a function to handle dynamic updates
const useResponsiveStyles = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const containerStyle = {
    backdropFilter: 'blur(16px) saturate(180%)',
    WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    backgroundColor: '#1877f2',  // Set the background color
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.125)',
    padding: '38px',
    filter: 'drop-shadow(0 30px 10px rgba(0, 0, 0, 0.125))',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: isMobile ? '100%' : '28%',
    position: 'absolute',  // Position absolutely
    right: 0,
    zIndex: 3 
    // Align to the right of the parent container
  };

  const wrapperStyle = {
    width: '100%',
    height: '100%',
  };

  const bannerImageStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '300px',
    width: '100%',
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.255)',
  };

  const h1Style = {
    fontFamily: "'Righteous', sans-serif",
    color: 'rgba(255, 255, 255, 0.98)',
    textTransform: 'uppercase',
    fontSize: '15px',
    marginTop:'10px'
  };

  const pStyle = {
    color: '#fff',
    fontFamily: "'Lato', sans-serif",
    textAlign: 'center',
    fontSize: '0.8rem',
    lineHeight: '150%',
    letterSpacing: '2px',
    textTransform: 'uppercase',
  };

  const buttonWrapperStyle = {
    marginTop: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  };

  const btnStyle = {
    border: 'none',
    padding: '12px 24px',
    borderRadius: '24px',
    fontSize: '0.8rem',
    letterSpacing: '2px',
    cursor: 'pointer',
    flex: '1',
    margin: '0 10px',
  };

  const acceptStyle = {
    ...btnStyle,
    background: 'rgba(0, 255, 127, 0.9)',
    color: 'rgba(255, 255, 255, 0.95)',
    filter: 'drop-shadow(0)',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
  };

  const declineStyle = {
    ...btnStyle,
    background: 'rgba(255, 0, 0, 0.9)',
    color: 'rgba(255, 255, 255, 0.95)',
    filter: 'drop-shadow(0)',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
  };

  const acceptHoverStyle = {
    transform: 'scale(1.125)',
    filter: 'drop-shadow(0 10px 5px rgba(0, 255, 127, 0.5))',
    transition: 'all 0.3s ease',
  };

  const declineHoverStyle = {
    transform: 'scale(1.125)',
    filter: 'drop-shadow(0 10px 5px rgba(255, 0, 0, 0.5))',
    transition: 'all 0.3s ease',
  };

  return {
    containerStyle,
    wrapperStyle,
    bannerImageStyle,
    h1Style,
    pStyle,
    buttonWrapperStyle,
    acceptStyle,
    declineStyle,
    acceptHoverStyle,
    declineHoverStyle,
  };
};

const CallScreen = ({ callerName, setOnVideoCall,CallChatmasterid }) => {
  
  const { setVideoPage,handleSendCallmessage} = useContext(DataContext);
  const authdata = useSelector((state) => state.auth.user);

  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_VIDEOAPI);
    socketRef.current.emit('join-room', CallChatmasterid.data.chatdata,"","","","","","");
    socketRef.current.on('connect', () => {
      console.log('Connected to server');
    });

    socketRef.current.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  const navigate = useNavigate();
  const styles = useResponsiveStyles();
  
    console.log('DataContext',CallChatmasterid)

  const handleCallAttan = ()=>{
    navigate('/videocall', { state: { chatdata: CallChatmasterid.data.chatdata,receivercallid:CallChatmasterid.data.receivercallid.replace(/'/g, ''),callerfrom:0,callername:CallChatmasterid.data.callername,call_status:"call_attan" } })
    setVideoPage(true);
    setOnVideoCall(false);
  }

  const handleDecliane = async() => {
   setOnVideoCall(false);
    // handleSendCallmessage(authdata?.id,CallChatmasterid.data.receivercallid.replace(/'/g, ''),"No Answer",CallChatmasterid.data.chatdata,"callRequest");
    socketRef.current.emit('join-room', CallChatmasterid.data.chatdata,"","","","","","decline");

  }

  return (
    <div
    style={{
      backgroundColor: "#4A4A4A",
      width: "280px",
      height: "310px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "32px 20px",
      borderRadius: "10px",
      position:"absolute",
      zIndex:"9999",
      top:"0",
      right:"0",
    }}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
      }}>
      <div
        style={{
          backgroundColor: "white",
          height: "80px",
          width: "80px",
          borderRadius: "50%",
          overflow: "hidden",
        }}>
        <img
          style={{
            height: "80px",
            width: "80px",
          }}
          src={'https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=2048x2048&w=is&k=20&c=d1b4VHqWm1Gt8V148JOvaYSnyIvsFZEpGRCxLK-hGU4='}
          alt=""
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <h6 style={{ color: "#FFFFFF", fontSize: "16px" }}>Mukesh Kanna</h6>
        <p style={{ color: "#BBB9B9", fontSize: "14px" }}>Video Call</p>
      </div>
    </div>

    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        gap: "16px",
      }}>
      <button
      onClick={handleDecliane}
        className="callActionBtnAccept"
        style={{
          border: "none",
          outline: "none",
          backgroundColor: "#FF3B3C",
          borderRadius: "10px",
          padding: "10px 18px",
          color: "white",
          fontSize: "14px",
        }}>
        <div>
          <PhoneDisconnect size={20} style={{ marginRight: "5px" }} />{" "}
          Decline
        </div>
      </button>
      <button
      onClick={handleCallAttan}
        className="callActionBtnDecline"
        style={{
          border: "none",
          outline: "none",
          backgroundColor: "#07C271",
          borderRadius: "10px",
          padding: "10px 18px",
          color: "white",
          fontSize: "14px",
        }}>
        <div>
          <PhoneCall size={20} style={{ marginRight: "5px" }} /> Accept
        </div>
      </button>
    </div>
  </div>

  );
};

export default CallScreen;
