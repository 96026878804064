import React from "react";
import call from "../assets/images/amico.png";
import Grid from "@mui/material/Grid";
import "./demo.css";

const ContactUs = () => {
  return (
    <div>
      <div className="card" style={{padding:"137px 30px",marginTop:"1%"}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <img src={call} alt="Customer Care" className="img-fluid" style={{ height: "451px" }} loading="lazy" />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="" style={{ marginLeft: "-158px" }}>
              <h3 className="text-center mt-md-0 mt-3" style={{ color: "#2196F3" }} >Contact Us</h3>
              <div className="ms-md-5 mt-md-5 contact-us">
                <p
                  className=""
                  style={{ fontSize: "1.5rem", marginTop: "30px", color: "#2196F3" }}
                >
                  Customer Care
                </p>
                <p style={{ fontSize: "16px" }}>
                  CustomerCare@kodukku.com
                </p>
                <p style={{ fontSize: "1.5rem", marginTop: "30px", color: "#2196F3" }}>
                  Address:
                </p>
                <p className="" style={{ fontSize: "16px" }}>
                  253 Thanthai Periyar Nagar,
                  <br />
                  Pallikaranai,
                  <br /> Chennai,
                  <br /> Tamil Nadu - 600100
                </p>
                <p style={{ fontSize: "1.5rem", marginTop: "30px", color: "#2196F3" }}>Email</p>
                <p className="" style={{ fontSize: "16px" }}>
                  Hr@kodukku.com
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ContactUs;
