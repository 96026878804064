import React from "react";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import { Box } from "@mui/material";
import { Assetsapi } from "../../config/serverUrl";
function ProductCard({ cur, handleOverview, theme, activeStep }) {
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  const baseUrl = `${Assetsapi}/adimages/`;
  return (
    <div
      key={cur.id}
      style={{ cursor: "pointer", overflow: "hidden" }}
      className="movie-post-card-design"
      onClick={() => handleOverview(cur.id)}
    >
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        className="cine-post-swipe"
      >
        {cur.img_url_array && cur.img_url_array.length > 0 ? (
          cur.img_url_array.map((img, ind) => (
            <div
              key={ind}
              style={{
                background: "#e6f2fc",
                height: "199.85px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {Math.abs(activeStep - ind) <= 10 ? (
                <Box
                  component="img"
                  sx={{
                    width: "234.51px",
                    height: "172.82px",
                    display: "block",
                    borderRadius: 2,
                  }}
                  src={`${baseUrl}${img}`}
                  alt={`Item ${ind + 1}`}
                />
              ) : null}
            </div>
          ))
        ) : (
          <div
            style={{
              background: "#e6f2fc",
              height: "199.85px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "300px",
              boxSizing: "border-box",
            }}
          >
            <p
              style={{
                marginRight: "30px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              No Image Available
            </p>
          </div>
        )}
      </AutoPlaySwipeableViews>
      <div className="movie-post-card-design-content">
        <p style={{ color: "#2824DF", fontWeight: "500" }}>{cur.title}</p>
        <p className="movie-post-card-design-content-director">
          <span className="movie-post-card-design-content-director-key">
            {" "}
            Director :
          </span>
          <span className="movie-post-card-design-content-director-value">
            {cur.director}
          </span>
        </p>

        <p className="movie-post-card-design-content-director">
          <span className="movie-post-card-design-content-director-key">
            {" "}
            Release Date :
          </span>
          <span className="movie-post-card-design-content-director-value">
            {cur.release_date}
          </span>
        </p>

        <p className="movie-post-card-design-content-director">
          <span className="movie-post-card-design-content-director-key">
            {" "}
            Genres :
          </span>
          {cur.genre
            .replace(/^"|"$/g, "")
            .split(",")
            .map((val, idx) => (
              <span
                className="movie-post-card-design-content-director-value"
                key={idx}
                style={{ marginLeft: "3px" }}
              >
                {val.trim()},
              </span>
            ))}
        </p>

        <div className="movie-post-card-design-content-censor">
          <p className="movie-post-card-design-content-director-key-censor">
            {cur.movie_certificate}
          </p>
          <span className="movie-post-card-design-content-censor-eclipse"></span>
          <p>
            {cur.language
              .replace(/^"|"$/g, "")
              .split(",")
              .map((val, idx) => (
                <span
                  className="movie-post-card-design-content-director-value"
                  key={idx}
                  style={{ marginLeft: "3px" }}
                >
                  {val.trim()},
                </span>
              ))}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
