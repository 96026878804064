import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Nodeapi, Assetsapi } from "../../config/serverUrl";
import moment from "moment/moment";
import Swal from "sweetalert2";
import axios from "axios";
import { MdDriveFolderUpload, MdRemoveCircleOutline } from "react-icons/md";
import "./cinepost.css";
import ProductCard from "./ProductCard.js";
import CineModal from "./CineModal.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CinePost() {
  const [documentation, setDocumentation] = useState({
    title: "",
    trailerUrl: "",
    director: "",
    musicDirector: "",
    writer: "",
    producer: "",
    productionCompany: "",
    performingArtist: "",
    synopsis: "",
    place: "",
    theatre: "",
  });
  const [releaseDate, setReleaseDate] = useState(null);
  const [genre, setGenre] = useState([]);
  const [stream, setStream] = useState([]);
  const [language, setLanguage] = useState([]);
  const navigate = useNavigate();
  const baseUrl = `${Assetsapi}/adimages/`;
  const [open, setOpen] = useState(false);
  const authdata = useSelector((state) => state.auth.user);
  const userId = authdata?.id;
  const [products, setProducts] = useState([]);

  const [fetchImage, setFetchImage] = useState([]);

  const [time, setTime] = useState(null);

  const [buttonvalue, setButtonValu] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const handleOpenform = () => {
    setIsEdit(false);
    setOpen(true);
    setButtonValu("Submit");
  };

  const theme = useTheme();

  const handleChangeGenre = (event) => {
    const {
      target: { value },
    } = event;
    setGenre(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeStream = (event) => {
    const {
      target: { value },
    } = event;
    setStream(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeLanguage = (event) => {
    const {
      target: { value },
    } = event;
    setLanguage(typeof value === "string" ? value.split(",") : value);
  };

  const handleClose = () => {
    setDocumentation({
      title: "",
      trailerUrl: "",
      director: "",
      musicDirector: "",
      writer: "",
      certificate: "",
      producer: "",
      productionCompany: "",
      performingArtist: "",
      synopsis: "",
      place: "",
      theatre: "",
    });
    setReleaseDate([]);
    setOpen(false);
    setTime("");
    setGenre([]);
    setStream([]);
    setLanguage([]);
    setUploadedImages([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocumentation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    setReleaseDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.value);
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    const res = await axios.get(`${Nodeapi}/cinePost/getAllData/${userId}`);
    const allData = res.data?.data.data;
    const allImageData = res.data?.data.allImages;

    const imageUrlMap = new Map(
      allImageData.map((item) => [Number(item.cine_post_id), item.image_url])
    );

    allData.map((user) => {
      if (imageUrlMap.has(user.id)) {
        user.image_url = imageUrlMap.get(user.id);
      }
    });

    const addImageUrl = addImageUrlFunc(allData, allImageData);

    setProducts(addImageUrl ? addImageUrl : []);
    setFetchImage(allImageData ? allImageData : []);
  };

  const addImageUrlFunc = (arr, val) => {
    var imgUrlMap = {};
    val.forEach((item) => {
      if (!imgUrlMap[item.cine_post_id]) {
        imgUrlMap[item.cine_post_id] = [];
      }
      imgUrlMap[item.cine_post_id].push(item.image_url);
    });

    arr.forEach((item) => {
      if (imgUrlMap[item.id]) {
        item.img_url_array = imgUrlMap[item.id];
      }
    });

    return arr;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const action = event.target.value;
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (action === "Submit") {
      const isEmpty = Object.values(documentation).reduce((acc, cur) => {
        if (cur == "") {
          ++acc;
        }
        return acc;
      }, 0);
      if (isEmpty > 5) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Fill Out All Required Fields",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsSubmitting(false);
        return setOpen(true);
      } else {
        const formattedDate = moment(releaseDate).format("DD-MM-YYYY");
        const date = new Date(time);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        const genreArray = genre.join(",");
        const streamArray = stream.join(",");
        const languageArray = language.join(",");
        const formData = new FormData();
        // Append images
        [...uploadedImages, ...additionalImages].forEach((image) => {
          formData.append("images", image.filePath); // Adjust if `filePath` needs to be an actual file object
        });
        // Append other fields
        formData.append("userid", userId);
        formData.append("title", documentation.title);
        formData.append("release_date", formattedDate);
        formData.append("genre", JSON.stringify(genreArray)); // Convert arrays to JSON string if required by backend
        formData.append("trailer_url", documentation.trailerUrl);
        formData.append("director", documentation.director);
        formData.append("music_director", documentation.musicDirector);
        formData.append("writer", documentation.writer);
        formData.append("movie_certificate", documentation.certificate);
        formData.append("producer", documentation.producer);
        formData.append("production_company", documentation.productionCompany);
        formData.append("streaming_platform", JSON.stringify(streamArray));
        formData.append("run_time", formattedTime);
        formData.append("language", JSON.stringify(languageArray));
        formData.append("performing_artist", documentation.performingArtist);
        formData.append("movie_description", documentation.synopsis);
        formData.append("place", documentation.place);
        formData.append("theatre", documentation.theatre);
        // Post request

        const res = await axios.post(
          `${Nodeapi}/cinePost/createData`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Important for file uploads
            },
          }
        );

        if (res.data) {
          if (res.data.code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Movie added Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            handleClose();
            setDocumentation("");
            setIsSubmitting(false);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Error 404",
              showConfirmButton: false,
              timer: 2000,
            });
            handleClose();
          }
        }
      }
    }
    fetchdata();
    handleClose();
    setIsSubmitting(false);
  };

  const [additionalImages, setAdditionalImages] = useState([]);
  const [listimages, setlistimages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleRemove = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      filePath: file,
      documentName: file.name,
    }));
    setUploadedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleAddMoreImage = (event) => {
    const files = Array.from(event.target.files);
    const newupdateImages = files.map((file) => ({
      filePath: file,
      documentName: file.name,
    }));
    setAdditionalImages([...additionalImages, ...newupdateImages]);
  };

  const handleuploadRemove = async (index, fileName) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        setlistimages((prevImages) => prevImages.filter((_, i) => i !== index));

        await axios.post(`${Nodeapi}/cinePost/deleteData/`, {
          file_name: fileName,
        });
      }
    } catch (error) {
      console.error("Error deleting image from backend:", error);
    }
  };

  const handleOverview = (id) => {
    navigate(`/main/cinePost/view/${id}`);
  };

  const imageUploadSection = (
    <>
      <div className="col-12 mt-md-0">
        <div className="d-flex flex-wrap">
          {uploadedImages.map((image, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <img
                src={URL.createObjectURL(image.filePath)}
                alt={image.documentName}
                style={{
                  width: "126px",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemove(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  minWidth: 0,
                  padding: "5px",
                }}
              >
                <MdRemoveCircleOutline />
              </Button>
            </div>
          ))}
          <label
            htmlFor="upload-photo"
            className="upload-card p-0"
            style={{
              border: "1px dashed #68bcff",
              borderRadius: "10px",
              background: "#eff5fc",
              width: "7rem",
              height: "6rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "-7px",
              cursor: "pointer",
            }}
          >
            <input
              hidden
              multiple
              accept="image/*"
              type="file"
              id="upload-photo"
              onChange={handleUpload}
            />
            <div
              className="icon"
              style={{
                fontSize: "1.5rem",
                marginRight: "0.5rem",
                color: "#2196f3",
              }}
            >
              <MdDriveFolderUpload />
            </div>
            <div className="overlay">
              <p style={{ color: "#2196f3" }}>Add</p>
            </div>
          </label>
        </div>
      </div>
    </>
  );

  const addmorimage = (
    <>
      <Button component="label" sx={{ height: 60 }}>
        <MdDriveFolderUpload
          style={{
            fontSize: "10px",
            marginRight: "0.5rem",
            border: "1px dashed #68bcff",
            borderRadius: "10px",
            background: "#eff5fc",
            width: "4rem",
            height: "3rem",
            marginLeft: "-425px",
            cursor: "pointer",
          }}
        />

        <input
          hidden
          multiple
          accept="image/*"
          type="file"
          onChange={handleAddMoreImage}
        />
      </Button>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        {additionalImages.map((image, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            <img
              src={URL.createObjectURL(image.filePath)}
              alt={image.documentName}
              style={{
                width: "126px",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemove(index)}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                minWidth: 0,
                padding: "5px",
              }}
            >
              <MdRemoveCircleOutline />
            </Button>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div className="cinePost-main-page">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <h1 className="my-3 recentpost">Movie Post</h1>
        <div style={{ marginTop: "10px" }}>
          <Button variant="contained" onClick={handleOpenform}>
            + Add Movie Post
          </Button>
        </div>
      </div>
      <div style={{ background: "red" }}>
        <CineModal
          open={open}
          handleClose={handleClose}
          documentation={documentation}
          isEdit={isEdit}
          handleChange={handleChange}
          releaseDate={releaseDate}
          handleDateChange={handleDateChange}
          genre={genre}
          handleChangeGenre={handleChangeGenre}
          MenuProps={MenuProps}
          getStyles={getStyles}
          theme={theme}
          stream={stream}
          handleChangeStream={handleChangeStream}
          time={time}
          handleTimeChange={handleTimeChange}
          language={language}
          handleChangeLanguage={handleChangeLanguage}
          imageUploadSection={imageUploadSection}
          addmorimage={addmorimage}
          listimages={listimages}
          additionalImages={additionalImages}
          handleuploadRemove={handleuploadRemove}
          handleSubmit={handleSubmit}
          buttonvalue={buttonvalue}
          isSubmitting={isSubmitting}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            background: "white",
            flexWrap: "wrap",
            marginLeft: "-10px",
          }}
        >
          {products?.map((cur) => (
            <ProductCard
              cur={cur}
              handleOverview={handleOverview}
              theme={theme}
              activeStep={activeStep}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CinePost;
