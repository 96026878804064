import React, { useState, useRef } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Envelope } from "@phosphor-icons/react";
import SendOtpMobile from "./SendOtpMobile";
import axios from "axios";
import { authapi } from "../../config/serverUrl";
import { Toast } from "primereact/toast";
import { IoMdCheckmarkCircle } from "react-icons/io";
import "./loader.css";
import MobileNumberTimer from "../../utils/MobileTimer";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const SendOtpPhone = ({ email, setMobile, mobile, MobileTimeHider, setformikMobileErrors, setTimerStatem, disableEmailOtp, setDisableMobileOtp, ResendLoaderMObile }) => {
  const [showhider, setShowhider] = useState(null);
  const [showResendButtoon, setshowResendButtoon] = useState(null);
  const toast = useRef(null);
  const [afterRes, setafterRes] = useState(0);
  const [hasLoader, setHasLoader] = useState(false);
  const initialValues = {
    mobileNumber: "",
  };
  
  const schema = yup.object().shape({
    mobileNumber: yup
      .string()
      .required("Mobile number is required")
      .test(
        'is-valid-phone',
        'Invalid mobile number',
        value => value && value.replace(/\D-/g, '').length >= 10
      ),
  });


  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('afterRes:',afterRes);
    if (afterRes === 0) {
      setHasLoader(true);
      const res = await axios.post(`${authapi}/auth/update_otp`, {
        email: email,
        mobile: values.mobileNumber,
      });
       const data = res.data
      if (data) {
        if (res.data.code === "200") {
          let val = values.mobileNumber
          setMobile(val);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.data.message,
            life: 3000,
          });
          setDisableMobileOtp(false);
          setTimerStatem(true);
          setshowResendButtoon(true);
          setHasLoader(false);
          setafterRes(1);
        } else {
          setHasLoader(false);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.data.data.message,
            life: 3000,
          });
        }
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, setFieldValue }) => {
            setformikMobileErrors(errors); // Update formikErrors state with the current errors
            return (
              <>
                <Form>
                  <div className="input-group reset-password d-flex">
                   
                    <PhoneInput
                      country={"in"}
                      name="mobileNumber"
                      placeholder="Mobile Number"
                      inputProps={{
                        name: "mobileNumber",
                        required: true,
                        autoFocus: true,
                      }}
                      inputClass={`form-control${
                        errors.mobileNumber ? " is-invalid" : ""
                      }`}
                      onChange={(value) => setFieldValue("mobileNumber", value)}
                      specialLabel=""
                      {...(afterRes === 1 ? { readOnly: true } : {})}
                    />
                    {!hasLoader ? (
                      <button
                       
                        className="btn btn-verify get-otp"
                        type="submit"
                        style={{
                          backgroundColor: "#2196f3",
                          color: "white",
                          width: "113px",
                        }}
                      >
                        {afterRes === 0 ? (
                          <span>GET OTP</span>
                        ) : ResendLoaderMObile ? (
                          <span className="loader"></span>
                        ) : (
                          <IoMdCheckmarkCircle
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                      </button>
                    ) : (
                      <button
                        className="btn btn-verify"
                        type="submit"
                        style={{
                          backgroundColor: "#2196f3",
                          color: "white",
                          width: "113px",
                          height: "43px",
                        }}
                      >
                        <span className="loader"></span>
                      </button>
                    )}
                  </div>
                  <div className="d-md-none d-block">
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="mobileNumber"
                      component="div"
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default SendOtpPhone;


