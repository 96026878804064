import React, { useState } from "react";
import { useEffect } from "react";
import {
  UpdateDetails,
  AddDetails,
  FetchDetails,
} from "../../routes/profileRoutes";
import Jobusestates from "../../useStates/JobUsestate";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import { MdAdd } from "react-icons/md";
import { useMediaQuery } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import "./Relationship.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import Swal from 'sweetalert2';
const PropertyDetailFormPage = () => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const authdata = useSelector((state) => state.auth.user);
  const toast = useRef(null);

  useEffect(() => {
    fetchPropertyDetails();
  }, []);

  const fetchPropertyDetails = async () => {
    const PropertyDetails = await axios.post(`${Nodeapi}/FetchLoopDetails`, {
      id: authdata?.id,
      table: "PropertyDetails",
    });

    if (PropertyDetails) {
      setPropertyDetails(PropertyDetails.data.data.response);
    }
  };

  const [Property, setProperty] = React.useState(false);
  const handlePropertyOpen = () => setProperty(true);
  
  const handlePropertyClose = () => setProperty(false);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [commercialType, setCommercialType] = useState("");
  const [residentialType, setResidentialType] = useState("");
  const [bhkType, setBhkType] = useState("");
 
  const [agriculturalType, setAgriculturalType] = useState("");
  const [industrialType, setIndustrialType] = useState("");
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [sample,setsample]= useState("")

  const [propert_area,setpropert_area]= useState("Nil")
  const [landsq,setlandsq]= useState("Nil")

  const [isSubmitting, setIsSubmitting] = useState(false); //to handle multiple form submission 

  const {
    PropertyUpdateButton,
    setPropertyUpdateButton,
    PropertyDetailsForm,
    setPropertyDetailsForm,
    PropertyDetails,
    setPropertyDetails,
  } = Jobusestates();


 
  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);

  const edit_propertydetails = async (e, data_params) => {
    e.preventDefault();
    setPropertyUpdateButton("Update");
    setPropertyDetailsForm(data_params.row);
  };

  const handleaddpropertyDetails = (e) => {
    e.preventDefault();
    setPropertyTypes([]);
    setCommercialType("");
    setResidentialType("");
    setBhkType("");
    setAgriculturalType("");
    setIndustrialType("");
    setPropertyUpdateButton("Add");
    setPropertyDetailsForm("");
    
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "0px",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
  };

  const handleProperty = async (e) => {
    e.preventDefault();

    if(isSubmitting) return;  //Prevent multiple form submissions
    setIsSubmitting(true);

    const action = e.nativeEvent.submitter.value;
    
    if (action.trim() === "update") {
      const json_data = {
        formdata: PropertyDetailsForm,
        table: "PropertyDetails",
        id: PropertyDetailsForm.id,
        userid: PropertyDetailsForm.userid,
        message: "Property Details Updated Successfully...!",
      };

      try {
        const updateDetailsResult = await axios.post(
          `${Nodeapi}/updateHAD`,
          json_data
        );

        if (updateDetailsResult) {
          setProperty(false);
          setPropertyDetails(updateDetailsResult.data.data.response);
          Swal.fire({
         
            position: "center",
            icon: "success",
            title: 'Updated Successfully',
            showConfirmButton: false,
            timer: 2000
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Error 404',
            showConfirmButton: false,
            timer: 2000
          });
          setProperty(false);

        }
      } catch (error) {
        console.error("Error updating details:", error);
      }
    } else {

      const isEmpty = Object.keys(PropertyDetailsForm).every(key => key === "area" || PropertyDetailsForm[key] === "");

      if(isEmpty){
        Swal.fire({
          position: "center",
          icon: "error",
          title: 'Please Fill Out All Required Fields',
          showConfirmButton: false,
          timer: 2000
        });
        return setProperty(false);

        
      } else{
       
        const json_data = {
          
          insertdata: PropertyDetailsForm,
          table: "PropertyDetails",
          insertMessage: "Property Details Inserted Successfully",
        };
  
        try {
          const addDetailsResult = await AddDetails(json_data);
  
          if (addDetailsResult) {
            setProperty(false);
            setPropertyDetails(addDetailsResult.data.data.response);
            Swal.fire({
           
              position: "center",
              icon: "success",
              title: 'Property Details Inserted Successfully',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: 'Error 404',
              showConfirmButton: false,
              timer: 2000
            });
            setProperty(false);

          }
        } catch (error) {
          console.error("Error adding details:", error);
        }
      }
      }
      setIsSubmitting(false);
    };

  const handlePropertyDetails = (e) => {
    const { name, value } = e.target;
    setPropertyDetailsForm({
      ...PropertyDetailsForm,
      [name]: value,
      userid: authdata?.id,
      property_types_option: propertyTypes[0],
    
    });
  };

  const handlePropertyTypeChange = (propertyType) => {
    if (propertyType === "commercial") {
      setCommercialType("");
      setResidentialType("");
      setBhkType("");
      setAgriculturalType("");
      setIndustrialType("");
    } else if (propertyType === "house") {
      setCommercialType("");
      setAgriculturalType("");
      setIndustrialType("");
    } else if (propertyType === "agricultural") {
      setCommercialType("");
      setResidentialType("");
      setBhkType("");
      setIndustrialType("");
    } else if (propertyType === "industrial") {
      setCommercialType("");
      setResidentialType("");
      setBhkType("");
      setAgriculturalType("");
    }

    const updatedPropertyTypes = [propertyType];
    setPropertyTypes(updatedPropertyTypes);
  };



  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelectedJobDetail(rowData);
      setsample(rowData)
      menuLeft.current.toggle(event);
    };
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

   
  useEffect(() => {
    if(PropertyDetails){
      setSerialNumbers(
        Array.from({ length: PropertyDetails.length }, (_, index) => index + 1)
      );

    }
    
  }, [PropertyDetails]);
  

  const menuLeft = useRef(null);
  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selectedJobDetail),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selectedJobDetail),
    },
  ];

  const handleEdit = (rowData)=>{
  
     handlePropertyOpen();
     const propertyType = rowData.property_types_option;
   handlePropertyTypeChange(propertyType);
     setPropertyDetailsForm(rowData);
     setPropertyUpdateButton("Update");
   }

   const deleteContactData = async(id)=>{
    try {
      await axios.delete(`${Nodeapi}//deletePropertyData/${id}`);
      fetchPropertyDetails();
     
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
   }
  
const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
   
    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData.id);
        Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success'
        });
      
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  }

   const handelpropertarea =()=>{

      setPropertyDetailsForm({
        area:"sq.Ft",
       
      })
    }

  return (
    <div>
      <Toast ref={toast} />
      <div className=" mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Property Details</h5>
          <p>
            <Button
              onClick={(e) => {
                handlePropertyOpen();
                handleaddpropertyDetails(e);
                handelpropertarea()
              }}
            >
              <MdAdd size={22} />
            </Button>
            <Modal
              open={Property}
              onClose={handlePropertyClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={style}
                style={{
                  width: isSmallScreen ? "95%" : "",
                }}
              >
                <form onSubmit={handleProperty}>
                  <div className="row">
                    <h6 className="labels mb-2">Property Details</h6>
                    
                    {PropertyUpdateButton == "Add" ? (
                      <div className="col-md-6 my-2Feduca">
                        <label className="pb-2">Property Types</label>
                        <div>
                          <input
                            type="checkbox"
                            value="commercial"
                            checked={propertyTypes.includes("commercial")}
                            onChange={() =>
                              handlePropertyTypeChange("commercial")
                            }
                          />
                          <label className="px-2">Commercial</label>

                          {propertyTypes.includes("commercial") && (
                            <select
                              className="form-control"
                              name="property_types"
                              id="property_types"
                              onChange={handlePropertyDetails}
                            >
                              <option value="">Select Commercial Type</option>
                              <option value="hotel">Hotel</option>
                              <option value="officeSpace">Office Space</option>
                              <option value="warehouse">Warehouse</option>
                              <option value="showroom">Showroom</option>
                            </select>
                          )}
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            value="house"
                            checked={propertyTypes.includes("house")}
                            onChange={() => handlePropertyTypeChange("house")}
                          />
                          <label className="px-2">Residential</label>
                          {propertyTypes.includes("house") && (
                            <div>
                              <select
                                className="form-control"
                                name="property_types"
                                id="property_types"
                                onChange={handlePropertyDetails}
                              >
                                <option value="">
                                  Select Residential Type
                                </option>
                                <option value="villa">Villa</option>
                                <option value="IndependentHouse">
                                  Independent House
                                </option>
                                <option value="residentialPlot">
                                  Residential Plot
                                </option>
                                <option value="FarmHouse">Farm House</option>
                                <option value="Bungalow">Bungalow</option>
                                <option value="PayingGuest">
                                  Paying Guest
                                </option>
                                <option value="rowhouse">Rowhouse</option>
                              </select>
                            </div>
                          )}
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            value="agricultural"
                            checked={propertyTypes.includes("agricultural")}
                            onChange={() =>
                              handlePropertyTypeChange("agricultural")
                            }
                          />
                          <label className="px-2">Agricultural</label>
                          {propertyTypes.includes("agricultural") && (
                            <select
                              className="form-control"
                              name="property_types"
                              id="property_types"
                              onChange={handlePropertyDetails}
                            >
                              <option value="">Select Agricultural Type</option>
                              <option value="FarmLand">Farm Land</option>
                              <option value="Plantation">Plantation</option>
                              <option value="Orchard">Orchard</option>
                              <option value="AgriculturalLand">
                                Agricultural Land
                              </option>
                            </select>
                          )}
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            value="industrial"
                            checked={propertyTypes.includes("industrial")}
                            onChange={() =>
                              handlePropertyTypeChange("industrial")
                            }
                          />
                          <label className="px-2">Industrial</label>
                          {propertyTypes.includes("industrial") && (
                            <select
                              className="form-control"
                              name="property_types"
                              id="property_types"
                              onChange={handlePropertyDetails}
                            >
                              <option value="">Select Industrial Type</option>
                              <option value="factory">Factory</option>
                              <option value="warehouse">Warehouse</option>
                              <option value="industrialLand">
                                Industrial Land
                              </option>
                              <option value="manufacturingPlant">
                                Manufacturing Plant
                              </option>
                            </select>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-6 my-2Feduca">
                      <label className="pb-2">Property Types</label>
                      <div>
                        <input
                          type="checkbox"
                          value="commercial"
                          checked={propertyTypes.includes("commercial")}
                          onChange={() =>
                            handlePropertyTypeChange("commercial")
                          }
                        />
                        <label className="px-2">Commercial</label>

                        {propertyTypes.includes("commercial") && (
                          <select
                            className="form-control"
                            name="property_types"
                            id="property_types"
                            onChange={handlePropertyDetails}
                            value={PropertyDetailsForm?.property_types}
                          >
                            <option value="">Select Commercial Type</option>
                            <option value="hotel">Hotel</option>
                            <option value="officeSpace">Office Space</option>
                            <option value="warehouse">Warehouse</option>
                            <option value="showroom">Showroom</option>
                          </select>
                        )}
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          value="house"
                          checked={propertyTypes.includes("house")}
                          onChange={() => handlePropertyTypeChange("house")}
                        />
                        <label className="px-2">Residential</label>
                        {propertyTypes.includes("house") && (
                          <div>
                            <select
                              className="form-control"
                              name="property_types"
                              id="property_types"
                              onChange={handlePropertyDetails}
                               value={PropertyDetailsForm?.property_types}
                            >
                              <option value="">
                                Select Residential Type
                              </option>
                              <option value="villa">Villa</option>
                              <option value="IndependentHouse">
                                Independent House
                              </option>
                              <option value="residentialPlot">
                                Residential Plot
                              </option>
                              <option value="FarmHouse">Farm House</option>
                              <option value="Bungalow">Bungalow</option>
                              <option value="PayingGuest">
                                Paying Guest
                              </option>
                              <option value="rowhouse">Rowhouse</option>
                            </select>
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          value="agricultural"
                          checked={propertyTypes.includes("agricultural")}
                          onChange={() =>
                            handlePropertyTypeChange("agricultural")
                          }
                        />
                        <label className="px-2">Agricultural</label>
                        {propertyTypes.includes("agricultural") && (
                          <select
                            className="form-control"
                            name="property_types"
                            id="property_types"
                            onChange={handlePropertyDetails}
                            value={PropertyDetailsForm?.property_types}
                          >
                            <option value="">Select Agricultural Type</option>
                            <option value="FarmLand">Farm Land</option>
                            <option value="Plantation">Plantation</option>
                            <option value="Orchard">Orchard</option>
                            <option value="AgriculturalLand">
                              Agricultural Land
                            </option>
                          </select>
                        )}
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="industrial"
                         
                          checked={propertyTypes.includes("industrial")}
                          onChange={() =>
                            handlePropertyTypeChange("industrial")
                          }
                        />
                        <label className="px-2">Industrial</label>
                        {propertyTypes.includes("industrial") && (
                          <select
                            className="form-control"
                            name="property_types"
                            id="property_types"
                            onChange={handlePropertyDetails}
                            value={PropertyDetailsForm?.property_types}
                          >
                            <option value="">Select Industrial Type</option>
                            <option value="factory">Factory</option>
                            <option value="warehouse">Warehouse</option>
                            <option value="industrialLand">
                              Industrial Land
                            </option>
                            <option value="manufacturingPlant">
                              Manufacturing Plant
                            </option>
                          </select>
                        )}
                      </div>
                    </div>
                    )}



                      <div className="col-md-6 my-2Feduca">
                      <label className="mb-2 mt-2">Property Ownership</label>
                      <select
                        className="form-control"
                        id="property_status"
                        name="property_status"
                        onChange={handlePropertyDetails}
                        value={PropertyDetailsForm?.property_status}
                      >
                        
                        <option value="">Select Residential Type</option>
                         <option value="Own House">Own House</option>
                         <option value="Rented">Rented</option>
                         <option value="Leased">Leased</option>
                         <option value="Company Accomodation">Company Accomodation</option>
                      </select>
                    </div>

                     {propertyTypes.includes("house") && (
                             <div className="col-md-6 my-2Feduca">
                             <label className="mb-2  mt-2">BHK Type</label>
                             <select
                               onChange={handlePropertyDetails}
                               className="form-control"
                               name="bhk_type"
                               id="bhk_type"
                               value={PropertyDetailsForm?.bhk_type}
                             >
                               <option value="">Select BHK</option>
                               <option value="1bhk">1 BHK</option>
                               <option value="2bhk">2 BHK</option>
                               <option value="3bhk">3 BHK</option>
                               <option value="4bhk">4 BHK</option>
                               <option value="4+bhk">4+ BHK</option>
                             </select>
                           </div>
                     )}
                 
                   
                    <div className="col-md-6 my-2Feduca">
                      <label className="mb-2 mt-2">Property Location</label>
                      <input
                        className="form-control"
                        type="text"
                        id="property_location"
                        name="property_location"
                        onChange={handlePropertyDetails}
                        value={PropertyDetailsForm?.property_location}
                      />
                    </div>
                    <div className="col-md-6 my-2Feduca">
                      <label className="mb-2 mt-2">Land Squarefit</label>
                      
                      <select
                               onChange={handlePropertyDetails}
                               className="form-select"
                               name="area"
                               
                               style={{width: "81px",borderTopRightRadius:"0px",borderBottomRightRadius:"0px"}}
                               value={PropertyDetailsForm?.area}
                               
                             >
                              
                               <option value="sq.ft">sq.ft</option>
                               <option value="sq.yards">sq.yards</option>
                               <option value="sq.m">sq.m</option>
                               <option value="acres">acres</option>
                               <option value="marla">marla</option>
                               <option value="cents">cents</option>
                             </select>                      
                           

                      <input
                        className="form-control"
                        type="text"
                        id="land_sqfit"
                        name="land_sqfit"
                        value={PropertyDetailsForm?.land_sqfit}
                        onChange={handlePropertyDetails}
                        style={{width:"176px",marginLeft:"76px",marginTop:"-38px",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px"}}
                      />
                    </div>
                    <div className="col-md-6 my-2Feduca">
                      <label className="mb-2 mt-2">Description</label>
                      <textarea
                        className="form-control"
                        type="text"
                        id="property_description"
                        name="property_description"
                        onChange={handlePropertyDetails}
                        value={PropertyDetailsForm?.property_description}
                      />
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <button
                      type="button"
                      class="btn btn-danger me-2"
                      onClick={handlePropertyClose}
                    >
                      Cancel
                    </button>
                    {PropertyUpdateButton == "Add" ? (
                      <button
                        type="submit"
                        value="add"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                          {isSubmitting?"Submitting":"Submit"}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        value="update"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                         {isSubmitting?"Submitting":"Update"}
                      </button>
                    )}
                  </div>
                </form>
              </Box>
            </Modal>
          </p>
        </div>
        <div className="expand" style={{marginTop:"7px"}}>
        <DataTable value={PropertyDetails}>
          <Column
              header="S.No"
              body={(rowData) => <span>{PropertyDetails.indexOf(rowData) + 1}</span>}
          ></Column>
          <Column field="property_types" header="Property Type" />
          <Column field="property_status" header="Property Ownership" />
          
         
            <Column
                // field="bhk_type"
                field={rowData => rowData.bhk_type === null ? propert_area : rowData.bhk_type} 
                header="BHK Type"
            />
        

          <Column field="property_location" header="Property Location" />
          <Column
              body={(rowData) => <span>{ rowData.land_sqfit === null ? propert_area : rowData.land_sqfit + rowData.area}</span>}
              header="Land Squarefit"
          />
          <Column field="property_description" header="Description" />
          <Column
              body={(rowData) => actions(rowData)}
              header="Actions"
          />
      </DataTable>

        </div>
      </div>
    </div>
  );
};

export default PropertyDetailFormPage;
