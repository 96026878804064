import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Chip, Avatar, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import LocationIcon from '../../../assets/Icons/CineJobLocation.png';
import RupeeIcon from '../../../assets/Icons/CineJobRupee.png';
import SkillIcon from '../../../assets/Icons/CineJobSkill.png';
import ExperienceIcon from '../../../assets/Icons/CineJobSuitcase.png';
import TimeIcon from '../../../assets/Icons/CineJobTime.png';
import BookmarkIcon from '../../../assets/Icons/CineJobBookmark.png';
import { Assetsapi } from '../../../config/serverUrl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { ASSETS_API } from '../../../config/api';

const JobCard = styled(Card)(({ theme }) => ({
  borderRadius: '15px',
  height: '260px',
  border: '1px solid #e6e6e6',
  backgroundColor: '#ffffff',
  padding: '14px',
  marginBottom: '24px',
  '&:hover': {
    border: '1px solid #68bcff',
    backgroundColor: 'transparent',
  },
}));

const JobIcon = styled(Avatar)(({ theme }) => ({
  width: '25px',
  height: '25px',
  marginRight: '4px'
}));

const JobTimeChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgb(239, 245, 252)',
  color: '#4a4a4a',
  fontSize: '14px',
}));

const JobContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginBottom: '0px'
}));

const JobChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgb(239, 245, 252)',
  color: '#4a4a4a',
  fontSize: '14px',
}));

const JobTitleContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

const CompanyLogo = styled(CardMedia)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  [theme.breakpoints.up('xs')]: {
    width: 75,
    height: 75
  },
  [theme.breakpoints.up('sm')]: {
    width: 80,
    height: 80
  },
  [theme.breakpoints.up('md')]: {
    width: 90,
    height: 90
  },
  [theme.breakpoints.up('lg')]: {
    width: 120,
    height: 120
  },
}));

const capitalizeText = {
  textTransform: 'capitalize',
};

const JobSeekingCard = ({ job }) => {
  const {
    jobDetail,
    candidateDetail,
    companyDetail,
    createdAt,
    id
  } = job;
  const postDate = moment(createdAt);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:600px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:960px)');
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const displayCount = isSmallScreen ? 1 : isMediumScreen ? 2 : isLargeScreen ? 3 : 2;
  let formattedDate;
  const now = moment();
  const minutesAgo = now.diff(postDate, 'minutes');
  const hoursAgo = now.diff(postDate, 'hours');
  const daysAgo = now.diff(postDate, 'days');

  if (minutesAgo < 60) {
    formattedDate = minutesAgo <= 1 ? 'Posted a minute ago' : `Posted ${minutesAgo} minutes ago`;
  } else if (hoursAgo < 24) {
    formattedDate = hoursAgo <= 1 ? 'Posted an hour ago' : `Posted ${hoursAgo} hours ago`;
  } else if (daysAgo < 7) {
    formattedDate = daysAgo === 1 ? 'Posted a day ago' : `Posted ${daysAgo} days ago`;
  } else {
    formattedDate = postDate.format('DD-MM-YYYY');
  }

  return (
    <Link to={`/main/CineJob/${id}`} style={{ color: 'none', textDecoration: 'none' }}>
      <JobCard >
        <CardContent sx={{
          padding: '0px', '&:last-child': {
            paddingBottom: '0px',
          }
        }}>
          <JobTitleContainer>
            <Typography variant="h6" component="div" fontWeight={500} color="#4a4a4a" sx={{
              ...capitalizeText, overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: {
                xs: 'calc(100% - 100px)',
                sm: 'calc(100% - 100px)',
                md: 'calc(100% - 150px)',
                lg: 'calc(100% - 200px)',
                xl: 'calc(100% - 250px)'
              },
            }}>
              {jobDetail?.jobTitle || "Job Title"}
            </Typography>
            <CompanyLogo>
              <Box
                bgcolor="#EFF5FC"
                borderRadius="10px 10px 10px 10px"
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <div style={{
                  position: 'relative',
                  width: 'calc(100% - 16px)',
                  height: 'calc(100% - 16px)',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  backgroundColor: '#EFF5FC',
                }}>
                  <span
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${ASSETS_API.ASSETS_AD_IMAGES(companyDetail?.file)})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      filter: 'blur(2px)',
                      zIndex: 0,
                      opacity: 0.9,
                      borderRadius: '8px',
                    }}
                  ></span>
                  <img
                    src={`${ASSETS_API.ASSETS_AD_IMAGES(companyDetail?.file)}`}
                    alt="Company Logo"
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      zIndex: 1,
                      borderRadius: '8px',
                    }}
                  />
                </div>
              </Box>
            </CompanyLogo>
          </JobTitleContainer>

          <Box display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={1}>
            <Box display="flex" alignItems="center" flex="1">
              <Typography variant="subtitle1" color="#2196f3" mr={2} sx={{
                ...capitalizeText, overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: {
                  xs: 'calc(100% - 100px)',
                  sm: 'calc(100% - 100px)',
                  md: 'calc(100% - 150px)',
                  lg: 'calc(100% - 200px)',
                  xl: 'calc(100% - 250px)'
                },
              }}>
                {companyDetail?.companyName || "Company Name"}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" flexWrap="wrap" gap={{ xs: 1, lg: 0 }} mb={1}>
            <Box display="flex" alignItems="center" sx={{ width: { xs: '100%', md: "auto" } }}>
              <JobIcon src={LocationIcon} alt="" variant="square" />
              <Typography variant="body2" color="#4a4a4a" ml={1} sx={{
                ...capitalizeText, overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                maxWidth: {
                  xs: 'calc(100% - 100px)', 
                  sm: 'calc(100% - 100px)',
                  md: '100%', 
                },
              }}>
                {jobDetail?.jobAddress
                  ? jobDetail?.jobAddress
                  : 'Not specified'}
              </Typography>
            </Box>
            {isMdUp && jobDetail?.workType.length > 0 && (
              <JobContainer ml={{ xs: 3, lg: 1 }}>
                {jobDetail.workType.slice(0, displayCount).map((skill, idx) => (
                  <JobChip key={idx} label={skill} sx={capitalizeText} />
                ))}
                {jobDetail.workType.length > displayCount && (
                  <JobChip
                    label={`+${jobDetail.workType.length - displayCount}`}
                    sx={capitalizeText}
                  />
                )}
              </JobContainer>
            )}
          </Box>
          <Box display="flex" justifyContent="start" flexWrap={"wrap"} mb={1} gap={2}>
            <Box display="flex" alignItems="center">
              <JobIcon src={ExperienceIcon} alt="" variant="square" />
              <Typography variant="body2" color="#4a4a4a" ml={1} sx={capitalizeText}>
                {candidateDetail?.minExperience && candidateDetail?.maxExperience
                  ? `${candidateDetail.minExperience} - ${candidateDetail.maxExperience} yrs`
                  : 'Not specified'}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <JobIcon src={RupeeIcon} alt="" variant="square" />
              <Typography variant="body2" color="#4a4a4a" ml={1} sx={capitalizeText}>
                {jobDetail?.minSalary && jobDetail?.maxSalary
                  ? `${jobDetail.minSalary} - ${jobDetail.maxSalary} INR`
                  : 'Not specified'}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={1}>
            <Box display="flex" alignItems="center">
              <JobIcon src={SkillIcon} alt="" variant="square" />
              <JobContainer>
                {candidateDetail?.preferredSkills.length > 0 ? (
                  <>
                    {candidateDetail.preferredSkills.slice(0, displayCount).map((skill, idx) => (
                      <JobChip key={idx} label={skill} sx={capitalizeText} />
                    ))}
                    {candidateDetail.preferredSkills.length > displayCount && (
                      <JobChip
                        label={`+${candidateDetail.preferredSkills.length - displayCount}`}
                        sx={capitalizeText}
                      />
                    )}
                  </>
                ) : <Typography variant="body2" color="#4a4a4a" ml={1}>
                  Not specified
                </Typography>}
              </JobContainer>
            </Box>
          </Box>
          {/* <Box display="flex" justifyContent="space-between" mb={1}>
            <Box display="flex" alignItems="center">
              <JobIcon src={TimeIcon} alt="" variant="square" />
              <JobContainer>
                {jobDetail?.workModes?.map((skill, idx) => (
                  <JobChip key={idx} label={skill} sx={capitalizeText} />
                ))}
              </JobContainer>
            </Box>
          </Box> */}
          <hr />
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <JobIcon src={TimeIcon} alt="" variant="square" />
              <Typography variant="body2" color="#4a4a4a">
                {formattedDate}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <JobIcon src={BookmarkIcon} alt="" variant="square" />
              <Typography variant="body2" color="#4a4a4a" sx={capitalizeText}>
                Save
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </JobCard>
    </Link >
  );
};

export default JobSeekingCard;
