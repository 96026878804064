import { MdAdd } from "react-icons/md";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../../Data/ProductService";
import "../../Component/AddEducationDetails/School.css";
import "./Relationship.css";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { useSelector } from "react-redux";
import {idProofs,countries} from './Dropdown_list'
import { Toast } from "primereact/toast";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import {AddDetails} from '../../routes/profileRoutes'
import HomeApplianceFormPage from "./HomeApplianceForm";
import { BsThreeDotsVertical } from "react-icons/bs";
import Swal from 'sweetalert2';
import { Calendar } from 'primereact/calendar';
import moment from "moment/moment";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "#fff",
  border: "0px",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  borderRadius: "12px",
  padding: "32px",
  //   height: "483px",
  //   overflowY: "scroll",
};

const Documentation = () => {

  const [documentation,setDocumentation] = useState({
    documentname:"",
    otherdocument:'',
    documentnumber:'',
    issuedate:'',
    validitydate:'',
    country:'', 
    issuingauthority:''

  })
  
 console.log("documentation",documentation);

  const authdata = useSelector((state) => state.auth.user);

  const [documentationforms,setDocumentationforms]= useState('')
  const [selectedJobDetail, setSelectedJobDetail] = React.useState(null);
  const [otherDocumentName, setOtherDocumentName] = useState({
    documentname:''
  });
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [issuedate,setissuedate] = useState('');
  const [validitydate,setvaliditydate] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); //to handle multiple form submission 
 
  useEffect(() => {
    fetchdocumentation();
  }, []);

  const fetchdocumentation = async () => {
    const fetchHomeDetails = await axios.post(`${Nodeapi}/FetchDocument`, {
      id: authdata?.id,
      table: "Documentation",
    });
    if (fetchHomeDetails) {
      setDocumentationforms(fetchHomeDetails.data.data.response);
    }
  };

  

  const [updatebutton,setupdatebutton] = useState(false)

 
  const toast = useRef(null);

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelectedJobDetail(rowData);
      menuLeft.current.toggle(event);
    };
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""

          className="mr-2"
          // onClick={(event) => menuLeft.current.toggle(event)}
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical  />
        </Button>
      </div>
    );
  };


  
  useEffect(() => {
    if(documentationforms){
      
    setSerialNumbers(
      Array.from({ length: documentationforms.length }, (_, index) => index + 1)
    );

    }
  }, [documentationforms]);
  
 
  const menuLeft = useRef(null);
  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selectedJobDetail),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selectedJobDetail),
    },
  ];


  const handleEdit = (rowData)=>{
   
    handleOpen();
   
    const value1 = rowData.issuedate
    let issue = moment(value1, 'DD-MM-YYYY').toDate()
    setissuedate(issue)
    const value2 = rowData.validitydate
    let validity = moment(value2, 'DD-MM-YYYY').toDate()
    setvaliditydate(validity)
    setDocumentation(rowData);
    // setUpdateButton("update");
    setupdatebutton(true)
  }

  const deleteContactData = async(id)=>{
    try {
      await axios.delete(`${Nodeapi}//deleteDocumentData/${id}`);
      fetchdocumentation();
    
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
   }


  
  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
   
    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData.id);
        Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success'
        });
      
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  }

 

  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);

  const  handleOpen = ()=>{
    setOpen(true);
    setupdatebutton(false)
    setDocumentation("")
    setissuedate("")
    setvaliditydate("")
  }
  const handleClose = () => setOpen(false);
  const [data, setData] = useState("");

  const [idProof, setIdProof] = useState("");
  const [showIdNumberField, setShowIdNumberField] = useState(false);
  const [idNumber, setIdNumber] = useState("");

  const handleIdProofChange = (event) => {
    const selectedIdProof = event.target.value;
    setIdProof(selectedIdProof);

    setShowIdNumberField(selectedIdProof === "other");
  };

  

  const handlechange = (e)=>{
    const { name, value } = e.target;

    

     setDocumentation({
      ...documentation,
      [name]: value,
      userid: authdata?.id,
    });
  }

  const HandleHomeApplianceDetails = async (e) => {
    e.preventDefault();

    if(isSubmitting) return;  //Prevent multiple form submissions
    setIsSubmitting(true);

    if (updatebutton) {
      const json_data = {
        formdata: documentation,
        table: "Documentation",
        id: documentation.id,
        userid: documentation.userid,
        message: "Documentation Updated Successfully...!",
      };

      try {
        const updateDetailsResult = await axios.post(
          `${Nodeapi}/updateDocument`,
          json_data
        );

        if (updateDetailsResult) {
          setOpen(false);

          setDocumentationforms(updateDetailsResult.data.data.response);
          Swal.fire({
            position: "center",
             icon: "success",
             title: 'Updated Successfully',
             showConfirmButton: false,
             timer: 2000
           });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Error 404',
            showConfirmButton: false,
            timer: 2000
          });
          setOpen(false);
        }
      } catch (error) {
        console.error("Error updating details:", error);
      }
    } else {   

      if(!documentation){
        Swal.fire({
          position: "center",
          icon: "error",
          title: 'Please Fill Out All Required Fields',
          showConfirmButton: false,
          timer: 2000
        });
        return setOpen(false);
      }

      const json_data = {
        insertdata: documentation,
        table: "Documentation",
        insertMessage: "Documentation Details Inserted Successfully",
      };

      try {
        const addDetailsResult = await AddDetails(json_data);

        if (addDetailsResult) {
          setOpen(false);
            
          setDocumentationforms(addDetailsResult.data.data.response);
            
          Swal.fire({
            position: "center",
             icon: "success",
             title: 'Documentation Details Inserted Successfully',
             showConfirmButton: false,
             timer: 2000
           });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Error 404',
            showConfirmButton: false,
            timer: 2000
          });
          setOpen(false);
        }
      } catch (error) {
        console.error("Error adding details:", error);
      
    }
      }
 
    setIsSubmitting(false);
  };

  const handleissuesdate = (event)=>{
    setissuedate(event.value)
    const newDob = event.value;
    const formattedDate = moment(newDob).format('DD-MM-YYYY'); // Format date
    
    console.log('formattedDate:',formattedDate);
    
    setDocumentation({
      ...documentation,
      issuedate: formattedDate, // Set formatted date
    });
   }

   const handlevaliditydate = (event)=>{
    setvaliditydate(event.value)
    const newDob = event.value;
    const formattedDate = moment(newDob).format('DD-MM-YYYY'); // Format date
    
    console.log('formattedDate:',formattedDate);
    
    setDocumentation({
      ...documentation,
      validitydate: formattedDate, // Set formatted date
    });
   }
  
   const minRelievingDate = issuedate ? new Date(issuedate.getTime()) : null;

  return (
    <div>
      <Toast ref={toast} />
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Documentation</h5>
          <p>
            <Button onClick={handleOpen}>
              <MdAdd size={22} />
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <h6 className="mb-2">Documentation</h6>
                <div className="">
                  <div className="">
                    <label
                      className="form-label labels mt-2"
                      htmlFor="idNumber"
                    >
                      ID Proof:
                    </label>
                    <select
                        id="idproof"
                        className="form-select col-md-4 form-control"
                        name="documentname"
                        value={documentation.documentname}
                        onChange={handlechange}
                      >
                        <option value="">Select ID Proof</option>
                        {idProofs
                          .sort((a, b) => a.localeCompare(b))
                          .map((proof, index) => (
                            <option key={index} value={proof}>
                              {proof}
                            </option>
                          ))}
                        <option value="other">Other</option>
                      </select>

                      {documentation.documentname === 'other' && (
                        <input
                          type="text"
                          id="idnumber"
                          className="form-control mt-3"
                          name="otherdocument"
                          placeholder="Type the Document name"
                          value={documentation.otherdocument} // Ensure this value reflects state
                          onChange={handlechange}
                        />
                      )}
                  </div>

                  <div className=" ">
                    <label
                      className="form-label labels mt-2"
                      htmlFor="idNumber"
                    >
                      ID Number:
                    </label>
                    <input
                      placeholder="ID Number"
                      type="text"
                      id="idnumber"
                      className="form-control"
                      name="documentnumber"
                      value={documentation.documentnumber}
                      onChange={handlechange}
                    />
                  </div>
                  <div className=" ">
                    <label
                      className="form-label labels mt-2"
                      htmlFor="issueDate"
                    >
                      Issue Date:
                    </label>
    
                     < Calendar
                    id="issuedate"
                    className="w-full input2"
                    showIcon={false} 
                    name="issuedate"
                    monthNavigator
                    placeholder="Issue Date"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={issuedate}
                    onChange={handleissuesdate}
                    style={{ width: '88%',height:"42px" , borderTopRightRadius:"4px" ,borderBottomRightRadius:"4px" , border:"none" }} // Set the width to 300px
                  />
                  </div>
                  <div className=" ">
                    <label
                      className="form-label labels mt-2"
                      htmlFor="issueDate"
                    >
                      Validity Date:
                    </label>
            
                   < Calendar
                    id="validitydate"
                    className="w-full input2"
                    showIcon={false} 
                    name="validitydate"
                    monthNavigator
                    placeholder="Validity Date"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={validitydate}
                    onChange={handlevaliditydate}
                    minDate={minRelievingDate}
                    style={{ width: '88%',height:"42px" , borderTopRightRadius:"4px" ,borderBottomRightRadius:"4px" , border:"none" }} // Set the width to 300px
                  />

                  </div>
                  <div className=" ">
                    <label
                      className="form-label labels mt-2"
                      htmlFor="idNumber"
                    >
                      Country:
                    </label>
                    <select
                      className="header_company form-select"
                      name="country"
                      id="country"
                      value={documentation.country}
                      onChange={handlechange}
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className=" ">
                    <label
                      className="form-label labels mt-2"
                      htmlFor="issuingAuthority"
                    >
                      Issuing Authority:
                    </label>
                    <input
                      placeholder="Issuing Authority"
                      type="text"
                      id="IssuingAuthority"
                      name="issuingauthority"
                      className="form-control"
                      value={documentation.issuingauthority}
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button className="btn btn-danger me-2" onClick={handleClose}>
                    Cancel
                  </button>
                  {updatebutton ? (
                      <button
                        type="submit"
                        value="update"
                        className="btn btn-primary"
                        onClick={HandleHomeApplianceDetails}
                         disabled={isSubmitting}
                      >
                        {isSubmitting?"Submitting":"Update"}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        value="add"
                        className="btn btn-primary"
                        onClick={HandleHomeApplianceDetails}
                         disabled={isSubmitting}
                      >
                         {isSubmitting?"Submitting":"Submit"}
                      </button>
                    )}

                </div>
              </Box>
            </Modal>
          </p>
        </div>
        <div className="expand mt-2">
          <DataTable value={documentationforms} tableStyle={{ minWidth: "50rem" }}>
            {/* <Column  field="documentname" header="Document Name"></Column> */}
              
          <Column
            header="S.No"
            body={(rowData) => <span>{documentationforms.indexOf(rowData) + 1}</span>}
          ></Column>
          
            <Column 
            field={rowData => rowData.documentname === 'other' ? rowData.otherdocument : rowData.documentname}  
            header="Document Name"
          ></Column>
            <Column field="documentnumber" header="Document Number"></Column>
            <Column field="issuedate" header="Issue Date"></Column>
            <Column field="validitydate" header="Validity Date"></Column>
            <Column field="country" header="Country"></Column>
            <Column
              field="issuingauthority"
              header="Issuing Authority"
            ></Column>
            <Column body={actions} header="Actions"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
