export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  borderRadius: "12px",
  p: 4,
  height:'90vh',
  overflowY:"scroll",
};

export const modaljob = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  borderRadius: "12px",
  p: 4,
};