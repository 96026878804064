import React, { useEffect, useState } from "react";
import { Grid, Box, Button, TextField, FormControl, useTheme, InputAdornment, RadioGroup, FormControlLabel, Radio, IconButton } from "@mui/material"; //prettier-ignore
import "./LostAndFound.css";
import axios from "axios";
import LostAndFoundModal from "./LostAndFoundModal";
import { CiLocationOn } from "react-icons/ci";
import { IoCalendarOutline } from "react-icons/io5";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { setFilterType } from "../../react-redux/slices/filterSlice";
import lostIcon from "../../assets/Icons/lost icon.png";
import foundIcon from "../../assets/Icons/found icon.png";
import Swal from "sweetalert2";
import { Nodeapi, Assetsapi } from "../../config/serverUrl";
import { MdOutlineClear } from "react-icons/md";
import { useFormData } from "./UseState/LostAndFoundUseState";

const LostAndFound = () => {
  const [open, setOpen] = useState(false);
  const [reportType, setReportType] = useState("");
  const [mode, setMode] = useState("");
  const [formData, setFormData] = useFormData();
  const [errors, setErrors] = useState({});
  const [items, setItems] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const selectedType = useSelector((state) => state.filter.filterType);
  const authData = useSelector((state) => state.auth.user);
  const userId = authData?.id;
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [selectedType]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${Nodeapi}/lostfound/${userId}`);
      if (Array.isArray(data.data)) {
        setItems(data.data);
      } else {
        console.error("API response is not an array:", data);
      }
    } catch (error) {
      console.error("Error fetching lost and found items:", error);
    }
  };

  const handleOpen = (type) => {
    setReportType(type);
    setMode("add");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReportType("");
    setFormData({
      itemName: "",
      category: "",
      description: "",
      lostAt: "",
      street: "",
      landmark: "",
      area: "",
      city: "",
      district: "",
      state: "",
      pincode: "",
      dateTime: "",
      name: "",
      email: "",
      contactNo: "",
      images: [],
    });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...files],
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleRemoveImage = (indexToRemove) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: prevFormData.images.filter((_, index) => index !== indexToRemove),
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const requiredFields = [
      "itemName",
      "category",
      "description",
      // "street",
      // "landmark",
      // "area",
      // "city",
      "district",
      // "pincode",
      "state",
      "country",
      "dateTime",
      "name",
      // "email",
      "contactNo",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field
          .replace(/([A-Z])/g, " $1")
          .replace(/\b\w/g, (str) => str.toUpperCase())
          .toLowerCase()
          .replace(/^\w/, (str) => str.toUpperCase())} is required`;
        isValid = false;
      }
    });
    // if (!/^\d{6}$/.test(formData.pincode)) {
    //   newErrors.pincode = "Pincode must be 6 digits";
    //   isValid = false;
    // }
    // if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //   newErrors.email = "Email is not valid";
    //   isValid = false;
    // }
    if (!/^\d{12}$/.test(formData.contactNo)) {
      newErrors.contactNo = "Contact No must be 10 digits";
      isValid = false;
    }
    if (reportType === "lost" && !formData.lostAt) {
      newErrors.lostAt = "Lost At is required";
      isValid = false;
    }
    if (reportType === "found" && !formData.foundAt) {
      newErrors.foundAt = "Found At is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const prepareFormData = (data, reportType) => {
    const cleanedData = { ...data };
    if (reportType === "lost") {
      delete cleanedData.foundAt;
    } else if (reportType === "found") {
      delete cleanedData.lostAt;
    }
    return cleanedData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const cleanedData = prepareFormData(formData, reportType);
      try {
        const formDataToSend = new FormData();
        Object.keys(cleanedData).forEach((key) => {
          if (key === "images") {
            cleanedData[key].forEach((file) => {
              formDataToSend.append("images", file);
            });
          } else {
            formDataToSend.append(key, cleanedData[key]);
          }
        });
        formDataToSend.append("userId", userId);
        formDataToSend.append(
          "reportType",
          reportType === "lost" ? "Lost" : "Found"
        );
        const response = await axios.post(
          `${Nodeapi}/lostfound`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Post Added Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        handleClose();
        fetchData();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const filteredItems = items
    .filter((item) => {
      const matchesSearchTerm =
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesType =
        selectedType === "All" || item.reportType === selectedType;
      return matchesSearchTerm && matchesType;
    })
    .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

  const handleFilterChange = (e) => {
    const selectedValue = e.target.value;
    dispatch(setFilterType(selectedValue));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const viewDetail = (id) => {
    navigate(`/main/LostAndFoundOverview/${id}`);
  };

  const truncateText = (text, maxLength = 15) => {
    if (typeof text !== "string") return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const getButtonStyle = (status) => {
    switch (status) {
      case "Rejected":
        return {
          color: "red",
          border: "1px solid red",
          fontWeight: 500,
          background: "none",
          borderRadius: "6px",
          padding: "2px 14px",
        };
      case "Approved":
        return {
          color: "#22c55e",
          border: "1px solid #22c55e",
          fontWeight: 500,
          background: "none",
          borderRadius: "6px",
          padding: "2px 14px",
        };
      case "Pending":
        return {
          color: "orange",
          border: "1px solid orange",
          fontWeight: 500,
          background: "none",
          borderRadius: "6px",
          padding: "2px 14px",
        };
      default:
        return {};
    }
  };

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const theme = useTheme();

  const BaseURL = `${Assetsapi}/adimages`;

  return (
    <div>
      <h6 className="my-3 recentpost_lnf">Lost & Found</h6>
      <div className="mb-4">
        <Box display="flex" flexWrap={"wrap"} gap={{ sm: 1, xs: 1, md: 0 }}>
          <div>
            <div
              style={{
                flexGrow: 1,
                marginRight: "16px",
                marginTop: "20px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <TextField
                variant="outlined"
                // label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                size="small"
                sx={{
                  marginBottom: "16px",
                  width: "300px",
                  background: "white",
                  borderRadius: "4px",
                  boxShadow: "0px 0px 8px #ddd",
                  "&:hover": {
                    border: "none",
                  },
                  "&:active": {
                    border: "0.2px solid #2196f3",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "#2196f3" }}>
                      <FaSearch />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchTerm && (
                        <IconButton onClick={handleClearSearch}>
                          <MdOutlineClear />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <Box
            display="flex"
            flexGrow="1"
            flexWrap={"wrap"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={{ xs: 1, md: 3 }}
          >
            <FormControl variant="outlined">
              <RadioGroup
                row
                value={selectedType}
                onChange={handleFilterChange}
                sx={{
                  width: "auto",
                  "& .MuiFormControlLabel-root": {
                    marginRight: "16px",
                  },
                }}
              >
                <FormControlLabel value="All" control={<Radio />} label="All" />
                <FormControlLabel
                  value="Lost"
                  control={<Radio />}
                  label="Lost"
                />
                <FormControlLabel
                  value="Found"
                  control={<Radio />}
                  label="Found"
                />
              </RadioGroup>
            </FormControl>
            <div
              style={{ display: "flex", gap: "8px", justifySelf: "flex-end" }}
            >
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#2196f3",
                  "&:hover": {
                    backgroundColor: "#2196f3",
                  },
                }}
                variant="contained"
                onClick={() => handleOpen("lost")}
                startIcon={
                  <img
                    src={lostIcon}
                    alt="Lost Icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              >
                Lost
              </Button>
              <Button
                variant="contained"
                onClick={() => handleOpen("found")}
                sx={{
                  color: "white",
                  backgroundColor: "#2196f3",
                  "&:hover": {
                    backgroundColor: "#2196f3",
                  },
                }}
                startIcon={
                  <img
                    src={foundIcon}
                    alt="Found Icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              >
                Found
              </Button>
            </div>
          </Box>
        </Box>
      </div>
      <Grid container spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}>
        {filteredItems.map((item, ind) => (
          <Grid item xs={6} sm={6} md={3} key={item.id}>
            <Box
              className="ad-card-home_lnf"
              onClick={() => {
                viewDetail(item.id.toString());
              }}
              sx={{ cursor: "pointer" }}
            >
              <div className="ad-card-heart_lnf">
                <div
                  className="img-card-home_lnf"
                  style={{
                    backgroundColor: "#E6F2FC",
                    padding: "15px",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  <AutoPlaySwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  >
                    {item.images.map((img, ind) => (
                      <div key={ind}>
                        {Math.abs(activeStep - ind) <= 10 ? (
                          <Box
                            component="img"
                            sx={{
                              height: 180,
                              display: "block",
                              maxWidth: 400,
                              overflow: "hidden",
                              width: "100%",
                              borderRadius: 2,
                            }}
                            src={`${BaseURL}/${img}`}
                            alt={`Item ${ind + 1}`}
                          />
                        ) : null}
                      </div>
                    ))}
                  </AutoPlaySwipeableViews>
                </div>
              </div>
              <div style={{ padding: "12px", margin: 0 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p style={{ color: "#BBB9B9" }}>
                    Category:{" "}
                    <span style={{ color: "#2824DF", fontWeight: "500" }}>
                      {truncateText(item.category, 10)}
                    </span>
                  </p>
                  <button
                    className={`btn-lost_lnf ${
                      item.reportType === "Lost"
                        ? "btn-lost_lnf"
                        : "btn-found_lnf"
                    }`}
                  >
                    {item.reportType}
                  </button>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <p className="title">{truncateText(item.itemName, 15)}</p>
                </div>
                <p
                  className="card-desp_lnf"
                  style={{
                    marginTop: "2px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.description}
                </p>
                <div
                  className="d-flex justify-content-between align-items-center mt-2"
                  style={{
                    borderBottom: "1px solid #E6E6E6",
                    paddingBottom: "12px",
                  }}
                >
                  <button style={getButtonStyle(item.status)}>
                    {item.status}
                  </button>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <p className="botm-text">
                    <CiLocationOn style={{ color: "#8f8f8f" }} />
                    <span className="icons ms-1">
                      {truncateText(item.district, 15)}
                    </span>
                  </p>
                  <p className="botm-text">
                    <IoCalendarOutline style={{ color: "#8f8f8f" }} />
                    <span className="icons ms-1">
                      {moment(item.dateTime).format("DD-MM-YYYY")}
                    </span>
                  </p>
                </div>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
      <LostAndFoundModal
        open={open}
        handleClose={handleClose}
        reportType={reportType}
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        handleChange={handleChange}
        handleRemoveImage={handleRemoveImage}
        validateForm={validateForm}
        prepareFormData={prepareFormData}
        handleSubmit={handleSubmit}
        mode={mode}
      />
    </div>
  );
};

export default LostAndFound;
