import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ASSETS_API, CINE_JOB_API } from '../../../../config/api';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import moment from 'moment';
import { CiLocationOn } from 'react-icons/ci';
import { IoCalendarOutline } from 'react-icons/io5';
import { FaCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const MyPostCineJob = () => {
 const [jobPostDetails, setJobPostDetails] = useState([]);
 const navigate = useNavigate();
 const userId = useSelector((state) => state?.auth?.user)?.id;

 useEffect(() => {
  const fetchPostData = async () => {
   try {
    const { data } = await axios.get(CINE_JOB_API.GET_USER_ID_CINE_JOB(userId));
    console.log(data)
    setJobPostDetails(data);
   } catch (err) {
    console.error('Error fetching job details:', err);
   }
  };

  fetchPostData();
 }, []);

 const getStatusColor = (status) => {
  switch (status) {
   case "closed":
    return { color: "orange" };
   case "unposted":
    return { color: "rgb(239 11 219)" };
   case "pending":
    return { color: "#904DFF" };
   case "active":
    return { color: "green" };
   case "rejected":
    return { color: "red" };
   default:
    return { color: "#4280E3" };
  }
 };

 const groupByDate = (posts) => {
  return posts.reduce((acc, post) => {
   const date = moment(post.createdAt).format('YYYY-MM-DD');
   if (!acc[date]) acc[date] = [];
   acc[date].push(post);
   return acc;
  }, {});
 };

 const groupedJobs = groupByDate(jobPostDetails);

 return (
  <div>
   {Object.keys(groupedJobs).map(date => {
    const isToday = moment(date).isSame(moment(), 'day');
    return (
     <Box key={date} mb={2}>
      <Typography variant="body2" fontWeight={300} mb={1} color="#8B8B8B" bgcolor={"#FAFAFA"} padding={1} borderRadius={"12px"} display="inline-block">
       {isToday ? "Today" : moment(date).format('D MMMM YYYY')}
      </Typography>
      <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
       {groupedJobs[date].map(job => {
        const {
         jobDetail,
         candidateDetail,
         companyDetail,
         createdAt,
         id
        } = job;

        const workType = jobDetail.workType?.length > 0 ? jobDetail.workType[0] : "Not specified";
        const salary = jobDetail.minSalary && jobDetail.maxSalary
         ? `${jobDetail.minSalary} - ${jobDetail.maxSalary} INR`
         : 'Not specified';

        return (
         <Link to={`/MyPost/CineJob/OverView/${id}`} style={{ cursor: 'pointer', textDecoration: 'none' }}>
          <Box key={id} m={{ xs: 1, md: 2 }}>
           <Card sx={{
            width: "265px", 
            
           }}>
            <Box
             bgcolor="#EFF5FC"
             borderRadius="10px 10px 0 0"
             width="100%"
             height="180px"
             display="flex"
             alignItems="center"
             justifyContent="center"
             overflow="hidden"
            >
             <div style={{
              position: 'relative',
              width: 'calc(100% - 32px)',
              height: 'calc(100% - 32px)',
              borderRadius: '8px',
              overflow: 'hidden',
              backgroundColor: '#EFF5FC',
             }}>
              <span
               style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${ASSETS_API.ASSETS_AD_IMAGES(companyDetail.file)})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'blur(2px)',
                zIndex: 0,
                opacity: 0.9,
                borderRadius: '8px',
               }}
              ></span>
              <img
               src={`${ASSETS_API.ASSETS_AD_IMAGES(companyDetail.file)}`}
               alt="Company Logo"
               style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                zIndex: 1,
                borderRadius: '8px',
               }}
              />
             </div>
            </Box>
            <Box px={2} py={1}>
             <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={1}>
              <Typography
               variant="h6"
               component="div"
               fontWeight={500}
               color="#4a4a4a"
               textTransform="capitalize"
               sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: {
                 xs: 'calc(100% - 50px)',
                 sm: 'calc(100% - 100px)',
                 md: '100%'
                }
               }}
              >
               {jobDetail?.jobTitle || "Job Title"}
              </Typography>
              <Typography
               variant="body2"
               sx={{ ...getStatusColor(jobDetail?.status), textTransform: 'capitalize', fontWeight: 500 }}
              >
               {jobDetail?.status === "active" ? (
                <Box display={"flex"} alignItems={"center"}>
                 <FaCircle style={{ marginRight: '4px', fontSize: '12px' }} /> {jobDetail.status}
                </Box>
               ) : (
                jobDetail?.status
               )}
              </Typography>
             </Box>
             <Typography
              textTransform="capitalize"
              variant="body2"
              color="#4a4a4a"
              mb={1}
              sx={{
               color: "#4B4B4B",
               overflow: 'hidden',
               textOverflow: 'ellipsis',
               whiteSpace: 'nowrap',
               maxWidth: {
                xs: 'calc(100% - 50px)',
                sm: 'calc(100% - 100px)',
                md: '100%'
               }
              }}
             >
              {workType} | {salary}
             </Typography>
             <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
              <Typography component="div" variant="body2" color="#8B8B8B">Vacancy: {jobDetail?.jobOpening || "0"}</Typography>
              <Typography component="div" variant="body2" color="#8B8B8B">{jobDetail?.jobOpening || "0"} People interested</Typography>
             </Box>
             {/* <Box display="flex" justifyContent={"flex-end"} mt={1}>
              <Button
               variant='outlined'
               sx={{
                borderColor: '#2196f3',
                color: '#2196f3',
                borderRadius: '8px',
                '&:hover': {
                 backgroundColor: 'rgba(33, 150, 243, 0.1)',
                 borderColor: '#2196f3',
                },
               }}
               onClick={() => navigate(`/MyPost/CineJob/OverView/${id}`)}
              >
               View Detail
              </Button>
             </Box> */}
            </Box>
            <Divider />
            <Box padding={1}>
             <Box display="flex" justifyContent="space-between">
              <Typography
               variant="body2"
               sx={{
                color: "#8B8B8B",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: {
                 xs: 'calc(100% - 100px)',
                }
               }}
              >
               <CiLocationOn
                style={{
                 color: "#8B8B8B",
                 marginBottom: "3px",
                 fontSize: "19px",
                 marginRight: '8px'
                }}
               />
               {jobDetail?.jobAddress || "Not specified"}
              </Typography>
              <Typography
               variant="body2"
               sx={{
                color: "#8B8B8B"
               }}
              >
               <IoCalendarOutline
                style={{
                 color: "#8B8B8B",
                 marginBottom: "2px",
                 fontSize: "16px",
                 marginRight: '8px'
                }}
               />
               {moment(createdAt).format("DD-MM-YYYY")}
              </Typography>
             </Box>
            </Box>
           </Card>
          </Box>
         </Link>
        );
       })}
      </Box>
     </Box>
    );
   })}
  </div>
 );
};

export default MyPostCineJob;
