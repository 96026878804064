import React, { useState, useRef, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./SideBar/Sidebar_sample";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { GoDotFill } from "react-icons/go";
import { GoBell } from "react-icons/go";
import avatar from "../assets/Icons/busi-Avatar.svg";
import axios from "axios";
import { Nodeapi, SocketApi } from "../config/serverUrl";
import { Toast } from "primereact/toast";
import Drawer from "@mui/material/Drawer";
import io from "socket.io-client";
import { useSelector } from "react-redux";

const socket = io(SocketApi);

const Main = () => {
  const toast = useRef(null);
  const authdata = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [islogin, setislogin] = useState(false);
  const [Notification, setNotification] = useState([]);
  const [notify, setNotify] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  useEffect(() => {
    const receiveMessageHandler = (data) => {
      try {
        if (data.message === "NotificationAccept") {
          fetchNotification();
        }
      } catch (error) {
        console.error("Error in receiveMessageHandler:", error);
      }
    };

    if (socket.connected) {
      socket.on("receive_message", receiveMessageHandler);
    } else {
      socket.on("connect", () => {
        socket.on("receive_message", receiveMessageHandler);
      });
    }

    return () => {
      socket.off("receive_message", receiveMessageHandler);
    };
  }, [socket]);

  const handleNotify = () => {
    setNotify(!notify);
  };

  const fetchNotification = async () => {
    try {
      const res = await axios.get(`${Nodeapi}/getnotification`, {
        params: {
          receiver_id: authdata?.id,
        },
      });
      if (res.data) {
        if (res.data.code == 200) {
          setNotification(res.data.data.message);
        } else {
          setNotification([]);
        }
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const AcceptRequest = async (sender_id, receiver_id, chatmaster_id, id) => {
    try {
      const res = await axios.post(`${Nodeapi}/acceptRequest`, {
        sender_id: sender_id,
        receiver_id: receiver_id,
        chatmaster_id: chatmaster_id,
      });
      removeById(Notification, id);
      if (res.data) {
        if (res.data.code == 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.data.message,
            life: 3000,
          });
          navigate("/main/Chat");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.data.data.message,
            life: 3000,
          });
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error accepting request:", error);
    }
  };

  const CancelRequest = async (chatmaster_id, id) => {
    try {
      const res = await axios.patch(`${Nodeapi}/canceltRequest`, {
        chatmaster_id: chatmaster_id,
      });
      removeById(Notification, id);
      if (res.data) {
        if (res.data.code == 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.data.message,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.data.data.message,
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error("Error canceling request:", error);
    }
  };

  function removeById(arr, id) {
    const updatedArray = arr.filter((item) => item.id !== id);
    setNotification(updatedArray);
    return updatedArray;
  }

  const OkRequest = async (chatmaster_id, id) => {
    const res = await axios.patch(`${Nodeapi}/okrequest`, {
      chatmaster_id: chatmaster_id,
    });
    if (res.data) {
      if (res.data.code == 200) {
        removeById(Notification, id);
        navigate("/main/chat");
      } else {
        console.log(res.data.data.message);
      }
    }
  };
  return (
    <div className="main">
      <Toast ref={toast} />
      <div className="d-none d-md-block">
        <Sidebar setSidebarOpen={setSidebarOpen} toggleDrawer={toggleDrawer} />
      </div>
      <div className="d-md-none d-block">
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            position: "fixed",
            top: 0,
            zIndex: 1,
            width: "100%",
            background: "#1877f2",
          }}
        >
          <AppBar position="static" style={{ background: "#1877f2" }}>
            <Toolbar className="flex justify-content-between">
              <IconButton
                onClick={toggleDrawer(true)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer open={open} onClose={toggleDrawer(false)}>
                <Sidebar toggleDrawer={toggleDrawer} />
              </Drawer>

              <div className="notifi-mobile">
                {authdata && (
                  <div className="flex mt-1">
                    <div
                      className="notification-container-mobile"
                      onClick={handleNotify}
                    >
                      {Notification[0] && (
                        <GoDotFill
                          className="ts-icon-dot-mobile"
                          style={{ color: "red" }}
                        />
                      )}
                    </div>
                    <div
                      className="notification-icon-mobile"
                      onClick={() => handleNotify()}
                    >
                      <GoBell className="ts-icon" />
                      {notify &&
                        (Notification.length > 0 ? (
                          <div
                            className="notifi-modal"
                            style={{
                              position: "absolute",
                              zIndex: 1100,
                              top: "100%",
                              right: "0",
                              backgroundColor: "white",
                              borderRadius: "4px",
                              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                              width: "300px",
                              maxHeight: "400px",
                              overflowY: "auto",
                            }}
                          >
                            <div
                              className="card"
                              style={{
                                padding: "14px",
                                overflow: "auto",
                              }}
                            >
                              {Notification.map((notifydata) => (
                                <div
                                  className="noti-list"
                                  style={{
                                    padding: "8px 0px",
                                    borderBottom: "1px solid #ecece6",
                                  }}
                                  key={notifydata.id}
                                >
                                  <div className="img-profile d-flex">
                                    <img
                                      src={avatar}
                                      alt=""
                                      style={{
                                        width: "2rem",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <p
                                      style={{
                                        color: "#333",
                                        fontSize: "14px",
                                        marginLeft: "12px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      <span>
                                        {notifydata.accept === "Accept"
                                          ? notifydata.receiver_name
                                          : notifydata.sender_name}
                                      </span>
                                      &nbsp;
                                      {notifydata.accept === "Accept"
                                        ? "has accepted the request"
                                        : "has requested to have a conversation"}
                                    </p>
                                  </div>
                                  <div className="side-noti-btn d-flex justify-content-end">
                                    {notifydata.accept === "Accept" ? (
                                      <Button
                                        className="noti-button"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          background: "#1877f2",
                                          color: "white",
                                        }}
                                        onClick={() =>
                                          OkRequest(
                                            notifydata.chatmaster_id,
                                            notifydata.id
                                          )
                                        }
                                      >
                                        Ok
                                      </Button>
                                    ) : (
                                      <>
                                        <Button
                                          className="noti-button"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            background: "red",
                                            color: "white",
                                          }}
                                          onClick={() =>
                                            CancelRequest(
                                              notifydata.chatmaster_id,
                                              notifydata.id
                                            )
                                          }
                                        >
                                          Reject
                                        </Button>
                                        <Button
                                          className="noti-button"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            background: "#22c55e",
                                            color: "white",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() =>
                                            AcceptRequest(
                                              notifydata.sender_id,
                                              notifydata.receiver_id,
                                              notifydata.chatmaster_id,
                                              notifydata.id
                                            )
                                          }
                                        >
                                          Accept
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null)}
                    </div>
                  </div>
                )}
                <div className="">
                  <Button color="inherit" onClick={() => navigate("/")}>
                    KODUKKU
                  </Button>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <div className="main-container">
        <div
          className="container"
          style={{
            height: "100vh",
            marginLeft: "0px",
            marginRight: "0px",
            position: "relative",
            zIndex: "0",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Main;