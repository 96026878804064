import React,{useContext,useRef} from "react";
import "./invoice.css";
import complogo from "../../assets/images/compLogo.svg";
import { TfiWorld } from "react-icons/tfi";
import html2pdf from "html2pdf.js";
import { DataContext } from "../../Admin/DataContext";
import Subscription from "../../Component/Subcription/Subcription";
const rowData = {
  slNo: 1,
  description: "Sample Item",
  creditValue: 2,
  unitPrice: 10,
  totalPrice: 20,
};

const Invoice = () => {

  const invoiceRef = useRef(null);
  const {
  
    invoicedetails,
    userdetails,
  } = useContext(DataContext);

 

  const subTotal = rowData.totalPrice;
  const tax = subTotal * 0.05;
  const total = subTotal + tax;

  return (
    <>
      {/* <button onClick={downloadPDF}>Download PDF</button> */}
      <div id="invoice" className="invoice" ref={invoiceRef}>
        <div className="invoice-in">
          <div className="invoice-top">
            <img src={complogo} className="invoice-logo" alt="Company Logo" />
            <h3>
              KODUKKU <br /> CLASSIFIEDS <br /> PRIVATE LIMITED
            </h3>
          </div>
          <div className="invoice-middle">
            <div
              className="flex justify-content-between"
              style={{ margin: "3rem 0" }}
            >
              <div className="invoice-to">
                <h5>BILL TO</h5>
                <p>{userdetails.username}</p>
                <p>{userdetails.email} </p>
                <p>{userdetails.mobileno} </p>
              </div>
              <div className="invoice-from">
                <h5>INVOICE</h5>
                <p>
                  Invoice Number : <span>KCPL-2024-001</span>
                </p>
                <p>
                  Invoice Date : <span>June 13, 2024</span>
                </p>
                <p>
                  Payment Mode : <span>Gpay</span>
                </p>
                <p>
                  Paid Date : <span>June 13, 2024</span>
                </p>
              </div>
            </div>
            <div className="invoice-table">
              <table>
                <thead>
                  <tr>
                    <th>Sl. No</th>
                    <th>Description</th>
                    <th>Unit</th>
                    <th>Unit Price</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{rowData.slNo}</td>
                    <td>{rowData.description}</td>
                    <td>{invoicedetails.quantity}</td>
                    <td>{invoicedetails.amount}</td>
                    <td>{rowData.totalPrice}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="invoice-total"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div
                className="invoice-card"
                style={{
                  border: "none",
                  padding: "20px 15px",
                  borderRadius: "18px",
                  width: "14rem",
                  background: "#EFF5FC",
                  color: "black",
                }}
              >
                <div className="grid">
                  <div className="col-6">
                    <h6>Sub total</h6>
                  </div>
                  <div className="col-6">
                    <p style={{ textAlign: "end" }}>₹{subTotal}</p>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-6">
                    <h6>Tax (5%)</h6>
                  </div>
                  <div className="col-6">
                    <p style={{ textAlign: "end" }}>₹{tax.toFixed(2)}</p>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-6">
                    <h6>Total</h6>
                  </div>
                  <div className="col-6">
                    <p style={{ textAlign: "end" }}>₹{total.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="invoice-note">
              <h5>NOTES</h5>
              <p>
                For billing inquiries, please contact [044 2154 3687] or
                [kodukku.com].
              </p>
              <p>Thank you for your business!</p>
            </div> */}
          </div>
        </div>
        <div className="invoice-footer">
          <p className="invoice-address">
            253 Thanthai Periyar Nagar,
            <br /> Velachery –Tambaram Main Road, Pallikaranai,
            <br /> Chennai 600100. <br />
            CIN: U74999TN2022PTC156514 | GSTIN: 33AAJCK8851D1ZH
          </p>
          <div className="">
            <p className="invoice-link">
              <TfiWorld style={{ color: "#fff", marginRight: "4px" }} />
              www.kodukku.com
            </p>
            <p>Contact Us : customercare@kodukku.com</p>
          </div>
        </div>
        
      </div>
      <Subscription invoiceRef={invoiceRef} />
    </>
    
  );
};

export default Invoice;
