import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import "./blooddonation.css";
import BloodRequestList from "./BloodRequest/BloodRequestList";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import DonorManagementList from "./DonorManagement/DonorManagementList";
import BloodRequestForm from "./BloodRequest/BloodRequestForm";
import BloodUsestates from "./BloodUsestates";

const BloodDonation = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialView = queryParams.get("menu") || "requests";
  const [mainView, setMainView] = useState("individuals");
  const [view, setView] = useState("requests");
  const [fetchRequest, setFetchRequest] = useState(false);
  const authdata = useSelector((state) => state.auth.user);
  const userid = authdata?.id;
  const { requestFormData, setRequestFormData,requestErrors, setRequestErrors, errors, setErrors, validateRequestForm} = BloodUsestates()
  const [isRequestSubmitting, setIsRequestSubmitting] = useState(false);
  const [kidId, setKidId] = useState("");

  useEffect(() => {
    setView(initialView);
  }, [initialView]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRequestErrors({});
    setRequestFormData({
      kidId: "",
      patient_id: "",
      firstname: "",
      lastname: "",
      dob: "",
      gender: "",
      blood_group: "",
      mobile_no: "",
      email: "",
      place: "",
      district: "",
      state: "",
      country: "",
      pincode: "",
      id_proof: null,
      hospital_name: "",
      doctor_name: "",
      ward_room_number: "",
      units_required: "",
      blood_component_type: "",
      from_date_required: "",
      to_date_required: "",
      to_time_required: "",
      urgency_level: "",
      reason_for_requirement: "",
      patient_underwent_surgery: "",
      surgery_type: "",
    });
  };

  const handleKidChange = async (event) => {
    const { value } = event.target;
    setKidId(value);
    try {
      const res = await axios.get(
        `${Nodeapi}/blood-donation/patientDetails?kidId=${value}`
      );
      const { name, fathername, Dob, gender, mobile_no, email } =
        res.data.fetchpersonal[0];

      setRequestFormData((prevData) => ({
        ...prevData,
        firstname: name,
        lastname: fathername,
        kidId: value,
        gender: gender,
        dob: Dob,
        mobile_no: mobile_no,
        email: email,
      }));
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };
  const handleRequestChange = (event) => {
    const { name, value, type, files } = event.target;
    if (type === "file") {
      setRequestFormData({
        ...requestFormData,
        [name]: files[0],
      });
    } else {
      setRequestFormData({
        ...requestFormData,
        [name]: value,
      });
    }
    setRequestErrors({
      ...requestErrors,
      [name]: "",
    });
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid email",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
      setRequestFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validatePhoneNumber = (value) => {
    const cleanValue = value.replace(/^\+91/, "").replace(/^91/, "").replace(/\D/g, "");
    return cleanValue.length === 10;
  };

  const handleMobileNoChange = (value, field) => {
    if (field === "mobile_no") {
      if (!validatePhoneNumber(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "Invalid mobile number",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
      setRequestFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handleSubmit = async () => {
      setIsRequestSubmitting(true);
      const isValid = validateRequestForm();
      if (!isValid) return setIsRequestSubmitting(false);
      const formData = new FormData();
      for (const key in requestFormData) {
        formData.append(key, requestFormData[key]);
      }
      formData.append("userid", userid);
      try {
        const response = await axios.post(
          `${Nodeapi}/blood-donation/request`,
          formData,
          { approval_status: "Created" },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Submitted Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setFetchRequest(true);
        handleClose();
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setRequestErrors({ patient_id: error.response.data.message });
        } else {
          console.error(
            "Error submitting request form:",
            error.response?.data || error.message
          );
        }
      } finally {
        setIsRequestSubmitting(false);
      }
  };
  
  const handleFetchRequestUpdate = () => {
    setFetchRequest(false);
  };
  return (
    <div className="blood-donation">
      <div style={{ paddingLeft: "20px", paddingTop: "20px" }}>
        <h6 className="blood-donation mt-4">Blood Donations</h6>
      </div>
      <div className="card blood-container-card">
        <div className="blood-main-menu flex">
          <p className={mainView === "individuals" ? "active blood-main-menu-btn  py-2": "blood-main-menu-btn  py-2"}
            onClick={() => setMainView("individuals")}>
            Individual Contributions
          </p>
          <p className={mainView === "bloodBanks"? "active blood-main-menu-btn ms-4 py-2"
                : "blood-main-menu-btn ms-4 py-2"
            }
            onClick={() => setMainView("bloodBanks")}
          >
            Blood Bank Management
          </p>
        </div>

        <div className="menu-container">
          <div className="flex justify-content-between">
            <div className="flex blood-menu">
              <p
                className={
                  view === "requests"
                    ? "active blood-menu-btn bloodrequest-menu-btn py-2 px-3"
                    : "blood-menu-btn bloodrequest-menu-btn py-2 px-3"
                }
                onClick={() => setView("requests")}
              >
                Blood Requests
              </p>
              <p
                className={
                  view === "donors"
                    ? "active blood-menu-btn blooddonor-menu-btn py-2 px-3 ms-3"
                    : "blood-menu-btn blooddonor-menu-btn py-2 px-3 ms-3"
                }
                onClick={() => setView("donors")}
              >
                Donor Management
              </p>
            </div>
            <div className="add flex justify-content-end">
              <button className="btn-add" onClick={handleOpen}>
                <IoMdAdd className="mb-1" />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {mainView === "individuals" && (
            <div>
              {view === "requests" ? (
                <BloodRequestList
                  fetchRequest={fetchRequest}
                  onFetchRequestUpdate={handleFetchRequestUpdate}
                />
              ) : (
                <DonorManagementList />
              )}
            </div>
          )}
          {/* {mainView === 'bloodBanks' && (
            <div>
              {view === 'requests' ? (
                <BloodRequestList fetchRequest={fetchRequest} onFetchRequestUpdate={handleFetchRequestUpdate} />
              ) : (
                <DonorManagementList />
              )}
            </div>
          )} */}
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box className="scrollBarHidden modal-box">
        <BloodRequestForm
              requestFormData={requestFormData}
              requestErrors={requestErrors}
              errors={errors}
              kidId={kidId}
              handleKidChange={handleKidChange}
              handleMobileNoChange={handleMobileNoChange}
              handleEmailChange={handleEmailChange}
              handleRequestChange={handleRequestChange}
              handleSubmit={handleSubmit}
              isRequestSubmitting={isRequestSubmitting}
              handleClose={handleClose}
            />
        </Box>
      </Modal>
    </div>
  );
};

export default BloodDonation;
