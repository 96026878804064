import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { IoMdAdd } from "react-icons/io";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import "./companyprofile.css";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Nodeapi } from "../config/serverUrl";
import axios from "axios";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

const AssetGroup = () => {
  const [visible, setVisible] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [addbutton, setaddbutton] = useState(false);
  const authdata = useSelector((state) => state.auth.user);
  const [descriptionValues, setDescriptionValues] = useState([]);
  const [displaydescripton, setdisplaydescripton] = useState([]);
  const [selected, setSelected] = React.useState(null);
  const [datevalue, setdatevalue] = useState("");
  const val = authdata.id;

  const fetchaboutdata = async (val) => {
    try {
      const res = await axios.get(`${Nodeapi}/getaboutdata?id=${val}`);
      const data = res.data.data.fetchdata;

      if (data && data.length > 0) {
        setdisplaydescripton(data);
      } else {
        setdisplaydescripton([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchaboutdata(val);
  }, [val]);

  const actions = (rowData) => {
    console.log(rowData,"rowData for asset")
    const handleMenuClick = (event) => {
      setSelected(rowData);
      menuLeft.current.toggle(event);
    };

    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2 button"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  const menuLeft = useRef(null);

  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selected),
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selected),
    },
  ];

  const handleEdit = (rowData) => {
    setVisible(true);
    setUpdate(true);
    const value1 = rowData.Dateofincorporation;
    let startYear = moment(value1, "DD-MM-YYYY").toDate();
    setdatevalue(startYear);
    setDescriptionValues(rowData);
  };

  const deleteContactData = async (rowData) => {
    try {
      await axios.delete(`${Nodeapi}/deleteaboutData/${rowData.id}`);
      fetchaboutdata(rowData.userid);
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
  };

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        setaddbutton(false);
        fetchaboutdata(rowData.userid);
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (Update) {
        const response = await axios.post(`${Nodeapi}/UpdateAboutus`, {
          ...descriptionValues,
          id: descriptionValues.id,
          userid: descriptionValues.userid,
        });
        if (response) {
          setVisible(false);
          fetchaboutdata(val);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
          setVisible(false);
        }
      } else {
        const isEmpty = Object.entries(descriptionValues).every(
          ([key, value]) => {
            if (key !== "userid" && value === "") {
              return false;
            }
            return true;
          }
        );

        if (!isEmpty) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please Fill Out All Required Fields",
            showConfirmButton: false,
            timer: 2000,
          });
          return setVisible(false);
        }

        const json_data = {
          insertdata: descriptionValues,
        };
        const response = await axios.post(`${Nodeapi}/Aboutus`, json_data);
        if (response) {
          setVisible(false);
          setdisplaydescripton((prevAssetTable) => [
            ...prevAssetTable,
            descriptionValues,
          ]);
          setaddbutton(true);
          fetchaboutdata(val);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Inserted Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Error 404",
            showConfirmButton: false,
            timer: 2000,
          });
          setVisible(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setDescriptionValues("");
    setVisible(false);
  };

  const handleopen = () => {
    setVisible(true);
    setDescriptionValues([]);
    setUpdate(false);
    setdatevalue("");
  };

  const handelvalues = (e) => {
    setDescriptionValues({
      ...descriptionValues,
      [e.target.id]: e.target.value,
      userid: authdata?.id,
    });
  };

  const handleDateChange = (event) => {
    const newDob = event.value;
    const formattedDate = moment(newDob).format("DD-MM-YYYY");
    setDescriptionValues({
      ...descriptionValues,
      Dateofincorporation: formattedDate,
    });
  };

  return (
    <div>
      <div className="asset-table  align-items-center mb-3 flex justify-content-end">
        <button
          className="btn btn-add"
          style={{ marginRight: "2px" }}
          onClick={handleopen}
        >
          <IoMdAdd className="mb-1" />
        </button>
      </div>
      <Dialog
        header="About Us"
        visible={visible}
        style={{ width: "42vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setVisible(false)}
      >
        <div>
          <div className="row">
            <div className="col-md-6 col-sm-12 my-2" style={{}}>
              <label className="mb-1" htmlFor="companyname">
                Company Name
              </label>
              <InputText
                className="form-control"
                type="text"
                id="companyname"
                onChange={handelvalues}
                value={descriptionValues?.companyname}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="Natureofbusiness">
                Nature of Business
              </label>
              <InputText
                className="form-control"
                type="text"
                id="Natureofbusiness"
                onChange={handelvalues}
                value={descriptionValues?.Natureofbusiness}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="insurancevaliditydate">
                Date of Incorporation
              </label>
              <Calendar
                id="insurancevaliditydate"
                className="cal-input w-full"
                name="insurancevaliditydate"
                monthNavigator
                yearNavigator
                yearRange="1500:2100"
                dateFormat="dd-mm-yy"
                value={datevalue}
                onChange={handleDateChange}
                style={{ width: "100%", height: "37px" }}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="CompanyTradeLicense">
                Company Trade License
              </label>
              <InputText
                className="form-control"
                type="text"
                id="CompanyTradeLicense"
                onChange={handelvalues}
                value={descriptionValues?.CompanyTradeLicense}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="GSTNumber">
                GST Number
              </label>
              <InputText
                className="form-control"
                type="text"
                id="GSTNumber"
                onChange={handelvalues}
                value={descriptionValues?.GSTNumber}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="NumberofEmployees">
                Number of Employees
              </label>
              <InputNumber
                id="NumberofEmployees"
                className="numberfield w-full"
                value={descriptionValues?.NumberofEmployees}
                onValueChange={handelvalues}
                useGrouping={false}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="CompanyVision">
                Company Vision
              </label>
              <InputTextarea
                className="w-full"
                type="text"
                id="CompanyVision"
                onChange={handelvalues}
                value={descriptionValues?.CompanyVision}
                rows={3}
                style={{ resize: "none" }}
              />
            </div>

            <div className="col-md-6 col-sm-12 my-2">
              <label className="mb-1" htmlFor="CompanyMission">
                Company Mission
              </label>
              <InputTextarea
                className="w-full"
                type="text"
                id="CompanyMission"
                onChange={handelvalues}
                value={descriptionValues?.CompanyMission}
                rows={3}
                style={{ resize: "none" }}
              />
            </div>

            <div className="col-12 my-2" style={{}}>
              <label className="mb-1" htmlFor="description">
                Description About Company
              </label>
              <InputTextarea
                className="w-full"
                type="text"
                id="description"
                onChange={handelvalues}
                value={descriptionValues?.description}
                rows={3}
                style={{ resize: "none" }}
              />
            </div>
          </div>
          <div className="text-center mt-2">
            <button className="btn btn-danger me-2" onClick={handleCancel}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSubmit}>
              {Update ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </Dialog>
      <DataTable value={displaydescripton} tableStyle={{ minWidth: "90rem" }}>
        <Column
          header="S.No"
          body={(rowData) => (
            <span>{displaydescripton.indexOf(rowData) + 1}</span>
          )}
        ></Column>
        <Column field="companyname" header="Company Name" />
        <Column field="Dateofincorporation" header="Date of Incorporation" />
        <Column field="Natureofbusiness" header="Nature of Business" />
        <Column field="CompanyTradeLicense" header="Company Trade License" />
        <Column field="GSTNumber" header="GST Number" />
        <Column field="NumberofEmployees" header="Number of Employees" />
        <Column field="CompanyVision" header="Company Vision" />
        <Column field="CompanyMission" header="Company Mission" />
        <Column field="description" header="Description" />
        <Column body={actions} header="Actions"></Column>
      </DataTable>
    </div>
  );
};

export default AssetGroup;