import './';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from 'react-redux';
import store from '../src/react-redux/store';
import { persistor } from '../src/react-redux/store';
import { PersistGate } from 'redux-persist/integration/react';

// console.log = () => {};
// console.warn = () => {};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function(registration) {
      
    }).catch(function(err) {
      console.log('Service Worker registration failed: ', err);
    });
}


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,


  document.getElementById('root')
);
