import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdOutlineDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Dialog } from "primereact/dialog";
import { IoMdAdd } from "react-icons/io";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import './companyprofile.css'
const AssetItem = () => {
  const [assets, setAssets] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [addValue, setAddValue] = useState({
    name: "",
    serialNumber: "",
    purchase: new Date(),
    returnDate: new Date(),
    cost: 0,
    manufacture: "",
    model: "",
    brand: "",
  });
  const [cost, setCost] = useState(0);

  const conditions = [{ name: "New" }, { name: "Rented" }, { name: "Used" }];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const newAsset = {
      ...addValue,
      condition: selectedCondition, // Assigning the selected condition
    };

    setAssets([...assets, newAsset]);
    setAddValue({
      name: "",
      serialNumber: "",
      purchase: new Date(),
      returnDate: new Date(),
      cost: 0,
      manufacture: "",
      model: "",
      brand: "",
    });
    setCost(0);
    
    setVisible(false);
  };

  const handleDelete = () => {};

  const [errors, setErrors] = useState("");

  const [assetTypeDropdown, setAssetTypeDropdown] = useState(""); // Initialize state

  const AssetType = [
    { name: "Laptop" },
    { name: "Monitor" },
    { name: "Keyboard" },
  ];

  const asset = AssetType.map((asset) => asset.name);

  return (
    // <div>
    //   <div className="asset-table flex justify-content-between align-items-center mb-3">
    //     <h2>Department</h2>
    //     <button className="btn btn-add" onClick={() => setVisible(true)}>
    //       <IoMdAdd />
    //     </button>
    //   </div>
    //   <DataTable
    //     value={assets}
    //     tableStyle={{ minWidth: "50rem" }}
    //     selectionMode="single"
    //     selection={selectedAsset}
    //     onSelectionChange={(e) => setSelectedAsset(e.value)}
    //   >
    //     <Column field="name" header="Asset Name"></Column>
    //     <Column field="serialNumber" header="Serial Number"></Column>
    //     <Column
    //       field="purchase"
    //       header="Date Of Purchase"
    //       body={(rowData) => (
    //         <span>{rowData.purchase.toLocaleDateString()}</span>
    //       )}
    //     ></Column>
    //     <Column field="cost" header="Cost"></Column>
    //     <Column
    //       body={() => (
    //         <div className="action">
    //           <button className="btn btn-edit" onClick={() => setVisible(true)}>
    //             <CiEdit />
    //           </button>
    //           <button className="btn btn-del" onClick={handleDelete}>
    //             <MdOutlineDelete />
    //           </button>
    //         </div>
    //       )}
    //       header="Actions"
    //     ></Column>
    //   </DataTable>

    //   {/* Add/Edit Modal */}
    //   <Dialog
    //     header="Asset Details"
    //     visible={visible}
    //     style={{ width: "50vw" }}
    //     onHide={() => {
    //       setVisible(false);
    //       setSelectedAsset(null);
    //     }}
    //   >
    //     <label htmlFor="assetType">Asset Type</label>
    //     <Dropdown
    //       value={assetTypeDropdown}
    //       onChange={(e) => setAssetTypeDropdown(e.target.value)}
    //       options={asset}
    //       placeholder="Select Asset Type"
    //       className="w-full "
    //     />
    //     <label htmlFor="serialNumber">Serial Number</label>
    //     <InputText
    //       id="serialNumber"
    //       className="w-full"
    //       onChange={handleChange}
    //       name="serialNumber"
    //       value={addValue.serialNumber}
    //     />
    //     <label htmlFor="dateOfPurchase">Date Of Purchase</label>
    //     <Calendar
    //       id="dateOfPurchase"
    //       className="w-full"
    //       name="purchase"
    //       onChange={(e) => setAddValue({ ...addValue, purchase: e.value })}
    //       value={addValue.purchase}
    //     />
    //     <label htmlFor="dateOfReturn">Date Of Return</label>
    //     <Calendar
    //       id="dateOfReturn"
    //       className="w-full"
    //       name="returnDate"
    //       onChange={(e) => setAddValue({ ...addValue, returnDate: e.value })}
    //       value={addValue.returnDate}
    //     />
    //     <label htmlFor="cost">Cost</label>
    //     <InputNumber
    //       inputId="integeronly"
    //       value={cost}
    //       className="w-full"
    //       onValueChange={(e) => setCost(e.value)}
    //     />
    //     <label htmlFor="manufacture">Manufacture</label>
    //     <InputText
    //       id="manufacture"
    //       className="w-full"
    //       onChange={handleChange}
    //       name="manufacture"
    //       value={addValue.manufacture}
    //     />
    //     <label htmlFor="model">Model</label>
    //     <InputText
    //       id="model"
    //       className="w-full"
    //       onChange={handleChange}
    //       name="model"
    //       value={addValue.model}
    //     />
    //     <label htmlFor="brand">Brand</label>
    //     <InputText
    //       id="brand"
    //       className="w-full"
    //       onChange={handleChange}
    //       name="brand"
    //       value={addValue.brand}
    //     />
    //     <div className="text-center mt-2">
    //       <Button className="p-button-success" onClick={handleSubmit}>
    //         Save
    //       </Button>
    //       <Button
    //         className="p-button-danger ml-2"
    //         onClick={() => setVisible(false)}
    //       >
    //         Cancel
    //       </Button>
    //     </div>
    //   </Dialog>
    // </div>
    <div>
      
    </div>
  );
};

export default AssetItem;
