import React, { useEffect, useState } from 'react';
import {
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Box,
  FormLabel,
  InputAdornment,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { CiCirclePlus } from 'react-icons/ci';

// Styled Components
const StyledChip = styled(Chip)(({ theme, selected }) => ({
  margin: theme.spacing(0.5),
  borderRadius: '15px',
  backgroundColor: selected ? '#2196f3' : '#fff',
  border: `1px solid ${selected ? '#2196f3' : '#BBB9B9'}`,
  color: selected ? '#fff' : '#BBB9B9',
  '&:hover': {
    backgroundColor: selected ? '#1976d2' : 'rgba(33, 150, 243, 0.1)',
    border: '1px solid #2196F3',
  },
  '& .MuiChip-deleteIcon': {
    color: selected ? '#fff' : '#2196F3',
  },
}));

const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    borderColor: '#2196f3',
    '&:hover fieldset': {
      borderColor: '#2196f3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#2196f3',
  },
};

const buttonStyles = {
  backgroundColor: '#2196f3',
  textTransform: 'none',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1976d2',
  },
};

const radioStyle = {
  color: '#BBB9B9',
  '&.Mui-checked': {
    color: '#2196f3',
  },
};

const checkBoxStyle = {
  color: '#BBB9B9',
  '&.MuiCheckbox-root': {
    '&.Mui-checked': {
      color: '#2196f3',
    },
  },
};

const chipStyles = {
  margin: 5,
};


const CandidateRequirement = ({ candidateRequirement, setCandidateRequirement, errorsCandidateReq, setErrorsCandidateReq }) => {
  const isMaxExperienceDisabled = !candidateRequirement.minExperience;

  useEffect(() => {
    if (!candidateRequirement.minExperience) {
      setCandidateRequirement(prevState => ({ ...prevState, maxExperience: '' }));
    }
  }, [candidateRequirement.minExperience, setCandidateRequirement]);

  const isMaxAgeDisabled = !candidateRequirement.minAge;

  useEffect(() => {
    if (!candidateRequirement.minAge) {
      setCandidateRequirement(prevState => ({ ...prevState, maxAge: '' }));
    }
  }, [candidateRequirement.minAge]);

  const [addLanguage, setAddLanguage] = useState({
    newLanguage: '',
    languageStatus: 'basic',
    languageLevels: {
      read: false,
      write: false,
      speak: false,
    },
  });

  const [newKeySkill, setNewKeySkill] = useState("");

  const handleCandidateRequirementChange = (key, value) => {
    setCandidateRequirement(prev => ({ ...prev, [key]: value }));
  };

  const handleCandidateRequirementArrayChange = (key, value) => {
    setCandidateRequirement(prev => ({
      ...prev,
      [key]: prev[key].includes(value)
        ? prev[key].filter(item => item !== value)
        : [...prev[key], value],
    }));
  };

  const handleAddKeySkill = () => {
    if (newKeySkill.trim() && !candidateRequirement.preferredSkills.includes(newKeySkill.trim())) {
      handleCandidateRequirementChange('preferredSkills', [...candidateRequirement.preferredSkills, newKeySkill.trim()]);
      setNewKeySkill(''); // Clear the input field
    }
  };

  const handleAddLanguage = () => {
    if (addLanguage.newLanguage.trim()) {
      handleCandidateRequirementChange('languages', [
        ...candidateRequirement.languages,
        {
          language: addLanguage.newLanguage.trim(),
          status: addLanguage.languageStatus,
          levels: Object.keys(addLanguage.languageLevels).filter(level => addLanguage.languageLevels[level]),
        }
      ]);
      setAddLanguage({
        newLanguage: '',
        languageStatus: 'basic',
        languageLevels: {
          read: false,
          write: false,
          speak: false,
        },
      });
    }
  };

  const handleDeleteLanguage = (language) => {
    handleCandidateRequirementChange('languages', candidateRequirement.languages.filter(lang => lang.language !== language));
  };

  const chipValues = {
    qualification: ['Below 10th Grade', '10th/12th Grade', 'Under Graduate', 'Post Graduate', 'Diploma', 'ITI', 'Skilled Labour', 'Unskilled Labour'],
    keySkills: candidateRequirement.preferredSkills,
    gender: ['Male', 'Female', 'Other'],
    hiringRange: ["Nearby areas (Upto 10Km)", "Anywhere in the City to commute"],
    bonus: [
      "Performance bonus",
      "Meal allowance",
      "Transportation allowance",
      "Stock purchase plan",
      "Hourly Pay",
      "Housing allowance",
      "Educational allowance",
      "Life Insurance",
      "Disability Insurance",
      "Overtime Pay",
      "Professional Development assistance",
      "Profit sharing",
      "Parental Leave",
      "Rewards",
      "Flexible Schedule",
      "Relocation assistance",
      "Retirement Plan",
      "401(K) or Retirement savings Plan",
      "Double Time Pay",
      "Monthly bonus",
      "Quarterly bonus",
      "Commission",
      "Yearly bonus",
      "Other"
    ],
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h6" marginBottom={"10px"} sx={{ color: '#2196f3' }} gutterBottom>Candidate Requirements</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormLabel component="legend">Candidate Minimum Qualification</FormLabel>
          <FormControl fullWidth>
            <Box display="flex" flexWrap="wrap">
              {chipValues.qualification.map((value) => (
                <StyledChip
                  key={value}
                  label={value.replace(/^\w/, (c) => c.toUpperCase())}
                  selected={candidateRequirement.qualification.includes(value)}
                  icon={candidateRequirement.qualification.includes(value) ? <CheckIcon style={{ color: "#fff" }} /> : <AddIcon style={{ color: '#BBB9B9' }} />}
                  onClick={() => handleCandidateRequirementArrayChange('qualification', value)}
                  style={chipStyles}
                />
              ))}
            </Box>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component="legend">Degree Specialization</FormLabel>
          <TextField
            placeholder="Enter degree specialization"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            size='small'
            value={candidateRequirement.degreeSpecialization || ''}
            onChange={(e) => handleCandidateRequirementChange('degreeSpecialization', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel component="legend">Specify Certifications If Any Required (Optional)</FormLabel>
          <TextField
            placeholder="Enter any required certifications"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            size='small'
            value={candidateRequirement.specifyCertificate || ''}
            onChange={(e) => handleCandidateRequirementChange('specifyCertificate', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormLabel component="legend">Preferred Skills</FormLabel>
          <FormControl>
            <Box display="flex" flexWrap="wrap" alignItems={"center"}>
              <Box display="flex" flexWrap="wrap">
                {chipValues.keySkills.map((value) => (
                  <StyledChip
                    key={value}
                    label={value}
                    selected={candidateRequirement.preferredSkills.includes(value)}
                    onClick={() => handleCandidateRequirementArrayChange('preferredSkills', value)}
                    onDelete={() => handleCandidateRequirementChange('preferredSkills', candidateRequirement.preferredSkills.filter(skill => skill !== value))}
                    style={chipStyles}
                  />
                ))}
              </Box>
              <Box>
                <TextField
                  value={newKeySkill}
                  onChange={(e) => setNewKeySkill(e.target.value)}
                  placeholder="Add Key Skill"
                  variant="outlined"
                  size="small"
                  sx={{ ...textFieldStyles, textTransform: 'normal', flexGrow: 1, marginTop: { xs: '10px', lg: '0px' }, '.MuiOutlinedInput-root': { paddingRight: '0px' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleAddKeySkill}
                          sx={{ color: '#2196f3' }}
                        >
                          <CiCirclePlus /> Add
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormLabel component="legend">Preferred Gender</FormLabel>
          <FormControl fullWidth>
            <Box display="flex" flexWrap="wrap">
              {chipValues.gender.map((value) => (
                <StyledChip
                  key={value}
                  label={value.replace(/^\w/, (c) => c.toUpperCase())}
                  selected={candidateRequirement.preferredGender.includes(value)}
                  icon={candidateRequirement.preferredGender.includes(value) ? <CheckIcon style={{ color: "#fff" }} /> : <AddIcon style={{ color: '#BBB9B9' }} />}
                  onClick={() => handleCandidateRequirementArrayChange('preferredGender', value)}
                  style={chipStyles}
                />
              ))}
            </Box>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component="legend">Required Experience</FormLabel>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5} md={5}>
              <TextField
                placeholder="Min. Experience"
                variant="outlined"
                fullWidth
                value={candidateRequirement.minExperience}
                onChange={(e) => handleCandidateRequirementChange('minExperience', e.target.value)}
                sx={textFieldStyles}
                size='small'
                type='number'
              />
            </Grid>
            <Grid item xs={2} md={2} style={{ textAlign: 'center' }}>
              <Typography>to</Typography>
            </Grid>
            <Grid item xs={5} md={5}>
              <TextField
                placeholder="Max. Experience"
                variant="outlined"
                fullWidth
                value={candidateRequirement.maxExperience}
                onChange={(e) => handleCandidateRequirementChange('maxExperience', e.target.value)}
                sx={textFieldStyles}
                size='small'
                type='number'
                disabled={isMaxExperienceDisabled}
              />
            </Grid>
          </Grid>
          {errorsCandidateReq.experience && <Typography color="error">{errorsCandidateReq.experience}</Typography>}

        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <FormLabel component="legend">Language Preferences</FormLabel>
              <TextField
                value={addLanguage.newLanguage}
                onChange={(e) => setAddLanguage(prev => ({ ...prev, newLanguage: e.target.value }))}
                placeholder="Enter Language"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
                size='small'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormLabel>Status</FormLabel>
              <RadioGroup
                value={addLanguage.languageStatus}
                onChange={(e) => setAddLanguage(prev => ({ ...prev, languageStatus: e.target.value }))}
                row
              >
                <FormControlLabel value="basic" control={<Radio sx={radioStyle} />} label="Basic" />
                <FormControlLabel value="intermediate" control={<Radio sx={radioStyle} />} label="Intermediate" />
                <FormControlLabel value="advanced" control={<Radio sx={radioStyle} />} label="Advanced" />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <FormLabel>Proficiency Levels</FormLabel>
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                {['read', 'write', 'speak'].map((level) => (
                  <FormControlLabel
                    key={level}
                    control={<Checkbox checked={addLanguage.languageLevels[level]} onChange={(e) => setAddLanguage(prev => ({
                      ...prev,
                      languageLevels: {
                        ...prev.languageLevels,
                        [e.target.name]: e.target.checked
                      }
                    }))} name={level} sx={radioStyle} />}
                    label={level.charAt(0).toUpperCase() + level.slice(1)}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={1}>
              <Button
                variant="contained"
                sx={{ ...buttonStyles, textTransform: 'normal' }}
                onClick={handleAddLanguage}
              >
                <CiCirclePlus style={{ fontSize: '24px' }} />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {candidateRequirement.languages.map((lang) => (
                  <StyledChip
                    key={lang.language}
                    label={`${lang.language} (${lang.status}) ${lang?.levels.join(', ')}`}
                    onDelete={() => handleDeleteLanguage(lang.language)}
                    style={chipStyles}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component="legend">Willing to Hire Candidates Within</FormLabel>
          <FormControl fullWidth>
            <Box display="flex" flexWrap="wrap">
              {chipValues.hiringRange.map((value) => (
                <StyledChip
                  key={value}
                  label={value}
                  selected={candidateRequirement.hiringRange.includes(value)}
                  onClick={() => handleCandidateRequirementArrayChange('hiringRange', value)}
                  icon={candidateRequirement.hiringRange.includes(value) ? <CheckIcon style={{ color: "#fff" }} /> : <AddIcon style={{ color: '#BBB9B9' }} />}
                  style={chipStyles}
                />
              ))}
            </Box>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component="legend">Extra Perks</FormLabel>
          <FormControl fullWidth>
            <Box display="flex" flexWrap="wrap">
              {chipValues.bonus.map((value) => (
                <StyledChip
                  key={value}
                  label={value}
                  selected={candidateRequirement.bonusOffered.includes(value)}
                  onClick={() => handleCandidateRequirementArrayChange('bonusOffered', value)}
                  icon={candidateRequirement.bonusOffered.includes(value) ? <CheckIcon style={{ color: "#fff" }} /> : <AddIcon style={{ color: '#BBB9B9' }} />}
                  style={chipStyles}
                />
              ))}
            </Box>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Age Range</FormLabel>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5} md={5}>
              <TextField
                placeholder="Min. Age"
                variant="outlined"
                fullWidth
                value={candidateRequirement.minAge}
                onChange={(e) => handleCandidateRequirementChange('minAge', e.target.value)}
                sx={textFieldStyles}
                size='small'
                type='number'
              />
            </Grid>
            <Grid item xs={2} md={2} style={{ textAlign: 'center' }}>
              <Typography>to</Typography>
            </Grid>
            <Grid item xs={5} md={5}>
              <TextField
                placeholder="Max. Age"
                variant="outlined"
                fullWidth
                value={candidateRequirement.maxAge}
                onChange={(e) => handleCandidateRequirementChange('maxAge', e.target.value)}
                sx={textFieldStyles}
                size='small'
                type='number'
                disabled={isMaxAgeDisabled}
              />
            </Grid>
          </Grid>
          {errorsCandidateReq.age && <Typography color="error">{errorsCandidateReq.age}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={candidateRequirement.resumeRequired} onChange={(e) => handleCandidateRequirementChange('resumeRequired', e.target.checked)} sx={checkBoxStyle} />}
            label="Resume required to apply"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={candidateRequirement.photoUploadRequired} onChange={(e) => handleCandidateRequirementChange('photoUploadRequired', e.target.checked)} sx={checkBoxStyle} />}
            label="Directing candidates to upload their photo"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CandidateRequirement;
