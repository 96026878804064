import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Nodeapi } from "../config/serverUrl"
import './Admin.css'
import Swal from 'sweetalert2';
import { BsThreeDotsVertical } from "react-icons/bs";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { Link } from "react-router-dom";
function Admin() {
  const [globalFilter, setGlobalFilter] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    try {

      const response = await axios.get(`${Nodeapi}/getusers`);
      const data = response.data.data.message
      setEmployees(data)
    } catch (error) {
      console.error("Error fetching employee data:", error.message);
    }
  };


  const getDate = (rowData) => {
    let value = rowData.created_at;
    // Parse the date string into a Date object
    let date = new Date(value);
    // Extract the date parts (year, month, day)
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    // Format the date as YYYY-MM-DD
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelected(rowData);
    
      menuLeft.current.toggle(event);
    };

    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical />
        </Button>
      </div>
    );
  };

  const menuLeft = useRef(null);

  const items = [

    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selected),
    },
  ];

  const deleteContactData = async (id) => {
    try {
      await axios.delete(`${Nodeapi}/deleteuserdata/${id}`);
      fetchEmployeeData();
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
  }

  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData.id);
        Swal.fire({
          title: 'Deleted!',
          text: 'User has been deleted.',
          icon: 'success'
        });

      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  }

  return (
    <div style={{ marginTop: "-28px" }}>
      <div className="cardadmin">
        <div className="grid">
          <div className="col-9 flex justify-content-between" >
            <h2 className="my-3" style={{ color: "#2196F3" }}>Users Lists</h2>
           
          </div>
          <div className="col-2">

          </div>
        </div>
        {employees.length > 0 && (
          <DataTable
            value={employees}
            paginator
            rows={10}
            tableStyle={{ minWidth: "50rem" }}
            className="data"
          >
            <Column
              header="S.No"
              className="font-bold"
              body={(rowData) => <span>{employees.indexOf(rowData) + 1}</span>}

            />
            <Column field="name" header="User Name" className="font-bold" />
            <Column field="fathername" header="Father Name" />
            <Column field="mobile_no" header="Mobile No" />
            <Column field="email" header="Email Id" />
            <Column field="user_id" header="KID Number" />
            <Column field="created_at" header="User Signed In" body={getDate} />
            <Column body={actions} header="Actions"></Column>
            {/* <Column field="Dob" header="Date of Birth" /> */}
          </DataTable>
        )}






      </div>
    </div>

  );

}

export default Admin