import React, { useState } from 'react';
import { Grid, TextField, Typography, FormLabel, IconButton, Avatar, Chip } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import PhoneInput from 'react-phone-input-2';

// Styled Components
const StyledChip = styled(Chip)(({ theme, selected }) => ({
  margin: theme.spacing(0.5),
  borderRadius: '15px',
  backgroundColor: selected ? '#2196f3' : '#fff',
  border: `1px solid ${selected ? '#2196f3' : '#BBB9B9'}`,
  color: selected ? '#fff' : '#BBB9B9',
  '&:hover': {
    backgroundColor: selected ? '#1976d2' : 'rgba(33, 150, 243, 0.1)',
    border: '1px solid #2196F3',
  },
  '& .MuiChip-deleteIcon': {
    color: selected ? '#fff' : '#2196F3',
  },
}));

const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    borderColor: '#2196f3',
    '&:hover fieldset': {
      borderColor: '#2196f3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#2196f3',
  },
};

const CompanyDetails = ({ companyDetails, setCompanyDetails, errorsCompanyDetails, setErrorsCompanyDetails }) => {

  const [logo, setLogo] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(URL.createObjectURL(file));
      setCompanyDetails((pre) => ({ ...pre, file: file }))
    }
  };

  const handleDeleteLogo = () => {
    setLogo(null);
  };

  const handleChipClick = (value) => {
    setCompanyDetails(prevState => ({
      ...prevState,
      hireCandidatesWithin: prevState.hireCandidatesWithin.includes(value)
        ? prevState.hireCandidatesWithin.filter(item => item !== value)
        : [...prevState.hireCandidatesWithin, value]
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails(prevState => ({ ...prevState, [name]: value }));

    setErrorsCompanyDetails((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };
  const handleChangeNumber = (value, name) => {
    setCompanyDetails(prevState => ({ ...prevState, [name]: value }));

    setErrorsCompanyDetails(prevErrors => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const chipValues = {
    hireCandidates: ['1 to 3 Days', '1 Week', '2 Weeks', '3 Weeks', '1 Month'],
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h6" marginBottom={"10px"} sx={{ color: '#2196f3' }} gutterBottom>Company Detail</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormLabel component={"legend"}>Company / Consultancy Name</FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="companyName"
            placeholder="Enter company or consultancy name"
            sx={textFieldStyles}
            size='small'
            value={companyDetails.companyName}
            onChange={handleChange}
            error={!!errorsCompanyDetails.companyName}
          />
          {errorsCompanyDetails.companyName && <Typography color="error">{errorsCompanyDetails.companyName}</Typography>}
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          <FormLabel sx={{ marginRight: 1 }}>Upload Logo</FormLabel>
          <input
            type="file"
            id="logo-upload"
            hidden
            onChange={handleFileChange}
          />
          <label htmlFor="logo-upload">
            <IconButton
              color="primary"
              component="span"
              sx={{ backgroundColor: blue[100], '&:hover': { backgroundColor: blue[200] } }}
            >
              <UploadIcon />
            </IconButton>
          </label>
          {logo && (
            <div style={{ position: 'relative' }}>
              <Avatar
                src={logo}
                variant="square"
                alt="Company Logo"
                sx={{ width: 56, height: 56, marginLeft: 2 }}
              />
              <IconButton
                onClick={handleDeleteLogo}
                sx={{
                  position: 'absolute',
                  top: -10,
                  right: -10,
                  color: 'red',
                  '&:hover': { backgroundColor: '#f5f5f5' }
                }}
              >
                <DeleteIcon style={{ width: '14px', height: '14px' }} />
              </IconButton>
            </div>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Company / Consultancy URL</FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="companyUrl"
            placeholder="Enter company or consultancy URL"
            sx={textFieldStyles}
            size='small'
            value={companyDetails.companyUrl}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Company Headquarters / Consultancy Address</FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="address"
            multiline
            rows={3}
            placeholder="Enter company headquarters or consultancy address"
            sx={textFieldStyles}
            size='small'
            value={companyDetails.address}
            onChange={handleChange}
            error={!!errorsCompanyDetails.address}
          />
          {errorsCompanyDetails.address && <Typography color="error">{errorsCompanyDetails.address}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Landmark near your office</FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="landmark"
            placeholder="Enter landmark near your office"
            sx={textFieldStyles}
            size='small'
            value={companyDetails.landmark}
            onChange={handleChange}
            error={!!errorsCompanyDetails.landmark}
          />
          {errorsCompanyDetails.landmark && <Typography color="error">{errorsCompanyDetails.landmark}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <FormLabel component={"legend"}>Company / Consultancy Overview</FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="overview"
            multiline
            rows={5}
            placeholder="Provide an overview of the company or consultancy"
            sx={textFieldStyles}
            size='small'
            value={companyDetails.overview}
            onChange={handleChange}
            error={!!errorsCompanyDetails.overview}
          />
          {errorsCompanyDetails.overview && <Typography color="error">{errorsCompanyDetails.overview}</Typography>}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel component={"legend"}>Recruiter Name</FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="recruiterName"
            placeholder="Enter recruiter name"
            style={textFieldStyles}
            size='small'
            value={companyDetails.recruiterName}
            onChange={handleChange}
            error={!!errorsCompanyDetails.recruiterName}
          />
          {errorsCompanyDetails.recruiterName && <Typography color="error">{errorsCompanyDetails.recruiterName}</Typography>}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel component={"legend"}>Recruiter Contact Number</FormLabel>
          <PhoneInput
            fullWidth
            country={"in"}
            variant="outlined"
            name="recruiterContact"
            type="number"
            placeholder="Enter recruiter contact number"
            style={textFieldStyles}
            size='small'
            value={companyDetails.recruiterContact}
            onChange={(value) => handleChangeNumber(value, 'recruiterContact')}
            error={!!errorsCompanyDetails.recruiterContact}
          />
          {errorsCompanyDetails.recruiterContact && <Typography color="error">{errorsCompanyDetails.recruiterContact}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Communication Preferences</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel component={"legend"}>Phone Number to Communicate</FormLabel>
          <PhoneInput
            country={"in"}
            fullWidth
            variant="outlined"
            name="phoneNumber"
            placeholder="Enter phone number"
            sx={textFieldStyles}
            size='small'
            value={companyDetails.phoneNumber}
            onChange={(value) => handleChangeNumber(value, 'phoneNumber')}
            error={!!errorsCompanyDetails.phoneNumber}
            type='number'
          />
          {errorsCompanyDetails.phoneNumber && <Typography color="error">{errorsCompanyDetails.phoneNumber}</Typography>}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel component={"legend"}>Email ID to Send Daily Updates</FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="emailId"
            type="email"
            placeholder="Enter email ID"
            sx={textFieldStyles}
            size='small'
            value={companyDetails.emailId}
            onChange={handleChange}
            error={!!errorsCompanyDetails.emailId}
          />
          {errorsCompanyDetails.emailId && <Typography color="error">{errorsCompanyDetails.emailId}</Typography>}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel component={"legend"}>Willing to hire candidates within</FormLabel>
          {chipValues.hireCandidates.map((value) => (
            <StyledChip
              key={value}
              label={value.replace(/^\w/, (c) => c.toUpperCase())}
              selected={companyDetails.hireCandidatesWithin.includes(value)}
              icon={companyDetails.hireCandidatesWithin.includes(value) ? <CheckIcon style={{ color: "#fff" }} /> : <AddIcon style={{ color: '#BBB9B9' }} />}
              onClick={() => handleChipClick(value)}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyDetails;
