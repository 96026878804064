import React from "react";

const TermsPolicy = () => {
  return (
    <div>
      <div className="card">
        <h3 style={{ color: "#2196F3", textAlign: "center" }}>
          Terms and conditions
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          These terms and conditions (“Agreement”) set forth the general terms
          and conditions of your use of the
          <a href="https://www.kodukku.com"> kodukku.com</a> website
          (“Website”), “Kodukku” mobile application (“Mobile Application”) and
          any of their related products and services (collectively, “Services”).
          This Agreement is legally binding between you (“User”, “you” or
          “your”) and Kodukku Classifieds Private Limited (doing business as
          “Kodukku”, “we”, “us” or “our”).
        </p>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          If you are entering into this Agreement on behalf of a business or
          other legal entity, you represent that you have the authority to bind
          such entity to this Agreement, in which case the terms “User,” “you,”
          or “your” shall refer to such entity. If you do not have such
          authority, or if you do not agree with the terms of this Agreement,
          you must not accept this Agreement and may not access and use the
          Services.
        </p>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          By accessing and using the Services, you acknowledge that you have
          read, understood, and agree to be bound by the terms of this
          Agreement. You acknowledge that this Agreement is a contract between
          you and Kodukku, even though it is electronic and is not physically
          signed by you, and it governs your use of the Services.
        </p>
        <div className="toc">
          <h3 className="mt-3" style={{ color: "#121212" }}>
            Table of contents
          </h3>
          <ol className="mt-2">
            <li>
              <a href="#accounts-and-membership">Accounts and membership</a>
            </li>
            <li>
              <a href="#user-content">User content</a>
            </li>
            <li>
              <a href="#backups">Backups</a>
            </li>
            <li>
              <a href="#links-to-other-resources">Links to other resources</a>
            </li>
            <li>
              <a href="#prohibited-uses">Prohibited uses</a>
            </li>
            <li>
              <a href="#intellectual-property-rights">
                Intellectual property rights
              </a>
            </li>
            <li>
              <a href="#indemnification">Indemnification</a>
            </li>
            <li>
              <a href="#severability">Severability</a>
            </li>
            <li>
              <a href="#changes-and-amendments">Changes and amendments</a>
            </li>
            <li>
              <a href="#acceptance-of-these-terms">Acceptance of these terms</a>
            </li>
            <li>
              <a href="#contacting-us">Contacting us</a>
            </li>
          </ol>
        </div>
        <h3
          className="mt-3"
          id="accounts-and-membership"
          style={{ color: "#121212" }}
        >
          Accounts and membership
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          If you create an account on the Services, you are responsible for
          maintaining the security of your account and you are fully responsible
          for all activities that occur under the account and any other actions
          taken in connection with it. We may monitor and review new accounts
          before you may sign in and start using the Services. Providing false
          contact information of any kind may result in the termination of your
          account. You must immediately notify us of any unauthorized uses of
          your account or any other breaches of security. We will not be liable
          for any acts or omissions by you, including any damages of any kind
          incurred as a result of such acts or omissions. We may suspend,
          disable, or delete your account (or any part thereof) if we determine
          that you have violated any provision of this Agreement or that your
          conduct or content would tend to damage our reputation and goodwill.
          If we delete your account for the foregoing reasons, you may not
          re-register for our Services. We may block your email address and
          Internet protocol address to prevent further registration.
        </p>
        <h3 className="mt-3" id="user-content" style={{ color: "#121212" }}>
          User content
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          We do not own any data, information or material (collectively,
          “Content”) that you submit on the Services in the course of using the
          Service. You shall have sole responsibility for the accuracy, quality,
          integrity, legality, reliability, appropriateness, and intellectual
          property ownership or right to use of all submitted Content. We may
          monitor and review the Content on the Services submitted or created
          using our Services by you. You grant us permission to access, copy,
          distribute, store, transmit, reformat, display and perform the Content
          of your user account solely as required for the purpose of providing
          the Services to you. Without limiting any of those representations or
          warranties, we have the right, though not the obligation, to, in our
          own sole discretion, refuse or remove any Content that, in our
          reasonable opinion, violates any of our policies or is in any way
          harmful or objectionable. You also grant us the license to use,
          reproduce, adapt, modify, publish or distribute the Content created by
          you or stored in your user account for commercial, marketing or any
          similar purpose.
        </p>
        <h3 className="mt-3" id="backups" style={{ color: "#121212" }}>
          Backups
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          We are not responsible for the Content residing on the Services. In no
          event shall we be held liable for any loss of any Content. It is your
          sole responsibility to maintain appropriate backup of your Content.
          Notwithstanding the foregoing, on some occasions and in certain
          circumstances, with absolutely no obligation, we may be able to
          restore some or all of your data that has been deleted as of a certain
          date and time when we may have backed up data for our own purposes. We
          make no guarantee that the data you need will be available.
        </p>
        <h3
          className="mt-3"
          id="links-to-other-resources"
          style={{ color: "#121212" }}
        >
          Links to other resources
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          Although the Services may link to other resources (such as websites,
          mobile applications, etc.), we are not, directly or indirectly,
          implying any approval, association, sponsorship, endorsement, or
          affiliation with any linked resource, unless specifically stated
          herein. We are not responsible for examining or evaluating, and we do
          not warrant the offerings of, any businesses or individuals or the
          content of their resources. We do not assume any responsibility or
          liability for the actions, products, services, and content of any
          other third parties. You should carefully review the legal statements
          and other conditions of use of any resource which you access through a
          link on the Services. Your linking to any other off-site resources is
          at your own risk.
        </p>
        <h3 className="mt-3" id="prohibited-uses" style={{ color: "#121212" }}>
          Prohibited uses
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          In addition to other terms as set forth in the Agreement, you are
          prohibited from using the Services or Content: (a) for any unlawful
          purpose; (b) to solicit others to perform or participate in any
          unlawful acts; (c) to violate any international, federal, provincial
          or state regulations, rules, laws, or local ordinances; (d) to
          infringe upon or violate our intellectual property rights or the
          intellectual property rights of others; (e) to harass, abuse, insult,
          harm, defame, slander, disparage, intimidate, or discriminate based on
          gender, sexual orientation, religion, ethnicity, race, age, national
          origin, or disability; (f) to submit false or misleading information;
          (g) to upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Services, third party products and services, or
          the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or
          scrape; (i) for any obscene or immoral purpose; or (j) to interfere
          with or circumvent the security features of the Services, third party
          products and services, or the Internet. We reserve the right to
          terminate your use of the Services for violating any of the prohibited
          uses.
        </p>
        <h3
          className="mt-3"
          id="intellectual-property-rights"
          style={{ color: "#121212" }}
        >
          Intellectual property rights
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          “Intellectual Property Rights” means all present and future rights
          conferred by statute, common law or equity in or in relation to any
          copyright and related rights, trademarks, designs, patents,
          inventions, goodwill and the right to sue for passing off, rights to
          inventions, rights to use, and all other intellectual property rights,
          in each case whether registered or unregistered and including all
          applications and rights to apply for and be granted, rights to claim
          priority from, such rights and all similar or equivalent rights or
          forms of protection and any other results of intellectual activity
          which subsist or will subsist now or in the future in any part of the
          world. This Agreement does not transfer to you any intellectual
          property owned by Kodukku or third parties, and all rights, titles,
          and interests in and to such property will remain (as between the
          parties) solely with Kodukku. All trademarks, service marks, graphics
          and logos used in connection with the Services, are trademarks or
          registered trademarks of Kodukku or its licensors. Other trademarks,
          service marks, graphics and logos used in connection with the Services
          may be the trademarks of other third parties. Your use of the Services
          grants you no right or license to reproduce or otherwise use any of
          Kodukku or third party trademarks.
        </p>
        <h3 className="mt-3" id="indemnification" style={{ color: "#121212" }}>
          Indemnification
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          You agree to indemnify and hold Kodukku and its affiliates, directors,
          officers, employees, agents, suppliers and licensors harmless from and
          against any liabilities, losses, damages or costs, including
          reasonable attorneys’ fees, incurred in connection with or arising
          from any third party allegations, claims, actions, disputes, or
          demands asserted against any of them as a result of or relating to
          your Content, your use of the Services or any willful misconduct on
          your part.
        </p>
        <h3 className="mt-3" id="severability" style={{ color: "#121212" }}>
          Severability
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          All rights and restrictions contained in this Agreement may be
          exercised and shall be applicable and binding only to the extent that
          they do not violate any applicable laws and are intended to be limited
          to the extent necessary so that they will not render this Agreement
          illegal, invalid or unenforceable. If any provision or portion of any
          provision of this Agreement shall be held to be illegal, invalid or
          unenforceable by a court of competent jurisdiction, it is the
          intention of the parties that the remaining provisions or portions
          thereof shall constitute their agreement with respect to the subject
          matter hereof, and all such remaining provisions or portions thereof
          shall remain in full force and effect.
        </p>
        <h3
          className="mt-3"
          id="changes-and-amendments"
          style={{ color: "#121212" }}
        >
          Changes and amendments
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          We reserve the right to modify this Agreement or its terms related to
          the Services at any time at our discretion. When we do, we will revise
          the updated date at the bottom of this page, post a notification
          within the Services. We may also provide notice to you in other ways
          at our discretion, such as through the contact information you have
          provided.
        </p>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          An updated version of this Agreement will be effective immediately
          upon the posting of the revised Agreement unless otherwise specified.
          Your continued use of the Services after the effective date of the
          revised Agreement (or such other act specified at that time) will
          constitute your consent to those changes.
        </p>
        <h3
          className="mt-3"
          id="acceptance-of-these-terms"
          style={{ color: "#121212" }}
        >
          Acceptance of these terms
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          You acknowledge that you have read this Agreement and agree to all its
          terms and conditions. By accessing and using the Services you agree to
          be bound by this Agreement. If you do not agree to abide by the terms
          of this Agreement, you are not authorized to access or use the
          Services.
        </p>
        <h3 className="mt-3" id="contacting-us" style={{ color: "#121212" }}>
          Contacting us
        </h3>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          If you have any questions, concerns, or complaints regarding this
          Agreement, we encourage you to contact us using the details below:
        </p>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          <a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#99;u&#115;&#116;&#111;merca&#114;e&#64;&#107;o&#100;ukk&#117;&#46;&#99;o&#109;">
            cu&#115;tome&#114;&#99;are&#64;ko&#100;uk&#107;&#117;&#46;c&#111;&#109;
          </a>
        </p>
        <p className="mt-2" style={{ color: "#4A4A4A" }}>
          This document was last updated on July 18, 2024
        </p>
      </div>

      {/* <div className="card" style={{marginTop:"27px"}}>
        <h3 style={{color:"#2196F3"}}>Cancellation & Refund Policy </h3>
        <p className="mt-3">
        KODUKKU CLASSIFIEDS PRIVATE LIMITED believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy: 
        </p>
        <p className="mt-3">
        • Cancellations will be considered only if the request is made immediately after placing the order. 
          
        </p>
        <p className="mt-3">
        In case of any Refunds approved by the KODUKKU CLASSIFIEDS PRIVATE LIMITED, 
          it’ll take 9-15 Days days for the refund to be processed to the end customer.
        </p> */}
      {/* <p className="mt-3">
        While we strive to maintain the highest standards of service and functionality, 
        it is important to note that liability is limited for platform owners, and warranties are 
        disclaimed to the fullest extent permitted by law. However, we remain committed to addressing user 
        concerns and resolving disputes in a fair and equitable manner.
        </p>
        <p className="mt-3">
        Our terms and policies are governed by applicable state and federal laws of India, and provisions are in place to establish jurisdiction for resolving disputes. 
        This ensures that our users can engage with confidence, knowing that their rights and interests are protected within a legal framework that promotes fairness and accountability. 
        Kindly contact us for further information about our company.
        </p> */}
      {/* </div> */}
    </div>
  );
};

export default TermsPolicy;
