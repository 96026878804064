// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ad-content_VSC::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Component/VehicleBoard/VehicleOverview/VehicleSimilarCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ","sourcesContent":[".ad-content_VSC::-webkit-scrollbar {\n    display: none; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
