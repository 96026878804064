import React, { useRef } from "react";
import { Tabs, Tab } from "@mui/material";

const TabsGroup = ({ activeSection, setActiveSection }) => {
  const tabsContainerRef = useRef(null);

  const handleChange = (event, newValue) => {
    setActiveSection(newValue);
    if (tabsContainerRef.current) {
      const tab = tabsContainerRef.current.querySelector(
        `[data-value="${newValue}"]`
      );
      if (tab) {
        tab.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };
  const tabStyle = {
    fontWeight: "bold",
    textTransform: "none",
    fontFamily: "'Hanken Grotesk', sans-serif",
    fontSize: "16px",
    color: "#626161",
  };

  return (
    <Tabs
      value={activeSection}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      centered
      ref={tabsContainerRef}
      sx={{
        "& .MuiTab-root": {
          color: "#4b4b4b",
          borderBottom: "2px solid transparent",
          transition: "color 0.3s, border-bottom-color 0.3s",
        },
        marginBottom: "20px",
        "& .MuiTabs-indicator": {
          backgroundColor: "#2196f3",
          transition: "background-color 0.3s",
        },
        "& .Mui-selected": {
          color: "#2196f3",
          borderBottomColor: "#2196f3",
        },
      }}
    >
      <Tab
        label="Personal Details"
        value="personal"
        data-value="personal"
        sx={tabStyle}
      />
      <Tab
        label="Contact Info"
        value="contact"
        data-value="contact"
        sx={tabStyle}
      />
      <Tab
        label="Education"
        value="education"
        data-value="education"
        sx={tabStyle}
      />
      <Tab label="Job" value="job" data-value="job" sx={tabStyle} />
      <Tab
        label="Home Appliance"
        value="homeAppliance"
        data-value="homeAppliance"
        sx={tabStyle}
      />
      <Tab label="Vehicle" value="vehicle" data-value="vehicle" sx={tabStyle} />
      <Tab
        label="Property"
        value="property"
        data-value="property"
        sx={tabStyle}
      />
      <Tab
        label="Upload Document"
        value="uploadDocument"
        data-value="uploadDocument"
        sx={tabStyle}
      />
      <Tab
        label="Expenses"
        value="expenses"
        data-value="expenses"
        sx={tabStyle}
      />
      <Tab
        label="Advertising"
        value="advertising"
        data-value="advertising"
        sx={tabStyle}
      />
      <Tab
        label="Documentation"
        value="documentation"
        data-value="documentation"
        sx={tabStyle}
      />
      <Tab
        label="Relationship"
        value="relationship"
        data-value="relationship"
        sx={tabStyle}
      />
    </Tabs>
  );
};

export default TabsGroup;
