import React from 'react'

function Cancellation_policy() {
  return (
    <div>
          <div className="card" style={{marginTop:"27px",height:"95vh",marginTop:"1%"}}>
        <h3 style={{color:"#2196F3",textAlign:"center"}}>Cancellation & Refund Policy </h3>
        <p className="mt-3">
        KODUKKU CLASSIFIEDS PRIVATE LIMITED believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy: 
        </p>
        <p className="mt-3">
        • Cancellations will be considered only if the request is made immediately after placing the order. 
          
        </p>
        <p className="mt-3">
        In case of any Refunds approved by the KODUKKU CLASSIFIEDS PRIVATE LIMITED, 
          it’ll take 9-15 Days days for the refund to be processed to the end customer.
        </p>
        {/* <p className="mt-3">
        While we strive to maintain the highest standards of service and functionality, 
        it is important to note that liability is limited for platform owners, and warranties are 
        disclaimed to the fullest extent permitted by law. However, we remain committed to addressing user 
        concerns and resolving disputes in a fair and equitable manner.
        </p>
        <p className="mt-3">
        Our terms and policies are governed by applicable state and federal laws of India, and provisions are in place to establish jurisdiction for resolving disputes. 
        This ensures that our users can engage with confidence, knowing that their rights and interests are protected within a legal framework that promotes fairness and accountability. 
        Kindly contact us for further information about our company.
        </p> */}
      </div>
    </div>
  )
}

export default Cancellation_policy