import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdOutlineDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Dialog } from "primereact/dialog";
import { IoMdAdd } from "react-icons/io";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import './companyprofile.css'
const AssetType = () => {
  // Modal
  const [visible, setVisible] = useState(false);

  // Action Table
  const Action = () => {
    return (
      <>
        <div className="action">
          <button className="btn btn-edit" onClick={() => setVisible(true)}>
            <CiEdit />
          </button>
          <button className="btn btn-del">
            <MdOutlineDelete />
          </button>
        </div>
      </>
    );
  };

  const [assetType, setAssetType] = useState({
    groupname: "",
    assetName: "",
    remarks: "",
  });

  const [selectedGroupName, setSelectedGroupName] = useState("");

  // Handle Change for groupname
  const handleGroupnameChange = (e) => {
    setSelectedGroupName(e.target.value);
  };

  const Groups = [
    { name: "Kodukku Groups Classified" },
    { name: "Karuppan Oil Refining" },
    { name: "Pg Camel Form" },
  ];

  const GroupNames = Groups.map((group) => group.name);

  // Handle Change for remarks
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssetType((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [assetTable, setAssetTable] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create a copy of the assetType
    const newAssetType = { ...assetType, groupname: selectedGroupName };
    // Add the new asset type to the table
    setAssetTable((prevAssetTable) => [...prevAssetTable, newAssetType]);
    // Reset the assetType state
    setAssetType({
      groupname: "",
      assetName: "",
      remarks: "",
    });
    setSelectedGroupName(""); // Reset selected group name
    setVisible(false);
  };

  return (
    // <div>
    //   <div className="asset-table flex justify-content-between align-items-center mb-3">
    //     <h2>Asset Categories </h2>
    //     <button className="btn btn-add" onClick={() => setVisible(true)}>
    //       <IoMdAdd />
    //     </button>
    //   </div>

    //   <DataTable value={assetTable} tableStyle={{ minWidth: "50rem" }}>
    //     <Column field="groupname" header="Group Name"></Column>
    //     <Column field="assetName" header="Asset Name"></Column>
    //     <Column field="remarks" header="Remarks"></Column>
    //     <Column body={Action} header="Actions"></Column>
    //   </DataTable>

    //   {/* Edit Modal */}
    //   <Dialog
    //     header="Asset Categories "
    //     visible={visible}
    //     style={{ width: "50vw" }}
    //     onHide={() => setVisible(false)}
    //   >
    //     <label htmlFor="groupname">GroupName</label>
    //     <Dropdown
    //       value={selectedGroupName}
    //       onChange={handleGroupnameChange}
    //       options={GroupNames}
    //       placeholder="Select a Group Name"
    //       className="w-full"
    //     />

    //     <label htmlFor="assetName">Asset Name</label>
    //     <InputText
    //       id="assetName"
    //       name="assetName"
    //       value={assetType.assetName}
    //       onChange={handleChange}
    //       placeholder="Asset Name"
    //     />

    //     <label htmlFor="groupname">Remarks</label>
    //     <InputTextarea
    //       id="remarks"
    //       name="remarks"
    //       value={assetType.remarks}
    //       onChange={handleChange}
    //       placeholder="Remarks"
    //     />
    //     <div className="text-center mt-2">
    //       <Button className="p-button-success" onClick={handleSubmit}>
    //         Save
    //       </Button>
    //       <Button
    //         className="p-button-danger ml-2"
    //         onClick={() => setVisible(false)}
    //       >
    //         Cancel
    //       </Button>
    //     </div>
    //   </Dialog>
    // </div>
    <div>
      
    </div>
  );
};

export default AssetType;
