const preDesignations = [
    "Analyst",
    "Area",
    "Assistant",
    "Associate",
    "Common",
    "Controller",
    "Coordinator",
    "Deputy",
    "Developer",
    "Executive",
    "External",
    "First",
    "Fourth",
    "General",
    "Global",
    "Higher",
    "Indoor",
    "Inside",
    "Intern",
    "Internal",
    "Junior",
    "Lead",
    "Level",
    "Lower",
    "Major",
    "Middle",
    "Minor",
    "Officer",
    "Onsite",
    "Outdoor",
    "Private",
    "Public",
    "Regional",
    "Represent",
    "Retail",
    "Second",
    "Section",
    "Senior",
    "Shift",
    "Specialist",
    "Strategist",
    "Substitute",
    "Supervisor",
    "Support",
    "Technician",
    "Territory",
    "Third",
    "Trine",
    "Vice",
    "Wholesale"
  ];


  const postDesignations = [
    "Analyst",
    "Area",
    "Assistant",
    "Associate",
    "Common",
    "Controller",
    "Coordinator",
    "Deputy",
    "Developer",
    "Executive",
    "External",
    "First",
    "Fourth",
    "General",
    "Global",
    "Higher",
    "Indoor",
    "Inside",
    "Intern",
    "Internal",
    "Junior",
    "Lead",
    "Level",
    "Lower",
    "Major",
    "Middle",
    "Minor",
    "Officer",
    "Onsite",
    "Outdoor",
    "Private",
    "Public",
    "Regional",
    "Represent",
    "Retail",
    "Second",
    "Section",
    "Senior",
    "Shift",
    "Specialist",
    "Strategist",
    "Substitute",
    "Supervisor",
    "Support",
    "Technician",
    "Territory",
    "Third",
    "Trine",
    "Vice",
    "Wholesale"
  ];


  const industries = [
    "Airlines/Aviation",
    "Advertising Agency",
    "Agriculture/Allied Industries",
    "Automobiles",
    "Autoparts",
    "Auto Fimnance",
    "Arms Dealer",
    "Accounting",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel & Fashion",
    "Architecture & Planning",
    "Arts and Crafts",
    "Automotive",
    "Aviation & Aerospace",
    "Banking/Financial Services",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies and Equipment",
    "Capital Markets",
    "Call Centers/BPO",
    "Chemicals",
    "Chit Funds",
    "Cements/Paints",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer & Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Consultancy",
    "Construction",
    "Consumer Durables",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Construction/Real Estate",
    "Courier/Cargo",
    "Cosmetics",
    "Dairy",
    "Defense & Space",
    "Design",
    "Engineering/Capital Goods",
    "Education Management",
    "E-learning",
    "Electronics",
    "Electrical & Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Fertilizers/Chemicals/Seeds",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fisheries/Poultry",
    "Forex Dealers/Bullion",
    "Food & Beverages",
    "Food Production",
    "Fundraising",
    "Furniture/Timber",
    "Gems/Jewellery",
    "Gambling & Casinos",
    "Glass, Ceramics & Concrete",
    "Government Bodies",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness & Fitness",
    "Higher Education",
    "Hostels/Restaurants",
    "Hospital & Health Care",
    "Hospitals/Clinics/Nursing Home",
    "Human Resources",
    "Import/Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Infrastructure",
    "Information Services",
    "Information Technology & Services",
    "Insurance",
    "International Affairs",
    "International Trade and Development",
    "Internet",
    "Investment Banking",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure, Travel & Tourism",
    "Libraries",
    "Logistics & Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Marbel/Granite",
    "Manufacturing",
    "Marketing & Advertising",
    "Market Research",
    "Mechanical or Industrial Engineering",
    "Media/Entertainment",
    "Media Production",
    "Medical Devices",
    "Medical Practice",
    "Mental/HealthCare",
    "Military",
    "Mining & Metals",
    "Motion Pictures & Film",
    "Money Lender",
    "Museums and Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers",
    "Nonprofit Organization Management",
    "Oil & Energy",
    "Oil & Gas",
    "Online Media",
    "Outsourcing/Offshoring",
    "Pesticides",
    "Package/Freight Delivery",
    "Packaging and Containers",
    "Paper & Forest Products",
    "Performing Arts",
    "Petrol  Pumps",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary Education",
    "Printing/Publishing",
    "Professionals(Doctors,Lawyer,ENGG,Consulting,HR)",
    "Professional Training & Coaching",
    "Program Development",
    "Public Policy",
    "Public Relations and Communications",
    "Public Safety",
    "Publishing",
    "Railroad Manufacture",
    "Ranching",
    "Real Estate",
    "Recreational Facilities and Services",
    "Religious Institutions",
    "Renewables & Environment",
    "Research",
    "Restaurants",
    "Retail Chain/FMCG",
    "Science & Technology",
    "School/Colleges/Institutes",
    "Security and Investigations",
    "Semiconductors",
    "Shipbuilding",
    "Sporting Goods",
    "Sports",
    "Stocks & Shares",
    "Staffing and Recruiting",
    "Steel/Hardware",
    "Supermarkets",
    "Tech Startups",
    "Telecommunications",
    "Textiles/Garments",
    "Think Tanks",
    "Tobacco",
    "Translation and Localization",
    "Travel & Tourism",
    "Transportation & Logistics",
    "Utilities",
    "Venture Capital & Private Equity",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine and Spirits",
    "Wireless",
    "Writing and Editing",
  ];


  const currencySymbols = [
    "",
    "₹",
    "$", 
     "£", 
    "€", 
     "¥",
     "R$",
     "₽",
    "R",
  ];

  module.exports ={
    industries,
    postDesignations,
    preDesignations,
    currencySymbols
  }