// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default theme */
:root {
  --primary-blue: #2196f3;
  --secondary-blue: #eff5fc;
  --primary-green: #17a2b8;
  --secondary-green: #eefdff;
  --primary-pink: #f75790;
  --secondary-pink: #feeaf1;
  --primary-purple: #9554ff;
  --secondary-purple: #f5efff;
  --primary-red: #ff4c00;
  --secondary-red: #ffefe8;
}

.pink-mode {
  --background: var(--primary-pink);
  --color: var(--secondary-pink);
}
.green-mode {
  --background: var(--primary-green);
  --color: var(--secondary-green);
}
.red-mode {
  --background: var(--primary-red);
  --color: var(--secondary-red);
}
.blue-mode {
  --background: var(--primary-blue);
  --color: var(--secondary-blue);
}
.purple-mode {
  --background: var(--primary-purple);
  --color: var(--secondary-purple);
}

@media screen and (max-width: 768px) {
  .chat {
    position: relative;
  }
  .mob-side {
    position: absolute;
    background-color: #fff;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/Chat/ChatMain/Chatmain.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,uBAAuB;EACvB,yBAAyB;EACzB,wBAAwB;EACxB,0BAA0B;EAC1B,uBAAuB;EACvB,yBAAyB;EACzB,yBAAyB;EACzB,2BAA2B;EAC3B,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,iCAAiC;EACjC,8BAA8B;AAChC;AACA;EACE,kCAAkC;EAClC,+BAA+B;AACjC;AACA;EACE,gCAAgC;EAChC,6BAA6B;AAC/B;AACA;EACE,iCAAiC;EACjC,8BAA8B;AAChC;AACA;EACE,mCAAmC;EACnC,gCAAgC;AAClC;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,sBAAsB;EACxB;AACF","sourcesContent":["/* Default theme */\n:root {\n  --primary-blue: #2196f3;\n  --secondary-blue: #eff5fc;\n  --primary-green: #17a2b8;\n  --secondary-green: #eefdff;\n  --primary-pink: #f75790;\n  --secondary-pink: #feeaf1;\n  --primary-purple: #9554ff;\n  --secondary-purple: #f5efff;\n  --primary-red: #ff4c00;\n  --secondary-red: #ffefe8;\n}\n\n.pink-mode {\n  --background: var(--primary-pink);\n  --color: var(--secondary-pink);\n}\n.green-mode {\n  --background: var(--primary-green);\n  --color: var(--secondary-green);\n}\n.red-mode {\n  --background: var(--primary-red);\n  --color: var(--secondary-red);\n}\n.blue-mode {\n  --background: var(--primary-blue);\n  --color: var(--secondary-blue);\n}\n.purple-mode {\n  --background: var(--primary-purple);\n  --color: var(--secondary-purple);\n}\n\n@media screen and (max-width: 768px) {\n  .chat {\n    position: relative;\n  }\n  .mob-side {\n    position: absolute;\n    background-color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
