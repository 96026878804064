import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import axios from "axios";
import { authapi } from "../../config/serverUrl";

const PrivateRoutes = () => {
  const isAuthenticated = useSelector((state) => state.auth.user);
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    if (isAuthenticated && isAuthenticated.token) {
      ValidToken(isAuthenticated.token);
    } else {
      setIsAuth(false);
    }
  }, [isAuthenticated?.token]);

  async function ValidToken(token) {
    try {
      const res = await axios.post(
        `${authapi}/auth/validtoken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsAuth(res.data.data.message === "Token Valid");
    } catch (error) {
      console.error("Error validating token", error);
      setIsAuth(false);
    }
  }

  if (isAuth === null) {
    return <div>Loading...</div>; // Or some kind of loading indicator
  }

  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
