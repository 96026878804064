import React, { useState } from "react";
import { useEffect } from "react";
import {
  UpdateDetails,
  AddDetails,
  FetchDetails,
} from "../../routes/profileRoutes";
import Jobusestates from "../../useStates/JobUsestate";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import axios from "axios";
import { Nodeapi } from "../../config/serverUrl";
import { Calendar } from 'primereact/calendar';
import moment from "moment/moment";
import { style } from "../../Styles/Jobformstyle";
import { MdAdd } from "react-icons/md";
import { useMediaQuery } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import Swal from 'sweetalert2';
import vehile_names  from '../../Json_datas/dropdown.json'
import vehiclesdata from '../../Json_datas/vehicle.json'
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import './Relationship.css'
const VehicleDetailsFormPage = () => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [selected, setSelected] = React.useState(null);
  const authdata = useSelector((state) => state.auth.user);
  const [Vehicle, setVehicle] = React.useState(false);
  const [VehicleBrand, setVehicleBrand] = React.useState('');
  const [VehicleModel, setVehicleModel] = React.useState('');
  const [Vehiclevalue, setVehiclevalue] = React.useState('');
  const [serialNumbers, setSerialNumbers] = useState([]); 
  const [vehicle_price,setvehicle_price] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false); //to handle multiple form submission 

  const handleVehicleOpen = () => setVehicle(true);
  const handleVehicleClose = () => setVehicle(false);
   const [test,settest]= useState("")
  const {
    VehicleDetailsForm,
    setVehicleDetailsForm,
    VehicleUpdateButton,
    setVehicleUpdateButton,
    VehicleDetails,
    setVehicleDetails,
  } = Jobusestates();


  const handleaddVehicleDetails = (e) => {
    e.preventDefault();
    setVehicleUpdateButton("Add");
    setVehicleDetailsForm("");
    setvehicle_price("")
  };

  useEffect(() => {
   fetchVehicleDetails();
  }, []);
    
  const fetchVehicleDetails = async () => {
    const fetchVehicleDetails = await axios.post(
      `${Nodeapi}/FetchLoopDetails`,
      {
        id: authdata?.id,
        table: "VehicleDetails",
      }
    );
    if (fetchVehicleDetails) {
      setVehicleDetails(fetchVehicleDetails.data.data.response);
    }
  };

 

 

  const actions = (rowData) => {
    const handleMenuClick = (event) => {
      setSelected(rowData); 
      setVehiclevalue(rowData.vehicle);
      menuLeft.current.toggle(event);
    };
  
    return (
      <div className="">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon=""
          className="mr-2"
          onClick={handleMenuClick}
          aria-controls="popup_menu_left"
          aria-haspopup
        >
          <BsThreeDotsVertical/>
        </Button>
      </div>
    );
  };
  
  const menuLeft = useRef(null);
  
  const items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: () => handleEdit(selected), 
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: () => handleDelete(selected), 
    },
  ];
  
  const handleEdit = (rowData) => {
    
    handleVehicleOpen();

    handlebrand(rowData.vehicle)
     handleModel(rowData.brand)
    
    const value1 = rowData.insurancevaliditydate
    let startYear = moment(value1, 'DD-MM-YYYY').toDate()
    setvehicle_price(startYear)
    setVehicleDetailsForm(rowData);
    setVehicleUpdateButton('Update');
    
  };
  

  useEffect(() => {
    if(VehicleDetails){
      setSerialNumbers(
        Array.from({ length: VehicleDetails.length }, (_, index) => index + 1)
      );
    }
    
  }, [VehicleDetails]);
  


  const deleteContactData = async(id)=>{
    try {
      await axios.delete(`${Nodeapi}//deleteVehiclData/${id}`);
      fetchVehicleDetails();
    } catch (error) {
      console.error("Error deleting contact data:", error);
    }
   }


  
 
  const handleDelete = async (rowData) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
   
    if (result.isConfirmed) {
      try {
        await deleteContactData(rowData.id);
        Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success'
        });
      
      } catch (error) {
        console.error("Error deleting relation data:", error);
      }
    }
  }



  const HandleVehicleDetails = async (e) => {
    e.preventDefault();
    if(isSubmitting) return;
    setIsSubmitting(true);

    const action = e.nativeEvent.submitter.value;

    if (action.trim() === "update") {
      const json_data = {
        formdata: VehicleDetailsForm,
        table: "VehicleDetails",
        id: VehicleDetailsForm.id,
        userid: VehicleDetailsForm.userid,
        message: "Vehicle Detail Updated Successfully...!",
      };

      try {
        const updateDetailsResult = await axios.post(
          `${Nodeapi}/updateHAD`,
          json_data
        );
        if (updateDetailsResult) {
          setVehicle(false);
          setVehicleDetails(updateDetailsResult.data.data.response);
          Swal.fire({
           position: "center",
            icon: "success",
            title: 'Updated Successfully',
            showConfirmButton: false,
            timer: 2000
          });
        } else {
         
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Error 404',
            showConfirmButton: false,
            timer: 2000
          });
          setVehicle(false);
        }
      } catch (error) {
        console.error("Error updating details:", error);
      }
    } else {

     
     
      const isEmpty = Object.entries(VehicleDetailsForm).every(([key, value]) => {
        if (key !== "userid" && value === "") {
          return false; 
        }
        return true; 
      });
      
      if (!isEmpty) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: 'Please Fill Out All Required Fields',
          showConfirmButton: false,
          timer: 2000
        });
        return setVehicle(false);
      }
    
      
      const json_data = {
        insertdata: VehicleDetailsForm,
        table: "VehicleDetails",
        insertMessage: "Vehicle Details Inserted Successfully",
      };

      try {
        const addDetailsResult = await AddDetails(json_data);

        if (addDetailsResult) {
          setVehicle(false);
          setVehicleDetails(addDetailsResult.data.data.response);
          Swal.fire({
            position: "center",
            icon: "success",
            title: 'Vehicle Details Inserted Successfully',
            showConfirmButton: false,
            timer: 2000
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: 'Error 404',
            showConfirmButton: false,
            timer: 2000
          });
          setVehicle(false);
        }
      } catch (error) {
        console.error("Error adding details:", error);
      }
    }
    setIsSubmitting(false);
  };
  const toast = useRef(null);


  const handleVehicleDetails = (e,key) => {
    const { name, value } = e.target;
    let updatesparekey = '';
   

    if (key) {
      updatesparekey = key;
    } else if (VehicleDetailsForm) { 
      updatesparekey = VehicleDetailsForm.sparekeys; 
    }

    setVehicleDetailsForm({
      ...VehicleDetailsForm,
      [name]: value,
      userid: authdata?.id,
      sparekeys:updatesparekey,
      
    });
  };


  const handlevehiletype = (e,key)=>{
    const { name, value } = e.target;
    let updatevehicletype = '';
    
    if (key) {
      updatevehicletype = key;
    } else if (VehicleDetailsForm) { 
      updatevehicletype = VehicleDetailsForm.vehicletype; 
    }

    setVehicleDetailsForm({
      ...VehicleDetailsForm,
      [name]: value,
      vehicletype:updatevehicletype
    });
    
  }

 
  const edit_Vehicledetails = async (e, data_params) => {
    e.preventDefault();
    setVehicleUpdateButton("Update");
    setVehicleDetailsForm(data_params.row);
  };
 
  
  const [selectedOwner, setSelectedOwner] = useState("");

  const handelchange = (event) => {
    setSelectedOwner(event.target.value);
  };

 const handlebrand = (vehiclebrand) => {
   
   setVehiclevalue(vehiclebrand);
  
  const filterdata = vehiclesdata.filter((vehicle) => vehicle.vehicle === vehiclebrand);
  setVehicleBrand(filterdata);

}

 const handleModel = (vehiclemodel) => {
 const filterdata = vehiclesdata.filter((vehicle) =>  vehicle.brand === vehiclemodel);
  const filterdatas = filterdata.filter((vehicle) =>  vehicle.vehicle === Vehiclevalue);
  setVehicleModel(filterdatas);

}



 const handleDateChange = (event)=>{
  
  const newDob = event.value;
  const formattedDate = moment(newDob).format('DD-MM-YYYY'); // Format date
  
  
   setVehicleDetailsForm({
    ...VehicleDetailsForm,
    insurancevaliditydate: formattedDate, // Set formatted date
  });
 }


  return (
    <div>
      <Toast ref={toast} />
      <div className=" mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Vehicle Details</h5>
          <p>
            <Button
              onClick={(e) => {
                handleVehicleOpen();
                handleaddVehicleDetails(e);
              }}
            >
              <MdAdd size={22} />
            </Button>
            <Modal
              open={Vehicle}
              onClose={handleVehicleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={style}
                style={{
                  width: isSmallScreen ? "95%" : "",
                }}
                className="scrollBarHidden"
              >
                <form onSubmit={HandleVehicleDetails}>
                  <h6>Vehicle Details</h6>
                  <div className="row">
                   
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="vehicleNumber">
                        Vehicle
                      </label>
                     
                      <select
                    className="form-control"
                    id="vehicle"
                    name="vehicle"
                    onChange={(e)=>{handleVehicleDetails(e);handlebrand(e.target.value);}}
                    value={VehicleDetailsForm?.vehicle}
                  >
                    <option value="">Select Vehicle</option>
                    {
                      [...new Set(vehiclesdata.map(vehicle => vehicle.vehicle))].map(vehicleName => (
                        <option key={vehicleName} value={vehicleName}>{vehicleName}</option>
                      ))
                    }
                  </select>


                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="vehicleNumber">
                        Vehicle Number
                      </label>
                      <input
                        placeholder=" Vehicle Number"
                        className="form-control"
                        type="text"
                        id="vehicle_no"
                        name="vehicle_no"
                        onChange={handleVehicleDetails}
                        value={VehicleDetailsForm?.vehicle_no}
                      />
                    </div>

                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="brand">
                        Brand
                      </label>
                    

                      <select
                      className="header_company form-select"
                      name="brand"
                        onChange={(e)=>{handleVehicleDetails(e);handleModel(e.target.value)}}
                        value={VehicleDetailsForm?.brand}
                    >
                      <option value="">Select Brand</option>
                      {

                      VehicleBrand &&

                      [...new Set(VehicleBrand.map(brand => brand.brand))].map(vehicleName => (
                        <option key={vehicleName} value={vehicleName}>{vehicleName}</option>
                      ))
                      }
    
                    {VehicleDetailsForm?.vehicle && (
                     <option value="Others">Others</option>
                    )}

                       
                    </select>

                    {VehicleDetailsForm?.brand === 'Others' && (
                        <input
                          type="text"
                          id="idnumber"
                          className="form-control mt-3"
                          name="otherbrand"
                          placeholder="Others"
                          value={VehicleDetailsForm.otherbrand} 
                          onChange={handleVehicleDetails}
                        />
                      )}
                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="model">
                        Model
                      </label>
                      <select
                      className="header_company form-select"
                      name="model"
                        onChange={handleVehicleDetails}
                        value={VehicleDetailsForm?.model}
                    >
                      <option value="">Select Models</option>
                      {
                      VehicleModel && VehicleModel.map((model, index) => (
                        model.model.map((modelName, modelIndex) => (
                          <option key={modelIndex} value={modelName}>{modelName}</option>
                        ))
                      ))
                    }
                    {
                      VehicleDetailsForm?.brand === 'Others' && (
                        <option value="Others">Others</option>
                      )
                    }
                    </select>

                    {  VehicleDetailsForm?.model === 'Others' && (
                        <input
                          type="text"
                          id="idnumber"
                          className="form-control mt-3"
                          name="othermodel"
                          placeholder="Others"
                          value={VehicleDetailsForm?.othermodel} 
                          onChange={handleVehicleDetails}
                        />
                      )}

                    </div>
                  

                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="model">
                        Agency
                      </label>
                      <input
                        placeholder="Agency"
                        className="form-control"
                        type="text"
                        id="dealer_agency"
                        name="dealer_agency"
                        onChange={handleVehicleDetails}
                        value={VehicleDetailsForm?.dealer_agency}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="model">
                        Type ( Manufacturer , Dealer)
                      </label>
                      <input
                        placeholder="Type"
                        className="form-control"
                        type="text"
                        id="type"
                        name="type"
                        onChange={handleVehicleDetails}
                        value={VehicleDetailsForm?.type}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="model">
                        Owners
                      </label>
                      <select
                        className="form-control"
                        id="owner"
                        name="owner"
                        value={VehicleDetailsForm?.owner}
                        onChange={handleVehicleDetails}
                      >
                        <option value="">Select Owner</option>
                        <option value="1st Owner">1st Owner</option>
                        <option value="2nd Owner">2nd Owner</option>
                        <option value="3rd Owner">3rd Owner</option>
                        <option value="4th Owner">4th Owner</option>
                        <option value="5th Owner">5th Owner</option>
                      </select>
                    </div>

                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="model">
                        OwnerShip Details
                      </label>
                      <select
                        className="form-control"
                        id="owner"
                        name="ownership"
                        value={VehicleDetailsForm?.ownership}
                        onChange={handleVehicleDetails}
                      >
                        <option value="">Select OwnerShip</option>
                        <option value="Lended">Lended</option>
                        <option value="Barrowed">Barrowed</option>
                        <option value="Lease">Lease</option>
                        <option value="Self Drive">Self Drive</option>
                        <option value="Own Use">Own Use</option>
                        <option value="Rent a Car">Rent a Car</option>
                        <option value="For Sale">For Sale</option>
                        <option value="On Contract">On Contract</option>
                      </select>
                    </div>
                    
                  
                    <div className="col-6 my-2">
                     <div style={{marginLeft:'7px',marginTop:"-8px"}}>
                      <label className="labels mb-1"> Vehicle Type</label>
                    
                      <div className="flex">
                        <button
                          type="button"
                          name="sparekeys"
                          style={{
                            padding: "8px 12px",
                            fontSize: "13px",
                            fontWeight: 600,
                            width:"67px"
                          }}
                          className={`btn btn-outline-primary  ${
                            VehicleDetailsForm?.vehicletype=== "New" && "active"
                          }`}
                          onClick={(e) => handlevehiletype(e, "New")}
                        >
                          New
                        </button>
                        <button
                          type="button"
                          name="sparekeys"
                          style={{
                            padding: "8px 12px",
                            fontSize: "13px",
                            fontWeight: 600,
                            width:"67px"
                          }}
                          className={`btn btn-outline-primary  mx-2
                             ${
                              VehicleDetailsForm?.vehicletype === "Used" &&
                               "active"
                             }`}
                          onClick={(e) => handlevehiletype(e, "Used")}
                        >
                          Used
                        </button>
                       
                      </div>
                      </div>
                   
                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="model">
                        Fuel Type
                      </label>
                      <select
                        className="form-control"
                      
                        name="fueltype"
                        value={VehicleDetailsForm?.fueltype}
                        onChange={handleVehicleDetails}
                      >
                        <option value="">Fuel Type</option>
                        <option value="Petrol">Petrol </option>
                        <option value="Diesel">Diesel </option>
                        <option value="CNG">CNG </option>
                        <option value="EV">EV </option>
                      </select>
                    </div>

                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="model">
                        Vehicle Seats
                      </label>

                    <InputText 
                    id="number" 
                    name="vehicleseats"
                    keyfilter="num" 
                    placeholder="Vehicle Seat"
                    value={VehicleDetailsForm?.vehicleseats}
                    onChange={handleVehicleDetails} 
                    style={{height:"37px",width:"248px", borderTopLeftRadius:"6px",borderBottomLeftRadius:"6px"}}
                    className="w-full " />
                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="model">
                        KM Driven
                      </label>
                    
                        
                        <InputText 
                    id="number" 
                    name="kmdriven" 
                    keyfilter="num" 
                    placeholder=" KM Driven"
                    value={VehicleDetailsForm?.kmdriven} 
                    onChange={handleVehicleDetails} 
                    style={{height:"37px",width:"248px",borderTopLeftRadius:"6px",borderBottomLeftRadius:"6px"}}
                    className="w-full" />

                    </div>
                    
                     <div className="col-6 my-2">
                     <div style={{marginLeft:'7px',marginTop:"-8px"}}>
                      <label className="labels mb-1"> Spare Keys:</label>
                    
                      <div className="flex">
                        <button
                          type="button"
                          name="sparekeys"
                          style={{
                            padding: "8px 12px",
                            fontSize: "13px",
                            fontWeight: 600,
                            width:"67px"
                          }}
                          className={`btn btn-outline-primary  ${
                            VehicleDetailsForm?.sparekeys=== "Yes" && "active"
                          }`}
                          onClick={(e) => handleVehicleDetails(e, "Yes")}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          name="sparekeys"
                          style={{
                            padding: "8px 12px",
                            fontSize: "13px",
                            fontWeight: 600,
                            width:"67px"
                          }}
                          className={`btn btn-outline-primary  mx-2
                             ${
                              VehicleDetailsForm?.sparekeys === "No" &&
                               "active"
                             }`}
                          onClick={(e) => handleVehicleDetails(e, "No")}
                        >
                          No
                        </button>
                       
                      </div>
                      </div>
                   
                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="vehicleNumber">
                        Vehicle Color
                      </label>
                      <input
                        placeholder="Type Vehicle Color"
                        className="form-control"
                        type="text"
                       
                        name="vehiclecolor"
                        onChange={handleVehicleDetails}
                        value={VehicleDetailsForm?.vehiclecolor}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="vehicleNumber">
                        Vehicle Price
                      </label>
                     
                        <InputNumber 
                        inputId="currency-india" 
                        placeholder="Vehicle Price"
                        name="vehicleprice"
                        className="w-full input1"
                        style={{height:"37px"}}
                        value={VehicleDetailsForm?.vehicleprice} 
                        onValueChange={handleVehicleDetails} 
                        currency="INR" 
                        currencyDisplay="code" 
                        locale="en-IN" />


                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="vehicleNumber">
                        Insurance Name
                      </label>
                      <input
                        placeholder="Insurance Name"
                        className="form-control"
                        type="text"
                     
                        name="insurancename"
                        onChange={handleVehicleDetails}
                        value={VehicleDetailsForm?.insurancename}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label className="mb-1" htmlFor="vehicleNumber">
                        Insurance Validity Date
                      </label>
                      
                    < Calendar
                    id="insurancevaliditydate"
                    className="w-full input1"
                    showIcon={false} 
                    name="insurancevaliditydate"
                    monthNavigator
                    placeholder=" Insurance Validity Date"
                    yearNavigator
                    yearRange="1500:2100"
                    dateFormat="dd-mm-yy"
                    inputId="in"
                    value={vehicle_price}
                    onChange={handleDateChange}
                    style={{ width: '88%',height:"42px" , borderTopRightRadius:"4px" ,borderBottomRightRadius:"4px" , border:"none" }} // Set the width to 300px
                  />


                    </div>
                    <div className="col-md-12 my-2">
                    <label htmlFor="agencyOrCompany" className="pb-2">
                      Description
                    </label>
                    <textarea
                      placeholder="Description"
                      className="form-control"
                      type="text"
                      name="description"
                      onChange={handleVehicleDetails}
                      value={VehicleDetailsForm?.description}
                    
                    />
                  </div>
                  </div>
                  <div className="text-center mt-2">
                    <button
                      type="button"
                      className="btn btn-danger me-2"
                      onClick={handleVehicleClose}
                    >
                      Cancel
                    </button>
                    {VehicleUpdateButton == "Add" ? (
                      <button
                        type="submit"
                        value="add"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting?"Submitting":"Submit"}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        value="update"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting?"Submitting":"Update"}
                      </button>
                    )}
                  </div>
                </form>
              </Box>
            </Modal>
          </p>
        </div>

        <div className="expand" style={{marginTop:"7px"}}>
          <DataTable value={VehicleDetails}>
          <Column
            header="S.No"
            body={(rowData) => <span>{VehicleDetails.indexOf(rowData) + 1}</span>}
          ></Column>
          
           
            <Column 
            field={rowData => rowData.brand === 'Others' ? rowData.otherbrand : rowData.brand}  
            header="Brand"
          ></Column>
           
             <Column 
            field={rowData => rowData.model === 'Others' ? rowData.othermodel : rowData.model}  
            header="Model"
          ></Column>
            <Column field="type" header="Type" style={{ MinWidth: "150px" }} />
            <Column
              field="vehicle"
              header="Vehicle"
              style={{ minWidth: "100px" }}
            />
            <Column
              field="vehicle_no"
              header="Vehicle No"
              style={{ minWidth: "100px" }}
            />
            <Column
              field="dealer_agency"
              header="Agency"
              style={{ minWidth: "100px" }}
            />
            <Column
              field="owner"
              header="Owners"
              style={{ minWidth: "100px" }}
            />
             <Column
              field="ownership"
              header="Ownership"
              style={{ minWidth: "100px" }}
            />
            <Column
              field="vehicletype"
              header="Vehicle Type"
              style={{ minWidth: "140px" }}
            />
            <Column
              field="fueltype"
              header="Fuel Type"
              style={{ minWidth: "100px" }}
            />
            <Column
              field="vehicleseats"
              header="Vehicle Seat"
              style={{ minWidth: "140px" }}
            />
            <Column
              field="vehiclecolor"
              header="Vehicle Color"
              style={{ minWidth: "140px" }}
            />
            <Column
              field="vehicleprice"
              header="Vehicle Price "
              style={{ minWidth: "140px" }}
            />
            <Column
              field="kmdriven"
              header="Km Driven"
              style={{ minWidth: "100px" }}
            />
             <Column
              field="sparekeys"
              header="Spare keys"
              style={{ minWidth: "100px" }}
            />
            <Column
              field="insurancename"
              header="Insurance Name"
              style={{ minWidth: "130px" }}
            />
            <Column
              field="insurancevaliditydate"
              header="Insurance Validity Date"
              style={{ minWidth: "180px" }}
            />
            <Column
              field="description"
              header="Description"
              style={{ minWidth: "300px" }}
            />
            <Column body={actions} header="Actions"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetailsFormPage;





